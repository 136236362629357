import { createSelector } from 'reselect';

import _Store from '@Store';

import stepNames from '@Compo/EntryList/steps';

import { IEntryListReducer } from './../types';
import get from './get';

const getActiveStep = createSelector<
  _Store.IState,
  IEntryListReducer,
  stepNames
>([get], (state) => state.activeStep);

export default getActiveStep;
