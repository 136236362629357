import { SyntheticEvent } from 'react';

/**
 * function that changes the default behavior Link component to jump into indicated element
 *
 * @param {Event} event - event (eg. onClick) to prevent
 * @param {string} id - id indicating destination object
 */
const scrollTo = (event: SyntheticEvent, id: string) => {
  event.preventDefault();

  const destinationElement = document.getElementById(id);

  if (destinationElement) {
    destinationElement.scrollIntoView();
  }
};

export default scrollTo;
