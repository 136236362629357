import { createSelector } from 'reselect';

import _Store from '@Store';

import {
  IPool,
  IPoolSelectedSeats,
  IPoolsGroupedSelectedSeats,
} from './../types';
import getPools from './getPools';
import getSeatsGroupedByCategoryKeysAndPools from './getSeatsGroupedByCategoryKeysAndPools';

const getSeatsGroupedByPoolId = createSelector<
  _Store.IState,
  IPoolsGroupedSelectedSeats,
  IPool[],
  IPoolSelectedSeats
>(
  [getSeatsGroupedByCategoryKeysAndPools, getPools],
  (seatsGroupedByPoolNameOrKey, pools) => {
    const seatsGroupedByPoolId: IPoolSelectedSeats = {};

    pools.map((current) => {
      const { id, seatsIoCategories } = current;
      const stringId = id.toString();
      const key = seatsIoCategories[0]?.categoryKey;
      if (!seatsGroupedByPoolId[id] && key) {
        seatsGroupedByPoolId[id] =
          (seatsGroupedByPoolNameOrKey[key] &&
            seatsGroupedByPoolNameOrKey[key]?.find(
              (pool) => pool.poolName === stringId
            )?.ids) ||
          [];
      }
    });

    return seatsGroupedByPoolId;
  }
);

export default getSeatsGroupedByPoolId;
