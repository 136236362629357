import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoContainer: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.border.radius,
      padding: theme.spacing(5),
    },
    iconContainer: {
      minWidth: '6rem',
      height: '6rem',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      borderRadius: '3rem',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        minWidth: '4rem',
        height: '4rem',
        alignSelf: 'start',
        marginBottom: theme.spacing(4),
      },
    },
    textContainer: {
      alignSelf: 'center',
      marginLeft: '1rem !important',
      color: theme.palette.text.primary,
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0rem !important',
      },
    },
    buttonContainer: {
      alignSelf: 'center',
      marginLeft: '1rem !important',
    },
    firstInfoWrapper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },
    mobileButton: { marginBottom: theme.spacing(3) },
    secondInfoWrapper: {
      padding: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },
    header: {
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(3),
      },
    },
  })
);

export default useStyles;
