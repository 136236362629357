import { createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import { IAvailableThemes } from './../types';

export const changeTheme = createStandardAction(
  CONSTS.CHANGE_THEME
)<IAvailableThemes>();

export const resetTheme = createStandardAction(CONSTS.RESET_THEME)();
