import { connect } from 'react-redux';

import _Store from '@Store';

import { placePage } from '@Model/meta/selectors';
import { IMeta } from '@Model/meta/types';

import Helmet from './../../Helmet';

const mapStateToProps = (state: _Store.IState): IMeta => placePage(state);

export default connect<IMeta, void, {}, _Store.IState>(mapStateToProps)(Helmet);
