import React, { FC } from 'react';

import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import PageWrapper from '@Compo/layout/PageWrapper';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import routes from '@Routes/routes';

import classes from './Fail.classes';
import useStyles from './Fail.styles';
import { IFailComponentProps } from './Fail.types';
import Loading from './components/Loading';

const Fail: FC<IFailComponentProps> = ({ isLoading, lang, redirectUrl }) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);
  const { isMobile } = useMuiBreakpoints();

  return (
    <PageWrapper>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className={cn(muiStyles.wrapper, classes.wrapper)}>
          <Grid container={true}>
            <Grid
              item={true}
              xs={12}
              className={cn(muiStyles.iconWrapper, classes.errorIcon)}
            >
              <Grid item={true} className={muiStyles.iconContainer}>
                <HighlightOffOutlinedIcon fontSize="large" />
              </Grid>
            </Grid>

            <Grid
              item={true}
              xs={12}
              className={cn(muiStyles.headerWrapper, classes.header)}
            >
              <Typography
                variant={isMobile ? 'h3' : 'h2'}
                color="textPrimary"
                align="center"
              >
                {lang.header}
              </Typography>
            </Grid>

            <Grid
              item={true}
              xs={12}
              className={cn(muiStyles.textWrapper, classes.text)}
            >
              <Typography
                variant={isMobile ? 'body1' : 'h5'}
                color="textPrimary"
              >
                {lang.contactWithUs}
              </Typography>
            </Grid>

            <Grid item={true} xs={12} className={classes.reportButton}>
              <Button
                component={Link}
                variant="outlined"
                size="large"
                color="primary"
                fullWidth={true}
                to={routes.contact}
              >
                <Typography>{lang.report}</Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </PageWrapper>
  );
};

export default Fail;
