import React, { useEffect } from 'react';

import { goingDarkTheme, overrides } from '@/config/mui';
import MomentUtils from '@date-io/moment';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/pl';
import { hotjar } from 'react-hotjar';

import { BagStore } from '@Compo/Basket/BasketProvider/BasketProvider';
import CookieBar from '@Compo/CookieBar';
import Login from '@Compo/Login';
import ProgressBar from '@Compo/ProgressBar';
import ErrorModal from '@Compo/errors/ErrorModal';
import Resizer from '@Compo/functional/Resizer';
import Notification from '@Compo/modals/Notification';
import config from '@Config';
import scrollToTopOnMessageEvent from '@Misc/helpers/scrollToTopOnMessageEvent';
import { useIsWebview } from '@Misc/hooks/useIsWebview';
import Routes from '@Routes/index';
import Logger from '@Services/Logger';

import { IAppProps } from './App.types';

// TODO: rebuild

const App = ({
  appIsReady,
  theme,
  isDark,
  setIsWebview,
  locale,
}: IAppProps) => {
  moment.locale(locale);

  const { isWebview } = useIsWebview();

  useEffect(() => {
    appIsReady();

    try {
      hotjar.initialize(2190634, 6);
    } catch (SSR) {}

    /* Please do not remove commented section (below, inside import()), as it is read by webpack during build
    and necessary for braze sdk to load properly. */
    (async () => {
      try {
        const { initialize, openSession } = await import(
          /* webpackExports: ["initialize", "openSession"] */
          '@braze/web-sdk'
        );
        initialize(config.braze.brazeWebAppApiKey, {
          baseUrl: config.braze.sdkEndpoint,
        });

        if (config.app.showUnfinishedFeatures) openSession();
      } catch (e) {
        Logger.error('braze', e);
      }
    })();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', scrollToTopOnMessageEvent);

      return () =>
        window.removeEventListener('message', scrollToTopOnMessageEvent);
    }
  }, []);

  useEffect(() => {
    setIsWebview(isWebview);
  }, [isWebview]);

  const darkTheme = createTheme({
    ...goingDarkTheme,
    // @ts-ignore TODO
    overrides: {
      ...overrides,
      MuiButton: {
        ...overrides.MuiButton,
        outlinedSizeLarge: {
          ...overrides.MuiButton.outlinedSizeLarge,
          color: goingDarkTheme.palette.text.primary,
        },
      },
    },
    spacing: [0, 4, 8, 16, 24, 32, 48, 96, 120, 160],
  });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
      <ThemeProvider
        theme={
          config.theme.isGoing && isDark ? darkTheme : config.mui(theme).theme
        }
      >
        <BagStore>
          <ErrorModal />
          <Login />
          <Notification />
          <ProgressBar />
          <Resizer />
          {!isWebview && <CookieBar />}

          {/* react-router v4 routing */}
          <Routes />
        </BagStore>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
