import { createSelector } from 'reselect';

import _Store from '@Store';

import { IProductSummary, IProductsReducer } from './../types';
import getAll from './getAll';

const getSelectedProductsSummary = createSelector<
  _Store.IState,
  IProductsReducer,
  IProductSummary[]
>([getAll], (products) => {
  const selectedProducts = Object.keys(products.selected).map((productId) => ({
    id: Number(productId),
    quantity: products.selected[productId],
  }));

  return selectedProducts;
});

export default getSelectedProductsSummary;
