import React from 'react';

import cn from 'classnames';

import getShortTime from '@Misc/helpers/dateTime/getShortTime';

import styles from './TimeSlots.module.scss';
import { ITimeSlotsProps } from './TimeSlots.types';

const TimeSlots = ({ setSlot, timeSlots }: ITimeSlotsProps) => (
  <div className={styles.wrapper}>
    <ul className={styles.timeSlots}>
      {timeSlots &&
        timeSlots.map((slot) => {
          if (slot.isDisabled) {
            return null;
          } else {
            return (
              <li key={slot.start}>
                <button
                  className={cn(
                    styles.button,
                    slot.isSelected && styles.selected
                  )}
                  disabled={slot.isDisabled}
                  onClick={() => setSlot(slot.start)}
                >
                  {getShortTime(slot.start)}
                </button>
              </li>
            );
          }
        })}
    </ul>
  </div>
);

export default TimeSlots;
