import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEvent, IReducerEvents } from '../types';
import getPlaceEvents from './getPlaceEvents';

const getPlaceEventsData = createSelector<
  _Store.IState,
  IReducerEvents,
  IEvent[]
>([getPlaceEvents], (placeEvents) => placeEvents.eventsData);

export default getPlaceEventsData;
