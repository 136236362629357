import { CancelTokenSource } from 'axios';
import { format, isToday, isTomorrow } from 'date-fns';
import { pl } from 'date-fns/locale';

import { ITag } from '@Compo/reusable/Tags/Tags.types';
import config from '@Config';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';
import translatableDate from '@Misc/helpers/translatableDate';
import ucFirst from '@Misc/helpers/ucFirst';
import { ILang } from '@Model/locale/types';
import { IEvent } from '@Model/placeEvents/types';
import {
  IDate,
  IEventPlaceCategoryResponse,
  IEventPlaceResponse,
  IImageResponse,
  IRundateCategory,
  IRundateResponse,
  IRundateResponseMutable,
  IRundateTag,
} from '@Services/$events-api/types';
import { IPlaceResponse as Places } from '@Services/$places-api/types';

const TODAY = 'Dzisiaj';
const TOMORROW = 'Jutro';
const DEFAULT_DATE_TRANSLATE = {
  locale: 'pl',
  today: 'Dzisiaj',
  tomorrow: 'Jutro',
};

class EventsListService {
  public static getDateString(startDate: IDate | undefined): string {
    if (!startDate) {
      return '';
    }
    const { year, month, day, hour, minutes } = startDate;

    return `${year}-${month}-${day}T${hour}:${minutes}Z`;
  }

  /**
   * Parses date strings into Cloudsearch date hash.
   *
   * @param {string} inputStr - string in date format yyyy-mm-dd.
   */

  public static getDateHashFromDateString = (inputStr?: string) => {
    const arr = inputStr?.split('-');

    if (arr?.length !== 3) return;

    return parseInt(
      arr
        .map((item, index) =>
          index === 1
            ? (parseInt(item, 10) - 1).toString().padStart(2, '0')
            : item.padStart(2, '0')
        )
        .join('')
    );
  };

  public static getFriendlyDate(
    startDate: string,
    descriptiveDate?: string
  ): string {
    const _date = new Date(startDate);
    const date = new Date(
      new Date(_date.valueOf() + _date.getTimezoneOffset() * 60 * 1000)
    );

    if (descriptiveDate) {
      return descriptiveDate;
    } else if (!startDate) {
      return '';
    } else if (isToday(date)) {
      return TODAY;
    } else if (isTomorrow(date)) {
      return TOMORROW;
    }

    return ucFirst(format(date, 'EEEE, d MMMM yyyy', { locale: pl }));
  }

  public static getFriendlyPlace(place?: IEventPlaceResponse | Places) {
    return place ? `${place.name}, ${place.address}` : '';
  }

  public static getImageUrl(images: IImageResponse[]): string {
    return images.length > 0 ? images[0].medium : '';
  }

  public static prepareTagsFromRundate(
    category: IRundateCategory,
    tags: IRundateTag[]
  ) {
    const eventTags = [];

    if (category) {
      eventTags.push({
        ...category,
        isMain: true,
      });
    }

    if (tags) {
      tags.forEach((tag) =>
        eventTags.push({
          ...tag,
          isMain: false,
        })
      );
    }

    return eventTags;
  }

  public static getPriceDescription(priceDescription?: string) {
    if (priceDescription && priceDescription.length > 0) {
      return priceDescription;
    }
  }

  public static normalizeEventPlaceTags(
    placeTag: IEventPlaceCategoryResponse | null
  ): ITag[] | null {
    if (!placeTag) {
      return null;
    }

    return [
      {
        id: placeTag.id,
        isMain: true,
        name: placeTag.name,
        slug: placeTag.slug,
      },
    ];
  }

  protected static salesAgentHeader = {
    'X-Sales-Agent': config.app.salesAgent,
  };

  protected static getFriendlyHour(
    startDate: string,
    descriptiveDate?: string
  ): string | undefined {
    const _date = new Date(startDate);
    const date = new Date(
      new Date(_date.valueOf() + _date.getTimezoneOffset() * 60 * 1000)
    );

    if (descriptiveDate) {
      return undefined;
    }

    return format(date, 'HH:mm', { locale: pl });
  }

  protected static getShortPlace(place?: IEventPlaceResponse | Places): string {
    return place ? `${place.name}, ${place.address.split(', ', 1)}` : '';
  }

  protected cancelTokenEvents?: CancelTokenSource;

  public normalizeShortEvent(
    eventResponse: IRundateResponseMutable,
    dateTranslate: ILang = DEFAULT_DATE_TRANSLATE
  ): IEvent {
    const {
      buttonLabel,
      changeMonitorType,
      currency,
      endDate,
      event: { category, titlePL, images, slug: eventSlug, tags },
      id,
      isForPremiumUsers,
      place,
      price,
      priceDescriptionPL,
      rundateDescription,
      slug: rundateSlug,
      startDate,
      redirectToUrl,
    } = eventResponse as IRundateResponse;
    const startDateString = EventsListService.getDateString(startDate);

    return {
      buttonLabel,
      changeMonitorName: changeMonitorType?.name || null,
      currency,
      dateDescription: rundateDescription,
      endDate: EventsListService.getDateString(endDate),
      eventSlug,
      friendlyDate: translatableDate(
        startDateString,
        rundateDescription,
        dateTranslate
      ),
      friendlyHour: EventsListService.getFriendlyHour(
        startDateString,
        rundateDescription
      ),
      id,
      imageUrl: EventsListService.getImageUrl(images),
      isPremiumEvent: isForPremiumUsers,
      place: EventsListService.getShortPlace(place),
      placeImageUrl: place.thumb,
      placeSlug: place.slug,
      price: getPriceFormatted(price),
      priceDescription:
        EventsListService.getPriceDescription(priceDescriptionPL),
      redirectToUrl: redirectToUrl || null,
      rundateSlug,
      startDate: startDateString,
      tags: EventsListService.prepareTagsFromRundate(category, tags),
      title: titlePL,
    };
  }

  public cancelEvents() {
    if (this.cancelTokenEvents) {
      this.cancelTokenEvents.cancel();
      this.cancelTokenEvents = undefined;
    }
  }
}

export default EventsListService;
