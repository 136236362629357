import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    filter: {
      fontFamily: theme.typography.fontFamily,
    },
  })
);

export default useStyles;
