import routes from '@Routes/routes';
import { FavoritesTypes } from '@Types/Favorites';

const pl = {
  artist: {
    nearest: 'Najbliższy koncert',
    noUpcomingEvents: 'Brak nadchodzących wydarzeń',
    pastEvents: 'Wydarzenie, które się odbyły',
    upcomingEvents: 'Nadchodzące wydarzenia',
  },
  basket: {
    courierDelivery: 'Wysyłka kurierem:',
    details: 'Szczegóły',
    hide: 'Ukryj',
    pieces: 'szt.',
    pricePerItem: 'Cena/szt.:',
    products: 'Produkty niebiletowe',
    serviceFee: 'Opłata serwisowa:',
    ticketsNum: 'Liczba biletów:',
    totalPrice: 'Łączna cena:',
  },
  breadcrumbs: {
    activities: 'Aktywności',
    events: 'Wydarzenia',
  },
  buy: {
    agreements: {
      acceptAgreements: 'Zaakceptuj zgody',
      acceptAgreementsDetailed: 'Zaakceptuj zgody i podaj dane dla biletów',
      additionalData: '(wymaga podania dodatkowych danych)',
      agreementsAndRegulations: 'Zgody i regulaminy',
      fillInData: 'Uzupełnij danymi nabywcy',
      iWantNewsletter: 'Chcę dostawać newsletter Going. Mustsee.',
      newsletterAgreement:
        'Zapisując się do Newslettera Aplikacji Going wyrażam zgodę na przetwarzanie podanych przeze mnie moich danych osobowych przez GOING Spółkę z ograniczoną odpowiedzialnością z siedzibą w Warszawie, Aleje Jerozolimskie 107, 02–011 Warszawa w celu otrzymywania informacji handlowych według rozumienia art. 9 ustawy z dnia 9 czerwca 2017 r. o świadczeniu usług drogą elektroniczną (Dz.U. z 2017 r. poz. 1219)',
      requiredFields:
        '*aby zrealizować zamówienie niezbędne jest zaznaczenie pól oznaczonych gwiazdką.',
      requiredFieldsAdditional:
        'Dla biletów, które nie wymagają podania unikalnych danych użyte zostaną informacje wprowadzone w sekcji 3.',
      selectConsents: 'Zaznacz zgody dla tego wydarzenia',
    },
    clientData: {
      didYouMean: 'Czy chodziło Ci o: ',
      discountAdded: 'Dodano zniżkę.',
      discountRemoved: 'Zniżka usunięta.',
      discountCode: 'Podaj kod rabatowy',
      discountCodeTooltip: 'Kod wygenerowany w ramach akcji promocyjnych',
      discountCodeTooltipSaltos:
        'Kody rabatowe dla kart partnerskich znajdziesz na grafice u góry',
      discountCodeInputEmpty: 'Pole na kod rabatowy nie może być puste.',
      discountUnavailableForBasketSales:
        '. UWAGA: kody zniżkowe są tymczasowo niedostępne dla biletów dodanych do koszyka. Aby użyć kodu zniżkowego, usuń bilety z koszyka i dokonaj zakupu bezpośrednio na stronie wydarzenia w Going.',
      discountUnavailableForBasketSalesEmpik:
        '. UWAGA: kody zniżkowe są tymczasowo niedostępne dla biletów dodanych do koszyka. Aby użyć kodu zniżkowego, usuń bilety z koszyka i dokonaj zakupu bezpośrednio na stronie wydarzenia w Empik Bilety',
      eMail: 'Adres e-mail',
      fillFormWithLastData: 'Uzupełnij danymi',
      firstName: 'Imię',
      getDataFrom: 'Pobierz swój adres z:',
      getLastData: 'Czy chcesz uzupełnić formularz ostatnio podanymi danymi?',
      iHaveDiscount: 'Mam kod rabatowy',
      iHaveDiscountGoing: 'Mam kod rabatowy',
      iHavePass: 'Posiadam karnet',
      iHavePrepaid: 'Mam kod vouchera',
      iHavePrepaidGoing: 'Mam kod vouchera lub karty podarunkowej Going.',
      id: ', gdyż obsługa może poprosić Cię o dokument potwierdzający tożsamość',
      lastName: 'Nazwisko',
      namedTicket: 'Bilet jest imienny. Podaj',
      pass: 'Numer karnetu',
      phone: 'Numer telefonu',
      prepaidCard: 'Podaj kod karty podarunkowej.',
      prepaidCardGoing: 'Podaj kod karty podarunkowej Going.',
      prepaidCardInputEmpty:
        'Pole na kartę podarunkową lub voucher nie może być puste.',
      prepaidTooltip:
        'Voucher Going. (otrzymany w ramach zwrotu biletu lub reklamacji) Karta Podarunkowa Going. (zakupiona przez Empik.com)',
      provideData: 'Podaj swoje dane',
      realData: 'prawdziwe dane',
      validEmail: 'Upewnij się, że e-mail jest poprawny',
      willSendTickets: 'Zakupione bilety wyślemy na wskazany adres.',
    },
    empikCardNumber: {
      buyNow: 'Kup teraz',
      cardDescription:
        'Jeżeli jesteś Klientem Empik Premium, podaj swój 13-cyfrowy kod Klienta przypisany do wskazanego przez Ciebie adresu e-mail. Kod Klienta znajdziesz w aplikacji Empik w zakładce Premium.',
      empikCardNumber: 'Kod klienta premium',
      here: 'tutaj',
      premiumUser: 'Jestem klientem Empik Premium',
      premiumUserData: 'Dane klienta Empik Premium',
    },
    formLayout: {
      buttonAdd: 'Dodaj do koszyka',
      buttonPay: 'Przejdź do płatności',
      buyAndPay: 'Kupuję i płacę',
      formInvalid:
        'Coś się nie zgadza. Popraw dane w formularzu i ruszaj w miasto!',
      summary: 'Podsumowanie',
    },
    happening: {
      selectNumberOfPlayers: 'Wybierz liczbę osób',
      selectDateAndTime: 'Wybierz datę i godzinę',
      date: 'Data',
      time: 'Godzina',
      selectSpace: 'Wybierz przestrzeń',
      selectTicketType: 'Wybierz rodzaj biletu',
      relatedProducts: 'Powiązane produkty',
      upsell: 'Wydłuż atrakcję',
      book: 'Rezerwuj',
      checkDiscount: 'Sprawdź zniżkę',
      enterDiscountCode: 'Wpisz kod rabatowy',
      noMoreSlots: 'Niestety w wybranym dniu nie można wykonać rezerwacji.',
      selectTime: 'Wybierz godzinę',
      availability: 'Dostępność',
    },
    insurance: {
      accidentInsurance:
        'Czy jesteś zainteresowany/a ubezpieczeniem biletu na wypadek braku możliwości udziału w wydarzeniu np. z powodu urazu ciała w wyniku NW, czasowej niezdolności do pracy, kolizji drogowej, lub poważnego zachorowania?',
      birthdayPlaceholder: 'RRRR-MM-DD',
      choose: 'Chcę ubezpieczyć bilet/y.',
      consumerRights:
        'Obowiązki informacyjne wynikające z ustawy o prawach konsumenta',
      iAmAdult: 'Czy masz ukończone 18 lat?',
      iLiveInPoland: 'Czy mieszkasz na stałe w Polsce?',
      insuranceBirthDateHelperText: 'Podaj swoją datę urodzenia.',
      insuranceHeader: 'Ubezpieczenie biletów',
      insuranceProductInfo: 'Informacje o produkcie ubezpieczeniowym (PID)',
      invalidInsuranceData: 'Dane ubezpieczeń zostały załadowane niepoprawnie.',
      invalidInsuranceFormData:
        'Sprawdź, czy możesz zaznaczyć pola z gwiazdką. Jeśli nie, to ubezpieczenie nie jest odpowiednie dla Ciebie.',
      insuranceNotAvailableforBasket:
        'Ubezpieczenie jest tymczasowo niedostępne dla biletów dodanych do koszyka. Aby dokupić ubezpieczenie, usuń bilety z koszyka i dokonaj zakupu bezpośrednio na stronie wydarzenia w Going.',
      insuranceNotAvailableforBasketEmpik:
        'Ubezpieczenie jest tymczasowo niedostępne dla biletów dodanych do koszyka. Aby dokupić ubezpieczenie, usuń bilety z koszyka i dokonaj zakupu bezpośrednio na stronie wydarzenia w Empik Bilety.',
      invalidTicketPrice:
        'Ubezpieczenie dostępne tylko dla biletów w cenie od 5 do 2500 zł. Aby skorzystać z ubezpieczenia, usuń z koszyka bilety w cenie spoza tego przedziału.',
      moreInsuranceInfo: 'Ogólne warunki ubezpieczenia',
      rodo: 'Przetwarzanie danych osobowych',
      tooManyTickets:
        'Maksymalna liczba biletów, które można objąć ubezpieczeniem to 6. Aby móc z niego skorzystać, usuń bilety z koszyka tak, aby nie przekraczały tej liczby.',
      tooltipPart1:
        'Ubezpieczenie obejmuje nieobecność na wydarzeniu w wyniku np.:',
      tooltipPart2:
        'Możesz uzyskać zwrot ceny zakupu biletu/-ów na wydarzenie. Szczegóły ubezpieczenia znajdziesz w ogólnych warunkach ubezpieczenia. Polisę prześlemy w wiadomości e-mail po zawarciu umowy ubezpieczenia. Ubezpieczycielem jest UNIQA.',
      tooltipLi1: 'wypadku lub kolizji drogowej',
      tooltipLi2: 'opóźnienia środka transportu',
      tooltipLi3: 'poważnego zachorowania',
      tooltipLi4: 'złamania kości',
      tooltipLi5: 'urazu ciała w wyniku NW',
      tooltipLi6: 'zachorowania dziecka',
      tryAgain: 'Spróbuj ponownie',
    },
    invoice: {
      address: 'Adres',
      city: 'Miasto',
      iWantInvoice: 'Chcę otrzymać fakturę',
      legalA:
        'Zgodnie ze zmianami obowiązującymi od 01 stycznia 2020 roku, aby otrzymać fakturę VAT należy przy zakupie zaznaczyć opcję "chcę otrzymać fakturę" ',
      legalB:
        'i wskazać dane do jej wystawienia. Zgodnie ze zmianami art. 106b ust. 5–7, art. 109a ustawy z 11 marca 2004 r. o podatku od towarów i usług j.t. Dz.U. z 2018 r. poz. 2174, ost.zm. Dz.U. z 2019 r. poz. 2200 oraz art. 15 ustawy z 4 lipca 2019 r. o zmianie ustawy o podatku od towarów i usług oraz niektórych innych ustaw Dz.U. z 2019 r. poz. 1520, gdy opcja ta nie jest zaznaczona sprzedawca nie ma obowiązku wystawienia kupującemu faktury VAT. W przypadku zaznaczenia chęci otrzymania faktury wyrażasz zgodę na przesłanie jej drogą elektroniczną. ',
      less: '[mniej]',
      more: '[więcej]',
      name: 'Nazwa firmy lub imię i nazwisko',
      nip: 'NIP',
      zip: 'Kod pocztowy',
      country: 'Kraj',
    },
    paymentMethod: {
      blik: 'BLIK',
      card: 'Karta płatnicza',
      choosePaymentMethod: 'Wybierz metodę płatności',
      payu: 'PayU',
      mBank: 'mBank',
      emptyPaymentMethods:
        'Brak dostępnych metod płatności. Niektóre z zaznaczonych biletów mogą mieć wymuszone metody platności.',
      confirmCardData: 'Potwierdź dane karty',
      cardDataConfirmed: 'Dane karty potwierdzone poprawnie!',
      cardDataError:
        'Nie udało się potwierdzić danych karty. Spróbuj ponownie.',
    },
    pickUpWay: {
      courierDelivery: 'Wysyłka kurierem ',
      pickUp: 'Wybierz sposób odbioru',
      pickUpProduct: 'Podaj dane do przesyłki produktów',
      rightAway: 'Bilety otrzymasz od razu',
      sendAsEmail: 'Wysyłka na adres e-mail',
      tenDaysDispatch: 'Wysyłka w ciągu 10 dni',
    },
    pools: {
      additionalData: 'Dane dodatkowe',
      dependency:
        'Wpisz kod biletu lub karnetu upoważniający do wyboru danej puli.',
      dependencyPlaceHolder: 'Kod biletu lub kod karnetu',
      details: 'Szczegóły',
      noPoolsAvailable: 'Brak dostępnych pul biletowych',
      sameCurrency:
        'W zamówieniu znajduje się bilet w innej walucie. Usuń go, aby wybrać tę pulę.',
      title: 'Wybierz rodzaj i liczbę biletów',
    },
    postAddress: {
      cityText: 'Miasto',
      collectorsTicket: 'Wzór biletu kolekcjonerskiego',
      countryText: 'Kraj',
      firstNameText: 'Imię',
      infoText:
        'Wybierając tę formę odbioru, wyrażasz zgodę na przekazanie danych osobowych do operatora wysyłki.',
      lastNameText: 'Nazwisko',
      localText: 'Lokal',
      phoneText: 'Numer telefonu',
      streetNumberText: 'Numer',
      streetText: 'Ulica',
      zipText: 'Kod pocztowy',
    },
    products: {
      title: 'Wybierz rodzaj i liczbę produktów',
      collectorTicket:
        'Bilet kolekcjonerski nie upoważnia do wstępu na wydarzenie. Wejście możliwe wyłącznie przy pomocy biletu elektronicznego.',
    },
    summary: {
      additionalProducts: 'Dodatkowe produkty:',
      amount: 'Liczba:',
      courierDelivery: 'Wysyłka kurierem:',
      currency: ' zł',
      defaultPriceType: 'Bilet normalny',
      empty: 'brak',
      finalPrice: 'Razem do zapłaty:',
      formInvalid:
        'Niestety formularz nie jest kompletny. Wypełnij brakujące pola.',
      giftWrapper: 'Opakowanie prezentowe:',
      iHaveCoupon: 'Mam kod rabatowy',
      insurance: 'Ubezpieczenie:',
      insuranceWithCurrentEventTickets:
        'Ubezpieczenie (koszyk + aktywne wydarzenie):',
      noData: 'Brak danych',
      numberOfPlayers: 'Liczba osób:',
      payment: 'do zapłaty:',
      pricePerItem: 'Cena/szt.:',
      serviceFee: 'Opłata serwisowa:',
      serviceFeeInformation:
        'Opłata serwisowa obejmuje koszty opłaty transakcyjnej, automatyzacji wysyłki biletu, obsługi posprzedażowej oraz obsługę ewentualnych zgłoszeń indywidualnych i reklamacji. Jest pobierana przy zakupie biletu wraz ze stawką bazową.',
      shipment: 'Dostawa:',
      space: 'Wybrana przestrzeń:',
      ticketTransferFee: 'Opłata za przekazanie biletu:',
      ticketsAmount: 'Liczba biletów:',
      ticketsPrice: 'Cena biletów:',
      time: 'Czas:',
      totalPrice: 'Łączna cena:',
      yourBasket: 'Twój koszyk',
      yourReservation: 'Twoja rezerwacja',
      extendedSlotPrice: 'Cena normalna',
      extendedSlotDiscount: 'Kwota zniżki',
    },
    term: {
      and: 'oraz',
      iAccept: 'Akceptuję',
      iAcceptTerms: 'Akceptuję regulamin',
      privacyPolicy: 'politykę prywatności',
      read: '[przeczytaj]',
      regulations: 'regulamin sprzedaży',
    },
  },
  dates: {
    locale: 'pl',
    today: 'Dzisiaj',
    tomorrow: 'Jutro',
  },
  entryListL: {
    addBuyerStep: {
      title: 'Czy chcesz dopisać do biletu osobę, która dokonała zakupu?',
    },
    addNewPeopleToTicketStep: {
      addNewPerson: 'Dodaj kolejną osobę',
      checkData: 'Sprawdź, czy dane się zgadzają',
      confirm: 'Potwierdzam',
      title: 'Aby dokończyć proces potrzeba jeszcze',
    },
    addPeopleToTicketStep: {
      noData: 'Brak powiązanych wpisów',
      title: 'Zaznacz osoby, które chcesz dopisać do biletu',
    },
    back: 'Wstecz',
    entryTerm: {
      confirm: 'Potwierdź',
      sendEmail: 'Wyślij email',
    },
    haveTicketCodeStep: {
      haveTicketCode: 'Czy posiadasz kod biletu?',
      title: 'Uzupełnij dodatkowe dane',
    },
    next: 'Dalej',
    no: 'Nie',
    numberOfPeopleStep: {
      inputHelperText:
        'Wpisz ile osób wybiera się na wydarzenie w ramach zakupionego biletu',
      inputText: 'Liczba osób na bilecie',
    },
    registerNewPerson: {
      title: 'Rejestracja',
    },
    thanksStep: {
      back: 'Powrót do początku',
      code: 'Kod Twojego powiązania to:',
      thanks: 'Dziękujemy!',
    },
    ticketStep: {
      enterTicketCode: 'Wpisz kod biletu, aby dodać do niego osoby',
      ticketCode: 'Kod biletu',
      ticketCodeHelper: 'Wpisz kod biletu otrzymany mailowo',
    },
    title: 'Lista wejściowa',
    yes: 'Tak',
  },
  errors: {
    authIdIsRequiredValidator: 'Identyfikator jest wymagany',
    cardActiveText:
      'Karta Mój Empik została odnaleziona. Korzyści usługi Premium zostały aktywowane.',
    checkingCardErrorValidator:
      'Nie udało się zweryfikować poprawności karty. Spróbuj ponownie później.',
    checkingCardInactiveValidator:
      'Karta Mój Empik została odnaleziona ale nie posiada ona usługi Premium.',
    checkingCardNomatchValidator:
      'Adres e-mail i kod klienta Premium nie są zgodne lub nie posiadasz aktywnej usługi Empik Premium.',
    checkingCardValidator: 'Trwa sprawdzanie numeru karty...',
    checkingThisRegulationsIsRequiredValidator:
      'Zaznaczenie tej zgody jest wymagane',
    dependencyCodeIsRequired: 'Kod biletu / karnetu jest wymagany',
    discountCodeIsRequired: 'Kod rabatowy jest wymagany',
    emailAddressFieldIsRequiredValidator: 'Uzupełnij pole z adresem e-mail',
    emailAddressIsRequiredValidator: 'Adres e-mail jest wymagany',
    firstNameIsRequiredValidator: 'Imię jest wymagane',
    incorrectData: 'Zły format',
    incorrectEmpikCardFormatValidator: 'Niepoprawny format numeru karty',
    incorrectNip: 'NIP może zawierać tylko cyfry',
    incorrectPhoneNumber: 'Podany numer jest nieprawidłowy',
    incorrectPostCodeValidator: 'Niepoprawny kod pocztowy',
    lastNameIsRequiredValidator: 'Nazwisko jest wymagane',
    countryIsRequiredValidator: 'Kraj jest wymagany',
    providedEmailAddressIsIncorrectValidator:
      'Podany adres e-mail jest niepoprawny',
    providedIdIsIncorrectValidator: 'Podany identyfikator jest nieprawidłowy',
    sorry: 'Przepraszamy',
    thisFieldIsRequiredValidator: 'Uzupełnij',
    wrongBirthDate:
      'Wprowadzona data urodzenia jest nieprawidłowa lub użyto złego formatu',
  },
  event: {
    additionalDescriptionTitle: 'informacja organizatora',
    anotherDateText: 'Zobacz inne terminy',
    artistBio: 'o artyście',
    buyTicket: 'Kup bilet',
    choose: 'WYBIERZ',
    currency: ' zł',
    details: 'Rodzaje biletów',
    disabledTooltip: 'Bilety aktualnie niedostępne w ',
    forFansOf: 'podobni artyści',
    from: 'od',
    gallery: 'galeria',
    hour: 'godz.',
    noDescription: 'brak opisu',
    noInfoAboutPrice: 'Brak informacji o cenie biletu',
    otherDates: 'inne terminy',
    place: 'miejsce',
    placeLink: 'przejdź do strony tego miejsca',
    readMore: 'Czytaj więcej',
    participatingArtists: 'występują',
    samePlaceActivitiesTitle: 'inne aktywności w tym miejscu',
    samePlaceEventsTitle: 'inne wydarzenia w tym miejscu',
    serviceFeeTooltip: 'Do ceny biletu może zostać doliczona opłata serwisowa.',
    serviceFeeInformationBox:
      'Do ceny biletu doliczana jest opłata serwisowa, jej wysokość można sprawdzić bezpośrednio w koszyku. Więcej informacji w Regulaminie.',
    similarEventsTitle: 'podobne wydarzenia',
    tickets: 'bilety',
    timetable: 'Plan Wydarzenia',
  },
  favorites: {
    close: 'Zamknij',
    stayFresh: 'Bądź na bieżąco!',
    [FavoritesTypes.Artists]: 'Artyści',
    [FavoritesTypes.Places]: 'Miejsca',
    [FavoritesTypes.Tags]: 'Tagi',
  },
  following: {
    artistHeader: 'Obserwuj artystę',
    artistPrompt:
      'Podaj swój adres e-mail, aby otrzymać informacje dotyczące tego artysty. ',
    artistTerm:
      'Zapisując się wyrażasz zgodę na przetwarzanie podanych danych przez GOING Sp. z o.o. w celu otrzymywania informacji związanych z artystą.',
    getUpdates:
      'Otrzymuj powiadomienia o istotnych zmianach, nowych wydarzeniach i innych aktualnościach.',
    salesHeader: 'Powiadom mnie o starcie sprzedaży',
    salesPrompt:
      'Podaj swój adres e-mail, aby otrzymać informację o starcie sprzedaży na to wydarzenie.',
    salesTerm:
      'Zapisując się wyrażasz zgodę na przetwarzanie podanych danych przez GOING Sp. z o.o. w celu otrzymywania informacji związanych z wydarzeniem.',
    ticketsHeader: 'Powiadom mnie o biletach',
    ticketsPrompt:
      'Bilety zostały wyprzedane. Podaj swój adres e-mail, aby otrzymać informację, jeśli bilety ponownie pojawią się w sprzedaży.',
    ticketsTerm:
      'Zapisując się wyrażasz zgodę na przetwarzanie podanych danych przez GOING Sp. z o.o. w celu otrzymywania informacji związanych z wydarzeniem.',
    placeHeader: 'Obserwuj miejsce',
    placePrompt:
      'Podaj swój adres e-mail, aby otrzymać informacje dotyczące wydarzeń w tym miejscu.',
    placeTerm:
      'Zapisując się wyrażasz zgodę na przetwarzanie podanych danych przez GOING Sp. z o.o. w celu otrzymywania informacji związanych z miejscem.',
    rundateHeader: 'Dodaj do kalendarza',
    rundatePrompt:
      'Podaj swój adres e-mail, aby obserwować to wydarzenie i dodać je do kalendarza.',
    rundateTerm:
      'Zapisując się wyrażasz zgodę na przetwarzanie podanych danych przez GOING Sp. z o.o. w celu otrzymywania informacji związanych z wydarzeniem.',
    yourEmail: 'Twój adres e-mail',
    emailValidate: 'Podaj poprawny adres e-mail',
    subscribe: 'Zapisz',
    success: 'Udało się!',
    successDownload:
      'Pobierz aplikację Going., by mieć wszystko pod ręką i być zawsze na bieżąco.',
    successButtonYes: 'Pobieram',
    successButtonNope: 'Nie chcę teraz',
    failure: 'Coś poszło nie tak, spróbuj jeszcze raz.',
  },
  formio: {
    langButton: 'Click here for english',
    title: 'Formularz zgłoszeniowy',
  },
  happening: {
    priceTypes: {
      choose: 'Wybierz',
      chosen: 'Wybrano',
      defaultTicket: 'Bilet normalny',
    },
    space: {
      unavailableTicketTypes:
        'Ta przestrzeń jest niedostępna ponieważ zaznaczono następujące typy biletów: ',
    },
    upsell: {
      add: 'Dodaj',
      added: 'Dodano',
      clickAgainToResign: 'Kliknij ponownie, aby zrezygnować',
      getAdditionalSlot: 'Dodaj dodatkowy czas',
      upsellDuration: 'Rezerwacja zostanie przedłużona do: ',
    },
  },
  modals: {
    ActionMessage: {
      close: 'Zamknij',
      no: 'NIE',
      yes: 'TAK',
    },
  },
  passwordReset: {
    passwordResetForm: {
      confirmNewPass: 'Potwierdź hasło',
      emailAddress: 'Adres email:',
      emailAddressBoundToAccount: 'Adres email przypisany do konta',
      incorrectEmail: 'Podany adres e-mail jest niepoprawny',
      newPass: 'Nowe hasło',
      pageTitle: 'Resetuj hasło',
      requiredField: 'Uzupełnij pole',
      submitButton: 'RESETUJ HASŁO',
      successMessage: 'Udało się! Twoje hasło zostało zmienione.',
    },
  },
  payment: {
    paymentFail: {
      contactWithUs:
        'Skontaktuj się z nami i daj nam znać, co się stało opisując dokładnie sytuację, w której wystąpił błąd. Sprawdzimy, co jest grane najszybciej jak to możliwe.',
      header: 'Coś poszło nie tak...',
      report: 'Zgłoś problem',
      title: 'Błąd podczas transakcji',
    },
    paymentSuccess: {
      altText: 'aplikacja Going.',
      altText2: 'Pobierz aplikację',
      beUpToDate:
        'Bądź na bieżąco z najlepszymi wydarzeniami, skomponuj swój kalendarz eventowy i trzymaj bilety zawsze pod ręką w aplikacji.',
      confirm: 'Potwierdzenie transakcji',
      currency: 'zł',
      discoverApp: 'Odkryj aplikację Going.',
      discoverApp2: 'odkryj apkę going.',
      downloadApp: 'Pobierz apkę Going.',
      enterNumber:
        'Wpisz numer, na który chcesz otrzymać SMS z linkiem do pobrania aplikacji Going.',
      error: 'Błąd',
      getMore: 'Zyskaj jeszcze więcej z aplikacją Going.!',
      getTicketByEmail:
        'Bilety dostaniesz mailem maksymalnie w ciągu 24h, ale z reguły uwijamy się szybciej. Bilety znajdziesz również w naszej aplikacji.',
      getTicketByEmailEmpik:
        'Bilety dostaniesz mailem maksymalnie w ciągu 24h, ale z reguły uwijamy się szybciej. Bilety znajdziesz również w aplikacji Going.',
      header: 'Dzięki za złożenie zamówienia!',
      helperText: 'Numer jest niepoprawny',
      importantInfo: 'Ważne info!',
      informationWillBeSend:
        'Zaproszenie do przejęcia biletów zostanie wysłane na adres wskazany w formularzu przekazania.',
      openApp: 'otwórz aplikację',
      orDownload: 'lub pobierz bezpośrednio z Twojego sklepu',
      place: 'Miejsce:',
      prefix: '+48',
      send: 'wyślij',
      somethingWentWrong: 'Coś poszło nie tak',
      successMessage: 'Sms wysłany!',
      summary: 'Razem:',
      sugestNotToPrint:
        'Przypomnij osobie, która odbiera bilet, że nie musi drukować tego biletu. Może go pokazać na ekranie telefonu.',
      thanks: 'Dziękujemy.',
      thanksForReceiving: 'Dziękujemy za odbiór biletu.',
      tickets: 'Liczba biletów:',
      transfer: 'Proces przekazania biletów został rozpoczęty.',
      waiting: 'Teraz czekamy na potwierdzenie transakcji...',
      yourBank:
        'Jeśli Twój bank nie potwierdzi transakcji w ciągu 10 min, zamówienie może zostać anulowane.',
      yourOrder: 'Twoje zamówienie',
    },
  },
  promotionBanner: {
    altText: 'o aplikacji',
  },
  routes: {
    buyRoute: routes.buy,
    eventRoute: routes.event,
  },
  sendMailSuccess: {
    title: 'Bilety są już w drodze!',
    description: 'Wysłaliśmy bilety na podany w zamówieniu adres e-mail.',
    button: 'Przejdź do strony głównej',
  },
  ticketsManagement: {
    changeDate: {
      selectDate: 'Wybierz datę',
      selectTime: 'Wybierz godzinę',
    },
    myTicket: {
      back: 'Wróć',
      cancel: 'Anuluj',
      cancelTransferButton: 'Anuluj przekazanie',
      cancelTransferMessage: 'Czy na pewno chcesz anulować przekazanie biletu?',
      choose: 'Wybierz',
      downloadButton: 'Pobierz',
      downloadTicket: 'Twój bilet (PDF)',
      pageTitle: 'Zarządzaj biletem',
      reschedule: 'Zmień datę rezerwacji',
      reset: 'Zarządzaj innym biletem',
      resetTicketCode: 'Wpisz inny kod biletu',
      return: 'Zwróć bilet',
      returnButton: 'Zwróć',
      returnMessage: 'Czy na pewno chcesz zwrócić bilet?',
      returnSuccess:
        'Twoje zgłoszenie zostało zarejestrowane. O dalszych krokach poinformujemy Cię drogą mailową.',
      refundOptionsMessage:
        'Wybierz, w jakiej formie chcesz otrzymać zwrot środków za bilet.',
      refundTransfer: 'Przelew',
      refundVoucherGoing: 'Voucher Going.',
      refundVoucherEmpik: 'Voucher Empik',
      saleClosed:
        'Nie można przekazywać biletów na wydarzenia z wyłączoną sprzedażą.',
      transfer: 'Przekaż bilet innej osobie',
      transferButton: 'Przekaż',
      transferInProgress: 'Bilet w trakcie przekazywania',
      transferSuccess:
        'Dziękujemy! Zaproszenie do odbioru biletu zostało wysłane na podany mail. Osoba, której przekazujesz bilet ma 72h na jego akceptację. Po upływie tego czasu bilet powróci do Ciebie.',
      transferCancel:
        'Przekazanie biletu zostało anulowane. Twój bilet powinien wrócić do Ciebie w ciągu godziny.',
    },
    myTickets: {
      choose: 'Wybierz',
      pageTitle: 'Wybierz bilet',
    },
    ticketDataForm: {
      codePlaceholder: 'Kod biletu',
      emailPlaceholder: 'Adres e-mail użyty do zakupu',
      incorrectEmail: 'Podany adres e-mail jest niepoprawny',
      mustBeANumber:
        'Wprowadź wartość liczbową, z maks. dwiema pozycjami po przecinku (wartości dziesiętne podaj po kropce)',
      orderIdPlaceholder: 'Numer zlecenia',
      pageTitle: 'Zarządzaj biletem',
      paymentSumPlaceholder: 'Kwota',
      requiredField: 'Uzupełnij pole',
      submitButton: 'Sprawdź',
      useTicketData: 'Wprowadź dane biletu:',
      useTransactionData: 'lub użyj danych transakcji:',
    },
    ticketRecipience: {
      contactForm: 'formularz kontaktowy.',
      getTicket: 'Odbierz bilety',
      hello: 'Witaj, ',
      namedTicket:
        'Pamiętaj, że bilety są imienne. Jeśli w Twoich danych pojawił się błąd, od razu nam to zgłoś poprzez ',
      poolSaleUnavailable:
        'Niestety przekazywanie biletów na wydarzenia z wyłączoną lub zakończoną sprzedażą chwilowo nie jest możliwe. Prosimy o kontakt z naszym działem Supportu poprzez formularz kontaktowy (link dostępny w stopce strony pod opisem "Kontakt") oraz zawarcie w zgłoszeniu kodu przekazywanego biletu. Dziękujemy za wyrozumiałość.',
      submitButton: 'Odbierz bilety',
      toRecipience:
        'Aby je przyjąć, zaakceptuj poniższe zgody i użyj przycisku ODBIERZ BILETY na dole strony.',
      transferTicket:
        'Na tej stronie możesz odebrać przesłane Ci bilety na to wydarzenie.',
    },
    ticketTransfer: {
      becauseStaffChecksId:
        ' osoby, która otrzyma bilet, ponieważ obsługa może poprosić ją o dokument potwierdzający tożsamość.',
      chooseAmount: 'Wybierz liczbę biletów, które chcesz przekazać',
      descFirstParagraph: `Tutaj możesz przekazać swój bilet, sprawdź jak to zrobić:`,
      descFirstBulletPointPart1: `Wpisz dane osoby, której chcesz przekazać bilet i kliknij `,
      descFirstBulletPointPart2: `“przekaż bilet”.`,
      descSecondBulletPointPart1: `Osoba odbierająca otrzyma wiadomość, `,
      descSecondBulletPointPart2: `w której potwierdza odbiór biletu, opłaca cenę biletu i opłatę za przekazanie.`,
      descThirdBulletPointPart1: `Następnie `,
      descThirdBulletPointPart2: `otrzymasz wiadomość z potwierdzeniem `,
      descThirdBulletPointPart3: `przekazania oraz zwrot za bilet pomniejszony o opłatę za przekazanie.`,
      descSecondParagraph: `💲 Wysokość opłaty za przekazanie jest zmienna i zależna od ustaleń z organizatorem wydarzenia. `,
      descThirdParagraphPart1: `Ty zapłacisz`,
      descThirdParagraphPart2: `, a odbiorca`,
      descFourthParagraphPart1: ` Pamiętaj, że zawsze możesz zmienić zdanie i anulować przekazanie biletu. Aby to zrobić zaloguj się do Mój bilet i użyj funkcji `,
      descFourthParagraphPart2: `anuluj przekazanie.`,
      emailLabel: 'Przekazane bilety wyślemy na adres podany poniżej.',
      emailPlaceHolder: 'Adres e-mail',
      emailTip: 'Upewnij się, że adres jest on poprawny.',
      enterReceiversData: 'Podaj dane osoby odbierającej',
      errorMessage:
        'Ten bilet nie może zostać przekazany. Istnieje kilka powodów, dlaczego ta operacja nie jest możliwa: brak zgody Organizatora wydarzenia na zmianę danych na bilecie, zamówienie z fakturą VAT lub zakup biletu przy użyciu kodu rabatowego. Masz wątpliwości? Skontaktuj się z nami poprzez ',
      errorMessageLinkLabel: 'formularz.',
      errorTitle: 'Ups!',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      pageTitle: 'Przekaż bilet',
      realData: 'prawdziwe dane',
      submitButton: 'Przekaż bilet',
      ticketIsNominal: 'Bilet jest imienny. Podaj',
      ticketsHeader: 'Pula 1',
    },
  },
  entryList: {
    starter: {
      title: 'Start',
      subtitle: 'Czy posiadasz kod biletu?',
      yes: 'Tak',
      no: 'Nie',
    },
    form: {
      title: 'Twoje dane',
      additionalPerson: 'Dane osoby towarzyszącej',
      subtitle: 'Uzupełnij poniższy formularz i zaakceptuj regulamin',
      firstName: 'Imię*',
      lastName: 'Nazwisko*',
      email: 'Adres e-mail*',
      birthDay: 'Data urodzenia (DD/MM/RRRR)*',
      goBack: 'Wstecz',
      goNext: 'Dalej',
      validEmail: 'Upewnij się, że e-mail jest poprawny',
      didYouMean: 'Czy chodziło Ci o: ',
    },
    termsOfUse: {
      accept: 'Akceptuję ',
      statue: 'regulamin',
      and: ' oraz ',
      privacyPolicy: 'politykę prywatności*',
    },
    dataConfirmation: {
      title: 'Potwierdź dane',
      subtitle: 'Sprawdź, czy wszystko się zgadza',
      goBack: 'Wstecz',
      confirm: 'Zatwierdź',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      email: 'Adres e-mail',
      birthDay: 'Data urodzenia',
    },
    greeting: {
      title: 'Dziękujemy za rejestrację!',
      subtitle: 'Wszystko złatwione. Udanej zabawy!',
      button: 'Przejdź do strony głównej',
    },
    parentForm: {
      title: 'Zgoda opiekuna',
      subtitle:
        'Rejestracja osób niepełnoletnich wymaga zgody rodzica lub opiekuna prawnego. Wpisz dane i numer telefonu takiej osoby. Otrzyma ona od nas SMS z kodem potwierdzającym zgodę i tożsamość.',
      goBack: 'Wstecz',
      goNext: 'Dalej',
      firstName: 'Imię rodzica lub opiekuna*',
      lastName: 'Nazwisko rodzica lub opiekuna*',
      phoneNumber: 'Numer telefonu*',
    },
    smsCode: {
      title: 'Zgoda opiekuna',
      subtitle: 'Wpisanie kodu SMS jest jednoznaczne z akceptacją regulaminu.',
      goBack: 'Wstecz',
      goNext: 'Dalej',
      code: 'Kod SMS*',
      sendAgain: 'Wyślij ponownie kod',
      codeError: 'Niepoprawny kod',
    },
    validation: {
      requiredField: 'Pole wymagane',
      incorrectEmail: 'E-mail niepoprawny',
      invalidDate: 'Niepoprawny format daty. Poprawny format DD/MM/RRRR',
      privacyPolicy: 'Musisz zaakceptować regulamin i politykę prywatności',
      incorrectPhoneNumber: 'Niepoprawny numer telefonu',
    },
    agreementExist: {
      title: 'Użytkownik jest już zarejestrowany',
      subtitle:
        'Użytkownik o podanych przez Ciebie danych został już wcześniej zarejestrowany.',
      submissionsCompleteTitle: 'Dziękujemy za rejestrację',
      submissionsCompleteHelper: 'Lista wejściowa jest już wypełniona.',
      homepage: 'Przejdź do strony głównej',
      goBack: 'Wróć',
    },
    ticketCode: {
      title: 'Wpisz kod biletu',
      placeholder: 'Kod biletu*',
      goBack: 'Wróć',
      goNext: 'Dalej',
    },
    submissionsSummary: {
      title: 'Lista wejściowa',
      helper: 'Lista osób przypisanych do biletu.',
      goBack: 'Wstecz',
      confirm: 'Zatwierdź',
      submissionExist: 'Osoba o podanych danych jest już dodana do biletu',
    },
    checkSubmission: {
      title: 'Adres e-mail',
      helper: 'Wprowadź swój adres e-mail',
      placeholder: 'Adres e-mail*',
      goBack: 'Wstecz',
      goNext: 'Dalej',
    },
    selectSubmission: {
      title: 'Dane uczestnika',
      helper: 'Wybierz dane z listy lub wprowadź nowe',
      addNew: 'Dodaj nowe dane',
      goBack: 'Wstecz',
      goNext: 'Dalej',
      additionalPerson: 'Dane osoby towarzyszącej',
      additionalPersonHelper: 'Wybierz osobę towarzyszącą lub dodaj nową',
    },
  },
};

export type Translation = typeof pl;

export default pl;
