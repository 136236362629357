import { connect } from 'react-redux';

import _Store from '@Store';

import {
  getActiveStep,
  getSubmissions,
  getTicketData,
} from '@Model/entryList/selectors';

import Paper from './Paper.component';
import { IPaperFromState } from './Paper.types';

const mapStateToProps = (state: _Store.IState): IPaperFromState => ({
  ticketData: getTicketData(state),
  submissions: getSubmissions(state),
  activeStep: getActiveStep(state),
});

export default connect<IPaperFromState, {}, {}, _Store.IState>(mapStateToProps)(
  Paper
);
