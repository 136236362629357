import { connect } from 'react-redux';

import _Store from '@Store';

import { isMobile } from '@Model/app/selectors';
import { getCompositionIdentifier } from '@Model/pages/selectors';
import { getLocation } from '@Model/router/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import WideArticle from './WideArticle.component';
import {
  IWideArticleFromState,
  IWideArticleOwnProps,
} from './WideArticle.types';

const mapStateToProps = (state: _Store.IState): IWideArticleFromState => {
  return {
    isDarkMode: getLocation(state).query?.isDark === 'true',
    isLoading: getLoading(CONST.SECTIONS)(state),
    isMobile: isMobile(state),
    compositionIdentifier: getCompositionIdentifier(state),
  };
};

export default connect<
  IWideArticleFromState,
  null,
  IWideArticleOwnProps,
  _Store.IState
>(mapStateToProps)(WideArticle);
