import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dot: {
    backgroundColor: theme.palette.text.disabled,
    width: '0.25rem',
    height: '0.25rem',
    borderRadius: '0.25rem',
    marginRight: '0.1250rem',
    opacity: 1,
    [theme.breakpoints.up('sm')]: {
      width: '0.375rem',
      height: '0.375rem',
      borderRadius: '0.25rem',
    },
  },
  active: {
    background: `linear-gradient(90deg, ${theme.palette.gradient.first} 0%, ${theme.palette.gradient.second} 100%)`,
  },
  text: {
    color: theme.palette.text.disabled,
    textTransform: 'capitalize',
    fontSize: '0.6125rem',
  },
}));

export default useStyles;
