import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import SellSuccessHeader from './SellSuccessHeader.component';
import { ISellSuccessHeaderFromState } from './SellSuccessHeader.types';

const mapStateToProps = (state: _Store.IState): ISellSuccessHeaderFromState => {
  return {
    lang: translate(state)('payment', 'paymentSuccess'),
  };
};

export default connect<ISellSuccessHeaderFromState, {}, {}, _Store.IState>(
  mapStateToProps,
  {}
)(SellSuccessHeader);
