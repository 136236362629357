import { createStandardAction } from 'typesafe-actions';

import { ITrafficParams } from '../types';
import * as CONSTS from './../constants';

export const prepareUtmParamsForState = createStandardAction(
  CONSTS.PREPARE_TRAFFIC_PARAMS
)();

export const recordUtmParamsInState = createStandardAction(
  CONSTS.RECORD_TRAFFIC_PARAMS
)<ITrafficParams>();
