const classes = {
  agreement: 'agreements-agreement',
  errorIcon: 'error-icon',
  header: 'header',
  reportButton: 'report-button',
  text: 'text',
  wrapper: 'wrapper',
};

export default classes;
