import config from '@/config';
import { connect } from 'react-redux';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';
import { translate } from '@Model/locale/selectors';
import { getTheme } from '@Model/theme/selectors';

import Product from './Product.component';
import { IProductFromState } from './Product.types';

const mapStateToProps = (state: _Store.IState): IProductFromState => ({
  isDesktop: isDesktop(state),
  lang: translate(state)('buy', 'products'),
  productsLimit: config.buy.maxTicketsCount,
  selectedTheme: getTheme(state),
});

export default connect<IProductFromState, void, {}, _Store.IState>(
  mapStateToProps
)(Product);
