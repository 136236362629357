import { getType } from 'typesafe-actions';

import { ICard } from '@Compo/reusable/Card/Card.types';
import { IEvent } from '@Model/events/types';

import {
  getEvent,
  getPlaceEvents,
  getSimilarEvents,
  setCurrentKey,
} from './../actions';
import { IAction, IEventReducer } from './../types';

const initialState: IEventReducer = {
  currentKey: null,
  data: {},
  placeEvents: [] as ICard[],
  requestTimes: {},
  similarEvents: [] as IEvent[],
};

const reducer = (
  state: IEventReducer = initialState,
  action: IAction
): IEventReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getEvent.success):
      const { event, key } = action.payload;

      return {
        ...state,
        currentKey: key,
        data: {
          ...state.data,
          [key]: event,
        },
        requestTimes: {
          ...state.requestTimes,
          [key]: Date.now(),
        },
      };

    case getType(setCurrentKey):
      return {
        ...state,
        currentKey: action.payload,
      };

    case getType(getSimilarEvents.success):
      return {
        ...state,
        similarEvents: action.payload.similarEvents,
      };

    case getType(getPlaceEvents.success):
      return {
        ...state,
        placeEvents: action.payload.placeEvents,
      };

    default:
      return state;
  }
};

export default reducer;
