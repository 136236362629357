import { Location } from 'history';

import { ILightHistory } from '@Compo/functional/InternalRouter/InternalRouter.types';
import routes from '@Routes/routes';

/**
 * function that changes current viewport of render page to the top
 *
 * @param {Location} location - if location is not a list (like eg. events) then perform action
 * @param {ILightHistory} [history] - browser history object
 */
function scrollTopTop(location: Location, history?: ILightHistory): void;

/**
 * function that changes current viewport of render page to the top
 *
 * @param {boolean} location - if true then perform action
 */
function scrollTopTop(location: boolean): void;
function scrollTopTop(
  location: Location | boolean,
  history?: ILightHistory
): void {
  let shouldScroll = false;

  if (typeof location === 'boolean') {
    if (location) {
      shouldScroll = true;
    }
  } else {
    shouldScroll =
      !!history &&
      history.action.toLowerCase() !== 'pop' &&
      !(location.pathname === routes.events && location.search !== '') &&
      !(location.hash !== '');
  }

  if (shouldScroll) {
    window.scrollTo(0, 0);
  }
}

export default scrollTopTop;
