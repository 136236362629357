import React, { FC, useRef } from 'react';

import theme from '@/config/theme';

import InteractiveMap from '@Compo/reusable/InteractiveMap';
import useResize from '@Misc/hooks/useResizer';
import GoingMarker from '@Styles/images/going-marker.svg';
import Marker from '@Styles/images/marker.svg';

import styles from './Map.module.scss';
import { IPlaceMap } from './Map.types';

const MAP_HEADER_TEXT = 'zobacz na mapie';
const MAP_MAX_HEIGHT = 500;
const MARKER_HEIGHT = 40;
const MARKER_WIDTH = 40;
const MAP_ZOOM = 14;
const locationMarker = theme.isEmpik ? Marker : GoingMarker;

const map: FC<IPlaceMap> = ({ longitude, latitude }) => {
  const mapRef = useRef<HTMLDivElement>(null);

  const { width } = useResize(mapRef);

  const calculatedHeight = width <= MAP_MAX_HEIGHT ? width : MAP_MAX_HEIGHT;

  return (
    <>
      <h3 className={styles.mapTitle}>{MAP_HEADER_TEXT}</h3>

      <div className={styles.map} ref={mapRef}>
        <InteractiveMap
          height={calculatedHeight}
          latitude={latitude}
          longitude={longitude}
          marker={locationMarker}
          markerHeight={MARKER_HEIGHT}
          markerWidth={MARKER_WIDTH}
          zoom={MAP_ZOOM}
        />
      </div>
    </>
  );
};

export default map;
