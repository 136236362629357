import { createSelector } from 'reselect';

import _Store from '@Store';

import config from '@Config';
import { getEvent } from '@Model/event/selectors';
import { IEventFull } from '@Model/event/types';
import { getParams } from '@Model/internalRouter/selectors';
import { IInternalRouterParams } from '@Model/internalRouter/types';
import { IPlaceMatchParams } from '@Routes/types';

import { IImagesReducer } from '../types';
import get from './get';

const getPlaceImages = createSelector<
  _Store.IState,
  IInternalRouterParams,
  IImagesReducer,
  IEventFull | null,
  string[] | null
>([getParams, get, getEvent], (_params, images, event) => {
  const params = _params as IPlaceMatchParams;
  const key = `${config.images.placePrefix}${
    params.slug ? params?.slug : event?.placeSlug
  }`;
  if (key && key in images) {
    return images[key];
  }

  return null;
});

export default getPlaceImages;
