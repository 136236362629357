export const PLACE_PAGE_MOUNTED = 'Empik/place/PLACE_PAGE_MOUNTED';
export const SET_CURRENT_KEY = 'Empik/place/SET_CURRENT_KEY';

// Private
export const _GET_REQUEST = 'Empik/place/_GET_REQUEST';
export const _GET_SUCCESS = 'Empik/place/_GET_SUCCESS';
export const _GET_FAILURE = 'Empik/place/_GET_FAILURE';

export const _GET_PLACE_SLUG_REQUEST = 'Empik/place/_GET_PLACE_SLUG_REQUEST';
export const _GET_PLACE_SLUG_SUCCESS = 'Empik/place/_GET_PLACE_SLUG_SUCCESS';
export const _GET_PLACE_SLUG_FAILURE = 'Empik/place/_GET_PLACE_SLUG_FAILURE';
