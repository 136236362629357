import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      color: theme.palette.text.primary,
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    secondary: {
      color: theme.palette.text.secondary,
    },
  })
);

export default useStyles;
