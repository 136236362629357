import { createSelector } from 'reselect';

import _Store from '@Store';

import { PagesEnum } from './../constants/pages';
import { ICompositionData, IPagesReducer } from './../types';
import get from './get';

const getRecommend = createSelector<
  _Store.IState,
  IPagesReducer,
  ICompositionData | null
>([get], (pages) =>
  pages.compositions[PagesEnum.recommend]?.sections.length
    ? pages.compositions[PagesEnum.recommend] || null
    : null
);

export default getRecommend;
