import { Selector } from 'reselect';

import _Store from '@Store';

import { IHappeningDataReducer } from './../types';

const getData: Selector<_Store.IState, IHappeningDataReducer> = (state) =>
  state.happening.data;

export default getData;
