import React, { useEffect, useState } from 'react';

import { Button, Tooltip } from '@material-ui/core';
import cn from 'classnames';

import classes from './Buttons.classes';
import styles from './Buttons.module.scss';
import { IButtonProps } from './Buttons.types';

const Buttons = ({
  poolId,
  selectTicket,
  howManyTickets,
  poolName,
  price,
  isActive,
  serviceFee,
  selectedTickets,
  additionalFields,
  forms,
  paymentMethods,
  eventAddToCart,
  eventRemoveFromCart,
  currency,
  basket,
  lang,
}: IButtonProps) => {
  const [amount, setAmount] = useState(0);

  const selectedTicket = selectedTickets.find(
    (ticket) => ticket.poolId === poolId
  );

  const isButtonPlusActive = amount !== howManyTickets && isActive;

  const isButtonMinusActive = isActive && amount > 0;

  const isSameCurrency = selectedTickets[0]
    ? currency === selectedTickets[0].currency
    : basket[0]
    ? currency === basket[0].currency
    : true;

  useEffect(() => {
    if (!selectedTicket) {
      setAmount(0);
    }
  }, [selectedTicket]);

  const subtract = () => {
    if (amount > 0) {
      eventRemoveFromCart({ currency, price: price || 0 });
      setAmount(amount - 1);
    }
  };

  const add = () => {
    if (isSameCurrency) {
      if (howManyTickets) {
        if (amount < howManyTickets) {
          setAmount(amount + 1);
          eventAddToCart({ currency, price: price || 0 });
        }
      } else {
        setAmount(amount + 1);
      }
    }
  };

  useEffect(() => {
    if (poolId && poolName) {
      selectTicket(
        poolId,
        amount,
        poolName,
        currency,
        price,
        serviceFee,
        additionalFields,
        forms,
        undefined,
        paymentMethods
      );
    }
  }, [amount]);

  return (
    <div className={cn(styles.buttons, classes.buttons)}>
      <Button
        disableElevation={true}
        variant={'contained'}
        color={'primary'}
        className={cn(
          styles.button,
          amount === 0 && styles.disabled,
          isButtonMinusActive && classes.buttonMinus
        )}
        data-test-id={`button-${poolId}-subtract`}
        onClick={subtract}
      >
        -
      </Button>
      <p className={styles.amount}>{amount}</p>
      <Tooltip
        title={lang.sameCurrency}
        disableHoverListener={isSameCurrency}
        disableFocusListener={isSameCurrency}
        disableTouchListener={isSameCurrency}
      >
        <Button
          disableElevation={true}
          variant={'contained'}
          color={'primary'}
          className={cn(
            styles.button,
            ((howManyTickets && amount === howManyTickets) ||
              !isSameCurrency) &&
              styles.disabled,
            isButtonPlusActive && classes.buttonPlus
          )}
          data-test-id={`button-${poolId}-add`}
          data-active-pool-button={isButtonPlusActive && 'button-pool'}
          onClick={add}
        >
          +
        </Button>
      </Tooltip>
    </div>
  );
};

export default Buttons;
