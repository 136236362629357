import React, { useEffect } from 'react';

import routes from '@/routes/routes';
import { Button, Grid, Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import PageWrapper from '@Compo/layout/PageWrapper';

import useStyles from './SendMailSuccess.styles';
import { ISendMailSuccessProps } from './SendMailSuccess.types';

const SendMailSuccess = ({ history, lang, mounted }: ISendMailSuccessProps) => {
  const muiStyles = useStyles();

  const redirect = () => {
    history.push(routes.index);
  };

  useEffect(() => {
    mounted();
  }, []);

  return (
    <PageWrapper>
      <Grid
        container={true}
        spacing={5}
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        className={muiStyles.container}
      >
        <Grid className={muiStyles.icon} item={true} xs={12}>
          <MailOutlineIcon fontSize={'large'} />
        </Grid>
        <Grid item={true} xs={12}>
          <Typography variant={'h3'} align={'center'} className={muiStyles.h3}>
            {lang.title}
          </Typography>
          <Typography
            variant={'body1'}
            align={'center'}
            className={muiStyles.body1}
          >
            {lang.description}
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            className={muiStyles.button}
            variant={'contained'}
            size={'medium'}
            color={'primary'}
            onClick={redirect}
          >
            {lang.button}
          </Button>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default SendMailSuccess;
