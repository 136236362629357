import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  captureIframeEvent,
  initEmbed,
  locationChange,
} from '@Model/internalRouter/actions';
import {
  getExternalStyle,
  getIframeParams,
} from '@Model/internalRouter/selectors';
import { IInternalRouterEvent } from '@Model/internalRouter/types';
import { setUserLocale } from '@Model/locale/actions';
import { isFirstRendering } from '@Model/router/selectors';

import {
  IInternalRouterFromDispatch,
  IInternalRouterFromState,
} from './../InternalRouter.types';
import Embed from './Embed.component';

function mapStateToProps(state: _Store.IState): IInternalRouterFromState {
  return {
    externalStyle: getExternalStyle(state),
    iframeProps: getIframeParams(state),
    isFirstRendering: isFirstRendering(state),
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IInternalRouterFromDispatch => {
  return {
    locationChange: (payload) => dispatch(locationChange(payload)),
    mounted: () =>
      dispatch(
        initEmbed((event: IInternalRouterEvent) =>
          dispatch(captureIframeEvent(event))
        )
      ),
    setBrowserLocale: () => dispatch(setUserLocale()),
  };
};

export default connect<
  IInternalRouterFromState,
  IInternalRouterFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Embed);
