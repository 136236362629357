import { createSelector } from 'reselect';

import _Store from '@Store';

import { IReducerEvents } from './../types';
import getPlaceEvents from './getPlaceEvents';

const isMorePlaceEventsAvailable = createSelector<
  _Store.IState,
  IReducerEvents,
  boolean
>(
  [getPlaceEvents],
  (placeEvents) => placeEvents.totalCount > placeEvents.eventsData.length
);

export default isMorePlaceEventsAvailable;
