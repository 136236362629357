import { createSelector } from 'reselect';

import _Store from '@Store';

import getCommonObjectFromArrays from '@Misc/helpers/getCommonObjectFromArrays';
import { getSelectedTickets } from '@Model/pools/selectors';
import { IPaymentMethod } from '@Services/$transactions-api/types';

import {
  IPaymentMethodsReducer,
  IPoolsSelectedTicketsReducer,
} from './../types';
import getPaymentMethods from './getPaymentMethods';

const getAvailablePaymentMethods = createSelector<
  _Store.IState,
  IPaymentMethodsReducer,
  IPoolsSelectedTicketsReducer,
  IPaymentMethod[]
>([getPaymentMethods, getSelectedTickets], (payment, selectedTickets) => {
  if (selectedTickets.length) {
    return getCommonObjectFromArrays(
      selectedTickets.map((ticket) => ticket.paymentMethods || [])
    );
  }

  return payment.paymentMethods;
});

export default getAvailablePaymentMethods;
