import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { calculatePrice } from '@Model/happening/actions';

import { resetLoading, setLoading } from '../actions';
import * as CONSTS from '../constants/constants';

export const setLoaderWhenHappeningPriceFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([calculatePrice.request])),
    map$(() => setLoading(CONSTS.HAPPENING_PRICE))
  );
};

export const setLoaderWhenHappeningPriceFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([calculatePrice.success, calculatePrice.failure], action)
    ),
    map$(() => resetLoading(CONSTS.HAPPENING_PRICE))
  );
};
