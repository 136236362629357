import { getModule } from '@/models/internalRouter/selectors';
import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { getSelectedTickets } from '@Model/pools/selectors';

import Invoice from './Invoice.component';
import { IInvoiceFromState, IInvoiceOwnProps } from './Invoice.types';

const mapStateToProps = (state: _Store.IState): IInvoiceFromState => ({
  lang: translate(state)('buy', 'invoice'),
  module: getModule(state),
  selectedTickets: getSelectedTickets(state),
});

export default connect<
  IInvoiceFromState,
  void,
  IInvoiceOwnProps,
  _Store.IState
>(mapStateToProps)(Invoice);
