import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { eventAddToCart, eventRemoveFromCart } from '@Model/analytics/actions';
import { getEvent, specialEvent } from '@Model/event/selectors';
import { deselectSeat, selectSeat } from '@Model/pools/actions';
import { getPricing, getSelectedSeats } from '@Model/pools/selectors';
import getPoolsData from '@Model/pools/selectors/getPoolsData';
import { IPoolsSelectedSeat } from '@Model/pools/types';
import getLocation from '@Model/router/selectors/getLocation';
import { setInvalidSelection, setValidSelection } from '@Model/tickets/actions';

import Seats from './Seats.component';
import { ISeatsFromDispatch, ISeatsFromState } from './Seats.types';

const mapStateToProps = (state: _Store.IState): ISeatsFromState => ({
  event: getEvent(state),
  poolsData: getPoolsData(state),
  pricing: getPricing(state),
  selectedSeats: getSelectedSeats(state),
  selectedPool: getLocation(state).query?.selectedPool,
  specialEvent: specialEvent(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISeatsFromDispatch => {
  return {
    deselectSeat: (seat: IPoolsSelectedSeat) => dispatch(deselectSeat(seat)),
    onSelectionInvalid: (reason) => dispatch(setInvalidSelection(reason)),
    onSelectionValid: () => dispatch(setValidSelection()),
    selectSeat: (seat: IPoolsSelectedSeat) => dispatch(selectSeat(seat)),
    eventAddToCart: (data) => dispatch(eventAddToCart(data)),
    eventRemoveFromCart: (data) => dispatch(eventRemoveFromCart(data)),
  };
};

export default connect<ISeatsFromState, ISeatsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Seats);
