import React, { FC } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import SectionLead from '@Compo/Bricks/components/LeadSection';
import ImageFixed from '@Compo/reusable/ImageFixed';
import ReadMore from '@Compo/reusable/ReadMore';
import ShortDescription from '@Compo/reusable/ShortDescription';
import Tags from '@Compo/reusable/Tags';
import Title from '@Compo/reusable/Title';
import TagsLoading from '@Compo/reusable/loadings/TagsLoading';
import invertIfGrayScaleColor from '@Misc/helpers/invertIfGrayScaleColor';
import isColorDark from '@Misc/helpers/isColorDark';
import slugifyString from '@Misc/helpers/slugifyString';

import styles from './WideArticle.module.scss';
import useStyles from './WideArticle.styles';
import { IWideArticleProps } from './WideArticle.types';

const WideArticle: FC<IWideArticleProps> = ({
  articleData,
  color,
  id,
  isDarkMode,
  isLoading,
  isMobile,
  sectionDescription,
  sectionTitle,
  bgColor,
  compositionIdentifier,
}) => {
  const showReadMore = articleData.url && !isMobile;
  const GAClassName = `${compositionIdentifier}-${id}-${slugifyString(
    articleData.title
  )}`;

  const isBgDark =
    isColorDark(bgColor) ||
    isColorDark(
      isDarkMode && bgColor ? invertIfGrayScaleColor(bgColor) : bgColor
    );

  const theme = useTheme();
  const muiStyles = useStyles(color, isBgDark)(theme);

  return (
    <>
      <SectionLead
        color={color}
        description={sectionDescription}
        medium={true}
        showDropdown={false}
        title={sectionTitle}
      />
      <div
        className={cn(styles.wideArticle, styles.normal, muiStyles.root)}
        id={id}
      >
        <div className={styles.image}>
          <ImageFixed
            src={articleData.imageUrl}
            link={articleData.url}
            isLoading={isLoading}
            title={articleData.title}
            className={GAClassName}
          />
        </div>
        <div className={styles.details}>
          {!isMobile && (
            <div className={styles.tags}>
              {!isLoading && <Tags tags={articleData.tags} />}
              {isLoading && <TagsLoading />}
            </div>
          )}
          {articleData.title && (
            <Title
              title={articleData.title}
              link={articleData.url}
              isLoading={isLoading}
              customClassName={cn(
                styles.title,
                muiStyles.fontColor,
                GAClassName
              )}
            />
          )}
          {articleData.date && (
            <h4 className={styles.date}>{articleData.date}</h4>
          )}
          {articleData.description && (
            <ShortDescription
              description={articleData.description}
              isLoading={isLoading}
              customClassName={cn(
                styles.description,
                muiStyles.fontColor,
                muiStyles.secondary
              )}
            />
          )}
          {isMobile && (
            <div className={styles.tags}>
              {!isLoading && <Tags tags={articleData.tags} />}
              {isLoading && <TagsLoading />}
            </div>
          )}

          {showReadMore && (
            <ReadMore
              link={articleData.url}
              isLoading={isLoading}
              isBgDark={isBgDark}
              className={GAClassName}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WideArticle;
