import React, { FC, useEffect } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import { Formik, FormikProps } from 'formik';
import { Link } from 'react-router-dom';
import { lazy, object } from 'yup';

import Agreements from '@Compo/buying/components/Agreements/Agreements.component';
import RedirectForPayU from '@Compo/buying/components/RedirectForPayU';
import {
  customTermValidator,
  termValidator,
} from '@Compo/buying/constants/validators';
import routes from '@Routes/routes';

import WithEventDetailBox from './../WithEventDetailBox';
import styles from './TicketRecipience.module.scss';
import useStyles from './TicketRecipience.styles';
import {
  ITicketRecipienceCustomTerms,
  ITicketRecipienceProps,
} from './TicketRecipience.types';

const TicketRecipience: FC<ITicketRecipienceProps> = ({
  customTerms,
  errors,
  lang,
  mounted,
  submit,
  ticketData,
}) => {
  useEffect(() => {
    mounted();
  }, []);

  const AgreementForm = ({
    handleSubmit,
    setFieldValue,
    values,
  }: FormikProps<any>) => (
    <form onSubmit={handleSubmit}>
      <Agreements
        isDisabled={false}
        isEmbed={false}
        number={2}
        lang={lang}
        customTerms={customTerms}
        selectedTickets={[]}
        setFieldValue={setFieldValue}
        values={values}
        selectedTheme={'default'}
      />
      <button className={styles.submitButton} type="submit" disabled={false}>
        {lang.submitButton}
      </button>
    </form>
  );

  const agreementsInitialValues: ITicketRecipienceCustomTerms = {
    customTerms: {},
    newsletter: false,
    terms: false,
  };

  const validators = object().shape({
    customTerms: lazy((value: { [key: string]: boolean }) =>
      customTermValidator(errors, customTerms, value, 'exceptFVat')
    ),
    terms: termValidator(errors.checkingThisRegulationsIsRequiredValidator),
  });

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <WithEventDetailBox>
      <h3 className={styles.header} data-number={1}>
        {lang.getTicket}
      </h3>
      <fieldset className={cn(styles.formPanel, muiStyles.root)}>
        <p className={styles.description}>
          {lang.hello}
          <b>
            {ticketData?.targetFirstname} {ticketData?.targetLastname}!
          </b>
        </p>
        <p className={styles.description}>
          {lang.transferTicket}
          <br />
          {lang.toRecipience}
        </p>
        <p className={cn(styles.description, styles.smallText)}>
          {lang.namedTicket}
          <Link
            className={cn(styles.contactFormButton, muiStyles.fontColor)}
            to={routes.contact}
          >
            {lang.contactForm}
          </Link>
        </p>
      </fieldset>
      <Formik
        component={AgreementForm}
        enableReinitialize={true}
        initialValues={agreementsInitialValues}
        onSubmit={submit}
        validationSchema={validators}
      />
      <RedirectForPayU />
    </WithEventDetailBox>
  );
};

export default TicketRecipience;
