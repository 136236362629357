import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';

import AuthGate from './AuthGate.component';
import { IAuthGateFromState } from './AuthGate.types';

const mapStateToProps = (state: _Store.IState): IAuthGateFromState => ({
  client: getIframeParams(state)?.client || '',
  isDesktop: isDesktop(state),
});

export default withRouter(
  connect<IAuthGateFromState, void, {}, _Store.IState>(mapStateToProps)(
    AuthGate
  )
);
