import { getType } from 'typesafe-actions';

import { setIsWebview } from './../../actions';
import { IAction } from './../../types';

const initialState: boolean = false;

const reducer = (state = initialState, action: IAction): boolean => {
  switch (action.type) {
    case getType(setIsWebview):
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
