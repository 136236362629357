import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolsSelectedTicketsReducer } from './../types';
import getSelectedTickets from './getSelectedTickets';

const isAnyPoolSelected = createSelector<
  _Store.IState,
  IPoolsSelectedTicketsReducer,
  boolean
>([getSelectedTickets], (selectedTickets) => selectedTickets.length > 0);

export default isAnyPoolSelected;
