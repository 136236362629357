import { Theme, createStyles, makeStyles } from '@material-ui/core';

import config from '@Config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      color: theme.palette.text.primary,
    },
    root: {
      backgroundColor: config.theme.isEmpik
        ? 'transparent'
        : theme.palette.background.paper,
    },
  })
);

export default useStyles;
