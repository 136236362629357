const scrollToTopOnMessageEvent = (event: MessageEvent): undefined | null => {
  try {
    const parsedEvent = JSON.parse(event.data);
    if (parsedEvent.type === 'scrollToTop') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  } catch (err) {
    return null;
  }
};

export default scrollToTopOnMessageEvent;
