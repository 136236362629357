import React from 'react';

import cn from 'classnames';

import styles from './BuyPageBackground.module.scss';
import { IBuyPageBackgroundProps } from './BuyPageBackground.types';

const BuyPageBackground = ({ hideApostrophe }: IBuyPageBackgroundProps) => (
  <div className={cn(styles.background, hideApostrophe && styles.hide)} />
);

export default BuyPageBackground;
