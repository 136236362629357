import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import PostAddress from './PostAddress.component';
import { IPostAddressFromState } from './PostAddress.types';

const mapStateToProps = (state: _Store.IState): IPostAddressFromState => ({
  lang: translate(state)('buy', 'postAddress'),
});

export default connect<IPostAddressFromState, void, {}, _Store.IState>(
  mapStateToProps
)(PostAddress);
