import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';

const URI_PLACEHOLDER_FOR_SLUG = ':slug';

/**
 * Function to prepare url from route and slug
 *
 * @param {string} route - to which slug refers
 *
 * @param {string} slug - slug to specific view
 *
 * @example
 * prepareUrlForRoute(routes.place, 'klub-studio');
 * //=> http://localhost:3000/miejsce/klub-studio
 */
const prepareUrlForRoute = (route: string, slug: string) => {
  return fillUrlWithValues(route, URI_PLACEHOLDER_FOR_SLUG, slug);
};

export default prepareUrlForRoute;
