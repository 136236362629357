import React, { useState } from 'react';

import { Button, Grid, TextField } from '@material-ui/core';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useGlobalStyles from '../../global.styles';
import stepNames from '../../steps';
import Paper from '../Paper';
import useStyles from './SmsCode.styles';
import { ISmsCodeProps } from './SmsCode.types';

const SmsCode = ({
  handleNextStep,
  handlePrevStep,
  smsCode,
  saveSubmission,
  generateAndSendSmsCode,
}: ISmsCodeProps) => {
  const classes = useStyles();
  const globals = useGlobalStyles();
  const { entryList: lang } = useDictionary();
  const [code, setCode] = useState('');
  const [error, setError] = useState<string>();

  const validateCode = () => {
    if (smsCode !== code) {
      setError(lang.smsCode.codeError);
    } else {
      saveSubmission();
      setError(undefined);
      handleNextStep(stepNames.greeting);
    }
  };

  const resendCode = () => {
    const generatedCode = Math.floor(1000 + Math.random() * 9000).toString();
    generateAndSendSmsCode(generatedCode);
  };

  return (
    <Paper title={lang.smsCode.title} helperText={lang.smsCode.subtitle}>
      <Grid container={true} justifyContent="center" className={classes.root}>
        <Grid item={true} xs={12}>
          <TextField
            error={!!error}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            label={lang.smsCode.code}
            variant="outlined"
            helperText={!!error && error}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button variant="text" size="medium" onClick={resendCode}>
            {lang.smsCode.sendAgain}
          </Button>
        </Grid>
        <Grid item={true} xs={12} className={classes.buttons}>
          <Grid
            container={true}
            justifyContent="center"
            className={globals.column}
          >
            <Grid item={true}>
              <Button
                className={globals.button}
                type="button"
                variant="text"
                color="primary"
                onClick={handlePrevStep}
              >
                {lang.smsCode.goBack}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                type="submit"
                className={globals.button}
                variant="contained"
                size="medium"
                color="primary"
                disabled={!code.length}
                onClick={validateCode}
              >
                {lang.smsCode.goNext}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SmsCode;
