import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPlaceReducer, IPlaceReducerData } from './../types';
import get from './get';

const getData = createSelector<_Store.IState, IPlaceReducer, IPlaceReducerData>(
  [get],
  (place) => place.data
);

export default getData;
