import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { validateForm } from '@Model/analytics/actions';
import { updateBasketStateFromContext } from '@Model/basket/actions';
import { getBasketData } from '@Model/basket/selector';
import { buyFormMounted } from '@Model/event/actions';
import { getCustomTerms, getEvent } from '@Model/event/selectors';
import {
  getIframeParams,
  isEnglishEmbed,
} from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import isDataHydratedTransaction from '@Model/pools/selectors/isDataHydratedTransaction';
import { getLocation } from '@Model/router/selectors';
import { buyAndPayOnline } from '@Model/transaction/actions';

import Online from './Online.component';
import { getInitialValues } from './Online.selectors';
import {
  IBuyingOnlineFromDispatch,
  IBuyingOnlineFromState,
  IBuyingOnlineOwnProps,
} from './Online.types';

const mapStateToProps = (state: _Store.IState): IBuyingOnlineFromState => ({
  currentBasketData: getBasketData(state),
  customTerms: getCustomTerms(state),
  errors: translate(state)('errors'),
  eventSlug: getEvent(state)?.eventSlug,
  initialValues: (basketCustomTerms) =>
    getInitialValues(state, basketCustomTerms),
  isDataHydratedTransaction: isDataHydratedTransaction(state),
  isEmbed: !!getIframeParams(state),
  isEnglishEmbed: isEnglishEmbed(state),
  isWebview: getLocation(state)?.query?.isWebview === 'true',
  queue: getEvent(state)?.queue,
  rundateSlug: getEvent(state)?.rundateSlug,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IBuyingOnlineFromDispatch => {
  return {
    buyAndPay: (data) => dispatch(buyAndPayOnline(data)),
    checkout: () => dispatch(validateForm()),
    mounted: () => dispatch(buyFormMounted()),
    updateBasketState: (basketState) =>
      dispatch(updateBasketStateFromContext(basketState)),
  };
};

export default connect<
  IBuyingOnlineFromState,
  IBuyingOnlineFromDispatch,
  IBuyingOnlineOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Online);
