import { createSelector } from 'reselect';

import _Store from '@Store';

import { HOME } from '@Routes/modules';

import { IInternalRouterModule, IInternalRouterReducer } from './../types';
import getModule from './getModule';

const isHome = createSelector<_Store.IState, IInternalRouterModule, boolean>(
  [getModule],
  (module) => module === HOME
);

export default isHome;
