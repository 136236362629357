import routes from '@/routes/routes';

/**
 * Function to translate polish to english route
 *
 * @param {string} link - Link to translate
 * @param {boolean} isEnglish - translate or not
 *
 *
 * @return {string} english link
 */
const translateEngLink = (link: string, isEnglish: boolean) => {
  return isEnglish
    ? link?.replace(routes.event.split('/')[1], routes.engEvent.split('/')[1])
    : link;
};

export default translateEngLink;
