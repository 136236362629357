import { ActionType, StateType } from 'typesafe-actions';

import { IEvent } from '@Model/events/types';
import { IFacetsResponse, IRundateCategory } from '@Services/$events-api/types';

import * as actions from './../actions';
import reducer from './../reducer';

export enum HitFields {
  _score = '_score',
  address = 'address',
  app_image = 'app_image',
  artists_ids = 'artists_ids',
  artists_names = 'artists_names',
  button_label = 'button_label',
  calendar_event = 'calendar_event',
  change_monitor_name = 'change_monitor_name',
  city_id = 'city_id',
  city_name = 'city_name',
  city_slug = 'city_slug',
  description_en = 'description_en',
  description_pl = 'description_pl',
  empik_premium = 'empik_premium',
  end_date = 'end_date',
  event_category_id = 'event_category_id',
  event_category_name = 'event_category_name',
  event_category_slug = 'event_category_slug',
  event_slug = 'event_slug',
  facet_event_category = 'facet_event_category',
  facet_event_category_slug = 'facet_event_category_slug',
  facet_public_tags_names = 'facet_public_tags_names',
  geolocation = 'geolocation',
  has_pools = 'has_pools',
  horizontal_image = 'horizontal_image',
  locations_ids = 'locations_ids',
  locations_names = 'locations_names',
  partner_id = 'partner_id',
  partner_name = 'partner_name',
  partner_premium_level = 'partner_premium_level',
  place_id = 'place_id',
  place_name = 'place_name',
  place_premium_level = 'place_premium_level',
  place_slug = 'place_slug',
  place_thumb = 'place_thumb',
  price = 'price',
  price_description_pl = 'price_description_pl',
  price_value = 'price_value',
  private_tags_ids = 'private_tags_ids',
  private_tags_names = 'private_tags_names',
  public_tags_ids = 'public_tags_ids',
  public_tags_names = 'public_tags_names',
  redirect_to_url = 'redirect_to_url',
  rundate_id = 'rundate_id',
  rundate_not_for_sale = 'rundate_not_for_sale',
  rundate_rundate = 'rundate_rundate',
  rundate_enddate = 'rundate_enddate',
  rundate_rundate_description = 'rundate_rundate_description',
  rundate_slug = 'rundate_slug',
  sales_channels_ids = 'sales_channels_ids',
  square_image = 'square_image',
  title_en = 'title_en',
  title_pl = 'title_pl',
}

export interface ISearchHighlight {
  count: number;
  key:
    | string
    | 'event_category_name'
    | 'place_name'
    | 'city_name'
    | 'partner_name'
    | 'artists_names'
    | 'public_tags_names'
    | 'private_tags_names';
  value: string;
}

export interface ISearchSuccessPayload {
  highlights: ISearchHighlight[];
  facets: IFacetsResponse;
  offset: number;
  results: IEvent[];
}

export type IFacetsSuccessPayload = Omit<
  ISearchSuccessPayload,
  'highlights' | 'offset' | 'results'
>;

export interface ISearchFilter {
  key: string;
  value: string;
  preventResetPhrase?: boolean;
  lastFilterName?: string;
}

export interface ISearchDates {
  from: number | null;
  to: number | null;
  lastFilterName?: string;
}

export interface ISearchReducer {
  datesFilter: ISearchDates;
  categories: IRundateCategory[];
  tags: IRundateCategory[];
  filters: ISearchFilter[];
  isLoading: boolean;
  searchPaginationOffset: number;
  searchPhrase: string;
  searchHighlights: ISearchHighlight[];
  searchResults: IEvent[];
  tileView: boolean;
  lastFilter: string;
}

export type ISearchQueryJoinMethod = 'AND' | 'OR';

export type ISearchSortOrder = 'asc' | 'desc';

export interface ISearchSortingElement {
  Priority: number;
  field: HitFields;
  sort: ISearchSortOrder;
}

export type ISearchSorting = ISearchSortingElement[];

export type IState = StateType<typeof reducer>;
export type IAction = ActionType<typeof actions>;
