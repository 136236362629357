import { createSelector } from 'reselect';

import config from '@Config';
import { getPlace } from '@Model/place/selectors';
import { getLocation } from '@Model/router/selectors';

import * as TEXTS from './../constants/texts';
import { IMeta, IMetaValue } from './../types';

const placePageMeta = createSelector(
  [getLocation, getPlace],
  (location, place): IMeta => {
    let pageName: IMetaValue = TEXTS.LOADING;
    let description: IMetaValue = TEXTS.LOADING;
    let ogDescription: IMetaValue = TEXTS.LOADING;

    const isEmpik = config.theme.isEmpik;

    if (place) {
      pageName = [
        place.shortName,
        place.city,
        isEmpik ? TEXTS.PLACE : TEXTS.EVENTS,
      ];

      description = [place.name, TEXTS.PLACE, place.address, place.city];

      ogDescription = [place.name, TEXTS.PLACE, place.address, place.city];
    }

    return {
      currentUrl: `${config.app.baseUrl}${location.pathname}`,
      description,
      ogDescription,
      pageName,
    };
  }
);

export default placePageMeta;
