import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  IFacetsSuccessPayload,
  ISearchDates,
  ISearchFilter,
  ISearchSuccessPayload,
} from '@Model/search/types';

import * as CONSTS from './../constants/actions';

// Public actions
export const searchMounted = createStandardAction(CONSTS.MOUNTED)();

export const baseSearch = createStandardAction(CONSTS.BASE_SEARCH)<{
  offset: number;
  isFuzzySearch?: boolean;
}>();

export const getFacets = createStandardAction(CONSTS.GET_FACETS)();

export const setSearchPhrase = createStandardAction(
  CONSTS.SET_SEARCH_PHRASE
)<string>();

export const setSearchFilter = createStandardAction(
  CONSTS.SET_FILTER
)<ISearchFilter>();

export const resetFilters = createStandardAction(CONSTS.RESET_FILTERS)();

export const setDateFilter = createStandardAction(
  CONSTS.SET_DATE_FILTER
)<ISearchDates>();

export const setLastFilter = createStandardAction(
  CONSTS.SET_LAST_FILTER
)<string>();

export const removeSearchFilter = createStandardAction(
  CONSTS.REMOVE_FILTER
)<ISearchFilter>();

export const removeDateFilter = createStandardAction(
  CONSTS.REMOVE_DATE_FILTER
)();

export const changeCityFilter = createStandardAction(
  CONSTS.CHANGE_CITY_FILTER
)<string>();

export const switchView = createStandardAction(CONSTS.SWITCH_VIEW)();

export const searchStructureMounted = createStandardAction(
  CONSTS.SEARCH_STRUCTURE_MOUNTED
)();

export const setSearchOffset = createStandardAction(CONSTS.SET_SEARCH_OFFSET)();

export const resetSearchOffset = createStandardAction(
  CONSTS.RESET_SEARCH_OFFSET
)();

// Private actions
export const fetchSearchResults = createAsyncAction(
  CONSTS._GET_BASE_SEARCH_RESULTS_REQUEST,
  CONSTS._GET_BASE_SEARCH_RESULTS_SUCCESS,
  CONSTS._GET_BASE_SEARCH_RESULTS_FAILURE
)<{ offset: number; isFuzzySearch?: boolean }, ISearchSuccessPayload, Error>();

export const fetchFacets = createAsyncAction(
  CONSTS._GET_FACETS_REQUEST,
  CONSTS._GET_FACETS_SUCCESS,
  CONSTS._GET_FACETS_FAILURE
)<undefined, IFacetsSuccessPayload, Error>();
