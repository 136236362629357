import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { Field, Form, Formik, FormikValues } from 'formik';
import { animated as a, useSpring } from 'react-spring';

import Term from '@Compo/buying/components/Term';
import Checkbox from '@Compo/forms/Checkbox';
import FormField from '@Compo/forms/FormField';
import SimpleLoading from '@Compo/reusable/SimpleLoading';
import useOutsideClick from '@Misc/hooks/useOutsideClick';

import styles from './Login.module.scss';
import { ILoginProps } from './Login.types';

const TERM_FIELD_NAME = 'terms';
const LOGIN_OR_REGISTER_TEXT = 'Zaloguj się lub stwórz konto';
const BUTTON_TEXT = 'Dalej';
const CHECK_EMAIL_TEXT =
  'Podaj swój email, aby się zalogować, lub utworzyć konto.';

const Login = ({ show, submitEmail, toggle }: ILoginProps) => {
  const ref = useRef(null);
  const [animate, toggleAnimation] = useState(show);
  const boxAnimation: CSSProperties = useSpring({
    opacity: animate ? 1 : 0,
    transform: animate ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
  });

  const handleToggle = () => toggleAnimation(!animate);

  useOutsideClick(ref, show, handleToggle);

  const handleSubmit = (values: FormikValues) => {
    submitEmail(values.email);
  };

  useEffect(() => {
    if (show && !animate) {
      toggleAnimation(show);
    }
  }, [show]);

  useEffect(() => {
    if (!animate && show) {
      setTimeout(() => {
        toggle();
      }, 1000);
    }
  }, [animate]);

  return show ? (
    <div className={styles.loginModal}>
      <a.div className={styles.loginBox} ref={ref} style={boxAnimation}>
        <i className={styles.closeLogin} onClick={handleToggle}>
          ✖︎
        </i>
        <Formik
          initialValues={{
            email: '',
            terms: false,
          }}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form className={styles.loginForm}>
              <h2 className={styles.loginHeader}>{LOGIN_OR_REGISTER_TEXT}</h2>
              <p className={styles.emailInfo}>{CHECK_EMAIL_TEXT}</p>
              <fieldset className={styles.loginFields}>
                <FormField
                  id={'email'}
                  name={'email'}
                  placeholder={'e-mail'}
                  type={'email'}
                />
              </fieldset>
              <button
                className={styles.submitLogin}
                type={'submit'}
                disabled={!props.values.terms}
              >
                {props.isSubmitting ? (
                  <SimpleLoading relative={true} />
                ) : (
                  BUTTON_TEXT
                )}
              </button>
              <Field
                component={Checkbox}
                name={TERM_FIELD_NAME}
                id={TERM_FIELD_NAME}
              >
                <Term />
              </Field>
            </Form>
          )}
        </Formik>
      </a.div>
    </div>
  ) : null;
};

export default Login;
