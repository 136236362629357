import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  generateAndSendSmsCode,
  handleNextStep,
  handlePrevStep,
  requestToRegisterNewSubmission,
} from '@Model/entryList/actions';
import { getSmsCode } from '@Model/entryList/selectors';

import SmsCode from './SmsCode.component';
import { ISmsCodeFromDispatch, ISmsCodeFromState } from './SmsCode.types';

const mapStateToProps = (state: _Store.IState): ISmsCodeFromState => ({
  smsCode: getSmsCode(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISmsCodeFromDispatch => ({
  handleNextStep: (step) => dispatch(handleNextStep(step)),
  handlePrevStep: () => dispatch(handlePrevStep()),
  saveSubmission: () => dispatch(requestToRegisterNewSubmission()),
  generateAndSendSmsCode: (code) => dispatch(generateAndSendSmsCode(code)),
});

export default connect<
  ISmsCodeFromState,
  ISmsCodeFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(SmsCode);
