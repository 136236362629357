import { useLayoutEffect } from '@Misc/hooks/useIsomorphicLayoutEffect';

import { IBreadcrumbProps } from './Breadcrumb.types';

const Breadcrumb = ({ current, set }: IBreadcrumbProps): null => {
  useLayoutEffect(() => {
    set(current);
  });

  return null;
};

export default Breadcrumb;
