import React, { useEffect, useState } from 'react';

import { Grid, useTheme } from '@material-ui/core';
import cn from 'classnames';

import styles from '@Compo/Happening/Happening.module.scss';
import { getSelectedPriceType } from '@Misc/helpers/happening/getSelectedPriceType';
import usePreviousState from '@Misc/hooks/usePreviousState';

import useStyles from './PriceTypes.styles';
import { IHappeningPriceType, IPriceTypesProps } from './PriceTypes.types';
import PriceType from './components/PriceType';

const PriceTypes = ({
  availabilities,
  calculatePricePerPerson,
  happening,
  happeningSpaces,
  selectedSpace,
  selectedSlot,
  setOnlyOnePrice,
  setPricesInState,
  selectedTheme,
  eventRemoveFromCartHappening,
  eventAddToCartHappening,
}: IPriceTypesProps) => {
  const prevSpace = usePreviousState(selectedSpace);
  const [allHappeningPriceTypes, setAllHappeningPriceTypes] = useState<
    IHappeningPriceType[]
  >([]);
  const isDefaultTheme = selectedTheme === 'default';
  const availabilitiesSelectedSlot = availabilities.find(
    (slot) => selectedSlot === slot.start
  );
  const availabilitiesSelectedSlotSpace =
    availabilitiesSelectedSlot?.spaces.find(
      (space) => selectedSpace === space.spaceId
    );
  const isNotCalculatedPerPersonAndHasOnePriceType =
    !calculatePricePerPerson && allHappeningPriceTypes.length === 1;

  const selectedSlotPriceTypes = () => {
    const getAdditionalDataByPriceType = (priceType: string) => {
      const foundedPriceType = allHappeningPriceTypes.find(
        (price) => price.type === priceType
      );
      if (foundedPriceType) {
        return {
          title: foundedPriceType.title,
          titleEN: foundedPriceType.titleEN,
        };
      }

      return { title: '', titleEN: '' };
    };

    return availabilitiesSelectedSlotSpace?.prices.map((slotPrice) => ({
      ...slotPrice,
      title: getAdditionalDataByPriceType(slotPrice.type).title,
      titleEN: getAdditionalDataByPriceType(slotPrice.type).titleEN,
    }));
  };

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  useEffect(() => {
    if (isNotCalculatedPerPersonAndHasOnePriceType && selectedSpace !== -1) {
      setOnlyOnePrice({
        numberOfPlayers: 1,
        priceType: getSelectedPriceType(
          allHappeningPriceTypes[0].type,
          selectedSlotPriceTypes()
        ),
      });
    }
    if (
      isNotCalculatedPerPersonAndHasOnePriceType &&
      prevSpace !== selectedSpace &&
      prevSpace !== -1 &&
      !!allHappeningPriceTypes.length
    ) {
      eventRemoveFromCartHappening({
        price: getSelectedPriceType(
          allHappeningPriceTypes[0].type,
          selectedSlotPriceTypes()
        ).value,
        currency: 'PLN',
      });
    }

    if (
      isNotCalculatedPerPersonAndHasOnePriceType &&
      selectedSpace !== -1 &&
      !!allHappeningPriceTypes.length
    ) {
      eventAddToCartHappening({
        price: getSelectedPriceType(
          allHappeningPriceTypes[0].type,
          selectedSlotPriceTypes()
        ).value,
        currency: 'PLN',
      });
    }
  }, [happening, selectedSpace]);

  useEffect(() => {
    const priceTypesArray: IHappeningPriceType[] = [];
    happeningSpaces?.forEach((space) =>
      space.rulePrice.forEach((priceRule) =>
        priceRule.prices.forEach((price) => {
          if (
            price.title &&
            allHappeningPriceTypes.findIndex(
              (priceType) => priceType.type === price.type
            ) === -1
          ) {
            priceTypesArray.push({
              counter: price.counter || 1,
              description: price.description,
              descriptionEN: price.descriptionEN,
              title: price.title,
              titleEN: price.titleEN,
              type: price.type,
            });
          }
        })
      )
    ); // all Happening priceType type names are collected and pushed into a separate array for display.
    const filteredPriceTypesArray = priceTypesArray.filter(
      (v, i, a) => a.findIndex((t) => t.type === v.type) === i
    );
    setAllHappeningPriceTypes(filteredPriceTypesArray);
    setPricesInState(filteredPriceTypesArray);
  }, []);

  if (isNotCalculatedPerPersonAndHasOnePriceType) return null;

  return (
    <>
      <div
        className={cn(
          styles.firstBox,
          styles.box,
          muiStyles.root,
          !isDefaultTheme && muiStyles.withoutPadding
        )}
      >
        <Grid container={true} direction={'column'} spacing={4}>
          {allHappeningPriceTypes.length > 0 &&
            allHappeningPriceTypes.map((priceType) => (
              <PriceType
                priceType={priceType}
                selectedSlotPriceTypes={selectedSlotPriceTypes()}
                key={priceType.type}
              />
            ))}
        </Grid>
      </div>
    </>
  );
};

export default PriceTypes;
