import React, { FC, useEffect } from 'react';

import Bricks from '@Compo/Bricks';
import Helmet from '@Compo/helmet/components/CompositionPage';
import MuiGrid from '@Compo/layout/MuiGrid';

import { IInspirationsProps } from './Inspirations.types';

const Inspirations: FC<IInspirationsProps> = ({
  mounted,
  structure,
  userLocation,
}) => {
  useEffect(() => {
    mounted();
  }, []);

  return (
    <MuiGrid withoutSidePaddings={true}>
      <Helmet />
      <Bricks structure={structure} userLocation={userLocation} />
    </MuiGrid>
  );
};

export default Inspirations;
