export const MOUNTED = 'Empik/pools/MOUNTED';
export const SELECT_POOL = 'Empik/pools/SELECT_POOL';
export const SELECT_TICKET = 'Empik/pools/SELECT_TICKET';
export const SELECT_SEAT = 'Empik/pools/SELECT_SEAT';
export const DESELECT_SEAT = 'Empik/pools/DESELECT_SEAT';
export const RESET_TICKETS_STATE = 'Empik/pools/RESET_TICKETS_STATE';
export const SET_FINAL_PRICE = 'Empik/pools/SET_FINAL_PRICE';
export const SET_PAYMENT_METHODS = 'Empik/pools/SET_PAYMENT_METHODS';

// Private
export const _GET_REQUEST = 'Empik/pools/_GET_REQUEST';
export const _GET_SUCCESS = 'Empik/pools/_GET_SUCCESS';
export const _GET_FAILURE = 'Empik/pools/_GET_FAILURE';

export const _GET_CLOSEST_POOLS_REQUEST =
  'Empik/pools/_GET_CLOSEST_POOLS_REQUEST';
export const _GET_CLOSEST_POOLS_SUCCESS =
  'Empik/pools/_GET_CLOSEST_POOLS_SUCCESS';
export const _GET_CLOSEST_POOLS_FAILURE =
  'Empik/pools/_GET_CLOSEST_POOLS_FAILURE';

export const _RESET_STATE = 'Empik/pools/_RESET_STATE';
export const _UPDATE_SELECTED_TICKETS = 'Empik/pools/_UPDATE_SELECTED_TICKETS';
export const _UPDATE_SELECTED_SEATS = 'Empik/pools/_UPDATE_SELECTED_SEATS';
