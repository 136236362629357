import { getType } from 'typesafe-actions';

import { getTransactionDetails, unmount } from '@Model/transaction/actions';
import { IAction, ITransactionDetailsReducer } from '@Model/transaction/types';

const reducer = (
  state: ITransactionDetailsReducer = null,
  action: IAction
): ITransactionDetailsReducer => {
  switch (action.type) {
    case getType(getTransactionDetails.success):
      return {
        ...state,
        ...action.payload,
      };
    // RESET_STORE
    case getType(unmount):
      return null;

    default:
      return state;
  }
};

export default reducer;
