import React from 'react';

import cn from 'classnames';

import isInInterval from '@Misc/helpers/date/isInInterval';
import prepareArrayForMonth from '@Misc/helpers/date/prepareArrayForMonth';

import styles from './../../DatePicker.module.scss';
import {
  IMonthHtmlProps,
  IMonthProps,
  IPrepareArrayFromMonth,
} from './../../DatePicker.types';

const TO = 'to';

const Month: IPrepareArrayFromMonth<IMonthProps> = ({
  chosen,
  currentDayHash,
  fromOrTo,
  hover,
  hoverFrom,
  hoverLeave,
  hoverTo,
  month,
  select,
  selectedFrom,
  selectedTo,
  year,
}) => {
  const data = prepareArrayForMonth(month, year, currentDayHash);
  const canModifySelectable = fromOrTo === TO && selectedFrom;

  return (
    <div onMouseLeave={hoverLeave}>
      {data.map((row, index) => (
        <div key={index} className={styles.week}>
          {row.map((dayObject) => {
            const { day, hash } = dayObject;
            let { selectable } = dayObject;

            // Limitation of typescript, optimization rule
            // @ts-ignore
            if (canModifySelectable && hash && hash < selectedFrom) {
              selectable = false;
            }

            const css = {
              [styles.chosen]: chosen && chosen === hash,
              [styles.current]: hash && hash === currentDayHash,
              [styles.hovered]: isInInterval(hoverFrom, hoverTo, hash),
              [styles.selectable]: selectable,
              [styles.selected]: isInInterval(selectedFrom, selectedTo, hash),
            };

            const monthHtmlProps: IMonthHtmlProps = {
              className: cn(styles.day, css),
              key: (hash || day).toString(),
              role: 'button',
            };

            if (selectable && hash) {
              monthHtmlProps.onClick = () => {
                select(hash);
              };

              monthHtmlProps.onMouseEnter = () => {
                hover(hash);
              };
            }

            if (!selectable) {
              monthHtmlProps.onMouseEnter = () => {
                hoverLeave();
              };
            }

            // Prop 'key' is in monthHtmlProps
            // tslint:disable-next-line
            return <div {...monthHtmlProps}>{dayObject.day}</div>;
          })}
        </div>
      ))}
    </div>
  );
};

export default Month;
