import React from 'react';

import Artist from '@Compo/Artist';
// TODO make proper Helmet to the Artist
import Helmet from '@Compo/helmet/components/ArtistPage';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import { IBreadcrumb } from '@Model/breadcrumb/types';

const ARTIST_BREADCRUMB = 'Artyści';
const breadcrumbs: IBreadcrumb[] = [
  {
    link: '#',
    name: ARTIST_BREADCRUMB,
  },
];

const ArtistPage = () => (
  <MuiGrid>
    <Helmet />
    <MuiSectionContainer>
      <MuiBreadcrumbs base={breadcrumbs} />
      <Artist />
    </MuiSectionContainer>
  </MuiGrid>
);

export default ArtistPage;
