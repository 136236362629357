import React, { FC } from 'react';

import styles from './MainPageTop.module.scss';
import { IMainPageTopProps } from './MainPageTop.types';

const MainPageTop: FC<IMainPageTopProps> = ({ children, title }) => (
  <div className={styles.top}>
    <div className={styles.background} />
    {title && <h1 className={styles.title}>{title}</h1>}
    {children}
  </div>
);

export default MainPageTop;
