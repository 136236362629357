import React from 'react';

import CtaButton from '@Compo/reusable/CtaButton/CtaButton.component';
import SimpleLoading from '@Compo/reusable/SimpleLoading';

import { ICtaButtonBrickProps } from './CtaButtonsBrick.types';

const CtaButtonsBrick = ({
  id,
  data,
  isLoading,
  compositionIdentifier,
}: ICtaButtonBrickProps) => {
  if (isLoading || !id) {
    return <SimpleLoading />;
  }

  return (
    <div
      id={id}
      style={{
        width: '100%',
      }}
    >
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            textAlign: item.align,
            width: '100%',
          }}
        >
          <CtaButton
            {...item}
            compositionIdentifier={compositionIdentifier}
            brickId={id}
          />
        </div>
      ))}
    </div>
  );
};

export default CtaButtonsBrick;
