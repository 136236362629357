const onlyServerRoutes = {
  sitemap: '/sitemap.xml',
  sitemapActivities: '/activities.xml',
  sitemapArtists: '/sitemap-artists.xml',
  sitemapEvents: '/sitemap-events.xml',
  sitemapEventsPage: '/sitemap-events-:page.xml',
  sitemapEventsPath: 'sitemap',
  sitemapGenerate: '/generate-sitemap',
  sitemapInspirations: '/sitemap-inspirations.xml',
  sitemapMainPages: '/sitemap-main-pages.xml',
  sitemapPages: '/sitemap-pages.xml',
  sitemapPath: 'sitemap',
  sitemapPlaces: '/sitemap-places.xml',
  sitemapPlacesPage: '/sitemap-places-:page.xml',
  sitemapPlacesPath: 'sitemap',
  sitemapRecommend: '/sitemap-recommend.xml',
  sitemapRedirects: '/sitemap-redirects.xml',
  sitemapSelections: '/sitemap-selections.xml',
};

const embedComponentsRoutes = {
  pinBuy: '/buying/:eventSlug?/:rundateSlug?',
  pinBuyId: '/buying-id/:id',
  pinChangeDate: '/date-management/:ticketId',
  pinEvent: '/event/:eventSlug/:rundateSlug',
  pinEventId: '/event-id/:id',
  pinEvents: '/embed',
  pinForm: '/formio/:slug',
  pinHappening: '/buy-happening/:slug',
  pinMyTicket: '/ticket-management',
  pinMyTicketEdit: '/ticket-management/:ticketId',
  pinProducts: '/buy-products/:slug',
  pinReceiveSummary: '/transfer-ticket/received',
  pinReceiveTicket: '/accept-ticket/:slug',
  pinTransferSuccess: '/transfer-ticket/success',
  pinTransferTicket: '/transfer-ticket/:ticketId',
  pinEntryList: '/entry-list',
};

const engRoutes = {
  engBuy: '/buy-ticket/:eventSlug/:rundateSlug',
  engBuyActivity: '/buy-ticket/:slug',
  engEvent: '/event/:eventSlug/:rundateSlug?',
  engPayment: '/payment/:transactionId',
  engPaymentFail: '/payment/:transactionId/failure',
  engPaymentSuccess: '/payment/:transactionId/success',
};

const routes = {
  about: '/o-aplikacji',
  activities: '/aktywnosci/:slug',
  activitiesDefault: '/aktywnosci',
  activity: '/aktywnosc/:eventSlug',
  artist: '/artysta/:slug',
  auth: '/autoryzacja/:token',
  basket: '/koszyk',
  basketBuy: '/koszyk-platnosc',
  betaEvent: '/betaevent/:eventSlug/:rundateSlug',
  booking: '/rezerwacja/:slug',
  buy: '/kup-bilety/:eventSlug/:rundateSlug',
  buyActivity: '/kup-bilety/:eventSlug',
  buyLegacy: '/buy/:id',
  buyProducts: '/kup-produkty/:slug',
  changeDate: '/moj-bilet/zmien-date/:ticketId',
  contact: '/kontakt/formularz-kontaktowy',
  deeplinkTicket: '/ticket/:ticketId',
  discount: '/kod-rabatowy',
  entryList: '/lista-wejsciowa',
  event: '/wydarzenie/:eventSlug/:rundateSlug?',
  eventLegacy: '/evt/:id/:eventSlug?',
  events: '/wydarzenia',
  form: '/form/:slug',
  giftCardActivation: '/karta-prezentowa',
  happening: '/kup-bilety/:slug',
  index: '/',
  inspirations: '/inspiracje/:slug',
  inspirationsDefault: '/inspiracje',
  landingPage: '/lp/:landingSlug',
  mainPage: '/miasto/:slug',
  myTicket: '/moj-bilet',
  myTicketEdit: '/moj-bilet/:ticketId',
  passwordReset: '/resetuj-haslo/:token/:languageParam',
  payment: '/rezultat-platnosci/:transactionId',
  paymentFail: '/rezultat-platnosci/:transactionId/platnosc-nieudana',
  paymentSuccess: '/rezultat-platnosci/:transactionId/platnosc-udana',
  place: '/miejsce/:slug',
  placeLegacy: '/place/:id/:key?/:slug?',
  places: '/miejsca',
  receiveTicket: '/odbierz-bilet/:slug',
  receiveTicketFail: '/odbierz-bilet/blad/:slug',
  receiveTicketSuccess: '/odbierz-bilet/odebrano/:slug',
  recommend: '/polecamy/:slug/:slug?',
  selection: '/selekcja/:slug',
  selectionLegacy: '/selection/:id/:slug?',
  sendMailSuccess: '/wyslij-mailem/:id/wyslano',
  staticArticle: '/artykul/:sourceSlug/:articleSlug',
  transferSuccess: '/przekaz-bilet/przekazano',
  transferTicket: '/przekaz-bilet/:ticketId',

  ...onlyServerRoutes,
  ...embedComponentsRoutes,
  ...engRoutes,
};

export default routes;
