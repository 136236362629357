import { getType } from 'typesafe-actions';

import { setFinalPrice } from './../../actions';
import { IAction } from './../../types';

export const initialState: number = 0;

const reducer = (state: number = initialState, action: IAction): number => {
  switch (action.type) {
    case getType(setFinalPrice):
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
