import { IEmbedBrickOwnProps } from '@Compo/Bricks/components/EmbedBrick/EmbedBrick.types';
import {
  ISectionsComponentDataMutable,
  ISectionsComponentEmbed,
} from '@Model/pages/types/sections';

export const embed = (
  data: ISectionsComponentDataMutable
): IEmbedBrickOwnProps => {
  const embedData = data as ISectionsComponentEmbed[];
  const embedElement = embedData[0];

  return {
    embed: embedElement,
  };
};
