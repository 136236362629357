import { connect } from 'react-redux';

import _Store from '@Store';

import { isBrowser } from '@Model/__base__/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';
import { getCardCredentials, getData } from '@Model/transaction/selectors';

import PayURedirect from './RedirectForCardPayment.component';
import {
  IRedirectForCardPaymentData,
  IRedirectForCardPaymentProps,
} from './RedirectForCardPayment.types';

const mapStateToProps = (
  state: _Store.IState
): IRedirectForCardPaymentProps => ({
  data: getData(state) as IRedirectForCardPaymentData,
  isBrowser: isBrowser(state) && !getIframeParams(state),
  isCardPayment: !!getCardCredentials(state),
});

export default connect<IRedirectForCardPaymentProps, null, {}, _Store.IState>(
  mapStateToProps
)(PayURedirect);
