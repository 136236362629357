import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    tooltip: {
      position: 'relative',
      top: '0.25rem',
      left: '0.25rem',
    },
  })
);

export default useStyles;
