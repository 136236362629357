import React, { FC } from 'react';

import Summary from '@Compo/eventBoxes/Summary/Summary.component';

import styles from './WithEventDetailBox.module.scss';
import { IWithEventDetailBoxProps } from './WithEventDetailBox.types';

const EventDetailBox: FC<IWithEventDetailBoxProps> = ({
  children,
  dateTranslate,
  event,
  isDesktop,
  isEmbed,
  isLoading,
  lang,
  langBasket,
  receivedEvent,
  specialEventCopy,
  summary,
  ticketTransferFee,
  receivedTickets,
  language,
}) => {
  return (
    <div className={styles.twoColumns}>
      <div className={styles.fixedBox}>
        <Summary
          dateTranslate={dateTranslate}
          event={event || receivedEvent}
          isLoading={isLoading}
          isDesktop={isDesktop}
          isEmbed={isEmbed}
          lang={lang}
          langBasket={langBasket}
          products={[]}
          receivedTickets={receivedTickets}
          summary={summary}
          specialEventCopy={specialEventCopy}
          selectedTickets={[]}
          selectedProducts={[]}
          transferTicketFee={ticketTransferFee}
          isTransferTicket={true}
          selectedTheme={'default'}
          shipments={[]}
          language={language}
          selectedExtendedSlot={null}
          setFinalPriceAction={() => 0}
          setInsuranceSummaryData={() => {}}
        />
      </div>

      <div className={styles.form}>{children}</div>
    </div>
  );
};

export default EventDetailBox;
