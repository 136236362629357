import config from '@Config';
import checkFilterDuplicate from '@Misc/helpers/checkFilterDuplicate';
import { ISearchFilter, ISearchHighlight } from '@Model/search/types';
import icon_artist_going from '@Styles/images/icon_highlight_artist.svg';
import icon_category_going from '@Styles/images/icon_highlight_category.svg';
import icon_city_going from '@Styles/images/icon_highlight_city.svg';
import icon_artist_empik from '@Styles/images/icon_highlight_empik_artist.svg';
import icon_category_empik from '@Styles/images/icon_highlight_empik_category.svg';
import icon_city_empik from '@Styles/images/icon_highlight_empik_city.svg';
import icon_locations_empik from '@Styles/images/icon_highlight_empik_location.svg';
import icon_partner_empik from '@Styles/images/icon_highlight_empik_partner.svg';
import icon_place_empik from '@Styles/images/icon_highlight_empik_place.svg';
import icon_tags_empik from '@Styles/images/icon_highlight_empik_tags.svg';
import icon_locations_going from '@Styles/images/icon_highlight_location.svg';
import icon_partner_going from '@Styles/images/icon_highlight_partner.svg';
import icon_place_going from '@Styles/images/icon_highlight_place.svg';
import icon_tags_going from '@Styles/images/icon_highlight_tags.svg';

const icon_artist = config.theme.isGoing
  ? icon_artist_going
  : icon_artist_empik;
const icon_category = config.theme.isGoing
  ? icon_category_going
  : icon_category_empik;
const icon_city = config.theme.isGoing ? icon_city_going : icon_city_empik;
const icon_locations = config.theme.isGoing
  ? icon_locations_going
  : icon_locations_empik;
const icon_partner = config.theme.isGoing
  ? icon_partner_going
  : icon_partner_empik;
const icon_place = config.theme.isGoing ? icon_place_going : icon_place_empik;
const icon_tags = config.theme.isGoing ? icon_tags_going : icon_tags_empik;

export const filterHighlights = (
  highlights: ISearchHighlight[],
  activeFilters: ISearchFilter[]
) => {
  const removedActive = highlights.filter(
    (highlight) => !checkFilterDuplicate(activeFilters, highlight)
  );

  const artists = removedActive
    .filter((highlight) => highlight.key === 'artists_names')
    .splice(0, 2);
  const places = removedActive
    .filter((highlight) => highlight.key === 'place_name')
    .splice(0, 1);
  const categories = removedActive
    .filter((highlight) => highlight.key === 'event_category_name')
    .splice(0, 1);
  const cities = removedActive
    .filter((highlight) => highlight.key === 'city_name')
    .splice(0, 3);
  const locations = removedActive
    .filter((highlight) => highlight.key === 'locations_names')
    .splice(0, 2);
  const publicTags = removedActive
    .filter((highlight) => highlight.key === 'public_tags_names')
    .splice(0, 3);

  // STAGING ONLY:
  const tags = config.app.showUnfinishedFeatures
    ? removedActive
        .filter((highlight) => highlight.key === 'private_tags_names')
        .splice(0, config.aws.searchHighlightsCount)
    : [];
  const partners = config.app.showUnfinishedFeatures
    ? removedActive
        .filter((highlight) => highlight.key === 'partner_name')
        .splice(0, config.aws.searchHighlightsCount)
    : [];

  return [
    ...artists,
    ...publicTags,
    ...tags,
    ...places,
    ...categories,
    ...cities,
    ...partners,
    ...locations,
  ].sort((a, b) => (a.count < b.count ? 1 : -1));
};

export const translateHighlightName = (name: string) => {
  switch (name) {
    case 'event_category_name':
      return 'Kategoria';

    case 'place_name':
      return 'Miejsce';

    case 'city_name':
      return 'Miasto';

    case 'partner_name':
      return 'Organizacja';

    case 'artists_names':
      return 'Powiązany artysta';

    case 'public_tags_names':
      return 'Tag';

    case 'private_tags_names':
      return 'Tag prywatny';

    case 'locations_names':
      return 'Lokalizacja';

    default:
      return name;
  }
};

export const getHighlightIcon = (name: string) => {
  switch (name) {
    case 'event_category_name':
      return icon_category;

    case 'place_name':
      return icon_place;

    case 'city_name':
      return icon_city;

    case 'partner_name':
      return icon_partner;

    case 'artists_names':
      return icon_artist;

    case 'public_tags_names':
      return icon_tags;

    case 'private_tags_names':
      return icon_tags;

    case 'locations_names':
      return icon_locations;

    default:
      return icon_artist;
  }
};
