import {
  ISectionsComponentDataMutable,
  ISectionsCtaButton,
} from '@Model/pages/types/sections';

export const cta_button = (data: ISectionsComponentDataMutable) => {
  const cta = data as ISectionsCtaButton[];
  const buttonsData = {
    data: cta,
  };

  return buttonsData;
};
