import makeNumber from './makeNumber';

const tomorrow = (): number => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();

  const tomorrowDate = new Date(year, month, day + 1);

  day = tomorrowDate.getDate();
  month = tomorrowDate.getMonth();
  year = tomorrowDate.getFullYear();

  return makeNumber(year, month, day);
};

export default tomorrow;
