import {
  IDescriptionBrickOwnProps,
  IDescriptionExtra,
} from '@Compo/Bricks/components/DescriptionBrick/DescriptionBrick.types';
import {
  ISectionsComponentDataMutable,
  ISectionsComponentDescription,
} from '@Model/pages/types/sections';

export const description = (
  data: ISectionsComponentDataMutable,
  extra?: IDescriptionExtra | null
): IDescriptionBrickOwnProps => {
  const textData = data as ISectionsComponentDescription[];
  const text = textData[0].formattedDescription
    ? textData[0].formattedDescription
    : textData[0].description;

  return {
    color: extra?.color,
    text,
  };
};
