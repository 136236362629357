import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isMobile } from '@Model/app/selectors';
import { setSearchFilter } from '@Model/search/actions';

import Highlight from './Highlight.component';
import {
  IHighlightFromDispatch,
  IHighlightFromState,
  IHighlightOwnProps,
} from './Highlight.types';

const mapStateToProps = (state: _Store.IState): IHighlightFromState => ({
  isMobile: isMobile(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IHighlightFromDispatch => ({
  setFilter: (filter) => dispatch(setSearchFilter(filter)),
});

export default connect<
  IHighlightFromState,
  IHighlightFromDispatch,
  IHighlightOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Highlight);
