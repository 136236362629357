import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISelectionReducer, ISelectionReducerData } from './../types';
import get from './get';

const getData = createSelector<
  _Store.IState,
  ISelectionReducer,
  ISelectionReducerData
>([get], (selection) => selection.data);

export default getData;
