import { connect } from 'react-redux';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';
import { translate } from '@Model/locale/selectors';

import BasketItemPool from './BasketItemPool.component';
import { IBasketItemPoolFromState } from './BasketItemPool.types';

const mapStateToProps = (state: _Store.IState): IBasketItemPoolFromState => ({
  isDesktop: isDesktop(state),
  lang: Object.assign(
    translate(state)('basket'),
    translate(state)('buy', 'summary')
  ),
});

export default connect<IBasketItemPoolFromState, null, {}, _Store.IState>(
  mapStateToProps
)(BasketItemPool);
