import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { PagesEnum } from '@Model/pages/constants/pages';
import { ILocationsResponse } from '@Services/$locations-api/types';

import * as CONSTS from './../constants/actions';
import { ILocations, ISelectedLocation } from './../types';

export const getLocations = createStandardAction(
  CONSTS.GET_LOCATIONS
)<PagesEnum>();

export const clearLocation = createStandardAction(CONSTS.CLEAR_LOCATION)();

export const changeLocation = createStandardAction(CONSTS.CHANGE_LOCATION)<
  string,
  PagesEnum | null | undefined
>();

export const setDefaultLocation = createStandardAction(CONSTS.DEFAULT_LOCATION)<
  ILocationsResponse,
  PagesEnum
>();

export const setLocation = createStandardAction(
  CONSTS.SET_LOCATION
)<ISelectedLocation>();

export const getLocationsRequest = createAsyncAction(
  CONSTS._GET_LOCATIONS_REQUEST,
  CONSTS._GET_LOCATIONS_SUCCESS,
  CONSTS._GET_LOCATIONS_FAILURE
)<PagesEnum, ILocations, Error>();
