import React, { FC, useEffect } from 'react';

import YouTube from 'react-youtube';

import Breadcrumb from '@Compo/Breadcrumb';
import { default as HeartLoading } from '@Compo/Bricks/components/Loading';
import BuyBox from '@Compo/eventBoxes/Buy';
import Description from '@Compo/reusable/Description';
import Event from '@Compo/reusable/Event';
import Tags from '@Compo/reusable/Tags';
import DetailsLoading from '@Compo/reusable/loadings/DetailsLoading/DetailsLoading.component';
import Loading from '@Compo/reusable/loadings/EventLoading';
import TagsLoading from '@Compo/reusable/loadings/TagsLoading';
import TitleLoading from '@Compo/reusable/loadings/TitleLoading';
import getNonEmptyObject from '@Misc/helpers/getNonEmptyObject';
import goTrough from '@Misc/helpers/goTrough';
import scrollToTop from '@Misc/helpers/scrollToTop';
import { IEvent } from '@Model/events/types';
import routes from '@Routes/routes';

import styles from './Artist.module.scss';
import { IArtistProps } from './Artist.types.js';
import ArtistGallery from './ArtistGallery';
import PastEvents from './PastEvents';

export const UPCOMING_EVENTS_ID = 'upcomingEvents';
const SHOW_MORE_TEXT = 'pokaż więcej';
const LOADERS_COUNT = 3;

const Artist: FC<IArtistProps> = ({
  artist,
  closestEvents,
  closestPool,
  images,
  isEventsLoading,
  isLoading,
  isDesktop,
  isMobile,
  lang,
  mounted,
  history,
  location,
  params,
  pastEvents,
  resetState,
}) => {
  const artistDetails = getNonEmptyObject(artist);
  const closestPoolDetails = getNonEmptyObject(closestPool);
  const isVideo = !!artistDetails.videoId;

  useEffect(() => {
    mounted();

    return () => {
      resetState();
    };
  }, []);

  const cloudinaryImages = images ? images : null;
  const closestPoolImg = closestPoolDetails.imagesUrl;

  const loadMoreRedirect = () => {
    history.push(`${routes.events}?artists_names=${artist?.title}`);
    scrollToTop(location, history);
  };

  if (!artist) return <HeartLoading />;

  return (
    <>
      <Breadcrumb
        current={{
          isLoading,
          link: `/artysta/${params.slug}`,
          name: artistDetails.title,
        }}
      />
      <div className={styles.artist}>
        <div className={styles.header}>
          {isLoading && <TitleLoading />}
          {!isLoading && <h1>{artistDetails.title}</h1>}
        </div>

        <div className={styles.tagBar}>
          <div className={styles.tags}>
            {isLoading && <TagsLoading />}
            {!isLoading && !!artistDetails.tags?.length && (
              <Tags tags={artistDetails.tags} />
            )}
          </div>
        </div>

        <div className={styles.twoColumns}>
          {isMobile && (
            <BuyBox
              withFloatedBar={true}
              isArtist={true}
              favoriteId={artist.id}
            />
          )}

          {isDesktop && (
            <div className={styles.fixedBox}>
              <BuyBox isArtist={true} favoriteId={artist.id} />
            </div>
          )}

          <div className={styles.artistDetails}>
            {!isMobile && (
              <ArtistGallery
                images={
                  cloudinaryImages
                    ? cloudinaryImages.map((image) => {
                        return {
                          alt: artistDetails.title,
                          imgBig: image,
                          imgSmall: image,
                        };
                      })
                    : [
                        {
                          alt: artistDetails.title,
                          imgBig: closestPoolImg,
                          imgSmall: closestPoolImg,
                        },
                      ]
                }
                isLoading={isLoading}
                customClassImage={styles.customImage}
              />
            )}

            {isLoading && <DetailsLoading />}

            {!isLoading && (
              <>
                <Description
                  text={artistDetails.description}
                  isVideo={isVideo}
                  title={artistDetails.title}
                />
                {isDesktop && (
                  <div className={styles.tagBarDescription}>
                    <div className={styles.tags}>
                      {!isLoading && !!artistDetails.tags?.length && (
                        <Tags tags={artistDetails.tags} />
                      )}
                    </div>
                  </div>
                )}

                {artistDetails.videoId && (
                  <div className={styles.video}>
                    <div className={styles.videoCover}>
                      <YouTube
                        videoId={artistDetails.videoId}
                        containerClassName={styles.player}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {!!closestEvents.length && (
          <div
            className={styles.upcomingEventsContainer}
            id={UPCOMING_EVENTS_ID}
          >
            <h3 className={styles.title}>{lang.upcomingEvents}</h3>
            {!isEventsLoading &&
              closestEvents.map((event: IEvent) => (
                <Event key={event.id.toString()} {...event} />
              ))}
            {isEventsLoading &&
              goTrough(LOADERS_COUNT).map((key) => <Loading key={key} />)}
            <div className={styles.center}>
              {!isEventsLoading && closestEvents.length === 3 && (
                <button className={styles.more} onClick={loadMoreRedirect}>
                  {SHOW_MORE_TEXT}
                </button>
              )}
            </div>
          </div>
        )}
        {!!pastEvents.length && (
          <div className={styles.pastEvents}>
            <h3 className={styles.title}>{lang.pastEvents}</h3>
            <PastEvents artistId={artist?.id} />
          </div>
        )}
      </div>
    </>
  );
};

export default Artist;
