import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IPasswordResetPayload } from '@Compo/PasswordReset/PasswordReset.types';
import * as CONSTS from '@Model/passwordReset/constants/actions';
import { IPasswordResetFailure } from '@Model/passwordReset/types';
import { IPasswordResetResponse } from '@Services/$legacy-auth-api/types';

export const passwordReset = createAsyncAction(
  CONSTS.PASSWORD_RESET_REQUEST,
  CONSTS.PASSWORD_RESET_SUCCESS,
  CONSTS.PASSWORD_RESET_FAILURE
)<IPasswordResetPayload, IPasswordResetResponse, IPasswordResetFailure>();

export const passwordResetFormSubmit = createStandardAction(
  CONSTS.PASSWORD_RESET_FORM_SUBMIT
)<IPasswordResetPayload>();
