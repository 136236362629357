import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IGetEventByIdSuccessPayload } from '@Model/event/types';

import * as CONSTS from './../constants/actions';
import {
  IInternalRouterEmbedInitial,
  IInternalRouterEvent,
  IInternalRouterPayload,
  ISetModulePayload,
} from './../types';

export const locationChange = createStandardAction(
  CONSTS.LOCATION_CHANGE
)<IInternalRouterPayload>();

export const setModule = createStandardAction(
  CONSTS.SET_MODULE
)<ISetModulePayload>();

export const captureIframeEvent = createStandardAction(
  CONSTS.CAPTURE_EVENT_FROM_IFRAME
)<IInternalRouterEvent>();

export const initEmbed = createStandardAction(
  CONSTS.INIT_EMBED
)<IInternalRouterEmbedInitial>();

export const embedLoaded = createStandardAction(CONSTS.EMBED_LOADED)();
export const redirectParentTo = createStandardAction(
  CONSTS.REDIRECT_PARENT_TO
)<string>();

export const getRundateSlug = createAsyncAction(
  CONSTS._GET_RUNDATE_SLUG_REQUEST,
  CONSTS._GET_RUNDATE_SLUG_SUCCESS,
  CONSTS._GET_RUNDATE_SLUG_FAILURE
)<number, IGetEventByIdSuccessPayload, Error>();
