import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolsSelectedTicketsReducer } from './../types';
import getSelectedTickets from './getSelectedTickets';

const isAnyPoolSelectedHasForm = createSelector<
  _Store.IState,
  IPoolsSelectedTicketsReducer,
  boolean
>([getSelectedTickets], (selectedTickets: IPoolsSelectedTicketsReducer) => {
  return selectedTickets.some((ticket) => !!ticket.forms?.length);
});

export default isAnyPoolSelectedHasForm;
