import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISearchReducer } from '@Model/search/types';

import get from './get';

const getSearchLoadingStatus = createSelector<
  _Store.IState,
  ISearchReducer,
  boolean
>([get], (search) => search.isLoading);

export default getSearchLoadingStatus;
