import React, { useEffect } from 'react';

import Breadcrumb from '@Compo/Breadcrumb';
import Description from '@Compo/reusable/Description';
import Event from '@Compo/reusable/Event';
import Image from '@Compo/reusable/Image';
import Tags from '@Compo/reusable/Tags';
import Title from '@Compo/reusable/Title';
import DetailsLoading from '@Compo/reusable/loadings/DetailsLoading';
import EventLoading from '@Compo/reusable/loadings/EventLoading';
import TagsLoading from '@Compo/reusable/loadings/TagsLoading';
import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import routes from '@Routes/routes';

import styles from './StaticArticle.module.scss';
import { IArticle, IParagraph } from './StaticArticle.types';
import Authors from './components/Authors';

const URI_ARTICLE_SLUG = ':articleSlug';

const StaticArticle = ({ article, isArticleLoading, mounted }: IArticle) => {
  useEffect(() => {
    mounted();
  }, []);

  return (
    <>
      {article && (
        <Breadcrumb
          current={{
            isLoading: isArticleLoading,
            link: fillUrlWithValues(
              routes.staticArticle,
              [URI_ARTICLE_SLUG],
              [article.articleSlug]
            ),
            name: article.title,
          }}
        />
      )}

      <div className={styles.staticArticleWrapper}>
        <div className={styles.imageWrapper}>
          <Image imageUrl={article?.imageUrl} isLoading={isArticleLoading} />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <Title
              title={article?.title}
              isLoading={isArticleLoading}
              customClassName={styles.titleWrapper}
            />
            {!isArticleLoading &&
              article?.paragraphs?.map((paragraph: IParagraph, key: number) => {
                return (
                  <div
                    key={'paragraph-' + key}
                    className={styles.paragraphWrapper}
                  >
                    <Description
                      text={paragraph.description}
                      isFullWidth={true}
                      hideApostrophe={config.theme.isGoing ? true : key !== 0}
                    />
                    {paragraph.ticket && (
                      <Event noDescription={true} {...paragraph.ticket} />
                    )}
                  </div>
                );
              })}
            <Description
              html={article?.html}
              isFullWidth={true}
              hideApostrophe={true}
            />
          </div>
          {isArticleLoading && <DetailsLoading isFullWidth={true} />}
          {isArticleLoading && <EventLoading />}
          {config.theme.isEmpik && (
            <>
              <div className={styles.tagsWrapper}>
                {isArticleLoading && <TagsLoading />}
                {!isArticleLoading && article && article.tags && (
                  <Tags tags={article.tags} />
                )}
              </div>
              <Authors data={article?.authors} />
            </>
          )}
          {config.theme.isGoing && (
            <>
              <Authors data={article?.authors} />
              <div className={styles.tagsWrapper}>
                {isArticleLoading && <TagsLoading />}
                {!isArticleLoading && article && article.tags && (
                  <Tags tags={article.tags} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StaticArticle;
