import React from 'react';

import { Formik } from 'formik';

import { IParentData } from '@Model/entryList/types';
import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import stepNames from '../../steps';
import ParentFormLayout from '../ParentFormLayout';
import { makeValidationSchema } from './ParentData.selector';
import { IParentDataProps } from './ParentData.types';

const ParentData = ({
  initialValues,
  setParentData,
  handleNextStep,
  generateAndSendSmsCode,
}: IParentDataProps) => {
  const { entryList: lang } = useDictionary();

  const handleFormSubmit = (data: IParentData) => {
    setParentData(data);
    const generatedCode = Math.floor(1000 + Math.random() * 9000).toString();
    generateAndSendSmsCode(generatedCode);
    handleNextStep(stepNames.smsCode);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={makeValidationSchema(lang.validation)}
      onSubmit={handleFormSubmit}
      component={ParentFormLayout}
    />
  );
};
export default ParentData;
