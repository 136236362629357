import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  setCalculatedPrices,
  setOnlyOnePriceType,
  setPriceType,
  toggleSelectedUpsell,
} from '@Model/happening/actions';
import { getData, getSelected } from '@Model/happening/selectors';
import { getSelectedLocale, translate } from '@Model/locale/selectors';

import PriceType from './PriceType.component';
import { IPriceTypeFromDispatch, IPriceTypeFromState } from './PriceType.types';

const mapStateToProps = (state: _Store.IState): IPriceTypeFromState => {
  return {
    isCalculatedPerPerson: getData(state)?.calculatePricePerPerson,
    lang: translate(state)('happening', 'priceTypes'),
    selectedSpace: getSelected(state).space,
    selections: getSelected(state).selectionsPerPriceType,
    selectedLang: getSelectedLocale(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPriceTypeFromDispatch => {
  return {
    calculatePrice: (value) => dispatch(setCalculatedPrices(value)),
    selectPriceType: (price) => dispatch(setPriceType(price)),
    setOnlyOnePrice: (value) => dispatch(setOnlyOnePriceType(value)),
    toggleUpsellState: (value) => dispatch(toggleSelectedUpsell(value)),
  };
};

export default connect<
  IPriceTypeFromState,
  IPriceTypeFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PriceType);
