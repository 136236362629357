import { Selector } from 'reselect';

import _Store from '@Store';

import { IRouterFirstRenderingReducer } from './../types';

const isFirstRendering: Selector<
  _Store.IState,
  IRouterFirstRenderingReducer
> = (state) => state.routerIsFirstRendering;

export default isFirstRendering;
