import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEntryListReducer, IParentData } from './../types';
import get from './get';

const getParentData = createSelector<
  _Store.IState,
  IEntryListReducer,
  IParentData
>([get], (state) => state.parentData);

export default getParentData;
