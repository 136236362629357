const classes = {
  event: 'event-container',
  eventDetails: 'event-details',
  fixedBox: 'event-fixed-box',
  gaClass: 'baner-wydarzenie',
  header: 'event-header',
  oneColumn: 'event-one-column',
  tagBar: 'event-tag-bar',
  tags: 'event-tags',
  twoColumns: 'event-two-columns',
  video: 'event-video',
  videoCover: 'event-video-cover',
};

export default classes;
