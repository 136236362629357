import React from 'react';

import { Grid } from '@material-ui/core';

import phone from '@Styles/images/going-footer-phone-image.png';

import useStyles from './PhoneMockup.styles';

const PhoneMockup = () => {
  const classes = useStyles();

  return (
    <Grid item={true} sm={4}>
      <img className={classes.image} src={phone} alt={''} />
    </Grid>
  );
};

export default PhoneMockup;
