import React from 'react';

/**
 * Function that highlight word in text, wrapping it by strong tag
 *
 * @param {string} text - Source text.
 * @param {string} wordToHighlight - Word you want to highlight in text.
 * @return {Component} Component includet text and highlited word wrapped by strong tag.
 */
const highlightWordInText = (text: string, wordToHighlight: string) => {
  const splittedText = text.split(wordToHighlight);
  const partsCountIndex = splittedText.length - 1;

  return splittedText.map((partText, index) => (
    <>
      {partText}
      {index < partsCountIndex && <strong>{wordToHighlight}</strong>}
    </>
  ));
};

export default highlightWordInText;
