export interface IModalDictionary {
  [k: string]: string;
}

/*
 * Keys in dictionary are similar to keys in:
 * >> @Model/modals/constants/constants.ts
 * but do not include prefix: @@modals/
 */
const modalDictionary: IModalDictionary = {
  FILTERS_DATE_PICKER: 'selectDate',
  FILTERS_LIST: 'filters',
};

export default modalDictionary;
