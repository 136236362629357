import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = (isMobile: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      heading: {
        fontWeight: 700,
        marginBottom: '1.25rem',
      },
      primaryColor: {
        color: theme.palette.primary.main,
      },
      root: {
        '& > div': {
          '& :last-of-type': {
            marginBottom: 'unset',
          },
          marginBottom: '1.5rem',
        },
        backgroundColor: theme.palette.background.paper,
        padding: isMobile ? '2rem' : '2rem 8rem',
        textAlign: 'center',
      },
    })
  );

export default useStyles;
