import { createSelector } from 'reselect';

import _Store from '@Store';

import { IProductSelect, IProductsReducer } from './../types';
import getAll from './getAll';

const getSelectedProducts = createSelector<
  _Store.IState,
  IProductsReducer,
  IProductSelect[]
>([getAll], (products) => products.selectedProducts);

export default getSelectedProducts;
