import React, { useContext, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import cn from 'classnames';
import { Field, useFormikContext } from 'formik';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import { IBuyingOnlineFormValues } from '@Compo/buying/Online/Online.types';
import classes from '@Compo/buying/components/PaymentMethod/PaymentMethod.classes';
import { IPaymentMethodProps } from '@Compo/buying/components/PaymentMethod/PaymentMethod.types';
import RadioButton from '@Compo/forms/Radio';
import { PayUContext } from '@Compo/functional/PayUContextProvider/PayUContextProvider.component';
import getCommonObjectFromArrays from '@Misc/helpers/getCommonObjectFromArrays';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import { PaymentMethods } from '@Model/transaction/constants/paymentMethods';
import { IPaymentMethod } from '@Services/$transactions-api/types';

import useStyles from './PaymentMethod.styles';

const PAYMENT_METHOD_TYPE = 'paymentMethod.type';

const PaymentMethod = ({
  isDisabled,
  step,
  lang,
  paymentMethodsFromState,
  selectedTheme,
  isSlotsSale,
  isBasketPay,
  cardPaymentForm,
  setCardCredentials,
}: IPaymentMethodProps) => {
  const { isMobile } = useMuiBreakpoints();
  const styles = useStyles(isMobile)();
  const state = useContext(clearContext);
  const { setIsCardPaymentMethod } = useContext(PayUContext);
  const basketItems = state?.basketItems;
  const paymentMethods: IPaymentMethod[] | undefined =
    isBasketPay && basketItems
      ? getCommonObjectFromArrays(
          basketItems.map((item) => item.paymentMethods || [])
        )
      : paymentMethodsFromState;
  const isDefaultTheme = selectedTheme === 'default';
  const { values } = useFormikContext<IBuyingOnlineFormValues>();

  const iconFileUrl = (method: PaymentMethods) => {
    switch (method) {
      case PaymentMethods.PAYU:
        return 'payu.png';
      case PaymentMethods.BLIK:
        return 'blik.png';
      case PaymentMethods.MBANK:
        return 'mbank.png';
      case PaymentMethods.CARD:
        return 'card.png';
      default:
        return;
    }
  };

  const methodName = (method: PaymentMethods) => {
    switch (method) {
      case PaymentMethods.PAYU:
        return lang.payu;
      case PaymentMethods.BLIK:
        return lang.blik;
      case PaymentMethods.MBANK:
        return lang.mBank;
      case PaymentMethods.CARD:
        return lang.card;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (
      values.paymentMethod.type === PaymentMethods.CARD &&
      setIsCardPaymentMethod
    ) {
      setIsCardPaymentMethod(true);
    } else if (setIsCardPaymentMethod) {
      setIsCardPaymentMethod(false);
      setCardCredentials(null);
    }
  }, [values.paymentMethod.type]);

  return (
    <Grid
      container={true}
      className={cn(
        styles.outerContainer,
        isDisabled ? styles.disabled : undefined,
        classes.paymentMethods
      )}
    >
      <Typography
        variant={isSlotsSale ? 'h4' : 'h3'} // TODO: unify this between forms
        children={`${step}. ${lang.choosePaymentMethod}`}
        className={styles.header}
      />
      <Grid
        container={true}
        direction={'column'}
        className={cn(styles.root, !isDefaultTheme && styles.noPadding)}
      >
        {paymentMethods && paymentMethods.length ? (
          paymentMethods.map((method, index) => (
            <Grid
              container={true}
              item={true}
              direction={'column'}
              className={cn(styles.button, classes.paymentMethod)}
              key={`payment-method-${index}`}
            >
              <Grid item={true}>
                <Field
                  name={PAYMENT_METHOD_TYPE}
                  component={RadioButton}
                  id={method.type}
                >
                  {methodName(method.type)}{' '}
                  <img
                    src={`/static_legacy/payment_methods/${iconFileUrl(
                      method.type
                    )}`}
                    className={styles.logo}
                  />
                </Field>
              </Grid>
              {method.type === PaymentMethods.CARD &&
                values.paymentMethod.type === PaymentMethods.CARD &&
                cardPaymentForm}
            </Grid>
          ))
        ) : (
          <Typography>{lang.emptyPaymentMethods}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default PaymentMethod;
