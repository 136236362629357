import { getType } from 'typesafe-actions';

import { recordUtmParamsInState } from '../actions';
import { IAction, ITrafficParamsReducer } from '../types';

const reducer = (state: ITrafficParamsReducer = null, action: IAction) => {
  switch (action.type) {
    case getType(recordUtmParamsInState):
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
