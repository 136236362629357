import React from 'react';

import { Button, Grid } from '@material-ui/core';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useGlobalStyles from '../../global.styles';
import stepNames from '../../steps';
import Paper from '../Paper';
import { IStarterProps } from './Starter.types';

const Starter = ({ handleNextStep, skipToSelectedStep }: IStarterProps) => {
  const globals = useGlobalStyles();
  const { entryList: lang } = useDictionary();

  return (
    <Paper title={lang.starter.title} helperText={lang.starter.subtitle}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} xs={12}>
          <Grid
            container={true}
            justifyContent="center"
            className={globals.column}
          >
            <Grid item={true}>
              <Button
                className={globals.button}
                type="button"
                variant="text"
                color="primary"
                onClick={() => handleNextStep(stepNames.checkSubmission)}
              >
                {lang.starter.no}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                className={globals.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => skipToSelectedStep(stepNames.ticketCode)}
              >
                {lang.starter.yes}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Starter;
