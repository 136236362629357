import React from 'react';

import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import TransferTicket from '@Compo/ticketsManagement/TransferTicket';
import { IBreadcrumb } from '@Model/breadcrumb/types';
import routes from '@Routes/routes';

const TICKET_MANAGEMENT_TEXT = 'Zarządzaj biletem';
const TRANSFER_TICKET_TEXT = 'Przekaż bilet';

const breadcrumbs: IBreadcrumb[] = [
  {
    link: routes.myTicket,
    name: TICKET_MANAGEMENT_TEXT,
  },
  {
    link: routes.transferTicket,
    name: TRANSFER_TICKET_TEXT,
  },
];

const TransferTicketPage = () => {
  return (
    <MuiGrid>
      <Helmet pageName={TRANSFER_TICKET_TEXT} />
      <FullTriangleBackground />
      <MuiSectionContainer>
        <MuiBreadcrumbs base={breadcrumbs} />
        <TransferTicket />
      </MuiSectionContainer>
    </MuiGrid>
  );
};

export default TransferTicketPage;
