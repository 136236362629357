import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    disabledLink: {
      '&:hover': {
        textDecoration: 'none',
      },
      color: theme.palette.action.disabled,
    },
    iconButton: {
      backgroundColor: 'transparent',
      color: theme.palette.primary.contrastText,
    },
    link: {
      '&:hover': {
        textDecoration: 'none',
      },
      alignItems: 'center',
      color: theme.palette.primary.contrastText,
      display: 'flex',
      flexDirection: 'column',
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.border.radius,
      marginTop: theme.spacing(4),
      padding: theme.spacing(5),
    },
  })
);

export default useStyles;
