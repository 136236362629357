import { getLocation } from 'connected-react-router';
import { connect } from 'react-redux';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';
import { specialEvent } from '@Model/event/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';
import { getSelectedLocale, translate } from '@Model/locale/selectors';
import { EVENT } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import {
  getEventFromTicket,
  getReceivedEvent,
  getReceivedSummary,
  getTicketTransferFee,
} from '@Model/tickets/selectors';
import getReceivedTicket from '@Model/tickets/selectors/getReceivedTicket';

import WithEventDetailBox from './WithEventDetailBox.component';
import { IWithEventDetailBoxFromState } from './WithEventDetailBox.types';

const mapStateToProps = (
  state: _Store.IState
): IWithEventDetailBoxFromState => {
  return {
    dateTranslate: translate(state)('dates'),
    event: getEventFromTicket(state)(getLocation(state).pathname.split('/')[2]),
    isDesktop: isDesktop(state),
    isEmbed: !!getIframeParams(state),
    isLoading: getLoading(EVENT)(state),
    lang: translate(state)('buy', 'summary'),
    langBasket: translate(state)('basket'),
    language: getSelectedLocale(state),
    receivedEvent: getReceivedEvent(state),
    receivedTickets: getReceivedTicket(state),
    specialEventCopy: specialEvent(state),
    summary: getReceivedSummary(state),
    ticketTransferFee: getTicketTransferFee(state)?.price,
  };
};

export default connect<IWithEventDetailBoxFromState, void, {}, _Store.IState>(
  mapStateToProps
)(WithEventDetailBox);
