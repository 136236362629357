import { ComponentType } from 'react';

import { IInternalRouterMapper } from './../InternalRouter.types';

class Mapper {
  private map: IInternalRouterMapper = {};

  public add(module: string, component: ComponentType<any>) {
    this.map[module] = component;
  }

  public get(module: string | null) {
    if (module && this.map?.[module]) {
      return this.map[module];
    }

    return null;
  }
}

export default Mapper;
