import React from 'react';

import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import cn from 'classnames';
import { withRouter } from 'react-router';

import config from '@Config';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import routes from '@Routes/routes';

import classes from '../../Success.classes';
import useStyles from './SellSuccessInfo.styles';
import { ISellSuccessProps } from './SellSuccessInfo.types';

const SellSuccessInfo = ({ history, lang }: ISellSuccessProps) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const { isMobile, isDesktop } = useMuiBreakpoints();

  const aboutAppRedirect = () => {
    window.open(
      config.theme.isGoing ? routes.about : config.app.aboutAppUrl,
      '_blank'
    );
  };

  const getTicketByEmailText = config.theme.isEmpik
    ? lang.getTicketByEmailEmpik
    : lang.getTicketByEmail;

  const redirectToApp = () => {
    try {
      window.open(
        `${config.app.deepLinkApp}?link=${config.app.baseUrl}&apn=${config.deepLink.androidPackageName}&ibi=${config.deepLink.iosPackageName}&isi=${config.deepLink.iosStoreId}`,
        '_self'
      );
    } catch (error) {}
  };

  return (
    <Grid container={true} className={muiStyles.infoContainer}>
      <Grid container={true}>
        <Grid
          item={true}
          xs={12}
          className={cn(muiStyles.header, classes.infoHeader)}
        >
          <Typography color="textPrimary" variant={isMobile ? 'h5' : 'h3'}>
            {lang.importantInfo}
          </Typography>
        </Grid>

        <Grid
          item={true}
          xs={12}
          className={cn(muiStyles.firstInfoWrapper, classes.firstInfoWrapper)}
        >
          <Grid container={true}>
            <Grid item={true}>
              <Grid
                container={true}
                wrap="nowrap"
                direction={isMobile ? 'column' : 'row'}
              >
                <Grid item={true} className={muiStyles.iconContainer}>
                  <ConfirmationNumberOutlinedIcon fontSize="large" />
                </Grid>

                <Grid item={true} xs={true}>
                  <Grid container={true} spacing={1}>
                    <Grid
                      item={true}
                      xs={12}
                      className={muiStyles.textContainer}
                    >
                      <Typography variant={isMobile ? 'body1' : 'h5'}>
                        {getTicketByEmailText}
                      </Typography>
                    </Grid>
                    {isDesktop && (
                      <Grid
                        item={true}
                        xs={12}
                        className={muiStyles.buttonContainer}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={aboutAppRedirect}
                        >
                          {lang.discoverApp}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isMobile && (
          <Grid item={true} xs={12} className={muiStyles.mobileButton}>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              fullWidth={true}
              onClick={redirectToApp}
            >
              {lang.openApp}
            </Button>
          </Grid>
        )}

        <Grid
          xs={12}
          item={true}
          className={cn(muiStyles.secondInfoWrapper, classes.secondInfoWrapper)}
        >
          <Grid
            container={true}
            wrap="nowrap"
            direction={isMobile ? 'column' : 'row'}
          >
            <Grid item={true} className={muiStyles.iconContainer}>
              <ErrorOutlineIcon fontSize="large" />
            </Grid>

            <Grid item={true} className={muiStyles.textContainer}>
              <Typography variant={isMobile ? 'body1' : 'h5'}>
                {lang.yourBank}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(SellSuccessInfo);
