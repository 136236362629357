import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invert: {
      filter: 'invert(1)',
    },
  })
);

export default useStyles;
