const classes = {
  baseAlertContainer: 'base-alert-container',
  buttomSummary: 'buttom-summary',
  buttonsWrapper: 'buttons-wrapper',
  detailsContainer: 'details-container',
  detailsHeader: 'details-header',
  detailsSubContainer: 'details-sub-container',
  doneIcon: 'done-icon',
  downloadAppText: 'download-app-text',
  downloadAlert: 'download-alert',
  downloadBigImage: 'download-big-image',
  downloadContainer: 'download-container',
  donwloadContentContainer: 'donwload-content-container',
  downloadHeader: 'download-header',
  downloadInputSection: 'download-input-section',
  downloadSection: 'download-section',
  downloadSubHeader: 'download-sub-header',
  downloadSuccessMessage: 'download-success-message',
  discoverApp2: 'discover-app-2',
  enterNumberText: 'enter-number-text',
  firstInfoWrapper: 'first-info-wrapper',
  gaClass: 'baner-podziękowanie',
  header: 'header',
  infoDetails: 'info-details',
  infoHeader: 'info-header',
  input: 'input',
  inputButton: 'input-button',
  inputContainer: 'input-container',
  place: 'place',
  placeText: 'place-text',
  secondInfoWrapper: 'second-info-wrapper',
  sellSuccessHeader: 'sell-success-header',
  sellSuccessInfo: 'sell-success-info',
  singleSegment: 'single-segment',
  singleSegmentIcon: 'single-segment-icon',
  successContainer: 'success-container',
  summaryAfterSellSegment: 'summary-after-sell-segment',
  summaryAfterSellSegmentHeader: 'summary-after-sell-segment-header',
  storeButton: 'store-button',
  textUnderInput: 'text-under-input',
  tickets: 'tickets',
  ticketsText: 'tickets-text',
};

export default classes;
