import { Selector } from 'reselect';

import _Store from '@Store';

import { IState } from '../types';

const getPlaceEvents: Selector<_Store.IState, IState> = (state) =>
  state.placeEvents;

export default getPlaceEvents;
