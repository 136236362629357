import {
  getLocation as getConnectedLocation,
  push,
} from 'connected-react-router';
import { EMPTY as EMPTY$, of as of$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { getLocation } from '@Model/router/selectors';
import { getTransactionIdFromLocation } from '@Model/transaction/selectors';
import routes from '@Routes/routes';

import { mounted } from './../actions';

const TRANSACTION_PARAM_NAME = ':transactionId';

export const doRedirectWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const transactionId = getTransactionIdFromLocation(state);
      const location = getLocation(state);
      const { query } = getConnectedLocation(state);

      const urlIncludesErrorParam = location.search.indexOf('error') > -1;
      let url;
      const hasGTMParams = !!(
        query.utm_campaign &&
        query.utm_medium &&
        query.utm_source
      );
      const getSuccessLink = () => {
        if (state.locale.selectedLang === 'en') {
          return query.gtmId
            ? `${routes.engPaymentSuccess}?gtmId=${query.gtmId}`
            : routes.engPaymentSuccess;
        } else {
          if (hasGTMParams) {
            return query.gtmId
              ? `${routes.paymentSuccess}?utm_campaign=${query.utm_campaign}&utm_medium=${query.utm_medium}&utm_source=${query.utm_source}&gtmId=${query.gtmId}`
              : `${routes.paymentSuccess}?utm_campaign=${query.utm_campaign}&utm_medium=${query.utm_medium}&utm_source=${query.utm_source}`;
          }

          return query.gtmId
            ? `${routes.paymentSuccess}?gtmId=${query.gtmId}`
            : routes.paymentSuccess;
        }
      };

      const getFailureLink = () => {
        if (state.locale.selectedLang === 'en') {
          return query.gtmId
            ? `${routes.engPaymentFail}?gtmId=${query.gtmId}`
            : routes.engPaymentFail;
        } else {
          return query.gtmId
            ? `${routes.paymentFail}?gtmId=${query.gtmId}`
            : routes.paymentFail;
        }
      };

      const successLink = getSuccessLink();
      const failureLink = getFailureLink();

      if (transactionId) {
        url = fillUrlWithValues(
          urlIncludesErrorParam ? failureLink : successLink,
          TRANSACTION_PARAM_NAME,
          transactionId
        );

        return of$(push(url));
      }

      return EMPTY$;
    })
  );
};
