import React, { FC, useEffect } from 'react';

import { Actions, Qualifiers } from '@cloudinary/url-gen';
import { useTheme } from '@material-ui/core/styles';

import { cloudinary, getImageUrl } from '@Services/Cloudinary';

import * as CONSTS from './../../Gallery.constants';
import styles from './MobileViewer.module.scss';
import { IMobileViewerProps } from './MobileViewer.types';

const {
  Delivery: { format, quality },
  Resize: { fill },
} = Actions;
const {
  Quality: { autoEco },
  Format: { auto },
} = Qualifiers;

const MobileViewer: FC<IMobileViewerProps> = ({
  galleryAnchor,
  images,
  locationHash,
  sizes,
}) => {
  let sizeWidth = CONSTS.FULL_WIDTH;
  const theme = useTheme();

  useEffect(() => {
    sizeWidth = sizes.width;
  }, []);
  useEffect(() => {
    const locationWithoutHash = locationHash.slice(1);
    const chosenImg = document.getElementById(locationWithoutHash);
    chosenImg?.scrollIntoView({ block: 'center' });
  });

  return (
    <>
      {images.map((image, index) => (
        <img
          alt={image}
          className={styles.image}
          id={galleryAnchor + index}
          key={index}
          src={getImageUrl(image, {
            width: theme.breakpoints.values.md,
            height: theme.breakpoints.values.md,
          })}
        />
      ))}
    </>
  );
};

export default MobileViewer;
