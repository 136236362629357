import { createSelector } from 'reselect';

import { ITransactionItem } from '@Services/$analytics/types';

import paymentSuccessPageMeta from './paymentSuccessPage';

const paymentSummary = createSelector(
  [paymentSuccessPageMeta],
  (transactionItems): ITransactionItem[] => {
    let quantity = transactionItems.transactionItems[0]?.quantity;
    const items: ITransactionItem[] = [];

    transactionItems.transactionItems.forEach((data, i, arr) => {
      if (data.productName === arr[i + 1]?.productName) {
        quantity += arr[i + 1]?.quantity || 0;
      } else {
        items.push({ ...data, quantity });
        quantity = arr[i + 1]?.quantity || 0;
      }
    });

    return items;
  }
);

export default paymentSummary;
