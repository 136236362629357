import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { checkEmail, toggleLogin } from '@Model/auth/actions';
import { showLoginPanel } from '@Model/auth/selectors';

import Login from './Login.component';
import { ILoginFromDispatch, ILoginFromState } from './Login.types';

const mapStateToProps = (state: _Store.IState): ILoginFromState => ({
  show: showLoginPanel(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ILoginFromDispatch => ({
  submitEmail: (email) => dispatch(checkEmail(email)),
  toggle: () => dispatch(toggleLogin()),
});

export default connect<ILoginFromState, ILoginFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Login);
