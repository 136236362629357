import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import sectionComponentFontColor from '@Misc/helpers/theme/sectionComponentFontColor';

const useStyles = (isBgDark: boolean, color?: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      fontColor: {
        color: sectionComponentFontColor(theme, color, isBgDark),
      },
      headerSection: {
        color: sectionComponentFontColor(theme, color, isBgDark),
        marginBottom: '1.5rem',
      },
      heading: {
        color: sectionComponentFontColor(theme, color, isBgDark),
        marginBottom: '1rem',
        textAlign: 'center',
      },
    })
  );

export default useStyles;
