export const MOUNTED = 'Empik/search/MOUNTED';
export const BASE_SEARCH = 'Empik/search/BASE_SEARCH';
export const SET_SEARCH_OFFSET = 'Empik/search/SET_SEARCH_OFFSET';
export const SET_SEARCH_PHRASE = 'Empik/search/SET_SEARCH_PHRASE';
export const SET_FILTER = 'Empik/search/SET_FILTER';
export const SET_LAST_FILTER = 'Empik/search/SET_LAST_FILTER';
export const SET_DATE_FILTER = 'Empik/search/SET_DATE_FILTER';
export const REMOVE_FILTER = 'Empik/search/REMOVE_FILTER';
export const REMOVE_DATE_FILTER = 'Empik/search/REMOVE_DATE_FILTER';
export const RESET_SEARCH_OFFSET = 'Empik/search/RESET_SEARCH_OFFSET';
export const SWITCH_VIEW = 'Empik/search/SWITCH_VIEW';
export const CHANGE_CITY_FILTER = 'Empik/search/CHANGE_CITY_FILTER';
export const SEARCH_STRUCTURE_MOUNTED = 'Empik/search/SEARCH_STRUCTURE_MOUNTED';
export const RESET_FILTERS = 'Empik/search/RESET_FILTERS';
export const GET_FACETS = 'Empik/search/GET_FACETS';

// Private
export const _GET_BASE_SEARCH_RESULTS_REQUEST =
  'Empik/search/_GET_BASE_SEARCH_RESULTS_REQUEST';
export const _GET_BASE_SEARCH_RESULTS_SUCCESS =
  'Empik/search/_GET_BASE_SEARCH_RESULTS_SUCCESS';
export const _GET_BASE_SEARCH_RESULTS_FAILURE =
  'Empik/search/_GET_BASE_SEARCH_RESULTS_FAILURE';

export const _GET_FACETS_REQUEST = 'Empik/search/_GET_FACETS_REQUEST';
export const _GET_FACETS_SUCCESS = 'Empik/search/_GET_FACETS_SUCCESS';
export const _GET_FACETS_FAILURE = 'Empik/search/_GET_FACETS_FAILURE';
