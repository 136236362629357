import React, { FC, useEffect, useMemo } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import Breadcrumb from '@Compo/Breadcrumb';
import Loading from '@Compo/Bricks/components/Loading';
import useStyles from '@Compo/Place/Place.styles';
import { Following } from '@Compo/eventBoxes/Buy/components/Following';
import { FollowingVariant } from '@Compo/eventBoxes/Buy/components/Following/Following.types';
import Triangle from '@Compo/eventBoxes/Triangle';
import Description from '@Compo/reusable/Description';
import ImageFixed from '@Compo/reusable/ImageFixed';
import InfoBox from '@Compo/reusable/InfoBox';
import { ISingleInfo } from '@Compo/reusable/InfoBox/InfoBox.types';
import DetailsLoading from '@Compo/reusable/loadings/DetailsLoading';
import TitleLoading from '@Compo/reusable/loadings/TitleLoading';
import config from '@Config';
import getNonEmptyObject from '@Misc/helpers/getNonEmptyObject';
import icons from '@Misc/helpers/icons';
import prepareUrlForRoute from '@Misc/helpers/prepareUrlForRoute';
import scrollTo from '@Misc/helpers/scrollTo';
import routes from '@Routes/routes';

import styles from './Place.module.scss';
import { IPlaceProps } from './Place.types';
import Accordion from './components/Accordion';
import Map from './components/Map';
import PlaceGallery from './components/PlaceGallery';
import SimilarPlaces from './components/SimilarPlaces';
import Tags from './components/Tags';

const PLACE_EVENTS_ID = 'aktualne-wydarzenia';
const CHECK_BUTTON_TEXT = 'Zobacz';
const CHECK_PLACE_EVENTS_TEXT = config.theme.isEmpik
  ? 'Sprawdź wydarzenia w tym miejscu'
  : 'Sprawdź wydarzenia';
const LOCATION_TEXT = 'Lokalizacja';

const locationIcon = config.theme.isEmpik
  ? cn(styles.icon, icons.location)
  : styles.locationIcon;

const Place: FC<IPlaceProps> = ({
  images,
  isDesktop,
  isLoading,
  isTablet,
  mounted,
  place: placeFromProps,
  gallery,
  similarPlaces,
}) => {
  useEffect(() => {
    mounted();
  }, []);

  const linkToPlace = useMemo(
    () => prepareUrlForRoute(routes.place, place?.slug || ''),
    [placeFromProps]
  );

  const place = getNonEmptyObject(placeFromProps);
  const tags = place.tags || [];
  const showTags = tags.length > 0;
  const infos: ISingleInfo[] = [
    {
      description: place.address,
      icon: 'locationGoing',
      title: LOCATION_TEXT,
    },
  ];

  const empikDesktop = isDesktop && config.theme.isEmpik;

  const placeImage = !!images?.length ? images?.[0] : place.image;

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Breadcrumb
        current={{
          isLoading,
          link: linkToPlace,
          name: place.name,
        }}
      />

      <div className={styles.place}>
        <div className={styles.header}>
          {isLoading && <TitleLoading />}
          {!isLoading && <h1>{place.name}</h1>}
        </div>

        {showTags && (
          <div className={styles.tagBar}>
            <div className={styles.tags}>
              <Tags isLoading={isLoading} tags={tags} />
            </div>
          </div>
        )}
        {!showTags && <div className={styles.titleSpacer} />}

        <div className={styles.twoColumns}>
          <div className={styles.fixedBox}>
            <div className={styles.placeEventsBox}>
              {!isDesktop && (
                <div className={cn(styles.boxImageWrapper, muiStyles.root)}>
                  <ImageFixed
                    src={placeImage}
                    title={place.name}
                    wide={isTablet}
                    transformation={{ width: 810, height: 700 }}
                  />
                </div>
              )}
              <div className={cn(styles.boxContent, muiStyles.root)}>
                {config.theme.isGoing && (
                  <span className={styles.infoText}>
                    {CHECK_PLACE_EVENTS_TEXT}
                  </span>
                )}
                <div className={cn(styles.shortName, muiStyles.fontColor)}>
                  <span className={locationIcon} />
                  {isLoading && <Loading />}
                  {!isLoading && (
                    <h3 className={styles.address}>
                      {place.shortName}, {place.city}
                    </h3>
                  )}
                </div>
                {config.theme.isEmpik && (
                  <span className={styles.infoText}>
                    {CHECK_PLACE_EVENTS_TEXT}
                  </span>
                )}
                <a
                  onClick={(event) => scrollTo(event, PLACE_EVENTS_ID)}
                  className={styles.button}
                >
                  {CHECK_BUTTON_TEXT}
                </a>
                {config.app.showUnfinishedFeatures && place.id && (
                  <Following
                    variant={FollowingVariant.PLACE}
                    ids={[place.id]}
                    itemName={place.name}
                  />
                )}
              </div>
              {config.theme.isEmpik && <Triangle />}
            </div>
          </div>

          <div className={styles.placeDetails}>
            {isDesktop && (
              <div className={styles.imageWrapper}>
                <ImageFixed
                  src={placeImage}
                  title={place.name}
                  transformation={{ width: 810, height: 700 }}
                />
              </div>
            )}

            <InfoBox infos={infos} />

            {isLoading && <DetailsLoading />}

            {!isLoading && place.description && (
              <Description text={place.description} />
            )}

            {isLoading && <div className={styles.fakeMap} />}

            {!isLoading &&
              place.latitude &&
              place.longitude &&
              config.theme.isEmpik && (
                <Map longitude={place.longitude} latitude={place.latitude} />
              )}
          </div>
        </div>

        {!isLoading &&
          place.latitude &&
          place.longitude &&
          config.theme.isGoing && (
            <Map longitude={place.longitude} latitude={place.latitude} />
          )}

        <PlaceGallery images={images} isLoading={isLoading} />

        {place.informations && place.informations.length > 0 && (
          <Accordion accordionData={place.informations} />
        )}

        {empikDesktop && (
          <Tags isLoading={isLoading} tags={tags} topLine={true} />
        )}

        {similarPlaces?.length && <SimilarPlaces places={similarPlaces} />}
      </div>
    </>
  );
};

export default Place;
