export const ARTICLE_PAGE_MOUNTED = 'Empik/artist/ARTIST_PAGE_MOUNTED';

export const _GET_REQUEST = 'Empik/artist/_GET_REQUEST';
export const _GET_SUCCESS = 'Empik/artist/_GET_SUCCESS';
export const _GET_FAILURE = 'Empik/artist/_GET_FAILURE';
export const _RESET_STATE = 'Empik/artist/_RESET_STATE';

export const _GET_CLOSEST_POOL_REQUEST =
  'Empik/artist/_GET_CLOSEST_POOL_REQUEST';
export const _GET_CLOSEST_POOL_SUCCESS =
  'Empik/artist/_GET_CLOSEST_POOL_SUCCESS';
export const _GET_CLOSEST_POOL_FAILURE =
  'Empik/artist/_GET_CLOSEST_POOL_FAILURE';

export const _GET_PAST_EVENTS_REQUEST = 'Empik/artist/_GET_PAST_EVENTS_REQUEST';
export const _GET_PAST_EVENTS_SUCCESS = 'Empik/artist/_GET_PAST_EVENTS_SUCCESS';
export const _GET_PAST_EVENTS_FAILURE = 'Empik/artist/_GET_PAST_EVENTS_FAILURE';
