import * as React from 'react';

import { Actions } from '@cloudinary/url-gen';
import { Delivery } from '@cloudinary/url-gen/actions';
import { Format, Quality } from '@cloudinary/url-gen/qualifiers';
import { useTheme } from '@material-ui/core';
import { Box } from '@mui/material';
import useSWR from 'swr';

import { FavoritesIcon } from '@Compo/PurchaseFollow/components/FavoritesIcon';
import ImageFixed from '@Compo/reusable/ImageFixed/';
import { cloudinary, fetchCloudinaryList } from '@Services/Cloudinary';
import { DataMutations } from '@Services/DataMutations';

import { IFavoriteAvatarProps } from './FavoriteAvatar.types';

const {
  Resize: { fill },
} = Actions;
const { quality, format } = Delivery;
const { webp } = Format;
const { autoGood } = Quality;

export const FavoriteAvatar = ({
  stroke,
  size = 64,
  slug,
  type,
}: IFavoriteAvatarProps) => {
  const theme = useTheme();
  const cloudinaryPrefix = DataMutations.favoriteCloudinaryPrefix(type);
  const { data: cloudinaryImages, isLoading } = useSWR<string[]>(
    [slug, cloudinaryPrefix],
    fetchCloudinaryList
  );

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: size / 2,
        display: 'flex',
        height: size,
        justifyContent: 'center',
        width: size,
      }}
    >
      {cloudinaryImages?.length ? (
        <ImageFixed
          aspectRatio={[1, 1]}
          isLoading={isLoading}
          src={cloudinary
            .image(cloudinaryImages[0])
            .resize(fill().width(size).height(size))
            .delivery(quality(autoGood()))
            .delivery(format(webp()))
            .toURL()}
        />
      ) : (
        <FavoritesIcon
          size={size}
          stroke={stroke ?? theme.palette.text.primary}
          type={type}
        />
      )}
    </Box>
  );
};
