import { createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import { IActionAgent, IActionDevice, IActionSizes } from './../types';

export const setDevice = createStandardAction(
  CONSTS.SET_DEVICE
)<IActionDevice>();

export const setSizes = createStandardAction(CONSTS.SET_SIZES)<IActionSizes>();

export const setUserAgent = createStandardAction(
  CONSTS.SET_USER_AGENT
)<IActionAgent>();

export const appIsReady = createStandardAction(CONSTS.APP_IS_READY)();

export const setIsWebview = createStandardAction(
  CONSTS.SET_IS_WEBVIEW
)<boolean>();

export const emptyAction = { type: '_empty', payload: '' };
