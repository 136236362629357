import React from 'react';

import { Box, Grid, Link, Typography } from '@material-ui/core';

import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import icon_huawei_store from '@Styles/images/button_app_gallery.svg';
import icon_ios_store from '@Styles/images/button_app_store.svg';
import icon_android_store from '@Styles/images/button_google_play.svg';

import useStyles from './AppDownload.styles';

const ITUNES_LINK = 'https://itunes.apple.com/us/app/going./id704764616';
const ANDROID_LINK =
  'https://play.google.com/store/apps/details?id=pl.goingapp';
const HUAWEI_LINK = 'https://appgallery7.huawei.com/#/app/C101184169';
const DOWNLOAD_TEXT = 'Pobierz aplikację';

const AppDownload = () => {
  const classes = useStyles();
  const { isMobile } = useMuiBreakpoints();

  return (
    <Box my={6}>
      <Typography className={classes.header} variant={'h6'}>
        {DOWNLOAD_TEXT}
      </Typography>
      <Grid
        container={true}
        spacing={isMobile ? 0 : 2}
        direction={isMobile ? 'column' : 'row'}
      >
        <Grid item={true} xs={6} sm={4} md={3}>
          <Link href={ITUNES_LINK} target={'_blank'} rel={'nofollow'}>
            <img
              className={classes.image}
              src={icon_ios_store}
              alt={DOWNLOAD_TEXT}
            />
          </Link>
        </Grid>
        <Grid item={true} xs={6} sm={4} md={3}>
          <Link href={ANDROID_LINK} target={'_blank'} rel={'nofollow'}>
            <img
              className={classes.image}
              src={icon_android_store}
              alt={DOWNLOAD_TEXT}
            />
          </Link>
        </Grid>
        <Grid item={true} xs={6} sm={4} md={3}>
          <Link href={HUAWEI_LINK} target={'_blank'} rel={'nofollow'}>
            <img
              className={classes.image}
              src={icon_huawei_store}
              alt={DOWNLOAD_TEXT}
            />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppDownload;
