import React from 'react';

import styles from '@Compo/Happening/Happening.module.scss';
import Product from '@Compo/Products/components/Product/Product.component';
import config from '@Config';
import isEmptyObject from '@Misc/helpers/isEmptyObject';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import { IProductsProps } from './Products.types';

const Products = ({ products, selectedTheme, lang }: IProductsProps) => {
  const { isDesktop } = useMuiBreakpoints();
  const productsLimit = config.buy.maxProductsCount;

  if (isEmptyObject(products)) {
    return null;
  }

  return (
    <ul>
      {products.map((product) => (
        <li className={styles.product} key={product.id}>
          <Product
            lang={lang}
            product={product}
            isDesktop={isDesktop}
            productsLimit={productsLimit}
            selectedTheme={selectedTheme}
          />
        </li>
      ))}
    </ul>
  );
};

export default Products;
