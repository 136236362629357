import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    reversed: {
      flexDirection: 'row-reverse',
    },
    root: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        textDecoration: 'none',
      },
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.border.radius,
      display: 'flex',
      flex: 1,
      overflow: 'hidden',
      transition: '0.5s',
    },
    title: {
      backgroundColor: 'inherit',
      padding: theme.spacing(5),
    },
    titleText: {
      color: theme.palette.grey[900],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

export default useStyles;
