import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  clearFormsData,
  handlePrevStep,
  selectSubmission,
  skipToSelectedStep,
} from '@Model/entryList/actions';
import {
  getFoundedSubmissions,
  getSubmissions,
  getTicketData,
} from '@Model/entryList/selectors';
import { checkEmailValidity } from '@Model/sendgrid/actions';

import SelectSubmission from './SelectSubmission.component';
import {
  ISelectSubmissionFromDispatch,
  ISelectSubmissionFromState,
} from './SelectSubmission.types';

const mapStateToProps = (state: _Store.IState): ISelectSubmissionFromState => ({
  submissions: getFoundedSubmissions(state),
  confirmedSubmissions: getSubmissions(state),
  ticketData: getTicketData(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISelectSubmissionFromDispatch => ({
  skipToSelectedStep: (step) => dispatch(skipToSelectedStep(step)),
  handlePrevStep: () => dispatch(handlePrevStep()),
  selectSubmission: (data) => dispatch(selectSubmission(data)),
  clearFormsData: () => dispatch(clearFormsData()),
  checkEmail: (email) => dispatch(checkEmailValidity(email)),
});

export default connect<
  ISelectSubmissionFromState,
  ISelectSubmissionFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(SelectSubmission);
