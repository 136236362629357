import { createStyles, makeStyles } from '@material-ui/core/styles';

import sectionComponentFontColor from '@Misc/helpers/theme/sectionComponentFontColor';

const useStyles = (color?: string, isBgDark?: boolean) =>
  makeStyles((theme) =>
    createStyles({
      fontColor: {
        color: sectionComponentFontColor(theme, color, isBgDark) + '!important',
      },
    })
  );

export default useStyles;
