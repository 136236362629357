import React from 'react';

import Online from '@Compo/buying/Online';
import Onsite from '@Compo/buying/Onsite';
import CardPaymentForm from '@Compo/buying/components/CardPaymentForm';
import PayUContextProvider from '@Compo/functional/PayUContextProvider';
import Helmet from '@Compo/helmet/components/BuyPage';
import BuyPageBackground from '@Compo/layout/BuyPageBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import config from '@Config';

const Buy = () => {
  const cardPaymentForm = <CardPaymentForm />;

  return (
    <MuiGrid>
      <Helmet />
      <MuiSectionContainer>
        {config.theme.isEmpik && <BuyPageBackground />}
        {config.app.onlineSale ? (
          <PayUContextProvider>
            <Online cardPaymentForm={cardPaymentForm} />
          </PayUContextProvider>
        ) : (
          <Onsite />
        )}
      </MuiSectionContainer>
    </MuiGrid>
  );
};

export default Buy;
