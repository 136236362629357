import { createSelector } from 'reselect';

import _Store from '@Store';

import { getLocation } from '@Model/router/selectors';
import { ILocation } from '@Model/router/types';

import { PagesEnum } from './../constants/pages';
import { ICompositionData, IPagesReducer } from './../types';
import get from './get';

const getUrlStructure = createSelector<
  _Store.IState,
  IPagesReducer,
  ILocation,
  ICompositionData | null
>([get, getLocation], (pages, location) =>
  pages.compositions[PagesEnum.link]?.sections.length
    ? pages.compositions[PagesEnum.link] || null
    : null
);

export default getUrlStructure;
