import { createSelector } from 'reselect';

import get from './get';

const getArtistPastEvents = createSelector(
  [get],
  (artist) => artist.pastEvents
);

export default getArtistPastEvents;
