import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import TransactionError from '@Misc/classes/TransactionError';
import { IGetEventByIdSuccessPayload } from '@Model/event/types';
import {
  IBuyAndPayOnlinePayload,
  IBuyAndPayOnsitePayload,
  ICardCredentials,
  IGetDetailsRequest,
  IGetTransactionDetailsSuccessPayload,
  IGetTransactionInfoSuccessPayload,
  ITransaction,
  ITransactionReducer,
} from '@Model/transaction/types';

import * as CONSTS from './../constants/actions';

// Public actions
export const buyAndPayOnline = createStandardAction(
  CONSTS.BUY_AND_PAY_ONLINE
)<IBuyAndPayOnlinePayload>();

export const buyAndPayOnsite = createStandardAction(
  CONSTS.BUY_AND_PAY_ONSITE
)<IBuyAndPayOnsitePayload>();

export const successSummaryMounted = createStandardAction(
  CONSTS.SUCCESS_SUMMARY_MOUNTED
)();
export const failSummaryMounted = createStandardAction(
  CONSTS.FAIL_SUMMARY_MOUNTED
)();
export const redirectAfterSale = createStandardAction(
  CONSTS.REDIRECT_AFTER_SALE
)<string>();
export const setCardCredentials = createStandardAction(
  CONSTS.SET_CARD_CREDENTIALS
)<ICardCredentials | null>();

export const unmount = createStandardAction(CONSTS.UNMOUNT)();

// Private actions
export const sendTransaction = createAsyncAction(
  CONSTS._POST_REQUEST,
  CONSTS._POST_SUCCESS,
  CONSTS._POST_FAILURE
)<
  ITransaction,
  ITransactionReducer & { onDone: () => void },
  TransactionError
>();

export const getTransactionInfo = createAsyncAction(
  CONSTS._GET_TRANSACTION_INFO_REQUEST,
  CONSTS._GET_TRANSACTION_INFO_SUCCESS,
  CONSTS._GET_TRANSACTION_INFO_FAILURE
)<IGetDetailsRequest, IGetTransactionInfoSuccessPayload, Error>();

export const getTransactionDetails = createAsyncAction(
  CONSTS._GET_TRANSACTION_DETAILS_REQUEST,
  CONSTS._GET_TRANSACTION_DETAILS_SUCCESS,
  CONSTS._GET_TRANSACTION_DETAILS_FAILURE
)<IGetDetailsRequest, IGetTransactionDetailsSuccessPayload, Error>();

export const getPaymentRundateSlug = createAsyncAction(
  CONSTS._GET_PAYMENT_RUNDATE_SLUG_REQUEST,
  CONSTS._GET_PAYMENT_RUNDATE_SLUG_SUCCESS,
  CONSTS._GET_PAYMENT_RUNDATE_SLUG_FAILURE
)<number, IGetEventByIdSuccessPayload, Error>();
