import { getType } from 'typesafe-actions';

import { setCardCredentials } from '@Model/transaction/actions';
import { IAction, ICardCredentialsReducer } from '@Model/transaction/types';

const reducer = (
  state: ICardCredentialsReducer = null,
  action: IAction
): ICardCredentialsReducer => {
  switch (action.type) {
    case getType(setCardCredentials):
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
