import { getSearch } from 'connected-react-router';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { passwordResetFormSubmit } from '@Model/passwordReset/actions';
import { PASSWORDRESET } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import PasswordReset from './PasswordReset.component';
import {
  IPasswordResetFromDispatch,
  IPasswordResetFromState,
} from './PasswordReset.types';

const mapStateToProps = (state: _Store.IState): IPasswordResetFromState => {
  return {
    isLoading: getLoading(PASSWORDRESET)(state),
    lang: translate(state)('passwordReset', 'passwordResetForm'),
    queryParams: queryString.parse(getSearch(state)),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IPasswordResetFromDispatch => {
  return {
    submitForm: (payload) => dispatch(passwordResetFormSubmit(payload)),
  };
};

export default connect<
  IPasswordResetFromState,
  IPasswordResetFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);
