import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.border.radius,
      boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.25)',
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '1.35rem',
      padding: '1.0625rem 0 ',
      textTransform: 'capitalize',
      width: '13.44rem',
    },
  })
);

export default useStyles;
