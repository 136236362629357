import { createSelector } from 'reselect';

import _Store from '@Store';

import { IProduct, IProductsReducer } from '../types';
import getAll from './getAll';

const getSelected = createSelector<_Store.IState, IProductsReducer, IProduct[]>(
  [getAll],
  (products) => {
    const selectedProducts = [] as IProduct[];
    Object.keys(products.selected).map((product) => {
      const amount = products.selected[product];
      if (amount) {
        const searchedProduct = products.products.find(
          (searchingProduct) => searchingProduct.id.toString() === product
        );

        if (searchedProduct) {
          selectedProducts.push({
            ...searchedProduct,
            price: searchedProduct.price * amount,
          });
        }
      }
    });

    return selectedProducts;
  }
);

export default getSelected;
