import { getType } from 'typesafe-actions';

import { setSizes } from './../../actions';
import * as CONSTS from './../../constants/constants';
import { IAction, IReducerSizes } from './../../types';

const initialState: IReducerSizes = {
  height: CONSTS.DESKTOP_MIN_HEIGHT,
  width: CONSTS.DESKTOP_MIN_WIDTH,
};

const reducer = (
  state: IReducerSizes = initialState,
  action: IAction
): IReducerSizes => {
  switch (action.type) {
    case getType(setSizes):
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
