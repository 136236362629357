import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import { IGetShipmentSuccessPayload } from './../types';

export const shipmentDeliveryMounted = createStandardAction(
  CONSTS.SHIPMENT_DELIVERY_MOUNTED
)();

export const getShipmentDelivery = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IGetShipmentSuccessPayload, Error>();
