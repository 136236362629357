import React from 'react';

import { useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';

import { getEvent } from '@Recoil/event/selectors';
import { IRundateResponse } from '@Services/$events-api/types';

const BetaEvent = () => {
  const { pathname } = useLocation();
  const event = useRecoilValue<IRundateResponse>(getEvent(pathname));

  return <div>{event.event.titlePL}</div>;
};

export default BetaEvent;
