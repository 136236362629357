import { createSelector } from 'reselect';

import getLocalizedDescription from '@Misc/helpers/getLocalizedDescription';
import { getSelectedLocale } from '@Model/locale/selectors';

import getData from './getData';

const getDescription = createSelector(
  [getData, getSelectedLocale],
  (happening, selectedlanguage) =>
    getLocalizedDescription(happening?.descriptions, selectedlanguage)
);

export default getDescription;
