import { createSelector } from 'reselect';

import calculatePrice from './calculatePrice';
import getData from './getData';
import getSelected from './getSelected';
import getSelectedSpaceDescription from './getSelectedSpaceDescription';

const getSlotsSummary = createSelector(
  [getData, getSelected, getSelectedSpaceDescription, calculatePrice],
  (happening, selected, space, price) => {
    if (!happening) {
      return null;
    }

    const mappedPlayers = selected.selectionsPerPriceType.map(
      (selection) => selection.numberOfPlayers || 0
    );
    const numberOfPlayers = mappedPlayers.reduce((a, b) => a + b, 0);

    return {
      activityName: space?.title,
      calculatePricePerPerson: happening.calculatePricePerPerson,
      numberOfPlayers,
      price,
    };
  }
);

export default getSlotsSummary;
