/**
 * Deprecated - use Cloudinary service instead (getImageUrl).
 * @param dimensions
 * @param url
 * @param cropMethod
 */
const getUrlWithTransformation = (
  dimensions: number[],
  url?: string | null,
  cropMethod?: string
) => {
  if (!url) {
    return;
  }

  const dimensionsStr =
    dimensions.length === 2
      ? `,w_${dimensions[0]},h_${dimensions[1]}`
      : dimensions.length === 1
      ? `,w_${dimensions[0]}`
      : '';
  const crop = cropMethod || 'fill';
  const isCloudinaryLink = url.includes('cloudinary');

  if (isCloudinaryLink) {
    const splitUrl = url.split('/');
    splitUrl.splice(6, 0, `c_${crop},g_auto,f_auto,fl_lossy${dimensionsStr}`);
    const newUrl = splitUrl.join('/');

    return newUrl;
  }

  return url;
};

export default getUrlWithTransformation;
