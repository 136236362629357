import React from 'react';

import { Grid, IconButton, Link, SvgIcon, Typography } from '@material-ui/core';
import { Facebook, Instagram } from '@material-ui/icons';
import { mdiSpotify } from '@mdi/js';

import useStyles from './ArtistSocialLinks.styles';
import { IArtistSocialLinksProps } from './ArtistSocialLinks.types';

const ArtistSocialLinks = ({ artist }: IArtistSocialLinksProps) => {
  const muiStyles = useStyles();
  const fbName = 'Facebook';
  const igName = 'Instagram';
  const spotifyName = 'Spotify';

  const SocialLink = ({ name, link }: { name: string; link?: string }) => {
    const getIcon = (smName: string) => {
      switch (smName) {
        case fbName:
          return <Facebook />;
        case igName:
          return <Instagram />;
        case spotifyName:
          return (
            <SvgIcon>
              <path d={mdiSpotify} />
            </SvgIcon>
          );
        default:
          return '';
      }
    };

    return (
      <Link href={link} className={muiStyles.link}>
        <IconButton className={muiStyles.iconButton} disabled={!link}>
          {getIcon(name)}
        </IconButton>
        <Typography
          variant={'caption'}
          children={name}
          className={!link ? muiStyles.disabledLink : undefined}
        />
      </Link>
    );
  };

  return (
    <Grid
      container={true}
      justifyContent={'space-between'}
      className={muiStyles.root}
    >
      <Grid item={true}>
        <SocialLink name={fbName} link={artist.fbLink} />
      </Grid>
      <Grid item={true}>
        <SocialLink name={igName} link={artist.instagramLink} />
      </Grid>
      <Grid item={true}>
        <SocialLink name={spotifyName} link={artist.spotifyLink} />
      </Grid>
    </Grid>
  );
};

export default ArtistSocialLinks;
