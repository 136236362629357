import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolsAllReducer, IPoolsData } from './../types';
import getAll from './getAll';

const getPoolsData = createSelector<
  _Store.IState,
  IPoolsAllReducer,
  IPoolsData
>([getAll], (all) => (all.poolsData || {}) as IPoolsData);

export default getPoolsData;
