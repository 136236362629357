import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = (color?: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      centeredText: {
        textAlign: 'center',
        width: '100%',
      },
      description: {
        marginBottom: '3rem',
      },
      pill: {
        borderRadius: '50%',
        height: '12.5rem',
        width: '12.5rem',
      },
      pillMobile: {
        marginBottom: '2rem',
      },
      root: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '1rem',
        color: color ?? undefined,
        marginBottom: '4rem',
        padding: '4rem',
      },
    })
  );

export default useStyles;
