import React from 'react';

import Tag from '@Compo/Events/components/Tag';

import useStyles from './ChangeMonitorTag.styles';
import { IChangeMonitorTagProps } from './ChangeMonitorTag.types';

const ChangeMonitorTag = ({ changeMonitorType }: IChangeMonitorTagProps) => {
  const muiStyles = useStyles();

  return (
    <div className={muiStyles.root}>
      <Tag
        id={changeMonitorType?.id}
        name={changeMonitorType?.name}
        slug={changeMonitorType?.slug}
        isMain={true}
        disableCapitalize={true}
      />
    </div>
  );
};

export default ChangeMonitorTag;
