import { connect } from 'react-redux';

import _Store from '@Store';

import {
  isDesktop,
  isMobile,
  isTablet,
  mobileUserAgent,
} from '@Model/app/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';

import AppClassName from './AppClassName.component';
import { IAppClassNameFromState } from './AppClassName.types';

const mapStateToProps = (state: _Store.IState): IAppClassNameFromState => ({
  client: getIframeParams(state)?.client || '',
  isDesktop: isDesktop(state),
  isMobile: isMobile(state),
  isTablet: isTablet(state),
  mobileUserAgent: mobileUserAgent(state),
});

export default connect<IAppClassNameFromState, void, {}, _Store.IState>(
  mapStateToProps
)(AppClassName);
