import { createSelector } from 'reselect';

import _Store from '@Store';

import {
  IPool,
  IPoolSelectedTicket,
  IPoolsGroupedSelectedSeats,
} from './../types';
import getPools from './getPools';
import getSeatsGroupedByCategoryKeysAndPools from './getSeatsGroupedByCategoryKeysAndPools';

const getSelectedTicketsBySeats = createSelector<
  _Store.IState,
  IPoolsGroupedSelectedSeats,
  IPool[],
  IPoolSelectedTicket[]
>(
  [getSeatsGroupedByCategoryKeysAndPools, getPools],
  (seatsGroupedByPoolNameOrKey, pools) => {
    const updatedSelected: IPoolSelectedTicket[] = [];

    Object.keys(seatsGroupedByPoolNameOrKey).forEach((poolNameOrKey) => {
      const poolKeyNumber = parseInt(poolNameOrKey, 10);

      seatsGroupedByPoolNameOrKey[poolKeyNumber]?.map((poolWithSeats) => {
        const currentPool = pools.find(
          (pool) => pool.id.toString() === poolWithSeats.poolName
        );
        const poolToUpdate = seatsGroupedByPoolNameOrKey[poolKeyNumber]?.find(
          (groupedPool) => groupedPool.poolName === currentPool?.id.toString()
        );

        if (currentPool && poolToUpdate) {
          updatedSelected.push({
            amount: poolToUpdate.amount,
            currency: currentPool.currency,
            poolId: currentPool.id,
            poolName: currentPool.title,
            price: currentPool.price,
            serviceFee: currentPool.serviceFee,
            paymentMethods: currentPool.payments,
          });
        }
      });
    });

    return updatedSelected;
  }
);

export default getSelectedTicketsBySeats;
