import config from '@Config';
import { Fetcher } from '@Services/Fetcher';
import Logger from '@Services/Logger';
import { IFavoritePayload } from '@Types/Favorites';

export class Favorites {
  public static add = async (item: IFavoritePayload): Promise<string> => {
    try {
      const res = await Fetcher.post(
        `${config.api.firebaseFunctionsUrl}/addToFavoritesWithUserEmailCheck`,
        JSON.stringify(item)
      );

      if (res.error) {
        throw res.error;
      }

      return res.message;
    } catch (e: any) {
      Logger.error('Favorites.add', e);

      return e.message;
    }
  };
}
