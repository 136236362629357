import React, { useContext } from 'react';

import { Formik } from 'formik';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';

import FormLayout from './../components/FormLayout';
import { makeValidationSchema } from './Onsite.selectors';
import { IBuyingOnsiteProps, IBuyingOnsiteValues } from './Onsite.types';

const Onsite = ({
  errorsTexts,
  buyAndPay,
  initialValues,
}: IBuyingOnsiteProps) => {
  const validationSchema = makeValidationSchema(errorsTexts);

  const state = useContext(clearContext);

  const catchSaveData = (data: IBuyingOnsiteValues) => {
    buyAndPay({
      basketItems: state?.basketItems ? state.basketItems : [],
      data,
      onDone: state?.clearBasket ? state.clearBasket : () => null,
    });
  };

  return (
    <Formik
      component={FormLayout}
      initialValues={initialValues}
      onSubmit={catchSaveData}
      validationSchema={validationSchema}
    />
  );
};

export default Onsite;
