import { eventSelectItem } from '@/models/analytics/actions';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { ISelectItemEvent } from '@Model/analytics/types';

import Event from './Event.component';
import { IEventElementFromDispatch } from './Event.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IEventElementFromDispatch => {
  return {
    eventSelectItem: (event: ISelectItemEvent) =>
      dispatch(eventSelectItem(event)),
  };
};

export default connect<{}, IEventElementFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(Event);
