import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getArtist, getClosestPool } from '@Model/artist/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenArtistFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getArtist.request)),
    map$(() => setLoading(CONSTS.ARTIST))
  );
};

export const setLoaderWhenArtistFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getArtist.success, getArtist.failure], action)
    ),
    map$(() => resetLoading(CONSTS.ARTIST))
  );
};

export const setLoaderWhenArtistClosestPoolFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getArtist.request, getClosestPool.request])),
    map$(() => setLoading(CONSTS.EVENT))
  );
};

export const setLoaderWhenArtistClosestPoolFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getClosestPool.success, getClosestPool.failure], action)
    ),
    map$(() => resetLoading(CONSTS.EVENT))
  );
};
