import { ActionType, StateType } from 'typesafe-actions';

import * as actions from './../actions';
import reducer from './../reducer';

export const themes = ['default', 'Hangar646', 'Saltos'] as const;

export type IThemeReducer = IAvailableThemes;

export type IAvailableThemes = (typeof themes)[number];

export type IState = StateType<typeof reducer>;
export type IAction = ActionType<typeof actions>;
