import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';

import SliderComponent from '@Compo/forms/Slider';
import usePreviousState from '@Misc/hooks/usePreviousState';

import styles from './PriceTypesPicker.module.scss';
import { IPriceTypesPickerProps } from './PriceTypesPicker.types';

const PriceTypesPicker = ({
  capacity,
  counter,
  isCalculatedPerPerson,
  isSelected,
  lang,
  priceType,
  selections,
  selectionsPerPriceType,
  selectedSlot,
  selectedDay,
  selectedSpace,
  setOnlyOnePrice,
  setPeopleCount,
  generateIdempotencyKey,
  theme,
  eventAddToCartHappening,
  eventRemoveFromCartHappening,
}: IPriceTypesPickerProps) => {
  const [state, setState] = useState(0);
  const numberOfPlayers = selectionsPerPriceType.find(
    (sel) => sel.priceType?.type === priceType.type
  )?.numberOfPlayers;
  const prevNumOfPlayers = usePreviousState(numberOfPlayers) || 0;
  const prevSpace = usePreviousState(selectedSpace);
  const [defaultNumberOfPeople, setDefaultNumberOfPeople] = useState(0);
  const selectedSinglePrice = selections.find(
    (selection) => selection.numberOfPlayers && selection.numberOfPlayers > 0
  );

  const setPlayerNumberForPriceType = (val: number) => {
    setState(val);

    isCalculatedPerPerson
      ? setPeopleCount({
          numberOfPlayers: val,
          priceType,
        })
      : setOnlyOnePrice({
          numberOfPlayers: val,
          priceType,
        });
    generateIdempotencyKey();
  };

  useEffect(() => {
    if (priceType.type === 'default') {
      setDefaultNumberOfPeople(counter);
      setPeopleCount({
        numberOfPlayers: counter,
        priceType,
      });
    }
  }, []);

  useEffect(() => {
    if (isCalculatedPerPerson) {
      setPeopleCount({
        numberOfPlayers: state,
        priceType,
      });
    }
    if (
      selectedSinglePrice &&
      selectedSinglePrice.priceType?.type === priceType.type
    ) {
      setOnlyOnePrice({
        ...selectedSinglePrice,
        priceType,
      });
    }
  }, [selectedSpace, selectedSlot]);

  useEffect(() => {
    if (selectedSpace !== -1 && isCalculatedPerPerson) {
      if (numberOfPlayers && numberOfPlayers > prevNumOfPlayers) {
        const diff = numberOfPlayers - prevNumOfPlayers;
        for (let i = 0; i < diff; i++) {
          eventAddToCartHappening({
            price: priceType.value,
            currency: 'PLN',
          });
        }
      }

      if (numberOfPlayers || 0 < prevNumOfPlayers) {
        const diff = prevNumOfPlayers - (numberOfPlayers || 0);
        for (let i = 0; i < diff; i++) {
          eventRemoveFromCartHappening({
            price: priceType.value,
            currency: 'PLN',
          });
        }
      }

      if (numberOfPlayers && prevSpace !== selectedSpace) {
        for (let i = 0; i < numberOfPlayers; i++) {
          eventRemoveFromCartHappening({
            price: priceType.value,
            currency: 'PLN',
          });
        }
      }

      if (numberOfPlayers && prevSpace === -1 && selectedSpace !== -1) {
        for (let i = 0; i < numberOfPlayers; i++) {
          eventAddToCartHappening({
            price: priceType.value,
            currency: 'PLN',
          });
        }
      }
    }
  }, [selectedSpace, numberOfPlayers, selectedSlot]);

  return (
    <>
      {isCalculatedPerPerson && (
        <div className={styles.picker}>
          <SliderComponent
            maxValue={capacity}
            onChange={setPlayerNumberForPriceType}
            minValue={0}
            step={counter}
            value={defaultNumberOfPeople}
            theme={theme}
          />
        </div>
      )}
      {!isCalculatedPerPerson && (
        <div className={styles.picker}>
          <Button
            onClick={() => setPlayerNumberForPriceType(1)}
            size={'large'}
            color={'primary'}
            variant={isSelected ? 'contained' : 'outlined'}
            disableElevation={true}
            fullWidth={true}
          >
            {isSelected ? lang.chosen : lang.choose}
          </Button>
        </div>
      )}
    </>
  );
};

export default PriceTypesPicker;
