import axios, { CancelTokenSource } from 'axios';
import getYouTubeId from 'get-youtube-id';

import config from '@Config';
import EventsListService from '@Misc/classes/EventsListService';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';
import translatableDate from '@Misc/helpers/translatableDate';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import { IEventFull } from '@Model/event/types';
import { ILang } from '@Model/locale/types';

import { IEventRouterParams, IRundateResponse } from './types';

export const DEFAULT_DATE_TRANSLATE = {
  locale: 'pl',
  today: 'Dzisiaj',
  tomorrow: 'Jutro',
};

class EventsApi extends EventsListService {
  public static getEventsUrl(): string {
    return `${config.api.baseUrl}events`;
  }

  public static getEventUrl(eventSlug: string, rundateSlug?: string): string {
    if (rundateSlug) {
      return `${config.api.baseUrl}events/rundates/${eventSlug}/${rundateSlug}`;
    }

    return `${config.api.baseUrl}events/${eventSlug}`;
  }

  public static getEventByIdUrl(eventId: number): string {
    return `${config.api.baseUrl}events/rundates/${eventId}`;
  }

  public static getEvent(
    eventSlug: string,
    rundateSlug?: string
  ): Promise<IRundateResponse> {
    return new Promise((resolve, reject) => {
      const eventUrl = EventsApi.getEventUrl(eventSlug, rundateSlug);

      axios
        .get(
          eventUrl,
          withCacheHeader({
            headers: EventsApi.salesAgentHeader,
          })
        )
        .then(getData)
        .then((response: IRundateResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }

  public cancelTokenEvent?: CancelTokenSource;

  public makeKeyFromParams(params: IEventRouterParams) {
    return `${params.eventSlug}/${params.rundateSlug}`;
  }

  public getSingleEvent(
    eventSlug: string,
    rundateSlug?: string
  ): Promise<IRundateResponse> {
    return new Promise((resolve, reject) => {
      const eventUrl = EventsApi.getEventUrl(eventSlug, rundateSlug);

      this.cancelTokenEvent = axios.CancelToken.source();

      axios
        .get(
          eventUrl,
          withCacheHeader({
            cancelToken: this.cancelTokenEvent.token,
            headers: EventsApi.salesAgentHeader,
          })
        )
        .then(getData)
        .then((response: IRundateResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }

  public getEventById(eventId: number): Promise<IRundateResponse> {
    return new Promise((resolve, reject) => {
      const eventUrl = EventsApi.getEventByIdUrl(eventId);

      this.cancelTokenEvent = axios.CancelToken.source();

      axios
        .get(
          eventUrl,
          withCacheHeader({
            cancelToken: this.cancelTokenEvent.token,
            headers: EventsApi.salesAgentHeader,
          })
        )
        .then(getData)
        .then((response: IRundateResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }

  public normalizeFullEvent(
    rundateResponse: IRundateResponse,
    dateTranslate: ILang = DEFAULT_DATE_TRANSLATE
  ): IEventFull {
    const {
      buttonLabel,
      calendarEvent,
      changeMonitorType,
      customTerms,
      currency,
      endDate,
      partner,
      event: {
        artists,
        category,
        descriptionPL,
        externalImages,
        forFansOfArtists,
        formattedDescription,
        formattedPartnerDescription,
        id: eventId,
        images,
        partner: {
          id: partnerId,
          name: partnerName,
          gtmId,
          transferAmount: partnerTransferAmount,
          transferPercent: partnerTransferPercent,
        },
        partnerDescription,
        rundatesCount,
        showAdditionalDescription,
        slug: eventSlug,
        tags,
        teaser,
        titlePL,
      },
      externalImageId,
      freeOfCharge,
      hasManyPools,
      id: rundateId,
      isAvailable,
      isForPremiumUsers,
      notForSale,
      passed,
      place: {
        address: placeAddress,
        category: placeCategory,
        city: { name: placeCityName, slug: placeCitySlug },
        description: placeDescription,
        id: placeId,
        lat: placeLat,
        lon: placeLon,
        name: placeName,
        slug: placeSlug,
        thumb,
      },
      premiumAuthUrl,
      price,
      priceDescriptionPL,
      redirectToUrl,
      rundate,
      rundateDescription,
      shipmentAllowed,
      slug: rundateSlug,
      startDate,
      queue,
    } = rundateResponse;
    const startDateString = EventsApi.getDateString(startDate);

    return {
      additionalDescription: formattedPartnerDescription
        ? formattedPartnerDescription
        : '',
      artists,
      buttonLabel,
      calendarEvent,
      changeMonitorType,
      courierDelivery: shipmentAllowed,
      customTerms,
      currency,
      description: formattedDescription ? formattedDescription : '',
      endDate: EventsApi.getDateString(endDate),
      eventId,
      eventSlug,
      externalImageId,
      externalImages,
      forFansOfArtists,
      freeOfCharge,
      friendlyDate: translatableDate(
        startDateString,
        rundateDescription,
        dateTranslate
      ),
      friendlyHour: EventsApi.getFriendlyHour(
        startDateString,
        rundateDescription
      ),
      gtmId: partner?.gtmId || gtmId,
      hasManyPools,
      imagesUrl: EventsApi.getImageUrl(images),
      isAvailable,
      isPremiumEvent: isForPremiumUsers,
      notForSale,
      partnerId,
      partnerName,
      partnerTransferAmount,
      partnerTransferPercent,
      passed,
      placeAddress,
      placeCategory: EventsApi.normalizeEventPlaceTags(placeCategory),
      placeCityName,
      placeCitySlug: placeCitySlug ? placeCitySlug : '',
      placeDescription,
      placeFriendly: EventsApi.getFriendlyPlace(rundateResponse.place),
      placeId,
      placeImageUrl: thumb,
      placeLat,
      placeLon,
      placeName,
      placeSlug,
      premiumAuthUrl,
      price: getPriceFormatted(price),
      priceDescription: EventsApi.getPriceDescription(priceDescriptionPL),
      queue,
      redirectToUrl,
      rundate,
      rundateDescription,
      rundateId,
      rundateSlug,
      rundatesCount,
      startDate: startDateString,
      tags: EventsApi.prepareTagsFromRundate(category, tags),
      teaser,
      title: titlePL,
      videoId: teaser?.length ? getYouTubeId(teaser) : null,
    };
  }

  public cancelEvent() {
    if (this.cancelTokenEvent) {
      this.cancelTokenEvent.cancel();
      this.cancelTokenEvent = undefined;
    }
  }

  public getCanBuyTicket(event: IEventFull | null) {
    return event?.isAvailable ?? false;
  }
}

export { EventsApi };
export default new EventsApi();
