import { connect } from 'react-redux';

import _Store from '@Store';

import { isBrowser } from '@Model/__base__/selectors';
import { getSizes, isDesktop } from '@Model/app/selectors';

import ArrowSlider from './ArrowSlider.component';
import {
  IArrowSliderFromState,
  IArrowSliderOwnProps,
} from './ArrowSlider.types';

const mapStateToProps = (state: _Store.IState): IArrowSliderFromState => ({
  isBrowser: isBrowser(state),
  isDesktop: isDesktop(state),
  windowWidth: getSizes(state).width,
});

export default connect<
  IArrowSliderFromState,
  null,
  IArrowSliderOwnProps,
  _Store.IState
>(mapStateToProps)(ArrowSlider);
