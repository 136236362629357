import { getType } from 'typesafe-actions';

import {
  getTicketsData,
  getTicketsDataFromTransaction,
  resetTickets,
  returnTicketSubmit,
} from './../../actions';
import { IAction, ITicketsListData } from './../../types';

const reducer = (
  state: ITicketsListData = null,
  action: IAction
): ITicketsListData => {
  switch (action.type) {
    case getType(getTicketsData.success):
      return action.payload;

    case getType(getTicketsDataFromTransaction.success):
      return action.payload;

    case getType(returnTicketSubmit.success):
      if (state) {
        const stateTicketList: ITicketsListData = state.slice();
        const processedTicketIndex = stateTicketList.findIndex(
          (ticket) => ticket.ticketId.toString() === action.payload.ticketId
        );

        stateTicketList.splice(
          processedTicketIndex,
          processedTicketIndex >= 0 ? 1 : 0
        );

        return stateTicketList.length > 0 ? stateTicketList : null;
      }

      return state;

    case getType(resetTickets):
      return null;

    default:
      return state;
  }
};

export default reducer;
