import { useRef, useState } from 'react';

import throttle from 'lodash.throttle';

import { useLayoutEffect } from './useIsomorphicLayoutEffect';

/**
 * Function returning flag if scrolled given amount of pixels from top.
 * Additional return can be added if needed - scrolling direction.
 *
 * @param {number} throttleTime - function throttle time on scroll event - higher for better performance / lower resolution
 * @param {number} topDistance - how many pixels from top should return true flag
 *
 * @return {boolean} show - true if scrolled given amount of pixels from top
 */
function useVerticalScrollValue(throttleTime = 200, topDistance = 200) {
  const isBrowser = typeof window !== 'undefined';
  const position = useRef(!isBrowser ? 0 : window.scrollY);
  const [show, toggleVisibility] = useState(false);

  const handleSticky = () => {
    const prevPos = position.current;
    const currPos = !isBrowser ? 0 : window.scrollY;

    if (!show && currPos > topDistance) {
      toggleVisibility(true);
    }

    if (show && currPos < topDistance) {
      toggleVisibility(false);
    }

    position.current = currPos;
  };

  const throttleCall = throttle(handleSticky, throttleTime);

  useLayoutEffect(() => {
    try {
      window.addEventListener('scroll', throttleCall);

      return () => {
        throttleCall.cancel();
        window.removeEventListener('scroll', throttleCall);
      };
    } catch (noWindow) {}
  }, [show]);

  return show;
}

export default useVerticalScrollValue;
