import { createSelector } from 'reselect';

import _Store from '@Store';

import { IBuyingOnlineFormValues } from '@Compo/buying/Online/Online.types';

import { ITransactionReducer } from './../types';
import get from './get';

const getUserData = createSelector<
  _Store.IState,
  ITransactionReducer,
  IBuyingOnlineFormValues | null
>([get], (transaction) => transaction.userData || null);

export default getUserData;
