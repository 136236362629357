import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { handleNextStep, skipToSelectedStep } from '@Model/entryList/actions';

import Starter from './Starter.component';
import { IStarterFromDispatch } from './Starter.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IStarterFromDispatch => ({
  handleNextStep: (step) => dispatch(handleNextStep(step)),
  skipToSelectedStep: (step) => dispatch(skipToSelectedStep(step)),
});

export default connect<{}, IStarterFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(Starter);
