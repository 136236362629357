import { defaultMemoize } from 'reselect';
import { object, string } from 'yup';

import { ILang } from '@Model/locale/types';

import * as CONSTS from './../constants/constants';
import {
  addressNumberValidator,
  addressValidator,
  authIdValidator,
  empikCardNumberValidator,
  postCodeValidator,
} from './../constants/validators';
import { IBuyingOnsiteValues } from './Onsite.types';

export const getInitialValues = () => {
  const initialValues: IBuyingOnsiteValues = {
    authId: CONSTS.DEFAULT_EMPTY_TEXT,
    empikPremiumNumber: CONSTS.DEFAULT_EMPTY_TEXT,
    userHavePremiumCheckbox: false,

    deliveryCity: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryFirstname: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryLastname: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryLocalStreet: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryNumberStreet: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryPhone: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryStreet: CONSTS.DEFAULT_EMPTY_TEXT,
    deliveryZip: CONSTS.DEFAULT_EMPTY_TEXT,
    giftWrapper: false,

    emailAdditional: {},
    firstnameAdditional: {},
    lastnameAdditional: {},

    pickUpWay: CONSTS.DEFAULT_PICKUP_WAY,
  };

  return initialValues;
};

const makeValidationSchemaToMemoize = (errors: ILang) => {
  return object().shape({
    authId: authIdValidator(errors),
    empikPremiumNumber: empikCardNumberValidator(errors),

    // Address - delivery by post
    deliveryCity: addressValidator(errors.thisFieldIsRequiredValidator),
    deliveryFirstname: addressValidator(errors.thisFieldIsRequiredValidator),
    deliveryLastname: addressValidator(errors.thisFieldIsRequiredValidator),
    deliveryLocalStreet: addressNumberValidator(errors.incorrectData),
    deliveryNumberStreet: string().when('pickUpWay', {
      is: CONSTS.PICK_UP_WAY_POST,
      then: addressNumberValidator(errors.incorrectData).required(
        errors.thisFieldIsRequiredValidator
      ),
    }),
    deliveryPhone: string().when('pickUpWay', {
      is: CONSTS.PICK_UP_WAY_POST,
      // @TODO: remove when sale's ended and update PostAddress.component
      then: string().required(errors.thisFieldIsRequiredValidator),
      // then: phoneNumberValidator(errors.incorrectPhoneNumber).required(
      //   errors.thisFieldIsRequiredValidator
      // ),
    }),
    deliveryStreet: addressValidator(errors.thisFieldIsRequiredValidator),
    deliveryZip: string().when('pickUpWay', {
      is: CONSTS.PICK_UP_WAY_POST,
      then: postCodeValidator(errors.incorrectPostCodeValidator).required(
        errors.thisFieldIsRequiredValidator
      ),
    }),
  });
};

export const makeValidationSchema = defaultMemoize(
  makeValidationSchemaToMemoize
);
