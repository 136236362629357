import { createSelector } from 'reselect';

import _Store from '@Store';

import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import routes from '@Routes/routes';

import { IGetTransactionInfoSuccessPayload } from './../types';
import getTransactionInfo from './getTransactionInfo';

const RUNDATE_SLUG_PLACEHOLDER = ':rundateSlug';
const EVENT_SLUG_PLACEHOLDER = ':eventSlug';

const getRedirectUrl = createSelector<
  _Store.IState,
  IGetTransactionInfoSuccessPayload,
  string | null
>([getTransactionInfo], (details) => {
  if (details?.eventSlug && details?.rundateSlug) {
    return fillUrlWithValues(
      routes.buy,
      [EVENT_SLUG_PLACEHOLDER, RUNDATE_SLUG_PLACEHOLDER],
      [details.eventSlug, details.rundateSlug]
    );
  }

  return null;
});

export default getRedirectUrl;
