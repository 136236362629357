const classes = {
  buyButton: 'form-layout-buy-button',
  buyForm: 'form-layout-buy-form',
  fixedBox: 'form-layout-fixed-box',
  form: 'form-layout-form',
  header: 'form-layout-header',
  mobileSummary: 'form-layout-mobile-summary',
  twoColumns: 'form-layout-two-columns',
};

export default classes;
