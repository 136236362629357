import React, { ComponentType } from 'react';

import { RouteComponentProps } from 'react-router';

import Mapper from './../Mapper';
import PageContainer from './Page.container';

const Page = (
  component: ComponentType<any>,
  module: string,
  language?: string
) => {
  Mapper.add(module, component);

  return (props: RouteComponentProps) => {
    return (
      <PageContainer
        module={module}
        match={props.match}
        language={language}
        location={props.location}
        history={{
          action: props.history.action,
          length: props.history.length,
        }}
      />
    );
  };
};

export default Page;
