import { createSelector } from 'reselect';

import get from './get';

const getArtistClosestEvents = createSelector(
  [get],
  (artist) => artist.closestEvents
);

export default getArtistClosestEvents;
