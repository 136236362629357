import { Dispatch } from 'react';

import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { productsMounted } from '@Model/products/actions';
import { getProducts } from '@Model/products/selectors';
import * as CONSTS from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import Products from './Products.component';
import { IProductsFromDispatch, IProductsFromState } from './Products.types';

const mapStateToProps = (state: _Store.IState): IProductsFromState => ({
  isTransactionLoading: getLoading(CONSTS.TRANSACTION)(state),
  lang: translate(state)('buy', 'products'),
  products: getProducts(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IProductsFromDispatch => ({
  productsMounted: () => dispatch(productsMounted()),
});

export default connect<
  IProductsFromState,
  IProductsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Products);
