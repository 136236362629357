import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { handlePrevStep, skipToSelectedStep } from '@Model/entryList/actions';
import {
  getErrors,
  getIsEmail,
  getSubmissions,
} from '@Model/entryList/selectors';
import { getPartnerFormId } from '@Model/internalRouter/selectors';
import { checkEmailValidity } from '@Model/sendgrid/actions';
import { getCheckEmail } from '@Model/sendgrid/selectors';

import FormLayout from './FormLayout.component';
import { IFormLayoutDispatch, IFormLayoutState } from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutState => ({
  submissions: getSubmissions(state),
  validationErrors: getErrors(state),
  emailValidation: getCheckEmail(state),
  isEmail: getIsEmail(state),
  partnerFormId: getPartnerFormId(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutDispatch => ({
  handlePrevStep: () => dispatch(handlePrevStep()),
  checkEmail: (value) => dispatch(checkEmailValidity(value)),
  skipToSelectedStep: (step) => dispatch(skipToSelectedStep(step)),
});

export default connect<
  IFormLayoutState,
  IFormLayoutDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
