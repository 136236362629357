import makeNumber from '@Misc/helpers/date/makeNumber';

export const thisMonth = (): number[] => {
  const date = new Date();

  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  let day = firstDay.getDate();
  let month = firstDay.getMonth();
  let year = firstDay.getFullYear();

  const first = makeNumber(year, month, day);

  day = lastDay.getDate();
  month = lastDay.getMonth();
  year = lastDay.getFullYear();

  const last = makeNumber(year, month, day);

  return [first, last];
};
