import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEventReducer, IEventReducerData } from './../types';
import get from './get';

const getData = createSelector<_Store.IState, IEventReducer, IEventReducerData>(
  [get],
  (event) => event.data
);

export default getData;
