import React from 'react';

import BasketBuyForm from '@Compo/buying/Basket';
import CardPaymentForm from '@Compo/buying/components/CardPaymentForm';
import PayUContextProvider from '@Compo/functional/PayUContextProvider';
import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';

const BASKET_TEXT = 'Koszyk - płatność';

const BasketBuy = () => {
  const cardPaymentForm = <CardPaymentForm />;

  return (
    <PayUContextProvider>
      <MuiGrid>
        <Helmet pageName={BASKET_TEXT} />
        <FullTriangleBackground />
        <MuiSectionContainer>
          <BasketBuyForm cardPaymentForm={cardPaymentForm} />
        </MuiSectionContainer>
      </MuiGrid>
    </PayUContextProvider>
  );
};

export default BasketBuy;
