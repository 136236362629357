import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITransactionData, ITransactionReducer } from './../types';
import get from './get';

const getPayment = createSelector<
  _Store.IState,
  ITransactionReducer,
  ITransactionData
>([get], (transaction) => transaction.data || ({} as ITransactionData));

export default getPayment;
