import { ISearchOwnProps } from '@Compo/Search/Search.types';

import {
  ISearchExtraData,
  ISectionsComponentDataMutable,
  ISectionsSearchBar,
} from './../types/sections';

const SEARCH_PLACEHOLDER_TEXT = 'Czego szukasz?';

export const search_bar = (
  data: ISectionsComponentDataMutable,
  extra?: ISearchExtraData | null
): ISearchOwnProps => {
  const searchData = data as ISectionsSearchBar[];
  const filters = searchData[0]?.filters ? searchData[0]?.filters : undefined;
  const formattedFilters: Array<{ key: string; value: string }> = [];
  if (filters) {
    filters.forEach((item) => {
      formattedFilters.push({
        key: item.filter,
        value: item.value,
      });
    });
  }

  return {
    brickFilters: formattedFilters,
    color: extra?.color,
    placeholder: SEARCH_PLACEHOLDER_TEXT,
    widget: true,
  };
};
