import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import routes from '@Routes/routes';

/**
 * Function prepares event link from keys or returns redirect address
 *
 * @param {string} eventSlug - event slug or address to redirect to
 * @param {string} rundateSlug - optional rundate slug
 * @param {boolean} isEmbed - should the link use embed route
 * @param {boolean} isBuyLink - should the link use buy route
 * @param {boolean} calendarEvent - if it's an activity
 * @return {string} prepared link
 */
const eventLinkKeys = [':eventSlug', ':rundateSlug'];
const eventEmptyLinkKey = '';
const regExp = new RegExp('^https?://');

const prepareEventLink = (
  eventSlug: string,
  rundateSlug?: string,
  isEmbed?: boolean,
  isBuyLink?: boolean,
  calendarEvent?: boolean
): string => {
  const isExternal = regExp.test(eventSlug);
  const isRundateSlug =
    rundateSlug && !calendarEvent ? rundateSlug : eventEmptyLinkKey;
  const eventRoute = calendarEvent
    ? routes.activity
    : isBuyLink
    ? routes.buy
    : routes.event;
  const eventPin = isBuyLink ? routes.pinBuy : routes.pinEvent;

  return isExternal
    ? eventSlug
    : fillUrlWithValues(isEmbed ? eventPin : eventRoute, eventLinkKeys, [
        eventSlug,
        isRundateSlug,
      ]);
};

export default prepareEventLink;
