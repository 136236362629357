import React, { useEffect, useRef } from 'react';

import cn from 'classnames';

import { IFormioRedirectProps } from '@Compo/Formio/Formio.types';
import config from '@Config';

import styles from './FormioBrick.module.scss';
import { IFormioBrickProps } from './FormioBrick.types';

const FormioBrick = ({ id, isLoading, formSlug }: IFormioBrickProps) => {
  const formRef = useRef(null);
  const formLink = config.formIo.location + formSlug;
  const doneLink = formLink + config.formIo.doneSlug;

  useEffect(() => {
    if (!isLoading && formSlug && formRef) {
      try {
        window.Formio.createForm(formRef.current, formLink).then(
          (form: IFormioRedirectProps) => {
            form.on('submitDone', (data: any) => {
              window.Formio.createForm(formRef.current, doneLink);
            });
          }
        );
      } catch (noSSR) {}
    }
  }, [formSlug, isLoading, formRef]);

  return (
    <div
      className={cn(styles.formioBrick, config.formIo.elementClass)}
      id={id}
      ref={formRef}
    />
  );
};

export default FormioBrick;
