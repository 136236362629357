import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import _Store from '@Store';

import { getSizes, isDesktop } from '@Model/app/selectors';
import { getLocation } from '@Model/router/selectors';

import ViewerModal from './ViewerModal.component';
import {
  IViewerModalFromState,
  IViewerModalOwnProps,
} from './ViewerModal.types';

const mapStateToProps = (state: _Store.IState): IViewerModalFromState => ({
  isMobile: !isDesktop(state),
  location: getLocation(state),
  sizes: getSizes(state),
});

export default withRouter(
  connect<IViewerModalFromState, void, IViewerModalOwnProps, _Store.IState>(
    mapStateToProps
  )(ViewerModal)
);
