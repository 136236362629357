import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isMobile } from '@Model/app/selectors';
import {
  generateIdempotencyKey,
  setDayFromSlot,
  setSlot,
} from '@Model/happening/actions';
import {
  getAvailabilities,
  getData,
  getSelected,
} from '@Model/happening/selectors';
import { translate } from '@Model/locale/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { getTheme } from '@Model/theme/selectors';

import TimeSlots from './TimeSlots.component';
import { ITimeSlotsFromDispatch, ITimeSlotsFromState } from './TimeSlots.types';

const mapStateToProps = (state: _Store.IState): ITimeSlotsFromState => {
  return {
    calculatePricePerPerson: getData(state)?.calculatePricePerPerson,
    happeningSpaces: getData(state)?.spaces,
    isLoadingAvailabilities: getLoading(CONST.AVAILABILITIES)(state),
    selectedDay: getSelected(state).day,
    selectedSlotFromState: getSelected(state).slot,
    selectedTheme: getTheme(state),
    selections: getSelected(state).selections,
    timeSlots: getAvailabilities(state),
    isMobile: isMobile(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITimeSlotsFromDispatch => {
  return {
    generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
    setDayFromSlot: (day) => dispatch(setDayFromSlot(day)),
    setSlot: (slot) => dispatch(setSlot(slot)),
  };
};

export default connect<
  ITimeSlotsFromState,
  ITimeSlotsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TimeSlots);
