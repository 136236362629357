import { Dispatch } from 'react';

import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import _Store from '@Store';

import { setNumberOfPlayers } from '@Model/happening/actions';
import { translate } from '@Model/locale/selectors';
import { selectTicket } from '@Model/pools/actions';
import getPoolsData from '@Model/pools/selectors/getPoolsData';
import { selectProduct } from '@Model/products/actions';
import { getTheme } from '@Model/theme/selectors';
import { getTicketTransferFee } from '@Model/tickets/selectors';

import Pools from './Pools.component';
import { IPoolsFromDispatch, IPoolsFromState } from './Pools.types';

const mapStateToProps = (state: _Store.IState): IPoolsFromState => ({
  isRundateWithSeatsIo: getPoolsData(state).seatsIoPublicKey,
  lang: Object.assign(
    translate(state)('buy', 'summary'),
    translate(state)('summary')
  ),
  selectedTheme: getTheme(state),
  ticketTransferFee: getTicketTransferFee(state)?.price,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPoolsFromDispatch => {
  return {
    selectPriceType: (value) => dispatch(setNumberOfPlayers(value)),
    selectProduct: (product, amount) =>
      dispatch(selectProduct({ product, amount })),
    selectTicket: (
      poolId,
      amount,
      poolName,
      currency,
      paymentMethods,
      price,
      serviceFee,
      additionalFields
    ) =>
      dispatch(
        selectTicket({
          additionalFields,
          amount,
          currency,
          paymentMethods,
          poolId,
          poolName,
          price,
          serviceFee,
        })
      ),
  };
};

export default connect<IPoolsFromState, IPoolsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Pools);
