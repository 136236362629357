export enum FollowingVariant {
  ARTIST = 'artist',
  SALES = 'sales',
  TICKETS = 'tickets',
  PLACE = 'place',
  RUNDATE = 'rundate',
}

export interface IFollowingProps {
  variant: FollowingVariant;
  ids: number[];
  itemName: string;
}
