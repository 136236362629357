import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getLandingPage } from '@Model/landingPage/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenLandingPageFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(getLandingPage.request)),
    map$(() => setLoading(CONSTS.LANDING_PAGE))
  );
};

export const setLoaderWhenLandingPageFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getLandingPage.success, getLandingPage.failure], action)
    ),
    map$(() => resetLoading(CONSTS.LANDING_PAGE))
  );
};
