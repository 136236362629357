import * as React from 'react';

import { Button, Typography } from '@material-ui/core';
import {
  Close,
  Event,
  Favorite,
  NotificationsActive,
} from '@mui/icons-material';
import { Dialog, IconButton, SvgIconTypeMap, TextField } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import cn from 'classnames';

import {
  FollowingVariant,
  IFollowingProps,
} from '@Compo/eventBoxes/Buy/components/Following/Following.types';
import { SuccessDialog } from '@Compo/eventBoxes/Buy/components/Following/components/SuccessDialog';
import { useFollowing } from '@Compo/eventBoxes/Buy/components/Following/useFollowing';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import mockupImage from '@Misc/styles/images/following_dialog_01.png';
import { useDictionary } from '@Recoil/lang/hooks/useDictionary';
import { FavoritesTypes } from '@Types/Favorites';

import classes from './Following.module.scss';

export const Following = ({ ids, variant, itemName }: IFollowingProps) => {
  const i18n = useDictionary();
  const { isMobile } = useMuiBreakpoints();
  const mapVariantToType = (): {
    header: string;
    prompt: string;
    term: string;
    type: FavoritesTypes;
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  } => {
    switch (variant) {
      case FollowingVariant.ARTIST:
        return {
          header: i18n.following.artistHeader,
          prompt: i18n.following.artistPrompt,
          term: i18n.following.artistTerm,
          type: FavoritesTypes.Artists,
          Icon: Favorite,
        };
      case FollowingVariant.PLACE:
        return {
          header: i18n.following.placeHeader,
          prompt: i18n.following.placePrompt,
          term: i18n.following.placeTerm,
          type: FavoritesTypes.Places,
          Icon: Favorite,
        };
      case FollowingVariant.TICKETS:
        return {
          header: i18n.following.ticketsHeader,
          prompt: i18n.following.ticketsPrompt,
          term: i18n.following.ticketsTerm,
          type: FavoritesTypes.Rundates,
          Icon: NotificationsActive,
        };
      case FollowingVariant.SALES:
        return {
          header: i18n.following.salesHeader,
          prompt: i18n.following.salesPrompt,
          term: i18n.following.salesTerm,
          type: FavoritesTypes.Rundates,
          Icon: NotificationsActive,
        };
      default:
        return {
          header: i18n.following.rundateHeader,
          prompt: i18n.following.rundatePrompt,
          term: i18n.following.rundateTerm,
          type: FavoritesTypes.Rundates,
          Icon: Event,
        };
    }
  };
  const { header, type, term, Icon, prompt } = mapVariantToType();
  const {
    email,
    error,
    handleShow,
    handleHide,
    isVisible,
    onChange,
    onSubmit,
    isSuccess,
    isLoading,
  } = useFollowing({ ids, type, itemName });

  return (
    <div className={classes.root}>
      <Button
        variant={'outlined'}
        color={'primary'}
        size={'large'}
        onClick={handleShow}
        fullWidth={true}
        className={classes.mainButton}
      >
        <Icon className={classes.iconMargin} />
        {header}
      </Button>
      <Dialog open={isVisible} onClose={handleHide}>
        {isSuccess ? (
          <SuccessDialog handleHide={handleHide} />
        ) : (
          <div className={cn(classes.dialog, isMobile && classes.mobile)}>
            <div className={classes.content}>
              <div className={classes.header}>
                <Typography className={classes.align} variant={'body1'}>
                  <Icon className={classes.icon} />
                  {header}
                </Typography>
                <IconButton onClick={handleHide}>
                  <Close />
                </IconButton>
              </div>
              <Typography variant={'body2'} children={prompt} />
              <div className={classes.form}>
                <TextField
                  className={classes.input}
                  value={email}
                  id={'email'}
                  label={
                    error
                      ? i18n.following.emailValidate
                      : i18n.following.yourEmail
                  }
                  variant={'outlined'}
                  onChange={onChange}
                  error={error}
                  color={error ? 'error' : 'primary'}
                />
                <Button
                  className={classes.submitButton}
                  variant={'contained'}
                  color={'primary'}
                  size={'large'}
                  onClick={onSubmit}
                  children={i18n.following.subscribe}
                  disabled={isLoading}
                />
              </div>
              <Typography
                variant={'caption'}
                color={'textSecondary'}
                children={term}
              />
            </div>
            <div className={classes.footer}>
              <div className={classes.imageContainer}>
                <img src={mockupImage} alt={type} />
              </div>
              <div className={classes.captionContainer}>
                <Typography
                  variant={'body2'}
                  children={i18n.following.getUpdates}
                />
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};
