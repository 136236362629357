import { createSelector } from 'reselect';

import config from '@Config';
import getCurrencyFromCode from '@Misc/helpers/getCurrencyFromCode';
import { getModule } from '@Model/internalRouter/selectors';
import { ITradeDoublerMeta } from '@Model/meta/types';
import { getLocation } from '@Model/router/selectors';
import getTransactionDetails from '@Model/transaction/selectors/getTransactionDetails';
import getTransactionInfo from '@Model/transaction/selectors/getTransactionInfo';

const paymentSuccessPageMeta = createSelector(
  [getLocation, getModule, getTransactionInfo, getTransactionDetails],
  (
    location,
    module,
    transactionInfo,
    transactionDetails
  ): ITradeDoublerMeta => {
    return {
      customGtmId: location.query?.gtmId,
      currency: getCurrencyFromCode(transactionInfo.currency),
      currentUrl: `${config.app.baseUrl}${location.pathname}`,
      module,
      orderNumber: Number(transactionInfo?.id) || 0,
      orderValue: transactionDetails?.revenue || 0,
      rundateId: transactionInfo?.products?.variant || '',
      transactionItems: transactionDetails?.transactionItems || [],
      userEmail: transactionDetails?.user?.userEmail || '',
      voucher: '', // @TODO when discounts are ready
    };
  }
);

export default paymentSuccessPageMeta;
