import React from 'react';

import SimpleLoading from '@Compo/reusable/SimpleLoading';

import styles from './Spaces.module.scss';
import { ISpacesProps } from './Spaces.types';
import Space from './components/Space';

const Spaces = ({ spaces }: ISpacesProps) => {
  let index = 0;

  if (!spaces) {
    return <SimpleLoading />;
  }

  return (
    <ul className={styles.spaces}>
      {spaces.map((space) => (
        <Space key={index++} space={space} />
      ))}
    </ul>
  );
};

export default Spaces;
