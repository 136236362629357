import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { eventAddToCart, eventRemoveFromCart } from '@Model/analytics/actions';
import { get as getBasketItems } from '@Model/basket/selector';
import { translate } from '@Model/locale/selectors';
import { selectTicket } from '@Model/pools/actions';
import { getSelectedTickets } from '@Model/pools/selectors';

import Buttons from './Buttons.component';
import { IButtonsFromDispatch, IButtonsFromState } from './Buttons.types';

const mapStateToProps = (state: _Store.IState): IButtonsFromState => ({
  basket: getBasketItems(state),
  lang: translate(state)('buy', 'pools'),
  selectedTickets: getSelectedTickets(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IButtonsFromDispatch => {
  return {
    selectTicket: (
      poolId,
      amount,
      poolName,
      currency,
      price,
      serviceFee,
      additionalFields,
      forms,
      submissions,
      paymentMethods
    ) =>
      dispatch(
        selectTicket({
          additionalFields,
          amount,
          currency,
          forms,
          poolId,
          poolName,
          price,
          serviceFee,
          paymentMethods,
        })
      ),
    eventAddToCart: (params) => dispatch(eventAddToCart(params)),
    eventRemoveFromCart: (params) => dispatch(eventRemoveFromCart(params)),
  };
};

export default connect<
  IButtonsFromState,
  IButtonsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Buttons);
