const join = <T>(separator: string, ...rest: Array<T | T[]>): string => {
  const parts = [];

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < rest.length; i++) {
    const isArray =
      Object.prototype.toString.call(rest[i]) === '[object Array]';

    if (isArray) {
      const elements = rest[i] as T[];

      elements.forEach((item) => {
        parts.push(item);
      });
    } else {
      parts.push(rest[i]);
    }
  }

  return parts.filter(Boolean).join(separator);
};

export default join;
