import moment from 'moment';

import makeNumber from '@Misc/helpers/date/makeNumber';

import { IHeaderProps, IMonthProps } from './../../DatePicker.types';

export const prepareMonthProps = (
  select: (hash: number) => void,
  defaultDate?: Date | null
): IMonthProps => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentDayHash = makeNumber(currentYear, currentMonth, currentDay);

  const dateFromState = moment(defaultDate || new Date());

  const newMonth = dateFromState.month() + 1;
  const newYear = dateFromState.year();

  const date = parseInt(dateFromState.format('YYYYMMDD'), 10);

  return {
    chosen: date,
    currentDayHash,
    fromOrTo: 'from',
    hover: () => null,
    hoverFrom: null,
    hoverLeave: () => null,
    hoverTo: null,
    month: newMonth,
    select,
    selectedFrom: date,
    selectedTo: date,
    year: newYear,
  };
};

export const prepareHeaderProps = (
  catchSetMonth: (delta: number) => void,
  defaultDate?: Date | null
): IHeaderProps => {
  const dateFromState = moment(defaultDate || new Date());
  const month = dateFromState.month();
  const year = dateFromState.year();

  return {
    fromOrTo: 'to',
    month,
    setMonth: catchSetMonth,
    year,
  };
};
