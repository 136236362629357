import { createSelector } from 'reselect';

import _Store from '@Store';

import isEmptyObject from '@Misc/helpers/isEmptyObject';

import { IProductsReducer } from './../types';
import getAll from './getAll';

const getProducts = createSelector<_Store.IState, IProductsReducer, boolean>(
  [getAll],
  (products) => !isEmptyObject(products.products)
);

export default getProducts;
