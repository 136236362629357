import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { giftCardActivation } from '@Model/giftCard/actions';

import GiftCard from './GiftCard.component';
import { IGiftCardFromDispatch } from './GiftCard.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IGiftCardFromDispatch => {
  return {
    activateCard: (payload) => dispatch(giftCardActivation(payload)),
  };
};

export default connect<void, IGiftCardFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(GiftCard);
