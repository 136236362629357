import React from 'react';

import cn from 'classnames';

import config from '@Config';
import goTrough from '@Misc/helpers/goTrough';

import styles from './Loading.module.scss';
import { ILoadingProps } from './Loading.types';

const Loading = ({ showImage }: ILoadingProps) => {
  return (
    <div className={styles.loading}>
      <div className={cn(styles.column, styles.columnLeft)}>
        {showImage && <div className={styles.fakeImage} />}
        <div className={styles.ticketInfo}>
          <div className={styles.fakeTitle} />
          <div className={styles.fakeDescription} />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.fakePrice} />
      </div>
      <div className={styles.column}>
        <div className={styles.fakeButtons}>
          {goTrough(config.buy.maxTicketsCount).map((key) => (
            <div key={key} className={styles.fakeButton} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Loading;
