import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#E5E5E5',
    },
  })
);

export default useStyles;
