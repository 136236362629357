import { Selector } from 'reselect';

import _Store from '@Store';

import { IEventTerm } from '@Model/event/types';

const getHappeningTerms: Selector<_Store.IState, IEventTerm[]> = (state) =>
  state.happening.terms || [];

export default getHappeningTerms;
