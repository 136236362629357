import { getType } from 'typesafe-actions';

import { set } from './../actions';
import { IAction, IBreadcrumbReducer } from './../types';

export const initialState: IBreadcrumbReducer = null;

const reducer = (
  state: IBreadcrumbReducer = initialState,
  action: IAction
): IBreadcrumbReducer => {
  switch (action.type) {
    // SET
    case getType(set):
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
