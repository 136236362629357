import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  ICancelTicketTransferPayload,
  IReturnTicketPayload,
} from '@Compo/ticketsManagement/MyTicket/MyTicket.types';
import {
  ITicketDataFormPayload,
  ITransactionDataFormPayload,
} from '@Compo/ticketsManagement/TicketDataForm/TicketDataForm.types';
import { ITransferTicketPayload } from '@Compo/ticketsManagement/TransferTicket/TransferTicket.types';
import TransactionError from '@Misc/classes/TransactionError';
import { IEventFull } from '@Model/event/types';
import { IProductResponse } from '@Services/$tickets-api/types';

import * as CONSTS from './../constants/actions';
import {
  IAvailabilitiesPayload,
  IAvailabilitiesResponseDay,
  IDownloadFromTicketDataPayload,
  IDownloadFromTransactionDataPayload,
  IReturnTicketSuccessPayload,
  ITicketReceiveData,
  ITicketRecipienceTermsForm,
  ITicketsListData,
} from './../types';

// Public actions
export const ticketDataFormSubmit = createStandardAction(
  CONSTS.GET_TICKET_DATA
)<ITicketDataFormPayload>();

export const transactionDataFormSubmit = createStandardAction(
  CONSTS.GET_TRANSACTION_DATA
)<ITransactionDataFormPayload>();

export const setTicketDate = createStandardAction(
  CONSTS.SET_TICKET_DATE
)<Date>();

export const transferTicketFormSubmit = createStandardAction(
  CONSTS.TRANSFER_TICKET
)<ITransferTicketPayload>();

export const returnTicket = createStandardAction(
  CONSTS.RETURN_TICKET
)<IReturnTicketPayload>();

export const cancelTransfer = createStandardAction(
  CONSTS.CANCEL_TRANSFER_TICKET
)<ICancelTicketTransferPayload>();

export const resetTickets = createStandardAction(CONSTS.RESET)();

export const setSlot = createStandardAction(CONSTS.SET_SLOT)<string>();

export const setShowSender = createStandardAction(
  CONSTS.SET_SHOW_SENDER
)<boolean>();

export const changeDateMounted = createStandardAction(
  CONSTS.CHANGE_DATE_MOUTED
)();

export const receiveTicketMounted = createStandardAction(
  CONSTS.RECEIVE_TICKET_MOUNTED
)();

export const receiveTicketSubmit = createStandardAction(
  CONSTS.RECEIVE_TICKET_SUBMIT
)<ITicketRecipienceTermsForm>();

export const setInvalidSelection = createStandardAction(
  CONSTS._SET_INVALID_SELECTION
)<string>();
export const setValidSelection = createStandardAction(
  CONSTS._SET_VALID_SELECTION
)();

export const downloadTicketFromTicketData = createStandardAction(
  CONSTS.DOWNLOAD_TICKET_FROM_TICKET_DATA
)<IDownloadFromTicketDataPayload>();

export const downloadTicketFromTransactionData = createStandardAction(
  CONSTS.DOWNLOAD_TICKET_FROM_TRANSACTION_DATA
)<IDownloadFromTransactionDataPayload>();

// Private actions
export const getTicketsData = createAsyncAction(
  CONSTS._GET_TICKETS_REQUEST,
  CONSTS._GET_TICKETS_SUCCESS,
  CONSTS._GET_TICKETS_FAILURE
)<ITicketDataFormPayload, ITicketsListData, TransactionError>();

export const getTicketsDataFromTransaction = createAsyncAction(
  CONSTS._GET_TRANSACTION_DATA_REQUEST,
  CONSTS._GET_TRANSACTION_DATA_SUCCESS,
  CONSTS._GET_TRANSACTION_DATA_FAILURE
)<ITransactionDataFormPayload, ITicketsListData, TransactionError>();

export const getEventData = createAsyncAction(
  CONSTS._GET_EVENT_REQUEST,
  CONSTS._GET_EVENT_SUCCESS,
  CONSTS._GET_EVENT_FAILURE
)<string, IEventFull, Error>();

export const getTransferTicketFee = createAsyncAction(
  CONSTS._GET_TRANSFER_TICKET_FEE_REQUEST,
  CONSTS._GET_TRANSFER_TICKET_FEE_SUCCESS,
  CONSTS._GET_TRANSFER_TICKET_FEE_FAILURE
)<undefined, IProductResponse | null, Error>();

export const getAvailabilities = createAsyncAction(
  CONSTS._GET_AVAILABILITIES_REQUEST,
  CONSTS._GET_AVAILABILITIES_SUCCESS,
  CONSTS._GET_AVAILABILITIES_FAILURE
)<IAvailabilitiesPayload, IAvailabilitiesResponseDay, Error>();

export const transferTicket = createAsyncAction(
  CONSTS._TRANSFER_REQUEST,
  CONSTS._TRANSFER_SUCCESS,
  CONSTS._TRANSFER_FAILURE
)<ITransferTicketPayload, void, TransactionError>();

export const cancelTicketTransfer = createAsyncAction(
  CONSTS._CANCEL_TRANSFER_REQUEST,
  CONSTS._CANCEL_TRANSFER_SUCCESS,
  CONSTS._CANCEL_TRANSFER_FAILURE
)<ICancelTicketTransferPayload, void, TransactionError>();

export const returnTicketSubmit = createAsyncAction(
  CONSTS._RETURN_REQUEST,
  CONSTS._RETURN_SUCCESS,
  CONSTS._RETURN_FAILURE
)<IReturnTicketPayload, IReturnTicketSuccessPayload, TransactionError>();

export const receiveTicket = createAsyncAction(
  CONSTS._GET_RECEIVE_REQUEST,
  CONSTS._GET_RECEIVE_SUCCESS,
  CONSTS._GET_RECEIVE_FAILURE
)<string, ITicketReceiveData, Error>();

export const downloadTicketFromTicketDataOnRequest = createAsyncAction(
  CONSTS._DOWNLOAD_TICKET_FROM_TICKET_DATA_REQUEST,
  CONSTS._DOWNLOAD_TICKET_FROM_TICKET_DATA_SUCCESS,
  CONSTS._DOWNLOAD_TICKET_FROM_TICKET_DATA_FAILURE
)<IDownloadFromTicketDataPayload, undefined, Error>();

export const downloadTicketFromTransactionDataOnRequest = createAsyncAction(
  CONSTS._DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_REQUEST,
  CONSTS._DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_SUCCESS,
  CONSTS._DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_FAILURE
)<IDownloadFromTransactionDataPayload, undefined, Error>();
