import { createSelector } from 'reselect';

import _Store from '@Store';

import * as MODULES from '@Routes/modules';

import { IInternalRouterModule } from './../types';
import getModule from './getModule';

const prepareShouldShow = createSelector<
  _Store.IState,
  IInternalRouterModule,
  boolean
>(
  [getModule],
  (module) =>
    module === MODULES.CONTACT ||
    module === MODULES.EVENT ||
    module === MODULES.EVENTS ||
    module === MODULES.HOME ||
    module === MODULES.LANDING_PAGE ||
    module === MODULES.PLACE ||
    module === MODULES.PLACES ||
    module === MODULES.STATIC_ARTICLE
);

export default prepareShouldShow;
