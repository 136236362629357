import React from 'react';

import { AppBar, Slide, Toolbar } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import Brand from '@Compo/layout/Brand';
import config from '@Config';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import useStyles from './MenuAppBar.styles';
import DesktopMenu from './components/DesktopMenu';
import EmpikBar from './components/EmpikBar';
import IconBar from './components/IconBar';

const MenuAppBar = () => {
  const classes = useStyles();
  const { isDesktop } = useMuiBreakpoints();
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction={'down'} in={!trigger}>
      <AppBar color={'default'}>
        {config.theme.isEmpik && isDesktop && <EmpikBar />}
        <Toolbar className={classes.toolbar}>
          <Brand />
          {isDesktop && <DesktopMenu />}
          <IconBar />
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export default MenuAppBar;
