import { Theme, createStyles, makeStyles } from '@material-ui/core';

import config from '@Config';

const useStyles = (isDarkTheme: boolean, color?: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      boxShadow: {
        boxShadow: config.theme.isEmpik
          ? '0.5rem 1rem 1rem rgba(#d2c6e1, 0.15)'
          : isDarkTheme
          ? '0.5rem 1rem 1rem rgba(#413b3a, 0.15)'
          : '0.5rem 1rem 1rem rgba(#bec4c5, 0.15)',
      },
      contrastColor: {
        color: theme.palette.primary.contrastText,
      },
      disablePointerEvents: {
        pointerEvents: 'none',
      },
      fontColor: {
        color: color ? color : theme.palette.text.primary,
      },
      root: {
        backgroundColor: theme.palette.background.paper,
      },
    })
  );

export default useStyles;
