/**
 * Function that autocomplete buy form from previous data stored into local Storage
 *
 * @param {boolean} autoFill - checks if current URL contains data parameter used to autofill form
 * @param {IEventTerm[]} customTerms - custom terms for current event or happening
 * @param {function} setFieldValue - formik function with the same name
 *
 * @return {void}
 */
import { IEventTerm } from '@Model/event/types';
import { getPreviousForm } from '@Model/transaction/selectors';

const autocompleteForm = (
  autoFill: boolean,
  customTerms: IEventTerm[],
  setFieldValue: (key: string, value: string) => void
) => {
  const data = getPreviousForm();

  if (data) {
    const areTermsValidWithCurrentEvent = () => {
      if (data.values.customTerms && customTerms) {
        return Object.keys(data.values.customTerms).every((term) =>
          customTerms.find((customTerm) => customTerm.id.toString(10) === term)
        );
      }

      return true;
    };

    if (!areTermsValidWithCurrentEvent || !autoFill) {
      delete data.values.customTerms;
    }

    if (!autoFill) {
      delete data.values.terms;
    }

    if (data.values.showBasket) {
      delete data.values.showBasket;
    }

    Object.keys(data.values).map((key) => {
      if (key !== 'pickUpWay' && key !== 'discount') {
        setFieldValue(key, data.values[key]);
      }
    });
  }

  return true;
};

export default autocompleteForm;
