import React from 'react';

import cn from 'classnames';
import moment from 'moment';

import getProperlyDateForDelta from '@Misc/helpers/date/getProperlyDateForDelta';
import splitNumberToDayMonthYear from '@Misc/helpers/date/splitNumberToDayMonthYear';

import styles from './../../DatePicker.module.scss';
import Header from './../Header';
import Month from './../Month';
import {
  prepareHeaderProps,
  prepareMonthProps,
} from './SingleDatePicker.selectors';
import { IDatePickerProps } from './SingleDatePicker.types';

const BEGIN_DATE_TEXT = 'Data początkowa';
const END_DATE_TEXT = 'Data końcowa';

const SingleDatePicker = ({
  defaultDate,
  mobile,
  handleSetDate,
}: IDatePickerProps) => {
  const catchSetMonth = (delta: number) => {
    const dateFromState = moment(defaultDate || new Date());

    const month = dateFromState.month();
    const year = dateFromState.year();

    const { month: newMonth, year: newYear } = getProperlyDateForDelta(
      delta,
      month,
      year
    );

    const date = moment().year(newYear).month(newMonth).date(1).toDate();

    handleSetDate(date);
  };

  const catchSetDate = (hash: number) => {
    const { day, month, year } = splitNumberToDayMonthYear(hash);

    const date = moment()
      .year(year)
      .month(month - 1)
      .date(day)
      .toDate();

    handleSetDate(date);
  };

  return (
    <div className={cn(styles.dataPicker, mobile && styles.mobile)}>
      <div className={styles.headers}>
        <span>{BEGIN_DATE_TEXT}</span>
        <span>{END_DATE_TEXT}</span>
      </div>
      <div className={styles.calendar}>
        <div className={styles.month}>
          <Header {...prepareHeaderProps(catchSetMonth, defaultDate)} />
          <Month {...prepareMonthProps(catchSetDate, defaultDate)} />
        </div>
      </div>
    </div>
  );
};

export default SingleDatePicker;
