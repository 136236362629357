import React, { useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import cn from 'classnames';

import Checkbox from '@Compo/forms/SimpleCheckbox';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import useStyles from './Space.styles';
import { ISpaceProps } from './Space.types';

const Space = ({
  isPriceCalculatedPerPerson,
  lang,
  selectedDay,
  selectedSpace,
  selectedSlot,
  selectedPriceTypes,
  selectSpace,
  spacesLen,
  summedPlayersNum,
  space,
  generateIdempotencyKey,
  selectedTheme,
}: ISpaceProps) => {
  const { isDesktop } = useMuiBreakpoints();
  const classes = useStyles(isDesktop, selectedTheme)();

  const select = () => {
    selectSpace(space.spaceId);
    generateIdempotencyKey();
  };
  const isSelected = selectedSpace === space.spaceId;

  const selectedPriceTypesWithPlayers = isPriceCalculatedPerPerson
    ? selectedPriceTypes.filter(
        (selectedPriceType) =>
          selectedPriceType.numberOfPlayers &&
          selectedPriceType.numberOfPlayers > 0
      )
    : selectedPriceTypes;

  const shouldShowSpace =
    selectedPriceTypesWithPlayers.every((selectedPriceType) =>
      space.prices.find(
        (spacePriceType) =>
          spacePriceType.type === selectedPriceType.priceType?.type
      )
    ) &&
    ((summedPlayersNum && space.capacityLeft >= summedPlayersNum) ||
      space.capacityLeft === null ||
      !isSelected) &&
    space.available;

  const unavailablePriceTypes = selectedPriceTypesWithPlayers.map(
    (selectedPriceType) =>
      !space.prices.find(
        (price) => price.type === selectedPriceType.priceType?.type
      ) && selectedPriceType
  );

  useEffect(() => {
    if (
      spacesLen === 1 &&
      selectedPriceTypes.length > 0 &&
      selectedSlot &&
      selectedDay
    ) {
      return select();
    }
  }, [selectedPriceTypes, selectedDay, selectedSlot]);

  return (
    <li
      className={cn(
        classes.spaceContainer,
        isDesktop && classes.spaceContainerDesktop,
        !shouldShowSpace && classes.disabled
      )}
    >
      <div
        className={cn(classes.linkBox, isDesktop && classes.linkBoxDesktop)}
        onClick={select}
        role={'button'}
      >
        <section
          className={cn(classes.image, isDesktop && classes.imageDesktop)}
          style={{
            backgroundImage: `url(${space.url || ''})`,
          }}
        />
        {!!shouldShowSpace && (
          <Grid
            container={true}
            spacing={4}
            direction={isDesktop ? 'row' : 'column'}
            className={classes.space}
          >
            <Grid
              item={true}
              container={true}
              spacing={1}
              direction={'column'}
              md={8}
              className={classes.spaceDescriptionBox}
            >
              <Grid item={true}>
                <Typography variant={'h6'}>{space.title}</Typography>
              </Grid>
              {space.description && (
                <Grid item={true}>
                  <Typography variant={'body2'}>{space.description}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid item={true} md={4} className={classes.checkbox}>
              <Checkbox checked={isSelected} showChooseButon={true} />
            </Grid>
          </Grid>
        )}
        {!shouldShowSpace && (
          <Grid
            container={true}
            spacing={4}
            direction={'row'}
            className={classes.space}
          >
            <Grid
              item={true}
              container={true}
              spacing={1}
              direction={'column'}
              md={8}
              className={classes.spaceDescriptionBox}
            >
              <Grid item={true}>
                <Typography variant={'h6'}>{space.title}</Typography>
              </Grid>
              {space.description && (
                <Grid item={true}>
                  <Typography variant={'body2'}>{space.description}</Typography>
                </Grid>
              )}
            </Grid>
            {selectedSlot &&
              selectedPriceTypesWithPlayers.length > 0 &&
              space.available && (
                <Grid container={true} item={true} md={4} direction={'column'}>
                  <Grid item={true}>
                    <Typography variant={'caption'} color={'secondary'}>
                      {lang.unavailableTicketTypes}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'caption'}>
                      {unavailablePriceTypes
                        .map(
                          (unavailablePriceType) =>
                            unavailablePriceType &&
                            unavailablePriceType.priceType?.type
                        )
                        .filter((item) => item)
                        .map((item) =>
                          item === 'default' ? lang.defaultTicket : item
                        )
                        .join(', ')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Grid>
        )}
      </div>
    </li>
  );
};

export default Space;
