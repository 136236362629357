import React, { CSSProperties, useEffect, useState } from 'react';

import { Actions, Qualifiers } from '@cloudinary/url-gen';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';

import Link from '@Compo/reusable/DualLink';
import useStyles from '@Compo/reusable/ImageFixed/ImageFixed.styles';
import config from '@Config';
import { Languages } from '@Constants/Languages';
import translateEngLink from '@Misc/helpers/translateEngLink';
import { useLang } from '@Recoil/lang/hooks/useLang';
import { cloudinary } from '@Services/Cloudinary';

import styles from './ImageFixed.module.scss';
import { IImageFixedProps } from './ImageFixed.types';

const {
  Delivery: { format, quality },
  Resize: { fill },
} = Actions;
const {
  Quality: { autoEco },
  Format: { webp },
} = Qualifiers;

const ImageFixed = ({
  aspectRatio,
  isPill,
  fallbackImageUrl,
  gradientOverlay,
  src,
  link: _link,
  title,
  vertical,
  wide,
  isLoading,
  onClick,
  transformation,
  className,
}: IImageFixedProps) => {
  const muiStyles = useStyles(aspectRatio)();
  const [isError, makeError] = useState(false);
  const [showImage, imageLoaded] = useState(false);
  const fallbackMosaic = `${
    config.app.baseUrl
  }/static_legacy/fallbackImg/mosaic_${Math.round(Math.random() * 10) + 1}.jpg`;
  const [source, changeSource] = useState<string>(src || fallbackMosaic);
  const [ref, inView] = useInView({ threshold: 0.01, triggerOnce: true });
  const alt = title || 'Image preview';
  const { language } = useLang();
  const link = translateEngLink(_link || '', language === Languages.English);
  const visibleImage: CSSProperties = { display: showImage ? 'block' : 'none' };

  const imageReady = () => imageLoaded(true);

  const onError = () => {
    if (!isError) {
      makeError(true);
      changeSource(fallbackImageUrl ? fallbackImageUrl : fallbackMosaic);
    }
  };

  const imageElement = !isLoading && inView && (
    <img
      style={visibleImage}
      onLoad={imageReady}
      src={
        isError || source.startsWith('https://')
          ? source
          : cloudinary
              .image(source)
              .resize(
                fill()
                  .width(transformation?.width ?? 'auto')
                  .height(transformation?.height ?? 'auto')
              )
              .delivery(format(webp()))
              .delivery(quality(autoEco()))
              .toURL()
      }
      className={cn(styles.image, isPill && muiStyles.pill, className)}
      alt={alt}
      draggable={'false'}
      onError={onError}
      width={transformation?.width}
      height={transformation?.height}
    />
  );

  useEffect(() => {
    if (!src) changeSource(fallbackMosaic);
    else changeSource(src);
  }, [src]);

  return (
    <>
      {link && (
        <>
          <div ref={ref} />
          <Link
            className={cn(
              styles.imageContainer,
              gradientOverlay && styles.gradientOverlay,
              wide && styles.wide,
              vertical && styles.vertical,
              (isLoading || !showImage) && styles.loading,
              aspectRatio && muiStyles.customAspectRatio
            )}
            to={link}
            onClick={onClick}
            children={imageElement}
          />
        </>
      )}
      {!link && (
        <div
          className={cn(
            styles.imageContainer,
            gradientOverlay && styles.gradientOverlay,
            wide && styles.wide,
            vertical && styles.vertical,
            (isLoading || !showImage) && styles.loading,
            aspectRatio && muiStyles.customAspectRatio
          )}
          onClick={onClick}
          children={imageElement}
          ref={ref}
        />
      )}
    </>
  );
};

export default ImageFixed;
