import React, { FC, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import cn from 'classnames';
import { Form, Formik } from 'formik';

import Breadcrumb from '@Compo/Breadcrumb/Breadcrumb.container';
import FormField from '@Compo/forms/FormField';

import styles from './TicketDataForm.module.scss';
import {
  initialTicketValues,
  initialTransactionValues,
  makeTicketFromTransactionValidationSchema,
  makeTicketValidationSchema,
} from './TicketDataForm.selector';
import { ITicketDataFormProps } from './TicketDataForm.types';
import useStyles from './styles/TicketDataForm.styles';

const TicketDataForm: FC<ITicketDataFormProps> = ({
  isLoading,
  lang,
  location,
  submitForm,
  submitTransactionDataForm,
}) => {
  const params = location.query;

  useEffect(() => {
    if (params?.email && params.entryToken) {
      submitForm({
        code: params.entryToken,
        email: params.email,
      });
    }
  }, [location]);

  const muiStyles = useStyles();

  return (
    <>
      <Breadcrumb
        current={{
          link: '',
          name: '',
        }}
      />
      <div className={cn(styles.formWrapper, muiStyles.root)}>
        <h2 className={styles.pageTitle}>{lang.pageTitle}</h2>

        <Typography variant={'body2'} children={lang.useTicketData} />

        <Formik
          initialValues={initialTicketValues}
          onSubmit={submitForm}
          validationSchema={makeTicketValidationSchema(lang)}
        >
          <Form>
            <label className={styles.label} htmlFor="code">
              <div className={styles.extraField}>{lang.codePlaceholder}</div>
              <FormField
                id="code"
                name="code"
                placeholder={lang.codePlaceholder}
                type="text"
              />
            </label>
            <label className={styles.label} htmlFor="email">
              <div className={styles.extraField}>{lang.emailPlaceholder}</div>
              <FormField
                id="email"
                name="email"
                placeholder={lang.emailPlaceholder}
                type="email"
              />
            </label>
            <button
              className={styles.submitButton}
              type="submit"
              disabled={isLoading}
            >
              {lang.submitButton}
            </button>
          </Form>
        </Formik>

        <>
          <Typography
            variant={'body2'}
            children={lang.useTransactionData}
            className={muiStyles.marginTop}
          />
          <Formik
            initialValues={initialTransactionValues}
            onSubmit={submitTransactionDataForm}
            validationSchema={makeTicketFromTransactionValidationSchema(lang)}
          >
            <Form>
              <label className={styles.label} htmlFor="orderId">
                <div className={styles.extraField}>
                  {lang.orderIdPlaceholder}
                </div>
                <FormField
                  id="orderId"
                  name="orderId"
                  placeholder={lang.orderIdPlaceholder}
                  type="text"
                />
              </label>
              <label className={styles.label} htmlFor="paymentSum">
                <div className={styles.extraField}>
                  {lang.paymentSumPlaceholder}
                </div>
                <FormField
                  id="paymentSum"
                  name="paymentSum"
                  placeholder={lang.paymentSumPlaceholder}
                  type="paymentSum"
                />
              </label>
              <button
                className={styles.submitButton}
                type="submit"
                disabled={isLoading}
              >
                {lang.submitButton}
              </button>
            </Form>
          </Formik>
        </>
      </div>
    </>
  );
};

export default TicketDataForm;
