import React, { FC, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import { Field, useField } from 'formik';

import RadioButton from '@Compo/forms/Radio';
import config from '@Config';
import icons from '@Misc/helpers/icons';
import { specialEventNames } from '@Model/event/constants/specialEvents';

import * as CONST from './../../constants/constants';
import PickUpParameters from './../PickUpParameters';
import PostAddress from './../PostAddress';
import styles from './PickUpWay.module.scss';
import useStyles from './PickUpWay.styles';
import { IPickUpWayProps } from './PickUpWay.types';

const COURIER_COST_TEXT = `(${config.buy.courierDeliveryPrice}zł)`;
const PICK_UP_FORM_NAME = 'pickUpWay';
const CURRENCY_TEXT = 'zł';

const BLOK_PARTY_COPY = 'Wybierz sposób odbioru pakietów';

const PickUpWay: FC<IPickUpWayProps> = ({
  courierAvailable,
  courierRequired,
  isDisabled,
  isProduct,
  lang,
  number,
  showAddressForm,
  specialEventCopy,
  shipmentDeliveryMounted,
  shipments,
  selectedTheme,
}) => {
  // TODO: || courierAvailable
  const showPostDelivery = courierRequired;
  const showMailDelivery =
    !showPostDelivery || (showPostDelivery && !courierRequired);
  const [field, meta, { setValue }] = useField(PICK_UP_FORM_NAME);

  const theme = useTheme();
  const muiStyles = useStyles(theme);
  const isDefaultTheme = selectedTheme === 'default';

  const checkIsOnShipmentsList = (id: string) =>
    shipments.some((shipment) => String(shipment.productId) === id);

  useEffect(() => {
    if (config.features.showNewShipmentDelivery) {
      shipmentDeliveryMounted();
    }
  }, []);

  useEffect(() => {
    if (courierRequired) {
      setValue(CONST.PICK_UP_WAY_POST);
    } else {
      setValue(CONST.PICK_UP_WAY_EMAIL);
    }
  }, [courierRequired]);

  return (
    <div className={cn(styles.pickUpWay, isDisabled && styles.disabled)}>
      <Typography
        variant={'h3'}
        className={muiStyles.header}
        data-number={number}
      >
        {specialEventCopy === specialEventNames.blokParty
          ? BLOK_PARTY_COPY
          : isProduct
          ? lang.pickUpProduct
          : lang.pickUp}
      </Typography>
      <fieldset
        className={cn(
          styles.formPanel,
          muiStyles.root,
          !isDefaultTheme && muiStyles.withoutPaddings
        )}
      >
        {showMailDelivery && (
          <div className={styles.label}>
            <Field
              component={RadioButton}
              name={PICK_UP_FORM_NAME}
              id={CONST.PICK_UP_WAY_EMAIL}
            >
              {lang.sendAsEmail}
              <span
                className={cn(styles.icon, icons.mail, muiStyles.fontColor)}
              />
              {!specialEventCopy && (
                <span className={cn(styles.specialInfo, muiStyles.fontColor)}>
                  {lang.rightAway}
                </span>
              )}
            </Field>
          </div>
        )}

        {config.features.showNewShipmentDelivery && showPostDelivery && (
          <>
            {shipments.map((shipment) => (
              <div className={styles.label}>
                <Field
                  component={RadioButton}
                  name={PICK_UP_FORM_NAME}
                  id={String(shipment.productId)}
                >
                  {`${shipment.description} (${shipment.price}${CURRENCY_TEXT})`}
                </Field>
              </div>
            ))}

            {checkIsOnShipmentsList(field.value) && (
              <PostAddress specialEventCopy={specialEventCopy} />
            )}
          </>
        )}

        {!config.features.showNewShipmentDelivery && showPostDelivery && (
          <>
            <div className={styles.label}>
              <Field
                component={RadioButton}
                name={PICK_UP_FORM_NAME}
                id={CONST.PICK_UP_WAY_POST}
              >
                {lang.courierDelivery + COURIER_COST_TEXT}
                <span
                  className={cn(styles.icon, icons.lorry, muiStyles.fontColor)}
                />
              </Field>
            </div>

            {config.features.giftWrapper && <PickUpParameters />}

            {/* <div className={cn(styles.label, styles.labelDisabled)}>
            <Field component={RadioButton} name="pickUpWay"
                    id={PICK_UP_WAY_OWN}>
              {PICK_UP_ON_CLIENTS_OWN_TEXT}
              <span className={cn(styles.icon, icons.location)}/>
            </Field>
          </div> */}

            {showAddressForm && (
              <PostAddress specialEventCopy={specialEventCopy} />
            )}
          </>
        )}
      </fieldset>
    </div>
  );
};

export default PickUpWay;
