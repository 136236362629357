import { Selector } from 'reselect';

import _Store from '@Store';

import { ITrafficParamsReducer } from './../types';

const get: Selector<_Store.IState, ITrafficParamsReducer> = (state) =>
  state.trafficParams;

export default get;
