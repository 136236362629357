import axios, { CancelTokenSource } from 'axios';

import EventsListService from '@Misc/classes/EventsListService';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import { IArticleFull } from '@Model/article/types';

import { sources } from './sources';
import { IArticleResponse, IArticleRouterParams, ISources } from './types';

class ArticleApi extends EventsListService {
  private cancelTokenArticle?: CancelTokenSource;

  public makeKeyFromParams(params: IArticleRouterParams) {
    return `${params.articleSlug}`;
  }

  public getArticleUrl(sourceSlug: ISources, articleSlug: string): string {
    return `${sources[sourceSlug]}wp-json/wp/v2/posts?_embed&slug=${articleSlug}`;
  }

  public getSingleArticle(
    sourceSlug: ISources,
    articleSlug: string
  ): Promise<IArticleFull> {
    return new Promise((resolve, reject) => {
      const articleUrl = this.getArticleUrl(sourceSlug, articleSlug);
      this.cancelTokenArticle = axios.CancelToken.source();

      axios
        .get(
          articleUrl,
          withCacheHeader({
            cancelToken: this.cancelTokenArticle.token,
          })
        )
        .then(getData)
        .then((response) => {
          resolve(this.normalizeFullArticle(response[0]));
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }

  public normalizeFullArticle(response: IArticleResponse): IArticleFull {
    return {
      articleSlug: response.slug,
      authors: response._embedded.author.map((author) => {
        return {
          avatarUrl: author.avatar_urls['96'],
          link: author.link,
          name: author.name,
        };
      }),
      html: response.content.rendered,
      imageUrl:
        response._embedded['wp:featuredmedia'] &&
        response._embedded['wp:featuredmedia'][0].source_url,
      paragraphs: [],
      tags: [],
      title: response.title.rendered,
    };
  }
}

export default new ArticleApi();
