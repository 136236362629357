import React, { useState } from 'react';

import cn from 'classnames';
import format from 'date-fns/format';
import { enGB, pl } from 'date-fns/locale';

import ToolTip from '@Compo/reusable/ToolTip';
import config from '@Config';
import capitalize from '@Misc/helpers/capitalize';

import ButtonDescription from './../ButtonDescription';
import Pools from './../Pools';
import styles from './Ticket.module.scss';
import { ITicketProps } from './Ticket.types';

const INVALID_DATE = 'Invalid Date';

const TicketComponent: React.FC<ITicketProps> = ({
  dateTranslate,
  lang,
  isMobile,
  samePlace,
  selectPool,
  ticket,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const handleClick = () => {
    setIsDetailsOpen(!isDetailsOpen);
  };

  const HOUR_TEXT = !config.theme.isGoing && lang.hour;
  const DISABLED_TOOLTIP_TEXT =
    lang.disabledTooltip + (config.theme.isGoing ? 'Going.' : 'Empik Bilety');
  const locale = dateTranslate?.locale === 'en' ? enGB : pl;
  const _date = new Date(ticket.rundate);
  const timezonedDate =
    _date.toString() === INVALID_DATE
      ? new Date()
      : new Date(
          new Date(_date.valueOf() + _date.getTimezoneOffset() * 60 * 1000)
        );

  return (
    <div className={styles.borderContainer}>
      <div
        className={cn(
          styles.singleTicketContainer,
          (!ticket.poolsData?.pools.length ||
            !config.features.showTicketDetails) &&
            styles.withoutDetails
        )}
      >
        <div className={styles.dateContainer}>
          <span className={styles.day}>
            {format(timezonedDate, 'd', { locale })}
          </span>
          <span className={styles.month}>
            {capitalize(format(timezonedDate, 'MMM', { locale }))}
          </span>
        </div>
        <div className={styles.ticketKind}>
          {capitalize(
            format(
              timezonedDate,
              !isMobile
                ? HOUR_TEXT
                  ? `EEEE, '${HOUR_TEXT}' kk:mm`
                  : 'EEEE, kk:mm'
                : 'd MMM, EEEE kk:mm',
              { locale }
            )
          )}
          {!samePlace && (
            <div
              className={styles.placeName}
            >{`${ticket.placeName}, ${ticket.cityName}`}</div>
          )}
        </div>
        <div className={styles.singleTicketContainerPrice}>
          <div className={styles.priceContainer}>
            <div className={styles.priceTextContainer}>
              <span className={styles.priceText}>od </span>
              <span className={styles.price}>
                {Number(ticket.price) || ticket.priceDescription}
              </span>
              {ticket.price && (
                <ToolTip title={lang.serviceFeeTooltip}>
                  <span className={styles.currency}>{lang.currency}</span>
                </ToolTip>
              )}
            </div>
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          {ticket.poolsData?.isAvailable && (
            <button
              className={styles.button}
              onClick={() => selectPool(ticket.eventSlug, ticket.rundateSlug)}
            >
              {lang.choose}
            </button>
          )}
          {!ticket.poolsData?.isAvailable && (
            <ToolTip bottom={true} title={DISABLED_TOOLTIP_TEXT} left={true}>
              <span className={cn(styles.button, styles.disabledButton)}>
                {lang.choose}
              </span>
            </ToolTip>
          )}
        </div>
        {!!ticket.poolsData?.pools.length &&
          config.features.showTicketDetails && (
            <div className={styles.mobileDisplayNone}>
              <ButtonDescription
                buttonText={lang.details}
                onClick={handleClick}
              />
            </div>
          )}
      </div>
      {!!ticket.poolsData?.pools.length &&
        config.features.showTicketDetails && (
          <div
            className={cn(
              styles.detailsTitle,
              isDetailsOpen && styles.borderRadius
            )}
          >
            <ButtonDescription
              buttonText={lang.details}
              onClick={handleClick}
            />
          </div>
        )}
      {isDetailsOpen && !!ticket.poolsData?.pools.length && (
        <div className={styles.descriptionContainer}>
          {ticket.poolsData.pools.map((pool, id) => (
            <Pools
              key={id}
              pool={pool}
              eventSlug={ticket.eventSlug}
              onlyOneTicket={false}
              rundateSlug={ticket.rundateSlug}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TicketComponent;
