import React, { FC, useEffect } from 'react';

import { eighteenGateSlugs } from '@/config/eighteenGate';

import Bricks from '@Compo/Bricks';
import Helmet from '@Compo/helmet/components/CompositionPage';
import MuiGrid from '@Compo/layout/MuiGrid';
import AuthGate from '@Compo/modals/AuthGate';
import EighteenGate from '@Compo/reusable/EighteenGate';
import { BricksEnum } from '@Model/pages/constants/bricks';
import { ISectionsComponentAgeGate } from '@Model/pages/types/sections';

import { IRecommendProps } from './Recommend.types';

const GOING_HELP_TEXT = '/polecamy/going-help';

const Recommend: FC<IRecommendProps> = ({
  authPage,
  history,
  location,
  mounted,
  structure,
  pagesAuth,
  userLocation,
}) => {
  const isEighteenGateFromEnum =
    structure?.slug && eighteenGateSlugs.includes(structure?.slug); // TODO: 4 backward compatibility, remove when not needed anymore
  const getEighteenGate = () => {
    const sectionWithGate = structure?.sections.find(
      (section) =>
        !!section.components.find(
          (component) => component.type === BricksEnum[BricksEnum.age_gate]
        )
    );

    const ageGate = sectionWithGate?.components.find(
      (component) => component.type === BricksEnum[BricksEnum.age_gate]
    );

    const data = ageGate?.data?.length && ageGate.data[0];

    return data as ISectionsComponentAgeGate | undefined;
  };

  const eighteenGate = getEighteenGate();

  useEffect(() => {
    mounted();
  }, [location.pathname, history]);

  return (
    <MuiGrid withoutSidePaddings={true}>
      <Helmet isGoingHelp={GOING_HELP_TEXT === location.pathname} />
      <Bricks structure={structure} userLocation={userLocation} />
      <AuthGate
        action={authPage}
        error={pagesAuth.error}
        show={pagesAuth.showGate}
        payload={pagesAuth.originalPayload}
      />
      {isEighteenGateFromEnum && structure?.slug && (
        <EighteenGate slug={structure.slug} />
      )}

      {eighteenGate && structure?.slug && (
        <EighteenGate
          slug={structure.slug}
          title={eighteenGate.title}
          description={eighteenGate.description}
          yesLabel={eighteenGate.yesLabel}
          noLabel={eighteenGate.noLabel}
        />
      )}
    </MuiGrid>
  );
};

export default Recommend;
