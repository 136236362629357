import axios from 'axios';

import config from '@Config';
import EventsListService from '@Misc/classes/EventsListService';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

import { ILandingPageResponse, ILandingRouterParams } from './types';

class LandingPageApi extends EventsListService {
  public makeKeyFromParams(params: ILandingRouterParams) {
    return `${params.landingSlug}`;
  }
  public getLandingPage(landingSlug: string): Promise<ILandingPageResponse> {
    const hash = Date.now();
    const landingUri = `${config.app.landingPageUrl}${landingSlug}.json?rev=${hash}`;

    return new Promise((resolve, reject) => {
      axios
        .get(landingUri)
        .then(getData)
        .then((response: ILandingPageResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}

export default new LandingPageApi();
