import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';

import { IExtendedSlot } from '@Model/happening/types';

import useStyles from './MultipleSlotModal.styles';
import { IMultipleSlotModalProps } from './MultipleSlotModal.types';

const EXTEND_GAME_TIME_TEXT = 'Wydłuż swoją grę!';
const DESCRIPTION_TEXT =
  'Daj sobie więcej czasu! Wybierz, o ile chcesz przedłużyć grę lub pomiń ten krok.';
const I_DO_NOT_WANT_TO_EXTEND_TEXT = 'Nie chcę wydłużać';
const EXTEND_GAME_TEXT = 'Przedłuż grę';
const MIN_TEXT = 'min';
const CHEAPER_TEXT = 'taniej';

const MultipleSlotModal = ({
  extendedSlots,
  setFormExtendedSlot,
  modalOpen,
  setModalOpen,
  isEmbed,
}: IMultipleSlotModalProps) => {
  const [extendedSlot, setExtendedSlot] = useState<IExtendedSlot | null>(null);
  const [iframeModalPosition, setIframeModalPosition] = useState<number | null>(
    null
  );

  const classes = useStyles();

  const getMousePosition = (event: MouseEvent) => {
    if (!modalOpen) {
      setIframeModalPosition(event.clientY * 2);
    }
  };

  const selectSlot = (slot: IExtendedSlot) => {
    if (slot.duration === extendedSlot?.duration) {
      setExtendedSlot(null);
    } else {
      setExtendedSlot(slot);
    }
  };

  const onClose = () => {
    setModalOpen(false);
    setExtendedSlot(null);
  };

  const selectExtendedSlot = () => {
    setFormExtendedSlot(extendedSlot);
    onClose();
  };

  useEffect(() => {
    setFormExtendedSlot(null);
    if (isEmbed) {
      document.addEventListener('click', getMousePosition);
    }

    return () => {
      if (isEmbed) {
        document.removeEventListener('click', getMousePosition);
      }
    };
  }, []);

  return (
    <Dialog
      open={modalOpen}
      maxWidth={'sm'}
      fullWidth={true}
      onClose={onClose}
      scroll={'paper'}
      classes={{ paper: classes.modal }}
      style={{ height: iframeModalPosition || '100%' }}
      disableAutoFocus={true}
    >
      <DialogTitle>
        <Typography variant={'h5'}>{EXTEND_GAME_TIME_TEXT}</Typography>
        <IconButton className={classes.cancelIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Typography variant={'body1'}>{DESCRIPTION_TEXT}</Typography>
          </Grid>

          <Grid item={true} xs={12}>
            <Grid container={true} spacing={2}>
              {extendedSlots.map((value) => (
                <Grid key={value.duration} item={true} xs={12} sm={6} md={3}>
                  <Button
                    fullWidth={true}
                    variant={'outlined'}
                    size={'medium'}
                    color={'primary'}
                    onClick={() => selectSlot(value)}
                    className={cn(
                      classes.button,
                      extendedSlot?.duration === value.duration &&
                        classes.activeButton
                    )}
                  >
                    <Typography
                      variant={'button'}
                      className={cn(
                        (value.upsellPercentageValue || value.upsellValue) &&
                          classes.buttonLine
                      )}
                      component={'p'}
                    >
                      +{value.duration} {MIN_TEXT}
                    </Typography>
                    {value.upsellPercentageValue && (
                      <Typography variant={'caption'}>
                        {value.upsellPercentageValue} % {CHEAPER_TEXT}
                      </Typography>
                    )}
                    {value.upsellValue && (
                      <Typography variant={'caption'}>
                        {value.upsellValue} zł {CHEAPER_TEXT}
                      </Typography>
                    )}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            {!!extendedSlot ? (
              <Button
                size={'large'}
                variant={'contained'}
                color={'primary'}
                fullWidth={true}
                onClick={selectExtendedSlot}
              >
                {EXTEND_GAME_TEXT}
              </Button>
            ) : (
              <Button
                size={'large'}
                variant={'contained'}
                color={'secondary'}
                fullWidth={true}
                onClick={onClose}
              >
                {I_DO_NOT_WANT_TO_EXTEND_TEXT}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MultipleSlotModal;
