import React from 'react';

import Place from '@Compo/Place';
import PlaceEvents from '@Compo/PlaceEvents';
import Helmet from '@Compo/helmet/components/PlacePage';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import { IBreadcrumb } from '@Model/breadcrumb/types';
import routes from '@Routes/routes';

const PLACES_LIST_TEXT = 'Miejsca';

const breadcrumbs: IBreadcrumb[] = [
  {
    link: routes.places,
    name: PLACES_LIST_TEXT,
  },
];

const PlacePage = () => (
  <MuiGrid>
    <Helmet />
    <MuiSectionContainer>
      <MuiBreadcrumbs base={breadcrumbs} />
      <Place />
      <PlaceEvents />
    </MuiSectionContainer>
  </MuiGrid>
);

export default PlacePage;
