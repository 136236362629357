import { Location } from 'history';
import { createSelector } from 'reselect';

import _Store from '@Store';

import getLocation from './getLocation';

const getKey = createSelector<_Store.IState, Location, string | undefined>(
  [getLocation],
  (router) => router.key
);

export default getKey;
