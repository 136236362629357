import React from 'react';

import FormField from '@Compo/forms/FormField';
import { specialEventNames } from '@Model/event/constants/specialEvents';

import styles from './PostAddress.module.scss';
import { IPostAddressProps } from './PostAddress.types';

const PostAddress = ({ id, lang, specialEventCopy }: IPostAddressProps) => {
  const isSbmFestival = specialEventCopy === specialEventNames.sbmEvent;

  const formatFieldName = (name: string) => (id ? `${name}_${id}` : `${name}`);

  return (
    <div className={styles.addressBlock} data-test-id={'post-address'}>
      <div className={styles.wrap}>
        <p className={styles.info}>{lang.infoText}</p>
        <div className={styles.name}>
          <FormField
            data-test-id={'post-address-deliveryFirstname'}
            id={formatFieldName('deliveryFirstname')}
            name={formatFieldName('deliveryFirstname')}
            placeholder={`${lang.firstNameText}*`}
            type="text"
          />
        </div>
        <div className={styles.name}>
          <FormField
            data-test-id={'post-address-deliveryLastname'}
            id={formatFieldName('deliveryLastname')}
            name={formatFieldName('deliveryLastname')}
            placeholder={`${lang.lastNameText}*`}
            type="text"
          />
        </div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.street}>
          <FormField
            data-test-id={'post-address-deliveryStreet'}
            id={formatFieldName('deliveryStreet')}
            name={formatFieldName('deliveryStreet')}
            placeholder={`${lang.streetText}*`}
            type="text"
          />
        </div>
        <div className={styles.number}>
          <FormField
            data-test-id={'post-address-deliveryNumberStreet'}
            id={formatFieldName('deliveryNumberStreet')}
            name={formatFieldName('deliveryNumberStreet')}
            placeholder={`${lang.streetNumberText}*`}
            type="text"
          />
        </div>
        <div className={styles.number}>
          <FormField
            id={formatFieldName('deliveryLocalStreet')}
            name={formatFieldName('deliveryLocalStreet')}
            placeholder={`${lang.localText}`}
            type="text"
          />
        </div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.code}>
          <FormField
            data-test-id={'post-address-deliveryZip'}
            id={formatFieldName('deliveryZip')}
            name={formatFieldName('deliveryZip')}
            placeholder={`${lang.zipText}*`}
            type="text"
          />
        </div>
        <div className={styles.city}>
          <FormField
            data-test-id={'post-address-deliveryCity'}
            id={formatFieldName('deliveryCity')}
            name={formatFieldName('deliveryCity')}
            placeholder={`${lang.cityText}*`}
            type="text"
          />
        </div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.phone}>
          <FormField
            data-test-id={'post-address-deliveryPhone'}
            id={formatFieldName('deliveryPhone')}
            name={formatFieldName('deliveryPhone')}
            placeholder={`${
              isSbmFestival ? lang.collectorsTicket : lang.phoneText
            }*`}
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

export default PostAddress;
