import app from './app';
import theme from './theme';

const features = {
  basket: app.onlineSale,
  courierSummary: app.showUnfinishedFeatures,
  deepLinkBar: true,
  eventBricks: app.showUnfinishedFeatures,
  gallery: true,
  giftWrapper: false,
  happeningSales: true,
  onsiteGiftCard: app.showUnfinishedFeatures,
  showHappeningDurationTime: false,
  showHeart: false,
  showLinkToPlace: theme.isEmpik,
  showNewShipmentDelivery: true,
  showTicketDetails: false,
  ticketManagement: true,
  useAwsApi: true,
};

export default features;
