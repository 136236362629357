import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { getLocation } from '@Model/router/selectors';
import { MYTICKET } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import {
  ticketDataFormSubmit,
  transactionDataFormSubmit,
} from '@Model/tickets/actions';

import TicketDataForm from './TicketDataForm.component';
import {
  ITicketDataFormFromDispatch,
  ITicketDataFormFromState,
} from './TicketDataForm.types';

const mapStateToProps = (state: _Store.IState): ITicketDataFormFromState => {
  return {
    isLoading: getLoading(MYTICKET)(state),
    lang: translate(state)('ticketsManagement', 'ticketDataForm'),
    location: getLocation(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketDataFormFromDispatch => {
  return {
    submitForm: (payload) => dispatch(ticketDataFormSubmit(payload)),
    submitTransactionDataForm: (payload) =>
      dispatch(transactionDataFormSubmit(payload)),
  };
};

export default connect<
  ITicketDataFormFromState,
  ITicketDataFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TicketDataForm);
