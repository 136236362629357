const getInfoAboutMonth = (
  month: number,
  year: number
): {
  length: number;
  startsAt: number;
} => {
  return {
    length: 32 - new Date(year, month, 32).getDate(),
    startsAt: (new Date(year, month, 1).getDay() + 6) % 7,
  };
};

export default getInfoAboutMonth;
