import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(2),
    },
    image: {
      height: '100%',
      marginRight: '2rem',
      objectFit: 'contain',
      width: '100%',
    },
  })
);

export default useStyles;
