import React from 'react';

import EntryList from '@/components/pages/EntryList';
import { Route, Switch } from 'react-router';

import EventEmbed from '@Compo/Event';
import Formio from '@Compo/Formio';
import Happening from '@Compo/Happening/';
// Embed (pins) component
import Online from '@Compo/buying/Online/Online.container';
import ProductsBuyEmbed from '@Compo/buying/Products';
import CardPaymentForm from '@Compo/buying/components/CardPaymentForm';
// Import components
import Error404 from '@Compo/errors/Error404';
import ErrorBasic from '@Compo/errors/ErrorBasic';
import {
  Embed,
  HiddenRoute,
  Switch as InternalSwitch,
  Page,
} from '@Compo/functional/InternalRouter';
import PayUContextProvider from '@Compo/functional/PayUContextProvider';
// Page Components
import About from '@Compo/pages/About';
import Activity from '@Compo/pages/Actitvity';
import Activities from '@Compo/pages/Activities';
import Artist from '@Compo/pages/ArtistPage';
import BasketPage from '@Compo/pages/Basket';
import BasketBuy from '@Compo/pages/BasketBuy';
// Recoil Components
import BetaEvent from '@Compo/pages/BetaEvent';
import Buy from '@Compo/pages/Buy';
import ChangeDatePage from '@Compo/pages/ChangeDate';
import DeeplinkTicket from '@Compo/pages/DeeplinkTicket';
import Discount from '@Compo/pages/Discount';
import Event from '@Compo/pages/EventPage';
import FormioPage from '@Compo/pages/Formio';
import HappeningPage from '@Compo/pages/HappeningPage';
import HappeningRegisterPage from '@Compo/pages/HappeningRegisterPage';
import Home from '@Compo/pages/HomePage';
import Inspirations from '@Compo/pages/Inspirations';
import LandingPage from '@Compo/pages/LandingPage';
import MyTicketPage from '@Compo/pages/MyTicket';
import MyTicketsPage from '@Compo/pages/MyTickets';
import PasswordResetPage from '@Compo/pages/PasswordResetPage/PasswordResetPage.component';
import Payment from '@Compo/pages/Payment';
import PaymentFail from '@Compo/pages/PaymentFail';
import PaymentSuccess from '@Compo/pages/PaymentSuccess';
import Place from '@Compo/pages/PlacePage';
import ProductsBuy from '@Compo/pages/ProductsBuy';
import ReceiveTicket from '@Compo/pages/ReceiveTicket';
import Recommend from '@Compo/pages/Recommend';
import Search from '@Compo/pages/Search';
import Selection from '@Compo/pages/Selection';
import SendMailSuccessPage from '@Compo/pages/SendMailSuccess';
import StaticArticle from '@Compo/pages/StaticArticle';
import TransferSuccessPage from '@Compo/pages/TransferSuccess';
import TransferTicketPage from '@Compo/pages/TransferTicket';
import ChangeDate from '@Compo/ticketsManagement/ChangeDate';
import MyTicket from '@Compo/ticketsManagement/MyTicket';
import MyTickets from '@Compo/ticketsManagement/MyTickets';
import TicketRecipience from '@Compo/ticketsManagement/TicketRecipience';
import TransferSummary from '@Compo/ticketsManagement/TransferSummary';
import TransferTicket from '@Compo/ticketsManagement/TransferTicket';
import config from '@Config';

// Import module's constants
import * as MODULES from './../modules';
import routes from './../routes';

const Routes = () => (
  <>
    <Switch>
      <Route
        exact={true}
        path={routes.betaEvent}
        component={Page(BetaEvent, MODULES.BETA_EVENT)}
      />

      <Route
        exact={true}
        path={routes.mainPage}
        component={Page(Home, MODULES.HOME)}
      />

      <Route
        exact={true}
        path={routes.index}
        component={Page(Home, MODULES.HOME)}
      />

      <Route
        exact={true}
        path={routes.inspirationsDefault}
        component={Page(Inspirations, MODULES.INSPIRATIONS)}
      />

      <Route
        exact={true}
        path={routes.inspirations}
        component={Page(Inspirations, MODULES.INSPIRATIONS)}
      />

      <Route
        exact={true}
        path={routes.activities}
        component={Page(Activities, MODULES.ACTIVITIES)}
      />

      <Route
        exact={true}
        path={routes.activitiesDefault}
        component={Page(Activities, MODULES.ACTIVITIES)}
      />

      <Route
        exact={true}
        path={routes.recommend}
        component={Page(Recommend, MODULES.RECOMMEND)}
      />

      <Route
        exact={true}
        path={routes.landingPage}
        component={Page(LandingPage, MODULES.LANDING_PAGE)}
      />

      <Route path={routes.eventLegacy} component={Page(Event, MODULES.EVENT)} />

      <Route path={routes.buyLegacy} component={Page(Buy, MODULES.BUY)} />

      <Route path={routes.placeLegacy} component={Page(Place, MODULES.PLACE)} />

      <Route
        path={routes.selectionLegacy}
        component={Page(Selection, MODULES.SELECTION)}
      />

      <Route
        exact={true}
        path={routes.staticArticle}
        component={Page(StaticArticle, MODULES.STATIC_ARTICLE)}
      />

      {config.features.basket && (
        <Route
          exact={true}
          path={routes.basket}
          component={Page(BasketPage, MODULES.BASKET)}
        />
      )}

      {config.features.basket && (
        <Route
          exact={true}
          path={routes.basketBuy}
          component={Page(BasketBuy, MODULES.BASKET_BUY)}
        />
      )}

      <Route
        exact={true}
        path={routes.selection}
        component={Page(Selection, MODULES.SELECTION)}
      />

      <Route
        exact={true}
        path={routes.place}
        component={Page(Place, MODULES.PLACE)}
      />

      <Route
        exact={true}
        path={routes.artist}
        component={Page(Artist, MODULES.ARTIST)}
      />

      {config.features.happeningSales && (
        <Route
          exact={true}
          path={routes.happening}
          component={Page(HappeningPage, MODULES.HAPPENING)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.myTicket}
          component={Page(MyTicketsPage, MODULES.MY_TICKETS)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.myTicketEdit}
          component={Page(MyTicketPage, MODULES.MY_TICKET)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.transferTicket}
          component={Page(TransferTicketPage, MODULES.TRANSFER_TICKET)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.transferSuccess}
          component={Page(TransferSuccessPage, MODULES.TRANSFER_SUCCESS)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.receiveTicketSuccess}
          component={Page(PaymentSuccess, MODULES.RECEIVE_SUCCESS)}
        />
      )}

      <Route
        exact={true}
        path={routes.receiveTicketFail}
        component={Page(PaymentFail, MODULES.RECEIVE_FAIL)}
      />

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.changeDate}
          component={Page(ChangeDatePage, MODULES.CHANGE_DATE)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.receiveTicket}
          component={Page(ReceiveTicket, MODULES.RECEIVE_TICKET)}
        />
      )}

      <Route
        exact={true}
        path={routes.events}
        component={Page(Search, MODULES.SEARCH)}
      />

      <Route
        exact={true}
        path={routes.event}
        component={Page(Event, MODULES.EVENT)}
      />

      <Route
        exact={true}
        path={routes.engEvent}
        component={Page(Event, MODULES.EVENT, 'en')}
      />

      <Route
        exact={true}
        path={routes.activity}
        component={Page(Activity, MODULES.ACTIVITY)}
      />

      <Route
        exact={true}
        path={routes.buy}
        component={Page(Buy, MODULES.BUY)}
      />

      <Route
        exact={true}
        path={routes.buyProducts}
        component={Page(ProductsBuy, MODULES.PRODUCTS_BUY)}
      />

      <Route
        exact={true}
        path={routes.engBuy}
        component={Page(Buy, MODULES.BUY, 'en')}
      />

      <Route
        exact={true}
        path={routes.discount}
        component={Page(Discount, MODULES.DISCOUNT)}
      />

      <Route
        exact={true}
        path={routes.payment}
        render={Page(Payment, MODULES.PAYMENT)}
      />

      <Route
        exact={true}
        path={routes.paymentFail}
        component={Page(PaymentFail, MODULES.SUMMARY_FAIL)}
      />

      <Route
        exact={true}
        path={routes.paymentSuccess}
        component={Page(PaymentSuccess, MODULES.SUMMARY_SUCCESS)}
      />

      <Route
        exact={true}
        path={routes.engPayment}
        render={Page(Payment, MODULES.PAYMENT, 'en')}
      />

      <Route
        exact={true}
        path={routes.engPaymentFail}
        component={Page(PaymentFail, MODULES.SUMMARY_FAIL, 'en')}
      />

      <Route
        exact={true}
        path={routes.engPaymentSuccess}
        component={Page(PaymentSuccess, MODULES.SUMMARY_SUCCESS, 'en')}
      />

      <Route
        exact={true}
        path={routes.about}
        component={Page(About, MODULES.ABOUT)}
      />

      <Route
        exact={true}
        path={routes.contact}
        component={Page(FormioPage, MODULES.FORMIO)}
      />

      <Route
        exact={true}
        path={routes.form}
        component={Page(FormioPage, MODULES.FORMIO)}
      />

      <Route
        exact={true}
        path={routes.passwordReset}
        component={Page(PasswordResetPage, MODULES.PASSWORD_RESET)}
      />

      <Route
        exact={true}
        path={routes.entryList}
        component={Page(HappeningRegisterPage, MODULES.HAPPENING_REGISTER)}
      />

      <Route
        exact={true}
        path={routes.sendMailSuccess}
        component={Page(SendMailSuccessPage, MODULES.SEND_MAIL_SUCCESS)}
      />

      <Route
        exact={true}
        path={routes.engBuyActivity}
        component={Page(HappeningPage, MODULES.HAPPENING, 'en')}
      />

      {/* EMBEDS */}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinMyTicket}
          component={Embed(MyTickets)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinMyTicketEdit}
          component={Embed(MyTicket)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinTransferTicket}
          component={Embed(TransferTicket)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinTransferSuccess}
          component={Embed(TransferSummary)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinReceiveSummary}
          component={Embed(TransferSummary)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinReceiveSummary}
          component={Embed(TransferSummary)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinChangeDate}
          component={Embed(ChangeDate)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinReceiveTicket}
          component={Embed(TicketRecipience)}
        />
      )}

      <Route
        exact={true}
        path={routes.deeplinkTicket}
        component={Page(DeeplinkTicket, MODULES.DEEPLINK_TICKET)}
      />

      <Route exact={true} path={routes.pinForm} component={Embed(Formio)} />

      <Route
        exact={true}
        path={routes.pinBuy}
        component={Embed(() => {
          const cardPaymentForm = <CardPaymentForm />;

          return (
            <PayUContextProvider>
              <Online cardPaymentForm={cardPaymentForm} />
            </PayUContextProvider>
          );
        }, MODULES.EMBED_BUY)}
      />

      <Route
        exact={true}
        path={routes.pinEvent}
        component={Embed(EventEmbed)}
      />

      <Route
        exact={true}
        path={routes.pinHappening}
        component={Embed(Happening, MODULES.EMBED_HAPPENING)}
      />

      <Route path={routes.pinEventId} component={Embed(EventEmbed)} />

      <Route
        path={routes.pinBuyId}
        component={Embed(() => {
          const cardPaymentForm = <CardPaymentForm />;

          return (
            <PayUContextProvider>
              <Online cardPaymentForm={cardPaymentForm} />
            </PayUContextProvider>
          );
        }, MODULES.EMBED_BUY)}
      />

      <Route
        exact={true}
        path={routes.pinProducts}
        component={Embed(ProductsBuyEmbed, MODULES.EMBED_BUY_PRODUCTS)}
      />

      <Route
        exact={true}
        path={routes.pinEntryList}
        component={Embed(EntryList)}
      />

      <Route component={Page(Error404, MODULES.ERROR_404)} />

      <HiddenRoute component={Page(ErrorBasic, MODULES.ERROR_BASIC)} />
    </Switch>
    <InternalSwitch />
  </>
);

export default Routes;
