import { connect } from 'react-redux';

import _Store from '@Store';

import { isBrowser } from '@Model/__base__/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';

import CookieBar from './CookieBar.component';
import { ICookieBarFromState } from './CookieBar.types';

function mapStateToProps(state: _Store.IState): ICookieBarFromState {
  return {
    isBrowser: isBrowser(state),
    isEmbed: !!getIframeParams(state),
  };
}

export default connect<ICookieBarFromState, null, {}, _Store.IState>(
  mapStateToProps
)(CookieBar);
