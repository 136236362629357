import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { informAboutError404 } from '@Model/errors/actions';
import { getIframeParams } from '@Model/internalRouter/selectors';
import { getLocation } from '@Model/router/selectors';

import Error404 from './Error404.component';
import { IError404FromDispatch, IError404FromState } from './Error404.types';

function mapStateToProps(state: _Store.IState): IError404FromState {
  return {
    isEmbed: !!getIframeParams(state),
    location: getLocation(state),
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IError404FromDispatch => ({
  informAboutError: () => dispatch(informAboutError404()),
});

export default connect<
  IError404FromState,
  IError404FromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Error404);
