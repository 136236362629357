import React from 'react';

import styles from './Loading.module.scss';

const Loading = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.fakeContent}>
        <div className={styles.fakeTitle} />

        <div className={styles.infoBox}>
          <p className={styles.fakeInfo} />
          <p className={styles.fakeInfo} />
        </div>
      </div>
    </div>
  );
};

export default Loading;
