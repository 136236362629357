import { getType } from 'typesafe-actions';

import config from '@Config';
import checkFilterDuplicate from '@Misc/helpers/checkFilterDuplicate';
import AWSSearchApi from '@Services/$awsSearch-api';

import {
  changeCityFilter,
  fetchFacets,
  fetchSearchResults,
  removeDateFilter,
  removeSearchFilter,
  resetFilters,
  resetSearchOffset,
  setDateFilter,
  setLastFilter,
  setSearchFilter,
  setSearchOffset,
  setSearchPhrase,
  switchView,
} from './../actions';
import { IAction, ISearchReducer } from './../types';

export const initialState: ISearchReducer = {
  datesFilter: {
    from: null,
    to: null,
  },
  categories: [],
  tags: [],
  filters: [],
  isLoading: false,
  searchHighlights: [],
  searchPaginationOffset: config.aws.searchPaginationPageSize,
  searchPhrase: '',
  searchResults: [],
  tileView: false,
  lastFilter: '',
};

const reducer = (state: ISearchReducer = initialState, action: IAction) => {
  switch (action.type) {
    case getType(fetchSearchResults.request):
      return {
        ...state,
        isLoading: true,
      };

    case getType(fetchSearchResults.success):
      return {
        ...state,
        isLoading: false,
        searchHighlights: action.payload.highlights,
        searchResults:
          action.payload.offset !== 0
            ? [
                ...state.searchResults,
                ...action.payload.results.filter((result) => result.eventSlug),
              ]
            : action.payload.results.filter((result) => result.eventSlug),
      };

    case getType(fetchFacets.success):
      return {
        ...state,
        categories: AWSSearchApi.combineFacets(
          action.payload.facets.event_category_id,
          action.payload.facets.facet_event_category,
          action.payload.facets.facet_event_category_slug
        ),
        tags: AWSSearchApi.combineFacets(
          action.payload.facets.public_tags_ids,
          action.payload.facets.facet_public_tags_names
        ),
      };

    case getType(setSearchOffset):
      return {
        ...state,
        searchPaginationOffset:
          state.searchPaginationOffset + config.aws.searchPaginationPageSize,
      };

    case getType(resetSearchOffset):
      return {
        ...state,
        searchPaginationOffset: 0,
      };

    case getType(setSearchPhrase):
      return {
        ...state,
        searchPhrase: action.payload,
      };

    case getType(setSearchFilter):
      const validFilterParamNames = [
        'artists_names',
        'city_name',
        'event_category_name',
        'locations_names',
        'partner_name',
        'place_name',
        'place_slug',
        'private_tags_names',
        'public_tags_names',
        'title_pl',
      ];
      const filterExists = checkFilterDuplicate(state.filters, action.payload);
      const filterIsValid = validFilterParamNames.find(
        (paramName) => paramName === action.payload.key
      );

      return filterExists || !filterIsValid
        ? state
        : {
            ...state,
            filters: [...state.filters, action.payload],
            searchHighlights: initialState.searchHighlights,
            ...(action.payload.preventResetPhrase
              ? {}
              : {
                  searchPhrase: initialState.searchPhrase,
                }),
          };

    case getType(removeSearchFilter):
      const filters = state.filters.filter(
        (filter) =>
          !(
            filter.key === action.payload.key &&
            filter.value === action.payload.value
          )
      );

      return {
        ...state,
        filters,
        searchHighlights: initialState.searchHighlights,
      };

    case getType(setDateFilter):
      return {
        ...state,
        datesFilter: action.payload,
      };

    case getType(setLastFilter):
      return {
        ...state,
        lastFilter: action.payload,
      };

    case getType(removeDateFilter):
      return {
        ...state,
        datesFilter: initialState.datesFilter,
      };

    case getType(changeCityFilter):
      const filterKey = 'locations_names';
      const isCityFilter = state.filters.some(
        (filter) => filter.key === filterKey
      );

      return {
        ...state,
        filters: isCityFilter
          ? state.filters.map((filter) => {
              if (filter.key === filterKey) {
                return {
                  key: filter.key,
                  value: action.payload,
                };
              }

              return filter;
            })
          : [...state.filters, { key: filterKey, value: action.payload }],
      };

    case getType(switchView):
      return {
        ...state,
        tileView: !state.tileView,
      };

    case getType(resetFilters):
      return {
        ...state,
        filters: initialState.filters,
      };

    default:
      return state;
  }
};

export default reducer;
