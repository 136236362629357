import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { buyAndPayOnsite } from '@Model/transaction/actions';

import Onsite from './Onsite.component';
import { getInitialValues } from './Onsite.selectors';
import {
  IBuyingOnsiteFromDispatch,
  IBuyingOnsiteFromState,
} from './Onsite.types';

const mapStateToProps = (state: _Store.IState): IBuyingOnsiteFromState => ({
  errorsTexts: translate(state)('errors'),
  initialValues: getInitialValues(),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IBuyingOnsiteFromDispatch => {
  return {
    buyAndPay: (values) => dispatch(buyAndPayOnsite(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Onsite);
