import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getIframeParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { getLocation } from '@Model/router/selectors';
import {
  changeDateMounted,
  resetTickets,
  setTicketDate,
} from '@Model/tickets/actions';
import { getDate, getTicketById } from '@Model/tickets/selectors';

import ChangeDate from './ChangeDate.component';
import { IMyTicketFromDispatch, IMyTicketFromState } from './ChangeDate.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IMyTicketFromDispatch => ({
  changeDateMounted: () => dispatch(changeDateMounted()),
  resetTicket: () => dispatch(resetTickets()),
  setTicketDate: (date: Date) => dispatch(setTicketDate(date)),
});

const mapStateToProps = (state: _Store.IState): IMyTicketFromState => {
  return {
    date: getDate(state).date || new Date(),
    isEmbed: !!getIframeParams(state),
    lang: translate(state)('ticketsManagement', 'changeDate'),
    ticketData: getTicketById(state)(getLocation(state).pathname.split('/')[2]),
  };
};

export default connect<
  IMyTicketFromState,
  IMyTicketFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ChangeDate);
