import React from 'react';

import cn from 'classnames';

import styles from './Triangle.module.scss';
import { ITriangleProps } from './Triangle.types';

const Triangle = ({ light, isMobile, angle }: ITriangleProps) => (
  <div
    className={cn(styles.triangleWrapper, isMobile && styles.withMarginBottom)}
  >
    <div className={styles.triangle}>
      <div
        className={cn(styles.shadow, light && styles.light)}
        style={
          isMobile
            ? {
                transform: `translateY(0.0625rem) rotate(-${angle}deg)`,
              }
            : undefined
        }
      />
    </div>
    <div className={styles.fakeShadow} />
    <div className={styles.square} />
  </div>
);

export default Triangle;
