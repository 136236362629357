import React, { useState } from 'react';

import cn from 'classnames';
import { Field, useFormikContext } from 'formik';

import Checkbox from '@Compo/forms/Checkbox';
import config from '@Config';

import { IBuyingOnlineFormValues } from './../../Online/Online.types';
import * as CONST from './../../constants/constants';
import styles from './PickUpParameters.module.scss';

const WANT_GIFT_WRAPP_TEXT = 'Chcę aby bilet został zapakowany na prezent ';
const GIFT_WRAPP_PRICE_TEXT = `(+${config.buy.giftWrapperPrice}zł)`;

const PickUpParameters = () => {
  const { values, setFieldValue } = useFormikContext<IBuyingOnlineFormValues>();
  const [checked, toggleCheck] = useState(values.giftWrapper);
  const isDisabled = values.pickUpWay !== CONST.PICK_UP_WAY_POST;
  const deselectPickUpByPost = checked !== isDisabled;

  if (deselectPickUpByPost) {
    setFieldValue('giftWrapper', false);
    toggleCheck(!checked);
  }

  return (
    <div className={cn(styles.wrapper, isDisabled && styles.disabled)}>
      <Field
        component={Checkbox}
        name="giftWrapper"
        id="giftWrapper"
        style={styles.checkbox}
      >
        {WANT_GIFT_WRAPP_TEXT}
        <span className={styles.price}>{GIFT_WRAPP_PRICE_TEXT}</span>
        <span className={styles.giftIcon} />
      </Field>
    </div>
  );
};

export default PickUpParameters;
