import React, { ReactNode } from 'react';

import cn from 'classnames';

import goTrough from '@Misc/helpers/goTrough';

import styles from './DetailsLoading.module.scss';
import { IDetailsLoadingProps } from './DetailsLoading.types';

const getLines = (minLength: number): number[] => {
  return goTrough(minLength + Math.random() * 3);
};

const getStyle = (baseWidth: number): object => ({
  width: `${baseWidth + Math.random() * 10}%`,
});

const getParagraph = (outerKey: number, minLength: number): ReactNode => (
  <div key={outerKey} className={styles.fakeParagraph}>
    {getLines(minLength).map((key) => (
      <div key={key} className={styles.fakeLine} style={getStyle(90)} />
    ))}

    <div className={styles.fakeLine} style={getStyle(50)} />
  </div>
);

const Loading = ({ isShortDescription, isFullWidth }: IDetailsLoadingProps) => {
  return (
    <div className={styles.detailsLoading}>
      <div
        className={cn(
          isShortDescription
            ? styles.fakeShortDescription
            : styles.fakeDescription,
          isFullWidth && styles.fullWidth
        )}
      >
        {goTrough(isShortDescription ? 1 : 3).map((key) =>
          getParagraph(key, isShortDescription ? 1 : 4)
        )}
      </div>
    </div>
  );
};

export default Loading;
