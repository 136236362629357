import React from 'react';

import Link from '@Compo/reusable/DualLink';
import ImageLoading from '@Compo/reusable/loadings/ImageLoading';
import { getBackgroundImageStyle } from '@Misc/helpers/css';

import styles from './Image.module.scss';
import { IImageProps } from './Image.types';

const Image = ({
  imageUrl,
  link,
  isLoading,
  forcedVertical,
  borderRadiusTopLeft,
}: IImageProps) => {
  if (isLoading || !imageUrl) {
    return (
      <ImageLoading
        borderRadiusTopLeft={borderRadiusTopLeft}
        forcedVertical={forcedVertical}
      />
    );
  }

  return (
    <>
      {link && (
        <Link
          className={forcedVertical ? styles.forcedVertical : styles.normal}
          to={link}
        >
          <div
            className={styles.image}
            style={{
              ...getBackgroundImageStyle(imageUrl),
              borderTopLeftRadius: borderRadiusTopLeft,
            }}
          />
        </Link>
      )}
      {!link && (
        <div className={forcedVertical ? styles.forcedVertical : styles.normal}>
          <div
            className={styles.image}
            style={{
              ...getBackgroundImageStyle(imageUrl),
              borderTopLeftRadius: borderRadiusTopLeft,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Image;
