import { Theme, createStyles, makeStyles } from '@material-ui/core';

import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

const useStyles = (isEmbed: boolean, embedDialogPosition: number) => {
  const { isDesktop } = useMuiBreakpoints();

  return makeStyles((theme: Theme) =>
    createStyles({
      actions: {
        justifyContent: 'center',
        padding: '2rem',
        paddingTop: 0,
      },
      content: {
        padding: '2rem',

        '&:first-child': {
          paddingTop: '2rem',
        },
      },
      root: {
        ...(isEmbed && {
          '& .MuiDialog-paper': {
            position: 'fixed',
            top: `calc(${embedDialogPosition}px + ${isDesktop ? 15 : 10}vh)`,
          },
        }),
      },
    })
  );
};

export default useStyles;
