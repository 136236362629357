import React from 'react';

import Loading from '@Compo/Bricks/components/Loading';
import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';

const TICKET_TEXT = 'Bilet';

const DeeplinkTicket = () => {
  return (
    <>
      <Helmet pageName={TICKET_TEXT} />
      <FullTriangleBackground />
      <MuiSectionContainer>
        <Loading />
      </MuiSectionContainer>
    </>
  );
};

export default DeeplinkTicket;
