import { createSelector } from 'reselect';

import _Store from '@Store';

import { IProductSelect } from './../types';
import getSelectedProducts from './getSelectedProducts';

const isAnyProductSelectedHasShipmentRequired = createSelector<
  _Store.IState,
  IProductSelect[],
  boolean
>([getSelectedProducts], (selectedProducts) =>
  selectedProducts.some((product) => product.requiredShipment)
);

export default isAnyProductSelectedHasShipmentRequired;
