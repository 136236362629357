import makeNumber from './makeNumber';

const today = (): number => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return makeNumber(year, month, day);
};

export default today;
