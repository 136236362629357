import React, { FC } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import { Form, Formik } from 'formik';

import FormField from '@Compo/forms/FormField';
import config from '@Config';
import getCurrencyFromCode from '@Misc/helpers/getCurrencyFromCode';
import goTrough from '@Misc/helpers/goTrough';
import icons from '@Misc/helpers/icons';

import TicketDataForm from './../TicketDataForm';
import WithEventDetailBox from './../WithEventDetailBox';
import styles from './TransferTicket.module.scss';
import { initialValues, makeValidationSchema } from './TransferTicket.selector';
import { ITransferTicketProps } from './TransferTicket.types';
import useStyles from './styles/TransferTicket.styles';

const ticketIcon = config.theme.isEmpik
  ? cn(styles.icon, icons.ticket)
  : cn(styles.ticketIcon);

const TransferTicket: FC<ITransferTicketProps> = ({
  isDesktop,
  lang,
  submitForm,
  ticketData,
  ticketTransferFee,
  shouldShowSender,
}) => {
  if (!ticketData) {
    return <TicketDataForm />;
  }

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <>
      <h2 className={styles.pageTitle}>{lang.pageTitle}</h2>
      <WithEventDetailBox>
        <Formik
          initialValues={{
            ...initialValues,
            ticketId: ticketData.ticketId,
            token: ticketData.token,
          }}
          onSubmit={submitForm}
          validationSchema={makeValidationSchema}
        >
          {({ handleChange, handleBlur, errors }) => (
            <Form>
              <fieldset className={cn(styles.formPanel, muiStyles.root)}>
                <p className={styles.description}>{lang.descFirstParagraph}</p>
                <ol>
                  <li className={styles.description}>
                    {lang.descFirstBulletPointPart1}
                    <b>
                      <i>{lang.descFirstBulletPointPart2}</i>
                    </b>
                  </li>
                  <li className={styles.description}>
                    <b>{lang.descSecondBulletPointPart1}</b>
                    {lang.descSecondBulletPointPart2}
                  </li>
                  <li className={styles.description}>
                    {lang.descThirdBulletPointPart1}
                    <b>{lang.descThirdBulletPointPart2}</b>
                    {lang.descThirdBulletPointPart3}
                  </li>
                </ol>
                <p className={styles.description}>{lang.descSecondParagraph}</p>
                {ticketTransferFee && (
                  <p className={styles.description}>
                    {lang.descThirdParagraphPart1}{' '}
                    <b>
                      {shouldShowSender ? ticketTransferFee : 0}{' '}
                      {getCurrencyFromCode(config.app.defaultCurrency)}
                    </b>
                    {lang.descThirdParagraphPart2}{' '}
                    <b>
                      {ticketTransferFee}{' '}
                      {getCurrencyFromCode(config.app.defaultCurrency)}.
                    </b>
                  </p>
                )}
                <p className={styles.description}>
                  {lang.descFourthParagraphPart1}
                  <i>{lang.descFourthParagraphPart2}</i>
                </p>
              </fieldset>

              {ticketData.numberOfTickets > 1 && (
                <>
                  <h3 className={styles.header} data-number={1}>
                    {lang.chooseAmount}
                  </h3>
                  <fieldset
                    className={cn(
                      styles.formPanel,
                      styles.tickets,
                      muiStyles.root
                    )}
                  >
                    <label
                      className={cn(styles.label, styles.columns)}
                      htmlFor="code"
                    >
                      <div className={cn(styles.column, styles.columnLeft)}>
                        {isDesktop && <span className={ticketIcon} />}
                        <h4>{ticketData.name || lang.ticketsHeader}</h4>
                      </div>
                      <div className={cn(styles.column, styles.columnRight)}>
                        {goTrough(ticketData.numberOfTickets).map((index) => {
                          return (
                            <div key={`ticket-${index}`}>
                              <input
                                type="radio"
                                id={`ticket-${index}`}
                                name="quantity"
                                value={index}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultChecked={index === 1}
                              />
                              <label
                                className={styles.ticket}
                                htmlFor={`ticket-${index}`}
                              >
                                {index}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </label>
                    {errors.quantity && (
                      <span className={styles.error}>{errors.quantity}</span>
                    )}
                  </fieldset>
                </>
              )}

              <h3
                className={styles.header}
                data-number={ticketData.numberOfTickets > 1 ? 2 : 1}
              >
                {lang.enterReceiversData}
              </h3>
              <fieldset className={cn(styles.formPanel, muiStyles.root)}>
                <label className={styles.label} htmlFor="email">
                  {lang.emailLabel}
                  <br />
                  <strong>{lang.emailTip}</strong>
                  <FormField
                    id="email"
                    name="email"
                    placeholder={lang.emailPlaceHolder}
                    type="email"
                  />
                </label>
                <div className={cn(styles.names, styles.label)}>
                  <span className={styles.text}>
                    {lang.ticketIsNominal} <strong>{lang.realData}</strong>
                    {lang.becauseStaffChecksId}
                  </span>
                  <label className={styles.namesLabel} htmlFor="firstName">
                    <FormField
                      id="firstName"
                      name="firstName"
                      placeholder={lang.firstName}
                      type="text"
                    />
                  </label>
                  <label className={styles.namesLabel} htmlFor="lastName">
                    <FormField
                      id="lastName"
                      name="lastName"
                      placeholder={lang.lastName}
                      type="text"
                    />
                  </label>
                </div>
              </fieldset>

              <button className={styles.submitButton} type="submit">
                {lang.submitButton}
              </button>
            </Form>
          )}
        </Formik>
      </WithEventDetailBox>
    </>
  );
};

export default TransferTicket;
