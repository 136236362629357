import {
  IHotDealsData,
  IHotDealsExtraData,
  IHotDealsOwnProps,
} from '@Compo/Bricks/components/HotDeals/HotDeals.types';
import makeFriendlyDate from '@Misc/helpers/makeFriendlyDate';
import prepareHotSpotUri from '@Misc/helpers/prepareHotSpotsUri';

import {
  ISectionComponentData,
  ISectionsComponentDataMutable,
  ISectionsComponentHotDeals,
} from './../types/sections';

export const hotdeals = (
  data: ISectionsComponentDataMutable,
  extra?: IHotDealsExtraData
): IHotDealsOwnProps & ISectionComponentData => {
  const hotSpotsData = data as ISectionsComponentHotDeals[];
  const hotDeals: IHotDealsData[] = [];

  hotSpotsData.map((hotSpot: ISectionsComponentHotDeals) => {
    const date = hotSpot.dateDesc
      ? hotSpot.dateDesc
      : makeFriendlyDate(hotSpot.date);

    hotDeals.push({
      badge: hotSpot.badge || null,
      friendlyDate: extra?.disableDate ? null : date,
      imageUrl: hotSpot.thumbUrl,
      place: extra?.disablePlace ? null : hotSpot.place,
      placeSlug: hotSpot.placeSlug,
      tags: hotSpot.tags || [],
      title: extra?.disableTitle ? null : hotSpot.title,
      url: prepareHotSpotUri(hotSpot),
    });
  });

  return {
    color: extra?.color,
    hotDeals,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
  };
};
