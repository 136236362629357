import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getLocationsRequest } from '@Model/locations/actions';
import { getSections } from '@Model/pages/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenSectionsFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getSections.request, getLocationsRequest.request])),
    map$(() => setLoading(CONSTS.SECTIONS))
  );
};

export const setLoaderWhenSectionsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getSections.success,
          getSections.failure,
          getLocationsRequest.success,
          getLocationsRequest.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(CONSTS.SECTIONS))
  );
};
