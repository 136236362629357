import { createSelector } from 'reselect';

import get from './get';

const getArtistClosestPool = createSelector(
  [get],
  (artist) => artist.closestPool
);

export default getArtistClosestPool;
