import { createSelector } from 'reselect';

import getSelected from './getSelected';

const getExtendedSlotModalVisible = createSelector(
  [getSelected],
  (selected) => selected.extendSlotModalVisible
);

export default getExtendedSlotModalVisible;
