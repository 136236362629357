import { createSelector } from 'reselect';

import _Store from '@Store';

import { IErrorsReducer } from './../types';
import get from './get';

const getLink = createSelector<_Store.IState, IErrorsReducer, string | null>(
  [get],
  (error) => error.errorLink
);

export default getLink;
