import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { getSelectedTickets } from '@Model/pools/selectors';

import AdditionalField from './AdditionalField.component';
import {
  IAdditionalFieldFromState,
  IAdditionalFieldOwnProps,
} from './AdditionalField.types';

const mapStateToProps = (state: _Store.IState): IAdditionalFieldFromState => ({
  clientLang: translate(state)('buy', 'clientData'),
  errors: translate(state)('errors'),
  lang: translate(state)('buy', 'agreements'),
  selectedTickets: getSelectedTickets(state),
});

export default connect<
  IAdditionalFieldFromState,
  void,
  IAdditionalFieldOwnProps,
  _Store.IState
>(mapStateToProps)(AdditionalField);
