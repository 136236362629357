import { createSelector } from 'reselect';

import _Store from '@Store';

import { getEvent } from './../selectors';
import { IEventFull, IEventTerm } from './../types';

export const getCustomTerms = createSelector<
  _Store.IState,
  IEventFull | null,
  IEventTerm[]
>([getEvent], (event) => {
  if (event?.customTerms) {
    return event.customTerms;
  }

  return [];
});

export default getCustomTerms;
