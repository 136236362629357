import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { handleNextStep, setFormValues } from '@Model/entryList/actions';
import { getFormValues } from '@Model/entryList/selectors';

import PersonalData from './PersonalData.component';
import {
  IPersonalDataFromDispatch,
  IPersonalDataFromState,
} from './PersonalData.types';

const mapStateToProps = (state: _Store.IState): IPersonalDataFromState => ({
  initialValues: getFormValues(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPersonalDataFromDispatch => ({
  setFormValues: (data) => dispatch(setFormValues(data)),
  handleNextStep: (step) => dispatch(handleNextStep(step)),
});

export default connect<
  IPersonalDataFromState,
  IPersonalDataFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PersonalData);
