import React from 'react';

import { Icon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import cn from 'classnames';

import ToolTip from '@Compo/reusable/ToolTip';
import getCurrencyFromCode from '@Misc/helpers/getCurrencyFromCode';

import useStyles from './Price.styles';
import { IPriceProps } from './Price.types';

const NO_POOLS_TEXT = 'TBA';

const Price = ({
  lang,
  price,
  priceDescription,
  currency,
  isServiceFeeTooltipDisabled,
}: IPriceProps) => {
  const isPriceAvailable = Boolean(price?.length);
  const isPriceDescriptionAvailable = Boolean(priceDescription?.length);
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const isActualPriceShown = !isPriceDescriptionAvailable && isPriceAvailable;
  const isPriceinPriceDesc =
    priceDescription?.includes('zł') ||
    priceDescription?.includes('€') ||
    priceDescription?.includes('£') ||
    priceDescription?.includes('$');
  const isTooltip =
    (isActualPriceShown || isPriceinPriceDesc) && !isServiceFeeTooltipDisabled;

  return (
    <>
      <span className={cn(muiStyles.fontColor, muiStyles.margin)}>
        {isActualPriceShown && (
          <>
            {lang.from}&nbsp;
            <strong className={cn(muiStyles.fontColor, muiStyles.priceFont)}>
              {price}&nbsp;{getCurrencyFromCode(currency)}
            </strong>
          </>
        )}
        {isPriceDescriptionAvailable && <strong>{priceDescription}</strong>}
        {!isPriceAvailable && !isPriceDescriptionAvailable && (
          <strong className={muiStyles.fontColor}>{NO_POOLS_TEXT}</strong>
        )}
      </span>
      {isTooltip && (
        <ToolTip title={lang.serviceFeeTooltip} className={muiStyles.tooltip}>
          <Icon component={InfoIcon} />
        </ToolTip>
      )}
    </>
  );
};

export default Price;
