import { IFormioBrickOwnProps } from '@Compo/Bricks/components/FormioBrick/FormioBrick.types';
import {
  ISectionsComponentDataMutable,
  ISectionsComponentFormio,
} from '@Model/pages/types/sections';

export const formio = (
  data: ISectionsComponentDataMutable
): IFormioBrickOwnProps => {
  const formElements = data as ISectionsComponentFormio[];

  return {
    formSlug: formElements[0].formSlug,
  };
};
