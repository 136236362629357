import React, { FC } from 'react';

import { Button } from '@material-ui/core';

import config from '@Config';

import styles from './SimpleCheckbox.module.scss';
import { ISimpleCheckboxProps } from './SimpleCheckbox.types';

const CHOOSE = 'Wybierz';
const CHOSEN = 'Wybrano';

const SimpleCheckbox: FC<ISimpleCheckboxProps> = ({
  checked,
  children,
  hideCheck,
  showChooseButon,
}) => {
  const hideCheckbox = hideCheck || showChooseButon;
  const isEmpik = config.theme.isEmpik;

  return (
    <div className={styles.simpleCheckbox}>
      <label className={styles.label}>
        <input checked={checked} type="checkbox" readOnly={true} />
        {!hideCheckbox && <span className={styles.selector} />}
        {children}
      </label>
      {showChooseButon && (
        <Button
          variant={!checked ? 'outlined' : 'contained'}
          color={isEmpik ? 'secondary' : 'primary'}
          size={'large'}
          disableElevation={true}
          fullWidth={true}
        >
          {!checked ? CHOOSE : CHOSEN}
        </Button>
      )}
    </div>
  );
};

export default SimpleCheckbox;
