import { Store } from 'redux';

import { changeLocation } from '@Model/locations/actions';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    globalLocationChange: (slug: string) => void;
  }
}

export const addLocationChangeMethodToWindow = (store: Store) => {
  try {
    window.globalLocationChange = (slug) => {
      const locationsDictionary = Object.keys(
        store.getState().locations.locations
      );
      const isSlugInDict = locationsDictionary.includes(slug);

      if (isSlugInDict) {
        store.dispatch(changeLocation(slug, undefined));
      }
    };
  } catch (SSR) {}
};
