import { ITourListData } from '@Compo/Bricks/components/TourListBrick/TourList.types';
import {
  ISectionComponentData,
  ISectionsComponentDataMutable,
} from '@Model/pages/types/sections';

export const tour_list = (
  data: ISectionsComponentDataMutable,
  extra?: ISectionComponentData | null,
  background?: string
) => {
  const tourListData = data as ITourListData[];

  return {
    data: tourListData,
    color: extra?.color,
    bgColor: background,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
  };
};
