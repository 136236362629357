import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    group: {
      marginBottom: '0.75rem',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  })
);

export default useStyles;
