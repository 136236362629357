import React, { FC } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import linkToFileInStorage from '@Misc/helpers/linkToFileInStorage';

import classes from './CustomTerm.classes';
import styles from './CustomTerm.module.scss';
import useStyles from './CustomTerm.styles';
import { ICustomTermProps } from './CustomTerm.types';

const CustomTerm: FC<ICustomTermProps> = ({ lang, term }) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <div>
      <div>
        {term.name || lang.iAcceptTerms}{' '}
        {term.link && (
          <a
            href={linkToFileInStorage(term.link, true)}
            target="_blank"
            className={muiStyles.secondary}
          >
            {lang.read}
          </a>
        )}
        {term.required && '*'}
      </div>

      {term.text && (
        <div
          className={cn(
            styles.additionalText,
            classes.additionalText,
            muiStyles.secondary
          )}
        >
          {term.text}
        </div>
      )}
    </div>
  );
};

export default CustomTerm;
