import React, { useEffect } from 'react';

import cn from 'classnames';
import { animated, config, useSpring } from 'react-spring';

import ArtistSlider from '@Compo/reusable/ArtistSlider';
import DualLink from '@Compo/reusable/DualLink';
import Faq from '@Compo/reusable/Faq';
import Gallery from '@Compo/reusable/Gallery';
import Timeline from '@Compo/reusable/Timeline';
import powered_black from '@Misc/styles/images/powered_by.svg';
import powered_white from '@Misc/styles/images/powered_by_fff.svg';
import routes from '@Routes/routes';

import styles from './LandingPage.module.scss';
import { ILandingPageProps } from './LandingPage.types';
import EventCards from './components/EventCards';
import InfoSection from './components/InfoSection';
import Partners from './components/Partners';
import useLandingPageStyles from './useLandingPageStyles';

const MAIN_BUTTON_TEXT = 'Kup Bilet';
const SECTION_LINK_ID = '#tickets';
const FAQ_SECTION_TITLE_TEXT = 'Faq';
const BACK_TO_TOP_LINK_ID = '#header';

const LandingPage = ({
  isLoading,
  isMobile,
  isTablet,
  landingPage,
  mounted,
}: ILandingPageProps) => {
  const {
    backgroundGradient,
    bodyScheme,
    borderColorScheme,
    buttonScheme,
    cardTitleScheme,
    headerScheme,
    isDark,
    isHeaderFixed,
    onButtonOut,
    onButtonOver,
    sectionTitleScheme,
    timelineScheme,
  } = useLandingPageStyles(isMobile, landingPage);

  const logoAnimation = useSpring({
    config: config.wobbly,
    delay: 400,
    from: { transform: 'translate3d(0, -10rem, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
  });

  useEffect(() => {
    mounted();
  }, []);

  const footerImage = isDark ? powered_white : powered_black;

  return (
    <>
      <div
        className={cn(styles.landingWrapper, isHeaderFixed && styles.fixed)}
        style={headerScheme}
      >
        <div className={styles.headerWrapper} id={BACK_TO_TOP_LINK_ID}>
          <animated.img
            src={landingPage?.header.logo}
            alt={landingPage?.title}
            style={logoAnimation}
          />
          <a
            href={SECTION_LINK_ID}
            className={styles.mainButton}
            type="button"
            style={buttonScheme}
            onMouseOver={onButtonOver}
            onMouseOut={onButtonOut}
          >
            {MAIN_BUTTON_TEXT}
          </a>
        </div>

        <div
          className={cn(styles.contentWrapper, isDark && styles.dark)}
          style={bodyScheme}
        >
          <div className={styles.mainColumn}>
            {landingPage?.artistSection && (
              <ArtistSlider
                artistSection={landingPage.artistSection}
                isDark={isDark}
              />
            )}

            <h1 className={styles.mainTitle} style={sectionTitleScheme}>
              {landingPage?.title}
            </h1>
            <p className={styles.description}>{landingPage?.description}</p>

            {landingPage?.timeline && (
              <Timeline
                isDark={isDark}
                timeline={landingPage?.timeline}
                timelineScheme={timelineScheme}
              />
            )}

            {landingPage?.infoSection && (
              <InfoSection
                infoSection={landingPage.infoSection}
                sectionTitleScheme={sectionTitleScheme}
              />
            )}

            {landingPage?.events && (
              <EventCards
                backgroundGradient={backgroundGradient}
                borderColorScheme={borderColorScheme}
                cardTitleScheme={cardTitleScheme}
                isDark={isDark}
                events={landingPage?.events}
                buttonScheme={buttonScheme}
                buttonHover={onButtonOver}
                buttonOut={onButtonOut}
                sectionTitleScheme={sectionTitleScheme}
              />
            )}

            {landingPage?.partners && (
              <Partners
                partners={landingPage?.partners}
                sectionTitleScheme={sectionTitleScheme}
              />
            )}

            {landingPage?.gallery && (
              <Gallery
                isLoading={isLoading}
                photos={landingPage?.gallery}
                vertical={true}
              />
            )}

            {landingPage?.faq && (
              <>
                <h2 className={styles.mainTitle} style={sectionTitleScheme}>
                  {FAQ_SECTION_TITLE_TEXT}
                </h2>
                <Faq faq={landingPage?.faq} isBgDark={isDark} />
              </>
            )}
          </div>
        </div>

        <div className={styles.footer} style={bodyScheme}>
          <DualLink to={routes.index}>
            <img src={footerImage} alt={landingPage?.title} />
          </DualLink>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
