import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import CustomTerm from './CustomTerm.component';
import { ICustomTermFromState, ICustomTermOwnProps } from './CustomTerm.types';

const mapStateToProps = (state: _Store.IState): ICustomTermFromState => ({
  lang: translate(state)('buy', 'term'),
});

export default connect<
  ICustomTermFromState,
  void,
  ICustomTermOwnProps,
  _Store.IState
>(mapStateToProps)(CustomTerm);
