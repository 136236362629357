import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEvent } from '@Model/events/types';

import { IEventReducer } from './../types';
import get from './get';

const getSimilarEvents = createSelector<_Store.IState, IEventReducer, IEvent[]>(
  [get],
  (event) => event.similarEvents
);

export default getSimilarEvents;
