import React, { MouseEvent } from 'react';

import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import useStyles from '@Compo/reusable/CtaButton/CtaButton.styles';
import { ICtaButtonProps } from '@Compo/reusable/CtaButton/CtaButton.types';
import slugifyString from '@Misc/helpers/slugifyString';
import Analytics from '@Services/$analytics';

const CtaButton = ({
  backgroundColor,
  borderColor,
  borderWidth,
  isBorder,
  title,
  link,
  compositionIdentifier,
  brickId,
}: ICtaButtonProps) => {
  const theme = useTheme();
  const muiStyles = useStyles({
    backgroundColor,
    borderColor,
    borderWidth,
    isBorder,
  })(theme);

  const handleAddToDataLayerOnClick = (e: MouseEvent<HTMLElement>) =>
    link
      ? Analytics.pushForHomepageSelectItem(e, link, title || '')
      : undefined;

  return (
    <Button
      size={'large'}
      className={cn(
        muiStyles.root,
        `${compositionIdentifier}-${brickId}-${slugifyString(title)}`
      )}
      children={title}
      href={link}
      onClick={handleAddToDataLayerOnClick}
    />
  );
};

export default CtaButton;
