import React, { MouseEvent } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import { animated as a, config, useSpring } from 'react-spring';

import DualLink from '@Compo/reusable/DualLink';
import slugifyString from '@Misc/helpers/slugifyString';
import Analytics from '@Services/$analytics';

import styles from './Timeline.module.scss';
import useStyles from './Timeline.styles';
import { ITimelineProps } from './Timeline.types';

const Timeline = ({
  color,
  id,
  isDark,
  isDesktop,
  isLoading,
  timeline,
  timelineScheme,
  compositionIdentifier,
  brickId,
}: ITimelineProps) => {
  const [ref, inView] = useInView({ threshold: isDesktop ? 1 : 0.5 });
  const theme = useTheme();
  const muiStyles = useStyles(color, isDark)(theme);

  const handleAddToDataLayerOnClick =
    (link?: string, textDisplayed?: string, componentName?: string) =>
    (e: MouseEvent<HTMLElement>) =>
      link
        ? Analytics.pushForHomepageSelectItem(
            e,
            link,
            textDisplayed || '',
            componentName
          )
        : undefined;

  const timelineElements = timeline.map((marker, index) => (
    <li className={styles.timelineElement} key={index.toString()} id={id}>
      {marker.markerLink ? (
        <DualLink
          className={cn(
            styles.marker,
            `${compositionIdentifier}-${brickId}-${slugifyString(
              marker.markerTitle
            )}`
          )}
          to={marker.markerLink}
          onClick={handleAddToDataLayerOnClick(
            marker.markerLink,
            marker.markerTitle
          )}
        />
      ) : (
        <div className={styles.marker} style={timelineScheme} />
      )}
      {isDesktop ? (
        <a.div
          className={styles.markerBar}
          style={useSpring({
            config: config.stiff,
            delay: index * 200 + 100,
            width: inView ? '100%' : '1%',
          })}
        />
      ) : (
        <a.div
          className={styles.markerBar}
          style={useSpring({
            config: config.stiff,
            delay: index * 200 + 100,
            height: inView ? '100%' : '1%',
          })}
        />
      )}
      <div className={cn(styles.dataWrapper, muiStyles.fontColor)}>
        <h3>{marker.markerTitle}</h3>
        <p>{marker.markerData}</p>
      </div>
    </li>
  ));

  return (
    <ul className={cn(styles.timelineWrapper, isDark && styles.dark)} ref={ref}>
      {timelineElements}
    </ul>
  );
};

export default Timeline;
