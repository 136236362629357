import React, { FC, MouseEvent } from 'react';

import DualLink from '@Compo/reusable/DualLink';
import slugifyString from '@Misc/helpers/slugifyString';
import Analytics from '@Services/$analytics';

import styles from './Banner.module.scss';
import { IBannerProps } from './Banner.types';

const Banner: FC<IBannerProps> = ({
  id,
  link,
  title,
  compositionIdentifier,
}) => {
  const handleAddToDataLayerOnClick = (e: MouseEvent<HTMLElement>) =>
    Analytics.pushForHomepageSelectItem(e, link, title, title);

  return (
    <div className={styles.wrapper} id={id}>
      <DualLink
        className={`${compositionIdentifier}-${id}-${slugifyString(title)}`}
        to={link}
        onClick={handleAddToDataLayerOnClick}
      >
        {title}
      </DualLink>
    </div>
  );
};

export default Banner;
