import React, { FC } from 'react';

import ReadMore from '@Compo/reusable/ReadMore';
import invertIfGrayScaleColor from '@Misc/helpers/invertIfGrayScaleColor';
import isColorDark from '@Misc/helpers/isColorDark';

import { IReadMoreBrickProps } from './ReadMoreBrick.types';

const ReadMoreBrick: FC<IReadMoreBrickProps> = ({
  color,
  id,
  isLoading,
  link,
  text,
  background,
  isDark,
  compositionIdentifier,
}) => {
  const bgColor =
    isDark && background ? invertIfGrayScaleColor(background) : background;
  const isBgDark = isColorDark(background) || isColorDark(bgColor);

  return (
    <ReadMore
      id={id}
      link={link}
      isLoading={isLoading}
      text={text}
      isBgDark={isBgDark}
      color={color}
      compositionIdentifier={compositionIdentifier}
    />
  );
};

export default ReadMoreBrick;
