import { getType } from 'typesafe-actions';

import { _closeAll, _closeLatest, _open, _setOne } from './../actions';
import { IAction, IReducerModal } from './../types';

const initialState: IReducerModal[] = [];

const reducer = (
  state: IReducerModal[] = initialState,
  action: IAction
): IReducerModal[] => {
  switch (action.type) {
    // _OPEN
    case getType(_open):
      return [...state, action.payload];

    // _SET_ONE
    case getType(_setOne):
      return [action.payload];

    // CLOSE_ALL
    case getType(_closeAll):
      return initialState;

    // CLOSE_LATEST
    case getType(_closeLatest):
      return state.length === 1 ? [] : state.slice(0, state.length - 1);

    default:
      return state;
  }
};

export default reducer;
