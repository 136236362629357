import React from 'react';

import cn from 'classnames';

import fillDataWithEmptyElement from '@Misc/helpers/fillDataWithEmptyElement';

import styles from './Authors.module.scss';
import { IAuthor, IAuthors } from './Authors.types';

const TITLE_TEXT = 'Autorzy';
const DEFAULT_AUTHORS_NUMBER = 2;

const Authors = ({ data }: IAuthors) => {
  if (!data || data.length === 0) {
    data = fillDataWithEmptyElement<IAuthor>(DEFAULT_AUTHORS_NUMBER);
  }

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{TITLE_TEXT}</p>
      <div className={styles.authorsWrapper}>
        {data.map((author, key) => {
          return (
            <a key={key} className={styles.author} href={author.link}>
              <div
                className={cn(
                  styles.avatar,
                  !author.avatarUrl && styles.loadingAvatar
                )}
                style={{ backgroundImage: `url(${author.avatarUrl})` }}
              />
              {author.name ? (
                `${author.name}`
              ) : (
                <span className={styles.loadingName} />
              )}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Authors;
