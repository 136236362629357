import { LOCATION_CHANGE } from 'connected-react-router';
import format from 'date-fns/format';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  concatMap as concatMap$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { basketBuyFormMounted } from '@Model/basket/actions';
import { buyFormMounted } from '@Model/event/actions';
import { getSelected, isAllSelected } from '@Model/happening/selectors';
import { getModule, getParams } from '@Model/internalRouter/selectors';
import {
  getPools,
  getPoolsIds,
  getSelectedTickets,
} from '@Model/pools/selectors';
import { insuranceSlugs } from '@Model/products/constants/insurance';
import { getSelectedProducts } from '@Model/products/selectors';
import { getInsuranceProducts as getInsuranceProductsSelector } from '@Model/products/selectors/index';
import * as MODULES from '@Routes/modules';
import { IBuyProductPrams } from '@Routes/types';
import ProductSelector from '@Services/$product-selector';

import {
  getInsuranceProducts,
  getProducts,
  getProductsPools,
  getSingleProduct,
  mountProducts,
  productsMounted,
  reloadInsurance,
  selectProduct,
  updateSelectedProducts,
} from './../actions';
import { IProduct } from './../types';

export const whenProductsMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(mountProducts)),
    withLatestFrom$(state$),
    filter$(([_, state]) => isAllSelected(state)),
    mergeMap$(([_, state]) => {
      const selected = getSelected(state);
      const dateTime = `${format(selected.dayFromSlot || 0, 'yyyy-MM-dd')}T${
        selected.slot
      }:00+00:00`;

      return of$(
        getProducts.request({
          dateTime,
          space: selected.space,
        })
      );
    })
  );
};

export const singleProductMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(productsMounted)),
    map$(() => getSingleProduct.request())
  );
};

export const insuranceMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([buyFormMounted, /*basketBuyFormMounted,*/ reloadInsurance])
    ),
    map$(() => getInsuranceProducts.request())
  );
};

export const getInsuranceProductsData: _Store.IEpic = (
  action$,
  state$,
  { productApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getInsuranceProducts.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) =>
      from$(insuranceSlugs)
        .pipe(concatMap$((slug) => productApi.getProduct(slug)))
        .pipe(
          concatMap$((insuranceProduct) => {
            const insuranceProducts = getInsuranceProductsSelector(state);

            return insuranceProducts.length < 9
              ? of$(
                  getInsuranceProducts.success({
                    id: insuranceProduct.id,
                    price: insuranceProduct.price,
                  })
                )
              : EMPTY$;
          }),
          catchError$((error: Error) =>
            of$(getInsuranceProducts.failure(error))
          )
        )
    )
  );
};

export const fetchSingleProduct: _Store.IEpic = (
  action$,
  state$,
  { productApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSingleProduct.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const params = getParams(state) as IBuyProductPrams;

      return from$(productApi.getProduct(params.slug)).pipe(
        mergeMap$((data: IProduct) => {
          return of$(getSingleProduct.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType([LOCATION_CHANGE])),
            tap$(() => productApi.cancelProduct())
          )
        ),
        catchError$((error: Error) => of$(getProducts.failure(error)))
      );
    })
  );
};

export const fetchProductsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getProducts.request)),
    mergeMap$((action) => {
      return from$(productsApi.getProducts(action.payload)).pipe(
        mergeMap$((data: IProduct[]) => of$(getProducts.success([...data]))),
        takeUntil$(
          action$.pipe(
            filter$(isOfType([LOCATION_CHANGE])),
            tap$(() => productsApi.cancelCalling())
          )
        ),
        catchError$((error: Error) => of$(getProducts.failure(error)))
      );
    })
  );
};

export const fetchProductsPoolsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { poolsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getProductsPools.request)),
    withLatestFrom$(state$),
    filter$(([_, state]) => !!getPools(state).length),
    mergeMap$(([_, state]) => {
      const poolsIds = getPoolsIds(state);

      return from$(poolsApi.getProducts(poolsIds)).pipe(
        mergeMap$((data) => {
          return of$(getProductsPools.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => poolsApi.cancelPools())
          )
        ),
        catchError$((error: Error) => {
          const module = getModule(state);
          if (module === MODULES.EVENT) {
            return EMPTY$;
          }

          return of$(getProductsPools.failure(error));
        })
      );
    })
  );
};

export const updateSelectedProductsWhenAmountClicked: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(selectProduct)),
    withLatestFrom$(state$),
    map$(([action, state]) => {
      const selectedProducts = getSelectedProducts(state);
      const selectedTickets = getSelectedTickets(state);
      const { product, amount } = action.payload;

      const availableProductTypes = ['collectorTicket', 'priorityTicket'];

      const updatedSelected = ProductSelector.updateSelected(
        selectedProducts,
        product,
        amount,
        product?.type &&
          availableProductTypes.includes(product.type) &&
          selectedTickets.length
          ? selectedTickets.map((ticket) => ticket.poolId)
          : undefined
      );

      return updateSelectedProducts(updatedSelected);
    })
  );
};
