import { Selector } from 'reselect';

import _Store from '@Store';

import { IHappeningReducer } from './../types';

const get: Selector<_Store.IState, IHappeningReducer> = (state) =>
  state.happening;

export default get;
