import { createSelector } from 'reselect';

import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { getEvent } from '@Model/event/selectors';
import { getModule } from '@Model/internalRouter/selectors';
import { getLocation } from '@Model/router/selectors';
import * as MODULES from '@Routes/modules';
import routes from '@Routes/routes';

import * as TEXTS from './../constants/texts';
import createSchemaFromEvent from './../helpers/createSchemaFromEvent';
import { IMeta, IMetaValue } from './../types';

const withEventMeta = createSelector(
  [getLocation, getEvent, getModule],
  (location, event, module): IMeta => {
    const isEmpik = config.theme.isEmpik;

    let pageName: IMetaValue = TEXTS.LOADING;
    let description: IMetaValue = TEXTS.LOADING;
    let ogDescription: IMetaValue = TEXTS.LOADING;
    let noIndex = false;
    let ogImage: string | undefined;
    let currentUrl = `${config.app.baseUrl}${location.pathname}`;

    if (event) {
      const isActivity = module === MODULES.ACTIVITY;

      const category = event.tags?.[0] || null;
      const categoryName = (category?.isMain && category?.name) || TEXTS.EVENTS;
      noIndex = event.partnerId === config.app.goingTestPartnerId;
      currentUrl = `${config.app.baseUrl}${
        isActivity
          ? fillUrlWithValues(
              routes.activity,
              [':eventSlug'],
              [event.eventSlug]
            )
          : fillUrlWithValues(
              routes.event,
              [':eventSlug', ':rundateSlug?'],
              [event.eventSlug, event.rundateSlug]
            )
      }`;

      let eventDescription = event.description;
      if (eventDescription?.length > 60) {
        eventDescription = `${eventDescription.substr(0, 60)}...`;
      }

      const city = event.placeCityName;

      pageName = [
        event.title,
        `${TEXTS.TICKETS_FOR} ${isEmpik ? categoryName : TEXTS.EVENT}`,
      ];

      if (city) {
        pageName.push(city);
      }

      description = [
        `${TEXTS.TICKETS_AND_EVENT_INFO} ${event.title}`,
        event.friendlyDate,
        event.placeAddress,
      ];

      ogDescription = [
        categoryName,
        event.title,
        eventDescription,
        TEXTS.BUY_TICKET,
      ];

      ogImage = event.imagesUrl;
    }

    return {
      currentUrl,
      customGtmId: event?.gtmId,
      description,
      noIndex,
      ogDescription,
      ogImage,
      pageName,
      schema: event ? [createSchemaFromEvent(event)] : null,
    };
  }
);

export default withEventMeta;
