import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    borderColor: theme.border.color,
    borderRadius: theme.border.radius,
    borderStyle: theme.border.style,
    borderWidth: theme.border.width,
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginLeft: 0,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(5),
  },
}));

export default useStyles;
