import { getLocation } from 'connected-react-router';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getIframeParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import {
  cancelTransfer,
  downloadTicketFromTicketData,
  downloadTicketFromTransactionData,
  resetTickets,
  returnTicket,
} from '@Model/tickets/actions';
import { getTicketById, getTicketsData } from '@Model/tickets/selectors';

import MyTicket from './MyTicket.component';
import {
  IMyTicketFromDispatch,
  IMyTicketFromState,
  IReturnTicketPayload,
} from './MyTicket.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IMyTicketFromDispatch => ({
  downloadTicketFromTicketData: (payload) =>
    dispatch(downloadTicketFromTicketData(payload)),
  downloadTicketFromTransactionData: (payload) =>
    dispatch(downloadTicketFromTransactionData(payload)),
  resetTicket: () => dispatch(resetTickets()),
  returnTicket: (ticket: IReturnTicketPayload) =>
    dispatch(returnTicket(ticket)),
  cancelTransfer: (payload) => dispatch(cancelTransfer(payload)),
});

const mapStateToProps = (state: _Store.IState): IMyTicketFromState => {
  return {
    isEmbed: !!getIframeParams(state),
    lang: translate(state)('ticketsManagement', 'myTicket'),
    ticketData: getTicketById(state)(getLocation(state).pathname.split('/')[2]),
    tickets: getTicketsData(state),
  };
};

export default connect<
  IMyTicketFromState,
  IMyTicketFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(MyTicket);
