const classes = {
  button: 'buy-box-buy-button',
  buttonWrapper: 'buy-box-button-wrapper',
  buy: 'buy-box-buy',
  buyCallBox: 'buy-box',
  buyMain: 'buy-box-buy-main',
  calendarIcon: 'buy-box-calendar-icon',
  clockIcon: 'buy-box-clock-icon',
  date: 'buy-box-date',
  disabledButton: 'buy-box-disabled-button',
  locationIcon: 'buy-box-location-icon',
  place: 'buy-box-place',
  price: 'buy-box-price',
  ticketIcon: 'buy-box-ticket-icon',
  tickets: 'buy-box-tickets',
  time: 'buy-box-time',
  title: 'buy-box-title',
  wrapper: 'buy-box-wrapper',
};

export default classes;
