import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolsSelectedTicketsReducer, IPoolsSummary } from './../types';
import getSelectedTickets from './getSelectedTickets';

const getSummary = createSelector<
  _Store.IState,
  IPoolsSelectedTicketsReducer,
  IPoolsSummary
>([getSelectedTickets], (selectedTickets: IPoolsSelectedTicketsReducer) => {
  return selectedTickets.reduce(
    (previous, current) => {
      const currentPrice = current.amount * (current.price || 0);
      const currentServiceFee = current.amount * (current.serviceFee || 0);

      return {
        amount: previous.amount + current.amount,
        finalPrice: previous.finalPrice + currentPrice + currentServiceFee,
        serviceFee: previous.serviceFee + currentServiceFee,
        summedPrice: previous.summedPrice + currentPrice,
      };
    },
    { amount: 0, finalPrice: 0, serviceFee: 0, summedPrice: 0 }
  );
});

export default getSummary;
