import { createSelector, defaultMemoize } from 'reselect';
import { object, string } from 'yup';

import _Store from '@Store';

import * as CONSTS from '@Compo/buying/constants/constants';
import { discountCodeValidator } from '@Compo/buying/constants/validators';
import { ILang } from '@Model/locale/types';
import { getProducts } from '@Model/products/selectors';
import { IProduct } from '@Model/products/types';

import {
  IHappeningFormOnsiteValidators,
  IHappeningFormOnsiteValues,
} from './HappeningBuyFormOnsite.types';

export const getInitialValues = createSelector<
  _Store.IState,
  IProduct[],
  IHappeningFormOnsiteValues
>([getProducts], (products) => {
  const initialValues: IHappeningFormOnsiteValues = {
    authId: CONSTS.DEFAULT_EMPTY_TEXT,
    discount: CONSTS.DEFAULT_EMPTY_TEXT,
    discountCheckbox: false,
    empikPremiumNumber: CONSTS.DEFAULT_EMPTY_TEXT,
    products: {},
  };

  Object.values(products).forEach((product) => {
    initialValues.products[`${product.id}`] = product.autocomplete ? 1 : 0;
  });

  return initialValues;
});

const makeValidationSchemaToMemoize = (errors: ILang) => {
  const validators: Partial<IHappeningFormOnsiteValidators> = {
    authId: string().required(errors.lastNameIsRequiredValidator),
    discount: discountCodeValidator(errors),
  };

  return object().shape(validators as IHappeningFormOnsiteValidators);
};

export const makeValidationSchema = defaultMemoize(
  makeValidationSchemaToMemoize
);
