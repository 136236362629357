import React, { useEffect } from 'react';

import { useTheme } from '@material-ui/core';
import cn from 'classnames';
import Helmet from 'react-helmet';

import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import config from '@Config';

import styles from './../styles/errors.module.scss';
import useStyles from './../styles/errors.styles';
import { IError404Props } from './Error404.types';

const HEADER_TEXT = '404 - Strony nie znaleziono';
const NOT_FOUND_TEXT = 'Strona o podanym adresie nie istnieje.';
const QUESTION_TEXT = 'Czy na pewno szukasz';

const Error404 = ({ location, informAboutError, isEmbed }: IError404Props) => {
  useEffect(() => {
    informAboutError();
  }, []);

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <>
      <Helmet>
        <meta name={'prerender-status-code'} content={'404'} />
      </Helmet>
      <FullTriangleBackground />
      <MuiGrid>
        <MuiSectionContainer>
          <div
            className={cn(
              styles.error,
              muiStyles.root,
              config.theme.isEmpik && styles.withApostrophe
            )}
          >
            <div className={styles.content}>
              <h2>{HEADER_TEXT}</h2>
            </div>
            <div className={styles.tryAgain}>
              {NOT_FOUND_TEXT}
              <br />
              {QUESTION_TEXT} <strong>{location.pathname}</strong>?
            </div>
          </div>
        </MuiSectionContainer>
      </MuiGrid>
    </>
  );
};

export default Error404;
