const linkToFileInStorage = (
  url: string,
  useTimestamp = true,
  host?: string
): string => {
  let link = url;

  if (useTimestamp) {
    link += '?t=' + Date.now().toString().substr(0, 9);
  }

  if (host) {
    link = host + link;
  }

  return link;
};

export default linkToFileInStorage;
