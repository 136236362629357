import { createSelector } from 'reselect';

import _Store from '@Store';

import {
  IAvailabilities,
  IAvailabilitiesData,
  IExtendedSlot,
  IHappeningDataReducer,
} from '../types';
import getAvailabilitiesSelector from './getAvailabilitiesSelector';
import getData from './getData';
import getSelectedSlot from './getSelectedSlot';

const getExtendedSlots = createSelector<
  _Store.IState,
  IHappeningDataReducer,
  IAvailabilities | undefined,
  IAvailabilitiesData,
  IExtendedSlot[]
>(
  [getData, getSelectedSlot, getAvailabilitiesSelector],
  (data, selectedSlot, availableSlots) => {
    if (data?.multipleSlotReservation && selectedSlot) {
      const filteredSlots: IAvailabilities[] = [];
      const filteredAvailableSlots = availableSlots.filter(
        (slot) => !slot.isDisabled || !slot.reserved
      );

      const index = filteredAvailableSlots.findIndex(
        (slot) => slot.slotDateTime === selectedSlot.slotDateTime
      );

      const slicedArray = filteredAvailableSlots.slice(index);

      slicedArray.some((element, i, array) => {
        filteredSlots.push(element);

        return (
          ((array[i + 1]?.slotDateTime || 0) - element.slotDateTime) / 1000 !==
          selectedSlot.duration
        );
      });

      const arrayWithoutSelectedSlot = filteredSlots.slice(1);

      const getArrayLength = () => {
        if (data.multipleSlotReservation) {
          if (arrayWithoutSelectedSlot.length > data.multipleSlotReservation) {
            return data.multipleSlotReservation;
          }

          return arrayWithoutSelectedSlot.length;
        }

        return 0;
      };

      const slots = Array.from({ length: getArrayLength() }, (_, i) => {
        const upsellExtended = selectedSlot.spaces[0].upsellExtended;
        const foundedSlot = upsellExtended?.find(
          (upsell) => upsell.slotIndex === i + 1
        );

        return {
          duration: (i + 1) * (selectedSlot.duration / 60),
          priceMultiplier: (foundedSlot?.slotIndex || 0) + 1,
          upsellValue: foundedSlot?.value,
          upsellPercentageValue: foundedSlot?.percentageValue,
        };
      });

      return slots;
    }

    return [];
  }
);

export default getExtendedSlots;
