import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import { IGetEventsSuccessPayload } from './../types';

// Public actions
export const placeEventsMounted = createStandardAction(CONSTS.MOUNTED)();
export const requestMoreEvents = createStandardAction(CONSTS.MORE_EVENTS)();

// Private actions
export const getPlaceEvents = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IGetEventsSuccessPayload, Error>();
export const resetState = createStandardAction(CONSTS._RESET_STATE)();
