import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import Summary from '@Compo/eventBoxes/Summary/Summary.component';
import {
  ISummaryFromDispatch,
  ISummaryFromState,
} from '@Compo/eventBoxes/Summary/Summary.types';
import { isDesktop } from '@Model/app/selectors';
import {
  getHappeningEvent,
  getSelectedExtendedSlot,
  getSelected as getSelections,
  getSlotsSummary,
} from '@Model/happening/selectors';
import { getIframeParams, getModule } from '@Model/internalRouter/selectors';
import { getSelectedLocale, translate } from '@Model/locale/selectors';
import { setFinalPrice } from '@Model/pools/actions';
import { getSelectedTickets } from '@Model/pools/selectors';
import { setInsuranceSummaryData } from '@Model/products/actions';
import {
  getProductsSummary,
  getSelected,
  getSelectedProducts,
} from '@Model/products/selectors';
import { EVENT } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { getTheme } from '@Model/theme/selectors';

const mapStateToProps = (state: _Store.IState): ISummaryFromState => ({
  dateTranslate: translate(state)('dates'),
  defaultSelections: getSelections(state).selections,
  event: getHappeningEvent(state),
  isDesktop: isDesktop(state),
  isEmbed: !!getIframeParams(state),
  isLoading: getLoading(EVENT)(state),
  lang: translate(state)('buy', 'summary'),
  langBasket: translate(state)('basket'),
  language: getSelectedLocale(state),
  module: getModule(state),
  products: getSelected(state),
  selectedDay: getSelections(state).day,
  selectedDayFromSlot: getSelections(state).dayFromSlot,
  selectedProducts: getSelectedProducts(state),
  selectedTheme: getTheme(state),
  selectedTickets: getSelectedTickets(state),
  selections: getSelections(state).selectionsPerPriceType,
  shipments: [],
  slots: getSlotsSummary(state) || undefined,
  specialEventCopy: null,
  summary: null,
  summaryProducts: getProductsSummary(state),
  selectedExtendedSlot: getSelectedExtendedSlot(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISummaryFromDispatch => {
  return {
    setFinalPriceAction: (value) => dispatch(setFinalPrice(value)),
    setInsuranceSummaryData: (value) =>
      dispatch(setInsuranceSummaryData(value)),
  };
};

export default connect<
  ISummaryFromState,
  ISummaryFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Summary);
