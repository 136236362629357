import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import EventsListService from '@Misc/classes/EventsListService';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import translatableDate from '@Misc/helpers/translatableDate';
import { ILang } from '@Model/locale/types';
import { IElement, ISelectionShort } from '@Model/selection/types';

import { IElementResponse, ISelectionResponse } from './types';

class SelectionApi extends EventsListService {
  private static getSelectionUri(slug: string): string {
    return `${config.api.baseUrl}selection/${slug}`;
  }

  private static getSelectionByIdUri(id: number): string {
    return `${config.api.baseUrl}selection/${id}`;
  }

  private static normalizeElementData(
    elementResponse: IElementResponse,
    translateDate: ILang
  ): IElement {
    const {
      buttonLabel,
      currency,
      date,
      dateDesc,
      description,
      externalImages,
      notForSale,
      thumbUrl,
      title,
      place,
      price,
      slug,
      endDate,
      changeMonitorName,
    } = elementResponse;
    const dataString = `${date.year}-${date.month}-${date.day}T${date.hour}:${date.minutes}:00Z`;

    return {
      buttonLabel,
      changeMonitorName,
      currency,
      description,
      eventSlug: slug,
      friendlyDate: translatableDate(
        dataString,
        undefined,
        translateDate,
        endDate
      ),
      friendlyHour: SelectionApi.getFriendlyHour(dataString),
      imageUrl: !!externalImages.length ? externalImages[0] : thumbUrl,
      notForSale,
      place: place?.name,
      price: String(price),
      rundateDescription: dateDesc,
      title,
    };
  }

  public cancelTokenSelection?: CancelTokenSource;

  public makeKeyFromParams(slug: string) {
    return `${slug}`;
  }

  public getSingleSelection(slug: string): Promise<ISelectionResponse> {
    return new Promise((resolve, reject) => {
      const selectionsUri = SelectionApi.getSelectionUri(slug);
      this.cancelTokenSelection = axios.CancelToken.source();

      axios
        .get(selectionsUri, { cancelToken: this.cancelTokenSelection.token })
        .then(getData)
        .then((response: ISelectionResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(new Error(error)));
        });
    });
  }

  public getSingleSelectionById(id: number): Promise<ISelectionResponse> {
    return new Promise((resolve, reject) => {
      const selectionsUri = SelectionApi.getSelectionByIdUri(id);

      axios
        .get(selectionsUri)
        .then(getData)
        .then((response: ISelectionResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(new Error(error)));
        });
    });
  }

  public normalizeShortSelection(
    selectionResponse: ISelectionResponse,
    translateDate: ILang
  ): ISelectionShort {
    const {
      description,
      elements,
      endDate,
      footer,
      id,
      locationId,
      mobileImage,
      externalImages,
      published,
      slug,
      startDate,
      thumbUrl,
      title,
    } = selectionResponse;

    return {
      date: startDate,
      endDate,
      description,
      elements: selectionResponse.elements.map((element) =>
        SelectionApi.normalizeElementData(element, translateDate)
      ),
      footer,
      id,
      mobileImage: !!externalImages?.length ? externalImages[0] : mobileImage,
      slug,
      thumbUrl: !!externalImages?.length ? externalImages[0] : thumbUrl,
      title,
    };
  }

  public cancelSelection() {
    if (this.cancelTokenSelection) {
      this.cancelTokenSelection.cancel();
      this.cancelTokenSelection = undefined;
    }
  }
}

export default new SelectionApi();
