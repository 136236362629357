import { getLocation } from 'connected-react-router';
import { createSelector } from 'reselect';

import config from '@Config';
import { getSelection } from '@Model/selection/selectors';

import * as TEXTS from './../constants/texts';
import { IMeta, IMetaValue } from './../types';

const selectionPageMeta = createSelector(
  [getSelection, getLocation],
  (selection, location): IMeta => {
    let pageName: IMetaValue = TEXTS.LOADING;
    let description: IMetaValue = TEXTS.LOADING;
    let ogDescription: IMetaValue = TEXTS.LOADING;

    if (selection) {
      pageName = [selection.title, TEXTS.SELECTION];
      description = [selection.description, TEXTS.SELECTION];
      ogDescription = [selection.description, TEXTS.SELECTION];
    }

    return {
      currentUrl: `${config.app.baseUrl}${location.pathname}`,
      description,
      ogDescription,
      pageName,
    };
  }
);

export default selectionPageMeta;
