import { getType } from 'typesafe-actions';

import { setSlot, setTicketDate } from './../../actions';
import { IAction, IDateReducer } from './../../types';

const initialState: IDateReducer = {
  date: null,
  slot: '',
};

const reducer = (
  state: IDateReducer = initialState,
  action: IAction
): IDateReducer => {
  switch (action.type) {
    case getType(setTicketDate):
      return {
        ...state,
        date: action.payload,
      };

    case getType(setSlot):
      return {
        ...state,
        slot: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
