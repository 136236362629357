import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import config from '@Config';

const { isEmpik } = config.theme;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& a': {
        color: theme.palette.text.primary,
      },
      color: theme.palette.text.primary,
    },
    backgroundBox: {
      background: theme.palette.background.paper,
    },
    serviceFeeInfo: {
      borderRadius: '0.25rem',
      color: isEmpik
        ? theme.palette.text.secondary
        : theme.palette.secondary.main,
      backgroundColor: isEmpik
        ? theme.palette.background.paper
        : theme.palette.secondary.light,
      fontWeight: 500,
      fontSize: '0.875rem',
      padding: '0.5rem',
      marginTop: '1rem',
    },
    imageContainer: {
      borderRadius: '1rem 1rem 0 0',
    },
  })
);

export default useStyles;
