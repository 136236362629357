class LocaleService {
  protected static normalizeLocale(locale: string): string {
    return locale.replace('_', '-').toLowerCase();
  }

  protected static resolveUserLocale(
    supportedLocales: ReadonlyArray<string>,
    userLocale: string
  ): string | undefined {
    const userLanguageCode = this.languageCode(userLocale);

    const languageCodeMatch = supportedLocales.find(
      (supported) => this.languageCode(supported) === userLanguageCode
    );

    if (languageCodeMatch) {
      return languageCodeMatch;
    }
  }

  private static languageCode(locale: string): string {
    return locale.split('-')[0];
  }
}

export default LocaleService;
