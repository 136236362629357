import { defaultMemoize } from 'reselect';
import { bool, object, string } from 'yup';

import { ILang } from '@Model/locale/types';

const personalDataValidation = (lang: ILang) => {
  const validators = {
    firstName: string().required(lang.requiredField),
    lastName: string().required(lang.requiredField),
    email: string().required(lang.requiredField).email(lang.incorrectEmail),
    dateOfBirth: string()
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
        lang.invalidDate
      )
      .required(lang.requiredField),
    privacyPolicy: bool().oneOf([true], lang.privacyPolicy),
  };

  return object().shape(validators);
};

export const makeValidationSchema = (lang: ILang) => {
  const validationSchema = () => personalDataValidation(lang);

  return defaultMemoize(validationSchema);
};
