import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { EMPTY, of as of$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  take as take$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getLocation } from '@Model/router/selectors';

export const removeHashWhenComeFromOutside: _Store.IEpic = (
  action$,
  state$,
  { modalDictionary }
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    take$(1),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const location = getLocation(state);
      const modal = modalDictionary.fromHash(location.hash || '');

      if (modal) {
        const { pathname, search } = location;

        return of$(replace(`${pathname}${search}`));
      }

      return EMPTY;
    })
  );
};
