import EventsListService from '@Misc/classes/EventsListService';
import { IDate } from '@Services/$events-api/types';

/**
 * Function to prepare url with Date from DateObject
 *
 * @param {IDate | undefined} startDate - date Object as year, month, day, hour, minutes
 *
 *
 * @return {string} friendlyDate
 */
const makeFriendlyDate = (startDate: IDate | string | undefined) => {
  if (typeof startDate === 'string') {
    return startDate;
  }

  return EventsListService.getFriendlyDate(
    EventsListService.getDateString(startDate)
  );
};

export default makeFriendlyDate;
