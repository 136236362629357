import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = (isMobile: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      box: {
        borderRadius: '1rem',
        padding: isMobile ? '1.75rem 1.25rem' : '2.375rem 1.875rem',
      },
      checkboxGap: {
        marginTop: '0.5rem',
      },
      container: {
        marginTop: '3.75rem',
        transition: '400ms',
      },
      disabled: {
        cursor: 'initial',
        opacity: 0.6,
        pointerEvents: 'none',
      },
      heading: {
        marginBottom: '2rem',
      },
      infoLink: {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
        // @ts-ignore TODO
        fontWeight: theme.typography.fontWeightBold,
      },
      indentation: {
        paddingLeft: '1.5rem !important',
      },
      stretch: {
        flex: 1,
      },
      fitContent: {
        maxWidth: 'fit-content',
      },
      link: {
        fontSize: '0.9rem',
        textDecoration: 'none !important',
      },
      toolTipList: {
        paddingLeft: '1rem',
      },
      toolTipListElement: {
        listStyle: 'disc outside none !important',
      },
      tryAgainButton: {
        color: theme.palette.secondary.main + '!important',
      },
    })
  );

export default useStyles;
