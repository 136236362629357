import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { locationChange } from '@Model/internalRouter/actions';
import { setUserLocale } from '@Model/locale/actions';
import { isFirstRendering } from '@Model/router/selectors';

import {
  IInternalRouterFromDispatch,
  IInternalRouterFromState,
} from './../InternalRouter.types';
import Page from './Page.component';

function mapStateToProps(state: _Store.IState): IInternalRouterFromState {
  return {
    isFirstRendering: isFirstRendering(state),
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IInternalRouterFromDispatch => {
  return {
    locationChange: (payload) => dispatch(locationChange(payload)),
    setBrowserLocale: (language?: string) => dispatch(setUserLocale(language)),
  };
};

export default connect<
  IInternalRouterFromState,
  IInternalRouterFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Page);
