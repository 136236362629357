import React, {
  ChangeEvent,
  FC,
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Menu, MenuItem } from '@mui/material';
import cn from 'classnames';
import debounce from 'lodash.debounce';

import { filterHighlights } from '@Compo/Search/Search.helpers';
import useStyles from '@Compo/Search/Search.styles';
import { ISearchProps } from '@Compo/Search/Search.types';
import config from '@Config';
import { ISearchFilter } from '@Model/search/types';
import routes from '@Routes/routes';
import Analytics from '@Services/$analytics';

import styles from './Search.module.scss';
import DateFilter from './components/DateFilter';
import Highlight from './components/Highlight';
import OnsiteInfo from './components/OnsiteInfo';
import ResultsList from './components/ResultsList';

const CHOOSE_LOCATIONS_TEXT = 'miasto';
const CHOOSE_CATEGORIES_TEXT = 'kategoria';
const CHOOSE_TAGS_TEXT = 'tag';
const SEARCH_TEXT = config.theme.isEmpik ? 'Szukaj' : 'Go!';
const SEARCH_DEBOUNCE_DELAY = 500;

const Search: FC<ISearchProps> = ({
  brickFilters,
  children,
  eventSearchClick,
  getCategories,
  history,
  locations,
  isMobile,
  placeholder,
  removeFilter,
  searchCategories,
  searchDates,
  searchFilters,
  searchHighlights,
  searchTags,
  searchMounted,
  searchPhrase,
  searchResults,
  search,
  setFilter,
  setSearchPhrase,
  showApostrophe,
  widget,
  setLastFilter,
}) => {
  const [categoriesAnchorEl, setCategoriesAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [locationsAnchorEl, setLocationsAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [tagsAnchorEl, setTagsAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [showLocations, toggleLocations] = useState(false);
  const [showCategories, toggleCategories] = useState(false);
  const [showTags, toggleTags] = useState(false);
  const [showHighlights, toggleHighlights] = useState(true);
  const [isFirstRequestDone, setFirstRequestDone] = useState(false);
  const [categories, setCategories] = useState(searchCategories);
  const [tags, setTags] = useState(searchTags);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const showChildren =
    !widget &&
    children &&
    !searchPhrase &&
    !searchDates.from &&
    !searchFilters.length;
  const muiStyles = useStyles();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    search(0);
    toggleHighlights(false);
    setFirstRequestDone(true);
    eventSearchClick();
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
    if (!showHighlights) {
      toggleHighlights(true);
    }
  };

  const appendFiltersToRoute = (filters: ISearchFilter[], route: string) => {
    let routeWithFilterParams = route;
    filters.forEach((item, index) => {
      if (index === 0) {
        routeWithFilterParams += `?${item.key}=${item.value}`;
      } else {
        routeWithFilterParams += `&${item.key}=${item.value}`;
      }
    });

    return routeWithFilterParams;
  };

  const onClick = (e: MouseEvent<HTMLElement>) => {
    if (widget) {
      if (brickFilters) {
        const route = appendFiltersToRoute(brickFilters, routes.events);
        history.push(route);

        Analytics.pushForHomepageSelectItem(
          e,
          `${config.app.baseUrl}${route}`,
          'searchbar'
        );
      } else {
        history.push(routes.events);

        Analytics.pushForHomepageSelectItem(
          e,
          `${config.app.baseUrl}${routes.events}`,
          'searchbar'
        );
      }
    }
  };

  const delayQuery = useCallback(debounce(search, SEARCH_DEBOUNCE_DELAY), []);
  const delayFacetsQuery = useCallback(
    debounce(getCategories, SEARCH_DEBOUNCE_DELAY),
    []
  );

  useEffect(() => {
    searchMounted();
  }, []);

  useEffect(() => {
    setFirstRequestDone(false);
  }, [searchPhrase]);

  useEffect(() => {
    delayQuery(0);
    setFirstRequestDone(true);

    return delayQuery.cancel;
  }, [searchPhrase, searchFilters, searchDates]);

  useEffect(() => {
    if (!widget) {
      inputRef?.current?.focus();
    }
  }, [widget, inputRef]);

  useEffect(() => {
    delayFacetsQuery();

    return delayFacetsQuery.cancel;
  }, [searchPhrase]);

  useEffect(() => {
    setCategories(searchCategories);
  }, [searchCategories]);

  useEffect(() => {
    setTags(searchTags);
  }, [searchTags]);

  useEffect(() => {
    if (isFirstRequestDone && !searchResults.length) search(0, true);
  }, [isFirstRequestDone, searchResults.length]);

  const highlightsList = filterHighlights(searchHighlights, searchFilters).map(
    (highlight) => (
      <Highlight
        category={highlight.key}
        count={highlight.count}
        thumb={highlight.key}
        title={highlight.value}
        key={highlight.key + '-' + highlight.value}
      />
    )
  );

  return (
    <div className={cn(styles.searchWrapper, !widget && styles.page)}>
      {!config.app.onlineSale && <OnsiteInfo />}
      <form autoComplete={'off'} className={styles.inputContainer} action={'#'}>
        {showApostrophe && !isMobile && <div className={styles.apostrophe} />}

        <input
          className={styles.input}
          onChange={onInputChange}
          onClick={onClick}
          placeholder={placeholder}
          ref={inputRef}
          name={'search'}
          type={'search'}
          value={searchPhrase}
        />

        <button
          className={styles.button}
          type={'submit'}
          onClick={handleSubmit}
        >
          <span className={styles.buttonText}>{SEARCH_TEXT}</span>
        </button>
      </form>

      {!widget && (
        <>
          {searchPhrase && !!highlightsList.length && showHighlights && (
            <ul className={styles.highlightsList}>{highlightsList}</ul>
          )}

          <div className={styles.filtersList}>
            <DateFilter buttonClass={styles.filter} />

            <div
              className={cn(styles.filter, muiStyles.filter)}
              onClick={(event) => {
                setCategoriesAnchorEl(event.currentTarget);
                toggleCategories((state) => !state);
              }}
            >
              <span>{CHOOSE_CATEGORIES_TEXT}</span>
            </div>

            <Menu
              open={showCategories}
              onClose={() => {
                toggleCategories(false);
                setCategoriesAnchorEl(null);
              }}
              anchorEl={categoriesAnchorEl}
            >
              {categories.map((filter) => (
                <MenuItem
                  onClick={() => {
                    setFilter({
                      key: 'event_category_name',
                      value: filter.name,
                      preventResetPhrase: true,
                      lastFilterName: CHOOSE_CATEGORIES_TEXT,
                    });
                    setLastFilter(CHOOSE_CATEGORIES_TEXT);
                    toggleCategories(false);
                  }}
                  key={'category' + '-' + filter.slug}
                >
                  <div className={cn(styles.filter, muiStyles.filter)}>
                    <span>{filter.name}</span>
                  </div>
                </MenuItem>
              ))}
            </Menu>

            <div
              className={cn(styles.filter, muiStyles.filter)}
              onClick={(event) => {
                setTagsAnchorEl(event.currentTarget);
                toggleTags((state) => !state);
              }}
            >
              <span>{CHOOSE_TAGS_TEXT}</span>
            </div>

            <Menu
              open={showTags}
              onClose={() => {
                toggleTags(false);
                setTagsAnchorEl(null);
              }}
              anchorEl={tagsAnchorEl}
            >
              {tags.map((filter) => (
                <MenuItem
                  onClick={() => {
                    setFilter({
                      key: 'public_tags_names',
                      value: filter.name,
                      preventResetPhrase: true,
                      lastFilterName: CHOOSE_TAGS_TEXT,
                    });
                    setLastFilter(CHOOSE_TAGS_TEXT);
                    toggleTags(false);
                  }}
                  key={'tag' + '-' + filter.slug}
                >
                  <div className={cn(styles.filter, muiStyles.filter)}>
                    <span>{filter.name}</span>
                  </div>
                </MenuItem>
              ))}
            </Menu>

            <div
              className={cn(styles.filter, muiStyles.filter)}
              onClick={(event) => {
                setLocationsAnchorEl(event.currentTarget);
                toggleLocations((state) => !state);
              }}
            >
              <span>{CHOOSE_LOCATIONS_TEXT}</span>
            </div>

            <Menu
              open={showLocations}
              onClose={() => {
                toggleLocations(false);
                setLocationsAnchorEl(null);
              }}
              anchorEl={locationsAnchorEl}
            >
              {Object.values(locations).map((location) => (
                <MenuItem
                  onClick={() => {
                    setFilter({
                      key: 'locations_names',
                      value: location.name,
                      preventResetPhrase: true,
                      lastFilterName: CHOOSE_LOCATIONS_TEXT,
                    });
                    setLastFilter(CHOOSE_LOCATIONS_TEXT);
                    toggleLocations(false);
                  }}
                  key={'location' + '-' + location.name}
                >
                  <div className={cn(styles.filter, muiStyles.filter)}>
                    <span>{location.name}</span>
                  </div>
                </MenuItem>
              ))}
            </Menu>

            {searchFilters.map((filter) => (
              <div
                className={cn(styles.filter, muiStyles.filter)}
                key={filter.key + '-' + filter.value}
                onClick={() =>
                  removeFilter({ key: filter.key, value: filter.value })
                }
              >
                <span>{filter.value}</span>
                <i>✖︎</i>
              </div>
            ))}
          </div>

          {showChildren && (
            <div className={styles.childrenContainer}>{children}</div>
          )}
          <ResultsList />
        </>
      )}
    </div>
  );
};

export default Search;
