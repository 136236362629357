import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { getAvailablePaymentMethods } from '@Model/pools/selectors';
import { getLocation } from '@Model/router/selectors';
import { getTheme } from '@Model/theme/selectors';
import { setCardCredentials } from '@Model/transaction/actions';

import PaymentMethod from './PaymentMethod.component';
import {
  IPaymentMethodFromDispatch,
  IPaymentMethodFromState,
} from './PaymentMethod.types';

const mapStateToProps = (state: _Store.IState): IPaymentMethodFromState => {
  return {
    isDarkTheme: getLocation(state).query?.isDark === 'true',
    lang: translate(state)('buy', 'paymentMethod'),
    paymentMethodsFromState: getAvailablePaymentMethods(state),
    selectedTheme: getTheme(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPaymentMethodFromDispatch => ({
  setCardCredentials: (cardCredentials) =>
    dispatch(setCardCredentials(cardCredentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
