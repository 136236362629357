import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEvent } from '@Model/events/types';
import { ISearchReducer } from '@Model/search/types';

import get from './get';

const getSearchResults = createSelector<
  _Store.IState,
  ISearchReducer,
  IEvent[] | []
>([get], (search) => search.searchResults);

export default getSearchResults;
