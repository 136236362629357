import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getSelectedLocation } from '@Model/locations/selectors';
import { getUrlStructure } from '@Model/pages/selectors';
import { searchStructureMounted } from '@Model/search/actions';

import SearchStructure from './SearchStructure.component';
import {
  ISearchStructureFromDispatch,
  ISearchStructureFromState,
} from './SearchStructure.types';

const mapStateToProps = (state: _Store.IState): ISearchStructureFromState => ({
  structure: getUrlStructure(state),
  userLocation: getSelectedLocation(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISearchStructureFromDispatch => ({
  searchStructureMounted: () => dispatch(searchStructureMounted()),
});

export default connect<
  ISearchStructureFromState,
  ISearchStructureFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(SearchStructure);
