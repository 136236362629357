import React, { FC, useEffect } from 'react';

import { IPaymentProps } from './Payment.types';

const Payment: FC<IPaymentProps> = ({ mounted }) => {
  useEffect(() => {
    mounted();
  }, []);

  return null;
};

export default Payment;
