import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';

import { IFormioRedirectProps } from '@Compo/Formio/Formio.types';
import config from '@Config';
import { IPoolSelectedTicket } from '@Model/pools/types';

import useStyles from './Forms.styles';
import { IFormioSubmitResponse, IFormsProps, IFormsRef } from './Forms.types';

const Forms = forwardRef<IFormsRef, IFormsProps>((props, ref) => {
  const {
    selectedTickets,
    number,
    selectTicket,
    lang,
    firstName,
    lastName,
    handleSubmit,
  } = props;

  const muiStyles = useStyles();
  const formRefs = useRef<Array<HTMLDivElement | null>>([]);
  const [count, setCount] = useState<number[]>([]);
  const [formio, setFormio] = useState<IFormioRedirectProps>();

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formio) {
        formio.emit('submitButton');
      }
    },
  }));

  useEffect(() => {
    setCount(selectedTickets.map((ticket) => ticket.amount));
  }, [selectedTickets]);

  const onSubmitDone = (
    ticket: IPoolSelectedTicket,
    data: IFormioSubmitResponse
  ) => {
    if (ticket) {
      selectTicket(
        ticket.poolId,
        ticket.amount,
        ticket.poolName,
        ticket.currency,
        ticket.paymentMethods,
        ticket.price,
        ticket.serviceFee,
        ticket.additionalFields,
        ticket.forms,
        [{ formId: data.form, submissionId: data._id }]
      );
      handleSubmit();
    }
  };

  useEffect(() => {
    if (formRefs) {
      selectedTickets.forEach((ticket) =>
        ticket.forms?.forEach((form) => {
          form.parameters
            .filter((param) => param.type === 'url')
            .forEach((param, index) => {
              try {
                window.Formio.createForm(formRefs.current[index], param.value, {
                  i18n: {
                    pl: config.formIo.langPL.default,
                    en: config.formIo.langEN.goingSupport, // tslint:disable-line:object-literal-sort-keys
                  },
                }).then((formIo: IFormioRedirectProps) => {
                  setFormio(formIo);
                  formIo.on('submitDone', (data) => {
                    onSubmitDone(ticket, data);
                  });
                });
              } catch (noSSR) {}
            });
        })
      );
    }
  }, [formRefs, JSON.stringify(count)]);

  useEffect(() => {
    if (formio) {
      formio.submission = {
        ...formio.submission,
        data: { ...formio.submission?.data, firstName, lastName },
      };
    }
  }, [firstName, lastName, formio]);

  return (
    <div className={muiStyles.container}>
      <h3 className={muiStyles.header} data-number={number}>
        {lang.additionalData}
      </h3>
      {selectedTickets.map((ticket) => (
        <fieldset
          key={ticket.poolId}
          className={cn(muiStyles.formPanel, muiStyles.root)}
        >
          {ticket.forms &&
            ticket.forms.map((form) => (
              <>
                {form.parameters
                  .filter((param) => param.type === 'url')
                  .map((param, index) => (
                    <div
                      key={index}
                      ref={(el) => (formRefs.current[index] = el)}
                      className={muiStyles.formContainer}
                    />
                  ))}
              </>
            ))}
        </fieldset>
      ))}
    </div>
  );
});

export default Forms;
