import { connect } from 'react-redux';

import _Store from '@Store';

import { isBrowser } from '@Model/__base__/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';
import {
  getCardCredentials,
  getData,
  isPayUPaymentAvailable,
} from '@Model/transaction/selectors';

import PayURedirect from './RedirectForPayU.component';
import {
  IRedirectForPayUData,
  IRedirectForPayUProps,
} from './RedirectForPayU.types';

const mapStateToProps = (state: _Store.IState): IRedirectForPayUProps => ({
  data: getData(state) as IRedirectForPayUData,
  isBrowser: isBrowser(state) && !getIframeParams(state),
  isPaymentAvailable: isPayUPaymentAvailable(state),
  isCardPayment: !!getCardCredentials(state),
});

export default connect<IRedirectForPayUProps, null, {}, _Store.IState>(
  mapStateToProps
)(PayURedirect);
