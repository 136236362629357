import { Selector } from 'reselect';

import _Store from '@Store';

import { ISelectionReducer } from './../types';

const get: Selector<_Store.IState, ISelectionReducer> = (state) =>
  state.selection;

export default get;
