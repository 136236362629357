/**
 * Capitalize first letter of a string
 *
 * @param {string} str - input string
 *
 * @return {string} string with first letter capitalized
 */
const ucFirst = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export default ucFirst;
