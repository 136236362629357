import { connect } from 'react-redux';

import _Store from '@Store';

import { getLocation } from '@Model/router/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import ArtistSliderBrick from './ArtistSliderBrick.component';
import {
  IArtistSliderBrickFromState,
  IArtistSliderBrickOwnProps,
} from './ArtistSliderBrick.types';

const mapStateToProps = (
  state: _Store.IState
): IArtistSliderBrickFromState => ({
  isDarkMode: getLocation(state).query?.isDark === 'true',
  isLoading: getLoading(CONST.SECTIONS)(state),
});

export default connect<
  IArtistSliderBrickFromState,
  null,
  IArtistSliderBrickOwnProps,
  _Store.IState
>(mapStateToProps)(ArtistSliderBrick);
