import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import { EMPTY as EMPTY$ } from 'rxjs/internal/observable/empty';
import { catchError as catchError$ } from 'rxjs/internal/operators/catchError';
import { takeUntil as takeUntil$ } from 'rxjs/internal/operators/takeUntil';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  tap as tap$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { checkEmailValidity, checkMailValidation } from '../actions';

export const checkIfUserEmailIsValid: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(checkEmailValidity)),
    mergeMap$((action) => {
      return of$(checkMailValidation.request(action.payload));
    })
  );
};

export const sendMailValidationRequest: _Store.IEpic = (
  action$,
  state$,
  { sendgridApi }
) => {
  return action$.pipe(
    filter$(isActionOf(checkMailValidation.request)),
    mergeMap$((action) =>
      from$(sendgridApi.checkEmail({ addressEmail: action.payload })).pipe(
        mergeMap$((data) => {
          return data ? of$(checkMailValidation.success(data)) : EMPTY$;
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => sendgridApi.cancelSendgrid())
          )
        ),
        catchError$((error) => of$(checkMailValidation.failure(error)))
      )
    )
  );
};
