import { connect } from 'react-redux';

import _Store from '@Store';

import { getIframeParams } from '@Model/internalRouter/selectors';

import ErrorBoundary from './ErrorBoundary.component';
import { IErrorBoundaryFromState } from './ErrorBoundary.types';

function mapStateToProps(state: _Store.IState): IErrorBoundaryFromState {
  return {
    isEmbed: !!getIframeParams(state),
  };
}

export default connect<IErrorBoundaryFromState, void, {}, _Store.IState>(
  mapStateToProps
)(ErrorBoundary);
