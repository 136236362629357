import { createSelector } from 'reselect';

import _Store from '@Store';

import { IDataFromTicketProps, IEntryListReducer } from './../types';
import get from './get';

const getTicketData = createSelector<
  _Store.IState,
  IEntryListReducer,
  IDataFromTicketProps | null
>([get], (state) => state.ticketData);

export default getTicketData;
