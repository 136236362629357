import { LOCATION_CHANGE } from 'connected-react-router';
import format from 'date-fns/format';
import moment from 'moment';
import normalizeUrl from 'normalize-url';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  debounceTime as debounceTime$,
  filter as filter$,
  mergeMap as mergeMap$,
  take as take$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import TransactionError from '@Misc/classes/TransactionError';
import utcOffsetFixer from '@Misc/helpers/dateTime/utcOffsetFixer';
import getLocalizedDescription from '@Misc/helpers/getLocalizedDescription';
import isValidTheme from '@Misc/helpers/isValidTheme';
import replaceColonParamsWithBrackets from '@Misc/helpers/replaceColonParamsWithBrackets';
import { emptyAction } from '@Model/app/actions';
import { setError } from '@Model/errors/actions';
import { getEvent as getEventReq } from '@Model/event/actions';
import { getCurrentKey, getEvent } from '@Model/event/selectors';
import {
  getDiscount,
  getExtendedSlots,
  getSelectedSpaceProducts,
} from '@Model/happening/selectors';
import {
  getIframeParams,
  getModule,
  getParams,
} from '@Model/internalRouter/selectors';
import { getSelectedLocale, translate } from '@Model/locale/selectors';
import { createNotification } from '@Model/notifications/actions';
import { updateSelectedTickets } from '@Model/pools/actions';
import { getSelectedTickets } from '@Model/pools/selectors';
import { getSelectedProducts } from '@Model/products/selectors';
import { getLocation } from '@Model/router/selectors';
import { getCheckEmail } from '@Model/sendgrid/selectors';
import { TRANSACTION } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { changeTheme } from '@Model/theme/actions';
import { sendTransaction } from '@Model/transaction/actions';
import { PaymentMethods } from '@Model/transaction/constants/paymentMethods';
import { getCardCredentials } from '@Model/transaction/selectors';
import {
  IPriceReduction,
  ISlotReservationData,
} from '@Model/transaction/types';
import * as MODULES from '@Routes/modules';
import routes from '@Routes/routes';
import { IHappeningMatchParams } from '@Routes/types';
import {
  IPriceBody,
  IReservationCheckPrice,
} from '@Services/$price-type-api/types';

import { ISelectedUpsell, ISelectionPerPriceType } from '../types';
import {
  calculatePrice,
  getAvailabilities,
  getHappening,
  happeningMounted,
  reserveSlots,
  reserveSlotsOnsite,
  saveTerms,
  setCalculatedPrices,
  setDay,
  setExtendedSlot,
  setNumberOfPlayers,
  setOnlyOnePriceType,
  setSelectedUpsell,
  setSlot,
  setSpace,
  setTotalPrice,
  setTotalPriceInState,
  setUpsellState,
  toggleSelectedUpsell,
  upsellMounted,
} from './../actions';
import { setExtendedSlotModalVisible } from './../actions/index';
import {
  calculatePrice as calculatePriceSelector,
  getAvailabilities as getAvailabilitiesSelector,
  getData,
  getIdempotencyKey,
  getSelected,
  getSelectedExtendedSlot,
  getSelectedSlot,
} from './../selectors';

const URI_TRANSACTION_KEY = 'transactionId';
const SECONDS_IN_MINUTE = 60;

export const whenHappeningMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(happeningMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { slug: happeningSlug } = getParams(state) as IHappeningMatchParams;
      const activeHappening = getData(state);
      const activityEventKey = getCurrentKey(state);

      if (activeHappening?.slug === happeningSlug) {
        if (isValidTheme(activeHappening.partner)) {
          return of$(changeTheme(activeHappening.partner));
        }

        return EMPTY$;
      }

      if (!activityEventKey)
        return [getHappening.request(happeningSlug), getEventReq.request()];

      return of$(getHappening.request(happeningSlug));
    })
  );
};

export const whenHappeningRequested: _Store.IEpic = (
  action$,
  state$,
  { happeningApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getHappening.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(happeningApi.getHappening(action.payload)).pipe(
        mergeMap$((data) => {
          if (
            isValidTheme(data.partner) &&
            config.theme.isGoing &&
            getLocation(state).query?.isDark !== 'true'
          ) {
            return [
              getHappening.success(data),
              setDay(data.startDate),
              changeTheme(data.partner),
            ];
          }

          return [getHappening.success(data), setDay(data.startDate)];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => happeningApi.cancelSingleHappening())
          )
        ),
        catchError$((error: Error) => of$(getHappening.failure(error)))
      );
    })
  );
};

export const getCustomTerms: _Store.IEpic = (action$, state$, { termsApi }) => {
  return action$.pipe(
    filter$(isActionOf([getHappening.success, setSpace])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const happening = getData(state);
      const selectedSlot = getSelected(state).space;
      const spaceDescriptions =
        happening?.spaces.find((space) => space.id === selectedSlot)
          ?.description || happening?.spaces[0].description;
      const userLocale = getSelectedLocale(state);
      const localizedDescription = getLocalizedDescription(
        spaceDescriptions,
        userLocale
      );

      const eventSlug = happening?.slug;
      const rundateSlug = localizedDescription?.slug;

      if (eventSlug && rundateSlug) {
        return of$(saveTerms.request({ eventSlug, rundateSlug }));
      }

      return EMPTY$;
    })
  );
};

export const getTermsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { termsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveTerms.request)),
    mergeMap$((action) => {
      const { eventSlug, rundateSlug } = action.payload;

      return from$(termsApi.getTerms(eventSlug, rundateSlug)).pipe(
        mergeMap$((data) => [saveTerms.success(data)]),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => termsApi.cancelCalling())
          )
        ),
        catchError$((error: Error) => of$(saveTerms.failure(error)))
      );
    })
  );
};

export const getAvailabilitiesOnDayChange: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setDay)),
    mergeMap$((action) => {
      return of$(getAvailabilities.request(action.payload));
    })
  );
};

export const fetchAvailabilitiesWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { availabilitiesApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAvailabilities.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (getParams(state)) {
        const { slug } = getParams(state) as IHappeningMatchParams;

        return from$(
          availabilitiesApi.getAvailabilities(slug, action.payload)
        ).pipe(
          mergeMap$((data) => {
            return [getAvailabilities.success(data)];
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => availabilitiesApi.cancelAvailabilities())
            )
          ),
          catchError$((error: Error) => of$(getAvailabilities.failure(error)))
        );
      }

      return EMPTY$;
    })
  );
};

export const calculatePriceForTicketType: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([setCalculatedPrices, setExtendedSlot])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selected = getSelected(state);
      const module = getModule(state);

      if (
        selected.space === -1 &&
        (module === MODULES.HAPPENING || module === MODULES.EMBED_HAPPENING)
      ) {
        return EMPTY$;
      }

      return of$(
        calculatePrice.request(
          action.payload && 'duration' in action.payload
            ? {}
            : {
                email: action.payload?.emailAddress,
                paymentDetails: action.payload?.paymentMethod,
              }
        )
      );
    })
  );
};

export const calculatePriceWhenChangeTicketType: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf([setNumberOfPlayers, setOnlyOnePriceType])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const selected = getSelected(state);

      if (selected.space === -1) {
        return EMPTY$;
      }

      return of$(setCalculatedPrices({}));
    }),
    debounceTime$(300)
  );
};

export const getPriceCalculated: _Store.IEpic = (
  action$,
  state$,
  { priceTypeApi }
) => {
  return action$.pipe(
    filter$(isActionOf(calculatePrice.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedProducts = getSelectedProducts(state);
      const selected = getSelected(state);
      const selectedTickets = getSelectedTickets(state);
      const query = getLocation(state).query;
      const agent =
        query?.isWebview === 'true'
          ? 'mobile'
          : config.theme.isEmpik
          ? 'embil-web'
          : 'purchase';
      const salesChannelId = config.app.salesChannelId;
      const selectedPriceTypes = selected.selections;
      const happeningData = getData(state);
      const availabilities = getAvailabilitiesSelector(state);
      const selectedAvailability = availabilities.find(
        (availability) => availability.start === selected.slot
      );
      const selectedSpaceInAvailability = selectedAvailability?.spaces.find(
        (space) => space.spaceId === selected.space
      );
      const discount = selected.discount
        ? { code: selected.discount }
        : undefined;
      const prepaidCard = selected.prepaid ? selected.prepaid : undefined;
      const emailValidation = getCheckEmail(state);

      const extendedSlot = getSelectedExtendedSlot(state);
      const selectedTimeSlot = getSelectedSlot(state);
      const cardCredentials = getCardCredentials(state);
      const lang = translate(state)('buy', 'clientData');
      const isDiscount = !!getDiscount(state)?.length;
      const module = getModule(state);

      const getReservations = (): IReservationCheckPrice[] => {
        const selectedSlot = selected.slot ? selected.slot : '';
        const hours = parseInt(selectedSlot.split(':')[0], 10);
        const minutes = parseInt(selectedSlot.split(':')[1], 10);
        const utcFixedDay = utcOffsetFixer(selected.dayFromSlot, 'add');
        const day = moment(utcFixedDay)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
        const dateTime = moment(day)
          .add({
            hours,
            minutes,
          })
          .format('YYYY-MM-DD HH:mm:ss');
        const rulePriceId = selectedSpaceInAvailability?.rulePriceId;
        const spaceId = selected.space;
        const upsell = selected.upsell?.isSelected;

        const reservations: IReservationCheckPrice[] = [];

        selectedPriceTypes.forEach((price) => {
          if (happeningData && price.priceType && price.numberOfPlayers) {
            let priceReduction;
            if (rulePriceId && (discount || upsell)) {
              priceReduction = {
                dateTime,
                discount,
                happeningId: happeningData.id,
                numberOfPeople: price.numberOfPlayers,
                priceType: price.priceType.type,
                rulePriceId,
                spaceId,
                upsell,
              };
            }
            reservations.push({
              dateTime,
              happeningId: happeningData.id,
              numberOfPeople: price.numberOfPlayers,
              priceReduction,
              priceType: price.priceType.type,
              spaceId,
              extendedDuration:
                extendedSlot && selectedTimeSlot
                  ? extendedSlot.duration * SECONDS_IN_MINUTE +
                    selectedTimeSlot.duration
                  : undefined,
            });
          }
        });

        return reservations;
      };

      const body: IPriceBody = {
        agent,
        ...(action.payload.paymentDetails && {
          paymentDetails: {
            type: action.payload.paymentDetails,
            ...(cardCredentials &&
              action.payload.paymentDetails === PaymentMethods.CARD && {
                card: {
                  mask: cardCredentials?.mask,
                },
              }),
          },
        }),
        products: selectedProducts.map((item) => ({
          id: item.id,
          quantity: item.amount,
        })),
        ...(getReservations().length === 0 && { priceReduction: discount }),
        reservations: getReservations(),
        salesChannelId,
        tickets: selectedTickets.map((ticket) => ({
          poolId: ticket.poolId,
          ticketsNum: ticket.amount,
        })),
        prepaidCard,
        user: {
          email:
            action.payload.email && emailValidation?.isValid
              ? action.payload.email
              : 'sprzedaz@goingapp.pl',
          empikCardNumber: null,
          empikPremiumJWT: null,
          facebookId: null,
          firstName: '-',
          lastName: '-',
          terms: true,
        },
      };

      if (getReservations().some((reservation) => reservation.spaceId === -1)) {
        return EMPTY$;
      }

      return from$(priceTypeApi.getPrice(body)).pipe(
        mergeMap$((data) => {
          const { reservations, status, message, tickets } = data;

          const newStateSelections: ISelectionPerPriceType[] = reservations.map(
            (reservation) => ({
              calculatedPrice: reservation.totalPrice,
              fee: reservation.fee,
              numberOfPlayers: reservation.numberOfPeople,
              priceType: {
                title: selected.prices.find(
                  (price) => price.type === reservation.priceType
                )?.title,
                titleEN: selected.prices.find(
                  (price) => price.type === reservation.priceType
                )?.titleEN,
                type: reservation.priceType,
                value: reservation.totalPrice - reservation.fee || 0,
              },
              value: reservation.totalPrice - reservation.fee,
            })
          );

          const ticketsCalculatedPrices = selectedTickets.map((ticket) => {
            const calculatedTicket = tickets.find(
              (foundedTicket) => foundedTicket.poolId === ticket.poolId
            );

            if (calculatedTicket) {
              const calculatedFee =
                calculatedTicket.fee / calculatedTicket.quantity;

              return {
                ...ticket,
                price:
                  (calculatedTicket.totalPrice - calculatedTicket.fee) /
                  calculatedTicket.quantity,
                amount: calculatedTicket.quantity,
                serviceFee: calculatedFee,
              };
            }

            return ticket;
          });

          if (status === 1) {
            return of$(calculatePrice.failure({ message }));
          }

          const discountNotification =
            (isDiscount || prepaidCard) &&
            (module === MODULES.BUY ||
              module === MODULES.EMBED_BUY ||
              module === MODULES.HAPPENING ||
              module === MODULES.EMBED_HAPPENING)
              ? createNotification(lang.discountAdded)
              : emptyAction;

          if (
            !!selectedTickets.length ||
            !!selected.selectionsPerPriceType.length
          ) {
            return of$(
              calculatePrice.success(newStateSelections),
              setTotalPrice(),
              updateSelectedTickets(ticketsCalculatedPrices),
              discountNotification
            );
          }

          if (!selected.upsell?.isSelected) {
            return of$(
              calculatePrice.success(newStateSelections),
              setTotalPrice(),
              updateSelectedTickets(ticketsCalculatedPrices)
            );
          }

          return of$(calculatePrice.success(newStateSelections));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => priceTypeApi.cancelCheckPrice())
          )
        ),

        catchError$((error: Error) => {
          return of$(calculatePrice.failure(error), setExtendedSlot(null));
        })
      );
    })
  );
};

export const notifyWhenPriceCalculationError: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(calculatePrice.failure)),
    withLatestFrom$(state$),
    mergeMap$(([action, _]) => {
      if (action.payload) {
        const message = action.payload.message;
        const error = new TransactionError(message);

        return [setError(error)];
      }

      return EMPTY$;
    })
  );
};

export const calculateTotalPrice: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setTotalPrice)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const totalPrice = calculatePriceSelector(state);

      if (totalPrice) {
        return of$(setTotalPriceInState(totalPrice));
      } else {
        return EMPTY$;
      }
    })
  );
};

export const calculateUpsell: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(upsellMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const availabilities = getAvailabilitiesSelector(state);
      const selected = getSelected(state);

      const selectedSlotData = availabilities.find(
        (availability) => availability.start === selected.slot
      );

      const selectedSlotIndex = availabilities.findIndex(
        (availability) => availability.start === selected.slot
      );

      const nextSlotData = availabilities[selectedSlotIndex];

      const selectedSpaceData = selectedSlotData?.spaces.find(
        (space) => space.spaceId === selected.space
      );

      const allCalculatedPrices = selected.selectionsPerPriceType.map(
        (selection) => selection.calculatedPrice
      );

      const currentPrice = allCalculatedPrices.reduce((prev, current) => {
        return current && prev ? prev + current : 0;
      }, 0);
      const upsellPercentage = selectedSpaceData?.upsell?.percentageValue
        ? selectedSpaceData?.upsell?.percentageValue
        : 0;

      const totalPrice = selected.totalPrice;
      const totalNumberOfPeople = selected.selectionsPerPriceType
        .map((selection) => selection.numberOfPlayers)
        .reduce((prev, current) => {
          if (prev && current) {
            return prev + current;
          } else if (current) {
            return current;
          }
        }, 0);
      const totalUpsell =
        (totalNumberOfPeople &&
          selectedSpaceData?.upsell?.value &&
          totalNumberOfPeople * selectedSpaceData?.upsell?.value) ||
        0;

      const currentPriceWithPercentUpsell =
        currentPrice && totalPrice * (upsellPercentage / 100);
      const price =
        selectedSpaceData?.upsell?.value ||
        selectedSpaceData?.upsell?.value === 0
          ? totalPrice - totalUpsell
          : currentPriceWithPercentUpsell || 0;

      const selectedSlotStartHourInSecs = moment
        .duration(selectedSlotData?.start)
        .asSeconds();
      const upsellEndHourInSecs =
        (selectedSlotData?.duration &&
          selectedSlotStartHourInSecs +
            selectedSlotData?.duration +
            nextSlotData.duration) ||
        0;

      const upsellEndHour = moment(selectedSlotData?.data)
        .startOf('day')
        .seconds(upsellEndHourInSecs)
        .format('HH:mm:ss');

      const selectedUpsell: ISelectedUpsell = {
        endTime: upsellEndHour,
        isSelected: selected.upsell?.isSelected
          ? selected.upsell?.isSelected
          : false,
        price,
      };

      return of$(setSelectedUpsell(selectedUpsell));
    })
  );
};

export const toggleUpsellState: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(toggleSelectedUpsell)),
    withLatestFrom$(state$),
    mergeMap$(([action, _]) => {
      const updatedUpsell = action.payload;

      return of$(setUpsellState(updatedUpsell));
    })
  );
};

export const sendOnsiteReserveForm: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(reserveSlotsOnsite)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const availabilitiesFromState = getAvailabilitiesSelector(state);
      const event = getEvent(state);
      const selected = getSelected(state);
      const products = getSelectedProducts(state);
      const productsMapped = products.map((product) => {
        return {
          id: product.id,
          quantity: product.amount,
        };
      });
      const dateTime = `${format(selected.dayFromSlot || 0, 'yyyy-MM-dd')}T${
        selected.slot
      }:00+00:00`;
      const happening = getData(state);
      const selectedSpace = happening?.spaces.find(
        (_space) => _space.id === selected.space
      );

      const language = state.locale.selectedLang;
      const title = selectedSpace?.description[0].title || '';
      const idempotencyKey = getIdempotencyKey(state);
      const embedData = getIframeParams(state);
      const baseUrl = embedData?.currentUrl || config.app.baseUrl;
      const paymentLink = !!embedData
        ? `${baseUrl}?${URI_TRANSACTION_KEY}={${URI_TRANSACTION_KEY}}`
        : replaceColonParamsWithBrackets(
            normalizeUrl(`${baseUrl}${routes.payment}`)
          );

      const body: ISlotReservationData = {
        agent: config.app.salesAgent,
        idempotencyKey,
        language,
        linkCancel: event
          ? `${config.app.baseUrl}/kup-bilety/${event.eventSlug}/`
          : `${config.app.baseUrl}/wydarzenia/`,
        linkFail: paymentLink,
        linkOk: paymentLink,
        paymentOperator: config.buy.onsiteSlotOperator,
        products: [],
        reservations: [],
        salesChannelId: config.app.salesChannelId,
        user: {
          authId: action.payload.authId,
        },
      };

      selected.selections.map((selection) => {
        if (
          (selection.numberOfPlayers && selection.numberOfPlayers > 0) ||
          !happening?.calculatePricePerPerson
        ) {
          const numberOfPeople =
            (happening?.calculatePricePerPerson
              ? selection.numberOfPlayers
              : selectedSpace?.maxNumberOfPeople) || 0;

          const rulePriceId =
            availabilitiesFromState
              .find((availability) => availability.start === selected.slot)
              ?.spaces.find((space) => space.spaceId === selected.space)
              ?.rulePriceId || 0;

          const discount =
            action.payload.discount && action.payload.discountCheckbox
              ? { code: action.payload.discount }
              : undefined;

          const spaceId = selectedSpace?.id || 0;
          const upsell = selected.upsell?.isSelected || false;
          const isPriceReduced = upsell || discount;

          const priceReduction: IPriceReduction | undefined = isPriceReduced
            ? {
                dateTime,
                discount,
                numberOfPeople,
                priceType: selection.priceType?.type || 'default',
                products: [],
                rulePriceId,
                spaceId,
                upsell,
              }
            : undefined;

          body.reservations.push({
            dateTime,
            duration: selectedSpace?.timeSlot || 0,
            happeningId: happening?.id || 0,
            numberOfPeople,
            paymentOperator: config.buy.onsiteSlotOperator,
            priceReduction,
            priceType: selection.priceType?.type || 'default',
            products: [],
            spaceId,
            title,
          });
        }
      });
      body.reservations[0].products = productsMapped;

      return of$(sendTransaction.request({ body, onDone: () => null }));
    })
  );
};

export const sendReserveForm: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(reserveSlots)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const availabilitiesFromState = getAvailabilitiesSelector(state);
      const selected = getSelected(state);
      const products = getSelectedProducts(state);
      const extendedSlot = getSelectedExtendedSlot(state);
      const spaceProducts = getSelectedSpaceProducts(state);
      const iframeParams = getIframeParams(state);
      const isLoading = getLoading(TRANSACTION)(state);

      if (isLoading) {
        return EMPTY$;
      }

      const productsMapped = products.map((product) => {
        const storageHouseId = iframeParams?.defaultStorageId
          ? product.stock?.find(
              (stock) => stock.storageHouseId === iframeParams?.defaultStorageId
            )?.storageHouseId
          : spaceProducts?.find(
              (spaceProduct) => spaceProduct.id === product.id
            )?.assignedStorageHouseId;

        return {
          id: product.id,
          quantity: product.amount,
          storageHouseId,
        };
      });
      const dateTime = `${format(selected.dayFromSlot || 0, 'yyyy-MM-dd')}T${
        selected.slot
      }:00+00:00`;
      const happening = getData(state);
      const selectedSpace = happening?.spaces.find(
        (_space) => _space.id === selected.space
      );

      const discount =
        action.payload.discount && action.payload.discountCheckbox
          ? { code: action.payload.discount }
          : undefined;
      const passCode =
        action.payload.pass && action.payload.passCheckbox
          ? action.payload.pass
          : undefined;
      const language = state.locale.selectedLang;
      const title = selectedSpace?.description[0].title || '';
      const idempotencyKey = getIdempotencyKey(state);
      const embedData = getIframeParams(state);
      const baseUrl = embedData?.currentUrl || config.app.baseUrl;
      const paymentLink = !!embedData
        ? `${baseUrl}?${URI_TRANSACTION_KEY}={${URI_TRANSACTION_KEY}}`
        : replaceColonParamsWithBrackets(
            normalizeUrl(`${baseUrl}${routes.payment}`)
          );
      const acceptedTerms: number[] = [];
      Object.keys(action.payload.customTerms).forEach((term) => {
        if (action.payload.customTerms[term]) {
          acceptedTerms.push(parseInt(term, 10));
        }
      });

      const paymentDetails = {
        type: action.payload.paymentMethod,
      };

      const body: ISlotReservationData = {
        agent: !!embedData ? 'going-integration' : config.app.salesAgent,
        idempotencyKey,
        language,
        linkFail: paymentLink,
        linkOk: paymentLink,
        paymentDetails,
        paymentOperator: config.buy.defaultOperator,
        prepaidCard: action.payload.prepaidCheckbox
          ? action.payload.prepaidCard
          : '',
        products: [],
        reservations: [],
        salesChannelId: config.app.salesChannelId,
        user: {
          acceptedTerms,
          email: action.payload.email,
          firstName: action.payload.firstname,
          lastName: action.payload.lastname,
          newsletter: action.payload.newsletter,
          phone: action.payload.phone,
          terms: action.payload.terms,
        },
      };

      selected.selections.map((selection) => {
        if (
          (selection.numberOfPlayers && selection.numberOfPlayers > 0) ||
          !happening?.calculatePricePerPerson
        ) {
          const numberOfPeople =
            (happening?.calculatePricePerPerson
              ? selection.numberOfPlayers
              : selectedSpace?.maxNumberOfPeople) || 0;

          const rulePriceId =
            availabilitiesFromState
              .find((availability) => availability.start === selected.slot)
              ?.spaces.find((space) => space.spaceId === selected.space)
              ?.rulePriceId || 0;
          const spaceId = selectedSpace?.id || 0;
          const upsell = selected.upsell?.isSelected || false;
          const isPriceReduced = upsell || discount;

          const priceReduction: IPriceReduction | undefined = isPriceReduced
            ? {
                dateTime,
                discount,
                numberOfPeople,
                priceType: selection.priceType?.type || 'default',
                products: [],
                rulePriceId,
                spaceId,
                upsell,
              }
            : undefined;

          body.reservations.push({
            dateTime,
            duration: selectedSpace?.timeSlot || 0,
            extendedDuration:
              extendedSlot && selectedSpace
                ? extendedSlot.duration * SECONDS_IN_MINUTE +
                  selectedSpace.timeSlot
                : undefined,
            happeningId: happening?.id || 0,
            numberOfPeople,
            passCode,
            paymentOperator: config.buy.defaultOperator,
            priceReduction,
            priceType: selection.priceType?.type || 'default',
            products: [],
            spaceId,
            title,
          });
        }
      });

      if (body.reservations.length > 0) {
        body.reservations[0].products = productsMapped;
      } else {
        window.airbrake.notify({
          error: new Error(
            'TODO: Trying to fill reservation data with products when no reservations are in payload.'
          ),
          params: { body } /* TODO: remove params when not needed anymore */,
        });
      }

      body.invoice = action.payload.invoiceCheckbox
        ? {
            address: action.payload.invoiceAddress,
            city: action.payload.invoiceCity,
            name: action.payload.invoiceName,
            nip: action.payload.invoiceNip,
            post: action.payload.invoicePost,
          }
        : null;

      return of$(sendTransaction.request({ body, onDone: () => null }));
    })
  );
};

export const openExtendedModalWhenSlotChanged: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(setSlot)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (getSelectedSlot(state) && getExtendedSlots(state).length) {
        return of$(setExtendedSlotModalVisible(true), setExtendedSlot(null));
      }

      return of$(setExtendedSlot(null));
    })
  );
};
