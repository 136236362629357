import api from './api';
import app from './app';
import aws from './aws';
import braze from './braze';
import buy from './buy';
import cloudFunctions from './cloudFunctions';
import deepLink from './deepLink';
import features from './features';
import filters from './filters';
import formIo from './formIo';
import i18nConfig from './i18n';
import { imagePriorityEnum, default as images } from './images';
import loading from './loading';
import mui from './mui';
import premium from './premium';
import redirections from './redirections';
import resizer from './resizer';
import seats from './seats';
import sitemap from './sitemap';
import social from './social';
import statutes from './statute';
import stickyHeader from './stickyHeader';
import storage from './storage';
import theme from './theme';

const config = {
  api,
  app,
  aws,
  buy,
  braze,
  cloudFunctions,
  deepLink,
  features,
  filters,
  formIo,
  i18nConfig,
  imagePriorityEnum,
  images,
  loading,
  mui,
  premium,
  redirections,
  resizer,
  seats,
  sitemap,
  social,
  stickyHeader,
  storage,
  theme,
  statutes,
};

export default config;
