import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IRouterRedirectReducer } from '@Model/router/types';
import { IRedirectData } from '@Services/$redirection-api/types';

import * as CONSTS from './../constants/actions';

export const manageRedirect = createStandardAction(
  CONSTS.MANAGE_REDIRECT
)<IRedirectData>();

export const getRedirection = createAsyncAction(
  CONSTS._GET_REDIRECT_REQUEST,
  CONSTS._GET_REDIRECT_SUCCESS,
  CONSTS._GET_REDIRECT_FAILURE
)<string, IRouterRedirectReducer, Error>();
