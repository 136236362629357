import React, { ChangeEvent, useEffect, useState } from 'react';

import { Button, Grid, TextField } from '@material-ui/core';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useGlobalStyles from '../../global.styles';
import Paper from '../Paper';
import { ITicketCodeProps } from './TicketCode.types';

const TicketCode = ({
  handlePrevStep,
  requestDataFromTicket,
  errors,
  clearErrors,
  resetForm,
}: ITicketCodeProps) => {
  const [code, setCode] = useState('');
  const globals = useGlobalStyles();
  const { entryList: lang } = useDictionary();

  const error = errors?.ticketCode;

  const handleChangeInput = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCode(e.target.value);
    clearErrors();
  };

  useEffect(() => {
    resetForm();
  }, []);

  return (
    <Paper title={lang.ticketCode.title}>
      <Grid container={true} spacing={4} justifyContent="center">
        <Grid item={true} xs={12}>
          <TextField
            value={code}
            onChange={(e) => handleChangeInput(e)}
            label={lang.ticketCode.placeholder}
            variant="outlined"
            fullWidth={true}
            helperText={!!error && error}
            error={!!error?.length}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Grid
            container={true}
            justifyContent="center"
            className={globals.column}
          >
            <Grid item={true}>
              <Button
                className={globals.button}
                type="button"
                variant="text"
                color="primary"
                onClick={handlePrevStep}
              >
                {lang.ticketCode.goBack}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                type="submit"
                className={globals.button}
                variant="contained"
                size="medium"
                color="primary"
                disabled={!code.length}
                onClick={() => requestDataFromTicket(code)}
              >
                {lang.ticketCode.goNext}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TicketCode;
