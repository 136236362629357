import React from 'react';

import { Formik } from 'formik';

import HappeningFormLayout from '@Compo/Happening/components/HappeningFormLayout';

import { makeValidationSchema } from './HappeningBuyFormOnsite.selector';
import { IHappeningOnsiteBuyFormProps } from './HappeningBuyFormOnsite.types';

const HappeningBuyFormOnsite = ({
  initialValues,
  reserve,
  langErrors,
  step,
}: IHappeningOnsiteBuyFormProps) => {
  const validationSchema = makeValidationSchema(langErrors);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={reserve}
      component={(props) => <HappeningFormLayout {...props} step={step} />}
    />
  );
};

export default HappeningBuyFormOnsite;
