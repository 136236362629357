import makeNumber from '@Misc/helpers/date/makeNumber';

const calculateDayForSearchFilters = (inputDate: Date) => {
  const day = inputDate.getDate();
  const month = inputDate.getMonth();
  const year = inputDate.getFullYear();

  return makeNumber(year, month, day);
};

export default calculateDayForSearchFilters;
