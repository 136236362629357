import { createSelector } from 'reselect';

import _Store from '@Store';

import { getSelectedLocale } from '@Model/locale/selectors';

import { getIframeParams } from './';
import { IInternalRouterEmbed } from './../types';

const isEnglishEmbed = createSelector<
  _Store.IState,
  string,
  IInternalRouterEmbed | null,
  boolean
>(
  [getSelectedLocale, getIframeParams],
  (language, embed) => !!embed && language === 'en'
);

export default isEnglishEmbed;
