import { getType } from 'typesafe-actions';

import { IAction, IAuthReducer } from '@Model/auth/types';

import { checkEmail, submitEmailCheck, toggleLogin } from './../actions';

const initialState: IAuthReducer = {
  email: '',
  showLogin: false,
  token: '',
};

const reducer = (
  state: IAuthReducer = initialState,
  action: IAction
): IAuthReducer => {
  switch (action.type) {
    case getType(toggleLogin):
      return {
        ...state,
        showLogin: !state.showLogin,
      };

    case getType(checkEmail):
      return {
        ...state,
        email: action.payload,
      };

    case getType(submitEmailCheck.success):
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
