import { ITransferTicketResponse } from './../types';

const ticketTransferErrorsDefinition: ITransferTicketResponse[] = [
  {
    errorCode: 'internal_error',
    errorMessage: 'Błąd wewnętrzny: ',
    status: false,
  },
  {
    errorCode: 'invalid_email',
    errorMessage:
      'Adres email odbiorcy biletu jest niepoprawny. Spróbuj ponownie.',
    status: false,
  },
  {
    errorCode: 'invalid_token',
    errorMessage:
      'Sesja przedawniona. Aby rozpocząć ponownie, kliknij ikonkę biletu na pasku nawigacji i wybierz WRÓĆ.',
    status: false,
  },
];

export default ticketTransferErrorsDefinition;
