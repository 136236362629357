import { getType } from 'typesafe-actions';

import getIdempotencyKey from '@Misc/helpers/happening/getIdempotencyKey';
import { generateIdempotencyKey } from '@Model/happening/actions';
import { IAction, IHappeningMetadata } from '@Model/happening/types';

const initialState: IHappeningMetadata = {
  idempotencyKey: getIdempotencyKey(),
};

const reducer = (
  state: IHappeningMetadata = initialState,
  action: IAction
): IHappeningMetadata => {
  switch (action.type) {
    case getType(generateIdempotencyKey):
      return {
        idempotencyKey: getIdempotencyKey(),
      };
    default:
      return state;
  }
};

export default reducer;
