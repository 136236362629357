import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import {
  receiveTicketMounted,
  receiveTicketSubmit,
} from '@Model/tickets/actions';
import {
  getReceivedCustomTerms,
  getReceivedTicketData,
} from '@Model/tickets/selectors';

import TicketRecipience from './TicketRecipience.component';
import {
  ITicketRecipienceFromDispatch,
  ITicketRecipienceFromState,
} from './TicketRecipience.types';

const mapStateToProps = (state: _Store.IState): ITicketRecipienceFromState => {
  return {
    customTerms: getReceivedCustomTerms(state),
    errors: translate(state)('errors'),
    lang: {
      ...translate(state)('buy', 'agreements'),
      ...translate(state)('ticketsManagement', 'ticketRecipience'),
    },
    ticketData: getReceivedTicketData(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketRecipienceFromDispatch => {
  return {
    mounted: () => dispatch(receiveTicketMounted()),
    submit: (form) => dispatch(receiveTicketSubmit(form)),
  };
};

export default connect<
  ITicketRecipienceFromState,
  ITicketRecipienceFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TicketRecipience);
