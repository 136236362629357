import {
  IFaqBrickOwnProps,
  IFaqExtra,
} from '@Compo/Bricks/components/FaqBrick/FaqBrick.types';
import {
  ISectionsComponentDataMutable,
  ISectionsComponentFaqElement,
} from '@Model/pages/types/sections';

export const faq = (
  data: ISectionsComponentDataMutable,
  extra?: IFaqExtra | null,
  background?: string
): IFaqBrickOwnProps => {
  const faqElements = data as ISectionsComponentFaqElement[];

  return {
    background,
    color: extra?.color,
    faq: faqElements,
  };
};
