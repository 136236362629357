import React from 'react';

import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import cn from 'classnames';
import moment from 'moment';

import styles from './../../Happening.module.scss';
import useStyles from './ExtendedSlot.styles';
import { IExtendedSlotProps } from './ExtendedSlot.types';

const DELETE_TEXT = 'Usuń';
const INFO_TEXT = 'Twoja gra została wydłużona! Potrwa od ';

const ExtendedSlot = ({
  selectedExtendedSlot,
  selectedSlot,
  resetExtendedSlot,
  selectedTheme,
}: IExtendedSlotProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  if (!selectedExtendedSlot || !selectedSlot) {
    return null;
  }

  return (
    <Grid
      container={true}
      justifyContent={'space-between'}
      alignItems={'center'}
      className={
        selectedTheme === 'default'
          ? cn(styles.box, styles.calendarBox, classes.root)
          : classes.container
      }
      spacing={2}
    >
      <Grid item={true}>
        <Typography variant={'body1'} align={matches ? 'center' : undefined}>
          {INFO_TEXT}{' '}
          {`${selectedSlot.start}-${moment(selectedSlot.start, 'HH:mm')
            .add(selectedExtendedSlot.duration, 'minutes')
            .add(selectedSlot.duration, 'seconds')
            .format('HH:mm')}.`}
        </Typography>
      </Grid>
      <Grid item={true} xs={matches ? 12 : undefined}>
        <Button
          variant={'outlined'}
          size={'large'}
          color={'primary'}
          onClick={resetExtendedSlot}
          fullWidth={matches}
        >
          {DELETE_TEXT}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ExtendedSlot;
