import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import Bar from './Bar.component';
import { IBarFromState, IBarOwnProps } from './Bar.types';

const mapStateToProps = (state: _Store.IState): IBarFromState => ({
  lang: translate(state)('event'),
});

export default connect<IBarFromState, void, IBarOwnProps, _Store.IState>(
  mapStateToProps
)(Bar);
