import React, { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import { useLocation } from 'react-router';

import BuyButton from '@Compo/Events/components/BuyButton';
import PremiumBadge from '@Compo/Events/components/PremiumBadge';
import Title from '@Compo/Events/components/TitleResponsive';
import ImageFixed from '@Compo/reusable/ImageFixed';
import Place from '@Compo/reusable/Place';
import Price from '@Compo/reusable/Price';
import prepareEventLink from '@Misc/helpers/prepareEventLink';

import styles from './EventTile.module.scss';
import { IEventProps } from './EventTile.types';

const EventTile: FC<IEventProps> = ({
  buttonLabel,
  calendarEvent,
  currency,
  eventSlug,
  friendlyDate,
  friendlyHour,
  id,
  isEmbed,
  imageUrl,
  isPremiumEvent,
  notForSale,
  place,
  placeId,
  placeSlug,
  position,
  price,
  priceDescription,
  redirectToUrl,
  rundateSlug,
  tags,
  title,
  partner,
  eventSelectItem,
}) => {
  const location = useLocation();

  const [urlAnalytics, setUrlAnalytics] = useState('');

  useEffect(() => {
    setUrlAnalytics(
      window.location.origin + '/' + location.pathname.split('/')[1]
    );
  }, []);

  const newAnalyticsData = {
    rundate_id: id,
    partner,
    price,
    tags,
    title,
    place,
    placeId,
    url: urlAnalytics,
  };

  const handleOnClick = () => {
    eventSelectItem(newAnalyticsData);
  };

  const link = prepareEventLink(
    eventSlug,
    rundateSlug,
    isEmbed,
    undefined,
    calendarEvent
  );

  return (
    <li className={cn(styles.eventTile, isPremiumEvent && styles.premiumEvent)}>
      <div className={styles.imageWrapper} onClick={handleOnClick}>
        <ImageFixed
          src={imageUrl}
          link={link}
          transformation={{
            width: 810,
            height: 700,
          }}
        />
      </div>
      <div className={styles.eventDetails}>
        <Title title={title} link={link} onClick={handleOnClick} />
        <h3 className={styles.value}>
          {friendlyDate}
          {friendlyHour && `, ${friendlyHour}`}
        </h3>
        {place && (price || priceDescription) && (
          <div className={styles.value}>
            <Place place={place} placeSlug={placeSlug} />
            <Price
              price={price}
              currency={currency}
              priceDescription={priceDescription}
            />
          </div>
        )}
      </div>
      <div className={styles.eventFooter}>
        {isPremiumEvent && <PremiumBadge />}
        <BuyButton
          label={buttonLabel}
          link={link}
          notForSale={notForSale}
          onClick={handleOnClick}
        />
      </div>
    </li>
  );
};

export default EventTile;
