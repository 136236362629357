import React from 'react';

import { Link, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import useStyles from '@Compo/Search/components/OnsiteInfo/OnsiteInfo.styles';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

const OnsiteInfo = () => {
  const theme = useTheme();
  const { isMobile } = useMuiBreakpoints();
  const muiStyles = useStyles(isMobile)(theme);

  return (
    <div className={muiStyles.root}>
      <div className={muiStyles.heading}>
        <Typography variant={'h4'}>
          Wystąpił problem?{' '}
          <span className={muiStyles.primaryColor}>Skontaktuj się z nami</span>
        </Typography>
      </div>
      <div>
        <Typography variant={'h5'}>
          <strong>Lista wydarzeń odwołanych i przełożonych:</strong>
        </Typography>
        <Typography variant={'subtitle1'}>
          <Link
            href={'https://web.goingapp.pl/storage/app/media/Ewidencja.pdf'}
          >
            Kliknij
          </Link>
        </Typography>
      </div>
      <div>
        <Typography variant={'h5'}>
          Problemy z zakupionymi biletami, zwrotami, problemy techniczne
          powiązane z systemem sprzedaży biletów w salonach Empik:
        </Typography>
        <Typography variant={'subtitle1'}>
          Marcin Lussa -{' '}
          <Link href={'mailto:marcin.lussa@empik.com'}>
            marcin.lussa@empik.com
          </Link>
          <br />
          <Link href={'tel:538-816-777'}>538-816-777</Link>
        </Typography>
      </div>

      <div>
        <Typography variant={'h5'}>
          Wszelkie pytania dotyczące zakupionych biletów online:
        </Typography>
        <Typography variant={'subtitle1'}>
          <Link href={'https://goingapp.pl/kontakt/formularz-kontaktowy'}>
            https://goingapp.pl/kontakt/formularz-kontaktowy
          </Link>
        </Typography>
      </div>

      <div>
        <Typography variant={'h5'}>
          Zamawianie blankietów biletowych do salonów:
        </Typography>
        <Typography variant={'subtitle1'}>
          Marcin Lussa -{' '}
          <Link href={'mailto:blankiety@empikbilety.pl'}>
            blankiety@empikbilety.pl
          </Link>
        </Typography>
      </div>
      <div>
        <Typography variant={'h4'}>SZCZEPIENIA / CERTYFIKAT UCC!</Typography>
        <Typography variant={'h5'}>
          Wydarzenia wymagające przedstawienia certyfikatu UCC oznaczone są na
          górze tagiem
          <br />
          <strong>"WYMAGANY UCC (Unijny Certyfikat Covid)!"</strong>
          <br />
          Unijny Ceryfikat Covid można uzyskać poprzez pełne zaszczepienie,
          posiadanie statusu ozdrowieńca, lub posiadanie negatywnego wyniku
          testu antygenowego lub PCR.
          <br />
          Przy sprzedaży biletu koniecznie poinformuj o tym kupującego.
          <br />
          Zasugeruj zapoznanie się z regulaminem wydarzenia przed zakupem.
          <br />
          Regulamin jest dostępny na stronie{' '}
          <Link href={'https:://empikbilety.pl'}>empikbilety.pl</Link>.
        </Typography>
      </div>
      <div>
        <Typography variant={'h4'}>ZWROTY!</Typography>
        <Typography variant={'h5'}>
          Jeśli na bilecie widnieje data wydarzenia, która nie jest zgodna z
          aktualną widniejącą w systemie oznacza to, że klient jest upoważniony
          do zwrotu.
          <br />W innym przypadku klientowi nie przysługuje zwrot środków.
        </Typography>
      </div>
    </div>
  );
};

export default OnsiteInfo;
