import React from 'react';

import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import useStyles from '@Compo/Bricks/components/ArtistSliderBrick/ArtistSliderBrick.styles';
import ArtistSlider from '@Compo/reusable/ArtistSlider';
import invertIfGrayScaleColor from '@Misc/helpers/invertIfGrayScaleColor';
import isColorDark from '@Misc/helpers/isColorDark';

import styles from './ArtistSliderBrick.module.scss';
import { IArtistSliderBrickProps } from './ArtistSliderBrick.types';

const ArtistSliderBrick = ({
  artists,
  bgColor,
  color,
  id,
  isDarkMode,
  isLoading,
  sectionDescription,
  sectionTitle,
}: IArtistSliderBrickProps) => {
  const theme = useTheme();
  const isBgDark =
    isColorDark(bgColor) ||
    isColorDark(
      isDarkMode && bgColor ? invertIfGrayScaleColor(bgColor) : bgColor
    );
  const muiStyles = useStyles(isBgDark, color)(theme);
  const showHeaderSection = sectionDescription || sectionTitle;

  return (
    <div className={cn(styles.artists, muiStyles.fontColor)} id={id}>
      {showHeaderSection && (
        <div className={muiStyles.headerSection}>
          {sectionTitle && (
            <Typography
              className={muiStyles.heading}
              variant={'h4'}
              children={sectionTitle}
            />
          )}
          {sectionDescription && (
            <Typography variant={'body1'} children={sectionDescription} />
          )}
        </div>
      )}
      <ArtistSlider
        artistSection={artists}
        id={id}
        componentTitle={sectionTitle}
      />
    </div>
  );
};

export default ArtistSliderBrick;
