import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '3.75rem',
    },
    disabled: {
      cursor: 'initial',
      opacity: 0.6,
      pointerEvents: 'none',
    },
    header: {
      marginBottom: '2rem',

      '&:before': {
        content: "attr(data-number) '. '",
      },
    },
  })
);

export default useStyles;
