import React, { useContext, useEffect, useRef, useState } from 'react';

import { useTheme } from '@material-ui/core';
import cn from 'classnames';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import classes from '@Compo/errors/ErrorModal/ErrorModal.classes';
import useStyles from '@Compo/errors/ErrorModal/styles/ErrorModal.styles';
import Portal from '@Compo/modals/Portal';
import config from '@Config';
import cancelClick from '@Misc/helpers/cancelClick';
import { useLayoutEffect } from '@Misc/hooks/useIsomorphicLayoutEffect';
import iframeProvider from '@Services/$iframe-provider';
import { getErrorFromPath } from '@Services/$transactions-api/helpers/getErrorFromPath';

import styles from './ErrorModal.module.scss';
import { IErrorModalProps } from './ErrorModal.types';
import WrongBasketItem from './components/WrongBasketItem';

const appClass = config.theme.isEmpik ? styles.empik : styles.going;

const CLOSE_TEXT = 'Zamknij';
const CONFIRM_TEXT = 'OK';
const BASKET_CONTINUE_TEXT = 'Popraw formularz zgodnie z informacją powyżej.';
const BASKET_CONTINUE_TEXT_MORE =
  'Jeśli to nie pomoże, możesz też usunąć bilety powodujące problemy i przejść do płatności, klikając OK.';
const BASKET_CANCEL = 'ANULUJ';

const ErrorModal = ({
  close,
  errorLink,
  errorMessageLinkLabel,
  errors,
  errorTitle,
  isDesktop,
  isEmbed,
  message,
  show,
  client,
  path,
  informAboutErrorModal,
  userData,
  buyAndPayOnline,
}: IErrorModalProps) => {
  const prevMessage = useRef({ message }).current;
  const [embedDialogPosition, setEmbedDialogPosition] = useState(0);

  const state = useContext(clearContext);

  const basketError = getErrorFromPath(path, state?.basketItems);

  useEffect(() => {
    if (show && message !== prevMessage.message) {
      informAboutErrorModal();
    }
  }, []);

  const handleContinueTransaction = () => {
    if (basketError && basketError.length && userData) {
      basketError.forEach((error) => {
        if (error) {
          state?.removeFromBag(error.id);
        }
      });

      const newList = state?.basketItems.filter((basketItem) => {
        return !basketError.some((errorItem) => {
          return errorItem.id === basketItem.id;
        });
      });
      close();
      if (newList?.length) {
        buyAndPayOnline({
          basketItems: newList || [],
          data: { ...userData, customTerms: {} },
          onDone: state && state.clearBasket ? state.clearBasket : () => null,
        });
      } else {
        buyAndPayOnline({
          basketItems: [],
          data: userData,
          onDone: state && state.clearBasket ? state.clearBasket : () => null,
        });
      }
    }
  };

  const theme = useTheme();
  const muiStyles = useStyles(embedDialogPosition, isEmbed, isDesktop)(theme);

  useLayoutEffect(() => {
    if (isEmbed && show) {
      iframeProvider.getIframeRelativeScrollPosition(setEmbedDialogPosition);
    }

    return () => window.removeEventListener('message', () => {});
  }, [show]);

  return (
    <Portal>
      {show && (
        <div
          className={cn(
            styles.errorModal,
            {
              [client]: client,
            },
            isEmbed && styles.embedError
          )}
          onClick={close}
          role="button"
        >
          {!basketError ? (
            <>
              {isDesktop && (
                <button className={styles.close} onClick={close}>
                  {CLOSE_TEXT}
                </button>
              )}
              <div
                className={cn(
                  styles.panel,
                  appClass,
                  styles.witIcon,
                  muiStyles.root,
                  classes.errorModalPanel
                )}
                onClick={cancelClick}
              >
                <h2 className={styles.title}>
                  {errorTitle ? errorTitle : errors.sorry}
                </h2>
                <p className={styles.message}>
                  {message || ''}

                  {errorMessageLinkLabel && (
                    <a href={errorLink || ''}>{errorMessageLinkLabel}</a>
                  )}
                </p>
                <div className={styles.buttonWrapper}>
                  <button className={styles.closeButton} onClick={close}>
                    {CONFIRM_TEXT}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={cn(styles.panel, appClass, styles.panelBasket)}
                onClick={cancelClick}
              >
                {/*<h2 className={styles.titleBasket}>*/}
                {/*  <BasketButton*/}
                {/*    isError={true}*/}
                {/*    iconClassName={styles.basketIcon}*/}
                {/*  />*/}
                {/*  {BASKET_TITLE_TEXT}*/}
                {/*</h2>*/}
                {basketError.map((error) => {
                  return (
                    <WrongBasketItem key={error.id} wrongBasketItem={error} />
                  );
                })}
                <h2 className={styles.message}>{message}</h2>
                <h2 className={styles.confirm}>
                  {BASKET_CONTINUE_TEXT}
                  <br />
                  {BASKET_CONTINUE_TEXT_MORE}
                </h2>
                <div className={styles.buttonsWrapper}>
                  <div className={styles.buttonWrapper}>
                    <button
                      className={styles.closeButton}
                      onClick={handleContinueTransaction}
                    >
                      {CONFIRM_TEXT}
                    </button>
                  </div>
                  <div className={styles.buttonWrapper}>
                    <button
                      className={cn(styles.closeButton, styles.cancelButton)}
                      onClick={close}
                    >
                      {BASKET_CANCEL}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Portal>
  );
};

export default ErrorModal;
