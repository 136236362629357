import { createSelector } from 'reselect';

import { IAvailabilities } from '../types';
import getAvailabilitiesSelector from './getAvailabilitiesSelector';
import getSelected from './getSelected';

const getSelectedSlot = createSelector(
  [getAvailabilitiesSelector, getSelected],
  (availabilities, selected): IAvailabilities | undefined =>
    availabilities.find((slot) => slot.start === selected.slot)
);

export default getSelectedSlot;
