export enum BricksEnum {
  artist_slider = 'artist_slider',
  banner = 'banner',
  big_and_vertical_list = 'big_and_vertical_list',
  big_hotspot = 'big_hotspot',
  carousel = 'carousel',
  cta_button = 'cta_button',
  custom_code = 'custom_code',
  description = 'description',
  embed = 'embed',
  faq = 'faq',
  formio = 'formio',
  gallery = 'gallery',
  horizontal_list = 'horizontal_list',
  hotdeals = 'hotdeals',
  multiline_hotspots = 'multiline_hotspots',
  read_more = 'read_more',
  search_bar = 'search_bar',
  section_lead = 'section_lead',
  slidable_hotspots = 'slidable_hotspots',
  timeline = 'timeline',
  text_block = 'text_block',
  text_block_with_image = 'text_block_with_image',
  testimonials = 'testimonials',
  tour_list = 'tour_list',
  age_gate = 'age_gate',
}
