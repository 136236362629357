import { connect } from 'react-redux';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';

import Timeline from './Timeline.component';
import { ITimelineFromState } from './Timeline.types';

const mapStateToProps = (state: _Store.IState): ITimelineFromState => ({
  isDesktop: isDesktop(state),
});

export default connect<ITimelineFromState, void, {}, _Store.IState>(
  mapStateToProps
)(Timeline);
