import { createSelector } from 'reselect';

import _Store from '@Store';

import { IProductSelect } from './../types';
import getSelectedProducts from './getSelectedProducts';

const isAnySelectedProductHasPassType = createSelector<
  _Store.IState,
  IProductSelect[],
  boolean
>([getSelectedProducts], (selectedProducts) =>
  selectedProducts.some((product) => product.type === 'pass')
);

export default isAnySelectedProductHasPassType;
