import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import { IGetEventsSuccessPayload } from './../types';

// Public actions
export const switchEventListView = createStandardAction(
  CONSTS.SWITCH_EVENT_LIST_VIEW
)();

// Private actions
export const getEvents = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IGetEventsSuccessPayload, Error>();

export const resetState = createStandardAction(CONSTS._RESET_STATE)();
