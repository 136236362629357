import React, { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import moment from 'moment';

import Calendar from '@Compo/reusable/Calendar/Calendar.component';
import SingleDatePicker from '@Compo/reusable/DatePicker/components/SingleDatePicker';

import TicketDataForm from './../TicketDataForm';
import WithEventDetailBox from './../WithEventDetailBox';
import styles from './ChangeDate.module.scss';
import { IMyTicketProps } from './ChangeDate.types';
import TimeSlots from './components/TimeSlots';

const ChangeDate: FC<IMyTicketProps> = ({
  changeDateMounted,
  setTicketDate,
  date,
  lang,
  ticketData,
}) => {
  useEffect(() => {
    changeDateMounted();
  }, []);

  const [manageDate, setManageDate] = useState(false);

  const catchManageDate = () => {
    setManageDate(!manageDate);
  };

  const catchSetTicketDate = (newDate: Date) => {
    setManageDate(false);
    setTicketDate(newDate);
  };

  if (!ticketData) {
    return <TicketDataForm />;
  }

  const formattedDate = moment(date).format('DD-MM-YYYY');

  return (
    <div className={styles.wrapper}>
      <WithEventDetailBox>
        <div>
          <h3 className={styles.header} data-number={1}>
            {lang.selectDate}
          </h3>

          <div className={styles.pool}>
            <div
              className={cn(styles.dateInput, !manageDate && styles.close)}
              onClick={catchManageDate}
            >
              <span className={styles.text}>{formattedDate}</span>
              <span className={styles.icon} />
            </div>
            {manageDate && (
              <>
                <div className={styles.defaultDatepicker}>
                  <SingleDatePicker
                    handleSetDate={catchSetTicketDate}
                    mobile={true}
                    defaultDate={date}
                  />
                </div>
                <div className={styles.clientDatepicker}>
                  <Calendar
                    selectedDate={date}
                    isDateSelectionAvailable={true}
                    setDay={catchSetTicketDate}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <h3 className={styles.header} data-number={2}>
            {lang.selectTime}
          </h3>
          <div>
            <div className={styles.pool}>
              <TimeSlots />
            </div>
          </div>
        </div>
        <div className={styles.submitButton}>Przenieś rezerwację</div>
      </WithEventDetailBox>
    </div>
  );
};

export default ChangeDate;
