import { Selector } from 'reselect';

import _Store from '@Store';

import { IProductsReducer } from '../types';

const getAll: Selector<_Store.IState, IProductsReducer> = (state) =>
  state.products;

export default getAll;
