import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  IHappeningFormLayoutFromDispatch,
  IHappeningFormLayoutFromState,
} from '@Compo/Happening/components/HappeningFormLayout/HappeningFormLayout.types';
import { isError } from '@Model/errors/selectors';
import {
  setCalculatedPrices,
  setDiscountState,
} from '@Model/happening/actions';
import {
  getHappeningTerms,
  getSelected,
  isAllSelected,
} from '@Model/happening/selectors';
import { translate } from '@Model/locale/selectors';
import { getLocation } from '@Model/router/selectors';
import { TRANSACTION } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { getTheme } from '@Model/theme/selectors';

import HappeningFormLayout from './HappeningFormLayout.component';

const mapStateToProps = (
  state: _Store.IState
): IHappeningFormLayoutFromState => ({
  autoFill: !!getLocation(state).query?.data,
  canReserve: isAllSelected(state),
  customTerms: getHappeningTerms(state),
  isError: isError(state),
  lang: translate(state)('buy', 'summary'),
  langAgreements: translate(state)('buy', 'agreements'),
  langDiscount: translate(state)('buy', 'clientData'),
  langHappening: translate(state)('buy', 'happening'),
  langFormLayout: translate(state)('buy', 'formLayout'),
  langErrors: translate(state)('errors'),
  selectedTheme: getTheme(state),
  upsellFromState: getSelected(state).upsell?.isSelected,
  isTransactionLoading: getLoading(TRANSACTION)(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IHappeningFormLayoutFromDispatch => ({
  calculatePrice: (value) => dispatch(setCalculatedPrices(value)),
  setDiscountState: (discountCode) => dispatch(setDiscountState(discountCode)),
});

export default connect<
  IHappeningFormLayoutFromState,
  IHappeningFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(HappeningFormLayout);
