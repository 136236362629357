import React from 'react';

import scrollToTop from '@Misc/helpers/scrollToTop';
import { useLayoutEffect } from '@Misc/hooks/useIsomorphicLayoutEffect';
import * as MODULES from '@Routes/modules';

import { IPageProps } from './../InternalRouter.types';

const Page = (props: IPageProps): null => {
  const {
    setBrowserLocale,
    module,
    match,
    language,
    location,
    history,
    locationChange,
    isFirstRendering,
  } = props;
  useLayoutEffect(() => {
    if (
      setBrowserLocale &&
      module !== MODULES.FORMIO &&
      module !== MODULES.CONTACT // TODO: try to maybe look for a nicer way of fixing this
    ) {
      setBrowserLocale(language);
    }
  }, []);

  useLayoutEffect(() => {
    if (module) {
      const payload = {
        module,
        params: Object.keys(match.params).length > 0 ? match.params : null,
      };

      if (!isFirstRendering) {
        locationChange(payload);
      }

      scrollToTop(location, history);
    }
  });

  return null;
};

export default Page;
