import en from '@/lang/en';
import pl from '@/lang/pl';
import { selector } from 'recoil';

import { Languages } from '@Constants/Languages';
import { RecoilKeys } from '@Constants/RecoilKeys';
import { locale } from '@Recoil/lang/atoms/locale';
import { ILangMap } from '@Types/Locale';

export const translate = selector<ILangMap>({
  get: ({ get }) => {
    const language = get(locale);

    switch (language) {
      case Languages.Polski:
        return pl;
      case Languages.English:
        return en;
      default:
        return pl;
    }
  },
  key: RecoilKeys.translate,
});
