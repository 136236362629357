import * as CONSTS from '@Model/app/constants/constants';

import { IDeviceSizes, ISizes } from './types';

class DeviceDetector {
  private readonly sizes: IDeviceSizes = {
    mobile: CONSTS.MOBILE_MAX_WIDTH,
    tablet: CONSTS.DESKTOP_MIN_WIDTH,
  };

  private readonly sortedKeysByResolution = ['tablet', 'mobile'];

  public getDevice({ width }: ISizes) {
    let device = 'desktop';

    this.sortedKeysByResolution.forEach((_device: string) => {
      if (width <= this.sizes[_device]) {
        device = _device;
      }
    });

    return device;
  }

  public determineMobileUserAgent() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return CONSTS.WINDOWS;
    }

    if (/android/i.test(userAgent)) {
      return CONSTS.ANDROID;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return CONSTS.IOS;
    }

    return CONSTS.UNDEFINED;
  }
}

export default new DeviceDetector();
