import { getLocation } from 'connected-react-router';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { appIsReady, setIsWebview } from '@Model/app/actions';
import { getSelectedLocale } from '@Model/locale/selectors';
import { getTheme } from '@Model/theme/selectors';

import App from './App.component';
import { IAppFromDispatch, IAppFromState } from './App.types';

const mapStateToProps = (state: _Store.IState): IAppFromState => ({
  isDark: getLocation(state).query.isDark === 'true',
  theme: getTheme(state),
  locale: getSelectedLocale(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IAppFromDispatch => ({
  appIsReady: () => dispatch(appIsReady()),
  setIsWebview: (isWebview) => dispatch(setIsWebview(isWebview)),
});

export default connect<IAppFromState, IAppFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(App);
