import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { ICheckEmailResponse } from '@Services/$sendgrid-api/types';

import * as CONSTS from './../constants';

export const checkEmailValidity = createStandardAction(
  CONSTS.CHECK_EMAIL_VALIDITY
)<string>();

export const checkMailValidation = createAsyncAction(
  CONSTS._GET_MAIL_VALIDATION_REQUEST,
  CONSTS._GET_MAIL_VALIDATION_SUCCESS,
  CONSTS._GET_MAIL_VALIDATION_FAILURE
)<string, ICheckEmailResponse, Error>();
