/* tslint:disable:no-console */

export default class Logger {
  public static debug(sourceName: string, messages: any) {
    if (
      process.env.NODE_ENV === 'development' ||
      typeof window === 'undefined'
    ) {
      console.debug(
        `${Logger.formatTitle(Logger.DEBUG, sourceName)}: ${messages}`
      );
    } else {
      // TODO: add reporting service in production
    }
  }

  public static error(sourceName: string, error: any) {
    if (
      process.env.NODE_ENV === 'development' ||
      typeof window === 'undefined'
    ) {
      console.error(
        `${Logger.formatTitle(Logger.ERROR, sourceName)}: ${error}`
      );
    } else {
      window.airbrake.notify({
        error,
        params: { sourceName },
      });
    }
  }

  public static info(sourceName: string, messages: any) {
    if (
      process.env.NODE_ENV === 'development' ||
      typeof window === 'undefined'
    ) {
      console.info(
        `${Logger.formatTitle(Logger.INFO, sourceName)}: ${messages}`
      );
    } else {
      // TODO: add reporting service in production
    }
  }

  protected static DEBUG = '[Debug]';
  protected static INFO = '[Info]';
  protected static ERROR = '[Error]';

  protected static formatTitle = (type: string, sourceName: string) => {
    return `%c ${type}[${sourceName}]`;
  };
}
