import config from '@Config';

export const insuranceSlugs = config.theme.isEmpik
  ? [
      'ubezpieczenie-i-eb',
      'ubezpieczenie-ii-eb',
      'ubezpieczenie-iii-eb',
      'ubezpieczenie-iv-eb',
      'ubezpieczenie-v-eb',
      'ubezpieczenie-vi-eb',
      'ubezpieczenie-vii-eb',
      'ubezpieczenie-viii-eb',
      'ubezpieczenie-ix-eb',
    ]
  : [
      'ubezpieczenie-i',
      'ubezpieczenie-ii',
      'ubezpieczenie-iii',
      'ubezpieczenie-iv',
      'ubezpieczenie-v',
      'ubezpieczenie-vi',
      'ubezpieczenie-vii',
      'ubezpieczenie-viii',
      'ubezpieczenie-ix',
    ];
