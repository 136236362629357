import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      height: '1.25rem',
    },
    link: {
      display: 'flex',
      marginRight: theme.spacing(4),
      padding: '0.25rem 0',
    },
    root: {
      backgroundColor: theme.palette.common.black,
    },
    textLink: {
      color: theme.palette.common.white,
      fontSize: '0.75rem',
      marginLeft: theme.spacing(4),
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
      minHeight: 'unset',
      width: 'inherit',
    },
  })
);

export default useStyles;
