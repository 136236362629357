import { ActionType, StateType } from 'typesafe-actions';

import * as actions from './../actions';
import reducer from './../reducer';

export interface IPremiumUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface IPremiumUserCard {
  email: string;
  cardNumber: string;
}

export interface IPremiumCheckCardStatusValues {
  cardNumber: string;
  email: string;
}

export interface IPremiumReducer {
  token: null | string;
  user: null | IPremiumUser;
  userCardStatus: UserCardStatusEnum;
  userCardData: null | IPremiumUserCard;
}

export enum UserCardStatusEnum {
  ERROR = 3,
  INACTIVE = 2,
  NODATA = 0,
  NOMATCH = 4,
  OK = 1,
}

export type IPremiumToken = string;

export type IPremiumSuccessPayload = IPremiumUser;

export type IState = StateType<typeof reducer>;
export type IAction = ActionType<typeof actions>;
