import { getType } from 'typesafe-actions';

import { getAvailabilities } from './../../actions';
import { IAction, IAvailabilitiesReducer } from './../../types';

const initialState: IAvailabilitiesReducer = {};

const reducer = (
  state: IAvailabilitiesReducer = initialState,
  action: IAction
): IAvailabilitiesReducer => {
  switch (action.type) {
    case getType(getAvailabilities.success):
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
