import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Bar.module.scss';
import useStyles from './Bar.styles';
import { IBarProps } from './Bar.types';

const Bar = ({ lang, price, show, url, currency }: IBarProps) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <div className={cn(styles.bar, muiStyles.root, show && styles.show)}>
      <Link className={styles.button} to={url}>
        {lang.buyTicket}
      </Link>
    </div>
  );
};

export default Bar;
