import { Theme, createTheme } from '@material-ui/core/styles';

import { IAvailableThemes } from '@Model/theme/types';

import theme from './theme';
import hangar from './theme/hangar';
import saltos from './theme/saltos';

export const goingTheme = {
  border: {
    radius: '1rem',
    style: 'none',
    width: '0px',
  },
  palette: {
    background: {
      default: '#f4f7f8',
    },
    gradient: {
      first: '#FE839F',
      second: '#EFE80D',
    },
    info: {
      contrastText: '#ffffff',
      main: '#60748B',
    },
    other: {
      stroke: 'rgba(0, 0, 0, 0.23)',
      gray: 'rgba(0,0,0,0.2)',
    },
    primary: {
      contrastText: '#111111',
      darkBg: '#ede40c',
      light: '#ffff57',
      main: '#eff200',
    },
    secondary: {
      contrastText: '#ffffff',
      darkBg: '#34495e',
      light: '#f3f3f3',
      main: '#849297',
    },
  },
  typography: {
    fontFamily: [
      '"Noir Pro"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    body1: {
      fontWeight: 500,
    },
    body2: {
      fontSize: '1rem',
    },

    fontWeightBold: 500,

    h2: {
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 700,
    },
  },
};

export const goingDarkTheme = {
  border: {
    radius: '1rem',
    style: 'none',
    width: '0px',
  },
  palette: {
    background: {
      default: '#111111',
      paper: '#282828',
    },
    gradient: {
      first: '#FE839F',
      second: '#EFE80D',
    },
    info: {
      contrastText: '#ffffff',
      main: '#60748B',
    },
    other: {
      stroke: 'rgba(255, 255, 255, 0.23)',
      gray: 'rgba(0,0,0,0.2)',
    },
    primary: {
      contrastText: '#111111',
      darkBg: '#ede40c',
      light: '#ffff57',
      main: '#eff200',
    },
    secondary: {
      contrastText: '#111111',
      darkBg: '#34495e',
      light: '#f3f3f3',
      main: '#849297',
    },
    text: {
      disabled: '#666666',
      hint: '#AAAAAA',
      primary: '#FFFFFF',
      secondary: '#849297',
    },
  },
  typography: {
    fontFamily: [
      '"Noir Pro"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    body1: {
      fontWeight: 500,
    },
    body2: {
      fontSize: '1.125rem',
    },

    fontWeightBold: 500,

    h2: {
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 700,
    },
  },
};

export const empikTheme = {
  border: {
    radius: '0',
    style: 'none',
    width: '0px',
  },
  palette: {
    gradient: {
      first: '#FE839F',
      second: '#EFE80D',
    },
    primary: {
      contrastText: '#111111',
      darkBg: '#692acb',
      light: '#d58aff',
      main: '#9f5aff',
    },
    secondary: {
      contrastText: '#ffffff',
      darkBg: '#c30000',
      light: '#ff6052',
      main: '#ff1627',
    },
    other: {
      gray: 'rgba(0,0,0,0.2)',
    },
  },
  typography: {
    fontFamily: [
      '"empik Pro"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    body1: {
      fontWeight: 500,
    },
    body2: {
      fontSize: '1.5rem',
    },

    fontWeightBold: 700,

    h2: {
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 700,
    },
  },
};

const themeSelector = theme.isEmpik ? empikTheme : goingTheme;

const muiTheme = (selectedTheme?: IAvailableThemes): Theme => {
  const currentTheme =
    selectedTheme && selectedTheme !== 'default'
      ? themes[selectedTheme]
      : themeSelector;

  return createTheme({
    ...currentTheme,
    spacing: [0, 4, 8, 16, 24, 32, 48, 96, 120, 160, 200],
  });
};

const isMobile = muiTheme().breakpoints.down('sm');
const isSmallMobile = muiTheme().breakpoints.down('xs');
const isTablet = muiTheme().breakpoints.down('lg');
const isNotMobile = muiTheme().breakpoints.up('sm');

const themes = {
  Hangar646: hangar(isMobile, isSmallMobile, isTablet),
  Saltos: saltos(),
};

export enum spacing {
  noSpacing,
  tiny,
  small,
  regular,
  medium,
  large,
  huge,
  humongous,
  enormous,
  colossal,
}

const appBar = {
  MuiAppBar: {
    colorDefault: {
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      boxShadow: 'none',
    },
    root: {
      height: '6.5rem',
      [isMobile]: {
        height: '4rem',
      },
    },
  },
};

const breadcrumbs = {
  MuiBreadcrumbs: {
    root: {
      fontSize: muiTheme().typography.caption.fontSize,
      lineHeight: 1,
      marginBottom: muiTheme().spacing(4),
      marginTop: muiTheme().spacing(4),
    },
    separator: {
      color: muiTheme().palette.primary.main,
      fontWeight: muiTheme().typography.fontWeightBold,
    },
  },
};

const button = {
  MuiButton: {
    contained: {
      boxShadow: 'none',
    },
    containedSizeLarge: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1,
      paddingBottom: muiTheme().spacing(3),
      paddingLeft: muiTheme().spacing(4),
      paddingRight: muiTheme().spacing(4),
      paddingTop: muiTheme().spacing(3),
    },
    outlinedSizeLarge: {
      borderColor: theme.isEmpik ? '#EBDDFF' : muiTheme().palette.text.primary,
      color: muiTheme().palette.text.primary,
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1,
      paddingBottom: muiTheme().spacing(3),
      paddingLeft: muiTheme().spacing(4),
      paddingRight: muiTheme().spacing(4),
      paddingTop: muiTheme().spacing(3),
    },
    // tslint:disable-next-line:object-literal-sort-keys
    outlinedPrimary: {
      '&.MuiButton-outlinedSizeLarge': {
        borderColor: theme.isEmpik
          ? '#EBDDFF'
          : muiTheme().palette.primary.main,
      },
      color: muiTheme().palette.text.primary,
    },
    root: {
      borderRadius: '0.5rem',
    },
    textPrimary: {
      color: muiTheme().palette.primary.contrastText,
    },
  },
};

const toolbar = {
  MuiToolbar: {
    gutters: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
  },
};

const typography = {
  MuiTypography: {
    body2: {
      [isMobile]: {
        fontSize: '0.875rem',
      },
    },
  },
};

const datePicker = {
  MuiPickersBasePicker: {
    pickerView: {
      maxWidth: 'unset',
    },
  },
  MuiPickersCalendar: {
    week: {
      '& > div': {
        width: '100%',
      },
      display: 'flex',
      gap: '0.25rem',
      height: '3.25rem',
      width: '99%',
    },
  },
  MuiPickersCalendarHeader: {
    dayLabel: {
      color: muiTheme().palette.text.primary,
      textAlign: 'left',
      width: '100%',
    },
    iconButton: {
      padding: 0,
    },
    switchHeader: {
      marginBottom: '1rem',
    },
  },
  MuiPickersDay: {
    day: {
      '& .MuiIconButton-label': {
        '& .MuiTypography-body2': {
          fontSize: '1.5rem',
        },
      },
      border: `1px solid ${
        theme.isEmpik ? '#EBDDFF' : muiTheme().palette.primary.main
      }`,
      borderRadius: '0.25rem',
      height: '100%',
      width: '100%',
    },
    daySelected: {
      '& .MuiIconButton-label': {
        color: theme.isEmpik
          ? muiTheme().palette.secondary.contrastText
          : muiTheme().palette.primary.contrastText,
      },
      '&:hover': {
        backgroundColor: theme.isEmpik
          ? muiTheme().palette.secondary.main
          : muiTheme().palette.primary.main,
      },
      backgroundColor: theme.isEmpik
        ? muiTheme().palette.secondary.main
        : muiTheme().palette.primary.main,
      borderColor: theme.isEmpik
        ? muiTheme().palette.secondary.main
        : muiTheme().palette.primary.main,
    },
  },
  MuiPickersSlideTransition: {
    transitionContainer: {
      '& .MuiTypography-alignCenter': {
        display: 'block',
      },
      '& > *': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.25rem',
        position: 'relative',
      },
    },
  },
};

export const overrides = {
  ...appBar,
  ...breadcrumbs,
  ...button,
  ...toolbar,
  ...typography,
  ...datePicker,
};

const mui = (selectedTheme?: IAvailableThemes) => {
  return {
    fontIconsUrl: 'https://fonts.googleapis.com/icon?family=Material+Icons',
    iconMenuId: 'icon-menu',
    mobileMenuId: 'menu',
    spacing,
    theme: {
      ...muiTheme(selectedTheme),
      overrides:
        selectedTheme === 'default'
          ? overrides
          : muiTheme(selectedTheme).overrides,
    },
  };
};

export default mui;
