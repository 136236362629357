import React from 'react';

import styles from './../../DatePicker.module.scss';

const daysNames = ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'];

const Days = () => {
  return (
    <div className={styles.days}>
      {daysNames.map((day) => (
        <div key={day} className={styles.day}>
          {day}
        </div>
      ))}
    </div>
  );
};

export default Days;
