export const ABOUT = '<ABOUT>';
export const ACTIVITIES = '<ACTIVITIES>';
export const ACTIVITY = '<ACTIVITY>';
export const ARTIST = '<ARTIST>';
export const BASKET = '<BASKET>';
export const BASKET_BUY = '<BASKET_BUY>';
export const BETA_EVENT = '<BETA_EVET>';
export const BUY = '<BUY>';
export const CHANGE_DATE = '<CHANGE_DATE>';
export const CONTACT = '<CONTACT>';
export const DISCOUNT = '<DISCOUNT>';
export const EMBED_BUY = '<EMBED_BUY>';
export const EMBED_BUY_PRODUCTS = '<EMBED_BUY_PRODUCTS>';
export const EMBED_HAPPENING = '<EMBED_HAPPENING>';
export const EMPTY = '<EMPTY>';
export const ERROR_404 = '<ERROR_404>';
export const ERROR_BASIC = '<ERROR_BASIC>';
export const EVENT = '<EVENT>';
export const EVENTS = '<EVENTS>';
export const FORMIO = '<FORMIO>';
export const GIFT_CARD = '<GIFT_CARD>';
export const HAPPENING = '<HAPPENING>';
export const HAPPENING_REGISTER = '<HAPPENING_REGISTER>';
export const HOME = '<HOME>';
export const INSPIRATIONS = '<INSPIRATIONS>';
export const LANDING_PAGE = '<LANDING_PAGE>';
export const MY_TICKET = '<MY_TICKET>';
export const MY_TICKETS = '<MY_TICKETS>';
export const PASSWORD_RESET = '<PASSWORD_RESET>';
export const PAYMENT = '<PAYMENT>';
export const PLACE = '<PLACE>';
export const PLACES = '<PLACES>';
export const PRODUCTS_BUY = '<PRODUCTS_BUY>';
export const RECEIVE_FAIL = '<RECEIVE_FAIL>';
export const RECEIVE_SUCCESS = '<RECEIVE_SUCCESS>';
export const RECEIVE_TICKET = '<RECEIVE_TICKET>';
export const RECOMMEND = '<RECOMMEND>';
export const SEARCH = '<SEARCH>';
export const SELECTION = '<SELECTION>';
export const STATIC_ARTICLE = '<STATIC_ARTICLE>';
export const SUMMARY_FAIL = '<SUMMARY_FAIL>';
export const SUMMARY_SUCCESS = '<SUMMARY_SUCCESS>';
export const TRANSFER_SUCCESS = '<TRANSFER_SUCCESS>';
export const TRANSFER_TICKET = '<TRANSFER_TICKET>';
export const DEEPLINK_TICKET = '<DEEPLINK_TICKET>';
export const SEND_MAIL_SUCCESS = '<SEND_MAIL_SUCCESS>';
