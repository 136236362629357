import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((color: string) =>
  createStyles({
    fontColor: {
      color,
    },
  })
);

export default useStyles;
