import { LOCATION_CHANGE, getLocation, push } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { loadImages } from '@Model/images/actions';
import { getParams } from '@Model/internalRouter/selectors';
import { getKeyWhenPlaceExistsAndIsFresh } from '@Model/place/selectors';
import routes from '@Routes/routes';
import { IPlaceMatchParams } from '@Routes/types';
import { IPlaceResponse } from '@Services/$places-api/types';

import {
  getPlace,
  getPlaceSlug,
  placePageMounted,
  setCurrentKey,
} from './../actions';

export const getPlaceWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(placePageMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const keyOfExistedPlace = getKeyWhenPlaceExistsAndIsFresh(state);

      if (keyOfExistedPlace === null) {
        return EMPTY$;
      }

      if (keyOfExistedPlace) {
        return [setCurrentKey(keyOfExistedPlace)];
      }

      return [loadImages(), getPlace.request()];
    })
  );
};

export const fetchPlaceWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { placesApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPlace.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as IPlaceMatchParams;
      if (params) {
        return from$(placesApi.getPlace(params.slug)).pipe(
          mergeMap$((data: IPlaceResponse) => {
            const normalizedPlaceKey = placesApi.makeKeyFromParams(data);
            const normalizedPlaceData = placesApi.normalizePlaceData(data);

            return [
              getPlace.success({
                key: normalizedPlaceKey,
                place: normalizedPlaceData,
              }),
            ];
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => placesApi.cancelPlace())
            )
          ),
          catchError$((error: Error) => of$(getPlace.failure(error)))
        );
      }

      return EMPTY$;
    }),
    catchError$((error: Error) => of$(getPlace.failure(error)))
  );
};

export const redirectPlaceWhenLocationChange: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] ===
        routes.placeLegacy.split('/')[1]
    ),
    mergeMap$(([_, state]) => {
      return of$(
        getPlaceSlug.request(Number(getLocation(state).pathname.split('/')[2]))
      );
    })
  );
};

export const getPlaceSlugWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { placesApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPlaceSlug.request)),
    mergeMap$((action) => {
      return from$(placesApi.getPlaceById(action.payload)).pipe(
        map$((data: IPlaceResponse) => {
          return getPlaceSlug.success(data);
        }),
        catchError$((error: Error) =>
          of$(getPlaceSlug.failure(error), push(routes.places))
        )
      );
    })
  );
};

export const redirectPlaceWhenPlaceSlugSuccess: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(getPlaceSlug.success)),
    mergeMap$((action) => {
      return of$(
        push(fillUrlWithValues(routes.place, ':slug', action.payload.slug))
      );
    })
  );
};
