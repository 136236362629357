import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getPremiumUserByToken } from '@Model/premium/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenPremiumFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getPremiumUserByToken.request)),
    map$(() => setLoading(CONSTS.PREMIUM))
  );
};

export const setLoaderWhenPremiumFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [getPremiumUserByToken.success, getPremiumUserByToken.failure],
        action
      )
    ),
    map$(() => resetLoading(CONSTS.PREMIUM))
  );
};
