import { combineReducers } from 'redux';

import embedReducer from './embed';
import internalRouterReducer from './internalRouter';

const reducer = combineReducers({
  embed: embedReducer,
  internalRouter: internalRouterReducer,
});

export default reducer;
