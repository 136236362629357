import React, { FC, useEffect } from 'react';

import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import FailContent from '@Compo/summaries/Fail';

import { IPaymentFailProps } from './PaymentFail.types';

const PaymentFail: FC<IPaymentFailProps> = ({
  isEmbed,
  lang,
  mounted,
  unmount,
}) => {
  useEffect(() => {
    mounted();

    return () => {
      unmount();
    };
  }, []);

  if (isEmbed) {
    return <FailContent />;
  }

  return (
    <MuiGrid>
      <Helmet pageName={lang.title} />
      <FullTriangleBackground />
      <FailContent />
    </MuiGrid>
  );
};

export default PaymentFail;
