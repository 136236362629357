import { createSelector } from 'reselect';

import _Store from '@Store';

import { ILocaleReducer } from '@Model/locale/types';

import get from './get';

const isEnglish = createSelector<_Store.IState, ILocaleReducer, boolean>(
  [get],
  (locale) => locale.selectedLang === 'en'
);

export default isEnglish;
