import { Selector } from 'reselect';

import _Store from '@Store';

import { ITransactionReducer } from './../types';

const get: Selector<_Store.IState, ITransactionReducer> = (state) =>
  state.transaction;

export default get;
