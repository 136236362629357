const statutes: Record<
  string,
  { smsMessage: (code: string) => string; statueLink: string }
> = {
  // HANGAR DEV
  '60acac73bd3a4991b3bcc543': {
    smsMessage: (code: string) =>
      `Twój kod: ${code}. Podając kod w formularzu rejestracyjnym wyrażasz zgodę na wejścia Twojego dziecka do hangarów 646 i akceptujesz treść regulaminu dostępnego na www.hangar646.pl/regulamin`,
    statueLink:
      'https://web.goingapp.pl/storage/app/media/hangar/hangar_regulamin.pdf ',
  },
  // HANGAR PROD
  '60f905ff6114346ba06101eb': {
    smsMessage: (code: string) =>
      `Twój kod: ${code}. Podając kod w formularzu rejestracyjnym wyrażasz zgodę na wejścia Twojego dziecka do hangarów 646 i akceptujesz treść regulaminu dostępnego na www.hangar646.pl/regulamin`,
    statueLink:
      'https://web.goingapp.pl/storage/app/media/hangar/hangar_regulamin.pdf ',
  },
};

export default statutes;
