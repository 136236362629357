import axios from 'axios';

import { ICard } from '@Compo/reusable/Card/Card.types';
import config from '@Config';
import AWSQueryBuilder from '@Misc/classes/AWSQueryBuilder';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import convertToBool from '@Misc/helpers/convertToBool';
import formatDate from '@Misc/helpers/date/formatDate';
import splitNumberToDayMonthYear from '@Misc/helpers/date/splitNumberToDayMonthYear';
import today from '@Misc/helpers/date/today';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';
import translatableDate from '@Misc/helpers/translatableDate';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import { IEvent } from '@Model/events/types';
import { ILang } from '@Model/locale/types';
import routes from '@Routes/routes';
import {
  ICardDeckResponseData,
  IClosestRundateNormalizedData,
  IClosestRundateResponseData,
} from '@Services/$events-api/types';

import { EventsApi } from './EventsApi';
import {
  IEventPlaceResponse,
  IEventsAwsResponseData,
  IEventsHitResponse,
  IEventsResponseData,
  IRundateResponseMutable,
} from './types';

const DEFAULT_ORDER_CITY_ID = 1;
const EVENTS_PER_PAGE = 20;
const LOCATION_LOCAL_STORAGE_KEY = 'homeLocation';
const SUGGEST_LIST_SIZE = 24;
const SIMILAR_EVENTS_LIST_SIZE = 3;
const PLACE_EVENTS_LIST_SIZE = 4;

const SORTING = [
  { priority: 1, field: '_score', sort: 'desc' },
  { priority: 2, field: 'rundate_not_for_sale', sort: 'asc' },
  { priority: 3, field: 'partner_premium_level', sort: 'desc' },
  { priority: 4, field: 'place_premium_level', sort: 'desc' },
  { priority: 5, field: 'rundate_rundate', sort: 'asc' },
  { priority: 6, field: 'city', sort: 'desc' },
];

const SORTING_RECOMMEND = [
  { priority: 1, field: '_score', sort: 'desc' },
  { priority: 2, field: 'rundate_not_for_sale', sort: 'asc' },
  { priority: 3, field: 'partner_premium_level', sort: 'desc' },
  { priority: 4, field: 'place_premium_level', sort: 'desc' },
  { priority: 5, field: 'rundate_rundate', sort: 'asc' },
];

const STATIC_TERMS = [
  {
    name: 'has_pools',
    value: config.theme.isGoing ? ['0', '1'] : ['1'],
  },
  {
    name: 'sales_channels_ids',
    value: [config.app.salesChannelId.toString()],
  },
];

const WANTED_FACETS = [
  { name: 'event_category_id' },
  { name: 'partner_id' },
  { name: 'partner_premium_level' },
  { name: 'place_id' },
  { name: 'rundate_id' },
  { name: 'rundate_not_for_sale' },
  { name: 'city_id' },
  { name: 'rundate_rundate', sort: 'bucket' },
];

const WANTED_FIELDS = [
  'city_name',
  'event_category_id',
  'event_category_name',
  'event_slug',
  'geolocation',
  'partner_premium_level',
  'place_slug',
  'place_id',
  'place_name',
  'rundate_rundate',
  'rundate_slug',
  'title_pl',
];

const SEARCH_ALL_PHRASE = AWSQueryBuilder.phrase([]);
const DATE_ERROR = 'Invalid date';

class AwsApi extends EventsApi {
  public static getEventsUrl(): string {
    return `${config.api.awsUrl}`;
  }

  protected static getPlaceEventsQueryString(
    placeId: number,
    rundateId: number
  ): string {
    let queryString = '';
    const termsArray = [] as string[];

    const placeEventsTerms = [
      {
        name: 'place_id',
        value: [`${placeId}`],
      },
      {
        name: 'rundate_not_for_sale',
        value: ['0'],
      },
      {
        name: 'sales_channels_ids',
        value: [config.app.salesChannelId.toString()],
      },
    ];

    termsArray.push(SEARCH_ALL_PHRASE);
    termsArray.push(
      AWSQueryBuilder.exclude(this.makeIdTerm('rundate_id', rundateId))
    );
    termsArray.push(AWSQueryBuilder.term(placeEventsTerms));

    if (termsArray.length > 0) {
      queryString = termsArray.join('');
    }

    const datesQuery = AWSQueryBuilder.datesQuery(
      formatDate(splitNumberToDayMonthYear(today()), true),
      null
    );

    return AWSQueryBuilder.query(
      'search',
      AWSQueryBuilder.bond([queryString, datesQuery], 'AND'),
      [
        AWSQueryBuilder.option('format', 'json'),
        AWSQueryBuilder.option('q.parser', 'lucene'),
        AWSQueryBuilder.option('size', PLACE_EVENTS_LIST_SIZE),
        AWSQueryBuilder.option('sort', AWSQueryBuilder.sort(SORTING_RECOMMEND)),
        AWSQueryBuilder.option(
          'q.options',
          `%7Bfields: ['title_pl^5','title_en^5','description_pl','description_en','artists_names','place_name','partner_name','square_image']%7D`
        ),
      ]
    );
  }

  protected static getSimilarEventsQueryString(
    city: string,
    placeId: number,
    rundateId: number,
    tag: string
  ): string {
    let queryString = '';
    const termsArray = [] as string[];

    const similarTerms = [
      {
        name: 'city_name',
        value: [city],
      },
      {
        name: 'event_category_name',
        value: [tag],
      },
      {
        name: 'rundate_not_for_sale',
        value: ['0'],
      },
      {
        name: 'sales_channels_ids',
        value: [config.app.salesChannelId.toString()],
      },
    ];

    const datesQuery = AWSQueryBuilder.datesQuery(
      formatDate(splitNumberToDayMonthYear(today()), true),
      null
    );

    termsArray.push(SEARCH_ALL_PHRASE);
    termsArray.push(
      AWSQueryBuilder.exclude(this.makeIdTerm('rundate_id', rundateId))
    );
    termsArray.push(
      AWSQueryBuilder.exclude(this.makeIdTerm('place_id', placeId))
    );
    termsArray.push(AWSQueryBuilder.term(similarTerms));

    if (termsArray.length > 0) {
      queryString = termsArray.join('');
    }

    return AWSQueryBuilder.query(
      'search',
      AWSQueryBuilder.bond([queryString, datesQuery], 'AND'),
      [
        AWSQueryBuilder.option('format', 'json'),
        AWSQueryBuilder.option('q.parser', 'lucene'),
        AWSQueryBuilder.option('size', SIMILAR_EVENTS_LIST_SIZE),
        AWSQueryBuilder.option('sort', AWSQueryBuilder.sort(SORTING_RECOMMEND)),
        AWSQueryBuilder.option(
          'q.options',
          `%7Bfields: ['title_pl^5','title_en^5','description_pl','description_en','artists_names','place_name','partner_name','square_image']%7D`
        ),
      ]
    );
  }

  protected static getClosestRundateSlugQueryString(eventSlug: string): string {
    const closestTerms = [
      {
        name: 'has_pools',
        value: config.theme.isGoing ? ['0', '1'] : ['1'],
      },
      {
        name: 'sales_channels_ids',
        value: [config.app.salesChannelId.toString()],
      },
      {
        name: 'event_slug',
        value: [eventSlug],
      },
    ];

    const closestSort = [{ priority: 1, field: 'closest', sort: 'desc' }];

    const queryString = [SEARCH_ALL_PHRASE, AWSQueryBuilder.term(closestTerms)];

    return AWSQueryBuilder.query('search', queryString.join(''), [
      AWSQueryBuilder.option('format', 'json'),
      AWSQueryBuilder.option('q.parser', 'lucene'),
      AWSQueryBuilder.option('size', 1),
      AWSQueryBuilder.option('sort', AWSQueryBuilder.sort(closestSort)),
      AWSQueryBuilder.option(
        'q.options',
        `%7Bfields: ['title_pl^5','title_en^5','description_pl','description_en','artists_names','place_name','partner_name','square_image']%7D`
      ),
      AWSQueryBuilder.option('expr.closest', '(_time%20-%20rundate_rundate)'),
      AWSQueryBuilder.return(['rundate_slug']),
    ]);
  }

  private static makeIdTerm(name: string, id: number) {
    return [
      {
        name: `${name}`,
        value: [`${id}`],
      },
    ];
  }

  public getPlaceEvents(
    placeId: number,
    rundateId: number,
    dateTranslate: ILang
  ): Promise<ICardDeckResponseData> {
    return new Promise((resolve, reject) => {
      const queryString = AwsApi.getPlaceEventsQueryString(placeId, rundateId);
      const urlPlaceEvents = `${AwsApi.getEventsUrl()}${queryString}`;
      this.cancelTokenEvents = axios.CancelToken.source();

      axios
        .get(
          urlPlaceEvents,
          withCacheHeader({ cancelToken: this.cancelTokenEvent?.token })
        )
        .then(getData)
        .then((response: IEventsAwsResponseData) => {
          return resolve({
            placeEvents: response.hits.hit.map((event) =>
              this.normalizeEventResponseToCardData(event, dateTranslate)
            ),
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSimilarEvents(
    city: string,
    placeId: number,
    rundateId: number,
    tag: string,
    dateTranslate: ILang
  ): Promise<IEventsResponseData> {
    return new Promise((resolve, reject) => {
      const queryString = AwsApi.getSimilarEventsQueryString(
        city,
        placeId,
        rundateId,
        tag
      );
      const urlSimilarEvents = `${AwsApi.getEventsUrl()}${queryString}`;
      this.cancelTokenEvents = axios.CancelToken.source();

      axios
        .get(
          urlSimilarEvents,
          withCacheHeader({ cancelToken: this.cancelTokenEvent?.token })
        )
        .then(getData)
        .then((response: IEventsAwsResponseData) => {
          return resolve({
            count: response.hits.found,
            eventsData: response.hits.hit.map((event) =>
              this.normalizeShortEvent(event, dateTranslate)
            ),
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getClosestRundateSlug(
    eventSlug: string
  ): Promise<IClosestRundateNormalizedData> {
    return new Promise((resolve, reject) => {
      const queryString = AwsApi.getClosestRundateSlugQueryString(eventSlug);
      const urlClosestRundate = `${AwsApi.getEventsUrl()}${queryString}`;
      this.cancelTokenEvents = axios.CancelToken.source();

      axios
        .get(
          urlClosestRundate,
          withCacheHeader({ cancelToken: this.cancelTokenEvent?.token })
        )
        .then(getData)
        .then((response: IClosestRundateResponseData) => {
          return resolve({
            rundateSlug: response.hits.hit[0]?.fields?.rundate_slug,
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeEventResponseToCardData(
    eventResponse: IRundateResponseMutable,
    dateTranslate: ILang
  ): ICard {
    const {
      fields: {
        city_name,
        description_pl,
        event_slug,
        horizontal_image,
        place_slug,
        place_name,
        price,
        rundate_rundate,
        rundate_rundate_description,
        rundate_slug,
        square_image,
        title_pl,
        rundate_enddate,
      },
    } = eventResponse as IEventsHitResponse;

    return {
      date: translatableDate(
        rundate_rundate,
        rundate_rundate_description,
        dateTranslate,
        rundate_enddate
      ),
      description: description_pl,
      link: fillUrlWithValues(
        routes.event,
        [':eventSlug', ':rundateSlug?'],
        [event_slug, rundate_slug]
      ),
      place: `${city_name}`,
      subtitle: undefined,
      thumb: EventsApi.getImageUrl([
        {
          large: square_image || horizontal_image,
          medium: square_image || horizontal_image,
        },
      ]),
      title: title_pl,
    };
  }

  public normalizeShortEvent(
    eventResponse: IRundateResponseMutable,
    dateTranslate: ILang
  ): IEvent {
    const {
      id,
      fields: {
        buttonLabel,
        city_name,
        city_slug,
        change_monitor_name,
        currency,
        description_pl,
        empik_premium,
        end_date,
        event_category_id,
        event_category_name,
        event_slug,
        geolocation,
        horizontal_image,
        partner_id,
        partner_name,
        place_slug,
        place_id,
        place_name,
        place_thumb,
        price,
        price_description_pl,
        public_tags_ids,
        public_tags_names,
        rundate_not_for_sale,
        rundate_rundate,
        rundate_enddate,
        rundate_rundate_description,
        rundate_slug,
        redirect_to_url,
        square_image,
        title_pl,
      },
    } = eventResponse as IEventsHitResponse;

    const location = geolocation ? geolocation.split(',') : [0, 0];
    const place = {
      address: city_name, // TODO: change this to address from api instead city_name
      city: { name: city_name, slug: city_slug },
      id: place_id,
      lat: Number(location[0]),
      lon: Number(location[1]),
      name: place_name,
      slug: place_slug,
    } as IEventPlaceResponse;

    const eventTags = public_tags_ids
      ? public_tags_ids.map((eventId, index) => {
          return {
            id: Number(eventId),
            name: public_tags_names[index],
            slug: public_tags_names[index],
          };
        })
      : [];

    return {
      buttonLabel,
      changeMonitorName: change_monitor_name,
      currency,
      dateDescription: rundate_rundate_description,
      description: description_pl,
      endDate: rundate_enddate,
      eventSlug: event_slug,
      friendlyDate: translatableDate(
        rundate_rundate,
        rundate_rundate_description,
        dateTranslate,
        rundate_enddate
      ),
      friendlyHour: EventsApi.getFriendlyHour(
        rundate_rundate,
        rundate_rundate_description
      ),
      id: parseInt(id, 10),
      imageUrl: EventsApi.getImageUrl([
        {
          large: square_image || horizontal_image,
          medium: square_image || horizontal_image,
        },
      ]),
      isPremiumEvent: empik_premium,
      notForSale: convertToBool(rundate_not_for_sale),
      partner: `${partner_name}|${partner_id}`,
      place: EventsApi.getFriendlyPlace(place),
      placeImageUrl: place_thumb || '',
      placeSlug: place_slug || '',
      price: getPriceFormatted(price),
      priceDescription: EventsApi.getPriceDescription(price_description_pl),
      redirectToUrl: redirect_to_url || null,
      rundateSlug: rundate_slug,
      startDate: rundate_rundate,
      tags: EventsApi.prepareTagsFromRundate(
        { id: event_category_id, name: event_category_name, slug: event_slug },
        eventTags
      ),
      title: title_pl,
    };
  }
}

export default new AwsApi();
