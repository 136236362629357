import axios from 'axios';

import config from '@Config';
import getData from '@Misc/helpers/api/getData';
import withCacheHeader from '@Misc/helpers/withCacheHeader';

import { IRedirectionResponse } from './types';

class RedirectionApi {
  private static prepareUrl(url: string): string {
    const encodedUrl = encodeURIComponent(url);

    return `${config.redirections.url}redirection/agent/${config.app.salesAgent}/from/${encodedUrl}`;
  }

  public check(url: string): Promise<IRedirectionResponse> {
    return new Promise((resolve, reject) => {
      axios
        .get(RedirectionApi.prepareUrl(url), withCacheHeader())
        .then(getData)
        .then((response: IRedirectionResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new RedirectionApi();
