import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1rem',
      gap: '1rem',
      backgroundColor: theme.palette.secondary.contrastText,
      borderRadius: '1rem',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        padding: '2rem',
        gap: '2rem',
        width: '37.5rem',
      },
    },
    title: {
      fontWeight: 600,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.75rem',
      },
    },
    subtitle: {
      color: theme.palette.secondary.main,
      marginTop: theme.spacing(1),
      fontWeight: 400,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
      },
    },
    header: {
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
  })
);

export default useStyles;
