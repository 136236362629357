import React from 'react';

import StaticArticle from '@Compo/StaticArticle';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import { IBreadcrumb } from '@Model/breadcrumb/types';

const breadcrumbs: IBreadcrumb[] = [];

const StaticArticlePage = () => (
  <MuiGrid>
    <MuiSectionContainer>
      <MuiBreadcrumbs base={breadcrumbs} />
      <StaticArticle />
    </MuiSectionContainer>
  </MuiGrid>
);

export default StaticArticlePage;
