import { LOCATION_CHANGE, getSearch, push } from 'connected-react-router';
import { base64decode } from 'nodejs-base64';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import convertBinaryToUnicode from '@Misc/helpers/convertBinaryToUnicode';
import fillRundateData from '@Misc/helpers/fillRundateData';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { informAboutBuyingProcess } from '@Model/analytics/actions';
import { getImages, loadImages } from '@Model/images/actions';
import { locationChange } from '@Model/internalRouter/actions';
import {
  getIframeParams,
  getModule,
  getParams,
} from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { fetchUrlStructure } from '@Model/pages/actions';
import { getPools } from '@Model/pools/actions';
import { getLocation } from '@Model/router/selectors';
import * as MODULES from '@Routes/modules';
import routes from '@Routes/routes';
import {
  IEmbedRidMatchParams,
  IEventMatchParams,
  IHappeningMatchParams,
} from '@Routes/types';
import {
  ICardDeckResponseData,
  IClosestRundateNormalizedData,
  IEventResponse,
  IEventsResponseData,
  IRundateResponse,
} from '@Services/$events-api/types';

import {
  buyFormMounted,
  eventPageMounted,
  getEvent,
  getPlaceEvents,
  getRundateSlug,
  getSimilarEvents,
  setCurrentKey,
} from './../actions';
import { getKeyWhenEventExistsAndIsFresh } from './../selectors';
import { IGetEventByIdSuccessPayload } from './../types';

const SLUGS_PLACEHOLDER = [':eventSlug', ':rundateSlug'];

export const getEventWhenMounted: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(eventPageMounted)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const slugs = getParams(state) as IEventMatchParams;

      return (
        slugs.eventSlug !== SLUGS_PLACEHOLDER[0] &&
        slugs.rundateSlug !== SLUGS_PLACEHOLDER[1]
      );
    }),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] !==
        routes.eventLegacy.split('/')[1]
    ),
    mergeMap$(([_, state]) => {
      const keyOfExistedEvent = getKeyWhenEventExistsAndIsFresh(state);

      if (keyOfExistedEvent === null) {
        return EMPTY$;
      }

      if (keyOfExistedEvent) {
        return [setCurrentKey(keyOfExistedEvent), fetchUrlStructure()];
      }

      return [getEvent.request(), fetchUrlStructure()];
    })
  );

export const redirectEventWhenLocationChange: _Store.IEpic = (
  action$,
  state$
) =>
  action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] ===
        routes.eventLegacy.split('/')[1]
    ),
    mergeMap$(([_, state]) =>
      of$(
        getRundateSlug.request(
          Number(getLocation(state).pathname.split('/')[2])
        )
      )
    )
  );

export const redirectSelectionWhenLocationChange: _Store.IEpic = (
  action$,
  state$
) =>
  action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] ===
        routes.selectionLegacy.split('/')[1]
    ),
    mergeMap$(([_, state]) => {
      return of$(
        push(
          fillUrlWithValues(
            routes.selection,
            ':slug',
            getLocation(state).pathname.split('/')[3]
          )
        )
      );
    })
  );

export const getRundateSlugWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { eventsApi }
) =>
  action$.pipe(
    filter$(isActionOf(getRundateSlug.request)),
    mergeMap$((action) =>
      from$(eventsApi.getEventById(action.payload)).pipe(
        map$((data: IGetEventByIdSuccessPayload) =>
          getRundateSlug.success(data)
        ),
        catchError$((error: Error) =>
          of$(getRundateSlug.failure(error), push(routes.events))
        )
      )
    )
  );

export const redirectEventWhenRundateSlugSuccess: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(getRundateSlug.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const params = {
        eventSlug: action.payload.event.slug,
        rundateSlug: action.payload.slug,
      };
      const module = getModule(state);
      const isEmbed = getIframeParams(state);

      if (isEmbed) {
        return of$(
          locationChange({ module, params }),
          loadImages(),
          getPools.request(params),
          getEvent.request()
        );
      }

      return of$(
        push(
          fillUrlWithValues(
            routes.event,
            [':eventSlug', ':rundateSlug?'],
            [params.eventSlug, params.rundateSlug]
          )
        )
      );
    })
  );
};

export const getRundateSlugWhenEventFetch: _Store.IEpic = (
  action$,
  state$,
  { awsApi, eventsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getEvent.request)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const params = getParams(state) as IEventMatchParams;

      return !params?.rundateSlug && !!params?.eventSlug;
    }),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as IEventMatchParams;

      return from$(eventsApi.getSingleEvent(params.eventSlug)).pipe(
        mergeMap$((_data) => {
          const data = _data as unknown as IEventResponse;
          const module = getModule(state);

          if (data.calendarEvent && module === MODULES.EVENT) {
            return of$(
              push(
                fillUrlWithValues(
                  routes.activity,
                  [':eventSlug'],
                  [params.eventSlug]
                )
              )
            );
          }

          if (data.calendarEvent) {
            return [
              getEvent.success({
                event: fillRundateData(data),
                key: data.slug,
              }),
            ];
          }

          return from$(awsApi.getClosestRundateSlug(params.eventSlug)).pipe(
            mergeMap$((rundateData: IClosestRundateNormalizedData) => {
              return of$(
                push(
                  fillUrlWithValues(
                    routes.event,
                    [':eventSlug', ':rundateSlug?'],
                    [params.eventSlug, rundateData.rundateSlug]
                  )
                )
              );
            })
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => eventsApi.cancelEvent())
          )
        ),
        catchError$((error: Error) => of$(getEvent.failure(error)))
      );
    })
  );
};

export const fetchEventWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { eventsApi }
) =>
  action$.pipe(
    filter$(isActionOf(getEvent.request)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const params = getParams(state) as
        | IEventMatchParams
        | IEmbedRidMatchParams
        | IHappeningMatchParams;

      if ('eventSlug' in params)
        return !!params.eventSlug && !!params.rundateSlug;
      return 'id' in params || 'slug' in params;
    }),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as
        | IEventMatchParams
        | IEmbedRidMatchParams
        | IHappeningMatchParams;
      const translateDate = translate(state)('dates');

      if (params) {
        return from$(
          'eventSlug' in params
            ? eventsApi.getSingleEvent(params.eventSlug, params.rundateSlug)
            : 'slug' in params
            ? eventsApi.getSingleEvent(params.slug)
            : eventsApi.getEventById(parseInt(params.id, 10))
        ).pipe(
          mergeMap$((data: IRundateResponse | IEventResponse) => {
            if ('event' in data) {
              const normalizedEvent = eventsApi.normalizeFullEvent(
                data,
                translateDate
              );
              const normalizedEventKey =
                eventsApi.makeKeyFromParams(normalizedEvent);
              const module = getModule(state);
              const key = `${config.images.placePrefix}${data.place.slug}`;

              if (module === MODULES.EVENT) {
                return [
                  getEvent.success({
                    event: normalizedEvent,
                    key: normalizedEventKey,
                  }),
                  getPlaceEvents.request({
                    placeId: data.place.id,
                    rundateId: data.id,
                  }),
                  getSimilarEvents.request({
                    city: data.place.city.name,
                    placeId: data.place.id,
                    rundateId: data.id,
                    tag: data.event.category.name,
                  }),
                  getImages.request(key),
                ];
              } else {
                return [
                  getEvent.success({
                    event: normalizedEvent,
                    key: normalizedEventKey,
                  }),
                ];
              }
            } else {
              return [
                getEvent.success({
                  event: fillRundateData(data),
                  key: data.slug,
                }),
              ];
            }
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => eventsApi.cancelEvent())
            )
          ),
          catchError$((error: Error) => of$(getEvent.failure(error)))
        );
      }

      return EMPTY$;
    }),
    catchError$((error: Error) => of$(getEvent.failure(error)))
  );

export const fetchPlaceEvents: _Store.IEpic = (action$, state$, { awsApi }) => {
  return action$.pipe(
    filter$(isActionOf(getPlaceEvents.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const dateTranslate = translate(state)('dates');

      return from$(
        awsApi.getPlaceEvents(
          action.payload.placeId,
          action.payload.rundateId,
          dateTranslate
        )
      ).pipe(
        map$((data: ICardDeckResponseData) =>
          getPlaceEvents.success({
            placeEvents: data.placeEvents,
          })
        ),
        catchError$((error: Error) => of$(getPlaceEvents.failure(error)))
      );
    })
  );
};

export const fetchSimilarEvents: _Store.IEpic = (
  action$,
  state$,
  { awsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSimilarEvents.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const translateDate = translate(state)('dates');

      return from$(
        awsApi.getSimilarEvents(
          action.payload.city,
          action.payload.placeId,
          action.payload.rundateId,
          action.payload.tag,
          translateDate
        )
      ).pipe(
        map$((data: IEventsResponseData) =>
          getSimilarEvents.success({
            similarEvents: data.eventsData,
          })
        ),
        catchError$((error: Error) => of$(getSimilarEvents.failure(error)))
      );
    })
  );
};

export const useAnalyticsWhenBuyFormMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf([buyFormMounted, getEvent.success])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const module = getModule(state);

      if (module === MODULES.BUY) {
        return of$(informAboutBuyingProcess());
      }

      return EMPTY$;
    })
  );
};

export const fillBuyFormFromUri: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([buyFormMounted])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const uri = getLocation(state);

      if (uri?.query?.data) {
        try {
          const dataEncoded = base64decode(uri.query.data);
          let values;

          /*
           *
           * Below: If data param comes from new mobile app (isWebview === 'true' - we have to check for a string,
           * not boolean), we need to convert binary string to unicode, after we decode base64 string. This is because
           * of the fact that mobile app converts the data string from unicode to binary before base64 encoding
           * (due to JS limitations).
           * If data param comes from the "Expo" form, we don't do that - otherwise the app would crash (and vice versa).
           *
           */

          if (uri.query.isWebview === 'true') {
            values = convertBinaryToUnicode(dataEncoded);
          } else {
            values = dataEncoded;
          }

          window.localStorage.setItem('buy-form', `{"values":${values}}`);
        } catch (noSSR) {}
      }

      return EMPTY$;
    })
  );
};
