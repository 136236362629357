import { Location } from 'history';
import qs from 'query-string';
import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRouterLocationSearchParams } from './../types';
import getLocation from './getLocation';

const acceptableParams = ['token'];

const getAcceptableValuesFromQueryString = createSelector<
  _Store.IState,
  Location,
  IRouterLocationSearchParams
>([getLocation], (location) => {
  const { search } = location;
  const paramObject: IRouterLocationSearchParams = {};

  if (search) {
    const params = qs.parse(search);

    const paramNames = Object.keys(params).filter(
      (name) => acceptableParams.indexOf(name) >= 0
    );

    paramNames.forEach((name) => {
      paramObject[name] = params[name] as string;
    });
  }

  return paramObject;
});

export default getAcceptableValuesFromQueryString;
