import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      color: theme.palette.text.primary,
    },
    secondary: {
      color: theme.palette.text.secondary,
    },
    priceFont: {
      fontSize: 'inherit',
    },
    margin: { marginRight: '0.25rem' },
    tooltip: {
      top: '0.375rem',
      position: 'relative',
      color: theme.palette.text.primary,
    },
  })
);

export default useStyles;
