import React, { FC } from 'react';

import { AccordionItem, Accordion as AccordionSanfona } from 'react-sanfona';

import styles from './Accordion.module.scss';
import { IPlaceAccordion } from './Accordion.types';

const ACCORDION_HEADER_TEXT = 'najważniejsze informacje';

const Accordion: FC<IPlaceAccordion> = ({ accordionData }) => (
  <>
    <h3 className={styles.sectionTitle}>{ACCORDION_HEADER_TEXT}</h3>

    <AccordionSanfona>
      {accordionData.map((item) => {
        return (
          <AccordionItem
            title={item.title}
            key={item.title}
            titleTag="h4"
            titleClassName={styles.title}
            bodyClassName={styles.text}
            expandedClassName={styles.expanded}
            className={styles.accordion}
          >
            {item.text}
          </AccordionItem>
        );
      })}
    </AccordionSanfona>
  </>
);

export default Accordion;
