import { getHappeningEvent } from '@/models/happening/selectors';
import moment from 'moment';
import { EMPTY as EMPTY$, of as of$ } from 'rxjs';
import {
  delay as delay$,
  filter as filter$,
  mergeMap as mergeMap$,
  switchMap as switchMap$,
  take as take$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import isEmptyObject from '@Misc/helpers/isEmptyObject';
import join from '@Misc/helpers/join';
import _L from '@Misc/helpers/local';
import { APP_IS_READY } from '@Model/app/constants/actions';
import { get as getBasket } from '@Model/basket/selector';
import {
  informAboutError404,
  informAboutErrorModal,
  informAboutErrorWhenSomethingWrong,
} from '@Model/errors/actions';
import { get } from '@Model/errors/selectors';
import { getEvent as getEventAction } from '@Model/event/actions';
import { getEvent } from '@Model/event/selectors';
import { getHappening } from '@Model/happening/actions';
import { locationChange } from '@Model/internalRouter/actions';
import { getIframeParams, getModule } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { selectTicket, updateSelectedTickets } from '@Model/pools/actions';
import {
  getFinalPrice,
  getNumberOfInsuredTickets,
  getSelectedTickets,
} from '@Model/pools/selectors';
import { getInsuranceSummaryData } from '@Model/products/selectors';
import { getLocation } from '@Model/router/selectors';
import {
  fetchSearchResults,
  setDateFilter,
  setSearchFilter,
  switchView,
} from '@Model/search/actions';
import {
  getLastFilter,
  getSearchDates,
  getSearchFilters,
  getSearchOffset,
  getSearchPhrase,
  getSearchResults,
  getSearchView,
} from '@Model/search/selectors';
import {
  buyAndPayOnline,
  getTransactionDetails as getTransactionDetailsAction,
  getTransactionInfo as getTransactionInfoAction,
} from '@Model/transaction/actions';
import getTransactionDetails from '@Model/transaction/selectors/getTransactionDetails';
import getTransactionInfo from '@Model/transaction/selectors/getTransactionInfo';
import {
  BUY,
  EMBED_BUY,
  EMBED_HAPPENING,
  EVENT,
  HAPPENING,
} from '@Routes/modules';
import * as LOCALS from '@Vars/constants';

import * as TEXTS from '../../meta/constants/texts';
import {
  IHappeningAddToCart,
  ISearchFilters,
  ISearchFiltersArrayElement,
} from '../types';
import {
  addNewsletterSubscriptionToDataLayer,
  checkoutStep,
  eventAddPaymentInfo,
  eventAddToCart,
  eventAddToCartHappening,
  eventAutoFillData,
  eventBannerClick,
  eventBuyButtonClicked,
  eventDeeplinkWeb,
  eventProceed,
  eventRemoveFromCart,
  eventRemoveFromCartHappening,
  eventSearchClick,
  eventSelectItem,
  firstValidation,
  informAboutBuyingProcess,
  setTradedoublerId,
  validateForm,
} from './../actions';
import { isFirstValidation, isSelectTickets } from './../selector';

const DESCRIPTION_SEPARATOR = ', ';
const TITLE_SEPARATOR = ' | ';
const PAGE_NAME_TEXT = _L(LOCALS.HELMET__PAGE_NAME);

export const addDataToGTMWhenAddPaymentInfoEvent: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(eventAddPaymentInfo)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { buttonLabel, paymentMethod } = action.payload;
      const basketData = getBasket(state);
      const event = getEvent(state);
      const embedParam = getIframeParams(state);
      const isEmbed = embedParam && !isEmptyObject(embedParam);
      const finalPrice = getFinalPrice(state);
      const selectedTickets = getSelectedTickets(state);
      const insuranceSummary = getInsuranceSummaryData(state);
      if (event && basketData) {
        analytics.pushForAddPaymentInfo(
          paymentMethod,
          finalPrice,
          buttonLabel,
          !!isEmbed,
          event,
          basketData,
          selectedTickets,
          insuranceSummary
        );
      }

      return EMPTY$;
    })
  );
};

export const addDataToGTMOnDeeplinkWeb: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(eventDeeplinkWeb)),
    mergeMap$((action) => {
      const payload = action.payload;
      analytics.pushForDeeplinkWeb(
        payload.textDisplayed,
        payload.destinationType,
        payload.destinationUrl
      );

      return EMPTY$;
    })
  );
};

export const addDataToGTMOnAutoFillData: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(eventAutoFillData)),
    mergeMap$((action) => {
      analytics.pushForAutoFillData(action.payload);

      return EMPTY$;
    })
  );
};

export const addDataToGTMOnProceed: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(eventProceed)),
    mergeMap$((action) => {
      analytics.pushForProceed(action.payload);

      return EMPTY$;
    })
  );
};

export const addDataToGTMOnSearchFilter: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf([setDateFilter, setSearchFilter])),
    switchMap$(() =>
      action$.pipe(
        filter$(isActionOf(fetchSearchResults.success)),
        take$(1),
        withLatestFrom$(state$),
        mergeMap$(([action, state]) => {
          const phrase = getSearchPhrase(state);
          const dates = getSearchDates(state);
          const resultsLength = getSearchResults(state).length;
          const tileView = getSearchView(state);
          const searchFilters = getSearchFilters(state);
          const lastFilter = getLastFilter(state);

          const searchFiltersFormatted: { [key: string]: string } = {};

          const firstDate = dates.from;
          const secondDate = dates.to;

          const firstDateFormatted =
            firstDate !== null ? moment(String(firstDate)).format('L') : '';
          const secondDateFormatted =
            firstDate !== null ? moment(String(secondDate)).format('L') : '';

          searchFilters.forEach((item) => {
            searchFiltersFormatted[item.key] = item.value;
          });

          analytics.pushForSearchFilter(
            phrase,
            resultsLength,
            tileView,
            searchFiltersFormatted,
            firstDateFormatted,
            secondDateFormatted,
            lastFilter
          );

          return EMPTY$;
        })
      )
    )
  );
};

export const addDataToGTMOnSearchView: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(switchView)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const phrase = getSearchPhrase(state);
      const dates = getSearchDates(state);
      const resultsLength = getSearchResults(state).length;
      const tileView = getSearchView(state);
      const searchFilters = getSearchFilters(
        state
      ) as ISearchFiltersArrayElement[];

      const searchFiltersFormatted: ISearchFilters = {};

      const firstDate = dates.from;
      const secondDate = dates.to;

      const firstDateFormatted =
        firstDate !== null ? moment(String(firstDate)).format('L') : '';
      const secondDateFormatted =
        firstDate !== null ? moment(String(secondDate)).format('L') : '';

      searchFilters.forEach((item) => {
        searchFiltersFormatted[item.key] = item.value;
      });

      if (phrase.length > 0) {
        analytics.pushForSearchView(
          phrase,
          resultsLength,
          tileView,
          searchFiltersFormatted,
          firstDateFormatted,
          secondDateFormatted
        );
      }

      return EMPTY$;
    })
  );
};

export const addDataToGTMOnSelectItem: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(eventSelectItem)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const phrase = getSearchPhrase(state);
      const results = getSearchResults(state);
      const tileView = getSearchView(state);
      const searchFilters = getSearchFilters(
        state
      ) as ISearchFiltersArrayElement[];
      const dates = getSearchDates(state);
      const event = action.payload;

      const firstDate = dates.from;
      const secondDate = dates.to;

      const firstDateFormatted =
        firstDate !== null ? moment(String(firstDate)).format('L') : '';
      const secondDateFormatted =
        firstDate !== null ? moment(String(secondDate)).format('L') : '';

      const searchFiltersFormatted: ISearchFilters = {};

      searchFilters.forEach((item) => {
        searchFiltersFormatted[item.key] = item.value;
      });

      analytics.pushForSelectItemEvent(
        phrase,
        results.length,
        tileView,
        searchFiltersFormatted,
        firstDateFormatted,
        secondDateFormatted,
        event
      );

      return EMPTY$;
    })
  );
};

export const addDataToGTMOnSearch: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(eventSearchClick)),
    switchMap$(() =>
      action$.pipe(
        filter$(isActionOf(fetchSearchResults.success)),
        take$(1),
        withLatestFrom$(state$),
        mergeMap$(([action, state]) => {
          const phrase = getSearchPhrase(state);
          const dates = getSearchDates(state);
          const resultsLength = getSearchResults(state).length;
          const tileView = getSearchView(state);
          const searchFilters = getSearchFilters(
            state
          ) as ISearchFiltersArrayElement[];

          const searchFiltersFormatted: ISearchFilters = {};

          const firstDate = dates.from;
          const secondDate = dates.to;

          const firstDateFormatted =
            firstDate !== null ? moment(String(firstDate)).format('L') : '';
          const secondDateFormatted =
            firstDate !== null ? moment(String(secondDate)).format('L') : '';

          searchFilters.forEach((item) => {
            searchFiltersFormatted[item.key] = item.value;
          });
          analytics.pushForSearch(
            phrase,
            resultsLength,
            tileView,
            searchFiltersFormatted,
            firstDateFormatted,
            secondDateFormatted
          );

          return EMPTY$;
        })
      )
    )
  );
};

export const addDataToGTMWhenBannerClicked: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(eventBannerClick)),
    mergeMap$((action) => {
      analytics.pushBannerClick(action.payload);

      return EMPTY$;
    })
  );
};

export const addDataToGTMWhenAddToCartEvent: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf([eventAddToCart, eventAddToCartHappening])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const event = getEvent(state);
      const embedParam = getIframeParams(state);
      const isEmbed = embedParam && !isEmptyObject(embedParam);
      const numOfInsuredTickets = getNumberOfInsuredTickets(
        action.payload &&
          'isInsuranceSelected' in action.payload &&
          !!action.payload.isInsuranceSelected
      )(state);
      const module = getModule(state);
      const isActivity = event?.calendarEvent;

      if (event && (module === HAPPENING || module === EMBED_HAPPENING)) {
        analytics.pushForAddToCartEvent({
          isEmbed: !!isEmbed,
          payload: action.payload,
          event,
          isActivity,
          isInsurance: false,
        });
      }

      if (event && (module === BUY || module === EMBED_BUY)) {
        analytics.pushForAddToCartEvent({
          isEmbed: !!isEmbed,
          payload: action.payload,
          numOfInsuredTickets,
          event,
          isInsurance:
            action.payload &&
            'isInsuranceAddToCartEvent' in action.payload &&
            action.payload.isInsuranceAddToCartEvent,
        });
      }

      if (!window?.ReactNativeWebView) return EMPTY$;

      const msgPayload = {
        event: 'add_to_cart',
        event_eventInfo_detail: 'add to cart',
        event_category_event_type: 'interaction',
        event_category_primary_category: 'ecommerce',
        items: [
          {
            item_id: event?.eventId.toString(10),
            item_name: event?.title,
            currency: action.payload.currency,
            index: 0,
            discount: 0,
            coupon: '',
            item_brand: `${event?.partnerName} | ${event?.partnerId}`,
            item_category: `${event?.placeName} | ${event?.placeId}`,
            item_category2: !!event?.tags.length ? event?.tags[0].name : '',
            item_variant: event?.rundateId.toString(10),
            price: action.payload.price,
            quantity: 1,
            tags: event?.tags.map((tag) => tag.name).join(','),
          },
        ],
      };

      window.ReactNativeWebView.postMessage(JSON.stringify(msgPayload));

      return EMPTY$;
    })
  );
};

export const addDataToGTMWhenRemoveFromCartEvent: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf([eventRemoveFromCart, eventRemoveFromCartHappening])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const event = getEvent(state);
      const embedParam = getIframeParams(state);
      const isEmbed = embedParam && !isEmptyObject(embedParam);
      const module = getModule(state);
      const numOfInsuredTickets = getNumberOfInsuredTickets(
        action.payload &&
          'isInsuranceSelected' in action.payload &&
          !!action.payload.isInsuranceSelected
      )(state);
      const isActivity = event?.calendarEvent;

      if (
        isActivity &&
        event &&
        (module === HAPPENING || module === EMBED_HAPPENING)
      ) {
        analytics.pushForRemoveFromCartEvent({
          isEmbed: !!isEmbed,
          payload: action.payload,
          event,
          isActivity,
          isInsurance: false,
        });
      }

      if (event && (module === BUY || module === EMBED_BUY)) {
        analytics.pushForRemoveFromCartEvent({
          isEmbed: !!isEmbed,
          payload: action.payload,
          numOfInsuredTickets,
          event,
          isInsurance:
            action.payload &&
            'isInsuranceAddToCartEvent' in action.payload &&
            action.payload.isInsuranceAddToCartEvent,
        });
      }

      if (!window?.ReactNativeWebView) return EMPTY$;

      const msgPayload = {
        event: 'remove_from_cart',
        event_eventInfo_detail: 'remove_from_cart',
        event_category_event_type: 'interaction',
        event_category_primary_category: 'ecommerce',
        items: [
          {
            item_id: event?.eventId.toString(10),
            item_name: event?.title,
            currency: action.payload.currency,
            index: 0,
            discount: 0,
            coupon: '',
            item_brand: `${event?.partnerName} | ${event?.partnerId}`,
            item_category: `${event?.placeName} | ${event?.placeId}`,
            item_category2: !!event?.tags.length ? event?.tags[0].name : '',
            item_variant: event?.rundateId.toString(10),
            price: action.payload.price,
            quantity: 1,
            tags: event?.tags.map((tag) => tag.name).join(','),
          },
        ],
      };

      window.ReactNativeWebView.postMessage(JSON.stringify(msgPayload));

      return EMPTY$;
    })
  );
};

export const addDataToGTMOnEventPageView: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(getEventAction.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const event = getEvent(state);
      const embedParam = getIframeParams(state);
      const isEmbed = embedParam && !isEmptyObject(embedParam);
      if (event) {
        analytics.pushForViewEvent(event, !!isEmbed);
      }

      return EMPTY$;
    })
  );
};

export const addDataToGTMOnEventPurchase: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(getTransactionDetailsAction.success)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const transactionInfo = getTransactionInfo(state);
      const transactionDetails = getTransactionDetails(state);
      const transactionProducts = transactionDetails?.transactionItems;
      const transactionInsurance = transactionDetails?.transactionProducts;
      const embedParam = getIframeParams(state);
      const isEmbed = embedParam && !isEmptyObject(embedParam);

      if (transactionProducts?.length !== 0 && transactionInfo) {
        analytics.pushForPurchase(
          !!isEmbed,
          transactionInfo,
          transactionProducts,
          transactionInsurance
        );
      }

      return EMPTY$;
    })
  );
};

export const addDataToGTMWhenPaymentComplete: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(getTransactionInfoAction.success)),
    withLatestFrom$(state$),
    tap$(([action, state]) => {
      const paymentDetails = action.payload;
      const isEmbed = !!getIframeParams(state);

      const { products, ...actionField } = paymentDetails;

      const formattedProducts = {
        ...products,
      };

      if (paymentDetails) {
        const layer = {
          ecommerce: {
            purchase: {
              actionField,
              products: [formattedProducts],
            },
          },
        };
        analytics.pushForTransactionCompletion(layer, isEmbed);
      }
    }),
    mergeMap$(() => EMPTY$)
  );
};

export const addDataToGTMWhenLocationChanged: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(locationChange)),
    withLatestFrom$(state$),
    delay$(0),
    mergeMap$(([_, state]) => {
      const embedParam = getIframeParams(state);
      const isEmbed = embedParam && !isEmptyObject(embedParam);
      let title = '';
      let counter = 0;

      const loadingDocumentTitle = config.theme.isSbm
        ? join(DESCRIPTION_SEPARATOR, TEXTS.LOADING)
        : join(
            TITLE_SEPARATOR,
            join(DESCRIPTION_SEPARATOR, TEXTS.LOADING),
            PAGE_NAME_TEXT
          );

      // This solution is necessary because of bug in React Helmet package which is connected with document.title property
      const interval = setInterval(() => {
        title = document.title;
        counter++;
        if (title !== loadingDocumentTitle || counter === 15) {
          analytics.pushPageView(!!isEmbed);
          clearInterval(interval);
        }
      }, 300);

      return EMPTY$;
    })
  );
};

export const addDataToGTMWhenEventDataLoaded: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) =>
  action$.pipe(
    filter$(isActionOf([getEventAction.success, getHappening.success])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const embedParam = getIframeParams(state);
      const module = getModule(state);
      let title = '';
      let counter = 0;
      const isEmbed = embedParam && !isEmptyObject(embedParam);
      const loadingDocumentTitle = config.theme.isSbm
        ? join(DESCRIPTION_SEPARATOR, TEXTS.LOADING)
        : join(
            TITLE_SEPARATOR,
            join(DESCRIPTION_SEPARATOR, TEXTS.LOADING),
            PAGE_NAME_TEXT
          );

      if (!window.routerHistory) {
        window.routerHistory = [];
      }

      window.routerHistory.push(window.location.href);

      const previousUrl =
        window.routerHistory.length > 1
          ? window.routerHistory[window.routerHistory.length - 2]
          : document.referrer;

      const sendEmbedPageView = (eventPartnerId?: number) => {
        const embedHostname = embedParam?.currentUrl
          ? new URL(embedParam.currentUrl).hostname
          : undefined;

        // This solution is necessary because of bug in React Helmet package which is connected with document.title property
        const interval = setInterval(() => {
          title = document.title;
          counter++;
          if (title !== loadingDocumentTitle || counter === 15) {
            analytics.pushPageView(
              !!isEmbed,
              previousUrl,
              eventPartnerId,
              embedHostname
            );
            clearInterval(interval);
          }
        }, 300);
      };

      if (module === EMBED_BUY) {
        const event = getEvent(state);
        const eventPartnerId = event?.partnerId;
        sendEmbedPageView(eventPartnerId);
      }

      if (module === EMBED_HAPPENING) {
        const event = getHappeningEvent(state);
        const eventPartnerId = event.partnerId;
        sendEmbedPageView(eventPartnerId);
      }

      return EMPTY$;
    })
  );

export const addDataToGTMWhenLocationChangedOld: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf([locationChange, getEventAction.success])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const module = getModule(state);

      if (module !== EVENT && module !== BUY) {
        analytics.pushPageViewOld();
      } else if (module === EVENT) {
        return of$(informAboutBuyingProcess());
      }

      return EMPTY$;
    })
  );
};

export const addDataToGTMWhenEventLoaded: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(informAboutBuyingProcess)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const event = getEvent(state);

      if (event) {
        const layer = {
          eventId: event.eventId,
          rundateId: event.rundateId,
        };
        analytics.pushForBuyingProcessOld(layer);
      }

      return EMPTY$;
    })
  );
};

export const addDataToGTMWhenErrorModal: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(informAboutErrorModal)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const url = getLocation(state).pathname;
      const error = get(state);

      analytics.pushForErrorModal(url, error);

      return EMPTY$;
    })
  );
};

export const addDataToGTMWhenErrorWhenSomethingWrong: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(informAboutErrorWhenSomethingWrong)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const url = getLocation(state).pathname;

      analytics.pushForErrorWhenSomethingWrong(url);

      return EMPTY$;
    })
  );
};

export const addDataToGTMWhenError404: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(informAboutError404)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const url = getLocation(state).pathname;

      analytics.pushForError404(url);

      return EMPTY$;
    })
  );
};

// NEW ANALYTICS

export const addDataToGTMOnEventPage: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf([getEventAction.success, eventBuyButtonClicked])),
    withLatestFrom$(state$),
    filter$(([action, state]) => {
      const module = getModule(state);

      if (module === EVENT) {
        return true;
      }

      return false;
    }),
    mergeMap$(([action, state]) => {
      const event = getEvent(state);
      const embedParam = getIframeParams(state);
      const isEmbed = embedParam && !isEmptyObject(embedParam);
      const addToCart = isActionOf(eventBuyButtonClicked)(action);

      if (event) {
        analytics.pushForEventPage(event, !!isEmbed, addToCart);
      }

      return EMPTY$;
    })
  );
};

export const addDataToGTMWhenTicketSelected: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf([selectTicket, updateSelectedTickets])),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        !getSelectedTickets(state).length && isSelectTickets(state)
    ),
    mergeMap$(() => of$(checkoutStep(1)))
  );
};

export const addDataToGTMWhenFormValidate: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(validateForm)),
    withLatestFrom$(state$),
    filter$(([_, state]) => isFirstValidation(state)),
    mergeMap$(() => of$(firstValidation(), checkoutStep(2)))
  );
};

export const addDataToGTMWhenFormSend: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(buyAndPayOnline)),
    mergeMap$(() => of$(checkoutStep(3)))
  );
};

export const addDataToGTMOnBuyPage: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(checkoutStep)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const event = getEvent(state);
      const embedParam = getIframeParams(state);
      const isEmbed = embedParam && !isEmptyObject(embedParam);
      const ticketsAmount = getSelectedTickets(state)
        .map((pool) => pool.amount)
        .reduce((a: number, b: number) => a + b, 0);
      const selectedTickets = getSelectedTickets(state)
        .map((pool) => `${pool.poolId}|${pool.amount}`)
        .join(', ');

      if (event) {
        analytics.pushForBuyPage(
          event,
          !!isEmbed,
          action.payload,
          ticketsAmount,
          selectedTickets
        );
      }

      return EMPTY$;
    })
  );
};

export const addTradeDoublerIdWhenAppIsReady: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isOfType(APP_IS_READY)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const tduid = getLocation(state).query?.tduid;

      if (tduid && tduid.length === 32) {
        document.cookie = `TRADEDOUBLER=${tduid};expires=${moment()
          .add(1, 'y')
          .utc()}`; // set cookie expiration date to 1 year onward

        return [setTradedoublerId(tduid)];
      }

      return EMPTY$;
    })
  );
};

export const addDataToGTMOnEventsListPage: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) => {
  return action$.pipe(
    filter$(isActionOf(fetchSearchResults.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { results } = action.payload;
      const offset = getSearchOffset(state);

      analytics.pushForEventsList(results, offset, 'search', 'search');

      return EMPTY$;
    })
  );
};

export const addDataToGTMonNewsletterSub: _Store.IEpic = (
  action$,
  state$,
  { analytics }
) =>
  action$.pipe(
    filter$(isActionOf(addNewsletterSubscriptionToDataLayer)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const lang = translate(state)('buy', 'formLayout');

      analytics.pushNewsletterSubscription(action.payload, lang.buyButton);

      return EMPTY$;
    })
  );
