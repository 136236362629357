import { createSelector } from 'reselect';

import _Store from '@Store';

import { IAuthReducer } from './../types';
import get from './get';

const showLoginPanel = createSelector<_Store.IState, IAuthReducer, boolean>(
  [get],
  (auth) => auth.showLogin
);

export default showLoginPanel;
