import React, { FC, useContext } from 'react';

import { Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Tooltip } from '@mui/material';
import cn from 'classnames';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import config from '@Config';
import getCurrencyFromCode from '@Misc/helpers/getCurrencyFromCode';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';

import styles from './Pools.module.scss';
import useStyles from './Pools.styles';
import { IPoolsProps } from './Pools.types';

const COURIER_COST = config.buy.courierDeliveryPrice;
const GIFT_WRAP_COST = config.buy.giftWrapperPrice;

const Pools: FC<IPoolsProps> = ({
  pool,
  lang,
  ticketTransferFee,
  cancelTicket,
  giftWrap,
  isRundateWithSeatsIo,
  isTransferTicket,
  priceType,
  selectProduct,
  selectTicket,
  withCourier,
  product,
  selectedTheme,
  isInsurance,
}) => {
  const state = useContext(clearContext);

  const summedPrice = pool
    ? (pool.totalPoolPrice || 0) +
      (pool.serviceFee || 0) +
      (ticketTransferFee || 0)
    : 0;

  const close = () => {
    if (pool) {
      if (cancelTicket) {
        if (!product) {
          selectTicket(pool.id, 0, pool.poolName, pool.currency, pool.payments);
        } else {
          selectProduct(product, 0);
        }
      } else {
        state?.removeFromBag(pool.id);
      }
    }
  };
  const muiStyles = useStyles();

  const isDefaultTheme = selectedTheme === 'default';

  return (
    <section
      className={cn(
        styles.basketPools,
        isInsurance && styles.insurance,
        !isDefaultTheme && muiStyles.basketPools
      )}
    >
      {pool && (
        <Grid container={true} direction={'column'} spacing={3}>
          <Grid
            container={true}
            item={true}
            direction={'row'}
            justifyContent={'space-between'}
            wrap={'nowrap'}
          >
            <Typography variant={'body1'}>{pool.poolName}</Typography>
            {!isRundateWithSeatsIo && !isTransferTicket && !priceType && (
              <IconButton
                className={styles.close}
                onClick={close}
                color={'secondary'}
              >
                <Close />
              </IconButton>
            )}
          </Grid>
          <Grid container={true} item={true} direction={'column'} spacing={0}>
            <Grid
              container={true}
              item={true}
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Typography
                variant={isDefaultTheme ? 'caption' : 'body2'}
                color={'secondary'}
              >
                {lang.pricePerItem}
              </Typography>
              <Typography
                variant={isDefaultTheme ? 'caption' : 'body2'}
                color={'secondary'}
              >
                {getPriceFormatted(pool.poolPrice || 0)}{' '}
                {getCurrencyFromCode(pool.currency)}
              </Typography>
            </Grid>
            {!!pool.serviceFee && (
              <Grid
                container={true}
                item={true}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Typography
                  className={muiStyles.typographyWithTooltip}
                  variant={isDefaultTheme ? 'caption' : 'body2'}
                  color={'secondary'}
                >
                  {lang.serviceFee}
                  {config.app.showUnfinishedFeatures && (
                    <Tooltip
                      title={lang.serviceFeeInformation}
                      enterTouchDelay={100}
                      leaveTouchDelay={6000}
                    >
                      <InfoIcon className={muiStyles.tooltip} />
                    </Tooltip>
                  )}
                </Typography>
                <Typography
                  variant={isDefaultTheme ? 'caption' : 'body2'}
                  color={'secondary'}
                >
                  {getPriceFormatted(pool.serviceFee)}{' '}
                  {getCurrencyFromCode(pool.currency)}
                </Typography>
              </Grid>
            )}
            {withCourier && config.features.courierSummary && (
              <Grid
                container={true}
                item={true}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant={'caption'} color={'secondary'}>
                  {lang.courierDelivery}
                </Typography>
                <Typography variant={'caption'} color={'secondary'}>
                  {COURIER_COST} {getCurrencyFromCode(pool.currency)}
                </Typography>
              </Grid>
            )}
            {giftWrap && (
              <Grid
                container={true}
                item={true}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant={'caption'} color={'secondary'}>
                  {lang.serviceFee}
                </Typography>
                <Typography variant={'caption'} color={'secondary'}>
                  {GIFT_WRAP_COST} {getCurrencyFromCode(pool.currency)}
                </Typography>
              </Grid>
            )}
            {isTransferTicket && (
              <Grid
                container={true}
                item={true}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant={'caption'} color={'secondary'}>
                  {lang.ticketTransferFee}
                </Typography>
                <Typography variant={'caption'} color={'secondary'}>
                  {getPriceFormatted(ticketTransferFee || 0)}{' '}
                  {getCurrencyFromCode(pool.currency)}
                </Typography>
              </Grid>
            )}
            <Grid
              container={true}
              item={true}
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Typography
                variant={isDefaultTheme ? 'caption' : 'body2'}
                color={'secondary'}
              >
                {lang.amount}
              </Typography>
              <Typography
                variant={isDefaultTheme ? 'caption' : 'body2'}
                color={'secondary'}
              >
                {pool.amount}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container={true}
            item={true}
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Typography variant={'body2'}>{lang.totalPrice}</Typography>
            <Typography variant={'body1'}>
              {summedPrice ? summedPrice.toFixed(2) : 0}{' '}
              {getCurrencyFromCode(pool.currency)}
            </Typography>
          </Grid>
        </Grid>
      )}
      {!pool && (
        <Grid
          container={true}
          item={true}
          direction={'row'}
          justifyContent={'space-between'}
        >
          <Typography variant={'caption'}>{lang.noData}</Typography>
        </Grid>
      )}
    </section>
  );
};

export default Pools;
