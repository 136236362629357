import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useMuiBreakpoints = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm')) && !isDesktop;
  const isMobile = !isDesktop && !isTablet;

  return {
    isDesktop,
    isMobile,
    isTablet,
  };
};

export default useMuiBreakpoints;
