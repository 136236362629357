import React from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import config from '@Config';
import _L from '@Misc/helpers/local';
import routes from '@Routes/routes';
import empikLogo from '@Styles/images/empik-bilety.svg';
import goingLogo from '@Styles/images/going.svg';
import * as LOCALS from '@Vars/constants';

import styles from './Brand.module.scss';
import { IBrandProps } from './Brand.types';

const LOGO_ALT_TEXT = _L(LOCALS.HELMET__AUTHOR);

const Brand = ({ isSticky }: IBrandProps) => (
  <h1
    className={styles.logoContainer}
    itemScope={true}
    itemType={'http://schema.org/Organization'}
  >
    <Link
      className={cn(styles.logoLink, isSticky && styles.sticky)}
      to={routes.index}
      itemProp={'url'}
    >
      <img
        className={styles.logo}
        src={config.theme.isEmpik ? empikLogo : goingLogo}
        alt={LOGO_ALT_TEXT}
        itemProp={'logo'}
      />
    </Link>
  </h1>
);

export default Brand;
