import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      maxWidth: '75rem',
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.border.radius,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(7),
      padding: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    iconContainer: {
      width: '6rem',
      height: '6rem',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
      borderRadius: '3rem',
      backgroundColor: theme.palette.primary.main,
    },
    iconWrapper: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(4),
      },
    },
    headerWrapper: {
      marginBottom: '4.5rem',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(6),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    textWrapper: {
      marginBottom: '2.5rem',
      padding: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
    },
  })
);

export default useStyles;
