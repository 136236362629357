import React, { FC, useEffect } from 'react';

import { PurchaseFollow } from '@Compo/PurchaseFollow';
import Helmet from '@Compo/helmet/components/PaymentSuccess';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import SuccessContent from '@Compo/summaries/Success';
import config from '@Config';

import { IPaymentSuccessProps } from './PaymentSuccess.types';

const PaymentSuccess: FC<IPaymentSuccessProps> = ({
  isEmbed,
  lang,
  transactionInfo,
  mounted,
  unmount,
  user,
}) => {
  useEffect(() => {
    mounted();

    return () => {
      unmount();
    };
  }, []);

  if (isEmbed) {
    return <SuccessContent />;
  }

  return (
    <MuiGrid>
      <Helmet pageName={lang.confirm} />
      {config.app.showUnfinishedFeatures && (
        <PurchaseFollow transaction={transactionInfo} user={user} />
      )}
      <FullTriangleBackground />
      <SuccessContent />
    </MuiGrid>
  );
};

export default PaymentSuccess;
