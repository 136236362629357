import React from 'react';

import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { Link as RouterLink } from 'react-router-dom';

import routes from '@Routes/routes';

import { IMuiBreadcrumbsProps } from './MuiBreadcrumbs.types';

const COLOR = 'inherit';

const MuiBreadcrumbs = ({ base, current }: IMuiBreadcrumbsProps) => (
  <Breadcrumbs aria-label={'breadcrumb'} separator={'\\'}>
    <Link color={COLOR} component={RouterLink} to={routes.index}>
      <HomeIcon fontSize={'small'} />
    </Link>
    {base.map((item) => (
      <Link
        color={COLOR}
        component={RouterLink}
        to={item.link}
        key={item.link}
        underline={'none'}
      >
        {item.name}
      </Link>
    ))}
    {current && (
      <Typography variant={'inherit'} color={COLOR}>
        {current.name}
      </Typography>
    )}
  </Breadcrumbs>
);

export default MuiBreadcrumbs;
