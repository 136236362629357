import { createSelector } from 'reselect';

import getData from './getData';
import getSelected from './getSelected';
import getSelectedSlot from './getSelectedSlot';

export const NO_PRICE = 0;

const calculatePrice = createSelector(
  [getData, getSelectedSlot, getSelected],
  (happening, slot, selected) => {
    const space = slot?.spaces.find(
      (_space) => _space.spaceId === selected.space
    );

    const summedPrice = selected.selectionsPerPriceType
      .map((item) => (item.numberOfPlayers && item.calculatedPrice) || NO_PRICE)
      .reduce((prev, curr) => prev + curr, NO_PRICE);

    if (happening?.calculatePricePerPerson) {
      return summedPrice;
    }

    return summedPrice;
  }
);

export default calculatePrice;
