import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import PayUContextProvider from '@Compo/functional/PayUContextProvider/PayUContextProvider.component';
import {
  IPayUContextFromDispatch,
  IPayUContextFromState,
} from '@Compo/functional/PayUContextProvider/PayUContextProvider.types';
import { setError } from '@Model/errors/actions';
import { translate } from '@Model/locale/selectors';
import { setCardCredentials } from '@Model/transaction/actions';

export const mapStateToProps = (
  state: _Store.IState
): IPayUContextFromState => ({
  lang: translate(state)('buy', 'paymentMethod'),
});

export const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPayUContextFromDispatch => ({
  setCardCredentials: (cardCredentials) =>
    dispatch(setCardCredentials(cardCredentials)),
  setError: (error) => dispatch(setError(error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayUContextProvider);
