import React from 'react';

import { Link } from 'react-router-dom';

import routes from '@Routes/routes';

import styles from './BasketEmpty.module.scss';

const CONTINUE_TEXT = 'Kontynuuj zakupy';
const EMPTY_TICKET_TEXT = 'Nie masz biletów w koszyku.';
const FIND_TEXT = 'Znajdź coś dla siebie.';

const BasketEmpty = () => {
  return (
    <div className={styles.content}>
      <div className={styles.wrapper}>
        <p>{EMPTY_TICKET_TEXT}</p>
        <p>{FIND_TEXT}</p>
      </div>
      <Link to={routes.events} className={styles.buttonWrapper}>
        <button type="button" className={styles.buyButton}>
          {CONTINUE_TEXT}
        </button>
      </Link>
    </div>
  );
};

export default BasketEmpty;
