import React from 'react';

import GiftCard from '@Compo/GiftCard';
import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import { IBreadcrumb } from '@Model/breadcrumb/types';
import routes from '@Routes/routes';

const GIFT_CARD_ACTIVATION_TEXT = 'Aktywuj kartę prezentową';

const breadcrumbs: IBreadcrumb[] = [
  {
    link: routes.giftCardActivation,
    name: GIFT_CARD_ACTIVATION_TEXT,
  },
];

const GiftCardPage = () => (
  <MuiGrid>
    <Helmet pageName={GIFT_CARD_ACTIVATION_TEXT} />
    <FullTriangleBackground />
    <MuiSectionContainer>
      <MuiBreadcrumbs base={breadcrumbs} />
      <GiftCard />
    </MuiSectionContainer>
  </MuiGrid>
);

export default GiftCardPage;
