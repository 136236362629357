import { getType } from 'typesafe-actions';

import { getAvailabilities } from '../../actions';
import { IAction, IAvailabilitiesData } from '../../types';

const initialState: IAvailabilitiesData = [];

const reducer = (
  state: IAvailabilitiesData = initialState,
  action: IAction
): IAvailabilitiesData => {
  switch (action.type) {
    case getType(getAvailabilities.success):
      return action.payload;
    case getType(getAvailabilities.failure):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
