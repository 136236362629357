/* tslint:disable no-bitwise */
import daysFromMonthBefore from './daysFromMonthBefore';
import getInfoAboutMonth from './getInfoAboutMonth';
import { IDay, IMonth } from './prepareArrayForMonth.types';

const ROW_COUNT = 6;
const COLUMN_COUNT = 7;
const DAYS_IN_MONTH = ROW_COUNT * COLUMN_COUNT;

function prepareArrayForMonth(
  month: number,
  year: number,
  currentDayHash: number
): IMonth {
  const arr: IDay[][] = [];

  // Iterators
  let i;
  let j;

  for (i = 0; i < ROW_COUNT; i++) {
    arr[i] = [];
  }

  const info = getInfoAboutMonth(month, year);

  const start = info.startsAt;
  const stop = info.startsAt + info.length;

  const daysFromMonthBeforeArray = daysFromMonthBefore(month, year, start);
  j = daysFromMonthBeforeArray.length;

  for (i = 0; i < j; i++) {
    arr[0][i] = {
      day: daysFromMonthBeforeArray[i],
      selectable: false,
    };
  }

  const basicHash = year * 10000 + month * 100;

  for (i = start, j = 1; i < stop; ++i, ++j) {
    const indexRow: number = (i / COLUMN_COUNT) >> 0;
    const indexColumn: number = i % COLUMN_COUNT;

    const hash = basicHash + j;

    arr[indexRow][indexColumn] = {
      day: j,
      hash,
      selectable: hash >= currentDayHash,
    };
  }

  for (j = 1; i < DAYS_IN_MONTH; ++i, ++j) {
    const indexRow: number = (i / COLUMN_COUNT) >> 0;
    const indexColumn: number = i % COLUMN_COUNT;

    arr[indexRow][indexColumn] = {
      day: j,
      selectable: false,
    };
  }

  return arr;
}

export default prepareArrayForMonth;
