import React from 'react';

import cn from 'classnames';

import styles from './Date.module.scss';
import { IDateProps } from './Date.types';

const Date = ({
  date,
  isLoading,
  customClassName,
  customFakeClassName,
}: IDateProps) => {
  if (isLoading || !date) {
    return <div className={cn(styles.fakeDate, customFakeClassName)} />;
  }

  return <h4 className={cn(styles.date, customClassName)}>{date}</h4>;
};

export default Date;
