import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import Activity from './Activity.component';
import { IActivityFromState } from './Activity.types';

const mapStateToProps = (state: _Store.IState): IActivityFromState => ({
  lang: translate(state)('breadcrumbs'),
});

export default connect<IActivityFromState, void, {}, _Store.IState>(
  mapStateToProps
)(Activity);
