import getInfoAboutMonth from './getInfoAboutMonth';

const daysFromMonthBefore = (
  month: number,
  year: number,
  startsAt: number
): number[] => {
  const info = getInfoAboutMonth(month - 1, year);
  const arr = [];

  let end = info.length;

  for (let i = startsAt - 1; i >= 0; --i, --end) {
    arr.push(end);
  }

  return arr.reverse();
};

export default daysFromMonthBefore;
