import { selectors } from 'goingapp-shared';
import { createSelector } from 'reselect';

import getData from './getData';
import getSelected from './getSelected';

const calculateDuration = createSelector(
  [getData, getSelected],
  (data, selected): number | null => selectors.calculateDuration(data, selected)
);

export default calculateDuration;
