const splitNumberToDayMonthYear = (
  value: number
): {
  day: number;
  month: number;
  year: number;
} => {
  const valueString = value.toString();

  return {
    day: parseInt(valueString.substr(6, 2), 10),
    month: parseInt(valueString.substr(4, 2), 10),
    year: parseInt(valueString.substr(0, 4), 10),
  };
};

export default splitNumberToDayMonthYear;
