import React, { useRef, useState } from 'react';

import cn from 'classnames';

import dateToHumanText from '@Misc/helpers/date/dateToHumanText';
import nearestWeekend from '@Misc/helpers/date/nearestWeekend';
import prepareArrayForMonth from '@Misc/helpers/date/prepareArrayForMonth';
import splitNumberToDayMonthYear from '@Misc/helpers/date/splitNumberToDayMonthYear';
import { thisMonth } from '@Misc/helpers/date/thisMonth';
import thisWeek from '@Misc/helpers/date/thisWeek';
import today from '@Misc/helpers/date/today';
import tomorrow from '@Misc/helpers/date/tomorrow';
import useOutsideClick from '@Misc/hooks/useOutsideClick';

import styles from './DateFilter.module.scss';
import { IDateFilterProps, IDateState } from './DateFilter.types';

const daysMap = ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'];

const monthsMap = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];

const initialState: IDateState = {
  from: null,
  to: null,
};

const BUTTON_TEXT = 'data';
const REMOVE_FILTERS_TEXT = 'wyczyść daty';
const SELECT_DATE_TEXT = 'wybierz datę lub zakres';
const ADD_DATE_TEXT = 'dodaj drugą datę, aby wybrać zakres';
const SHOW_TEXT = 'Pokaż';
const SHORTCUTS_TEXT = 'skróty:';
const TODAY_TEXT = 'dziś';
const TOMORROW_TEXT = 'jutro';
const WEEKEND_TEXT = 'weekend';
const WEEK_TEXT = 'tydzień';
const MONTH_TEXT = 'miesiąc';

const DateFilter = ({
  buttonClass,
  isBrowser,
  isMobile,
  removeDateFilter,
  searchDates,
  setDates,
  setLastFilter,
}: IDateFilterProps) => {
  const ref = useRef(null);
  const currentDateHash = today();
  const { day, month, year } = splitNumberToDayMonthYear(currentDateHash);
  const [show, toggle] = useState(false);
  const [displayMonth, switchMonth] = useState(month);
  const [displayYear, switchYear] = useState(year);
  const [selectedDates, selectDate] = useState<IDateState>(initialState);
  const monthData = prepareArrayForMonth(
    displayMonth,
    displayYear,
    currentDateHash
  );

  const filterDates = () => {
    selectDate({
      ...selectedDates,
      to: selectedDates.to ? selectedDates.to : selectedDates.from,
    });
    setDates({
      from: selectedDates.from,
      to: selectedDates.to ? selectedDates.to : selectedDates.from,
      lastFilterName: BUTTON_TEXT,
    });
    setLastFilter(BUTTON_TEXT);
    toggle(false);
  };

  const setToday = () => {
    const makeToday = {
      from: today(),
      to: today(),
      lastFilterName: BUTTON_TEXT,
    };

    selectDate(makeToday);
    setDates(makeToday);
    toggle(false);
  };

  const setTomorrow = () => {
    const makeTomorrow = {
      from: tomorrow(),
      to: tomorrow(),
      lastFilterName: BUTTON_TEXT,
    };

    selectDate(makeTomorrow);
    setDates(makeTomorrow);
    toggle(false);
  };

  const setWeekend = () => {
    const weekend = nearestWeekend();
    const makeWeekend = {
      from: weekend[0],
      to: weekend[1],
      lastFilterName: BUTTON_TEXT,
    };

    selectDate(makeWeekend);
    setDates(makeWeekend);
    toggle(false);
  };

  const setThisWeek = () => {
    const weekDays = thisWeek();
    const makeThisWeek = {
      from: weekDays[0],
      to: weekDays[1],
      lastFilterName: BUTTON_TEXT,
    };

    selectDate(makeThisWeek);
    setDates(makeThisWeek);
    toggle(false);
  };

  const setThisMonth = () => {
    const monthDates = thisMonth();
    const makeThisMonth = {
      from: monthDates[0],
      to: monthDates[1],
      lastFilterName: BUTTON_TEXT,
    };

    selectDate(makeThisMonth);
    setDates(makeThisMonth);
    toggle(false);
  };

  const clearFilters = () => {
    selectDate(initialState);
    removeDateFilter();
    toggle(false);
  };

  const nextMonth = () => {
    if (displayMonth + 1 > monthsMap.length - 1) {
      switchYear(displayYear + 1);
      switchMonth(0);
    } else {
      switchMonth(displayMonth + 1);
    }
  };

  const previousMonth = () => {
    if (displayMonth - 1 < 0) {
      switchYear(displayYear - 1);
      switchMonth(monthsMap.length - 1);
    } else {
      switchMonth(displayMonth - 1);
    }
  };

  const handleSelect = (dateHash: number) => {
    if (selectedDates.from && !selectedDates.to) {
      selectDate({
        ...selectedDates,
        to: dateHash,
      });
    } else if (selectedDates.from && selectedDates.to) {
      selectDate({
        from: dateHash,
        to: null,
      });
    } else {
      selectDate({
        ...selectedDates,
        from: dateHash,
      });
    }
  };

  const closeAction = () => {
    if (selectedDates.from && show) {
      filterDates();
    } else {
      toggle(!show);
    }
  };

  if (ref) {
    useOutsideClick(ref, show, () => toggle(false));
  }

  if (isBrowser) {
    document.body.style.overflowY = show && isMobile ? 'hidden' : 'scroll';
  }

  const calendarRows = monthData.map((row, index) => (
    <div className={styles.calendarRow} key={index}>
      {row.map((dayObject) => {
        const { from, to } = selectedDates;
        const { hash, selectable } = dayObject;
        const isToday = hash === currentDateHash;
        const differentMonth = !selectable;
        const isLaterDateSelected = !to && hash && from && hash < from;
        const isDateSelected = hash === from || hash === to;
        const isDateBetweenSelected =
          to && from && hash && hash > from && hash < to;

        return (
          <div
            className={cn(
              styles.day,
              isToday && styles.today,
              (differentMonth || isLaterDateSelected) && styles.disabled,
              (isDateSelected || isDateBetweenSelected) && styles.selected
            )}
            key={hash ? hash.toString() : `${index}-${dayObject.day}`}
            onClick={() => (hash ? handleSelect(hash) : null)}
          >
            {dayObject.day}
          </div>
        );
      })}
    </div>
  ));

  return (
    <div className={styles.dateFilterWrapper} ref={ref}>
      <div className={buttonClass}>
        <span onClick={closeAction}>{BUTTON_TEXT}</span>
        {(searchDates.from || searchDates.to) && (
          <i onClick={clearFilters}>✖︎</i>
        )}
      </div>
      {show && (
        <div className={styles.dateDropdown}>
          {isMobile && (
            <i className={styles.close} onClick={closeAction}>
              ✖︎
            </i>
          )}
          <div className={styles.shortcuts}>
            <span>{SHORTCUTS_TEXT}</span>
            <span className={styles.shortcut} onClick={setToday}>
              {TODAY_TEXT}
            </span>
            <span className={styles.shortcut} onClick={setTomorrow}>
              {TOMORROW_TEXT}
            </span>
            <span className={styles.shortcut} onClick={setWeekend}>
              {WEEKEND_TEXT}
            </span>
            <span className={styles.shortcut} onClick={setThisWeek}>
              {WEEK_TEXT}
            </span>
            <span className={styles.shortcut} onClick={setThisMonth}>
              {MONTH_TEXT}
            </span>
          </div>
          <div className={styles.monthSelect}>
            <i
              className={cn(styles.chevron, styles.left)}
              onClick={previousMonth}
            />
            <span
              className={styles.month}
            >{`${monthsMap[displayMonth]} ${displayYear}`}</span>
            <i className={styles.chevron} onClick={nextMonth} />
          </div>
          <div className={styles.calendarContainer}>
            <div className={styles.daysBar}>
              {daysMap.map((dayName) => (
                <div key={dayName}>{dayName}</div>
              ))}
            </div>
            <div className={styles.calendar}>{calendarRows}</div>
          </div>
          <p className={styles.addDates}>
            {selectedDates.from && !selectedDates.to
              ? ADD_DATE_TEXT
              : SELECT_DATE_TEXT}
          </p>
          {selectedDates.from && (
            <button className={styles.filterButton} onClick={filterDates}>
              {SHOW_TEXT}&nbsp;
              {dateToHumanText(selectedDates.from, selectedDates.to)}
            </button>
          )}
          {(searchDates.from || searchDates.to) && (
            <button className={styles.removeFilters} onClick={clearFilters}>
              {REMOVE_FILTERS_TEXT}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DateFilter;
