import React, { FC } from 'react';

import cn from 'classnames';

import { ISelectViewProps } from '@Compo/reusable/SelectView/SelectView.types';
import icons from '@Misc/helpers/icons';

import styles from './SelectView.module.scss';

const SelectView: FC<ISelectViewProps> = ({ tileView, switchView }) => {
  return (
    <span
      className={cn(
        styles.selectView,
        icons.viewIcons,
        tileView && icons.viewList
      )}
      onClick={switchView}
    />
  );
};

export default SelectView;
