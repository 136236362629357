import { LOCATION_CHANGE, getLocation } from 'connected-react-router';
import { of as of$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import getThemeFromPartnerId from '@Misc/helpers/getThemeFromPartnerId';
import { getSingleProduct } from '@Model/products/actions';
import routes from '@Routes/routes';

import { changeTheme } from './../actions';
import { getTheme } from './../selectors';

export const resetThemeWhenRouteChange: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] !==
          routes.buyActivity.split('/')[1] && getTheme(state) !== 'default'
    ),
    mergeMap$(() => {
      return of$(changeTheme('default'));
    })
  );
};

export const changeThemeWhenProductPinMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(getSingleProduct.success)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] ===
          routes.pinProducts.split('/')[1] &&
        getLocation(state).query.isDark !== 'true'
    ),
    mergeMap$(([action]) => {
      return of$(
        changeTheme(getThemeFromPartnerId(action.payload.partnerIdentity))
      );
    })
  );
};
