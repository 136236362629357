import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import _Store from '@Store';

import { getArtistPastEvents } from '@Model/artist/selectors';

import PastEvents from './PastEvents.component';
import { IPastEventsFromState } from './PastEvents.types';

const mapStateToProps = (state: _Store.IState): IPastEventsFromState => {
  return {
    pastEvents: getArtistPastEvents(state),
  };
};

export default withRouter(
  connect<IPastEventsFromState, void, {}, _Store.IState>(mapStateToProps)(
    PastEvents
  )
);
