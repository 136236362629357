import { connect } from 'react-redux';

import _Store from '@Store';

import Helmet from '@Compo/helmet/Helmet';
import { paymentSuccessPage } from '@Model/meta/selectors';
import { ITradeDoublerMeta } from '@Model/meta/types';

const mapStateToProps = (state: _Store.IState): ITradeDoublerMeta =>
  paymentSuccessPage(state);

export default connect<ITradeDoublerMeta, void, {}, _Store.IState>(
  mapStateToProps
)(Helmet);
