import React, { ComponentType } from 'react';

import { RouteComponentProps } from 'react-router';

import EmbedContainer from './Embed.container';

const Embed = (component: ComponentType<any>, module?: string) => {
  return (props: RouteComponentProps) => {
    return (
      <EmbedContainer
        module={module}
        Child={component}
        match={props.match}
        location={props.location}
        history={{
          action: props.history.action,
          length: props.history.length,
        }}
      />
    );
  };
};

export default Embed;
