import React from 'react';

import SendMailSuccess from '@Compo/SendMailSuccess';
import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';

const SEND_MAIL_SUCCESS_TEXT = 'Wyślij bilety mailem';

const SendMailSuccessPage = () => (
  <MuiGrid>
    <Helmet pageName={SEND_MAIL_SUCCESS_TEXT} />
    <FullTriangleBackground />
    <SendMailSuccess />
  </MuiGrid>
);

export default SendMailSuccessPage;
