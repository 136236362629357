import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `0.0625rem solid ${theme.palette.other.gray}`,
      borderRadius: '1.5rem',
      padding: '0.375rem 0.5rem',
      width: 'fit-content',
    },
    content: {
      color: theme.palette.other.gray,
    },
  })
);

export default useStyles;
