import { AxiosError } from 'axios';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import stepNames from '@Compo/EntryList/steps';

import {
  APPEND_TO_SUBMISSIONS_SUMMARY,
  ATTACH_SUBMISSION_FAILURE,
  ATTACH_SUBMISSION_REQUEST,
  ATTACH_SUBMISSION_SUCCESS,
  CHECK_AGREEMENT_EXIST,
  CHECK_AGREEMENT_EXIST_FAILURE,
  CHECK_AGREEMENT_EXIST_REQUEST,
  CHECK_AGREEMENT_EXIST_SUCCESS,
  CLEAR_FORMS_DATA,
  CLEAR_FORMS_ERRORS,
  COMPLETE_ATTACHING,
  CONFIRM_PERSONAL_DATA,
  FINALIZE_TRANSACTION,
  GENERATE_AND_SEND_SMS_CODE,
  GET_DATA_FROM_TICKET,
  GET_DATA_FROM_TICKET_FAILURE,
  GET_DATA_FROM_TICKET_REQUEST,
  GET_DATA_FROM_TICKET_SUCCESS,
  HANDLE_NEXT_STEP,
  HANDLE_PREV_STEP,
  PREPARE_SUBMISSIONS_TO_ATTACH,
  REGISTER_NEW_SUBMISSION_FAILURE,
  REGISTER_NEW_SUBMISSION_REQUEST,
  REGISTER_NEW_SUBMISSION_SUCCESS,
  REQUEST_TO_ATTACH_SUBMISSION,
  REQUEST_TO_CHECK_SUBMISSIONS_EXISTS,
  REQUEST_TO_REGISTER_NEW_SUBMISSION,
  RESET_FORM,
  SELECT_SUBMISSION,
  SEND_SMS_CODE_FAILURE,
  SEND_SMS_CODE_REQUEST,
  SEND_SMS_CODE_SUCCESS,
  SET_FORM_ERRORS,
  SET_FORM_VALUES,
  SET_IS_VIEW_ONLY,
  SET_PARENT_DATA,
  SKIP_TO_SELECTED_STEP,
  TICKET_SUBMISSIONS_COMPLETE,
  _CHECK_SUBMISSIONS_EXISTS_FAILURE,
  _CHECK_SUBMISSIONS_EXISTS_REQUEST,
  _CHECK_SUBMISSIONS_EXISTS_SUCCESS,
} from '../constants/constants';
import {
  ICheckSubmissionsExistsResponse,
  IDataFromTicketPayloadProps,
  IFormIoUserExistRequest,
  IFormValues,
  IParentData,
  IRegisterNewSubmissionRequestPayload,
  ISelectedSubmissionProps,
  ISetErrorsProps,
  ISubmissionAttachSuccessPayload,
  ISubmissionsSummary,
  ISubmissionsToAttachProps,
} from '../types';

export const requestToCheckSubmissionsExists = createStandardAction(
  REQUEST_TO_CHECK_SUBMISSIONS_EXISTS
)<string>();

export const setIsViewOnly = createStandardAction(SET_IS_VIEW_ONLY)<boolean>();

export const setFormValues =
  createStandardAction(SET_FORM_VALUES)<IFormValues>();

export const selectSubmission =
  createStandardAction(SELECT_SUBMISSION)<ISelectedSubmissionProps>();

export const requestToRegisterNewSubmission = createStandardAction(
  REQUEST_TO_REGISTER_NEW_SUBMISSION
)();

export const confirmPersonalData = createStandardAction(
  CONFIRM_PERSONAL_DATA
)();

export const checkSubmissionsExists = createAsyncAction(
  _CHECK_SUBMISSIONS_EXISTS_REQUEST,
  _CHECK_SUBMISSIONS_EXISTS_SUCCESS,
  _CHECK_SUBMISSIONS_EXISTS_FAILURE
)<string, ICheckSubmissionsExistsResponse, Error>();

export const registerNewSubmission = createAsyncAction(
  REGISTER_NEW_SUBMISSION_FAILURE,
  REGISTER_NEW_SUBMISSION_REQUEST,
  REGISTER_NEW_SUBMISSION_SUCCESS
)<IRegisterNewSubmissionRequestPayload, string, Error>();

export const clearFormsData = createStandardAction(CLEAR_FORMS_DATA)();

export const checkAgreementExist = createAsyncAction(
  CHECK_AGREEMENT_EXIST_REQUEST,
  CHECK_AGREEMENT_EXIST_SUCCESS,
  CHECK_AGREEMENT_EXIST_FAILURE
)<IFormIoUserExistRequest, ICheckSubmissionsExistsResponse, Error>();

// ##########################

export const completeAttaching = createStandardAction(COMPLETE_ATTACHING)();

export const setFormErrors =
  createStandardAction(SET_FORM_ERRORS)<ISetErrorsProps>();

export const setTicketSubmissionsComplete = createStandardAction(
  TICKET_SUBMISSIONS_COMPLETE
)<boolean>();

export const clearFormsErrors = createStandardAction(CLEAR_FORMS_ERRORS)();

export const appendToSubmissionsSummary = createStandardAction(
  APPEND_TO_SUBMISSIONS_SUMMARY
)<ISubmissionsSummary>();

export const finalizeTransaction = createStandardAction(FINALIZE_TRANSACTION)();

export const setParentData =
  createStandardAction(SET_PARENT_DATA)<IParentData>();

export const prepareSubmissionsToAttach = createStandardAction(
  PREPARE_SUBMISSIONS_TO_ATTACH
)<ISubmissionsToAttachProps>();

export const requestToCheckAgreementExists = createStandardAction(
  CHECK_AGREEMENT_EXIST
)<IFormIoUserExistRequest>();

export const requestToAttachSubmission = createStandardAction(
  REQUEST_TO_ATTACH_SUBMISSION
)<ISubmissionsToAttachProps>();

export const resetForm = createStandardAction(RESET_FORM)();

export const requestDataFromTicket =
  createStandardAction(GET_DATA_FROM_TICKET)<string>();

export const generateAndSendSmsCode = createStandardAction(
  GENERATE_AND_SEND_SMS_CODE
)<string>();

export const attachSubmission = createAsyncAction(
  ATTACH_SUBMISSION_REQUEST,
  ATTACH_SUBMISSION_SUCCESS,
  ATTACH_SUBMISSION_FAILURE
)<ISubmissionsToAttachProps, ISubmissionAttachSuccessPayload[], Error>();

export const sendSmsCode = createAsyncAction(
  SEND_SMS_CODE_REQUEST,
  SEND_SMS_CODE_SUCCESS,
  SEND_SMS_CODE_FAILURE
)<string, undefined, Error>();

export const handleNextStep =
  createStandardAction(HANDLE_NEXT_STEP)<stepNames>();

export const handlePrevStep = createStandardAction(HANDLE_PREV_STEP)();

export const skipToSelectedStep = createStandardAction(
  SKIP_TO_SELECTED_STEP
)<stepNames>();

export const getDataFromTicket = createAsyncAction(
  GET_DATA_FROM_TICKET_REQUEST,
  GET_DATA_FROM_TICKET_SUCCESS,
  GET_DATA_FROM_TICKET_FAILURE
)<string, IDataFromTicketPayloadProps, AxiosError<Error>>();
