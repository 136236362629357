import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { setSlot } from '@Model/tickets/actions';
import { getTimeSlots } from '@Model/tickets/selectors';

import TimeSlots from './TimeSlots.component';
import { ITimeSlotsFromDispatch, ITimeSlotsFromState } from './TimeSlots.types';

const mapStateToProps = (state: _Store.IState): ITimeSlotsFromState => {
  return {
    timeSlots: getTimeSlots(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITimeSlotsFromDispatch => {
  return {
    setSlot: (slot: string) => dispatch(setSlot(slot)),
  };
};

export default connect<
  ITimeSlotsFromState,
  ITimeSlotsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TimeSlots);
