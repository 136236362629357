export const SELECTION_PAGE_MOUNTED = 'Empik/selection/SELECTION_PAGE_MOUNTED';
export const SET_CURRENT_KEY = 'Empik/selection/SET_CURRENT_KEY';

// Private
export const _GET_REQUEST = 'Empik/selection/_GET_REQUEST';
export const _GET_SUCCESS = 'Empik/selection/_GET_SUCCESS';
export const _GET_FAILURE = 'Empik/selection/_GET_FAILURE';

export const _GET_SELECTION_SLUG_REQUEST =
  'Empik/selection/_GET_SELECTION_SLUG_REQUEST';
export const _GET_SELECTION_SLUG_SUCCESS =
  'Empik/selection/_GET_SELECTION_SLUG_SUCCESS';
export const _GET_SELECTION_SLUG_FAILURE =
  'Empik/selection/_GET_SELECTION_SLUG_FAILURE';
