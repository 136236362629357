import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';
import { getSelectedLocale, translate } from '@Model/locale/selectors';
import { poolsMounted } from '@Model/pools/actions';
import {
  getPools,
  getSelectedTickets,
  shouldShowSeatsIo,
} from '@Model/pools/selectors';
import * as CONSTS from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import Pools from './Pools.component';
import { IPoolsFromDispatch, IPoolsFromState } from './Pools.types';

const mapStateToProps = (state: _Store.IState): IPoolsFromState => ({
  arePoolsLoading: getLoading(CONSTS.POOLS)(state),
  isDesktop: isDesktop(state),
  isTransactionLoading: getLoading(CONSTS.TRANSACTION)(state),
  lang: translate(state)('buy', 'pools'),
  eventLang: translate(state)('event'),
  pools: getPools(state),
  selectedLang: getSelectedLocale(state),
  selectedPools: getSelectedTickets(state),
  shouldShowSeatsIo: shouldShowSeatsIo(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPoolsFromDispatch => {
  return {
    poolsMounted: () => dispatch(poolsMounted()),
  };
};

export default connect<IPoolsFromState, IPoolsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Pools);
