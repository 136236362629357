import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import getArtistImages from '@Model/images/selectors/getArtistImages';
import { getModule, getParams } from '@Model/internalRouter/selectors';
import * as MODULES from '@Routes/modules';
import {
  IArtistMatchParams,
  IEventMatchParams,
  IPlaceMatchParams,
} from '@Routes/types';

import {
  getImages,
  getImagesByCloudinaryPrefixAndSlug,
  loadImages,
} from './../actions';
import { getEventImages, getPlaceImages } from './../selectors';

export const requestForImages: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(loadImages)),
    filter$(() => !!config.features.gallery),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const module = getModule(state);
      let key = '';
      let checkIfGotImages = true;
      if (module === MODULES.PLACE) {
        const slugs = getParams(state) as IPlaceMatchParams;
        key = `${config.images.placePrefix}${slugs.slug}`;
        checkIfGotImages = !!getPlaceImages(state);
      } else if (module === MODULES.EVENT || module === MODULES.ACTIVITY) {
        const slugs = getParams(state) as IEventMatchParams;
        key = `${config.images.eventPrefix}${slugs.eventSlug}`;
        checkIfGotImages = !!getEventImages(state);
      } else if (module === MODULES.ARTIST) {
        const slugs = getParams(state) as IArtistMatchParams;
        key = `${config.images.artistPrefix}${slugs.slug}`;
        checkIfGotImages = !!getArtistImages(state);
      }
      if (checkIfGotImages) {
        return EMPTY$;
      }

      return of$(getImages.request(key));
    })
  );
};

export const getImagesByCloudinaryParams: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getImagesByCloudinaryPrefixAndSlug)),
    mergeMap$((action) => {
      const { prefix, slug, rundateSlug } = action.payload;
      const key = `${prefix}${slug}${rundateSlug ? `/${rundateSlug}` : ''}`;

      return of$(getImages.request(key));
    })
  );
};

export const fetchImagesFromCloudinary: _Store.IEpic = (
  action$,
  state$,
  { cloudinaryApi }
) => {
  return action$.pipe(
    filter$(isActionOf([getImages.request])),
    mergeMap$((action) => {
      return from$(cloudinaryApi.getImagesByTag(action.payload)).pipe(
        map$((_data) => {
          const images = _data as string[];

          return getImages.success({ images, key: action.payload });
        }),
        catchError$((error: Error) => of$(getImages.failure(error)))
      );
    })
  );
};
