import React, { useContext, useEffect, useRef, useState } from 'react';

import { Button } from '@material-ui/core';
import cn from 'classnames';
import { base64decode } from 'nodejs-base64';
import { Link } from 'react-router-dom';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import Pools from '@Compo/Pools';
import Products from '@Compo/Products';
import AdditionalField from '@Compo/buying/components/AdditionalField/';
import Insurance from '@Compo/buying/components/Insurance';
import PaymentMethod from '@Compo/buying/components/PaymentMethod';
import RedirectForCardPayment from '@Compo/buying/components/RedirectForCardPayment';
import EventDetailsBox from '@Compo/eventBoxes/Details';
import SummaryBox from '@Compo/eventBoxes/Summary';
import config from '@Config';
import autocompleteForm from '@Misc/helpers/autocompleteForm';
import convertBinaryToUnicode from '@Misc/helpers/convertBinaryToUnicode';
import scrollTo from '@Misc/helpers/scrollTo';
import routes from '@Routes/routes';

import { IFormsRef } from '../Forms/Forms.types';
import RedirectForFreePayment from '../RedirectForFreePayment';
import * as CONST from './../../constants/constants';
import ClientData from './..//ClientData';
import Agreements from './../Agreements';
import AuthId from './../AuthId';
import EmpikCardNumber from './../EmpikCardNumber';
import Forms from './../Forms';
import PickUpWay from './../PickUpWay';
import RedirectForECard from './../RedirectForECard';
import RedirectForPayU from './../RedirectForPayU';
import RedirectForSale from './../RedirectForSale';
import classes from './FormLayout.classes';
import styles from './FormLayout.module.scss';
import { IFormLayoutProps } from './FormLayout.types';

const CLIENT_DATA_ID = 'client-data-id';

const FormLayout = (props: IFormLayoutProps) => {
  const {
    allRequiredFormsAreSubmitted,
    autoComplete,
    autoFill,
    currentBasketData,
    customTerms,
    handleSubmit,
    handleTokenizeCard,
    hasDependency,
    isAnyPaymentAvailable,
    isAnyPoolSelected,
    isAnyProductSelected,
    isBasketPay,
    isCourierAvailable,
    isCourierRequired,
    isDataHydratedTransaction,
    isDesktop,
    isEmbed,
    isError,
    isMobile,
    isProductsPay,
    isTablet,
    isTransactionLoading,
    isUserPremium,
    isSelectionValid,
    isValid,
    isWebview,
    lang,
    products,
    values,
    selectedTickets,
    selectedTheme,
    setFieldValue,
    specialEvent,
    isAnyPoolSelectedHasDetailed,
    isAnyPoolSelectedHasForm,
    isAnySelectedProductHasPassType,
    isSeatsIoForm,
    touched,
    cardPaymentForm,
    payuInstance,
    eventAddPaymentInfo,
    event,
    isAnyProductSelectedHasShipmentRequired,
    poolsData,
  } = props;

  useEffect(() => {
    setDependencyCode(Boolean(values.discount?.length));
  }, [values.discount]);

  const [isFormInvalid, setFormInvalid] = useState(false);
  const [isDependencyCode, setDependencyCode] = useState(false);
  const formsRef = useRef<IFormsRef | null>(null);

  const GIFT_CARDS_RUN_DATES = [2353795, 2357465];
  const isGiftCard = GIFT_CARDS_RUN_DATES.includes(poolsData?.id || 0);

  const showDependencyInput =
    (isAnyPoolSelected && hasDependency && isDependencyCode) ||
    (isAnyPoolSelected && !hasDependency);

  const isDisabled =
    ((!isAnyPoolSelected ||
      isTransactionLoading ||
      isAnyPaymentAvailable ||
      !isSelectionValid) &&
      !isBasketPay &&
      !isAnyProductSelected) ||
    isError;

  const isDefaultTheme = selectedTheme === 'default';

  const showAddressForm = values.pickUpWay === CONST.PICK_UP_WAY_POST;

  const BagState = useContext(clearContext);

  const basketItems = BagState?.basketItems || [];

  const isDefaultCurrency = ![...currentBasketData, ...basketItems].some(
    (item) => item.currency !== config.app.defaultCurrency
  );

  const showInsurance =
    isDefaultCurrency && config.app.onlineSale && !isProductsPay;

  const isAnyBasketItemHasCourierRequired = BagState?.basketItems.find(
    (item) => item.courierRequired
  )?.courierRequired;

  const isAnyBasketItemHasDetailedRequired = BagState?.basketItems.some(
    (item) => item.additionalFields
  );

  const showCartButtons =
    !isEmbed &&
    (!isAnyPoolSelectedHasForm ||
      (isAnyPoolSelectedHasForm && allRequiredFormsAreSubmitted));

  useEffect(() => {
    if (!isDefaultCurrency) {
      setFieldValue('prepaidCheckbox', false);
      setFieldValue('discountCheckbox', false);
    }
  }, [isDefaultCurrency]);

  useEffect(() => {
    setFieldValue('customTerms', {});
  }, [BagState?.basketItems]);

  const handleFormSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    setFormInvalid(!isValid);
    const paymentMethod = config.app.onlineSale
      ? values.paymentMethod?.type
      : undefined;

    if (isAnyPoolSelectedHasForm && formsRef.current) {
      e?.preventDefault();
      formsRef.current.submitForm();
    } else {
      handleSubmit(e);
    }

    if (isValid) {
      eventAddPaymentInfo({
        buttonLabel: lang.buyAndPay,
        paymentMethod,
      });
      const copyValues = Object.assign({}, values);

      delete copyValues.customTerms;
      delete copyValues.terms;
      delete copyValues.hasDependency;
      delete copyValues.emailAdditional;
      delete copyValues.firstnameAdditional;
      delete copyValues.lastnameAdditional;
      delete copyValues.dependencyCode;
      delete copyValues.paymentMethod;
      delete copyValues.pickUpWay;
      delete copyValues.deliveryCity;
      delete copyValues.deliveryFirstname;
      delete copyValues.deliveryLastname;
      delete copyValues.deliveryLocalStreet;
      delete copyValues.deliveryNumberStreet;
      delete copyValues.deliveryPhone;
      delete copyValues.deliveryStreet;
      delete copyValues.deliveryZip;
      delete copyValues.discount;
      delete copyValues.discountCheckbox;
      delete copyValues.discountCode;
      delete copyValues.prepaidCard;
      delete copyValues.prepaidCheckbox;
      delete copyValues.empikPremiumNumber;
      delete copyValues.giftWrapper;
      delete copyValues.userHavePremiumCheckbox;
      delete copyValues.isInsurance;
      delete copyValues.isInsuranceAdultCheckbox;
      delete copyValues.insuranceBirthDate;
      delete copyValues.insuranceIsLivingInPoland;
      delete copyValues.isAccidentInsurance;

      try {
        window.localStorage.setItem(
          'buy-form',
          JSON.stringify({ values: copyValues })
        );
      } catch (noSSR) {}
    }
  };

  /*
   *
   * below: If data param comes from new mobile app (isWebview === true) we need to convert binary string to unicode
   * If it comes from the expo form, we don't do that - otherwise the app would crash (and vice versa).
   *
   */
  const autoCompleteDecoded = autoComplete
    ? isWebview
      ? convertBinaryToUnicode(base64decode(autoComplete))
      : base64decode(autoComplete)
    : '{}';
  const autoCompleteData = JSON.parse(autoCompleteDecoded);
  const shouldShowBasketButton: boolean =
    autoFill && autoCompleteData.showBasket !== undefined
      ? autoCompleteData.showBasket
      : true;

  const autocomplete = () =>
    autocompleteForm(autoFill, customTerms, setFieldValue);

  const handleAddToBasket = () => {
    if (BagState && currentBasketData) {
      currentBasketData.forEach((basketItem) => {
        if (basketItem.hasDependency) {
          BagState.addToBag({
            ...basketItem,
            artists: event?.artists,
            partnerId: event?.partnerId,
            partnerName: event?.partnerName,
            tags: event?.tags,
            placeFriendly: event?.placeFriendly,
            placeId: event?.placeId,
            dependencyCode: Boolean(values.discount.length)
              ? values.discount
              : '',
          });
        } else {
          BagState.addToBag({
            ...basketItem,
            artists: event?.artists,
            partnerId: event?.partnerId,
            partnerName: event?.partnerName,
            tags: event?.tags,
            placeFriendly: event?.placeFriendly,
            placeId: event?.placeId,
          });
        }
      });
      BagState.handleUserData(values);
    }
  };

  let step = 1;

  return (
    <div className={cn(styles.buyForm, classes.buyForm)}>
      <div className={cn(styles.twoColumns, classes.twoColumns)}>
        {(isMobile || isTablet) && (
          <EventDetailsBox
            isBasketPay={isBasketPay}
            isProductsPay={isProductsPay}
          />
        )}

        {isDesktop && (
          <div className={cn(styles.fixedBox, classes.fixedBox)}>
            <SummaryBox
              isBasketPay={isBasketPay}
              isProductsPay={isProductsPay}
              isInsurance={values.isInsurance}
            />
          </div>
        )}

        <div className={cn(styles.form, classes.form)}>
          {!isBasketPay && (
            <>
              {!isProductsPay ? (
                <Pools specialEventCopy={specialEvent} number={step++} />
              ) : (
                <Products number={step++} isProductPay={isProductsPay} />
              )}

              {showCartButtons && (
                <div className={styles.buttonsWrapper}>
                  <a
                    onClick={(e) => scrollTo(e, CLIENT_DATA_ID)}
                    className={cn(
                      styles.buttonWrapper,
                      (!config.features.basket || isSeatsIoForm) &&
                        styles.fullWidth
                    )}
                  >
                    <button
                      type="button"
                      className={cn(
                        styles.buyButton,

                        classes.buyButton,
                        showDependencyInput
                          ? styles.selectedPool
                          : styles.noSelectedPool
                      )}
                    >
                      {showDependencyInput && lang.buttonPay}
                    </button>
                  </a>

                  {!isAnyProductSelected &&
                    (shouldShowBasketButton || !autoFill) &&
                    ((config.features.basket && !isSeatsIoForm) ||
                      isWebview) && (
                      <Link
                        to={routes.basket}
                        className={cn(
                          styles.buttonWrapper,
                          showDependencyInput
                            ? styles.selectedPool
                            : styles.noSelectedPool
                        )}
                      >
                        <button
                          type="button"
                          className={cn(
                            styles.buyButton,
                            classes.buyButton,
                            styles.basketButton,
                            showDependencyInput
                              ? styles.selectedPool
                              : styles.noSelectedPool
                          )}
                          disabled={isDisabled}
                          onClick={handleAddToBasket}
                        >
                          {showDependencyInput && lang.buttonAdd}
                        </button>
                      </Link>
                    )}
                </div>
              )}
            </>
          )}

          <form onSubmit={handleFormSubmit}>
            {config.app.onlineSale && (
              <div id={CLIENT_DATA_ID}>
                {!isDataHydratedTransaction && (
                  <ClientData
                    specialEventCopy={specialEvent}
                    isBasketPay={isBasketPay}
                    isDisabled={isUserPremium || isDisabled}
                    number={step++}
                    showPremiumCardInput={values.userHavePremiumCheckbox}
                    showInvoiceForm={values.invoiceCheckbox}
                    setFieldValue={setFieldValue}
                    autocompleteForm={autocomplete}
                    isBasketItems={
                      !!BagState?.basketItems?.length &&
                      BagState?.basketItems?.length > 0
                    }
                    handleTokenizeCard={handleTokenizeCard}
                    payuInstance={payuInstance}
                    isProductsPay={isProductsPay}
                  />
                )}
                {isAnyPoolSelectedHasForm && (
                  <Forms
                    ref={formsRef}
                    number={step++}
                    firstName={values.firstname}
                    lastName={values.lastname}
                    handleSubmit={handleSubmit}
                  />
                )}

                {!isGiftCard && showInsurance && (
                  <Insurance
                    isDisabled={isDisabled}
                    values={values}
                    step={step++}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    isBasket={isBasketPay || !!BagState?.basketItems.length}
                  />
                )}
                {!!products.length && !isProductsPay && !isBasketPay && (
                  <Products
                    number={step++}
                    isProductPay={isProductsPay}
                    isDisabled={isDisabled}
                  />
                )}

                <PickUpWay
                  specialEventCopy={specialEvent}
                  courierAvailable={isCourierAvailable}
                  courierRequired={
                    isAnyBasketItemHasCourierRequired ||
                    isCourierRequired ||
                    (!isAnySelectedProductHasPassType &&
                      isAnyProductSelectedHasShipmentRequired)
                  }
                  isDisabled={isDisabled}
                  number={step++}
                  showAddressForm={showAddressForm}
                  isProduct={isAnyProductSelectedHasShipmentRequired}
                />
                <Agreements
                  specialEventCopy={specialEvent}
                  isBasketPay={isBasketPay}
                  isDisabled={isDisabled}
                  isDetailedPurchase={
                    isAnyPoolSelectedHasDetailed ||
                    isAnyBasketItemHasDetailedRequired
                  }
                  number={step++}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
            )}
            {!config.app.onlineSale && (
              <div id={CLIENT_DATA_ID}>
                <EmpikCardNumber
                  isDisabled={isDisabled}
                  number={step++}
                  open={values.userHavePremiumCheckbox}
                  withoutHeader={false}
                />
                {config.app.showUnfinishedFeatures && isCourierRequired && (
                  <PickUpWay
                    specialEventCopy={specialEvent}
                    courierAvailable={isCourierAvailable}
                    courierRequired={
                      isAnyBasketItemHasCourierRequired ||
                      isCourierRequired ||
                      isAnyProductSelectedHasShipmentRequired
                    }
                    isDisabled={isDisabled}
                    number={step++}
                    showAddressForm={showAddressForm}
                    isProduct={isAnyProductSelectedHasShipmentRequired}
                  />
                )}
                {config.app.showUnfinishedFeatures &&
                  selectedTickets.map((ticket) => {
                    let additionalFieldStep = 0;

                    return [...Array(ticket.amount)].map((value, index) => {
                      if (ticket.additionalFields) {
                        return (
                          <div className={styles.onsiteAdditionalFields}>
                            <AdditionalField
                              key={`additional_${index}`}
                              ticket={{
                                id: ticket.poolId,
                                poolName: ticket.poolName,
                              }}
                              index={index}
                              step={additionalFieldStep++}
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          </div>
                        );
                      }
                    });
                  })}
                <AuthId isDisabled={isDisabled} number={step++} />
              </div>
            )}

            {config.app.onlineSale && (
              <PaymentMethod
                isDisabled={isDisabled}
                isBasketPay={isBasketPay}
                step={step++}
                cardPaymentForm={cardPaymentForm}
              />
            )}

            {(isMobile || isTablet) && (
              <div className={cn(styles.mobileSummary, classes.mobileSummary)}>
                <h3 className={cn(styles.header, classes.header)}>
                  {lang.summary}
                </h3>
                <SummaryBox
                  isBasketPay={isBasketPay}
                  isInsurance={values.isInsurance}
                />
              </div>
            )}

            <div className={styles.buyWrapper}>
              {isFormInvalid && (
                <div className={styles.errorContainer}>{lang.formInvalid}</div>
              )}
              {isDefaultTheme ? (
                <button
                  className={cn(
                    styles.buyButton,
                    classes.buyButton,
                    styles.halfButton
                  )}
                  data-test-id={'form-layout-buyAndPay'}
                  type="submit"
                  disabled={isDisabled}
                  id="payButton"
                >
                  {lang.buyAndPay}
                </button>
              ) : (
                <Button
                  data-test-id={'form-layout-buyAndPay'}
                  type="submit"
                  disabled={isDisabled}
                  id="payButton"
                  size={'large'}
                  variant={'contained'}
                  color={'primary'}
                  fullWidth={true}
                >
                  {lang.buyAndPay}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>

      <RedirectForCardPayment />
      <RedirectForECard />
      <RedirectForFreePayment />
      <RedirectForPayU />
      <RedirectForSale />
    </div>
  );
};

export default FormLayout;
