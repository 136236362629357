import { connect } from 'react-redux';

import _Store from '@Store';

import { isBrowser } from '@Model/__base__/selectors';
import { getSizes, isDesktop } from '@Model/app/selectors';

import ArtistSlider from './ArtistSlider.component';
import {
  IArtistSliderFromState,
  IArtistSliderOwnProps,
} from './ArtistSlider.types';

const mapStateToProps = (state: _Store.IState): IArtistSliderFromState => {
  return {
    isBrowser: isBrowser(state),
    isDesktop: isDesktop(state),
    windowWidth: getSizes(state).width,
  };
};

export default connect<
  IArtistSliderFromState,
  void,
  IArtistSliderOwnProps,
  _Store.IState
>(mapStateToProps)(ArtistSlider);
