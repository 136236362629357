import axios, { AxiosResponse, CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import { ISelectionResponse } from '@Services/$selection-api/types';
import {
  ICheckEmailBody,
  ICheckEmailResponse,
} from '@Services/$sendgrid-api/types';

class SendgridApi {
  public cancelTokenSendgrid?: CancelTokenSource;

  private emailCheckUrl = `${config.api.baseUrl}user/address-email/check`;

  public checkEmail = async (
    body: ICheckEmailBody
  ): Promise<ICheckEmailResponse> =>
    new Promise((resolve, reject) => {
      this.cancelTokenSendgrid = axios.CancelToken.source();

      return axios
        .post(
          this.emailCheckUrl,
          body,
          withCacheHeader({
            cancelToken: this.cancelTokenSendgrid.token,
          })
        )
        .then(getData)
        .then((response: ICheckEmailResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(new Error(error)));
        });
    });

  public cancelSendgrid() {
    if (this.cancelTokenSendgrid) {
      this.cancelTokenSendgrid.cancel();
      this.cancelTokenSendgrid = undefined;
    }
  }
}

export default new SendgridApi();
