import React, { JSXElementConstructor, useContext } from 'react';

import { Formik } from 'formik';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import getBasketTerms from '@Misc/helpers/getBasketTerms';

import { makeValidationSchema } from './../Online/Online.selectors';
import { IBuyingOnlineFormValues } from './../Online/Online.types';
import FormLayout from './../components/FormLayout';
import { IBuyingOnsiteProps } from './Products.types';

const Basket = ({
  checkout,
  errors,
  buyAndPay,
  initialValues,
  isDataHydratedTransaction,
}: IBuyingOnsiteProps) => {
  const state = useContext(clearContext);

  const basketCustomTerms = state?.basketItems
    ? getBasketTerms(state?.basketItems)
    : [];

  const validationSchema = makeValidationSchema(
    basketCustomTerms,
    errors,
    checkout,
    !isDataHydratedTransaction
  );

  const catchSaveData = (data: IBuyingOnlineFormValues) => {
    buyAndPay({
      basketItems: state?.basketItems ? state.basketItems : [],
      data,
      onDone: state?.clearBasket ? state.clearBasket : () => null,
    });
  };

  return (
    <Formik
      children={(props) => <FormLayout {...props} isProductsPay={true} />}
      enableReinitialize={true}
      initialValues={initialValues(basketCustomTerms)}
      onSubmit={catchSaveData}
      validationSchema={validationSchema}
      validateOnMount={false}
    />
  );
};

export default Basket as JSXElementConstructor<unknown>;
