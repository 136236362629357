import { Selector } from 'reselect';

import _Store from '@Store';

import { INotificationReducer } from './../types';

const get: Selector<_Store.IState, INotificationReducer> = (state) =>
  state.notifications;

export default get;
