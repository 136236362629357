import React from 'react';

import Happening from '@Compo/Happening';
import Helmet from '@Compo/helmet/components/BuyPage';
import BuyPageBackground from '@Compo/layout/BuyPageBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import config from '@Config';

const HappeningPage = () => (
  <MuiGrid>
    <Helmet />
    <MuiSectionContainer>
      {config.theme.isEmpik && <BuyPageBackground />}
      <Happening />
    </MuiSectionContainer>
  </MuiGrid>
);

export default HappeningPage;
