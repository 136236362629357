/**
 * Function that return localized description for slot sales
 *
 * @param {IHappeningDescription[]} descriptions - array of descriptions
 * @param {string} selectedlanguage - selected language code
 *
 * @return {IHappeningDescription} localized description
 */
import { IHappeningDescription } from '@Model/happening/types';

const DEFAULT_LANGUAGE = 'pl';

const getLocalizedDescription = (
  descriptions: IHappeningDescription[] | undefined,
  selectedlanguage?: string
) => {
  return (
    descriptions?.find(
      (description) =>
        description.language.toLocaleLowerCase() === selectedlanguage
    ) ||
    descriptions?.find(
      (description) =>
        description.language.toLocaleLowerCase() === DEFAULT_LANGUAGE
    ) ||
    null
  );
};

export default getLocalizedDescription;
