import {
  ISectionsComponentBanner,
  ISectionsComponentLink,
} from '@Model/pages/types/sections';
import routes from '@Routes/routes';

/**
 * Function to prepare url fot hot spots
 *
 * @param {ISectionsComponentLink} hotspot - to which slug refers
 *
 * @return {string} - link for hotspot
 */
const prepareHotSpotUri = (
  hotspot: ISectionsComponentLink | ISectionsComponentBanner
) => {
  const eventUri = routes.event.split(':')[0];

  return hotspot.link ?? eventUri + hotspot.slug;
};

export default prepareHotSpotUri;
