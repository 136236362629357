import { Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ICtaButtonStyleProps } from '@Compo/reusable/CtaButton/CtaButton.types';
import isColorDark from '@Misc/helpers/isColorDark';

const useStyles = ({
  backgroundColor,
  isBorder,
  borderWidth,
  borderColor,
}: ICtaButtonStyleProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        backgroundColor,
        border: isBorder
          ? `${borderWidth}px solid ${
              borderColor ? borderColor : theme.palette.text.primary
            }`
          : undefined,
        color: isColorDark(backgroundColor)
          ? theme.palette.grey['50']
          : theme.palette.grey['900'],
        marginBottom: '4rem',
      },
    })
  );

export default useStyles;
