const BOX_HEIGHT = 174;

const calculateAngleForTriangleBox = (sizes: {
  width: number;
  height: number;
}) => {
  return Math.floor(Math.atan(BOX_HEIGHT / sizes.width) * (180 / Math.PI) + 1);
};

export default calculateAngleForTriangleBox;
