import { connect } from 'react-redux';

import _Store from '@Store';

import { getLocation } from '@Model/router/selectors';

import SectionLead from './LeadSection.component';
import {
  ILeadSectionFromState,
  ILeadSectionOwnProps,
} from './LeadSection.types';

const mapStateToProps = (state: _Store.IState): ILeadSectionFromState => {
  return {
    isDarkMode: getLocation(state).query?.isDark === 'true',
  };
};

export default connect<
  ILeadSectionFromState,
  null,
  ILeadSectionOwnProps,
  _Store.IState
>(mapStateToProps)(SectionLead);
