import React from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import prepareUrlForRoute from '@Misc/helpers/prepareUrlForRoute';
import routes from '@Routes/routes';

import styles from './Place.module.scss';
import { IPlaceProps } from './Place.types';

const Place = ({
  customClassName,
  isLoading,
  placeSlug,
  place,
}: IPlaceProps) => {
  if (isLoading || !place) {
    return <div className={styles.fakePlace} />;
  }

  return (
    <h4 className={cn(styles.value, customClassName)}>
      {placeSlug && (
        <Link to={prepareUrlForRoute(routes.place, placeSlug)}>{place}</Link>
      )}
      {!placeSlug && place}
    </h4>
  );
};

export default Place;
