import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { eventAddPaymentInfo } from '@Model/analytics/actions';
import { isDesktop, isMobile, isTablet } from '@Model/app/selectors';
import { getBasketData } from '@Model/basket/selector';
import { isError } from '@Model/errors/selectors';
import {
  getCustomTerms,
  getEvent,
  isCourierAvailable,
  specialEvent,
} from '@Model/event/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import {
  allRequiredFormsAreSubmitted,
  getSelectedTickets,
  isAnyPoolSelected,
  isAnyPoolSelectedHasDetailed,
  isAnyPoolSelectedHasForm,
  isCourierRequired,
  isDataHydratedTransaction,
  isPoolHasDependency,
} from '@Model/pools/selectors';
import getPoolsData from '@Model/pools/selectors/getPoolsData';
import { getIsUserPremium } from '@Model/premium/selectors';
import {
  getProducts,
  isAnyProductSelected,
  isAnyProductSelectedHasShipmentRequired,
  isAnySelectedProductHasPassType,
} from '@Model/products/selectors';
import { getLocation } from '@Model/router/selectors';
import * as CONSTS from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { getTheme } from '@Model/theme/selectors';
import { getIsSelectionValid } from '@Model/tickets/selectors';
import {
  isECardPaymentAvailable,
  isPayUPaymentAvailable,
} from '@Model/transaction/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  allRequiredFormsAreSubmitted: allRequiredFormsAreSubmitted(state),
  autoComplete: getLocation(state).query?.data,
  autoFill: !!getLocation(state).query?.data,
  currentBasketData: getBasketData(state),
  customTerms: getCustomTerms(state),
  event: getEvent(state),
  hasDependency: isPoolHasDependency(state),
  isAnyPaymentAvailable:
    isECardPaymentAvailable(state) || isPayUPaymentAvailable(state),
  isAnyPoolSelected: isAnyPoolSelected(state),
  isAnyPoolSelectedHasDetailed: isAnyPoolSelectedHasDetailed(state),
  isAnyPoolSelectedHasForm: isAnyPoolSelectedHasForm(state),
  isAnyProductSelected: isAnyProductSelected(state),
  isAnySelectedProductHasPassType: isAnySelectedProductHasPassType(state),
  isAnyProductSelectedHasShipmentRequired:
    isAnyProductSelectedHasShipmentRequired(state),
  isCourierAvailable: isCourierAvailable(state),
  isCourierRequired: isCourierRequired(state),
  isDataHydratedTransaction: isDataHydratedTransaction(state),
  isDesktop: isDesktop(state),
  isEmbed: !!getIframeParams(state),
  isError: isError(state),
  isMobile: isMobile(state),
  isSelectionValid: getIsSelectionValid(state),
  isTablet: isTablet(state),
  isTransactionLoading: getLoading(CONSTS.TRANSACTION)(state),
  isSeatsIoForm: !!getPoolsData(state).seatsIoPublicKey,
  isUserPremium: getIsUserPremium(state),
  isWebview: getLocation(state)?.query?.isWebview === 'true',
  lang: translate(state)('buy', 'formLayout'),
  products: getProducts(state),
  selectedTheme: getTheme(state),
  selectedTickets: getSelectedTickets(state),
  specialEvent: specialEvent(state),
  poolsData: getPoolsData(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  eventAddPaymentInfo: (value) => dispatch(eventAddPaymentInfo(value)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
