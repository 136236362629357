import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.border.radius,
      marginTop: theme.spacing(4),
    },
    detailsContainer: {
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    detailsDivider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    divider: {
      marginTop: theme.spacing(3),
    },
    buttomSummary: {
      padding: theme.spacing(5),
    },
    summaryText: {
      alignSelf: 'end',
    },
  })
);

export default useStyles;
