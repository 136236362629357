import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getEvent, getRundateSlug } from '@Model/event/actions';
import { getEventData } from '@Model/tickets/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenEventFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getEvent.request,
        getEventData.request,
        getRundateSlug.request,
      ])
    ),
    map$(() => setLoading(CONSTS.EVENT))
  );
};

export const setLoaderWhenEventFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getEvent.success,
          getEvent.failure,
          getEventData.success,
          getEventData.failure,
          getRundateSlug.success,
          getRundateSlug.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(CONSTS.EVENT))
  );
};
