import React from 'react';

import DualLink from '@Compo/reusable/DualLink';

import styles from './Partners.module.scss';
import { IPartnersProps } from './Partners.types';

const SECTION_TITLE_TEXT = 'Partnerzy';
const SECTION_ID = 'partners';

const Partners = ({ partners, sectionTitleScheme }: IPartnersProps) => {
  return (
    <>
      <h2 className={styles.sectionTitle} style={sectionTitleScheme}>
        {SECTION_TITLE_TEXT}
      </h2>
      <ul className={styles.partnersWrapper}>
        {partners.map((partner, id) => {
          return (
            <li className={styles.partnersElement} key={id} id={SECTION_ID}>
              <div className={styles.partnersContainer}>
                {partner.slug && (
                  <DualLink to={partner.slug}>
                    <img src={partner.imageUrl} alt={partner.title} />
                  </DualLink>
                )}
                {!partner.slug && (
                  <img src={partner.imageUrl} alt={partner.title} />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Partners;
