import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const useStyles = (isMobile: boolean) =>
  makeStyles((theme) => {
    return createStyles({
      button: {
        '&:last-of-type': {
          marginBottom: 0,
        },
        marginBottom: '1rem',
      },
      disabled: {
        cursor: 'initial',
        opacity: 0.6,
        pointerEvents: 'none',
      },
      header: {
        marginBottom: '2rem',
      },
      logo: {
        height: '1.5rem',
        marginLeft: '1rem',
      },
      noPadding: {
        padding: '0!important',
      },
      outerContainer: {
        marginTop: '3.75rem',
      },
      root: {
        background: theme.palette.background.paper,
        borderRadius: '1rem',
        padding: isMobile ? '1.75rem 1.25rem' : '2.375rem 1.875rem',
      },
    });
  });

export default useStyles;
