export interface IStorageObject {
  [key: string]: any;
}

class LocalStorage {
  /**
   * Function setting localStorage item with optional expiration time in milliseconds.
   *
   * @param storeKey - string key under which to store the data.
   * @param data - data object to be stored.
   * @param ttl - optional expiration time in milliseconds:
   *
   * 60000 - 1 minute
   * 3600000 - 1 hour
   */
  public setItem(storeKey: string, data: IStorageObject, ttl?: number) {
    const now = new Date();
    const expiration = ttl ? { expiry: now.getTime() + ttl } : {};

    try {
      window.localStorage.setItem(
        storeKey,
        JSON.stringify({
          ...data,
          ...expiration,
        })
      );
    } catch (noSSR) {}
  }

  public getItem(storeKey: string): IStorageObject | null {
    try {
      const storage = window.localStorage.getItem(storeKey);

      if (!storage) {
        return null;
      }

      const storageItem = JSON.parse(storage);

      if (storageItem.expiry) {
        const now = new Date();

        if (now.getTime() > storageItem.expiry) {
          window.localStorage.removeItem(storeKey);

          return null;
        }

        delete storageItem.expiry;
      }

      return storageItem;
    } catch (error) {
      return null;
    }
  }

  public deleteItem(storeKey: string) {
    try {
      window.localStorage.removeItem(storeKey);
    } catch (noSSR) {}
  }
}

export default new LocalStorage();
