import React, { FC, useContext, useState } from 'react';

import { Typography } from '@material-ui/core';
import cn from 'classnames';
import { Field } from 'formik';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import Checkbox from '@Compo/forms/Checkbox';
import FormField from '@Compo/forms/FormField';
import * as MODULES from '@Routes/modules';

import styles from './Invoice.module.scss';
import { IInvoiceProps } from './Invoice.types';

const Invoice: FC<IInvoiceProps> = ({
  selectedTickets,
  open,
  lang,
  module,
  isDefaultCurrency,
}) => {
  const [showLegalNote, changeLegalNoteVisibility] = useState(false);

  const legalNoteToggleClicked = () => {
    changeLegalNoteVisibility(!showLegalNote);
  };

  const basket = useContext(clearContext);
  const isInvoiceAvailable =
    module === MODULES.HAPPENING ||
    module === MODULES.EMBED_HAPPENING ||
    module === MODULES.EMBED_BUY_PRODUCTS
      ? true
      : selectedTickets.length > 0
      ? selectedTickets.some((ticket) => ticket.price && ticket.price > 0)
      : basket
      ? basket.basketItems.some((item) => item.poolPrice && item.poolPrice > 0)
      : false;

  return (
    <div className={styles.wrapper}>
      <div className={styles.checkbox}>
        <Field
          component={Checkbox}
          name="invoiceCheckbox"
          id="invoiceCheckbox"
          disabled={!isInvoiceAvailable}
        >
          {lang.iWantInvoice}
        </Field>
      </div>
      <div className={styles.invoiceLegalText}>
        {lang.legalA}
        {showLegalNote && lang.legalB}
        <button type="button" onClick={legalNoteToggleClicked}>
          <Typography variant={'body1'} color={'primary'}>
            {showLegalNote ? lang.less : lang.more}
          </Typography>
        </button>
      </div>

      {open && (
        <div className={cn(styles.toggledFields)}>
          <label className={styles.label} htmlFor="invoiceName">
            <FormField
              id="invoiceName"
              name="invoiceName"
              placeholder={lang.name}
              type="text"
            />
          </label>
          <label className={styles.label} htmlFor="invoiceNip">
            <FormField
              id="invoiceNip"
              name="invoiceNip"
              placeholder={lang.nip}
              type="text"
            />
          </label>
          <label className={styles.label} htmlFor="invoiceAddress">
            <FormField
              id="invoiceAddress"
              name="invoiceAddress"
              placeholder={lang.address}
              type="text"
            />
          </label>
          <div className={styles.cities}>
            <label className={styles.label} htmlFor="invoicePost">
              <FormField
                id="invoicePost"
                name="invoicePost"
                placeholder={lang.zip}
                type="text"
              />
            </label>
            <label className={styles.label} htmlFor="invoiceCity">
              <FormField
                id="invoiceCity"
                name="invoiceCity"
                placeholder={lang.city}
                type="text"
              />
            </label>
          </div>
          {!isDefaultCurrency && (
            <label className={styles.label} htmlFor="invoiceCountry">
              <FormField
                id="invoiceCountry"
                name="invoiceCountry"
                placeholder={lang.country}
                type="text"
              />
            </label>
          )}
        </div>
      )}
    </div>
  );
};

export default Invoice;
