import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getLocations } from '@Model/locations/actions';
import { getSelectedLocation } from '@Model/locations/selectors';
import { PagesEnum } from '@Model/pages/constants/pages';
import { getActivities } from '@Model/pages/selectors';

import Activities from './Activities.component';
import {
  IActivitiesFromDispatch,
  IActivitiesFromState,
} from './Activities.types';

const mapStateToProps = (state: _Store.IState): IActivitiesFromState => ({
  structure: getActivities(state),
  userLocation: getSelectedLocation(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IActivitiesFromDispatch => ({
  mounted: () => dispatch(getLocations(PagesEnum.activities)),
});

export default connect<
  IActivitiesFromState,
  IActivitiesFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Activities);
