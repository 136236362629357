import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { mounted } from '@Model/mail/actions';

import SendMailSuccess from './SendMailSuccess.component';
import {
  ISendMailSuccessFromDispatch,
  ISendMailSuccessFromState,
} from './SendMailSuccess.types';

const mapStateToProps = (state: _Store.IState): ISendMailSuccessFromState => ({
  lang: translate(state)('sendMailSuccess'),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISendMailSuccessFromDispatch => ({
  mounted: () => dispatch(mounted()),
});

export default withRouter(
  connect<
    ISendMailSuccessFromState,
    ISendMailSuccessFromDispatch,
    {},
    _Store.IState
  >(
    mapStateToProps,
    mapDispatchToProps
  )(SendMailSuccess)
);
