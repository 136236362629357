import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  eventAddToCartHappening,
  eventRemoveFromCartHappening,
} from '@Model/analytics/actions';
import {
  generateIdempotencyKey,
  setNumberOfPlayers,
  setOnlyOnePriceType,
} from '@Model/happening/actions';
import {
  calculateCapacity,
  calculateDuration,
  getData,
  getSelected,
} from '@Model/happening/selectors';
import { translate } from '@Model/locale/selectors';
import { getTheme } from '@Model/theme/selectors';

import PriceTypesPicker from './PriceTypesPicker.component';
import {
  IPriceTypesPickerFromDispatch,
  IPriceTypesPickerFromState,
} from './PriceTypesPicker.types';

const mapStateToProps = (state: _Store.IState): IPriceTypesPickerFromState => {
  return {
    activityDuration: calculateDuration(state),
    capacity: getData(state)?.maxNumberOfPeople || calculateCapacity(state),
    isCalculatedPerPerson: getData(state)?.calculatePricePerPerson,
    lang: translate(state)('happening', 'priceTypes'),
    selectedSlot: getSelected(state).slot,
    selectedDay: getSelected(state).day,
    selections: getSelected(state).selections,
    selectionsPerPriceType: getSelected(state).selectionsPerPriceType,
    theme: getTheme(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPriceTypesPickerFromDispatch => ({
  generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
  setOnlyOnePrice: (value) => dispatch(setOnlyOnePriceType(value)),
  setPeopleCount: (value) => dispatch(setNumberOfPlayers(value)),
  eventAddToCartHappening: (params) =>
    dispatch(eventAddToCartHappening(params)),
  eventRemoveFromCartHappening: (params) =>
    dispatch(eventRemoveFromCartHappening(params)),
});

export default connect<
  IPriceTypesPickerFromState,
  IPriceTypesPickerFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PriceTypesPicker);
