import React, { FC, useContext, useEffect } from 'react';

import { Formik } from 'formik';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import { IFormLayoutProps } from '@Compo/buying/components/FormLayout/FormLayout.types';
import { PayUContext } from '@Compo/functional/PayUContextProvider/PayUContextProvider.component';
import config from '@Config';
import getBasketTerms from '@Misc/helpers/getBasketTerms';

import FormLayout from './../components/FormLayout';
import { makeValidationSchema } from './Online.selectors';
import { IBuyingOnlineFormValues, IBuyingOnlineProps } from './Online.types';

const Online: FC<IBuyingOnlineProps> = ({
  buyAndPay,
  checkout,
  customTerms,
  errors,
  eventSlug,
  rundateSlug,
  initialValues,
  isDataHydratedTransaction,
  isEmbed,
  isEnglishEmbed,
  isWebview,
  mounted,
  queue,
  updateBasketState,
  cardPaymentForm,
  currentBasketData,
}) => {
  useEffect(() => {
    mounted();
  }, []);
  const state = useContext(clearContext);
  const { handleTokenizeCard, payuInstance } = useContext(PayUContext);

  const basketItems = state?.basketItems || [];
  const loadBasket = state?.basketItems && !isEmbed;
  const basketCustomTerms = loadBasket ? getBasketTerms(basketItems) : [];
  const seatsIoBasketItems = basketItems.filter(
    (item) => item.seats && item.seats.length > 0
  );
  const isDefaultCurrency = ![...currentBasketData, ...basketItems].some(
    (item) => item.currency !== config.app.defaultCurrency
  );

  const validationSchema = makeValidationSchema(
    [...customTerms, ...basketCustomTerms],
    errors,
    checkout,
    !isDataHydratedTransaction,
    isEnglishEmbed,
    isDefaultCurrency
  );

  const catchSaveData = async (data: IBuyingOnlineFormValues) => {
    if (handleTokenizeCard && payuInstance) {
      const tokenize = await handleTokenizeCard(payuInstance);

      if (tokenize === 'ERROR') {
        return;
      }
    }

    buyAndPay({
      basketItems,
      data,
      onDone: state?.clearBasket ? state.clearBasket : () => null,
    });
  };

  useEffect(() => {
    try {
      /*
       * https://goingapp.atlassian.net/browse/WEAPP-2768
       */
      if (
        queue === 'queuegoingsite' &&
        !isWebview &&
        config.app.onlineSale &&
        !window.location.href.includes('queue')
      ) {
        const finalUrl = `${config.app.queueUrl}/kup-bilety/${eventSlug}/${rundateSlug}`;

        if (rundateSlug && !isEmbed) {
          window.location.assign(finalUrl);
        } else if (isEmbed) {
          window.top?.postMessage(JSON.stringify({ queue: true }), '*');
        }
      }
    } catch (noWindowObjectBecauseSsrOrEmbed) {}
  }, [rundateSlug, queue]);

  useEffect(() => {
    updateBasketState(basketItems);
  }, [basketItems]);

  useEffect(() => {
    /*
     *
     * TODO: REMOVE WHEN SEATS.IO SUPPORT FOR BASKET IS DONE
     *
     * */

    try {
      if (seatsIoBasketItems && seatsIoBasketItems?.length > 0) {
        seatsIoBasketItems.map((item) => state?.removeFromBag(item.id));
      }
    } catch (e) {}
  }, [seatsIoBasketItems?.length]);

  return (
    <Formik
      children={(props: IFormLayoutProps) => (
        <FormLayout
          {...props}
          cardPaymentForm={cardPaymentForm}
          handleTokenizeCard={handleTokenizeCard}
          payuInstance={payuInstance}
        />
      )}
      initialValues={initialValues(basketCustomTerms)}
      onSubmit={catchSaveData}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={false}
    />
  );
};

export default Online;
