import { createSelector } from 'reselect';

import get from './get';

const getNotifications = createSelector(
  [get],
  (notifications) => notifications.notifications
);

export default getNotifications;
