import React from 'react';

import Faq from '@Compo/reusable/Faq';
import invertIfGrayScaleColor from '@Misc/helpers/invertIfGrayScaleColor';
import isColorDark from '@Misc/helpers/isColorDark';

import styles from './FaqBrick.module.scss';
import { IFaqBrickProps } from './FaqBrick.types';

const FaqBrick = ({
  faq,
  id,
  isLoading,
  isDarkMode,
  color,
  background,
}: IFaqBrickProps) => {
  const isBgDark =
    isColorDark(background) ||
    isColorDark(
      isDarkMode && background ? invertIfGrayScaleColor(background) : background
    );

  return (
    <div className={styles.faq} id={id}>
      <Faq isBgDark={isBgDark} color={color} faq={faq} />
    </div>
  );
};

export default FaqBrick;
