import React, { useEffect } from 'react';

import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MainPageTop from '@Compo/layout/MainPageTop';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import StaticContentWrapper from '@Compo/layout/StaticContentWrapper';

const DISCOUNT_TEXT = 'Kod rabatowy';

const Discount = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://goingapp.pl/plugins/going/embed/assets/js/out/gi.min.js';
    script.setAttribute('data-cfasync', 'false');
    document.body.appendChild(script);
  }, []);

  return (
    <MuiGrid>
      <Helmet pageName={DISCOUNT_TEXT} />
      <FullTriangleBackground />
      <MuiSectionContainer>
        <MainPageTop title={DISCOUNT_TEXT} />
        <StaticContentWrapper>
          <div id="going_frame" />
        </StaticContentWrapper>
      </MuiSectionContainer>
    </MuiGrid>
  );
};

export default Discount;
