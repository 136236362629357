import { Selector } from 'reselect';

import _Store from '@Store';

/* TODO: move discount logic out of happening reducer */

const get: Selector<_Store.IState, string | null> = (state) =>
  state.happening.selected.discount;

export default get;
