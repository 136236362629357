import { createSelector } from 'reselect';

import _Store from '@Store';

import { ICard } from '@Compo/reusable/Card/Card.types';

import { IEventReducer } from './../types';
import get from './get';

const getPlaceEvents = createSelector<_Store.IState, IEventReducer, ICard[]>(
  [get],
  (event) => event.placeEvents
);

export default getPlaceEvents;
