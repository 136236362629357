import { getType } from 'typesafe-actions';

import { updateBasketStateFromContext } from '@Model/basket/actions';
import { IAction, IBasketReducer } from '@Model/basket/types';

const initialState: IBasketReducer = [];

const reducer = (state: IBasketReducer = initialState, action: IAction) => {
  switch (action.type) {
    case getType(updateBasketStateFromContext):
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
