import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { informAboutErrorWhenSomethingWrong } from '@Model/errors/actions';
import { getIframeParams } from '@Model/internalRouter/selectors';
import { getLocation } from '@Model/router/selectors';

import ErrorBasic from './ErrorBasic.component';
import {
  IErrorBasicFromDispatch,
  IErrorBasicFromState,
} from './ErrorBasic.types';

function mapStateToProps(state: _Store.IState): IErrorBasicFromState {
  return {
    isEmbed: !!getIframeParams(state),
    location: getLocation(state),
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IErrorBasicFromDispatch => ({
  informAboutError: () => dispatch(informAboutErrorWhenSomethingWrong()),
});

export default connect<
  IErrorBasicFromState,
  IErrorBasicFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBasic);
