import React, { useEffect } from 'react';

import { Button, Grid, Icon, Typography } from '@material-ui/core';
import { Update } from '@material-ui/icons';
import cn from 'classnames';

import getShortTime from '@Misc/helpers/dateTime/getShortTime';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import useUpdateEffect from '@Misc/hooks/useUpdateEffect';

import useStyles from './Upsell.styles';
import { IUpsellProps } from './Upsell.types';

const Upsell = ({
  calculatePrice,
  isSelectedSlotLastInCurrentDay,
  lang,
  mounted,
  selected,
  toggleUpsellState,
  generateIdempotencyKey,
  selectedTheme,
}: IUpsellProps) => {
  const { isDesktop } = useMuiBreakpoints();
  const upsellFromState = selected.upsell?.isSelected;

  const calculateUpsellForAllTicketTypes = () =>
    calculatePrice({
      discountCode: selected.discount || undefined,
      upsell: upsellFromState,
    });

  const toggleUpsell = () => {
    toggleUpsellState(!upsellFromState);
    calculateUpsellForAllTicketTypes();
    generateIdempotencyKey();
  };

  const endTime = getShortTime(selected.upsell?.endTime || '');
  const isDisabled = selected.space < 1;
  const isUpsellCalculated =
    selected.upsell?.price && selected.upsell?.price > 0;

  useUpdateEffect(() => {
    mounted();
  }, [selected.slot, selected.space, selected.totalPrice]);

  useEffect(() => {
    if (isSelectedSlotLastInCurrentDay) {
      toggleUpsellState(false);
    }
  }, [isSelectedSlotLastInCurrentDay]);

  const muiStyles = useStyles();
  const isDefaultTheme = selectedTheme === 'default';

  return (
    <Grid
      container={true}
      direction={isDesktop ? 'row' : 'column'}
      justifyContent={'space-between'}
      className={cn(muiStyles.root, isDisabled && muiStyles.disabled)}
      spacing={4}
    >
      <Grid item={true} md={2} xs={12} className={muiStyles.center}>
        <Icon fontSize={'large'} className={muiStyles.iconContainer}>
          <Update className={muiStyles.icon} fontSize={'large'} />
        </Icon>
      </Grid>
      <Grid
        item={true}
        container={true}
        direction={'column'}
        justifyContent={'center'}
        md={6}
        xs={12}
      >
        <Grid item={true} className={isDesktop ? '' : muiStyles.center}>
          <Typography variant={isDefaultTheme ? 'body1' : 'h6'}>
            {lang.getAdditionalSlot}
          </Typography>
        </Grid>
        <Grid container={true}>
          <Grid
            item={true}
            xs={12}
            lg={9}
            className={isDesktop ? '' : muiStyles.center}
          >
            <Typography
              variant={'body2'}
            >{`${lang.upsellDuration}${endTime}`}</Typography>
          </Grid>
          <Grid
            item={true}
            xs={12}
            lg={3}
            className={isDesktop ? '' : muiStyles.center}
          >
            <Typography variant={'h5'}>
              + {isUpsellCalculated ? selected.upsell?.price : 0} zł
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item={true}
        justifyContent={'flex-end'}
        container={true}
        xs={12}
        md={4}
        className={cn(
          isDesktop && muiStyles.center,
          muiStyles.button,
          isDesktop && muiStyles.upsellButton
        )}
      >
        <Button
          variant={upsellFromState ? 'contained' : 'outlined'}
          color={'primary'}
          size={'large'}
          disableElevation={true}
          fullWidth={!isDesktop}
          onClick={toggleUpsell}
        >
          {upsellFromState ? lang.added : lang.add}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Upsell;
