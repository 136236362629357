import {
  LOCATION_CHANGE,
  getLocation,
  getSearch,
  push,
} from 'connected-react-router';
import queryString from 'query-string';
import { EMPTY, from as from$, fromEvent, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { setSizes } from '@Model/app/actions';
import { getEvent } from '@Model/event/actions';
import { IGetEventByIdSuccessPayload } from '@Model/event/types';
import { loadImages } from '@Model/images/actions';
import { getPools } from '@Model/pools/actions';
import routes from '@Routes/routes';

import {
  captureIframeEvent,
  embedLoaded,
  getRundateSlug,
  initEmbed,
  locationChange,
  redirectParentTo,
} from './../actions';
import { EmbedActions, allowedActions } from './../constants/embedActions';
import { getModule } from './../selectors';

const WAITING_FOR_PONG = 5000;
// tslint:disable-next-line:prefer-const
let redirectWhenNotEmbed: NodeJS.Timeout;

export const initEmbededPage: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider }
) => {
  return action$.pipe(
    filter$(isActionOf(initEmbed)),
    tap$(
      () =>
        (redirectWhenNotEmbed = setTimeout(
          () => window.location.replace('/error'),
          WAITING_FOR_PONG
        ))
    ),
    mergeMap$((action) => {
      fromEvent(window, 'message').subscribe((event) => {
        iframeProvider.addEventListenerMethod(
          event as MessageEvent,
          action.payload
        );
      });

      iframeProvider.pingMethod();

      return EMPTY;
    })
  );
};

export const establishIframeConnection: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$((action) => action.type === EmbedActions.PONG),
    tap$(() => clearTimeout(redirectWhenNotEmbed)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search = queryString.parse(getSearch(state));

      if (search.error) {
        return of$(push(routes.paymentFail));
      } else if (search.transactionId) {
        return of$(push(routes.paymentSuccess));
      }

      return of$(embedLoaded());
    })
  );
};

export const loadFormDataForEmbed: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$((action) => action.type === EmbedActions.PONG),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search = queryString.parse(getSearch(state));
      const searchFormData = search.data as string;

      if (searchFormData) {
        try {
          window.localStorage.setItem(
            'buy-form',
            `{"values":${atob(searchFormData)}}`
          );
        } catch (noSSR) {}
      }

      return EMPTY;
    })
  );
};

export const captureIframeEventWhenPostMessage: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(captureIframeEvent)),
    mergeMap$((action) => {
      const { type, payload } = action.payload;
      const isAllowedAction = allowedActions.includes(type);

      if (isAllowedAction) {
        return of$({ type: EmbedActions[type], payload });
      }

      return EMPTY;
    })
  );
};

export const runResizeMethodWhenSetDevice: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider }
) => {
  return action$.pipe(
    filter$(isActionOf(setSizes)),
    mergeMap$((action) => {
      const { height } = action.payload;

      iframeProvider.runResizeMethod((height || 100) + 'px');

      return EMPTY;
    })
  );
};

export const redirectParentToWhenAction: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider }
) => {
  return action$.pipe(
    filter$(isActionOf(redirectParentTo)),
    mergeMap$((action) => {
      iframeProvider.runRedirectParentMethod(action.payload);

      return EMPTY;
    })
  );
};

export const redirectEventWhenLocationChange: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] ===
        routes.pinBuyId.split('/')[1]
    ),
    mergeMap$(([_, state]) => {
      return of$(
        getRundateSlug.request(
          Number(getLocation(state).pathname.split('/')[2])
        )
      );
    })
  );
};

export const getRundateSlugWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { eventsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getRundateSlug.request)),
    mergeMap$((action) => {
      return from$(eventsApi.getEventById(action.payload)).pipe(
        map$((data: IGetEventByIdSuccessPayload) => {
          return getRundateSlug.success(data);
        }),
        catchError$((error: Error) =>
          of$(getRundateSlug.failure(error), push(routes.events))
        )
      );
    })
  );
};

export const redirectEventWhenRundateSlugSuccess: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(getRundateSlug.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const params = {
        eventSlug: action.payload.event.slug,
        rundateSlug: action.payload.slug,
      };
      const module = getModule(state);

      return of$(
        locationChange({ module, params }),
        loadImages(),
        getPools.request(params),
        getEvent.request()
      );
    })
  );
};
