import React, { useEffect, useState } from 'react';

import { spacing } from '@/config/mui';
import { Grid, Typography, useTheme } from '@material-ui/core';
import cn from 'classnames';

import { IHappeningProps } from '@Compo/Happening/Happening.types';
import PriceTypes from '@Compo/Happening/components/PriceTypes/';
import Products from '@Compo/Happening/components/Products/Products.container';
import TimeSlots from '@Compo/Happening/components/TimeSlots';
import classes from '@Compo/buying/components/FormLayout/FormLayout.classes';
import RedirectForFreePayment from '@Compo/buying/components/RedirectForFreePayment';
import RedirectForPayU from '@Compo/buying/components/RedirectForPayU';
import RedirectForSale from '@Compo/buying/components/RedirectForSale/';
import EventDetailsBox from '@Compo/eventBoxes/Details';
import SimpleLoading from '@Compo/reusable/SimpleLoading';
import config from '@Config';

import styles from './Happening.module.scss';
import useStyles from './Happening.styles';
import Calendar from './components/Calendar';
import ExtendedSlot from './components/ExtendedSlot';
import HappeningBuyForm from './components/HappeningBuyForm/';
import HappeningBuyFormOnsite from './components/HappeningBuyFormOnsite/';
import HappeningSummary from './components/HappeningSummary';
import MultipleSlotModal from './components/MultipleSlotModal';
import Spaces from './components/Spaces';
import Upsell from './components/Upsell';

const Happening = ({
  areAnyProductsAvailable,
  calculatePricePerPerson,
  canReserve,
  happeningEndDate,
  happeningSpaces,
  happeningStartDate,
  isEmbed,
  isActivityEventLoading,
  isHappeningLoading,
  isLoadingPrice,
  isMobile,
  isSelectedSlotLastInCurrentDay,
  mounted,
  mountProducts,
  params,
  selectedSpace,
  slotHasUpsell,
  selectedTheme,
  hasExtendedSlot,
  lang,
}: IHappeningProps) => {
  let step = 1;
  const [allHappeningPrices, setAllHappeningPrices] = useState<string[]>([]);

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const isNotCalculatedPerPersonAndHasOnePriceType =
    !calculatePricePerPerson && allHappeningPrices.length === 1;

  const showUpsell =
    slotHasUpsell && !isSelectedSlotLastInCurrentDay && !hasExtendedSlot;

  useEffect(() => {
    mounted();
  }, []);

  useEffect(() => {
    if (canReserve) {
      mountProducts();
    }
  }, [canReserve, selectedSpace]);

  useEffect(() => {
    if (!isEmbed) {
      try {
        window.top?.scrollTo(-1, 0);
      } catch (noSSR) {}
    }

    if (!isHappeningLoading) {
      const newAllHappeningPrices: string[] = [];
      happeningSpaces?.map((space) =>
        space.rulePrice.map((priceRule) =>
          priceRule.prices.map(
            (price) =>
              !newAllHappeningPrices.includes(price.type) &&
              newAllHappeningPrices.push(price.type)
          )
        )
      );
      setAllHappeningPrices(newAllHappeningPrices);
    }
  }, [isHappeningLoading]);

  if (isHappeningLoading || isActivityEventLoading) {
    return <SimpleLoading />;
  }

  const isDefaultTheme = selectedTheme === 'default';

  return (
    <div
      className={cn(
        styles.buyForm,
        classes.buyForm,
        isLoadingPrice && styles.disabled
      )}
    >
      <MultipleSlotModal />
      <div className={cn(styles.twoColumns, classes.twoColumns)}>
        {isMobile && <EventDetailsBox />}
        {!isMobile && (
          <div className={cn(styles.fixedBox, classes.fixedBox)}>
            <HappeningSummary />
          </div>
        )}
        <div className={cn(styles.form, classes.form)}>
          <section className={styles.content}>
            <Grid container={true} spacing={6}>
              <Grid item={true} xs={12}>
                <Grid container={true} spacing={4}>
                  <Grid item={true} xs={12}>
                    {calculatePricePerPerson && (
                      <Typography variant={'h4'}>
                        {step++}. {lang.selectNumberOfPlayers}
                      </Typography>
                    )}
                    {!calculatePricePerPerson &&
                      !isNotCalculatedPerPersonAndHasOnePriceType && (
                        <Typography variant={'h4'}>
                          {step++}. {lang.selectTicketType}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item={true} xs={12}>
                    <div
                      className={cn(
                        !isDefaultTheme && muiStyles.withoutPadding
                      )}
                    >
                      <PriceTypes />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item={true} xs={12}>
                <Grid container={true} spacing={4}>
                  <Grid item={true} xs={12}>
                    <Typography variant={'h4'}>
                      {step++}. {lang.selectDateAndTime}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    {selectedTheme === 'default' ? (
                      <div
                        className={cn(
                          styles.box,
                          styles.calendarBox,
                          muiStyles.root
                        )}
                      >
                        <h4>{lang.date}</h4>
                        <Calendar
                          happeningStartDate={happeningStartDate}
                          happeningEndDate={happeningEndDate}
                        />

                        <h4>{lang.time}</h4>
                        <TimeSlots />
                      </div>
                    ) : (
                      <Grid container={true} spacing={spacing.medium}>
                        <Grid item={true} lg={6} xs={12}>
                          <Calendar
                            happeningStartDate={happeningStartDate}
                            happeningEndDate={happeningEndDate}
                          />
                        </Grid>
                        <Grid item={true} lg={6} xs={12}>
                          <TimeSlots />
                        </Grid>
                      </Grid>
                    )}
                    <ExtendedSlot />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item={true} xs={12}>
                <Grid container={true} spacing={4}>
                  <Grid item={true} xs={12}>
                    <Typography variant={'h4'}>
                      {step++}. {lang.selectSpace}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Spaces />
                  </Grid>
                </Grid>
              </Grid>

              {showUpsell && (
                <Grid item={true} xs={12}>
                  <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12}>
                      <Typography variant={'h4'}>
                        {step++}. {lang.upsell}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Upsell />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {canReserve && areAnyProductsAvailable && (
                <Grid item={true} xs={12}>
                  <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12}>
                      <Typography variant={'h4'}>
                        {step++}. {lang.relatedProducts}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Products />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </section>
          {config.app.onlineSale ? (
            <HappeningBuyForm step={step} />
          ) : (
            <HappeningBuyFormOnsite step={step} />
          )}
        </div>

        <RedirectForFreePayment />
        <RedirectForPayU />
        <RedirectForSale />
      </div>
    </div>
  );
};

export default Happening;
