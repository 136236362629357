import React, { FC } from 'react';

import { Grid, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import cn from 'classnames';

import Description from '@Compo/Pools/components/Description';
import Buttons from '@Compo/Products/components/Buttons';
import config from '@Config';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';
import productIconEmpik from '@Misc/styles/images/product-empik.svg';
import productIcon from '@Misc/styles/images/product.svg';

import styles from './Product.module.scss';
import useStyles from './Product.styles';
import { IProductProps } from './Product.types';

const CURRENCY_TEXT = 'zł';

const Product: FC<IProductProps> = ({
  product,
  isDesktop,
  productsLimit,
  selectedTheme,
  lang,
}) => {
  const muiStyles = useStyles();
  const showDescription = product.description && product.description.length > 0;
  const isDefaultTheme = selectedTheme === 'default';

  return (
    <div className={cn(styles.container, muiStyles.container)}>
      <div className={styles.productInfo}>
        {isDesktop && (
          <img
            className={styles.productImage}
            src={
              product.imageUrl
                ? product.imageUrl
                : config.theme.isEmpik
                ? productIconEmpik
                : productIcon
            }
          />
        )}
        <div className={styles.titleContainer}>
          {showDescription && <Description text={product.description || ''} />}
          <Grid container={true} alignItems={'center'}>
            <Grid item={true}>
              <Typography variant={'h6'}>{product.name}</Typography>
            </Grid>

            {product.type === 'collectorTicket' && (
              <Tooltip
                title={lang.collectorTicket}
                enterTouchDelay={100}
                leaveTouchDelay={6000}
              >
                <InfoIcon className={muiStyles.tooltip} />
              </Tooltip>
            )}
          </Grid>
        </div>
      </div>
      {isDefaultTheme ? (
        <p className={styles.productPrice}>
          <span className={styles.priceValue}>
            {getPriceFormatted(product.price)}
          </span>{' '}
          {CURRENCY_TEXT}
        </p>
      ) : (
        <Typography variant={'h6'}>
          + {getPriceFormatted(product.price)} {CURRENCY_TEXT}
        </Typography>
      )}
      <div className={styles.buttons}>
        <Buttons product={product} howManyProducts={productsLimit} />
      </div>
    </div>
  );
};

export default Product;
