import React from 'react';

import { Link } from 'react-router-dom';

import { IExternalLink } from './ExternalLink.types';

const ExternalLink = ({ children, to, newWindow, ...rest }: IExternalLink) => {
  if (!to) {
    return (
      <a
        className={rest.className}
        target={newWindow ? '_blank' : '_self'}
        id={rest.id}
        onClick={rest.onClick}
      >
        {children}
      </a>
    );
  }

  if (to && typeof to === 'object' && to?.external) {
    return (
      <a
        className={rest.className}
        href={to.pathname}
        target={newWindow ? '_blank' : '_self'}
        id={rest.id}
        onClick={rest.onClick}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      {...rest}
      target={newWindow ? '_blank' : '_self'}
      id={rest.id}
      onClick={rest.onClick}
    >
      {children}
    </Link>
  );
};

export default ExternalLink;
