// KEYS are action names from iFrame, VALUES are translated action names in our app
export const EmbedActions: { [key: string]: string } = {
  LOAD_EXTERNAL_LANG_MAP: '@@internalRouter/LOAD_EXTERNAL_LANG_MAP',
  LOAD_EXTERNAL_STYLE: '@@internalRouter/LOAD_EXTERNAL_STYLE',
  PONG: '@@InternalRouter/ESTABLISH_CONNECTION',
  SET_APP_URL: '@@internalRouter/SET_APP_URL',
  SET_CLIENT: '@@internalRouter/SET_CLIENT',
  SET_CURRENT_URL: '@@internalRouter/SET_CURRENT_URL',
  SET_LANGUAGE: '@@internalRouter/SET_LANGUAGE',
  SET_PARENT: '@@internalRouter/SET_PARENT',
  SET_REDIRECT_AFTER_SALE_URL: '@@internalRouter/SET_REDIRECT_AFTER_SALE_URL',
  SET_GTM_KEY: '@@internalRouter/SET_GTM_KEY',
  SET_GTAG_KEY: '@@internalRouter/SET_GTAG_KEY',
  SET_FB_PIXEL_KEY: '@@internalRouter/SET_FB_PIXEL_KEY',
  SET_DEFAULT_STORAGE_ID: '@@internalRouter/SET_DEFAULT_STORAGE_ID',
  SET_PARTNER_FORM_ID: '@@internalRouter/SET_PARTNER_FORM_ID',
};

export const allowedActions = Object.keys(EmbedActions);
