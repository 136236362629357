import { connect } from 'react-redux';

import _Store from '@Store';

import contactPageMeta from '@Model/meta/selectors/contactPage';

import Helmet from '../../Helmet';

export default connect((state: _Store.IState) => contactPageMeta(state))(
  Helmet
);
