import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';
import { getEvent, specialEvent } from '@Model/event/selectors';
import { getIframeParams, getModule } from '@Model/internalRouter/selectors';
import { getSelectedLocale, translate } from '@Model/locale/selectors';
import { setFinalPrice } from '@Model/pools/actions';
import {
  getInsurancePrice,
  getSelectedTickets,
  getSummary,
} from '@Model/pools/selectors';
import { setInsuranceSummaryData } from '@Model/products/actions';
import {
  getProductsSummary,
  getSelected,
  getSelectedProducts,
} from '@Model/products/selectors';
import { get as getShipments } from '@Model/shipment/selectors';
import { EVENT } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { getTheme } from '@Model/theme/selectors';
import getReceivedTicket from '@Model/tickets/selectors/getReceivedTicket';

import Summary from './Summary.formik';
import { ISummaryFromDispatch, ISummaryFromState } from './Summary.types';

const mapStateToProps = (state: _Store.IState): ISummaryFromState => ({
  dateTranslate: translate(state)('dates'),
  event: getEvent(state),
  isDesktop: isDesktop(state),
  isEmbed: !!getIframeParams(state),
  isLoading: getLoading(EVENT)(state),
  lang: translate(state)('buy', 'summary'),
  langBasket: translate(state)('basket'),
  language: getSelectedLocale(state),
  module: getModule(state),
  products: getSelected(state),
  receivedTickets: getReceivedTicket(state),
  selectedProducts: getSelectedProducts(state),
  selectedTheme: getTheme(state),
  selectedTickets: getSelectedTickets(state),
  shipments: getShipments(state),
  specialEventCopy: specialEvent(state),
  summary: getSummary(state),
  summaryProducts: getProductsSummary(state),
  insurancePrice: getInsurancePrice(state),
  selectedExtendedSlot: null,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISummaryFromDispatch => {
  return {
    setFinalPriceAction: (value) => dispatch(setFinalPrice(value)),
    setInsuranceSummaryData: (value) =>
      dispatch(setInsuranceSummaryData(value)),
  };
};

export default connect<
  ISummaryFromState,
  ISummaryFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Summary);
