import React from 'react';

import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import TransferSuccess from '@Compo/ticketsManagement/TransferSummary';

const CONFIRM_TEXT = 'Potwierdzenie transferu';

const TransferSuccessPage = () => {
  return (
    <MuiGrid>
      <Helmet pageName={CONFIRM_TEXT} />
      <FullTriangleBackground />
      <TransferSuccess />
    </MuiGrid>
  );
};

export default TransferSuccessPage;
