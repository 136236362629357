import { connect } from 'react-redux';

import _Store from '@Store';

import { getLocation } from '@Model/router/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import FaqBrick from './FaqBrick.component';
import { IFaqBrickFromState, IFaqBrickOwnProps } from './FaqBrick.types';

const mapStateToProps = (state: _Store.IState): IFaqBrickFromState => ({
  isDarkMode: getLocation(state).query?.isDark === 'true',
  isLoading: getLoading(CONST.SECTIONS)(state),
});

export default connect<
  IFaqBrickFromState,
  void,
  IFaqBrickOwnProps,
  _Store.IState
>(mapStateToProps)(FaqBrick);
