import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      background: theme.palette.background.paper,
      display: 'flex',
      height: '4rem',
    },
  })
);

export default useStyles;
