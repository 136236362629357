import { connect } from 'react-redux';

import _Store from '@Store';

import { getCustomTerms } from '@Model/event/selectors';
import { getHappeningTerms } from '@Model/happening/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { getSelectedTickets } from '@Model/pools/selectors';
import { getTheme } from '@Model/theme/selectors';

import Agreements from './Agreements.component';
import { IAgreementsFromState, IAgreementsOwnProps } from './Agreements.types';

const mapStateToProps = (state: _Store.IState): IAgreementsFromState => ({
  customTerms: [...getCustomTerms(state), ...getHappeningTerms(state)],
  isEmbed: !!getIframeParams(state),
  lang: translate(state)('buy', 'agreements'),
  selectedTheme: getTheme(state),
  selectedTickets: getSelectedTickets(state),
});

export default connect<
  IAgreementsFromState,
  void,
  IAgreementsOwnProps,
  _Store.IState
>(mapStateToProps)(Agreements);
