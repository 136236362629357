import { ISearchFilter } from '@Model/search/types';

/**
 * Function checks if a filter is already in an array of filters.
 *
 * @param {ISearchFilter[]} filterArray - array of filters to check in.
 * @param {ISearchFilter} filter - filter to search for.
 *
 * @return {boolean} true if filter exists in the array.
 */
const checkFilterDuplicate = (
  filterArray: ISearchFilter[],
  filter: ISearchFilter
): boolean => {
  return filterArray.some(
    (f) =>
      f.key === filter.key &&
      f.value.toLowerCase() === filter.value.toLowerCase()
  );
};

export default checkFilterDuplicate;
