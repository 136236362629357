import { connect } from 'react-redux';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';

import BasketItem from './BasketItem.component';
import { IBasketItemFromState } from './BasketItem.types';

const mapStateToProps = (state: _Store.IState): IBasketItemFromState => ({
  isDesktop: isDesktop(state),
});

export default connect<IBasketItemFromState, null, {}, _Store.IState>(
  mapStateToProps
)(BasketItem);
