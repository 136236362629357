import splitNumberToDayMonthYear from './splitNumberToDayMonthYear';

const months = [
  'stycznia',
  'lutego',
  'marca',
  'kwietnia',
  'maja',
  'czerwca',
  'lipca',
  'sierpnia',
  'września',
  'października',
  'listopada',
  'grudnia',
];

const hashToDate = (
  hash: number
): {
  day: number;
  month: string;
  year: number;
} => {
  const splitted = splitNumberToDayMonthYear(hash);

  return {
    day: splitted.day,
    month: months[splitted.month],
    year: splitted.year,
  };
};

export default hashToDate;
