// Import modules
import * as EntryList from '@/models/entryList/epics';
import { combineEpics } from 'redux-observable';

import _Store from '@Store';

import * as __Base__ from '@Model/__base__/epics';
import * as Analytics from '@Model/analytics/epics';
import * as App from '@Model/app/epics';
import * as Article from '@Model/article/epics';
import * as Artist from '@Model/artist/epics';
import * as Auth from '@Model/auth/epics';
import * as Errors from '@Model/errors/epics';
import * as Event from '@Model/event/epics';
import * as GiftCard from '@Model/giftCard/epics';
import * as Happening from '@Model/happening/epics';
import * as Images from '@Model/images/epics';
import * as InternalRouter from '@Model/internalRouter/epics';
import * as LandingPage from '@Model/landingPage/epics';
import * as Locale from '@Model/locale/epics';
import * as Locations from '@Model/locations/epics';
import * as Mail from '@Model/mail/epics';
import * as Modals from '@Model/modals/epics';
import * as Pages from '@Model/pages/epics';
import * as PasswordReset from '@Model/passwordReset/epics';
import * as Payment from '@Model/payment/epics';
import * as Place from '@Model/place/epics';
import * as PlaceEvents from '@Model/placeEvents/epics';
import * as Pools from '@Model/pools/epics';
import * as Premium from '@Model/premium/epics';
import * as Products from '@Model/products/epics';
import * as Router from '@Model/router/epics';
import * as Search from '@Model/search/epics';
import * as Selection from '@Model/selection/epics';
import * as Sendgrid from '@Model/sendgrid/epics';
import * as Shipment from '@Model/shipment/epics';
import * as Social from '@Model/social/epics';
import * as State from '@Model/state/epics';
import * as Theme from '@Model/theme/epics';
import * as Tickets from '@Model/tickets/epics';
import * as TrafficParams from '@Model/trafficParams/epics';
import * as Transactions from '@Model/transaction/epics';

/* @@STORE_COMPONENT@@ */
const epics = [
  // __Base__
  __Base__.removeHashWhenComeFromOutside,

  // Analytics
  Analytics.addDataToGTMWhenAddPaymentInfoEvent,
  Analytics.addDataToGTMOnDeeplinkWeb,
  Analytics.addDataToGTMOnAutoFillData,
  Analytics.addDataToGTMOnProceed,
  Analytics.addDataToGTMOnSearchFilter,
  Analytics.addDataToGTMOnSearchView,
  Analytics.addDataToGTMOnSelectItem,
  Analytics.addDataToGTMOnSearch,
  Analytics.addDataToGTMWhenAddToCartEvent,
  Analytics.addDataToGTMWhenRemoveFromCartEvent,
  Analytics.addDataToGTMWhenBannerClicked,
  Analytics.addDataToGTMOnBuyPage,
  Analytics.addDataToGTMOnEventPage,
  Analytics.addDataToGTMOnEventPageView,
  Analytics.addDataToGTMOnEventPurchase,
  Analytics.addDataToGTMWhenError404,
  Analytics.addDataToGTMWhenErrorWhenSomethingWrong,
  Analytics.addDataToGTMWhenErrorModal,
  Analytics.addDataToGTMWhenEventLoaded,
  Analytics.addDataToGTMWhenFormSend,
  Analytics.addDataToGTMWhenFormValidate,
  Analytics.addDataToGTMWhenLocationChanged,
  Analytics.addDataToGTMWhenPaymentComplete,
  Analytics.addDataToGTMWhenTicketSelected,
  Analytics.addTradeDoublerIdWhenAppIsReady,
  Analytics.addDataToGTMOnEventsListPage,
  Analytics.addDataToGTMWhenLocationChangedOld,
  Analytics.addDataToGTMonNewsletterSub,
  Analytics.addDataToGTMWhenEventDataLoaded,

  // App
  App.informThatAppIsReady,
  App.resetLoadingsAlwaysOnLocationChange,
  App.setDeviceWhenSizesChangedEpic,
  App.determineMobileUserAgent,

  // Article
  Article.fetchArticleWhenRequested,
  Article.getArticleWhenMounted,

  // Artist
  Artist.fetchArtistWhenRequested,
  Artist.fetchClosestPoolWhenRequested,
  Artist.fetchPastEventsWhenRequested,
  Artist.getArtistWhenMounted,

  // Auth
  Auth.emailCheckInit,
  Auth.emailCheckSubmit,
  Auth.generateToken,

  // EntryList
  EntryList.requestToCheckIfAgreementExistWhenFormSubmitted,
  EntryList.checkIfAgreementExistWhenRequested,
  EntryList.requestToCheckSubmissionsExistsWhenEmailPassed,
  EntryList.checkSubmissionsExistsWhenRequested,
  EntryList.selectNextStepWhenSubmissionSelected,
  EntryList.selectNextActionAfterPersonalDataFormSubmitted,
  EntryList.requestToRegisterNewSubmissionsWhenDataValid,
  EntryList.registerNewSubmissionWhenRequested,
  EntryList.requestToGetDataFromTicketWhenCodePassed,
  EntryList.getDataFromTicketWhenRequested,
  EntryList.requestToSendSmsWithCode,
  EntryList.sendSmsCodeWhenRequested,
  EntryList.checkIfAllSubmissionsAreComleted,
  EntryList.attachAllSubmissionsToTicketWhenFormComplete,

  // Errors
  Errors.doRedirectOnFailure,
  Errors.setErrorOnFailure,

  // Event
  Event.fetchEventWhenRequested,
  Event.fetchPlaceEvents,
  Event.fetchSimilarEvents,
  Event.fillBuyFormFromUri,
  Event.getEventWhenMounted,
  Event.getRundateSlugWhenEventFetch,
  Event.getRundateSlugWhenRequest,
  Event.redirectEventWhenLocationChange,
  Event.redirectEventWhenRundateSlugSuccess,
  Event.useAnalyticsWhenBuyFormMounted,

  // GiftCard
  GiftCard.giftCardActivationInit,
  GiftCard.submitCardActivation,

  // Happening
  Happening.fetchAvailabilitiesWhenRequested,
  Happening.getAvailabilitiesOnDayChange,
  Happening.getCustomTerms,
  Happening.getTermsWhenRequested,
  Happening.sendReserveForm,
  Happening.sendOnsiteReserveForm,
  Happening.whenHappeningMounted,
  Happening.whenHappeningRequested,
  Happening.calculatePriceForTicketType,
  Happening.getPriceCalculated,
  Happening.calculateUpsell,
  Happening.toggleUpsellState,
  Happening.calculateTotalPrice,
  Happening.notifyWhenPriceCalculationError,
  Happening.calculatePriceWhenChangeTicketType,
  Happening.openExtendedModalWhenSlotChanged,

  // Images
  Images.fetchImagesFromCloudinary,
  Images.requestForImages,
  Images.getImagesByCloudinaryParams,

  // InternalRouter
  InternalRouter.captureIframeEventWhenPostMessage,
  InternalRouter.establishIframeConnection,
  InternalRouter.getRundateSlugWhenRequest,
  InternalRouter.initEmbededPage,
  InternalRouter.loadFormDataForEmbed,
  InternalRouter.runResizeMethodWhenSetDevice,
  InternalRouter.redirectEventWhenLocationChange,
  InternalRouter.redirectEventWhenRundateSlugSuccess,
  InternalRouter.redirectParentToWhenAction,

  // Landing Page
  LandingPage.fetchLandingPageWhenRequested,
  LandingPage.getLandingPageWhenMounted,

  // Locale
  Locale.bindBrowserLocale,
  Locale.getBrowserLocaleSetting,
  Locale.setBrowserLocale,

  // Locations
  Locations.changeLocationsAndReload,
  Locations.clearLocationInStorage,
  Locations.fetchLocations,
  Locations.getLocationsForSite,
  Locations.setLocationOnFirstLoad,

  // Modals
  Modals.closeAllOrChangeRouteWhenTryCloseAll,
  Modals.closeLatestOrChangeRouteWhenTryCloseLatest,
  Modals.openModalWhenLocationChange,
  Modals.openOrChangeRouteWhenTryOpen,

  // Mail
  Mail.sendMailWhenMounted,

  // Pages
  Pages.fetchSections,
  Pages.fetchUrlSections,
  Pages.getCustomSections,
  Pages.getMainPageSections,
  Pages.authenticateSections,
  Pages.getAboutPageWhenRequested,

  // Payment
  Payment.doRedirectWhenMounted,

  // Password Reset
  PasswordReset.passwordResetFailure,
  PasswordReset.passwordResetSuccess,
  PasswordReset.sendResetPasswordRequest,
  PasswordReset.submitResetPassword,

  // Place
  Place.fetchPlaceWhenRequested,
  Place.getPlaceSlugWhenRequest,
  Place.getPlaceWhenMounted,
  Place.redirectPlaceWhenLocationChange,
  Place.redirectPlaceWhenPlaceSlugSuccess,

  // Place Events
  PlaceEvents.fetchEventsWhenRequested,
  PlaceEvents.firstPlaceEventsRequest,
  PlaceEvents.loadMoreWhenRequested,

  // Pools
  Pools.fetchPoolsWhenRequested,
  Pools.getPoolsWhenPoolsComponentMounted,
  Pools.redirectWhenSelectPool,
  Pools.resetStateWhenBuyRouteLeft,
  Pools.updateSelectedSeatsWhenSeatDeselected,
  Pools.updateSelectedSeatsWhenSeatSelected,
  Pools.updateSelectedTicketsWhenAmountClicked,
  Pools.updateSelectedTicketsWhenSeatsSelectedUpdate,
  Pools.fetchClosestPoolsWhenRequested,
  Pools.removeProductWhenDeselectTicket,

  // Premium
  Premium.checkIfNeedFetchCardData,
  Premium.doRedirectWhenPremiumTokenIsInvalid,
  Premium.fetchPremiumUserByToken,
  Premium.fetchPremiumWhenEventSuccess,
  Premium.fetchUserCardData,

  // Products
  Products.whenProductsMounted,
  Products.fetchProductsWhenRequested,
  Products.fetchProductsPoolsWhenRequested,
  Products.updateSelectedProductsWhenAmountClicked,
  Products.singleProductMounted,
  Products.fetchSingleProduct,
  Products.insuranceMounted,
  Products.getInsuranceProductsData,

  // Router
  Router.checkRedirectExists,
  Router.checkRouteExists,
  Router.redirectIfExists,

  // Search
  Search.getFiltersFromQuery,
  Search.baseSearchInit,
  Search.fetchBaseSearchResults,
  Search.getSearchStructure,
  Search.resetFiltersOnLocationChange,
  Search.getFacetsForAPhrase,
  Search.fetchFacetsEpic,
  Search.getLocationOnSearchMounted,

  // Selection
  Selection.fetchSelectionWhenRequested,
  Selection.getSelectionWhenMounted,
  Selection.getSelectionSlugWhenRequest,
  Selection.redirectSelectionWhenLocationChange,
  Selection.redirectSelectionWhenSelectionSlugSuccess,

  // Sendgrid
  Sendgrid.checkIfUserEmailIsValid,
  Sendgrid.sendMailValidationRequest,

  // Shipment
  Shipment.fetchShipmentDeliveryWhenRequested,
  Shipment.getShipmentDeliveryWhenMounted,

  // Social
  Social.getClientDataFromFacebook,

  // State
  State.setLoaderWhenAvailabilitiesFetchFinished,
  State.setLoaderWhenAvailabilitiesFetchRequested,
  State.setLoaderWhenArticleFetchFinished,
  State.setLoaderWhenArticleFetchRequested,
  State.setLoaderWhenArtistFetchFinished,
  State.setLoaderWhenArtistFetchRequested,
  State.setLoaderWhenArtistClosestPoolFetchFinished,
  State.setLoaderWhenArtistClosestPoolFetchRequested,
  State.setLoaderWhenEventFetchFinished,
  State.setLoaderWhenEventFetchRequested,
  State.setLoaderWhenEventsFetchFinished,
  State.setLoaderWhenEventsFetchRequested,
  State.setLoaderWhenHappeningFetchFinished,
  State.setLoaderWhenHappeningFetchRequested,
  State.setLoaderWhenHappeningPriceFetchFinished,
  State.setLoaderWhenHappeningPriceFetchRequested,
  State.setLoaderWhenLandingPageFetchFinished,
  State.setLoaderWhenLandingPageFetchRequested,
  State.setLoaderWhenPlaceFetchFinished,
  State.setLoaderWhenPlaceFetchRequested,
  State.setLoaderWhenPlaceEventsFetchFinished,
  State.setLoaderWhenPlaceEventsFetchRequested,
  State.setLoaderWhenPoolsFetchFinished,
  State.setLoaderWhenPoolsFetchRequested,
  State.setLoaderWhenPremiumFetchFinished,
  State.setLoaderWhenPremiumFetchRequested,
  State.setLoaderWhenSelectionFetchFinished,
  State.setLoaderWhenSelectionFetchRequested,
  State.setLoaderWhenSectionsFetchFinished,
  State.setLoaderWhenSectionsFetchRequested,
  State.setLoaderWhenTicketsSendFinished,
  State.setLoaderWhenTicketsSendRequested,
  State.setLoaderWhenTransactionSendFinished,
  State.setLoaderWhenTransactionSendRequested,

  // Theme
  Theme.changeThemeWhenProductPinMounted,
  Theme.resetThemeWhenRouteChange,

  // Tickets
  Tickets.displaySuccessNotification,
  Tickets.displayTransferSuccessNotification,
  Tickets.fetchEventWhenRequested,
  Tickets.fetchReceiveTicketWhenRequested,
  Tickets.whenDayIsSetGetAvailabilities,
  Tickets.whenReceiveTicketMounted,
  Tickets.fetchTicketDataWhenRequested,
  Tickets.sendTransferTicketRequest,
  Tickets.submitReceiveTicketForm,
  Tickets.submitTicketDataForm,
  Tickets.submitTransferTicketForm,
  Tickets.returnTicketInit,
  Tickets.onReturnTicket,
  Tickets.redirectOnReset,
  Tickets.fetchTransferTicketFee,
  Tickets.submitTransactionDataForm,
  Tickets.fetchTicketDataFromTransactionWhenRequested,
  Tickets.requestTicketDownloadFromTicketData,
  Tickets.requestTicketDownloadFromTransactionData,
  Tickets.downloadTheTicketFromTicketDataWhenRequested,
  Tickets.downloadTheTicketFromTransactionDataWhenRequested,
  Tickets.submitCancelTransferTicketForm,
  Tickets.sendCancelTransferTicketRequest,
  Tickets.displayCancelTransferSuccessNotification,

  // Transactions
  Transactions.fetchTransactionInfoWhenRequested,
  Transactions.fetchTransactionDetailsWhenRequested,
  Transactions.fetchTransactionInfoAndDetailsWhenSummaryMounted,
  Transactions.getPaymentRundateSlugWhenRequest,
  Transactions.redirectPaymentOnEmbed,
  Transactions.redirectPaymentWhenLocationChange,
  Transactions.redirectPaymentWhenRundateSlugSuccess,
  Transactions.requestOnlineTransactionWhenBuyClicked,
  Transactions.requestOnsiteTransactionWhenBuyClicked,
  Transactions.sendTransactionWhenRequested,
  Transactions.removeHoldTokenWhenTransactionWasSent,
  Transactions.removeBasketItemsWhenTransactionWasSent,
  Transactions.runRedirectAfterSaleWhenRequest,
  Transactions.saveLastPurchasedEventOnSuccessfulPurchase,

  // UTM
  TrafficParams.getTrafficParams,
  TrafficParams.saveTrafficParamsInState,
];

export default combineEpics(...epics.filter(Boolean)) as _Store.IEpic;
