import React, { useContext } from 'react';

import { Typography } from '@material-ui/core';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import FormField from '@Compo/forms/FormField';
import config from '@Config';

import styles from './AdditionalField.module.scss';
import { IAdditionalFieldProps } from './AdditionalField.types';

const AdditionalField = ({
  clientLang,
  errors,
  index,
  lang,
  ticket,
  selectedTickets,
  step,
  setFieldValue,
  values,
}: IAdditionalFieldProps) => {
  const state = useContext(clearContext);
  const basketItems = state?.basketItems || [];
  const fillWithBuyerData = () => {
    const firstPoolItemWithAdditional =
      selectedTickets.length > 0
        ? selectedTickets.find((selectedTicket) => {
            return selectedTicket.additionalFields;
          })
        : '';

    const firstBasketItemWithAdditional =
      basketItems.length > 0
        ? basketItems.find((selectedBasketItem) => {
            return selectedBasketItem.additionalFields;
          })
        : '';

    const poolId = firstPoolItemWithAdditional
      ? firstPoolItemWithAdditional.poolId
      : firstBasketItemWithAdditional
      ? firstBasketItemWithAdditional.id
      : '';

    setFieldValue(`firstnameAdditional["id_${poolId}"].0`, values.firstname);
    setFieldValue(`lastnameAdditional["id_${poolId}"].0`, values.lastname);
    setFieldValue(`emailAdditional["id_${poolId}"].0`, values.email);
  };

  if (!values.firstnameAdditional[`id_${ticket.id}`]) {
    values.firstnameAdditional[`id_${ticket.id}`] = [''];
  }
  if (!values.lastnameAdditional[`id_${ticket.id}`]) {
    values.lastnameAdditional[`id_${ticket.id}`] = [''];
  }
  if (!values.emailAdditional[`id_${ticket.id}`]) {
    values.emailAdditional[`id_${ticket.id}`] = [''];
  }

  return (
    <div className={styles.additionalFieldsContainer}>
      <div className={styles.ticketContainer}>
        <p className={styles.ticketTitle}>{`Bilet ${step + 1}`}</p>
        <Typography variant={'subtitle2'} color={'secondary'}>
          {`${ticket.poolName} ${lang.additionalData}`}
        </Typography>
      </div>
      <div>
        <p className={styles.info}>
          {clientLang.namedTicket} <strong>{clientLang.realData}</strong>
          {clientLang.id}
        </p>
        <FormField
          id={`emailAdditional['id_${ticket.id}'].${index}`}
          name={`emailAdditional['id_${ticket.id}'].${index}`}
          placeholder={`${clientLang.eMail}*`}
          type="email"
          validate={(val) => {
            if (!val) {
              return errors.emailAddressIsRequiredValidator;
            }
          }}
        />
        <FormField
          id={`firstnameAdditional['id_${ticket.id}'].${index}`}
          name={`firstnameAdditional['id_${ticket.id}'].${index}`}
          placeholder={`${clientLang.firstName}*`}
          type="text"
          validate={(val) => {
            if (!val) {
              return errors.firstNameIsRequiredValidator;
            }
          }}
        />
        <FormField
          id={`lastnameAdditional['id_${ticket.id}'].${index}`}
          name={`lastnameAdditional['id_${ticket.id}'].${index}`}
          placeholder={`${clientLang.lastName}*`}
          type="text"
          validate={(val) => {
            if (!val) {
              return errors.lastNameIsRequiredValidator;
            }
          }}
        />
      </div>
      {config.app.onlineSale && step === 0 && (
        <div
          className={styles.fillWithBuyerDataButton}
          onClick={() => fillWithBuyerData()}
        >
          {lang.fillInData}
        </div>
      )}
    </div>
  );
};

export default AdditionalField;
