import { IGalleryBrickOwnProps } from '@Compo/Bricks/components/GalleryBrick/GalleryBrick.types';
import {
  ISectionsComponentDataMutable,
  ISectionsComponentGallery,
} from '@Model/pages/types/sections';

export const gallery = (
  data: ISectionsComponentDataMutable
): IGalleryBrickOwnProps => {
  const galleryData = data as ISectionsComponentGallery[];
  const images = galleryData[0].images
    ? galleryData[0].images.map((image) => image.src)
    : [];

  return {
    cloudinaryTag: galleryData[0].cloudinaryTag,
    photos: images,
  };
};
