import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gap: '0.625rem',
    },
    checkbox: {
      marginTop: '1rem',
      marginBottom: '2rem',
    },
    privacyPolicy: {
      display: 'inline',
      '& strong': {
        fontSize: '0.875rem',
      },
      '& strong a': {
        color: theme.palette.primary.contrastText,
      },
    },
  })
);

export default useStyles;
