// noinspection JSNonASCIINames,NonAsciiCharacters
export const formTranslations = {
  en: {
    goingSupport: {
      Adres: 'Address',
      'Brak biletów w aplikacji': 'Tickets not visible in the app',
      'Błąd aplikacji': 'Error in the app',
      'Dane do wystawienia faktury:': 'Data for the invoice:',
      'Dane nowego właściciela:': 'Data of the new owner:',
      'Data zakupu': 'Purchase date',
      'Dodatkowe informacje:': 'Additional info:',
      'E-mail': 'E-mail',
      'E-mail kontaktowy': 'Contact e-mail',
      'E-mail, na jaki został zakupiony bilet': 'E-mail used during the purchase',
      'E-mail, na który zarejestrowana jest aplikacja': 'E-mail to which the app is registered',
      Faktury: 'Invoices',
      'ID zamówienia PayU': 'PayU order ID',
      Imię: 'First name',
      'Imię i nazwisko': 'Full name',
      'Informacje na temat przetwarzania danych znajdziesz w naszej <a target="_blank" href="https://goingapp.pl/themes/going/assets/docs/privacy_policy.pdf?cache=1588598855">Polityce Prywatności</a>.': 'To learn how we handle your data, read our <a href="https://goingapp.pl/themes/going/assets/docs/privacy_policy.pdf?cache=1588598855" target="_blank">Privacy Policy',
      'Informację dot. wersji aplikacji znajdziesz w prawym dolnym rogu zakładki „Moje konto", w aplikacji Going.': 'You can find the version of your app in the bottom right corner of the "My Account" tab in the Going. app.',
      'Inne zapytanie': 'Other inquiries',
      'Kod biletu / biletów:': 'Ticket code:',
      'Kod biletu:': 'Ticket code:',
      'Kod pocztowy': 'ZIP code',
      'Liczba biletów do przekazania:': 'Amount of tickets to pass:',
      Miasto: 'City/Town',
      'Mój bilet nie dotarł': 'I did not get my ticket',
      'Na moim poprzednim koncie są bilety. Proszę o przeniesienie ich na nowe konto.': 'There are tickets on my previous account. Please transfer them to a new account.',
      'Nazwa osoby fizycznej': 'Name of the entity',
      'Nazwa pliku': 'Filename',
      Nazwisko: 'Last name',
      'Nowy e-mail': 'New e-mail',
      'Opis problemu': 'Problem description',
      'Potwierdzam, iż dokonałam/em rejestracji.': 'I confirm that I have registered with the new address.',
      'Potwierdzenie płatności w formacie PDF': 'Payment confirmation in PDF format',
      'Problem z płatnością': 'Payment issues',
      'Prosimy o dokładne sprawdzenie danych. Nie ma możliwości późniejszej zmiany danych na fakturze. Jedyna opcja to samodzielne wystawienie <b>noty korygującej</b>.': 'Please remember to enter your invoice details correctly. After you receive an invoice, there is no way for us to change the data. In such a case, in accordance with Polish law, the buyer must append a <b>"corrective note"</b> (nota korygująca) to the document.',
      'Prosimy o dokładne wypełnienie poniższego formularza.<br> Uwaga! Tylko osoba, której dane widnieją na bilecie może zgłosić się z prośbą o jego wycofanie i zwrot środków.': 'Please fill the below form carefully.<br>Caution! The refund request can only be made by the person whose personal data are written on the ticket.',
      'Przeciągnij lub przeglądaj': 'Drag or browse',
      Rozmiar: 'Size',
      'Stary e-mail': 'Previous e-mail',
      'Szanowni Państwo,<br><br>Uprzejmie informujemy, iż zgodnie ze zmianami art. 106b ust. 5–7, art. 109a ustawy z 11 marca 2004 r. o podatku od towarów i usług j.t. Dz.U. z 2018 r. poz. 2174; ost.zm. Dz.U. z 2019 r. poz. 2200 oraz art. 15 ustawy z 4 lipca 2019 r. o zmianie ustawy o podatku od towarów i usług oraz niektórych innych ustaw Dz.U. z 2019 r. poz. 1520, gdy opcja ta nie jest zaznaczona podczas zakupu sprzedawca nie ma obowiązku wystawienia kupującemu faktury VAT. W związku z tym możliwe jest otrzymanie faktury tylko na osobę fizyczną do 3 miesięcy od od końca miesiąca w jakim został zakupiony bilet.': 'Dear Customers,<br><br>We would like to inform you that in accordance with the amendments to art. 106b paragraph 5-7, art. 109a of the 11th March 2004 act on tax on goods and services (Ustawa o podatku od towarów i usług), consolidated text: Dziennik Ustaw from 2018, pos. 2174; last changes in Dziennik Ustaw from 2019, pos. 2200 & art. 15 of the 4th July 2019 act amending the act on tax on goods and services and some other acts (Ustawa o zmianie ustawy o podatku od towarów i usług oraz niektórych innych ustaw), Dziennik Ustaw from 2019, pos. 1520, when the box "Chcę otrzymać fakturę" is not checked in the moment of purchase, the seller is not obliged to issue a VAT invoice to the buyer. You can request a natural person invoice for up to 3 months from the purchase.',
      'UWAGA: Przed wysłaniem zgłoszenia należy się zarejestrować na nowy adres e-mail.': 'Caution: Before submitting your request, you must register to a new email address.',
      'Uprzejmie informujemy, iż zgodnie ze zmianami art. 106b ust. 5–7, art. 109a ustawy z 11 marca 2004 r. o podatku od towarów i usług j.t. Dz.U. z 2018 r. poz. 2174; ost.zm. Dz.U. z 2019 r. poz. 2200 oraz art. 15 ustawy z 4 lipca 2019 r. o zmianie ustawy o podatku od towarów i usług oraz niektórych innych ustaw Dz.U. z 2019 r. poz. 1520, gdy opcja ta nie jest zaznaczona podczas zakupu sprzedawca nie ma obowiązku wystawienia kupującemu faktury VAT. W związku z tym możliwe jest otrzymanie faktury tylko na osobę fizyczną do 3 miesięcy od od końca miesiąca w jakim został zakupiony bilet.': 'Dear Customers, We would like to inform you that in accordance with the amendments to art. 106b paragraph 5-7, art. 109a of the 11th March 2004 act on tax on goods and services (Ustawa o podatku od towarów i usług), consolidated text: Dziennik Ustaw from 2018, pos. 2174; last changes in Dziennik Ustaw from 2019, pos. 2200 & art. 15 of the 4th July 2019 act amending the act on tax on goods and services and some other acts (Ustawa o zmianie ustawy o podatku od towarów i usług oraz niektórych innych ustaw), Dziennik Ustaw from 2019, pos. 1520, when the box "Chcę otrzymać fakturę" is not checked in the moment of purchase, the seller is not obliged to issue a VAT invoice to the buyer. You can request a natural person invoice for up to 3 months from the purchase.',
      'Usunięcie danych': 'Personal data removal',
      'Uwagi:': 'Comments:',
      'Wersja aplikacji Going.': 'Going. app version',
      'Wpisz kod biletów': 'Enter ticket code',
      'Wpisz pełną nazwę wydarzenia widniejącą na bilecie': 'Type in the full name of the event (written on the ticket)',
      'Wybierz formę zwrotu': 'Choose the refund form',
      'Wybierz przyczynę zgłoszenia': 'Choose the topic',
      'Wybrana forma zwrotu:': 'Chosen refund form:',
      'Wydarzenie, którego dotyczy zapytanie': 'Event, which the inquiry concerns',
      'Wyrażam zgodę na przesyłanie faktur drogą elektroniczną.': 'I agree to receive invoices through e-mail.',
      Wyślij: 'Submit',
      'Z jakiego systemu operacyjnego korzystasz?': 'What operating system are you using?',
      'Zapraszamy do wyboru tematu zapytania:': 'Please choose the topic of your inquiry:',
      'Zmiana danych na bilecie': 'Changing personal data on ticket',
      'Zmiana maila w aplikacji': 'Changing e-mail in the app',
      'Zrzut ekranu (printscreen) z kodem błędu:': 'Screenshot containing the error code',
      'Zrzut ekranu (printscreen) z zakładki „Moje Dane” w aplikacji Going.': 'Screenshot of the "My data" tab in the Going. app.',
      'Zwrot biletu': 'I want to return a ticket',
      'Zwrot za bilety zakupione ponad rok temu poprzez formę płatności BLIK': 'Refund for tickets purchased over a year ago via the BLIK payment method',
      'Wszystkie bilety zawierają moje imię i nazwisko. Czy pozostałe osoby, dla których zostały zakupione, bez problemu wejdą na wydarzenie?': 'All tickets include my name and surname. Will the other people for whom they were purchased enter the event without any problems?',
      'Dane osobowe - kontakt z administratorem danych osobowych.': 'Personal data - contact with the personal data administrator.',
      'Problemy z przekazaniem biletu': 'Problems with handing over the ticket',
      'Ponowne przesłanie biletu': 'Ticket resend',
      'Pamiętaj, że o zwrot środków za bilety można ubiegać się wyłącznie w przypadku, gdy wydarzenie zostało przeniesione lub odwołane. Uwaga! Tylko osoba, której dane widnieją na bilecie, może zgłosić się do nas z taką prośbą.': 'Remember, that tickets can only be refunded if the event has been moved or canceled. Attention! Only the person whose data appears on the ticket may contact us with such a request.',
      'Jasne! W takim przypadku osoba towarzysząca powinna wejść na teren wydarzenia w obecności osoby, której dane widnieją na biletach. Masz bilety ze swoimi danymi, a nie wybierasz się na wydarzenie? Skorzystaj z opcji przekazania biletu w aplikacji (więcej informacji znajdziesz <a href="https://pomoc.goingapp.pl/help/jak-zmienic-dane-osobowe-na-bilecie" target="_blank">TUTAJ</a>). Jeśli nadal chcesz się z nami skontaktować, wypełnij poniższy formularz.': 'Of course! In this case, the accompanying person should enter the event area in the presence of the person whose details appear on the tickets. Do you have tickets with your details and you are not going to the event? Use the ticket transfer option in the app (for more information see <a href="https://help.goingapp.pl/help/how-changing-personal-data-on- ticket" target="_blank"> HERE </a>). If you still want to contact us, please fill out the form below.',
      'Najpierw zajrzyj do folderu SPAM i pozostałych zakamarków Twojej poczty. Biletu nadal nie ma? Wypełnij poniższy formularz. Pomożemy! Bilet został wysłany wcześniej, ale mail gdzieś zaginął? Zainstaluj naszą aplikację na adres podany przy zakupie, a znajdziesz w niej swój bilet.': 'First check the SPAM folder and other nooks of your e-mail. Ticket still missing? Please complete the form below. We will help! The ticket was sent earlier, but the mail is lost somewhere? Install our application to the address provided during the purchase and you will find your ticket there.',
      'Pamiętaj, aby przy zakupie biletu wprowadzić ten sam adres mailowy, na który zostało założone konto w aplikacji Going. W innym przypadku bilet nie pojawi się w aplikacji. Jeżeli jeszcze nie masz konta w aplikacji Going. - załóż konto przy użyciu tego samego adresu, który podany został podczas zakupu. Masz iOS? Zarejestruj apkę nie używając AppleID. Jeśli nadal chcesz się z nami skontaktować, wypełnij poniższy formularz.':'Remember, to enter the same e-mail address used for the account in the Going application when purchasing a ticket. Otherwise, the ticket will not appear in the application. If you do not have an account in the Going. application yet - create an account using the same address that was provided during the purchase. Do you have iOS? Register the app without using AppleID. If you still want to contact us, please fill out the form below.',
      'Wystarczy, że ponownie zarejestrujesz aplikację na wybrany adres mailowy. Jeżeli na poprzednim koncie znajdują się bilety na wydarzenia, które jeszcze się nie odbyły, napisz do nas - wyślemy je na nowe konto.': 'All you have to do is re-register the application to the selected e-mail address. If your previous account has tickets for events that have not been held yet, write to us - we will send them to your new account.',
      'Uściślenie zapytania:':'Clarification of the question:',
      'Osoby z niepełnosprawnością': 'People with disabilities',
      'Samodzielny udział w wydarzeniu rozrywkowym': 'Independent participation in an entertainment event',
      'Bilety ulgowe dla dzieci': 'Discount tickets for children',
      'Inne pytania' : 'Other questions',
      'Dane, na które został zakupiony bilet są przetwarzane nawet do 6 lat od daty realizacji zakupu (m.in. przetwarzanie niezbędne do ustalenia i dochodzenia ewentualnych roszczeń lub obrony przed nimi).Zapraszam do zapoznania się z zapisami dotyczącymi okresu przetwarzania danych i praw użytkownika zawartych w Polityce prywatności.': 'The data for which the ticket was purchased is processed up to 6 years from the date of purchase (including processing necessary to establish and assert any claims or defend against them). I invite you to read the provisions regarding the period of data processing and user rights contained in the Privacy Policy.',
      'Uwaga! Zanim przekażesz bilet zapoznaj się z warunkami i szczegółami dostępnymi w naszym <a href="https://pomoc.goingapp.pl/help/jak-zmienic-dane-osobowe-na-bilecie" target="_blank">FAQ</a>': 'Attention! Before you submit your ticket, please read the terms and conditions and details on our <a href="https://help.goingapp.pl/help/how-changing-personal-data-on- ticket" target="_blank"> FAQ </a>',
      'Bilet został wysłany wcześniej, ale mail z potwierdzeniem gdzieś zaginął? Zainstaluj naszą aplikację na adres podany przy zakupie, a znajdziesz w niej swój bilet jeszcze zanim zdążymy Ci odpisać :)': 'The ticket was sent earlier but the confirmation email is lost somewhere? Install our application to the address provided during purchase, and you will find your ticket in it before we can write back to you :)',
      'W przypadku większości wydarzeń jest możliwość zakupu biletów dla osób z niepełnosprawnościami i ich opiekunów. Sprawdź w regulaminie wydarzenia zapisy dotyczące zakupu tego rodzaju biletów, jeśli pojawią się dodatkowe pytania, skontaktuj się z nami. Regulamin wydarzenia jest dostępny na stronie zakupu biletu.':'For most events, it is possible to purchase tickets for people with disabilities and their guardians. Check the event regulations for records regarding the purchase of this type of tickets, if you have additional questions, please contact us. The rules of the event are available on the ticket purchase page.',
      'W przypadku większości wydarzeń osoba powyżej 16 roku życia może samodzielnie uczestniczyć w wydarzeniu. Musi posiadać oświadczenie opiekuna prawnego , które  znajdziesz <a href="https://web.goingapp.pl/storage/app/media/zgoda-na-uczestnictwo-osoby-niepelnoletniej-w-wydarzeniu.pdf" target="_blank">TUTAJ</a>. Jeżeli uczestnik nie ma ukończonych 16 lat musi być pod opieką osoby pełnoletniej.': 'For most events, a person over the age of 16 may attend the event on their own. It must have a legal guardian declaration, which you can find <a href = "https://web.goingapp.pl/storage/app/media/zgoda-na-uczestnictwo-osoby-niepelnoletniej-w-wydarzeniu.pdf" target = "_ blank" >HERE</a>. If the participant is under 16 years of age, he or she must be under the care of an adult.',
      'Sprawdź w regulaminie wydarzenia zapisy dotyczące zakupu tego rodzaju biletów, jeśli pojawią się dodatkowe pytania, skontaktuj się z nami. Regulamin wydarzenia jest dostępny na stronie zakupu biletu.': 'Check the event regulations for records regarding the purchase of this type of tickets, if you have additional questions, please contact us. The rules of the event are available on the ticket purchase page.',
      'Inne pytanie': 'Another question',
      'Miasto wydarzenia': 'Event city',
      'Data wydarzenia': 'Event date',
      'Numer konta bankowego': 'Bank account number',
      'Nazwa wydarzenia': 'Event name',
      'Opis problemu:': 'Problem description:'
    },
  },
  pl: {
    default: {
      'Add Another': 'Dodaj kolejne',
      April: 'Kwiecień',
      August: 'Sierpień',
      Day: 'Dzień',
      December: 'Grudzień',
      'Drop files to attach,': 'Przeciągnij',
      February: 'Luty',
      'File Name': 'Nazwa Pliku',
      'File is the wrong type; it must be .pdf': 'Zły rodzaj pliku, powinien być to .pdf',
      'File is too big; it must be at most 2MB': 'Plik jest za duży, powinien mieć maksymalnie 2MB',
      January: 'Styczeń',
      July: 'Lipiec',
      June: 'Czerwiec',
      March: 'Marzec',
      Month: 'Miesiąc',
      'No results found': 'Brak wyników',
      November: 'Listopad',
      October: 'Październik',
      September: 'Wrzesień',
      Size: 'Rozmiar',
      Submit: 'Wyślij',
      'Type to search': 'Wyszukaj',
      Year: 'Rok',
      april: 'Kwiecień',
      array: '{{field}} powinno być tablicą.',
      array_nonempty: '{{field}} nie powinno być pustą tablicą.',
      august: 'Sierpień',
      browse: 'przeglądaj',
      cancel: 'Anuluj',
      complete: 'Zgłoszenie wysłane.',
      day: 'Dzień',
      december: 'Grudzień',
      error: 'Proszę popraw błędy przed wysłaniem formularza.',
      february: 'Luty',
      invalid_date: 'Pole {{field}} nie zawiera poprawnej daty.',
      invalid_day: '{{field}} nie jest poprawnym dniem tygodnia.',
      invalid_email: 'Pole {{field}} nie zawiera poprawnego adresu e-mail.',
      invalid_regex: '{{field}} nie jest zgodne z wyrażeniem {{regex}}.',
      invalid_url: '{{field}} powinno być adresem url.',
      january: 'Styczeń',
      july: 'Lipiec',
      june: 'Czerwiec',
      march: 'Marzec',
      mask: '{{field}} nie jest właściwe.',
      max: '{{field}} nie może być dłuższe niż {{max}}.',
      maxDate: '{{field}} nie powinno być po {{- maxDate}}.',
      maxLength: '{{field}} powinno być krótsze niż {{length}} znaki/ów.',
      maxWords: '{{field}} powinno zawierać mniej niż {{length}} słów.',
      maxYear: '{{field}} nie powinno być później niż {{maxYear}}',
      may: 'Maj',
      min: '{{field}} nie powinno być krótsze niż {{min}}.',
      minDate: '{{field}} nie powinno być przed {{- minDate}}.',
      minLength: '{{field}} powinno być dłuższe niż {{length}} znaki/ów.',
      minWords: '{{field}} powinno zawierać przynajmniej {{length}} słów.',
      minYear: '{{field}} nie powinno być przed {{minYear}}.',
      month: 'Miesiąc',
      next: 'Dalej',
      nonarray: '{{field}} nie powinno być tablicą.',
      november: 'Listopad',
      october: 'Październik',
      or: 'lub',
      pattern: '{{field}} nie pasuje do {{pattern}}.',
      previous: 'Wstecz',
      required: 'Pole {{field}} jest wymagane.',
      select: 'W {{field}} została wybrana niewłaściwa pozycja.',
      september: 'Wrzesień',
      stripe: '{{stripe}}',
      submit: 'Wyślij formularz',
      submitError: 'Przed wysłaniem sprawdź formularz i popraw wszystkie błędy.',
      unique: '{{field}} powinno być unikalne.',
      year: 'Rok',
    },
  },
  translatedForms: ['formularz-kontaktowy', 'zwrot-biletu'],
};
