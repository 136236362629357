import React from 'react';

import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MainPageTop from '@Compo/layout/MainPageTop';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import TicketRecipience from '@Compo/ticketsManagement/TicketRecipience';
import { IBreadcrumb } from '@Model/breadcrumb/types';
import routes from '@Routes/routes';

import style from './ReceiveTicket.module.scss';

const RECEIVE_TICKET_TEXT = 'Odbierz bilet';
const TICKET_MANAGEMENT_TEXT = 'Zarządzaj biletem';

const breadcrumbs: IBreadcrumb[] = [
  {
    link: routes.myTicket,
    name: TICKET_MANAGEMENT_TEXT,
  },
  {
    link: routes.receiveTicket,
    name: RECEIVE_TICKET_TEXT,
  },
];

const ReceiveTicket = () => {
  return (
    <MuiGrid>
      <Helmet pageName={RECEIVE_TICKET_TEXT} />
      <FullTriangleBackground />
      <MuiSectionContainer>
        <MuiBreadcrumbs base={breadcrumbs} />
        <MainPageTop title={RECEIVE_TICKET_TEXT} />
        <div className={style.content}>
          <TicketRecipience />
        </div>
      </MuiSectionContainer>
    </MuiGrid>
  );
};

export default ReceiveTicket;
