import React from 'react';

import { Divider, Grid, Typography, useTheme } from '@material-ui/core';
import cn from 'classnames';

import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import classes from '../../Success.classes';
import useStyles from './SummaryAfterSellSegment.styles';
import { ISummaryAfterSellSegmentProps } from './SummaryAfterSellSegment.types';
import SingleSegment from './components/SingleSegment';

const SummaryAfterSellSegment = ({
  transactionItems,
  transactionData,
  lang,
}: ISummaryAfterSellSegmentProps) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);
  const { isMobile } = useMuiBreakpoints();

  return (
    <Grid item={true}>
      <Grid container={true} direction="row">
        <Grid
          item={true}
          xs={12}
          className={cn(classes.summaryAfterSellSegmentHeader)}
        >
          <Typography variant={isMobile ? 'h5' : 'h3'} color="textPrimary">
            {lang.yourOrder}
          </Typography>
        </Grid>

        <Grid
          item={true}
          xs={12}
          className={cn(muiStyles.container, classes.detailsContainer)}
        >
          <Grid container={true} className={muiStyles.detailsContainer}>
            {transactionItems.map((item, index, arr) => (
              <Grid
                item={true}
                xs={12}
                className={cn(classes.singleSegment)}
                key={item.uuid}
              >
                <SingleSegment transactionItems={item} />
                {arr.length - 1 === index ? null : (
                  <Divider className={muiStyles.detailsDivider} />
                )}
              </Grid>
            ))}
          </Grid>

          <Divider className={muiStyles.divider} />

          <Grid
            container={true}
            className={cn(muiStyles.buttomSummary, classes.buttomSummary)}
            alignItems={'flex-end'}
          >
            <Grid item={true} xs={6} className={muiStyles.summaryText}>
              <Typography variant="body1" color="textPrimary">
                {lang.summary}
              </Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Typography variant="h5" align="right" color="textPrimary">
                {transactionData.orderValue.toFixed(2)}{' '}
                {transactionData.currency}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryAfterSellSegment;
