import React from 'react';

import { Formik } from 'formik';

import { IFormValues } from '@Model/entryList/types';
import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import stepNames from '../../steps';
import FormLayout from '../FormLayout';
import { makeValidationSchema } from './PersonalData.selector';
import { IPersonalDataProps } from './PersonalData.types';

const PersonalData = ({
  handleNextStep,
  initialValues,
  setFormValues,
}: IPersonalDataProps) => {
  const { entryList: lang } = useDictionary();

  const handleFormSubmit = (values: IFormValues) => {
    setFormValues(values);
    handleNextStep(stepNames.summary);
  };

  return (
    <Formik
      validationSchema={makeValidationSchema(lang.validation)}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      component={FormLayout}
      enableReinitialize={true}
    />
  );
};

export default PersonalData;
