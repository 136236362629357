import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      justifyContent: 'space-between',
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
      width: 'inherit',
    },
  })
);

export default useStyles;
