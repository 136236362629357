import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      marginBottom: '1.5rem',
    },
  });
});

export default useStyles;
