import React from 'react';

import { IInternalRouterPayload } from '@Model/internalRouter/types';

import Mapper from './../Mapper';

const Switch = (props: IInternalRouterPayload) => {
  const { module } = props;

  const RouteComponent = Mapper.get(module);

  if (RouteComponent) {
    return <RouteComponent />;
  }

  return null;
};

export default Switch;
