import React, { useEffect, useState } from 'react';

import { services as sharedServices } from 'goingapp-shared';

import Gallery from '@Compo/reusable/Gallery';
import config from '@Config';

import styles from './GalleryBrick.module.scss';
import { IGalleryBrickProps } from './GalleryBrick.types';

const NO_PHOTOS_TEXT = 'Brak zdjęć w galerii.';

const GalleryBrick = ({
  cloudinaryTag,
  id,
  isLoading,
  photos,
}: IGalleryBrickProps) => {
  const [imageList, setImageList] = useState(photos);
  const cloudinaryApi = new sharedServices.CloudinaryApi(
    config.images.cloudName
  );

  useEffect(() => {
    if (cloudinaryTag) {
      cloudinaryApi.getImagesByTag(cloudinaryTag).then((cloudinaryPhotos) => {
        if (!cloudinaryPhotos) {
          return;
        }

        const imageArray =
          photos.length > 0
            ? [...photos, ...cloudinaryPhotos]
            : cloudinaryPhotos;
        setImageList(imageArray);
      });
    }
  }, [cloudinaryTag]);

  if (imageList.length === 0) {
    return (
      <div className={styles.gallery} id={id}>
        <p>{NO_PHOTOS_TEXT}</p>
      </div>
    );
  }

  return (
    <div className={styles.gallery} id={id}>
      <Gallery
        isLoading={isLoading}
        photos={imageList}
        vertical={true}
        anchor={id?.replace('-', '')}
      />
    </div>
  );
};

export default GalleryBrick;
