import { getType } from 'typesafe-actions';

import { resetTicketsState, updateSelectedSeats } from './../../actions';
import { IAction, IPoolsSelectedSeatsReducer } from './../../types';

export const initialState: IPoolsSelectedSeatsReducer = [];

const reducer = (
  state: IPoolsSelectedSeatsReducer = initialState,
  action: IAction
): IPoolsSelectedSeatsReducer => {
  switch (action.type) {
    // _UPDATE_SELECTED_SEATS
    case getType(updateSelectedSeats):
      return action.payload;

    // RESET_TICKETS_STATE
    case getType(resetTicketsState):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
