import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktop: {
      padding: '6.5rem 1.25rem 0 1.25rem',
    },
    root: {
      padding: '4rem 1rem 0 1rem',
    },
    sbm: {
      padding: '0',
    },
    withoutSidePaddingsDesktop: {
      padding: '6.5rem 0 0 0',
    },
    withoutSidePaddingsRoot: {
      padding: '4rem 0 0 0',
    },
  })
);

export default useStyles;
