import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getIframeParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { failSummaryMounted, unmount } from '@Model/transaction/actions';

import PaymentFail from './PaymentFail.component';
import {
  IPaymentFailFromDispatch,
  IPaymentFailFromState,
} from './PaymentFail.types';

function mapStateToProps(state: _Store.IState): IPaymentFailFromState {
  return {
    isEmbed: !!getIframeParams(state),
    lang: translate(state)('payment', 'paymentFail'),
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPaymentFailFromDispatch => {
  return {
    mounted: () => {
      dispatch(failSummaryMounted());
    },
    unmount: () => {
      dispatch(unmount());
    },
  };
};

export default connect<
  IPaymentFailFromState,
  IPaymentFailFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PaymentFail);
