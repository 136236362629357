import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import cn from 'classnames';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useStyles from './Occupancy.styles';
import { IOccupancyProps } from './Occupancy.types';

const Dot = ({ active }: { active: boolean }) => {
  const classes = useStyles();

  return (
    <Grid item={true} className={cn(classes.dot, active && classes.active)} />
  );
};

const Occupancy = ({ slot }: IOccupancyProps) => {
  const i18n = useDictionary();
  const classes = useStyles();

  const dotsCount = Math.ceil(
    (slot.spaces[0].capacityLeft / slot.spaces[0].capacity) * 4
  );

  const components: JSX.Element[] = Array.from(Array(4).keys()).map((key) => (
    <Dot key={key} active={key < dotsCount} />
  ));

  return (
    <Grid container={true} alignItems={'center'} justifyContent={'center'}>
      <Grid item={true} xs={12}>
        <Typography variant={'caption'} className={classes.text}>
          {i18n.buy.happening.availability}
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true} justifyContent="center">
          {components}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Occupancy;
