import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISelectionReducer, ISelectionReducerCurrentKey } from './../types';
import get from './get';

const getCurrentKey = createSelector<
  _Store.IState,
  ISelectionReducer,
  ISelectionReducerCurrentKey
>([get], (selection) => selection.currentKey);

export default getCurrentKey;
