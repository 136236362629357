import moment from 'moment';
import { createSelector } from 'reselect';

import getAvailabilities from './getAvailabilities';
import getDate from './getDate';

const getTimeSlots = createSelector(
  [getDate, getAvailabilities],
  (date, availabilities) => {
    if (!date || !availabilities) {
      return [];
    }

    const now = moment.utc().valueOf();

    const hours = Object.keys(availabilities || {});

    return hours.map((hour) => {
      const time = moment(hour).utc().valueOf();

      const disableByTime = now > time;

      const isSelected = date?.slot === hour;

      return {
        isDisabled: disableByTime,
        isReserved: false,
        isSelected,
        start: hour,
      };
    });
  }
);

export default getTimeSlots;
