import { connect } from 'react-redux';

import _Store from '@Store';

import { getCompositionIdentifier } from '@Model/pages/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import SlidableCards from './SlidableCards.component';
import {
  ISlidableCardsFromState,
  ISlidableCardsOwnProps,
} from './SlidableCards.types';

const mapStateToProps = (state: _Store.IState): ISlidableCardsFromState => {
  return {
    isLoading: getLoading(CONST.SECTIONS)(state),
    compositionIdentifier: getCompositionIdentifier(state),
  };
};

export default connect<
  ISlidableCardsFromState,
  null,
  ISlidableCardsOwnProps,
  _Store.IState
>(mapStateToProps)(SlidableCards);
