import { connect } from 'react-redux';

import _Store from '@Store';

import Products from '@Compo/Happening/components/Products/Products.component';
import { IProductsFromState } from '@Compo/Happening/components/Products/Products.types';
import { translate } from '@Model/locale/selectors';
import { getProducts } from '@Model/products/selectors';
import { getTheme } from '@Model/theme/selectors';

const mapStateToProps = (state: _Store.IState): IProductsFromState => ({
  products: getProducts(state),
  lang: translate(state)('buy', 'products'),
  selectedTheme: getTheme(state),
});

export default connect<IProductsFromState, void, {}, _Store.IState>(
  mapStateToProps
)(Products);
