import React from 'react';

import cn from 'classnames';

import DualLink from '@Compo/reusable/DualLink';
import icons from '@Misc/helpers/icons';
import prepareEventLink from '@Misc/helpers/prepareEventLink';

import styles from './EventCards.module.scss';
import { IEventCardsProps } from './EventCards.types';

const SOCIAL_BUTTON_TEXT = 'Dołącz';
const SECTION_TITLE_TEXT = 'Bilety';
const SECTION_ID = 'tickets';
const BUY_BUTTON_TEXT = 'Kup Bilet';
const BUY_BUTTON_DISABLED_TEXT = 'Niedostępne';
const SEPARATOR_TEXT = '❖';

const EventCards = ({
  backgroundGradient,
  borderColorScheme,
  buttonHover,
  buttonOut,
  buttonScheme,
  cardTitleScheme,
  events,
  isDark,
  sectionTitleScheme,
}: IEventCardsProps) => {
  return (
    <>
      <h2
        className={styles.sectionTitle}
        id={SECTION_ID}
        style={sectionTitleScheme}
      >
        {SECTION_TITLE_TEXT}
      </h2>
      <ul className={styles.cardDeck}>
        {events.map((event, id) => {
          const link = prepareEventLink(
            event.eventSlug,
            event.rundateSlug,
            false,
            event.isBuyLink
          );
          const buyButtonActive = event.eventSlug && !event.disableBuyButton;
          const mainTitle = event.city ? event.city : event.title;
          const buyButtonText = event.buttonText || BUY_BUTTON_TEXT;
          const disabledButtonText =
            event.buttonText || BUY_BUTTON_DISABLED_TEXT;

          return (
            <li
              className={cn(styles.card, isDark && styles.dark)}
              key={id}
              style={borderColorScheme}
            >
              <div
                className={styles.cardHeader}
                style={{
                  ...borderColorScheme,
                  ...backgroundGradient,
                }}
              >
                {SEPARATOR_TEXT}
              </div>
              <div className={styles.cardBody}>
                {event.date && (
                  <h2 className={styles.date}>
                    <b>{event.date}</b> {event.day}
                  </h2>
                )}
                <h3 className={styles.title} style={cardTitleScheme}>
                  {mainTitle}
                </h3>
                {event.placeName && (
                  <h3 className={styles.place}>{event.placeName}</h3>
                )}
              </div>
              <div className={styles.cardFooter}>
                {event.social && (
                  <DualLink to={event.social} newwindow={true}>
                    <button
                      className={styles.cardButton}
                      style={buttonScheme}
                      onMouseOver={buttonHover}
                      onMouseOut={buttonOut}
                    >
                      <span
                        className={cn(styles.socialIcon, icons.facebookSquare)}
                      />
                      {SOCIAL_BUTTON_TEXT}
                    </button>
                  </DualLink>
                )}

                {!buyButtonActive && (
                  <button
                    className={cn(styles.cardButton, styles.disabled)}
                    type="button"
                    style={buttonScheme}
                    onMouseOver={buttonHover}
                    onMouseOut={buttonOut}
                  >
                    {disabledButtonText}
                  </button>
                )}
                {buyButtonActive && (
                  <DualLink to={link} newwindow={true}>
                    <button
                      className={styles.cardButton}
                      style={buttonScheme}
                      onMouseOver={buttonHover}
                      onMouseOut={buttonOut}
                    >
                      {buyButtonText}
                    </button>
                  </DualLink>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default EventCards;
