import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPlaceReducer, IPlaceReducerRequestTimes } from './../types';
import get from './get';

const getRequestTimes = createSelector<
  _Store.IState,
  IPlaceReducer,
  IPlaceReducerRequestTimes
>([get], (place) => place.requestTimes);

export default getRequestTimes;
