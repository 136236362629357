import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import config from '@Config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      '&:visited': {
        color: config.theme.isEmpik
          ? theme.palette.primary.main
          : theme.palette.text.primary,
      },
      color: theme.palette.text.primary,
    },
  })
);

export default useStyles;
