import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEntryListReducer } from './../types';
import get from './get';

const getSmsCode = createSelector<_Store.IState, IEntryListReducer, string>(
  [get],
  (state) => state.smsCode
);

export default getSmsCode;
