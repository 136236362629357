import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import {
  IPremiumCheckCardStatusValues,
  IPremiumSuccessPayload,
  IPremiumToken,
  UserCardStatusEnum,
} from './../types';

export const getPremiumUserByToken = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IPremiumSuccessPayload, Error>();

export const getUserCardStatus = createAsyncAction(
  CONSTS._GET_STATUS_REQUEST,
  CONSTS._GET_STATUS_SUCCESS,
  CONSTS._GET_STATUS_FAILURE
)<IPremiumCheckCardStatusValues, UserCardStatusEnum, Error>();

export const redirect = createStandardAction(CONSTS.REDIRECT)();

export const checkUserCardStatus = createStandardAction(
  CONSTS.CHECK_CARD_STATUS
)<IPremiumCheckCardStatusValues>();

export const setToken = createStandardAction(CONSTS.SET_TOKEN)<IPremiumToken>();
