const utcOffsetFixer = (
  date: Date | null,
  mode: 'add' | 'subtract'
): Date | undefined => {
  if (date) {
    const utcOffset = date.getTimezoneOffset();
    const utcOffsetInMs = utcOffset * 60000;
    const dateInMs = date.getTime();
    const fixedDateInMs =
      mode === 'add' ? dateInMs + utcOffsetInMs : dateInMs - utcOffsetInMs;
    const fixedDate = new Date(fixedDateInMs);

    return fixedDate;
  }
};

export default utcOffsetFixer;
