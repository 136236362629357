import en from '@/lang/en';
import pl, { Translation } from '@/lang/pl';

interface ILocaleConfig {
  fallbackLocale: string;
  supportedLocales: {
    [key: string]: string;
  };
  langMaps: {
    [key: string]: Translation;
  };
}

const i18nConfig: ILocaleConfig = {
  fallbackLocale: 'en',
  langMaps: {
    en,
    pl,
  },
  supportedLocales: {
    en: 'English',
    pl: 'Polski',
  },
};

export default i18nConfig;
