import React, { useEffect, useRef } from 'react';

import { useHistory } from 'react-router';

import config from '@Config';
import loadDOMScript from '@Misc/helpers/loadDOMScript';
import priceFormatter from '@Misc/helpers/priceFormatter';
import { specialEventNames } from '@Model/event/constants/specialEvents';

import { IChart, ISeatsChartProps } from './SeatsChart.types';

const SEATS_CONTAINER_ID = 'seats-container';
const UNAVAILABLE_TEXT = 'Niedostępne';
// Time to clear selection before heldToken expired
const TIME_TO_CLEAR_SELECTION = 595000;

const SeatsChart = (props: ISeatsChartProps) => {
  const seatingChartRef: any = useRef(null);
  const timerRef = useRef<NodeJS.Timeout | undefined>();
  const history = useHistory();

  const {
    eventId,
    onSeatsDeselected,
    onSeatsSelected,
    onSelectionInvalid,
    onSelectionValid,
    maxSelectedSeats,
    pricing,
    publicKey,
    selectedSeats,
    selectedPool,
    pools,
    specialEvent,
  } = props;

  const selectedPoolCategory = pools.find(
    (pool) => pool.id === Number(selectedPool)
  );

  const category = selectedPoolCategory?.seatsIoCategories[0].categoryKey;

  const isOrphanSeatsAllowed =
    specialEvent === specialEventNames.jimekHistoriaPolskiegoHH;

  const onChartRendered = selectedPool
    ? (chart: IChart) => {
        const isPrice = chart.config.pricing.find(
          (element) => element.category === category
        )?.price;
        if (!!isPrice) {
          chart.selectBestAvailable({
            number: 1,
            category,
          });
        } else {
          chart.selectBestAvailable({
            ticketTypes: {
              [selectedPool]: 1,
            },
          });
        }
      }
    : () => {};

  const loadChart = () => {
    seatingChartRef.current = new window.seatsio.SeatingChart({
      divId: SEATS_CONTAINER_ID,
      event: eventId,
      holdOnSelectForGAs: true,
      language: 'pl',
      maxSelectedObjects: maxSelectedSeats,
      onChartRendered,
      onObjectDeselected: onSeatsDeselected,
      onObjectSelected: onSeatsSelected,
      onSelectionInvalid,
      onSelectionValid,
      priceFormatter: (price: number) => `${priceFormatter(price)} zł`,
      pricing,
      publicKey,
      selectionValidators: isOrphanSeatsAllowed
        ? undefined
        : [{ type: 'noOrphanSeats' }],
      session: 'continue',
      unavailableCategories: [
        UNAVAILABLE_TEXT,
        UNAVAILABLE_TEXT.toUpperCase(),
        `${UNAVAILABLE_TEXT.toLowerCase()
          .charAt(0)
          .toUpperCase()}${UNAVAILABLE_TEXT.slice(1)}`,
      ],
    }).render();
  };

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      seatingChartRef.current.clearSelection();
    }, TIME_TO_CLEAR_SELECTION);
  };

  useEffect(() => {
    resetTimer();
  }, [selectedSeats]);

  useEffect(() => {
    if (!window.seatsio) {
      loadDOMScript(config.seats.url).then(loadChart);
    } else {
      loadChart();
    }
    return () => {
      clearTimeout(timerRef.current);
      seatingChartRef.current
        ?.clearSelection()
        .then(() => seatingChartRef.current?.destroy());
    };
  }, [eventId]);

  useEffect(() => {
    const unblock = history.block(({ pathname }) => {
      seatingChartRef.current?.clearSelection().then(() => {
        if (seatingChartRef.current?.selectedObjects.length === 0) {
          unblock();
          history.push(pathname);
        }
      });

      return false;
    });
  }, [JSON.stringify(seatingChartRef.current?.selectedObjects)]);

  useEffect(() => {
    const onUnload = () => {
      seatingChartRef.current
        ?.clearSelection()
        .then(() => seatingChartRef.current?.destroy());
    };

    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  return <div id={SEATS_CONTAINER_ID} ref={seatingChartRef} />;
};

export default SeatsChart;
