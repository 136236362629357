import React from 'react';

import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import MyTicket from '@Compo/ticketsManagement/MyTicket';
import { IBreadcrumb } from '@Model/breadcrumb/types';
import routes from '@Routes/routes';

const MY_TICKET_TEXT = 'Zarządzaj biletami';

const breadcrumbs: IBreadcrumb[] = [
  {
    link: routes.myTicket,
    name: MY_TICKET_TEXT,
  },
];

const MyTicketPage = () => (
  <MuiGrid>
    <Helmet pageName={MY_TICKET_TEXT} />
    <FullTriangleBackground />
    <MuiSectionContainer>
      <MuiBreadcrumbs base={breadcrumbs} />
      <MyTicket />
    </MuiSectionContainer>
  </MuiGrid>
);

export default MyTicketPage;
