import { combineReducers } from 'redux';

import availabilities from './availabilities';
import data from './data';
import metadata from './metadata';
import selected from './selected';
import terms from './terms';

const reducer = combineReducers({
  availabilities,
  data,
  metadata,
  selected,
  terms,
});

export default reducer;
