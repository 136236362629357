import { connect } from 'react-redux';

import _Store from '@Store';

import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import FormioBrick from './FormioBrick.component';
import {
  IFormioBrickFromState,
  IFormioBrickOwnProps,
} from './FormioBrick.types';

const mapStateToProps = (state: _Store.IState): IFormioBrickFromState => ({
  isLoading: getLoading(CONST.SECTIONS)(state),
});

export default connect<
  IFormioBrickFromState,
  void,
  IFormioBrickOwnProps,
  _Store.IState
>(mapStateToProps)(FormioBrick);
