import React, { useEffect, useState } from 'react';

import { Grid, IconButton, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import cn from 'classnames';
import RcSlider from 'rc-slider';

import styles from './Slider.module.scss';
import useStyles from './Slider.styles';
import { ISliderProps } from './Slider.types';

const DEFAULT_STEP = 1;

const Slider = ({
  className,
  maxValue,
  minValue,
  step: _step,
  value: initialValue,
  onChange,
  theme,
}: ISliderProps) => {
  const step = _step || DEFAULT_STEP;
  const [value, setValue] = useState(initialValue || 0);
  const muiStyles = useStyles();
  const changeValue = (val: number) => {
    setValue(val);
    onChange(val);
  };

  useEffect(() => {
    changeValue(initialValue || 0);
  }, [initialValue]);

  const up = () => {
    if (value < maxValue) {
      changeValue(value + step);
    }
  };

  const down = () => {
    if (value > (minValue || 0)) {
      changeValue(value - step);
    }
  };

  if (theme !== 'default') {
    return (
      <Grid className={muiStyles.wrapper} container={true}>
        <Grid item={true}>
          <IconButton size={'medium'} color={'primary'} onClick={down}>
            <RemoveCircleOutlineIcon fontSize={'large'} />
          </IconButton>
        </Grid>
        <Grid item={true} xs={true} className={styles.wrapperSlider}>
          <div className={cn(styles.wrapper, muiStyles.sliderWrapper)}>
            <RcSlider
              className={cn(className, styles.slider, muiStyles.slider)}
              onChange={changeValue}
              value={value}
              min={minValue || 0}
              max={maxValue}
              step={step}
            />
          </div>
          <Typography variant={'body1'} className={muiStyles.value}>
            {value}
          </Typography>
        </Grid>
        <IconButton size={'medium'} color={'primary'} onClick={up}>
          <AddCircleOutlineIcon fontSize={'large'} />
        </IconButton>
      </Grid>
    );
  }

  return (
    <div className={styles.mainWrapper}>
      <span className={styles.additionalButton} onClick={down}>
        -
      </span>
      <div className={styles.wrapper}>
        <RcSlider
          className={cn(className, styles.slider)}
          onChange={changeValue}
          value={value}
          min={minValue || 0}
          max={maxValue}
          step={step}
        />
      </div>
      <span className={styles.additionalButton} onClick={up}>
        +
      </span>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default Slider;
