import { Selector } from 'reselect';

import _Store from '@Store';

import { IPoolsSelectedTicketsReducer } from './../types';

const getSelectedTickets: Selector<
  _Store.IState,
  IPoolsSelectedTicketsReducer
> = (state) => state.pools.selectedTickets;

export default getSelectedTickets;
