import { connect } from 'react-redux';

import _Store from '@Store';

import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import GalleryBrick from './GalleryBrick.component';
import {
  IGalleryBrickFromState,
  IGalleryBrickOwnProps,
} from './GalleryBrick.types';

const mapStateToProps = (state: _Store.IState): IGalleryBrickFromState => ({
  isLoading: getLoading(CONST.SECTIONS)(state),
});

export default connect<
  IGalleryBrickFromState,
  null,
  IGalleryBrickOwnProps,
  _Store.IState
>(mapStateToProps)(GalleryBrick);
