import React, { useEffect, useState } from 'react';

import CarouselFixed from '@/components/reusable/CarouselFixed';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';

import StatusBar from '@Compo/Search/components/StatusBar/StatusBar.container';
import Event from '@Compo/reusable/Event';
import EventTile from '@Compo/reusable/EventTile';
import SimpleLoading from '@Compo/reusable/SimpleLoading';
import mobile_banner from '@Styles/images/baner_kalendarium_810x700.png';
import desktop_banner from '@Styles/images/baner_kalendarium_1248x415.png';

import classes from './ResultsList.classes';
import styles from './ResultsList.module.scss';
import { IResultsListProps, IResultsListState } from './ResultsList.types';

const ABOUT_APP_LINK = 'https://goingapp.pl/o-aplikacji';
const BANNER_ALT_TEXT = 'o aplikacji';
const BANNER_POSITION = 1;
const EVENT_BANNER_LABEL = 'baner kalendarium';
const NO_RESULTS_TEXT = 'Brak wyników.';

const initialState: IResultsListState = {
  items: [],
};

const ResultsList = ({
  events,
  eventBannerClick,
  isMobile,
  location,
  phrase,
  resetOffset,
  search,
  searchLoadingStatus,
  searchOffset,
  setOffset,
  tileView,
}: IResultsListProps) => {
  const [ref, inView] = useInView({ threshold: 0.01 });
  const [state, setState] = useState(initialState);
  const onBannerClick = () => {
    eventBannerClick(EVENT_BANNER_LABEL);
  };
  const getMoreItems = () => {
    setOffset();
    search(searchOffset);
    setState({
      items: events,
    });
  };

  useEffect(() => {
    setState({
      items: events,
    });
  }, [events]);

  useEffect(() => {
    setState({ ...state });
  }, [inView]);

  useEffect(() => {
    if (inView && events.length > 0) getMoreItems();
  }, [inView, events.length]);

  useEffect(() => {
    if (inView) {
      setState(initialState);
    }
  }, [location.pathname]);

  useEffect(() => {
    resetOffset();
  }, [phrase]);

  const renderResults = state.items.map((event, index) => {
    const element = tileView ? (
      <EventTile {...event} key={event.id + index} />
    ) : (
      <Event {...event} key={event.id + index} />
    );

    if (index === BANNER_POSITION) {
      return (
        <div key={'banner_' + event.id.toString()}>
          {element}
          <div className={cn(styles.banner)} onClick={onBannerClick}>
            <CarouselFixed
              images={[
                {
                  alt: BANNER_ALT_TEXT,
                  link: ABOUT_APP_LINK,
                  wideImage: isMobile ? mobile_banner : desktop_banner,
                },
              ]}
              isLoading={false}
              GAclass={classes.gaClass}
            />
          </div>
        </div>
      );
    }

    return element;
  });

  return (
    <>
      <StatusBar />
      <ul className={cn(styles.resultsList, tileView && styles.tiles)}>
        {!events.length ? <span>{NO_RESULTS_TEXT}</span> : renderResults}
      </ul>
      <div ref={ref} data-test-id={'intercept-component'}>
        {searchLoadingStatus && <SimpleLoading relative={true} />}
      </div>
    </>
  );
};

export default ResultsList;
