import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import { ITag } from '@Compo/reusable/Tags/Tags.types';
import config from '@Config';

import styles from './Tag.module.scss';
import useStyles from './Tag.styles';

const Tag = ({ name, isMain, disableCapitalize }: ITag) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <div
      className={cn(styles.tag, {
        [styles.tagMain]: isMain,
        [styles.tagOther]: !isMain,
        [muiStyles.fontColor]: isMain,
        [muiStyles.secondary]: !isMain,
        [styles.disableCapitalize]: disableCapitalize,
      })}
    >
      {isMain || config.theme.isGoing ? name : `#${name}`}
    </div>
  );
};

export default Tag;
