const isInInterval = (
  from: number | null,
  to: number | null,
  hash?: number
) => {
  if (!hash) {
    return false;
  }

  if (from) {
    if (!to) {
      to = from;
    }

    return hash >= from && hash <= to;
  }

  return false;
};

export default isInInterval;
