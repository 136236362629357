/**
 * Function replaces URL params placeholders with corresponding values
 *
 * @param {string} route - Source url.
 * @param {string[] | string} params - Param or params to be replaced (prefix with `:`).
 * @param {string[] | string} values - Values for corresponding keys.
 * @return {string} Destination url.
 */
const fillUrlWithValues = (
  route: string,
  params: string[] | string,
  values: string[] | string
) => {
  const arrayKeys = typeof params === 'string' ? [params] : params;
  const arrayValues = typeof values === 'string' ? [values] : values;

  let replacedUrl = route;

  for (let i = 0; i < arrayKeys.length; i++) {
    if (arrayValues[i] !== undefined) {
      replacedUrl = replacedUrl.replace(arrayKeys[i], arrayValues[i]);
    }
  }

  return replacedUrl;
};

export default fillUrlWithValues;
