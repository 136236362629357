import React, { FC } from 'react';

import cn from 'classnames';

import SimpleLoading from '@Compo/reusable/SimpleLoading';
import useArrowsSlider from '@Misc/hooks/useArrowsSlider';

import styles from './ArrowSlider.module.scss';
import { IArrowSliderProps } from './ArrowSlider.types';

const ArrowSlider: FC<IArrowSliderProps> = ({
  children,
  id,
  isBrowser,
  isDesktop,
  length,
  verticalImage,
  windowWidth,
  elementsMargin = 16,
  usePadding = false,
}) => {
  if (children && isBrowser) {
    const {
      containerRef,
      listRef,
      load,
      next,
      previous,
      showNext,
      showPrevious,
      useNativeScroll,
    } = useArrowsSlider(
      length,
      isDesktop,
      windowWidth,
      28,
      elementsMargin,
      usePadding
    );

    return (
      <div className={styles.mainContainer} id={id} ref={containerRef}>
        <ul
          className={cn(
            styles.arrowSlider,
            useNativeScroll && styles.nativeScroll
          )}
          ref={listRef}
        >
          {load && children}
        </ul>
        {!load && <SimpleLoading />}
        {showPrevious && (
          <div
            className={cn(
              styles.arrow,
              styles.left,
              verticalImage && styles.verticalImage
            )}
            onClick={previous}
          />
        )}
        {showNext && (
          <div
            className={cn(
              styles.arrow,
              styles.right,
              verticalImage && styles.verticalImage
            )}
            onClick={next}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <ul className={styles.arrowSlider}>{children}</ul>
    </div>
  );
};

export default ArrowSlider;
