import { createSelector } from 'reselect';

import config from '@Config';
import { getLocation } from '@Model/router/selectors';

import { IMetaForCurrentUrl } from './../types';

const staticPageMeta = createSelector(
  [getLocation],
  (location): IMetaForCurrentUrl => {
    return {
      currentUrl: `${config.app.baseUrl}${location.pathname}`,
      customGtmId: location.query?.gtmId,
    };
  }
);

export default staticPageMeta;
