import routes from '@Routes/routes';
import { FavoritesTypes } from '@Types/Favorites';

import { Translation } from './pl';

const en: Translation = {
  artist: {
    nearest: 'Nearest concert',
    noUpcomingEvents: 'Brak nadchodzących wydarzeń',
    pastEvents: 'The event that took place',
    upcomingEvents: 'Upcoming events',
  },
  basket: {
    courierDelivery: 'Courier delivery:',
    details: 'Details',
    hide: 'Hide',
    pieces: 'pcs',
    pricePerItem: 'Price/pcs:',
    products: 'Products',
    serviceFee: 'Service fee:',
    ticketsNum: 'Quantity:',
    totalPrice: 'Total price:',
  },
  breadcrumbs: {
    activities: 'Activities',
    events: 'Events',
  },
  buy: {
    agreements: {
      acceptAgreements: 'Accept necessary fields',
      acceptAgreementsDetailed:
        'Accept necessary fields and provide ticket details',
      additionalData: '(requires additional data)',
      agreementsAndRegulations: 'Consents and regulations',
      fillInData: "Fill with buyer's data",
      iWantNewsletter: "I'd like to receive Going. Mustsee newsletter.",
      newsletterAgreement:
        'By subscribing to the Going Application Newsletter I consent to processing of provided personal data by Going Spółka z ograniczoną odpowiedzialnością with its registered seat in Warsaw, ul. Jana Szymczak 3/36, 01 - 227 Warsaw, for the purpose of receiving commercial information, according to the art. 9 of the Act of June 9, 2017 on the provision of electronic services (Dz. U. from 2017, pos. 1219).',
      requiredFields:
        '*to complete the order, you must check the boxes marked with an asterisk.',
      requiredFieldsAdditional:
        'For tickets that do not require unique data, the information entered in section 3 will be used.',
      selectConsents: 'Select the consents for this event',
    },
    clientData: {
      didYouMean: 'Did you mean: ',
      discountAdded: 'Added a discount.',
      discountRemoved: 'Discount removed.',
      discountCode: 'Discount code',
      discountCodeTooltip:
        'Discount code generated as part of promotional campaigns',
      discountCodeTooltipSaltos:
        'Discount codes for partner cards can be found in the graphic above',
      discountCodeInputEmpty: 'Discount code input cannot be empty',
      discountUnavailableForBasketSales:
        '. NOTE: Discount codes are temporarily unavailable for tickets added to the cart. To use a discount code, remove tickets from your cart and make a purchase directly on the event page in Going.',
      discountUnavailableForBasketSalesEmpik:
        '. NOTE: Discount codes are temporarily unavailable for tickets added to the cart. To use a discount code, remove tickets from your cart and make a purchase directly on the event page in Empik Bilety',
      eMail: 'E-mail address',
      fillFormWithLastData: 'Complete with data',
      firstName: 'Name',
      getDataFrom: 'Get your e-mail from:',
      getLastData: 'Do you want to complete the form with the last given data?',
      iHaveDiscount: "I've got discount code",
      iHaveDiscountGoing: 'I have a discount code',
      iHavePass: 'I Have Pass',
      iHavePrepaid: 'I have a gift card',
      iHavePrepaidGoing: 'I have a Going gift card.',
      id: ', the Staff may ask for an id to verify your ticket.',
      lastName: 'Last name',
      namedTicket: 'The ticket is assigned to your name. Provide',
      pass: 'Pass number',
      phone: 'Phone number',
      prepaidCard: 'Enter the gift card code.',
      prepaidCardGoing: 'Enter the Going gift card code.',
      prepaidCardInputEmpty:
        'Input for a gift card or voucher cannot be empty.',
      prepaidTooltip:
        'Going voucher. (received as part of the ticket return or complaint) Going Gift Card. (purchased by Empik.com)',
      provideData: 'Provide your data',
      realData: 'real data',
      validEmail: 'Make sure the e-mail address is correct',
      willSendTickets:
        'Purchased tickets will be sent to the address provided.',
    },
    empikCardNumber: {
      buyNow: 'Buy now',
      cardDescription:
        'If you are an Empik Premium Customer, enter your Customer code assigned to the e-mail address provided by you. The Customer Code can be found in the Empik application in the Premium tab. Find out more ',
      empikCardNumber: 'Premium user number',
      here: 'here',
      premiumUser: 'Empik Premium user',
      premiumUserData: 'Empik Premium user data',
    },
    formLayout: {
      buttonAdd: 'Add to cart',
      buttonPay: 'Go to payment',
      buyAndPay: 'Buy and pay',
      formInvalid:
        "Something's wrong. Correct the details in the form and go to the city!",
      summary: 'Summary',
    },
    happening: {
      selectNumberOfPlayers: 'Choose the number of players',
      selectDateAndTime: 'Choose a date and time',
      date: 'Date',
      time: 'Time',
      selectSpace: 'Choose a space',
      selectTicketType: 'Select the type of ticket',
      relatedProducts: 'Related products',
      upsell: 'Add an extra time slot',
      book: 'Book it',
      checkDiscount: 'Check discount',
      enterDiscountCode: 'Enter the discount code',
      noMoreSlots:
        'Unfortunately, reservations cannot be made on the selected date.',
      selectTime: 'Choose the hour',
      availability: 'Availability',
    },
    insurance: {
      accidentInsurance:
        'Are you interested in insuring your ticket in case of inability to participate in the event, e.g. due to personal injury as a result of accidental accident, temporary inability to work, road collision, or serious illness?',
      birthdayPlaceholder: 'YYYY-MM-DD',
      choose: 'I would like to insure the ticket(s).',
      consumerRights:
        'Information obligations resulting from the Act on consumer rights',
      iAmAdult: 'Are you over 18 years?',
      iLiveInPoland: 'Do you live permanently in Poland?',
      insuranceBirthDateHelperText: 'Please enter your date of birth.',
      insuranceHeader: 'Ticket insurance',
      insuranceProductInfo: 'Insurance Product Information (PID)',
      insuranceNotAvailableforBasket:
        'Insurance is temporarily unavailable for tickets added to the cart. To buy insurance, remove tickets from your cart and make a purchase directly on the event page in Going.',
      insuranceNotAvailableforBasketEmpik:
        'Insurance is temporarily unavailable for tickets added to the cart. To buy insurance, remove tickets from your cart and make a purchase directly on the event page in Empik Bilety.',
      moreInsuranceInfo: 'General insurance Conditions',
      rodo: 'Processing of personal data',
      tooManyTickets:
        'The maximum number of tickets that can be insured is 6. Please remove tickets from the basket so their number does not exceed the limit.',
      invalidInsuranceData: 'Invalid insurance data.',
      invalidInsuranceFormData:
        'See if you can check the boxes with an asterisk. If not, then the insurance coverage is not suitable for you.',
      invalidTicketPrice:
        'Insurance is only available for tickets priced between 5 PLN and 2500 PLN. Remove tickets priced outside this range from your cart to add the insurance.',
      tooltipPart1:
        'The insurance covers absence from the event as a result of, for example:',
      tooltipPart2:
        'You can get a refund of the purchase price of the ticket (s) for the event. Details of the insurance can be found in the general terms and conditions of the insurance. We will send the policy by e-mail after the conclusion of the insurance contract. The insurer is UNIQA.',
      tooltipLi1: 'accident or road collision',
      tooltipLi2: 'delay of the means of transport',
      tooltipLi3: 'you are seriously ill',
      tooltipLi4: 'bone fractures',
      tooltipLi5: 'body trauma as a result of an unexpected accident',
      tooltipLi6: 'your child becomes ill',
      tryAgain: 'Try again',
    },
    invoice: {
      address: 'Address',
      city: 'City',
      iWantInvoice: 'I want to get an invoice',
      legalA:
        'In accordance with the law changes from January 1st 2020, you will be able to receive a VAT invoice only when you select the option "Receive an invoice" and provide the necessary data.',
      legalB:
        ' Legal note: art. 106b ust. 5–7, art. 109a ustawy z 11 marca 2004 r. o podatku od towarów i usług j.t. Dz.U. z 2018 r. poz. 2174, ost.zm. Dz.U. z 2019 r. poz. 2200 oraz art. 15 ustawy z 4 lipca 2019 r. o zmianie ustawy o podatku od towarów i usług oraz niektórych innych ustaw Dz.U. z 2019 r. poz. 1520 ',
      less: '[less]',
      more: '[more]',
      name: 'Company name or first and last name',
      nip: 'Tax Identification Number',
      zip: 'ZIP code',
      country: 'Country',
    },
    paymentMethod: {
      blik: 'BLIK',
      card: 'Card',
      choosePaymentMethod: 'Choose payment method',
      payu: 'PayU',
      mBank: 'mBank',
      emptyPaymentMethods:
        'No payment method. Some of the selected tickets may have forced payment methods.',
      confirmCardData: 'Confirm card data',
      cardDataConfirmed: 'Card data confirmed successfully!',
      cardDataError: 'Card data confirmation failed. Please try again.',
    },
    pickUpWay: {
      courierDelivery: 'Courier delivery ',
      pickUp: 'Decide how you want to get your tickets',
      pickUpProduct: 'Provide data for product shipments',
      rightAway: 'You will get your tickets immediately',
      sendAsEmail: 'E-ticket (e-mail)',
      tenDaysDispatch: 'Shipping within 10 days',
    },
    pools: {
      additionalData: 'Additional data',
      dependency:
        'Enter the ticket or pass code authorizing you to purchase from the selected pool.',
      dependencyPlaceHolder: 'Ticket code or pass code',
      details: 'Details',
      noPoolsAvailable: 'No pools available at the moment.',
      sameCurrency:
        'The order includes a ticket in a different currency. Remove it to select this pool.',
      title: 'Choose ticket types and quantity',
    },
    postAddress: {
      cityText: 'City',
      collectorsTicket: "Collector's ticket pattern",
      countryText: 'Country',
      firstNameText: 'First name',
      infoText:
        'By choosing courier delivery, you agree to the transfer of your personal data to the shipping operator.',
      lastNameText: 'Last name',
      localText: 'Flat',
      phoneText: 'Phone number',
      streetNumberText: 'Number',
      streetText: 'Street',
      zipText: 'ZIP code',
    },
    products: {
      title: 'Choose product types and quantity',
      collectorTicket: `The collector's ticket does not entitle you to enter the event. Entrance possible only with an electronic ticket.`,
    },
    summary: {
      additionalProducts: 'Additional products:',
      amount: 'Quantity:',
      courierDelivery: 'Courier delivery:',
      currency: ' zł',
      defaultPriceType: 'Normal ticket',
      empty: 'no selected activity',
      finalPrice: 'Total:',
      formInvalid:
        'Unfortunately, the form is not complete. Please fill in the missing fields.',
      giftWrapper: 'Gift wrap:',
      iHaveCoupon: 'I have a coupon',
      insurance: 'Insurance:',
      insuranceWithCurrentEventTickets: 'Insurance (basket + active event):',
      noData: 'No data',
      numberOfPlayers: 'Number of players:',
      payment: 'payment:',
      pricePerItem: 'Price for pc.:',
      serviceFee: 'Service fee:',
      serviceFeeInformation:
        'Service fee covers the costs of the transaction fee, ticket shipping automation, after-sales service and handling any individual notifications and complaints. It is charged together with the base rate when purchasing a ticket.',
      shipment: 'Delivery:',
      space: 'Chosen space:',
      ticketTransferFee: 'Ticket transfer fee:',
      ticketsAmount: 'Quantity:',
      ticketsPrice: 'Tickets price:',
      time: 'Time:',
      totalPrice: 'Total price:',
      yourBasket: 'Your basket',
      yourReservation: 'Your reservation',
      extendedSlotPrice: 'Normal price',
      extendedSlotDiscount: 'Amount of the discount',
    },
    term: {
      and: 'and',
      iAccept: 'I accept',
      iAcceptTerms: 'I accept terms',
      privacyPolicy: 'privacy policy',
      read: '[read]',
      regulations: 'terms of use',
    },
  },
  dates: {
    locale: 'en',
    today: 'Today',
    tomorrow: 'Tomorrow',
  },
  entryListL: {
    addBuyerStep: {
      title:
        'Do you want to add the person who made the purchase to the ticket?',
    },
    addNewPeopleToTicketStep: {
      addNewPerson: 'Add another person',
      checkData: 'Check that the data is correct',
      confirm: 'I confirm',
      title: 'You need more to complete the process',
    },
    addPeopleToTicketStep: {
      noData: 'No related entries',
      title: 'Select the people you want to add to the ticket',
    },
    back: 'Back',
    entryTerm: {
      confirm: 'Confirm',
      sendEmail: 'Send email',
    },
    haveTicketCodeStep: {
      haveTicketCode: 'Do you have a ticket code?',
      title: 'Fill in the additional data',
    },
    next: 'Next',
    no: 'No',
    numberOfPeopleStep: {
      inputHelperText:
        'Enter how many people are going to the event as part of the purchased ticket',
      inputText: 'Number of people on the ticket',
    },
    registerNewPerson: {
      title: 'Registration',
    },
    thanksStep: {
      back: 'Back to start',
      code: 'Your code binding is:',
      thanks: 'Thank you!',
    },
    ticketStep: {
      enterTicketCode: 'Enter the ticket code to add people to it',
      ticketCode: 'Ticket code',
      ticketCodeHelper: 'Enter the ticket code received by e-mail',
    },
    title: 'Entry list',
    yes: 'Yes',
  },
  errors: {
    authIdIsRequiredValidator: 'Identifier is required',
    cardActiveText: 'Mój Empik card matched, premium benefits activated.',
    checkingCardErrorValidator: 'Card validation failed. Try again later.',
    checkingCardInactiveValidator:
      'Mój Empik card matched, but Premium service it not active.',
    checkingCardNomatchValidator:
      'Provided e-mail address does not match Mój Empik card number or the card number is invalid.',
    checkingCardValidator: 'Checking card number...',
    checkingThisRegulationsIsRequiredValidator: 'Checking this box is required',
    dependencyCodeIsRequired: 'The ticket / passcode is required',
    discountCodeIsRequired: 'The discount code is required.',
    emailAddressFieldIsRequiredValidator: 'Fill the e-mail address box',
    emailAddressIsRequiredValidator: 'E-mail address is required',
    firstNameIsRequiredValidator: 'First name is required.',
    incorrectData: 'Incorrect format',
    incorrectEmpikCardFormatValidator:
      'Entered card number is not in the correct format',
    incorrectNip: 'TIN must contain digits only',
    incorrectPhoneNumber: 'Incorrect phone number',
    incorrectPostCodeValidator: 'Incorrect ZIP code',
    lastNameIsRequiredValidator: 'Last name is required',
    countryIsRequiredValidator: 'Country is required',
    providedEmailAddressIsIncorrectValidator:
      'Entered e-mail address is incorrect',
    providedIdIsIncorrectValidator: 'Entered identifier is incorrect',
    sorry: `We're Sorry`,
    thisFieldIsRequiredValidator: 'Fill this box',
    wrongBirthDate:
      'The birth date entered is incorrect or a wrong format was used',
  },
  event: {
    additionalDescriptionTitle: 'organizer information',
    anotherDateText: 'See other dates',
    artistBio: 'artist bio',
    buyTicket: 'Buy ticket',
    choose: 'CHOOSE',
    currency: ' zł',
    details: 'Types of tickets',
    disabledTooltip: 'Tickets currently unavailable at ',
    forFansOf: 'similar artists',
    from: 'from',
    gallery: 'gallery',
    hour: 'hour',
    noDescription: 'no description',
    noInfoAboutPrice: 'No information on the ticket price',
    otherDates: 'other dates',
    place: 'place',
    placeLink: 'go to the site of this place',
    readMore: 'Read more',
    participatingArtists: 'performing',
    samePlaceActivitiesTitle: 'other activities at this place',
    samePlaceEventsTitle: 'other events at this place',
    serviceFeeTooltip: 'A service charge may be added to the ticket price.',
    serviceFeeInformationBox:
      'A service fee is added to the ticket price, its amount can be checked directly in the basket. More information in the Rules and Regulations.',
    similarEventsTitle: 'similar events',
    tickets: 'tickets',
    timetable: 'Event plan',
  },
  favorites: {
    close: 'Close',
    stayFresh: 'Stay up to date!',
    [FavoritesTypes.Artists]: 'Artists',
    [FavoritesTypes.Places]: 'Places',
    [FavoritesTypes.Tags]: 'Tags',
  },
  following: {
    artistHeader: 'Follow artist',
    artistPrompt:
      'Enter your email address to receive information about this artist. ',
    artistTerm:
      'By signing up, you consent to the processing of your data by GOING Sp. z o. o. to receive information related to the artist.',
    getUpdates:
      'Receive notifications about important changes, new events and other news.',
    salesHeader: 'Notify me when sales start',
    salesPrompt:
      'Enter your e-mail address to receive information about the start of sales for this event.',
    salesTerm:
      'By signing up, you consent to the processing of your data by GOING Sp. z o. o. in order to receive information related to the event.',
    ticketsHeader: 'Notify me about tickets',
    ticketsPrompt:
      'Tickets sold out. Enter your e-mail address to be notified when tickets go on sale again.',
    ticketsTerm:
      'By signing up, you consent to the processing of your data by GOING Sp. z o. o. in order to receive information related to the event.',
    placeHeader: 'Watch place',
    placePrompt:
      'Enter your e-mail address to receive information about events in this place.',
    placeTerm:
      'By signing up, you consent to the processing of your data by GOING Sp. z o. o. to receive information related to the place.',
    rundateHeader: 'Add to calendar',
    rundatePrompt:
      'Enter your email address to follow this event and add it to your calendar.',
    rundateTerm:
      'By signing up, you consent to the processing of your data by GOING Sp. z o. o. in order to receive information related to the event.',
    yourEmail: 'Your e-mail address',
    emailValidate: 'Enter valid e-mail address',
    subscribe: 'Subscribe',
    success: 'Success!',
    successDownload:
      'Download the Going. app, to have everything at hand and always up to date.',
    successButtonYes: 'Yes please',
    successButtonNope: 'Not right now',
    failure: 'Something went wrong, please try again.',
  },
  formio: {
    langButton: 'Zmień na język polski',
    title: 'Contact form',
  },
  happening: {
    priceTypes: {
      choose: 'Choose',
      chosen: 'Chosen',
      defaultTicket: 'Normal ticket',
    },
    space: {
      unavailableTicketTypes:
        'This space is unavailable because you chose following ticket types: ',
    },
    upsell: {
      add: 'Add',
      added: 'Added',
      clickAgainToResign: 'Click again to resign',
      getAdditionalSlot: 'Add an additional slot',
      upsellDuration: 'The reservation will last until: ',
    },
  },
  modals: {
    ActionMessage: {
      close: 'Close',
      no: 'No',
      yes: 'Yes',
    },
  },
  passwordReset: {
    passwordResetForm: {
      confirmNewPass: 'Confirm the new password',
      emailAddress: 'Email address',
      emailAddressBoundToAccount: 'Email address bound to the account',
      incorrectEmail: 'Your e-mail is incorrect',
      newPass: 'Enter the new password',
      pageTitle: 'Reset the password',
      requiredField: 'Please fill this field',
      submitButton: 'RESET THE PASSWORD',
      successMessage: 'We did it! Your password has been changed.',
    },
  },
  payment: {
    paymentFail: {
      contactWithUs:
        'Contact us and let us know what happened, describing the exact situation in which the error occurred. We will check what is going on as soon as possible.',
      header: 'Something went wrong...',
      report: 'Report a problem',
      title: 'Error during transaction',
    },
    paymentSuccess: {
      altText: 'Going app.',
      altText2: 'Download the app',
      beUpToDate:
        'Stay up to date with the best events, compose your event calendar and keep tickets always at hand in the app.',
      confirm: 'Confirmation of the transaction',
      currency: ' zł',
      discoverApp: 'Discover the Going. app',
      discoverApp2: 'discover the going. app',
      downloadApp: 'Download the Going. app',
      enterNumber:
        'Enter the number to which you want to receive an SMS with the link to download the Going. application.',
      error: 'Error',
      getMore: 'Get even more with the Going app.!',
      getTicketByEmail:
        'You will get the tickets by e-mail within 24 hours, but we usually bust faster. You can also find tickets in our app.',
      getTicketByEmailEmpik:
        'You will get the tickets by e-mail within 24 hours, but usually we work faster. You can also find tickets in the Going. app',
      header: 'Thanks for placing the order!',
      helperText: 'The phone number is incorrect',
      importantInfo: 'Important info!',
      informationWillBeSend:
        'An invitation to take over the tickets will be sent to the address indicated on the form.',
      openApp: 'open app',
      orDownload: 'or download directly from your store',
      place: 'Place:',
      prefix: '+48',
      send: 'send',
      somethingWentWrong: 'Something went wrong',
      successMessage: 'SMS sent!',
      summary: 'Summary:',
      sugestNotToPrint:
        'Remind the person who receives the ticket that they do not have to print the ticket. They can show it by using their phone.',
      thanks: 'Thank you.',
      thanksForReceiving: 'Thank you for ticket reception.',
      tickets: 'Number of tickets:',
      transfer: 'The ticket transfer process has started.',
      waiting: 'Now we are waiting for the confirmation of the transaction...',
      yourBank:
        'If your bank does not confirm the transaction within 10 minutes, the order may be canceled.',
      yourOrder: 'Your order',
    },
  },
  promotionBanner: {
    altText: 'about aplication',
  },
  routes: {
    buyRoute: routes.engBuy,
    eventRoute: routes.engEvent,
  },
  sendMailSuccess: {
    title: 'Tickets are on their way!',
    description:
      'We have sent the tickets to the e-mail address provided in the order.',
    button: 'Go to the homepage',
  },
  ticketsManagement: {
    changeDate: {
      selectDate: 'Select a date',
      selectTime: 'Choose the hour',
    },
    myTicket: {
      back: 'Go back',
      cancel: 'Cancel',
      cancelTransferButton: 'Cancel transfer',
      cancelTransferMessage:
        'Are you sure you want to cancel the ticket transfer?',
      choose: 'Choose',
      downloadButton: 'Download',
      downloadTicket: 'Download PDF Ticket',
      pageTitle: 'Manage ticket',
      reschedule: 'Change the reservation date',
      reset: 'Manage another tickets',
      resetTicketCode: 'Enter a different ticket code',
      return: 'Return the ticket',
      returnButton: 'Return',
      returnMessage: 'Are you sure you want to return the ticket?',
      returnSuccess:
        'Your application has been registered. We will inform you about the next steps by e-mail.',
      refundOptionsMessage:
        'Choose the form in which you want to receive a refund for the ticket.',
      refundTransfer: 'Transfer',
      refundVoucherGoing: 'Voucher Going.',
      refundVoucherEmpik: 'Voucher Empik',
      saleClosed: 'Passing tickets to events with closed sales is not allowed.',
      transfer: 'Transfer the ticket',
      transferButton: 'Transfer',
      transferInProgress:
        'The ticket is waiting to be accepted by the recipient.',
      transferSuccess:
        'Thank you! The invitation to collect the ticket was sent to the e-mail address provided.',
      transferCancel:
        'Ticket delivery has been canceled. Your ticket should be returned to you within an hour.',
    },
    myTickets: {
      choose: 'Choose',
      pageTitle: 'Select the ticket',
    },
    ticketDataForm: {
      codePlaceholder: 'Ticket number',
      emailPlaceholder: 'E-mail address used during purchase',
      incorrectEmail: 'Your e-mail is incorrect',
      mustBeANumber:
        'Please enter a number with max. 2 decimal digits, after a dot',
      orderIdPlaceholder: 'Order number',
      pageTitle: 'Manage ticket',
      paymentSumPlaceholder: 'Payment sum',
      requiredField: 'Please fill this field',
      submitButton: 'Check',
      useTicketData: 'Fill in the ticket info:',
      useTransactionData: 'or use transaction data:',
    },
    ticketRecipience: {
      contactForm: 'contact form.',
      getTicket: 'Get the tickets',
      hello: 'Hello, ',
      namedTicket:
        'Remember that tickets are personal. If you notice an error in your data, please report it to us immediately through the ',
      poolSaleUnavailable:
        'Unfortunately passing tickets for events with closed sales is temporarily not available. We kindly ask you to contact our Support team via the contact form (the link is available in the bottom of the page under the "Kontakt" label). Please include the original ticket code in the message. Thank you for your patience.',
      submitButton: 'Pick up your tickets',
      toRecipience:
        'To get them, accept below agreements and use the PICK UP YOUR TICKETS button on the bottom of the page.',
      transferTicket:
        'On this site you can pick up the tickets sent to you for this event.',
    },
    ticketTransfer: {
      becauseStaffChecksId:
        ' to the person that receives the ticket, because the staff might check their identity.',
      chooseAmount: 'Choose the amount of tickets you would like to pass',
      descFirstParagraph: `Here you can transfer your ticket, check how to do it:`,
      descFirstBulletPointPart1: `Enter the details of the person you want to give the ticket to and click `,
      descFirstBulletPointPart2: `“pass the ticket”.`,
      descSecondBulletPointPart1: `The receiving person will receive a message `,
      descSecondBulletPointPart2: `in which he confirms receipt of the ticket, pays the ticket price and transfer fee.`,
      descThirdBulletPointPart1: `You will then `,
      descThirdBulletPointPart2: `receive a message `,
      descThirdBulletPointPart3: `confirming the transfer and a refund for the ticket minus the transfer fee.`,
      descSecondParagraph: `💲 The amount of the transfer fee is variable and depends on the arrangements with the event organizer.`,
      descThirdParagraphPart1: `You will pay `,
      descThirdParagraphPart2: `and the recipient will pay`,
      descFourthParagraphPart1: `Remember that you can always change your mind and cancel the transfer of the ticket. To do this, log in to My Ticket and use the `,
      descFourthParagraphPart2: `cancel transfer function.`,
      emailLabel: 'Tickets will be sent to the address provided below.',
      emailPlaceHolder: 'E-mail address',
      emailTip: 'Please make sure it is correct',
      errorMessage: `This ticket cannot be transferred. There are several reasons why this operation is not possible: Event Organizer didn't give us permission to change the details on the ticket, order with a VAT invoice or ticket purchased with a discount code. In case of any questions - contact us through `,
      errorMessageLinkLabel: 'this form.',
      errorTitle: 'Oops!',
      enterReceiversData: "Enter the receiver's data",
      firstName: 'First name',
      lastName: 'Last name',
      pageTitle: 'Pass the ticket',
      realData: 'real data',
      submitButton: 'Pass the ticket',
      ticketIsNominal: 'The ticket is assigned to the bearer. Provide',
      ticketsHeader: 'Pool 1',
    },
  },
  entryList: {
    starter: {
      title: 'Start',
      subtitle: 'Do you have a ticket code?',
      yes: 'Yes',
      no: 'No',
    },
    form: {
      title: 'Your data',
      additionalPerson: 'Accompanying person details',
      subtitle: 'Complete the form below and accept the terms and conditions',
      firstName: 'First name*',
      lastName: 'Last name*',
      email: 'E-mail address*',
      birthDay: 'Date of birth (DD/MM/YYYY)*',
      goBack: 'Back',
      goNext: 'Next',
      validEmail: 'Make sure the e-mail address is correct',
      didYouMean: 'Did you mean: ',
    },
    termsOfUse: {
      accept: 'I accept the ',
      statue: 'regulations',
      and: ' and ',
      privacyPolicy: 'privacy policy*',
    },
    dataConfirmation: {
      title: 'Confirm data',
      subtitle: 'Check, if everything is correct',
      goBack: 'Back',
      confirm: 'Confirm',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'E-mail address',
      birthDay: 'Date of birth',
    },
    greeting: {
      title: 'Thank you for your registration!',
      subtitle: 'Everything made easy. Have fun!',
      button: 'Go to the homepage',
    },
    parentForm: {
      title: 'Parent consent',
      subtitle:
        "Registration of minors requires the consent of a parent or legal guardian. Enter the person's details and phone number. They will receive an SMS from us with a code confirming consent and identity.",
      goBack: 'Back',
      goNext: 'Next',
      firstName: 'First name of the parent or guardian*',
      lastName: 'Last name of the parent or guardian*',
      phoneNumber: 'Phone number*',
    },
    smsCode: {
      title: 'Parent consent',
      subtitle:
        'Entering the SMS code is tantamount to accepting the regulations.',
      goBack: 'Back',
      goNext: 'Next',
      code: 'SMS code*',
      sendAgain: 'Resend the code',
      codeError: 'Invalid code',
    },
    validation: {
      requiredField: 'Field required',
      incorrectEmail: 'Incorrect e-mail',
      invalidDate: 'Invalid date format. The correct format is DD.MM.YYYY',
      privacyPolicy: 'You must accept the terms and conditions of privacy',
      incorrectPhoneNumber: 'Incorrect phone number',
    },
    agreementExist: {
      title: 'The user is already registered',
      subtitle:
        'The user with the data provided by you has already been registered.',
      submissionsCompleteTitle: 'Thanks for your registartion',
      submissionsCompleteHelper: 'The entry list is already filled.',
      homepage: 'Go to the homepage',
      goBack: 'Back',
    },
    ticketCode: {
      title: 'Enter ticket code',
      placeholder: 'Ticket code*',
      goBack: 'Back',
      goNext: 'Next',
    },
    submissionsSummary: {
      title: 'Entry list',
      helper: 'List of people assigned to the ticket.',
      goBack: 'Back',
      confirm: 'Confirm',
      submissionExist:
        'The person with the given data is already added to the ticket',
    },
    checkSubmission: {
      title: 'E-mail address',
      helper: 'Enter your email address',
      placeholder: 'E-mail address*',
      goBack: 'Back',
      goNext: 'Next',
    },
    selectSubmission: {
      title: 'Participant data',
      helper: 'Select data from the list or enter a new one',
      addNew: 'Add new data',
      goBack: 'Back',
      goNext: 'Next',
      additionalPerson: 'Accompanying person details',
      additionalPersonHelper: 'Choose a companion or add a new one',
    },
  },
};

export default en;
