import React from 'react';

import cn from 'classnames';

import DetailsLoading from '../loadings/DetailsLoading';
import styles from './ShortDescription.module.scss';
import { IShortDescriptionProps } from './ShortDescription.types';

const ShortDescription = ({
  description,
  isLoading,
  forcedVertical,
  customClassName,
}: IShortDescriptionProps) => {
  if (isLoading || !description) {
    return <DetailsLoading isShortDescription={true} />;
  }

  return (
    <div
      className={cn(
        forcedVertical ? styles.forcedVerticalWrapper : styles.wrapper,
        customClassName
      )}
    >
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default ShortDescription;
