import { createSelector } from 'reselect';

import _Store from '@Store';

import { getParams } from '@Model/internalRouter/selectors';
import { IInternalRouterParams } from '@Model/internalRouter/types';
import { IPaymentMatchParams } from '@Routes/types';

const getTransactionIdFromLocation = createSelector<
  _Store.IState,
  IInternalRouterParams,
  string | null
>([getParams], (_params) => {
  const params = _params as IPaymentMatchParams;

  if (params) {
    return params.transactionId;
  }

  return null;
});

export default getTransactionIdFromLocation;
