const homePageSchemae = [
  {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Going.',
    legalName: 'Going.',
    url: 'http://goingapp.pl/',
    logo: 'http://goingapp.pl/themes/going/assets/images/partials/menu/menu_logo.png',
    foundingDate: '2014',
    founders: [
      {
        '@type': 'Person',
        name: 'Maciej Gastol',
      },
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Aleje Jerozolimskie 107',
      addressRegion: 'MZ',
      postalCode: '01227',
      addressCountry: 'PL',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      telephone: '+48-501-306-607',
      email: 'support@goingapp.pl',
    },
    sameAs: [
      'https://web.facebook.com/goingapp/',
      'https://www.instagram.com/going.warsaw/',
      'https://twitter.com/goingdot',
      'https://pl.pinterest.com/goingapp/',
    ],
  },
  {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'http://www.goingapp.pl',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+48-501-306-607',
        contactType: 'customer service',
      },
    ],
  },
];

export default homePageSchemae;
