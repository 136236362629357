import React, { MouseEvent } from 'react';

import { Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import MarkdownText from '@Compo/reusable/MarkdownText';
import isColorDark from '@Misc/helpers/isColorDark';
import slugifyString from '@Misc/helpers/slugifyString';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import Analytics from '@Services/$analytics';

import useStyles from './TextBlock.styles';
import { ITextBlockProps } from './TextBlock.types';

const TextBlock = ({
  color,
  reversed,
  title,
  formattedDescription,
  cta,
  link,
  sectionBgColor,
  src,
  compositionIdentifier,
  brickId,
}: ITextBlockProps) => {
  const isBgDark = isColorDark(sectionBgColor);
  const theme = useTheme();
  const { isMobile } = useMuiBreakpoints();
  const muiStyles = useStyles(reversed, isMobile, isBgDark, color)(theme);

  const handleAddToDataLayerOnClick = (e: MouseEvent<HTMLElement>) =>
    link ? Analytics.pushForHomepageSelectItem(e, link, cta, title) : undefined;

  return (
    <div className={cn(muiStyles.root, muiStyles.fontColor)}>
      <div className={muiStyles.centerAlign}>
        <Typography
          variant={'h2'}
          children={title}
          className={isMobile ? muiStyles.h2mobile : undefined}
        />
        <MarkdownText text={formattedDescription} />
        {link && (
          <div className={muiStyles.buttonContainer}>
            <Button
              size={'large'}
              children={cta}
              href={link}
              className={cn(
                muiStyles.fontColor,
                muiStyles.button,
                `${compositionIdentifier}-${brickId}-${slugifyString(title)}`
              )}
              onClick={handleAddToDataLayerOnClick}
            />
            {!isMobile && <div />}
          </div>
        )}
      </div>
      {!isMobile && !src && <div />}
      {src && (
        <div className={muiStyles.centerAlign}>
          <img src={src} className={muiStyles.image} />
        </div>
      )}
    </div>
  );
};

export default TextBlock;
