import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISearchReducer } from '@Model/search/types';

import get from './get';

const getSearchPhrase = createSelector<_Store.IState, ISearchReducer, string>(
  [get],
  (search) => search.searchPhrase
);

export default getSearchPhrase;
