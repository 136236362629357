import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEventReducer, IEventReducerRequestTimes } from './../types';
import get from './get';

const getRequestTimes = createSelector<
  _Store.IState,
  IEventReducer,
  IEventReducerRequestTimes
>([get], (event) => event.requestTimes);

export default getRequestTimes;
