import React, { FC, useEffect, useState } from 'react';

import { history } from '@/store';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { classes } from '@Compo/reusable/EighteenGate/EighteenGate.classes';
import { IEighteenGateProps } from '@Compo/reusable/EighteenGate/EighteenGate.types';

const VERIFY_TEXT = 'WERYFIKACJA WIEKU';
const REJECTED_TEXT = 'Nie masz 18 lat, nie możesz odwiedzić tej strony.';
const PLEASE_CONFIRM_TEXT = 'Czy jesteś osobą pełnoletnią?';
const BACK_TEXT = 'Wróć';
const NO_TEXT = 'Nie';
const YES_TEXT = 'Tak';

const EighteenGate: FC<IEighteenGateProps> = ({
  slug,
  title,
  description,
  noLabel,
  yesLabel,
}) => {
  const [isOpen, setOpen] = useState(true);
  const [isRejected, setRejected] = useState(false);

  const botRegex =
    /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex|facebookexternalhit/i;

  const handleYes = () => setOpen(false);

  const handleNo = () => setRejected(true);

  const handleGoBack = () => {
    history.push('');
  };

  useEffect(() => {
    if (botRegex.test(navigator.userAgent)) {
      setOpen(false);
    }
  });

  return (
    <Dialog open={isOpen} className={classes.eighteenGate} id={slug}>
      <DialogTitle className={classes.eighteenGateTitle}>
        {title || VERIFY_TEXT}
      </DialogTitle>
      <DialogContent className={classes.eighteenGateContent}>
        <DialogContentText>
          {isRejected ? REJECTED_TEXT : description || PLEASE_CONFIRM_TEXT}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.eighteenGateActions}>
        {isRejected ? (
          <Button id={'back'} onClick={handleGoBack}>
            {BACK_TEXT}
          </Button>
        ) : (
          <>
            <Button id={'no'} onClick={handleNo}>
              {noLabel || NO_TEXT}
            </Button>
            <Button id={'yes'} onClick={handleYes}>
              {yesLabel || YES_TEXT}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EighteenGate;
