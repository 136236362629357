import React, { CSSProperties, FC } from 'react';

import cn from 'classnames';

import invertIfGrayScaleColor from '@Misc/helpers/invertIfGrayScaleColor';
import isColorDark from '@Misc/helpers/isColorDark';
import isWhiteColor from '@Misc/helpers/isWhiteColor';
import useBackgroundImageHeight from '@Misc/hooks/useBackgroundImageHeight';

import styles from './Section.module.scss';
import { ISectionProps } from './Section.types';

const WHITE_CLASSNAME = 'white';
const DARK_CLASSNAME = 'dark';

const Section: FC<ISectionProps> = ({
  background,
  backgroundImage,
  children,
  contain,
  inner,
  inRow,
  isBrowser,
  isDarkMode,
  isMobile,
  mobileBackgroundImage,
  useGradient,
  id,
}) => {
  const imageSrc =
    mobileBackgroundImage && isMobile ? mobileBackgroundImage : backgroundImage;
  const backgroundImageHeight =
    useGradient && useBackgroundImageHeight(imageSrc, isBrowser);
  const imageStyle = useGradient
    ? `linear-gradient(to bottom, transparent, ${background} ${backgroundImageHeight}), url(${imageSrc})`
    : `url(${imageSrc})`;

  if (
    isDarkMode &&
    !backgroundImage &&
    !mobileBackgroundImage &&
    !isColorDark(background)
  ) {
    background = invertIfGrayScaleColor(background);
  }

  const backgroundImg: CSSProperties = {
    backgroundColor: background,
    backgroundImage: imageStyle,
  };

  const backgroundSolid: CSSProperties = {
    backgroundColor: background,
  };

  const isWhite = isWhiteColor(background);
  const isDark = isColorDark(background);

  if (!children) {
    return null;
  }

  return (
    <div
      id={`section-${id}`}
      className={cn(
        styles.wrapper,
        contain && styles.contain,
        useGradient && styles.gradient,
        isWhite && WHITE_CLASSNAME,
        isDark && DARK_CLASSNAME,
        inner && styles.inner
      )}
      style={imageSrc ? backgroundImg : backgroundSolid}
    >
      <div className={cn(styles.content, inRow && styles.row)}>{children}</div>
    </div>
  );
};

export default Section;
