import { createSelector } from 'reselect';

import _Store from '@Store';

import { ILocaleReducer } from '@Model/locale/types';

import get from './get';

const getSelectedLocale = createSelector<_Store.IState, ILocaleReducer, string>(
  [get],
  (locale) => locale.selectedLang
);

export default getSelectedLocale;
