import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isDesktop, isMobile } from '@Model/app/selectors';
import { artistPageMounted, resetState } from '@Model/artist/actions';
import {
  getArtistClosestEvents,
  getArtistClosestPool,
  getArtistPastEvents,
  getData,
} from '@Model/artist/selectors';
import { getArtistImages } from '@Model/images/selectors';
import { getParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { IArtistMatchParams } from '@Routes/types';

import Artist from './Artist.component';
import { IArtistFromDispatch, IArtistFromState } from './Artist.types.js';

const mapStateToProps = (state: _Store.IState): IArtistFromState => {
  return {
    artist: getData(state),
    closestEvents: getArtistClosestEvents(state),
    closestPool: getArtistClosestPool(state),
    images: getArtistImages(state),
    isDesktop: isDesktop(state),
    isEventsLoading: getLoading(CONST.EVENT)(state),
    isLoading: getLoading(CONST.ARTIST)(state),
    isMobile: isMobile(state),
    lang: translate(state)('artist'),
    params: getParams(state) as IArtistMatchParams,
    pastEvents: getArtistPastEvents(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IArtistFromDispatch => {
  return {
    mounted: () => dispatch(artistPageMounted()),
    resetState: () => dispatch(resetState()),
  };
};

export default withRouter(
  connect<IArtistFromState, IArtistFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(Artist)
);
