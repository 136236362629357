import React from 'react';

import { ITag } from '@Compo/reusable/Tags/Tags.types';

import Tag from './../Tag';
import styles from './Tags.module.scss';
import { ITagsProps } from './Tags.types';

const Tags = ({ tags }: ITagsProps) => (
  <div className={styles.tags}>
    {tags.map((tag: ITag, index: number) => (
      <Tag key={index} {...tag} />
    ))}
  </div>
);

export default Tags;
