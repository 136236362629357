import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getSelectedLocation } from '@Model/locations/selectors';
import { getAboutPage } from '@Model/pages/actions';
import { getRecommend } from '@Model/pages/selectors';

import About from './About.component';
import { IAboutPageFromDispatch, IAboutPageFromState } from './About.types';

const mapStateToProps = (state: _Store.IState): IAboutPageFromState => ({
  structure: getRecommend(state),
  userLocation: getSelectedLocation(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IAboutPageFromDispatch => ({
  mounted: () => dispatch(getAboutPage()),
});

export default connect<
  IAboutPageFromState,
  IAboutPageFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(About);
