import { connect } from 'react-redux';

import _Store from '@Store';

import { getSizes, isMobile, isTablet } from '@Model/app/selectors';
import { isEnglish } from '@Model/locale/selectors';

import TitleResponsive from './TitleResponsive.component';
import { ITitleFromState } from './TitleResponsive.types';

function mapStateToProps(state: _Store.IState): ITitleFromState {
  return {
    isEnglish: isEnglish(state),
    pageWidth: getSizes(state).width || 0,
    shouldResize: !(isMobile(state) || isTablet(state)),
  };
}

export default connect<ITitleFromState, null, {}, _Store.IState>(
  mapStateToProps
)(TitleResponsive);
