import { createSelector } from 'reselect';

import _Store from '@Store';

import {
  IEventFull,
  IEventReducerCurrentKey,
  IEventReducerData,
} from './../types';
import getCurrentKey from './getCurrentKey';
import getData from './getData';

const getEvent = createSelector<
  _Store.IState,
  IEventReducerCurrentKey,
  IEventReducerData,
  IEventFull | null
>([getCurrentKey, getData], (key, data) => {
  if (key && key in data) {
    return data[key] as IEventFull;
  }

  return null;
});

export default getEvent;
