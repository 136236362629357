import { getType } from 'typesafe-actions';

import { locationChange, setModule } from './../../actions';
import { IInternalRouterData, ITypesafeActions } from './../../types';

export const initialState: IInternalRouterData = {
  module: null,
  params: null,
};

const reducer = (
  state: IInternalRouterData = initialState,
  action: ITypesafeActions
): IInternalRouterData => {
  switch (action.type) {
    case getType(locationChange):
      return {
        ...state,
        ...action.payload,
      };

    case getType(setModule):
      return {
        ...state,
        module: action.payload,
        params: null,
      };

    default:
      return state;
  }
};

export default reducer;
