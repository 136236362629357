import React from 'react';

import configureStore, { history } from '@/store';
import preloadedState from '@/store/preloadedState';
import { ConnectedRouter as Router } from 'connected-react-router';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';

import App from '@Compo/App';
import AppClassName from '@Compo/functional/AppClassName';
import ErrorBoundary from '@Compo/functional/ErrorBoundary';
import config from '@Config';
import { addLocationChangeMethodToWindow } from '@Misc/helpers/windowMethods/globalLocationChange';

// Load polyfills
import './polyfills';

// Configure store
export const store = configureStore(preloadedState);
addLocationChangeMethodToWindow(store);

render(
  <Provider store={store}>
    <ErrorBoundary>
      <RecoilRoot>
        <AppClassName>
          <Router history={history}>
            <App />
          </Router>
        </AppClassName>
      </RecoilRoot>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

////////////////////////////////////////////////////////////////////////////////
//                           DEVELOPMENT MODE ONLY                            //
////////////////////////////////////////////////////////////////////////////////
if (process.env.NODE_ENV === 'development') {
  if (module.hot && localStorage) {
    const callback = () => {
      const state = store.getState();

      localStorage.setItem('@@hmr', JSON.stringify(state));

      document.location.reload();
    };

    module.hot.accept('./../components/App', callback);
    module.hot.accept('./../store/index.ts', callback);
  }
}
////////////////////////////////////////////////////////////////////////////////
