import { createSelector } from 'reselect';

import _Store from '@Store';

import { ILandingPageReducer, ILandingPageReducerData } from './../types';
import get from './get';

const getData = createSelector<
  _Store.IState,
  ILandingPageReducer,
  ILandingPageReducerData
>([get], (landingPage) => landingPage.data);

export default getData;
