import { getType } from 'typesafe-actions';

import { resetTicketsState, updateSelectedTickets } from './../../actions';
import { IAction, IPoolsSelectedTicketsReducer } from './../../types';

export const initialState: IPoolsSelectedTicketsReducer = [];

const reducer = (
  state: IPoolsSelectedTicketsReducer = initialState,
  action: IAction
): IPoolsSelectedTicketsReducer => {
  switch (action.type) {
    // _UPDATE_SELECTED_TICKETS
    case getType(updateSelectedTickets):
      return action.payload;

    // RESET_TICKETS_STATE
    case getType(resetTicketsState):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
