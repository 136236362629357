import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPremiumReducer, IPremiumUser } from './../types';
import get from './get';

const getUser = createSelector<
  _Store.IState,
  IPremiumReducer,
  IPremiumUser | null
>([get], (slice) => {
  return slice.user;
});

export default getUser;
