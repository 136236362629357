import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import DownloadSection from './DownloadSection.component';
import { IDownloadSectionFromState } from './DownloadSection.types';

const mapStateToProps = (state: _Store.IState): IDownloadSectionFromState => {
  return {
    lang: translate(state)('payment', 'paymentSuccess'),
  };
};

export default connect<IDownloadSectionFromState, {}, {}, _Store.IState>(
  mapStateToProps,
  {}
)(DownloadSection);
