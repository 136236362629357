import React from 'react';

import { Grid, Typography, useTheme } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import cn from 'classnames';

import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import classes from '../../Success.classes';
import useStyles from './SellSuccessHeader.styles';
import { ISellSuccessHeaderProps } from './SellSuccessHeader.types';

const SellSuccessHeader = ({ lang }: ISellSuccessHeaderProps) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const { isMobile } = useMuiBreakpoints();

  return (
    <Grid container={true} direction="column">
      <Grid item={true} xs={true}>
        <Grid
          container={true}
          wrap="nowrap"
          className={muiStyles.headerAfterSellContainer}
        >
          <Grid className={cn(muiStyles.bigIconContainer, classes.doneIcon)}>
            <DoneIcon fontSize="large" />
          </Grid>

          <Grid item={true} className={cn(muiStyles.mainText, classes.header)}>
            <Typography variant={isMobile ? 'h5' : 'h2'}>
              {lang.header}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item={true}
        xs={true}
        className={cn(muiStyles.baseAlertContainer, classes.baseAlertContainer)}
      >
        <Grid container={true}>
          <Grid item={true} className={muiStyles.baseAlertIcon}>
            <TimerOutlinedIcon />
          </Grid>

          <Grid item={true} className={muiStyles.baseAlertText}>
            <Typography variant="body2">{lang.waiting}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SellSuccessHeader;
