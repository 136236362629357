import { getType } from 'typesafe-actions';

import { IAction, IPaymentMethodsReducer } from '@Model/pools/types';

import { setPaymentMethods } from './../../actions';

const reducer = (
  state: IPaymentMethodsReducer = {
    paymentMethods: [],
  },
  action: IAction
): IPaymentMethodsReducer => {
  switch (action.type) {
    case getType(setPaymentMethods):
      return {
        paymentMethods: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
