import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      height: '100%',
      objectFit: 'contain',
      transform: 'translateY(-8rem)',
      width: '100%',
    },
  })
);

export default useStyles;
