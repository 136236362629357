import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getImagesByCloudinaryPrefixAndSlug } from '@Model/images/actions';
import { get } from '@Model/images/selectors';

import ArtistCard from './ArtistCard.component';
import {
  IArtistCardFromDispatch,
  IArtistCardFromState,
  IArtistCardOwnProps,
} from './ArtistCard.types';

const mapStateToProps = (state: _Store.IState): IArtistCardFromState => ({
  images: get(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IArtistCardFromDispatch => ({
  mounted: (payload) => dispatch(getImagesByCloudinaryPrefixAndSlug(payload)),
});

export default connect<
  IArtistCardFromState,
  IArtistCardFromDispatch,
  IArtistCardOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ArtistCard);
