import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    yellowBg: {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

export default useStyles;
