import React, { FC } from 'react';

import prepareUrlForRoute from '@Misc/helpers/prepareUrlForRoute';
import routes from '@Routes/routes';

import styles from './SimilarPlaces.module.scss';
import { IPlaceSimilarPlacesProps } from './SimilarPlaces.types';

const SIMILAR_PLACES_HEADER_TEXT = 'Podobne miejsca';

const SimilarPlaces: FC<IPlaceSimilarPlacesProps> = ({ places }) => {
  return (
    <>
      <h2 className={styles.similarPlacesTitle}>
        {SIMILAR_PLACES_HEADER_TEXT}
      </h2>

      <div className={styles.similarPlaces}>
        {places.map((place) => {
          const url = prepareUrlForRoute(routes.place, place.slug);

          return (
            <a href={url} className={styles.place} key={place.name}>
              <img src={place.image} alt={place.name} />
              <h4 className={styles.name}>{place.name}</h4>
              <span className={styles.city}>{place.city}</span>
            </a>
          );
        })}
      </div>
    </>
  );
};

export default SimilarPlaces;
