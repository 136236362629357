import { push } from 'connected-react-router';
import qs from 'query-string';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  IFormioFromDispatch,
  IFormioFromState,
} from '@Compo/Formio/Formio.types';
import TransactionError from '@Misc/classes/TransactionError';
import { setError } from '@Model/errors/actions';
import { getIframeParams } from '@Model/internalRouter/selectors';
import { setUserLocale } from '@Model/locale/actions';
import { getSelectedLocale, translate } from '@Model/locale/selectors';
import { getLocation } from '@Model/router/selectors/';

import Formio from './Formio.component';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormioFromDispatch => {
  return {
    changeLocale: (locale) => dispatch(setUserLocale(locale.selectedLang)),
    redirect: (uri: string) => dispatch(push(uri)),
    setError: (errorMessage: string) =>
      dispatch(setError(new TransactionError(errorMessage || ''))),
  };
};

const mapStateToProps = (state: _Store.IState): IFormioFromState => {
  return {
    formSlug: getLocation(state).pathname.split('/')[2],
    isDarkTheme: getLocation(state).query?.isDark === 'true',
    isEmbed: !!getIframeParams(state),
    isWebview: getLocation(state).query?.isWebview === 'true',
    langButton: translate(state)('formio', 'langButton'),
    locale: getSelectedLocale(state),
    params: qs.parse(getLocation(state).search),
  };
};

export default connect<
  IFormioFromState,
  IFormioFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Formio);
