const formatDate = (
  date: {
    day: number;
    month: number;
    year: number;
  },
  withDash?: boolean
): string => {
  const day = date.day.toString().padStart(2, '0');
  const month = (date.month + 1).toString().padStart(2, '0');

  return withDash
    ? `${date.year}-${month}-${day}`
    : `${day}.${month}.${date.year}`;
};

export default formatDate;
