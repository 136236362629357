import { getLocation } from 'connected-react-router';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';
import { translate } from '@Model/locale/selectors';
import { transferTicketFormSubmit } from '@Model/tickets/actions';
import {
  getShouldShowSender,
  getTicketById,
  getTicketTransferFee,
} from '@Model/tickets/selectors';

import MyTicket from './TransferTicket.component';
import {
  ITransferTicketFromDispatch,
  ITransferTicketFromState,
} from './TransferTicket.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITransferTicketFromDispatch => {
  return {
    submitForm: (payload) => dispatch(transferTicketFormSubmit(payload)),
  };
};

const mapStateToProps = (state: _Store.IState): ITransferTicketFromState => {
  return {
    isDesktop: isDesktop(state),
    lang: translate(state)('ticketsManagement', 'ticketTransfer'),
    ticketData: getTicketById(state)(getLocation(state).pathname.split('/')[2]),
    ticketTransferFee: getTicketTransferFee(state)?.price,
    shouldShowSender: getShouldShowSender(state),
  };
};

export default connect<
  ITransferTicketFromState,
  ITransferTicketFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(MyTicket);
