import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import HttpError from '@Misc/classes/HttpError';
import { getEvent } from '@Model/event/actions';
import { getEvents } from '@Model/events/actions';
import { getHappening } from '@Model/happening/actions';
import { setModule } from '@Model/internalRouter/actions';
import { getLandingPage } from '@Model/landingPage/actions';
import { getPlace } from '@Model/place/actions';
import { getPools } from '@Model/pools/actions';
import { getRedirection } from '@Model/router/actions';
import { getSelection } from '@Model/selection/actions';
import {
  downloadTicketFromTicketDataOnRequest,
  downloadTicketFromTransactionDataOnRequest,
  getTicketsData,
  getTicketsDataFromTransaction,
  receiveTicket,
  transferTicket,
} from '@Model/tickets/actions';
import { sendTransaction } from '@Model/transaction/actions';
import * as MODULES from '@Routes/modules';
import Logger from '@Services/Logger';

import { setError } from './../actions';

export const setErrorOnFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getTicketsData.failure,
        getTicketsDataFromTransaction.failure,
        downloadTicketFromTransactionDataOnRequest.failure,
        downloadTicketFromTicketDataOnRequest.failure,
        sendTransaction.failure,
        transferTicket.failure,
      ])
    ),
    map$((action) => {
      return setError(action.payload);
    })
  );
};

export const doRedirectOnFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getEvent.failure,
        getEvents.failure,
        getHappening.failure,
        getLandingPage.failure,
        getPlace.failure,
        getPools.failure,
        getRedirection.failure,
        // @TODO: backend ready for special chars in slug
        // getSections.failure,
        getSelection.failure,
        receiveTicket.failure,
      ])
    ),
    map$((action) => {
      const { payload: error } = action;

      Logger.error('doRedirectOnFailure', error);

      if (error instanceof HttpError && error.status === 404) {
        return setModule(MODULES.ERROR_404);
      }

      return setModule(MODULES.ERROR_BASIC);
    })
  );
};
