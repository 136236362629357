import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEventFull } from '@Model/event/types';
import { ITicketsReducer } from '@Model/tickets/types';

import get from './get';

const getReceivedEvent = createSelector<
  _Store.IState,
  ITicketsReducer | null,
  IEventFull | null
>([get], (ticket) => ticket?.eventData || null);

export default getReceivedEvent;
