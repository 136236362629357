import React from 'react';

import styles from './ImageLoading.module.scss';
import { IImageLoadingProps } from './ImageLoading.types';

const ImageLoading = ({
  borderRadiusTopLeft,
  forcedVertical,
}: IImageLoadingProps) => (
  <div className={forcedVertical ? styles.forcedVertical : styles.normal}>
    <div
      className={styles.fakeImage}
      style={{
        borderTopLeftRadius: borderRadiusTopLeft,
      }}
    />
  </div>
);

export default ImageLoading;
