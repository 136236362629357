import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { shipmentDeliveryMounted } from '@Model/shipment/actions';
import { get as getShipments } from '@Model/shipment/selectors';
import { getTheme } from '@Model/theme/selectors';

import PickUpWay from './PickUpWay.component';
import {
  IPickUpFromDispatch,
  IPickUpWayFromState,
  IPickUpWayOwnProps,
} from './PickUpWay.types';

const mapStateToProps = (state: _Store.IState): IPickUpWayFromState => ({
  lang: translate(state)('buy', 'pickUpWay'),
  selectedTheme: getTheme(state),
  shipments: getShipments(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPickUpFromDispatch => ({
  shipmentDeliveryMounted: () => dispatch(shipmentDeliveryMounted()),
});

export default connect<
  IPickUpWayFromState,
  IPickUpFromDispatch,
  IPickUpWayOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PickUpWay);
