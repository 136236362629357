import React from 'react';

import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import MyTickets from '@Compo/ticketsManagement/MyTickets';
import { IBreadcrumb } from '@Model/breadcrumb/types';
import routes from '@Routes/routes';

const MY_TICKETS_TEXT = 'Zarządzaj biletami';

const breadcrumbs: IBreadcrumb[] = [
  {
    link: routes.myTicket,
    name: MY_TICKETS_TEXT,
  },
];

const MyTicketsPage = () => (
  <MuiGrid>
    <Helmet pageName={MY_TICKETS_TEXT} />
    <FullTriangleBackground />
    <MuiSectionContainer>
      <MuiBreadcrumbs base={breadcrumbs} />
      <MyTickets />
    </MuiSectionContainer>
  </MuiGrid>
);

export default MyTicketsPage;
