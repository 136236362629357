import { translate } from '@/models/locale/selectors';
import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';
import {
  EMPTY as EMPTY$,
  from as from$,
  merge as merge$,
  of as of$,
} from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import stepNames from '@Compo/EntryList/steps';
import config from '@Config';
import generateUUID from '@Misc/helpers/generateUUID';
import { getPartnerFormId } from '@Model/internalRouter/selectors';

import {
  appendToSubmissionsSummary,
  attachSubmission,
  checkAgreementExist,
  checkSubmissionsExists,
  clearFormsData,
  clearFormsErrors,
  completeAttaching,
  confirmPersonalData,
  finalizeTransaction,
  generateAndSendSmsCode,
  getDataFromTicket,
  prepareSubmissionsToAttach,
  registerNewSubmission,
  requestDataFromTicket,
  requestToCheckAgreementExists,
  requestToCheckSubmissionsExists,
  requestToRegisterNewSubmission,
  resetForm,
  selectSubmission,
  sendSmsCode,
  setFormErrors,
  setIsViewOnly,
  setTicketSubmissionsComplete,
  skipToSelectedStep,
} from '../actions';
import {
  IFormIoUserExistRequest,
  IRegisterNewSubmissionRequestPayload,
  ISubmissionAttachSuccessPayload,
  ISubmissionsSummary,
} from '../types';

export const requestToCheckSubmissionsExistsWhenEmailPassed: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(requestToCheckSubmissionsExists)),

    mergeMap$((action) => {
      if (action.payload === 'sprzedaz@goingapp.pl') {
        return of$(
          clearFormsData(),
          skipToSelectedStep(stepNames.personalData)
        );
      }
      return of$(checkSubmissionsExists.request(action.payload));
    })
  );
};

export const checkSubmissionsExistsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { formioApi }
) => {
  return action$.pipe(
    filter$(isActionOf(checkSubmissionsExists.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const formId = getPartnerFormId(state);
      if (!formId) return EMPTY$;
      return from$(
        formioApi.getFormIoUserWhenExist({ userEmail: action.payload }, formId)
      ).pipe(
        mergeMap$((data) => {
          return of$(
            checkSubmissionsExists.success(data),
            skipToSelectedStep(stepNames.selectSubmission)
          );
        }),
        catchError$((error) => {
          return of$(
            checkSubmissionsExists.failure(error),
            skipToSelectedStep(stepNames.personalData)
          );
        })
      );
    })
  );
};

export const selectNextStepWhenSubmissionSelected: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(selectSubmission)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { ticketCode } = state.entryList;
      if (ticketCode) {
        const newSubmission = {
          entryToken: ticketCode,
          ...action.payload,
        };
        const reviewSubmission = {
          firstName: action.payload.data.page2enterform.data.firstname,
          lastName: action.payload.data.page2enterform.data.lastname,
          email: action.payload.data.page2enterform.data.email,
        };

        return of$(
          appendToSubmissionsSummary(reviewSubmission),
          prepareSubmissionsToAttach(newSubmission),
          completeAttaching()
        );
      }

      return of$(skipToSelectedStep(stepNames.greeting));
    })
  );
};

export const selectNextActionAfterPersonalDataFormSubmitted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(confirmPersonalData)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const isEmail = state.entryList.isEmail;
      const formData = state.entryList.initialValues;
      if (isEmail) {
        const isAdult =
          moment().diff(
            moment(state.entryList.initialValues.dateOfBirth, 'DD/MM/YYYY'),
            'years'
          ) >= 18;
        if (isAdult) return of$(requestToRegisterNewSubmission());
        return of$(skipToSelectedStep(stepNames.parentData));
      } else {
        return of$(
          checkAgreementExist.request({
            userEmail: formData.email,
            userFirstName: formData.firstName,
            userLastName: formData.lastName,
          })
        );
      }
    })
  );
};

export const requestToRegisterNewSubmissionsWhenDataValid: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(requestToRegisterNewSubmission)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerFormId = getPartnerFormId(state);
      const clientData = state.entryList.initialValues;
      const preparedData: IRegisterNewSubmissionRequestPayload = {
        request: {
          data: {
            page2enterform: {
              data: {
                firstname: clientData.firstName,
                lastname: clientData.lastName,
                email: clientData.email,
                dataurodzenia: clientData.dateOfBirth,
              },
            },
            firstname: clientData.firstName,
            lastname: clientData.lastName,
            email: clientData.email,
            dataurodzenia: clientData.dateOfBirth,
          },
          metadata: {},
          form: partnerFormId ? partnerFormId : generateUUID(),
          project: partnerFormId ? partnerFormId : generateUUID(),
        },
        submission: {
          _id: generateUUID(),
        },
      };

      return of$(registerNewSubmission.request(preparedData));
    })
  );
};

export const registerNewSubmissionWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { formioApi }
) => {
  return action$.pipe(
    filter$(isActionOf(registerNewSubmission.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(formioApi.registerNewSubmission(action.payload)).pipe(
        mergeMap$((data) => {
          const { ticketCode } = state.entryList;

          if (ticketCode) {
            const newSubmission = {
              entryToken: ticketCode,
              submissionId: action.payload.submission._id,
            };
            return of$(
              appendToSubmissionsSummary({
                firstName:
                  action.payload.request.data.page2enterform.data.firstname,
                lastName:
                  action.payload.request.data.page2enterform.data.lastname,
                email: action.payload.request.data.page2enterform.data.email,
              }),
              prepareSubmissionsToAttach(newSubmission),
              registerNewSubmission.success(data),
              completeAttaching()
            );
          } else {
            return of$(
              registerNewSubmission.success(data),
              skipToSelectedStep(stepNames.greeting)
            );
          }
        }),
        catchError$((error) => {
          return of$(registerNewSubmission.failure(error));
        })
      );
    })
  );
};

export const requestToCheckIfAgreementExistWhenFormSubmitted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(requestToCheckAgreementExists)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const compareData = (
        payload: IFormIoUserExistRequest,
        submissions: ISubmissionsSummary[]
      ) => {
        return submissions.some(
          (obj) =>
            obj.firstName === payload.userFirstName &&
            obj.lastName === payload.userLastName &&
            obj.email === payload.userEmail
        );
      };

      const { ticketCode, submissionsSummary } = state.entryList;
      const userExist = compareData(action.payload, submissionsSummary);
      const message = translate(state)('entryList', 'submissionsSummary');

      if (ticketCode && userExist) {
        return of$(
          setFormErrors({
            type: 'submissionsUser',
            value: message.submissionExist,
          }),
          skipToSelectedStep(stepNames.personalData)
        );
      }

      return of$(checkAgreementExist.request(action.payload));
    })
  );
};

export const checkIfAgreementExistWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { formioApi }
) => {
  return action$.pipe(
    filter$(isActionOf(checkAgreementExist.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const formId = getPartnerFormId(state);
      if (!formId) return EMPTY$;

      return from$(
        formioApi.getFormIoUserWhenExist(action.payload, formId)
      ).pipe(
        mergeMap$((data) => {
          const { ticketCode } = state.entryList;

          const checkDataExist = () => {
            if (data.submission && data.submission.length) {
              return data.submission[0];
            }
          };

          const agreement = checkDataExist();

          if (ticketCode && agreement) {
            return of$(
              appendToSubmissionsSummary({
                firstName: agreement.data.page2enterform?.data.firstname,
                lastName: agreement.data.page2enterform?.data.lastname,
                email: agreement.data.page2enterform?.data.email,
              }),
              prepareSubmissionsToAttach({
                entryToken: ticketCode,
                submissionId: agreement.submissionId,
              }),
              completeAttaching(),
              clearFormsErrors()
            );
          } else {
            return of$(
              checkAgreementExist.success(data),
              skipToSelectedStep(stepNames.agreementExist)
            );
          }
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => formioApi.cancelEvent())
          )
        ),
        catchError$((error) => {
          if (error.status === 404) {
            const isAdult =
              moment().diff(
                moment(state.entryList.initialValues.dateOfBirth, 'DD/MM/YYYY'),
                'years'
              ) >= 18;
            if (isAdult) {
              return of$(requestToRegisterNewSubmission());
            } else {
              return of$(skipToSelectedStep(stepNames.parentData));
            }
          } else {
            return of$(checkAgreementExist.failure(error));
          }
        })
      );
    })
  );
};

export const requestToGetDataFromTicketWhenCodePassed: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(requestDataFromTicket)),
    mergeMap$((action) => of$(getDataFromTicket.request(action.payload)))
  );
};

export const getDataFromTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { formioApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getDataFromTicket.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const formId = getPartnerFormId(state);
      const { ticketCode } = state.entryList;
      if (!formId) return EMPTY$;

      return from$(formioApi.getDataFromTicket(action.payload, formId)).pipe(
        mergeMap$((data) => {
          const checkTicketExist = () => {
            if (data.data.length > 0) {
              return data.data[0];
            }
          };
          const ticket = checkTicketExist();
          if (ticket && ticket.entriesQuantity <= ticket.entriesSubmissions) {
            const submissions = ticket.submission.map((item) =>
              appendToSubmissionsSummary({
                firstName: item.submissions[0].data.page2enterform
                  ? item.submissions[0]?.data?.page2enterform.data.firstname
                  : item.submissions[0]?.data['4'],
                lastName: item.submissions[0].data.page2enterform
                  ? item.submissions[0]?.data?.page2enterform.data.lastname
                  : item.submissions[0]?.data['5'],
                email: item.submissions[0].data.page2enterform
                  ? item.submissions[0]?.data?.page2enterform.data.email
                  : item.submissions[0]?.data['3'],
              })
            );
            return merge$(
              from$(submissions),
              of$(
                setIsViewOnly(true),
                setTicketSubmissionsComplete(true),
                getDataFromTicket.success(data),
                skipToSelectedStep(stepNames.submissionsList)
              )
            );
          }
          if (ticket && !!ticket.entriesSubmissions) {
            const submissionsSummary = ticket.submission.map((item) =>
              appendToSubmissionsSummary({
                firstName: item.owner.firstName,
                lastName: item.owner.lastName,
                email: item.owner.email,
              })
            );
            const submissionsToSend = ticket.submission.map((item) =>
              prepareSubmissionsToAttach({
                submissionId: item.submissions[0].submissionId,
                entryToken: ticketCode,
              })
            );
            return merge$(
              from$(submissionsSummary),
              from$(submissionsToSend),
              of$(
                getDataFromTicket.success(data),
                skipToSelectedStep(stepNames.personalData)
              )
            );
          }
          return merge$(
            of$(getDataFromTicket.success(data)),
            ticket?.owner.email !== 'sprzedaz@goingapp.pl'
              ? of$(checkSubmissionsExists.request(ticket?.owner.email || ''))
              : EMPTY$,
            ticket?.owner.email === 'sprzedaz@goingapp.pl'
              ? of$(
                  skipToSelectedStep(stepNames.personalData),
                  clearFormsData()
                )
              : EMPTY$
          );
        }),
        takeUntil$(
          action$.pipe(
            tap$(() => formioApi.cancelEvent()),
            filter$(isOfType(LOCATION_CHANGE))
          )
        ),
        catchError$((error) => {
          return of$(getDataFromTicket.failure(error));
        })
      );
    })
  );
};

export const requestToSendSmsWithCode: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(generateAndSendSmsCode)),
    mergeMap$((action) => {
      return of$(sendSmsCode.request(action.payload));
    })
  );
};

export const sendSmsCodeWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { formioApi }
) => {
  return action$.pipe(
    filter$(isActionOf(sendSmsCode.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const formId = state.internalRouter.embed.partnerFormId;
      if (!formId) return EMPTY$;
      const data = {
        message: config.statutes[formId].smsMessage(action.payload),
        receiver: '+48' + state.entryList.parentData.phoneNumber,
      };
      return from$(formioApi.sendSmsCode(data)).pipe(
        mergeMap$(() => {
          return of$(sendSmsCode.success());
        }),
        takeUntil$(
          action$.pipe(
            tap$(() => formioApi.cancelEvent()),
            filter$(isOfType(LOCATION_CHANGE))
          )
        ),
        catchError$((error) => {
          return of$(sendSmsCode.failure(error));
        })
      );
    })
  );
};

export const checkIfAllSubmissionsAreComleted: _Store.IEpic = (
  action$,
  state$,
  { formioApi }
) => {
  return action$.pipe(
    filter$(isActionOf(completeAttaching)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { submissions, ticketData, foundedSubmissions } = state.entryList;
      const submissionsPromises: Array<
        Promise<ISubmissionAttachSuccessPayload>
      > = [];

      if (ticketData && ticketData.entriesQuantity === submissions.length) {
        if (ticketData.entriesQuantity === 1 && submissions.length) {
          submissions.forEach((data) => {
            submissionsPromises.push(formioApi.attachSubmissionToTicket(data));
          });
          return from$(Promise.all(submissionsPromises)).pipe(
            mergeMap$((data) => {
              return of$(
                attachSubmission.success(data),
                clearFormsData(),
                resetForm(),
                skipToSelectedStep(stepNames.greeting)
              );
            }),
            catchError$((error) => {
              return of$(attachSubmission.failure(error));
            })
          );
        }
        return of$(skipToSelectedStep(stepNames.submissionsList));
      }
      if (!!foundedSubmissions?.submission.length) {
        return of$(
          clearFormsData(),
          skipToSelectedStep(stepNames.selectSubmission)
        );
      }
      return of$(clearFormsData(), skipToSelectedStep(stepNames.personalData));
    })
  );
};

export const attachAllSubmissionsToTicketWhenFormComplete: _Store.IEpic = (
  action$,
  state$,
  { formioApi }
) => {
  return action$.pipe(
    filter$(isActionOf(finalizeTransaction)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { submissions } = state.entryList;

      const submissionsPromises: Array<
        Promise<ISubmissionAttachSuccessPayload>
      > = [];
      if (submissions.length) {
        submissions.forEach((data) => {
          submissionsPromises.push(formioApi.attachSubmissionToTicket(data));
        });
      }

      return from$(Promise.all(submissionsPromises)).pipe(
        mergeMap$((data) => {
          return of$(
            attachSubmission.success(data),
            clearFormsData(),
            resetForm(),
            skipToSelectedStep(stepNames.greeting)
          );
        }),
        catchError$((error) => {
          return of$(attachSubmission.failure(error));
        })
      );
    })
  );
};
