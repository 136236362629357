/**
 * Function that create Rundate normalization for calendar (slots) Event
 *
 * @param {IRundateResponse} data - Event data
 *
 * @return {IEventFull} Event object.
 */
import { IEventFull } from '@Model/event/types';
import { IEventResponse } from '@Services/$events-api/types';

const fillRundateData = (data: IEventResponse): IEventFull => {
  const {
    artists,
    category,
    currency,
    calendarEvent,
    descriptionPL,
    externalImages,
    forFansOfArtists,
    formattedDescription,
    formattedPartnerDescription,
    id: eventId,
    images,
    partner: { id: partnerId, name: partnerName, gtmId },
    partnerDescription,
    place,
    rundatesCount,
    showAdditionalDescription,
    slug: eventSlug,
    tags,
    teaser,
    titlePL,
  } = data;

  return {
    additionalDescription: formattedPartnerDescription
      ? formattedPartnerDescription
      : '',
    artists,
    buttonLabel: null,
    calendarEvent,
    courierDelivery: false,
    customTerms: [],
    currency,
    description: formattedDescription ? formattedDescription : '',
    eventId,
    eventSlug,
    externalImageId: null,
    externalImages,
    forFansOfArtists,
    freeOfCharge: false,
    friendlyDate: '',
    gtmId,
    hasManyPools: false,
    imagesUrl: images.length > 0 ? images[0].large || '' : '',
    isAvailable: true,
    notForSale: false,
    partnerId,
    partnerName,
    passed: false,
    placeAddress: place?.address || '',
    placeCategory: null,
    placeCityName: place?.city.name || '',
    placeCitySlug: place?.city.slug || '',
    placeDescription: place?.description,
    placeFriendly: place ? `${place.name}, ${place.address}` : '',
    placeId: place?.id || 0,
    placeImageUrl: place?.thumb,
    placeLat: place?.lat || 52.2330653,
    placeLon: place?.lon || 20.9211125,
    placeName: place?.name || '',
    placeSlug: place?.slug || '',
    price: null,
    priceDescription: '',
    rundate: '',
    rundateId: 0,
    rundateSlug: '',
    rundatesCount,
    startDate: '',
    tags: [],
    teaser,
    title: titlePL,
    videoId: null,
  };
};

export default fillRundateData;
