import { connect } from 'react-redux';

import _Store from '@Store';

import { get } from '@Model/breadcrumb/selectors';

import MuiBreadcrumbs from './MuiBreadcrumbs.component';
import {
  IBreadcrumbsFromState,
  IMuiBreadcrumbsOwnProps,
} from './MuiBreadcrumbs.types';

const mapStateToProps = (state: _Store.IState): IBreadcrumbsFromState => ({
  current: get(state),
});

export default connect<
  IBreadcrumbsFromState,
  void,
  IMuiBreadcrumbsOwnProps,
  _Store.IState
>(mapStateToProps)(MuiBreadcrumbs);
