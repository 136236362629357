import { selectors } from 'goingapp-shared';
import { createSelector } from 'reselect';

import { IAvailableSpaceWithDescription } from '@Compo/Happening/components/Spaces/Spaces.types';
import { getSelectedLocale } from '@Model/locale/selectors';

import getData from './getData';
import getSelected from './getSelected';
import getSelectedSlot from './getSelectedSlot';

const DEFAULT_LANGUAGE = 'pl';

const getSpaces = createSelector(
  [getSelectedSlot, getData, getSelectedLocale, getSelected],
  (slot, data, locale, selected) => {
    const spaces = selectors.getSpaces(slot, data, locale, selected);
    if (spaces.length) {
      return spaces;
    }

    return (
      data?.spaces.map((space) => {
        const localizedDescription =
          space?.description.find((desc) => desc.language === locale) ||
          space?.description.find((desc) => desc.language === DEFAULT_LANGUAGE);

        return {
          ...space,
          available: false,
          break: null,
          capacity: 0,
          capacityLeft: 0,
          description: localizedDescription?.description,
          duration: 0,
          prices: [],
          reserved: true,
          ruleCapacityId: 0,
          rulePriceId: 0,
          ruleTimeId: 0,
          spaceId: 0,
          start: 0,
          title: localizedDescription?.title,
          upsell: null,
          upsellExtended: null,
        } as IAvailableSpaceWithDescription;
      }) || []
    );
  }
);

export default getSpaces;
