import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolsSelectedTicketsReducer } from './../types';
import getSelectedTickets from './getSelectedTickets';

const allRequiredFormsAreSubmitted = createSelector<
  _Store.IState,
  IPoolsSelectedTicketsReducer,
  boolean
>([getSelectedTickets], (selectedTickets: IPoolsSelectedTicketsReducer) => {
  const ticketFormsSubmittedArray = selectedTickets.map((ticket) => {
    if (ticket.forms?.length && ticket.submissions?.length) {
      return true;
    }

    return false;
  });

  return !ticketFormsSubmittedArray.some((ticketForm) => ticketForm === false);
});

export default allRequiredFormsAreSubmitted;
