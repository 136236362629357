import { getType } from 'typesafe-actions';

import { getArticle, setCurrentKey } from './../actions';
import { IAction, IArticleReducer } from './../types';

const initialState: IArticleReducer = {
  currentKey: null,
  data: {},
  requestTimes: {},
};

const reducer = (
  state: IArticleReducer = initialState,
  action: IAction
): IArticleReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getArticle.success):
      const { article, key } = action.payload;

      return {
        ...state,
        currentKey: key,
        data: {
          ...state.data,
          [key]: article,
        },
        requestTimes: {
          ...state.requestTimes,
          [key]: Date.now(),
        },
      };

    case getType(setCurrentKey):
      return {
        ...state,
        currentKey: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
