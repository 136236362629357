import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import cn from 'classnames';

import styles from './../Pools/Pools.module.scss';
import productsStyles from './Products.module.scss';
import useStyles from './Products.styles';
import { IProductsProps } from './Products.types';
import Product from './components/Product';

const Products = ({
  isProductPay,
  isTransactionLoading,
  lang,
  number,
  products,
  productsMounted,
  isDisabled,
}: IProductsProps) => {
  useEffect(() => {
    if (isProductPay) {
      productsMounted();
    }
  }, []);

  const shouldShowProducts = !!products.length;

  const muiStyles = useStyles();

  return (
    <div
      className={cn(
        styles.pools,
        isTransactionLoading && styles.disabled,
        isDisabled && muiStyles.disabled,
        !isProductPay && muiStyles.container,
        productsStyles.title
      )}
    >
      <Typography
        className={muiStyles.header}
        variant={'h3'}
        data-number={number}
      >
        {lang.title}
      </Typography>

      {shouldShowProducts && (
        <ul>
          {products.map((product) => {
            return (
              <li key={product.id}>
                <Product product={product} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Products;
