import React from 'react';

import { Icon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import cn from 'classnames';

import ToolTip from '@Compo/reusable/ToolTip';
import config from '@Config';
import getCurrencyFromCode from '@Misc/helpers/getCurrencyFromCode';
import icons from '@Misc/helpers/icons';

import Buttons from './../Buttons';
import Description from './../Description';
import classes from './Pool.classes';
import styles from './Pool.module.scss';
import useStyles from './Pool.styles';
import { IPoolProps } from './Pool.types';

const ticketIcon = config.theme.isEmpik
  ? cn(styles.icon, icons.ticket, classes.ticketIcon)
  : cn(styles.ticketIcon, classes.ticketIcon);

const Pool = ({ pool, showImage, lang }: IPoolProps) => {
  const showDescription = pool.description.length > 0;
  const isDisabled =
    !pool.active || (!config.app.onlineSale && pool.currency !== 'PLN');

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const tooltip = (
    <ToolTip title={lang.serviceFeeTooltip} className={muiStyles.tooltip}>
      <Icon component={InfoIcon} />
    </ToolTip>
  );

  return (
    <div
      className={cn(
        muiStyles.root,
        styles.pool,
        classes.pool,
        isDisabled && cn(styles.disabled, classes.disabled),
        `${classes.pool}-${pool.id}`
      )}
      data-test-id={`pool-${pool.id}`}
      {...(pool.active && { 'data-pool-id': pool.id })}
    >
      <div
        className={cn(
          styles.column,
          styles.columnLeft,
          classes.column,
          classes.columnLeft
        )}
      >
        {showImage && <span className={ticketIcon} />}
        <div className={cn(styles.ticketInfo, classes.ticketInfo)}>
          <div
            className={cn(
              styles.title,
              classes.title,
              showDescription && styles.titleWithMarginBottom
            )}
          >
            <h4>{pool.title}</h4>
          </div>
          {showDescription && <Description text={pool.description} />}
        </div>
      </div>
      <div className={cn(styles.column, classes.column)}>
        <span className={cn(styles.price, classes.price)}>
          <span className={cn(styles.priceValue, classes.priceValue)}>
            {pool.price}
          </span>{' '}
          {getCurrencyFromCode(pool.currency)}
        </span>
        {tooltip}
      </div>

      <div className={cn(styles.column, classes.column)}>
        <Buttons
          poolId={pool.id}
          howManyTickets={pool.ticketsLimit}
          poolName={pool.title}
          price={pool.price}
          serviceFee={pool.serviceFee}
          additionalFields={pool.isDetailedPurchaseRequired}
          forms={pool.forms}
          isActive={pool.active}
          paymentMethods={pool.payments}
          currency={pool.currency}
        />
      </div>
    </div>
  );
};

export default Pool;
