import React, { FC, useState } from 'react';

import cn from 'classnames';

import config from '@Config';

import styles from './Heart.module.scss';

const Heart: FC = () => {
  const [isActive, toggleActive] = useState(false);

  const switchState = () => toggleActive(!isActive);

  if (!config.features.showHeart) {
    return null;
  }

  return (
    <span
      className={cn(styles.heart, isActive && styles.active)}
      onClick={switchState}
    />
  );
};

export default Heart;
