import React, { CSSProperties, FC } from 'react';

import { Button, useTheme } from '@material-ui/core';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import { animated as a, useSpring } from 'react-spring';

import { UPCOMING_EVENTS_ID } from '@Compo/Artist/Artist.component';
import ArtistSocialLinks from '@Compo/Event/components/ArtistSocialLinks';
import { Following } from '@Compo/eventBoxes/Buy/components/Following';
import { FollowingVariant } from '@Compo/eventBoxes/Buy/components/Following/Following.types';
import ChangeMonitorTag from '@Compo/reusable/ChangeMonitorTag';
import Link from '@Compo/reusable/DualLink';
import ImageFixed from '@Compo/reusable/ImageFixed';
import Price from '@Compo/reusable/Price';
import ToolTip from '@Compo/reusable/ToolTip';
import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import getNonEmptyObject from '@Misc/helpers/getNonEmptyObject';
import icons from '@Misc/helpers/icons';
import prepareUrlForRoute from '@Misc/helpers/prepareUrlForRoute';
import scrollTo from '@Misc/helpers/scrollTo';
import translatableDate from '@Misc/helpers/translatableDate';
import useVerticalScrollValue from '@Misc/hooks/useVerticalScrollValue';
import routes from '@Routes/routes';

import Triangle from './../Triangle';
import classes from './Buy.classes';
import styles from './Buy.module.scss';
import useStyles from './Buy.styles';
import { IBuyProps } from './Buy.types';
import Bar from './components/Bar';
import Loading from './components/Loading';

const calendarIcon = !config.theme.isGoing
  ? cn(styles.icon, icons.calendar)
  : styles.calendarIcon;
const clockIcon = !config.theme.isGoing
  ? cn(styles.icon, icons.clock)
  : styles.clockIcon;
const locationIcon = !config.theme.isGoing
  ? cn(styles.icon, icons.location)
  : styles.locationIcon;
const ticketIcon = !config.theme.isGoing
  ? cn(styles.icon, icons.ticket)
  : styles.ticketIcon;

const TICKETS_ID = 'terminy-i-bilety';

const Buy: FC<IBuyProps> = ({
  eventArtist,
  artistClosestPool,
  artistEvents,
  event,
  buyButtonAnalytic,
  dateTranslate,
  image,
  isBrowser,
  isPreview,
  isArtist,
  isDesktop,
  isLoading,
  isMobile,
  isTablet,
  lang,
  params,
  withFloatedBar,
  tickets,
  eventProceed,
  favoriteId,
  isPool,
}) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);
  const [buttonRef, inView] = useInView({
    initialInView: true,
    threshold: 0.01,
  });
  const showTitle = isDesktop ? useVerticalScrollValue(100, 400) : false;
  const DISABLED_TOOLTIP_TEXT =
    lang.disabledTooltip + (config.theme.isGoing ? 'Going.' : 'Empik Bilety');
  const eventObject = getNonEmptyObject(!isArtist ? event : artistClosestPool);
  const buyUrl = fillUrlWithValues(
    lang.buyRoute,
    [':eventSlug', ':rundateSlug'],
    [eventObject.eventSlug, eventObject.rundateSlug]
  );

  const descriptionForScrapperPriceTBA =
    !isPool && eventObject.priceDescription === 'TBA'
      ? lang.noInfoAboutPrice
      : eventObject.priceDescription;

  const buyLink = () => {
    if (!config.app.onlineSale) {
      return buyUrl;
    }

    if (eventObject.redirectToUrl) {
      return eventObject.redirectToUrl;
    }

    if (event?.queue === 'queuegoingsite') {
      if (event.rundateSlug) {
        return `${config.app.queueUrl}/kup-bilety/${event.eventSlug}/${event.rundateSlug}`;
      }

      return `${config.app.queueUrl}/kup-bilety/${event.eventSlug}`;
    }

    return buyUrl;
  };

  const isPriceAvailable = Boolean(eventObject.price?.length);
  const canBuyTicket = eventObject.isAvailable;
  const hasRundates = getNonEmptyObject(event).rundatesCount > 0;
  const showLinkButton = canBuyTicket || eventObject.redirectToUrl;
  const buyButtonClicked = () => {
    eventProceed(lang.buyTicket);
    if (buyButtonAnalytic) {
      buyButtonAnalytic();
    }
  };

  const empikPadding = showTitle ? '0 0 1rem' : '0rem 0rem 0rem';
  const goingPadding = showTitle ? '2rem 2rem 1.5rem' : '0rem 2rem 0rem';
  const showAnimation: CSSProperties = useSpring({
    maxHeight: showTitle ? 1000 : 0,
    opacity: showTitle ? 1 : 0,
    padding: config.theme.isEmpik ? empikPadding : goingPadding,
  });

  const filteredTickets = tickets.filter(
    (ticket) => ticket.eventSlug === params.eventSlug
  );

  const isServiceFeeInfoEnabled = isArtist
    ? canBuyTicket || isPreview
    : (canBuyTicket && hasRundates) || isPreview;
  const isBuyButtonEnabled = isArtist
    ? showLinkButton || isPreview
    : (showLinkButton && hasRundates) || isPreview;

  return (
    <div className={cn(styles.buyCallBox, classes.buyCallBox)}>
      <div className={cn(styles.wrapper, muiStyles.root, classes.wrapper)}>
        <div className={cn(config.theme.isGoing && muiStyles.imageContainer)}>
          {(isMobile || isTablet) && (
            <ImageFixed
              src={
                event?.externalImageId ||
                event?.externalImages[0] ||
                image ||
                eventObject.imagesUrl
              }
              title={eventObject.title}
              wide={isTablet}
              transformation={{
                height: 700,
                width: 810,
              }}
            />
          )}
        </div>
        <div
          className={cn(
            styles.buy,
            muiStyles.root,
            muiStyles.backgroundBox,
            classes.buy
          )}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {isDesktop && (
                <a.h2
                  className={cn(styles.title, classes.title)}
                  style={showAnimation}
                >
                  {eventObject.title}
                </a.h2>
              )}
              <div
                className={cn(styles.buyMain, muiStyles.root, classes.buyMain)}
                data-test-id={'buy-component'}
              >
                {isArtist && (
                  <h3 className={styles.nearest}>
                    {eventObject.startDate
                      ? lang.nearest
                      : lang.noUpcomingEvents}
                  </h3>
                )}
                {!!event?.changeMonitorType && (
                  <ChangeMonitorTag
                    changeMonitorType={event.changeMonitorType}
                  />
                )}
                {(eventObject.startDate || eventObject.rundateDescription) && (
                  <div className={cn(styles.date, classes.date)}>
                    <span className={cn(calendarIcon, classes.calendarIcon)} />
                    <div>
                      <h3>
                        {translatableDate(
                          eventObject.startDate,
                          eventObject.rundateDescription,
                          dateTranslate,
                          eventObject.endDate
                        )}
                      </h3>
                    </div>
                  </div>
                )}
                {eventObject.friendlyHour && (
                  <div className={cn(styles.time, classes.time)}>
                    <span className={cn(clockIcon, classes.clockIcon)} />
                    <h3>{eventObject.friendlyHour}</h3>
                  </div>
                )}
                {eventObject.placeSlug && (
                  <div
                    className={cn(styles.place, muiStyles.root, classes.place)}
                  >
                    <span className={cn(locationIcon, classes.locationIcon)} />
                    <h3>
                      <Link
                        to={prepareUrlForRoute(
                          routes.place,
                          eventObject.placeSlug
                        )}
                      >
                        {eventObject.placeName}, {eventObject.placeCityName}
                      </Link>
                    </h3>
                  </div>
                )}
                {(!!eventObject.price?.length ||
                  !!eventObject.priceDescription?.length) && (
                  <div className={cn(styles.tickets, classes.tickets)}>
                    {config.theme.isGoing && (
                      <span className={cn(ticketIcon, classes.ticketIcon)} />
                    )}
                    <div className={cn(styles.price, classes.price)}>
                      <Price
                        currency={eventObject.currency}
                        price={eventObject.price}
                        priceDescription={descriptionForScrapperPriceTBA}
                        isServiceFeeTooltipDisabled={true}
                      />
                    </div>
                  </div>
                )}

                {isServiceFeeInfoEnabled && (
                  <div className={muiStyles.serviceFeeInfo}>
                    {lang.serviceFeeInformationBox}
                  </div>
                )}

                <div
                  ref={withFloatedBar ? buttonRef : undefined}
                  className={classes.buttonWrapper}
                >
                  {isBuyButtonEnabled ? (
                    <Link
                      to={`${
                        isPreview ? buyLink() + '?preview=true' : buyLink()
                      }`}
                      className={cn(styles.button, classes.button)}
                      onClick={buyButtonClicked}
                    >
                      {eventObject.buttonLabel || lang.buyTicket}
                    </Link>
                  ) : (
                    <ToolTip bottom={true} title={DISABLED_TOOLTIP_TEXT}>
                      <span
                        className={cn(
                          styles.button,
                          styles.disabledButton,
                          classes.button,
                          classes.disabledButton
                        )}
                      >
                        {eventObject.buttonLabel || lang.buyTicket}
                      </span>
                    </ToolTip>
                  )}
                </div>

                {!!filteredTickets.length && filteredTickets.length > 1 && (
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    size={'large'}
                    onClick={(e) => scrollTo(e, TICKETS_ID)}
                    fullWidth={true}
                  >
                    {lang.anotherDateText}
                  </Button>
                )}

                {!!artistEvents.length && (
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    size={'large'}
                    onClick={(e) => scrollTo(e, UPCOMING_EVENTS_ID)}
                    fullWidth={true}
                  >
                    {lang.anotherDateText}
                  </Button>
                )}
                {config.app.showUnfinishedFeatures && !!favoriteId && (
                  <Following
                    variant={
                      isArtist
                        ? FollowingVariant.ARTIST
                        : canBuyTicket
                        ? FollowingVariant.RUNDATE
                        : FollowingVariant.SALES
                    }
                    ids={[favoriteId]}
                    itemName={
                      (isArtist ? eventArtist?.name : event?.title) ?? ''
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>

        {config.theme.isEmpik && !isTablet && <Triangle />}

        {eventArtist && config.theme.isGoing && (
          <ArtistSocialLinks artist={eventArtist} />
        )}
      </div>

      {!isLoading && canBuyTicket && withFloatedBar && (
        <Bar
          price={isPriceAvailable ? (eventObject.price as string) : undefined}
          show={!inView}
          url={buyUrl}
          currency={eventObject.currency}
        />
      )}
    </div>
  );
};

export default Buy;
