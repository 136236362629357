import { createSelector } from 'reselect';

import _Store from '@Store';

import config from '@Config';
import { getParams } from '@Model/internalRouter/selectors';
import { IInternalRouterParams } from '@Model/internalRouter/types';
import { IArtistMatchParams } from '@Routes/types';

import { IImagesReducer } from '../types';
import get from './get';

const getArtistImages = createSelector<
  _Store.IState,
  IInternalRouterParams,
  IImagesReducer,
  string[] | null
>([getParams, get], (_params, images) => {
  const params = _params as IArtistMatchParams;
  const key = `${config.images.artistPrefix}${params?.slug}`;
  if (key && key in images) {
    return images[key];
  }

  return null;
});

export default getArtistImages;
