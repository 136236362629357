import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  activeButton: {
    background: theme.buttonSelect?.background || theme.palette.primary.main,
  },
  button: {
    display: 'block',
    paddingTop: '1rem',
  },
  buttonLine: {
    lineHeight: 0,
  },
  cancelIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  content: {
    overflow: 'hidden',
    paddingBottom: '1.5rem',
  },
  modal: {
    borderColor: theme.border.color,
    borderRadius: theme.border.radius,
    borderStyle: theme.border.style,
    borderWidth: theme.border.width,
  },
}));

export default useStyles;
