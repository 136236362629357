import React from 'react';

import { Grid, Typography, useTheme } from '@material-ui/core';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import cn from 'classnames';

import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import classes from '../../../../Success.classes';
import useStyles from './SingleSegment.styles';
import { ISingleSegmentProps } from './SingleSegment.types';

const SingleSegment = ({ transactionItems, lang }: ISingleSegmentProps) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const { isMobile } = useMuiBreakpoints();

  return (
    <Grid container={true}>
      <Grid
        item={true}
        className={cn(muiStyles.iconWrapper, classes.singleSegmentIcon)}
      >
        <ConfirmationNumberOutlinedIcon className={muiStyles.icon} />
      </Grid>

      <Grid item={true} xs={true} className={classes.detailsSubContainer}>
        <Grid container={true} direction={'column'}>
          <Grid
            item={true}
            className={cn(muiStyles.headerContainer, classes.detailsHeader)}
          >
            <Typography
              variant={isMobile ? 'body1' : 'h5'}
              align={'left'}
              color={'textPrimary'}
            >
              {transactionItems.productName}
            </Typography>
          </Grid>

          <Grid item={true} xs={12} className={classes.infoDetails}>
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true} className={classes.placeText}>
                    <Typography
                      variant={'body2'}
                      align={'left'}
                      className={muiStyles.secondTypography}
                    >
                      {lang.place}
                    </Typography>
                  </Grid>

                  <Grid item={true} className={classes.place}>
                    <Typography
                      variant={'body2'}
                      align={'right'}
                      className={muiStyles.secondTypography}
                    >
                      {transactionItems.place?.placeName},{' '}
                      {transactionItems.place?.city}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item={true} xs={12}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true} className={classes.ticketsText}>
                    <Typography
                      variant={'body2'}
                      align={'left'}
                      className={muiStyles.secondTypography}
                    >
                      {lang.tickets}
                    </Typography>
                  </Grid>

                  <Grid item={true} className={classes.tickets}>
                    <Typography
                      variant={'body2'}
                      align={'right'}
                      className={muiStyles.secondTypography}
                    >
                      {transactionItems.quantity}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleSegment;
