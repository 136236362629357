import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEntryListReducer, IFormValues } from './../types';
import get from './get';

const getFormValues = createSelector<
  _Store.IState,
  IEntryListReducer,
  IFormValues
>([get], (state) => state.initialValues);

export default getFormValues;
