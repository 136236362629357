import { getType } from 'typesafe-actions';

import { IAction, IDataReducer } from '@Model/transaction/types';

import { sendTransaction } from './../../actions';

const reducer = (state: IDataReducer = null, action: IAction): IDataReducer => {
  switch (action.type) {
    // _POST_SUCCESS
    case getType(sendTransaction.success):
      return action.payload.data;

    default:
      return state;
  }
};

export default reducer;
