import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      marginTop: theme.spacing(6),
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    withoutPadding: {
      padding: '0!important',
    },
  })
);

export default useStyles;
