import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';

import { useLayoutEffect } from '@Misc/hooks/useIsomorphicLayoutEffect';
import iframeProvider from '@Services/$iframe-provider';

import useStyles from './Notification.styles';
import { INotificationProps } from './Notification.types';

const BUTTON_TEXT = 'OK';

const Notification = ({
  close,
  notifications,
  isEmbed,
}: INotificationProps) => {
  if (notifications.length === 0) {
    return null;
  }

  const [embedDialogPosition, setEmbedDialogPosition] = useState(0);
  const muiStyles = useStyles(isEmbed, embedDialogPosition)();

  const notification = notifications[0];

  const dismissMessage = () => close(notification.id);

  useLayoutEffect(() => {
    if (isEmbed) {
      iframeProvider.getIframeRelativeScrollPosition(setEmbedDialogPosition);
    }

    return () => window.removeEventListener('message', () => {});
  }, []);

  return (
    <Dialog
      maxWidth={'sm'}
      open={!!notifications.length}
      onClose={dismissMessage}
      className={muiStyles.root}
    >
      <DialogContent className={muiStyles.content}>
        <Typography variant={'body1'}>{notification.message}</Typography>
      </DialogContent>
      <DialogActions className={muiStyles.actions}>
        <Button
          variant={'contained'}
          size={'medium'}
          color={'primary'}
          onClick={dismissMessage}
        >
          {BUTTON_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Notification;
