import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import AWSQueryBuilder from '@Misc/classes/AWSQueryBuilder';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import getCurrencyFromCode from '@Misc/helpers/getCurrencyFromCode';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import { IPool, IPoolsData } from '@Model/pools/types';

import {
  INormalizedPools,
  IPoolDescriptionResponse,
  IPoolsAwsResponseData,
  IPoolsHitResponse,
  IPoolsResponse,
} from './types';

const SEARCH_ALL_PHRASE = AWSQueryBuilder.phrase([]);

class PoolsApi {
  private static salesAgentHeader = {
    'X-Sales-Agent': config.app.salesAgent,
  };

  private static getUrl({
    eventSlug,
    language,
    rundateSlug,
    isPreview,
  }: {
    eventSlug: string;
    rundateSlug: string;
    isPreview?: boolean;
    language?: string;
  }): string {
    const uri = new URL(
      `${config.api.baseUrl}rundate/${eventSlug}/${rundateSlug}/pools`
    );

    if (typeof window !== 'undefined') {
      const isSearch = window.location.search;

      if (isSearch) {
        const params = new URLSearchParams(uri.search);

        if (language) {
          params.append('language', language);
        }

        if (isPreview) {
          params.append('preview', 'true');
        }

        return `${uri}?${params}`;
      }
    }

    return `${uri}`;
  }

  private static getProductsUrl(): string {
    return `${config.api.baseUrl}pools/products`;
  }

  private static getPoolsUrl(): string {
    return `${config.api.awsUrl}`;
  }

  private static howManyTicketsUserCanSelect(poolLimit: number): number {
    return Math.min(poolLimit, config.buy.maxTicketsCount);
  }

  private static getClosestPools(eventSlug: string): string {
    const closestTerms = [
      {
        name: 'has_pools',
        value: config.theme.isGoing ? ['0', '1'] : ['1'],
      },
      {
        name: 'sales_channels_ids',
        value: [config.app.salesChannelId.toString()],
      },
      {
        name: 'event_slug',
        value: [eventSlug],
      },
    ];

    const closestSort = [{ priority: 1, field: 'closest', sort: 'desc' }];

    const queryString = [
      SEARCH_ALL_PHRASE,
      AWSQueryBuilder.rangeDate(
        new Date(new Date().setHours(0, 0, 0, 0)).toString(),
        ''
      ),
      AWSQueryBuilder.term(closestTerms),
    ];

    return AWSQueryBuilder.query('search', queryString.join(''), [
      AWSQueryBuilder.option('format', 'json'),
      AWSQueryBuilder.option('q.parser', 'lucene'),
      AWSQueryBuilder.option('size', 4),
      AWSQueryBuilder.option('sort', AWSQueryBuilder.sort(closestSort)),
      AWSQueryBuilder.option('expr.closest', '(_time%20-%20rundate_rundate)'),
      AWSQueryBuilder.return([
        'rundate_slug',
        'price',
        'rundate_rundate',
        'event_slug',
        'price_description_pl',
        'place_id',
        'city_name',
        'place_name',
      ]),
    ]);
  }

  private cancelToken?: CancelTokenSource;

  public getPools({
    eventSlug,
    rundateSlug,
    language,
    isPreview,
  }: {
    eventSlug: string;
    rundateSlug: string;
    language?: string;
    isPreview?: boolean;
  }): Promise<IPoolsResponse> {
    return new Promise((resolve, reject) => {
      const url = PoolsApi.getUrl({
        eventSlug,
        isPreview,
        language,
        rundateSlug,
      });

      this.cancelToken = axios.CancelToken.source();

      axios
        .get(
          url,
          withCacheHeader({
            cancelToken: this.cancelToken.token,
            headers: PoolsApi.salesAgentHeader,
          })
        )
        .then(getData)
        .then((response: IPoolsResponse) => {
          resolve({ ...response, rundateSlug });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getProducts(poolIds: number[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = PoolsApi.getProductsUrl();

      this.cancelToken = axios.CancelToken.source();

      axios
        .get(
          url,
          withCacheHeader({
            cancelToken: this.cancelToken.token,
            params: { poolIds },
          })
        )
        .then(getData)
        .then(getData)
        // TODO: any
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getClosestPools(eventSlug: string): Promise<INormalizedPools[]> {
    return new Promise((resolve, reject) => {
      const queryString = PoolsApi.getClosestPools(eventSlug);
      const urlClosestPools = `${PoolsApi.getPoolsUrl()}${queryString}`;
      this.cancelToken = axios.CancelToken.source();

      axios
        .get(
          urlClosestPools,
          withCacheHeader({
            cancelToken: this.cancelToken.token,
          })
        )
        .then(getData)
        .then((response: IPoolsAwsResponseData) => {
          return resolve(response.hits.hit.map(this.normalizePools));
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelPools() {
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.cancelToken = undefined;
    }
  }

  public normalizePools(data: IPoolsHitResponse): INormalizedPools {
    const {
      fields: {
        city_name,
        event_slug,
        place_id,
        place_name,
        price,
        rundate_rundate,
        rundate_slug,
        price_description_pl,
      },
    } = data;

    return {
      cityName: city_name,
      eventSlug: event_slug,
      placeId: Number(place_id),
      placeName: place_name,
      poolsData: null,
      price,
      priceDescription: price_description_pl,
      rundate: rundate_rundate,
      rundateSlug: rundate_slug,
    };
  }

  public normalize(data: IPoolsResponse): IPoolsData {
    return {
      ...data,
      pools: data.pools.map((pool): IPool => {
        const description =
          pool.poolDescription || ({} as IPoolDescriptionResponse);

        const poolHasSeatsIoCats = pool.seatsIoCategories.length > 0;

        const normalizedSeatsIoCats = poolHasSeatsIoCats && [
          {
            categoryKey: pool.seatsIoCategories[0].categoryKey,
            ticketTypes: [
              {
                label: pool.seatsIoCategories[0].categoryName,
                price: pool.seatsIoCategories[0].price,
                ticketType: pool.id.toString(),
              },
            ],
          },
        ];

        return {
          active: pool.active,
          courierRequired: pool.onlyByShipment,
          currency: pool.currency,
          description: description.description || '',
          discountEnabled: pool.discountEnabled,
          discountMaxTickets: pool.discountMaxTickets,
          forms: pool.forms,
          hasDependency: pool.hasDependency,
          hasTransactionDataHydrator: pool.hasTransactionDataHydrator || false,
          id: pool.id,
          isDetailedPurchaseRequired: pool.isDetailedPurchaseRequired,
          payments: pool.payments,
          price: pool.price,
          seatsIoCategories: normalizedSeatsIoCats || [],
          serviceFee: pool.serviceFee,
          sortOrder: pool.sortOrder,
          ticketInfo: description.ticketInfo || '',
          ticketsLimit: PoolsApi.howManyTicketsUserCanSelect(
            pool.ticketsNumLimit
          ),
          title: description.title || '',
          vat: pool.vat,
          poolGroupEN: pool.poolGroupEN,
          poolGroupPL: pool.poolGroupPL,
        };
      }),
      rundateSlug: data.rundateSlug,
    };
  }
}

export default new PoolsApi();
