import React from 'react';

import MarkdownText from '@Compo/reusable/MarkdownText';

import styles from './DescriptionBrick.module.scss';
import { IDescriptionBrickProps } from './DescriptionBrick.types';

const DescriptionBrick = ({
  text,
  id,
  isLoading,
  color,
}: IDescriptionBrickProps) => (
  <div className={styles.description} id={id}>
    <MarkdownText color={color} text={text} />
  </div>
);

export default DescriptionBrick;
