import React, { FC } from 'react';

import Gallery from '@Compo/reusable/Gallery';

import { IArtistGalleryTypes } from './ArtistGallery.types';

const ArtistGallery: FC<IArtistGalleryTypes> = ({
  anchor,
  customClassImage,
  images,
  isLoading,
  vertical,
}) => (
  <Gallery
    isLoading={isLoading}
    anchor={anchor}
    photos={images.map((image) => image.imgBig)}
    vertical={vertical}
    customClassImage={customClassImage}
  />
);

export default ArtistGallery;
