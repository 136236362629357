export enum specialEventNames {
  blokParty,
  lasFestival,
  sbmEvent,
  ekipaEvent,
  aliceCooperRundate,
  shaggyRundate,
  festFestival2022Rundate,
  onAirFestival,
  igoWroclaw,
  igoLodz,
  igoGdansk,
  igoBydgoszcz,
  igoPoznan,
  igoKrakow,
  igoWarszawa,
  snowFest2023,
  dreamTheater,
  jimekIGoscie,
  jimekHistoriaPolskiegoHH,
  mataGliwice,
  mataSzczecin,
  radzimirWarszawa1,
  radzimirWarszawa2,
  radzimirWroclaw,
  radzimirLuslawice,
  hey30,
  hey30SecondDate,
}
