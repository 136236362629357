import { connect } from 'react-redux';

import _Store from '@Store';

import { getCompositionIdentifier } from '@Model/pages/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import CarouselBrick from './CarouselBrick.component';
import {
  ICarouselBrickFromState,
  ICarouselBrickOwnProps,
} from './CarouselBrick.types';

const mapStateToProps = (state: _Store.IState) => {
  return {
    isLoading: getLoading(CONST.SECTIONS)(state),
    compositionSlug: getCompositionIdentifier(state),
    compositionIdentifier: getCompositionIdentifier(state),
  };
};

export default connect<
  ICarouselBrickFromState,
  null,
  ICarouselBrickOwnProps,
  _Store.IState
>(mapStateToProps)(CarouselBrick);
