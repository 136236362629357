import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { validateForm } from '@Model/analytics/actions';
import {
  basketBuyFormMounted,
  updateBasketStateFromContext,
} from '@Model/basket/actions';
import { translate } from '@Model/locale/selectors';
import { isDataHydratedTransaction } from '@Model/pools/selectors';
import { buyAndPayOnline } from '@Model/transaction/actions';

import { getInitialValues } from './../Online/Online.selectors';
import Basket from './Basket.component';
import {
  IBasketFromDispatch,
  IBasketFromState,
  IBasketOwnProps,
} from './Basket.types';

const mapStateToProps = (state: _Store.IState): IBasketFromState => {
  return {
    errors: translate(state)('errors'),
    initialValues: (basketCustomTerms) =>
      getInitialValues(state, basketCustomTerms),
    isDataHydratedTransaction: isDataHydratedTransaction(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IBasketFromDispatch => {
  return {
    buyAndPay: (values) => dispatch(buyAndPayOnline(values)),
    checkout: () => dispatch(validateForm()),
    mounted: () => dispatch(basketBuyFormMounted()),
    updateBasketState: (basketState) =>
      dispatch(updateBasketStateFromContext(basketState)),
  };
};

export default connect<
  void,
  IBasketFromDispatch,
  IBasketOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Basket);
