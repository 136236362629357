import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&:hover': {
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      },
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
    header: {
      marginBottom: '2rem',

      '&:before': {
        content: "attr(data-number) '. '",
      },
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    withoutPadding: {
      padding: `0!important`,
    },
  })
);

export default useStyles;
