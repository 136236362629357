import React, { FC } from 'react';

import { Button, useTheme } from '@material-ui/core';
import cn from 'classnames';

import useStyles from './MuiButton.styles';
import { IMuiButtonProps } from './MuiButton.types';

const MuiButton: FC<IMuiButtonProps> = (props) => {
  const { children } = props;

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <Button
      {...props}
      variant={'contained'}
      className={cn(muiStyles.button, props.className)}
    >
      {children}
    </Button>
  );
};

export default MuiButton;
