import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      background: theme.palette.background.paper,
      borderColor: theme.border.color,
      borderRadius: `${theme.border.radius}!important`,
      borderStyle: theme.border.style,
      borderWidth: theme.border.width,
    },
    tooltip: {
      marginLeft: theme.spacing(2),
    },
  })
);

export default useStyles;
