import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empik: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.secondary.contrastText,
    },
    going: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    root: {
      padding: '4rem 1.25rem',
    },
  })
);

export default useStyles;
