import { createSelector } from 'reselect';

import { getExternalLangMap, getLangMap } from '@Model/locale/selectors/index';

import { ILang, ILangExtend } from './../types';

const translate = createSelector(
  [getLangMap, getExternalLangMap],
  (langMap: ILangExtend, externalLangMap: ILangExtend | undefined) =>
    (view: string, component?: string): ILang => {
      if (
        externalLangMap &&
        component &&
        externalLangMap[view].hasOwnProperty(component)
      ) {
        // @ts-ignore
        return externalLangMap[view][component] as ILang;
      }
      if (externalLangMap && externalLangMap.hasOwnProperty(view)) {
        return externalLangMap[view] as ILang;
      }
      if (component && langMap[view].hasOwnProperty(component)) {
        // @ts-ignore
        return langMap[view][component] as ILang;
      }

      return langMap[view] as ILang;
    }
);

export default translate;
