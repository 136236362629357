import { createSelector } from 'reselect';

import _Store from '@Store';

import { IErrorsReducer } from './../types';
import get from './get';

const getLinkLabel = createSelector<
  _Store.IState,
  IErrorsReducer,
  string | null
>([get], (error) => error.errorMessageLinkLabel);

export default getLinkLabel;
