import { Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontColor: {
      color: theme.palette.text.primary,
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default useStyles;
