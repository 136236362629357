import React, { FC } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import icons from '@Misc/helpers/icons';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import styles from './InfoBox.module.scss';
import useStyles from './InfoBox.styles';
import { IInfos } from './InfoBox.types';

const INFO_TEXT = 'informacje ogólne';

const InfoBox: FC<IInfos> = ({ infos }) => {
  const { isDesktop } = useMuiBreakpoints();

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionTitle}>{INFO_TEXT}</div>
      {infos.map((info, id) => {
        return (
          <div className={cn(styles.infoContainer, muiStyles.root)} key={id}>
            <div className={styles.iconColumn}>
              <span className={cn(styles.icon, icons[info.icon])} />
            </div>
            <div className={styles.infoColumn}>
              <div
                className={cn(
                  styles.titleWrapper,
                  isDesktop && muiStyles.yellowBg
                )}
              >
                <p className={styles.title}>{info.title}</p>
              </div>
              <div className={styles.descriptionWrapper}>
                <p className={styles.description}>{info.description}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InfoBox;
