import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getSelectedLocation } from '@Model/locations/selectors';
import { authorizePage, getCustomPage } from '@Model/pages/actions';
import { getPagesAuth, getRecommend } from '@Model/pages/selectors';

import Recommend from './Recommend.component';
import { IRecommendFromDispatch, IRecommendFromState } from './Recommend.types';

const mapStateToProps = (state: _Store.IState): IRecommendFromState => ({
  pagesAuth: getPagesAuth(state),
  structure: getRecommend(state),
  userLocation: getSelectedLocation(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IRecommendFromDispatch => ({
  authPage: (payload) => dispatch(authorizePage(payload)),
  mounted: () => dispatch(getCustomPage()),
});

export default withRouter(
  connect<IRecommendFromState, IRecommendFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(Recommend)
);
