import catchHttpError from '@Misc/helpers/api/catchHttpError';
import Logger from '@Services/Logger';

export class Fetcher {
  public static get = async (url: string, headers?: HeadersInit) => {
    try {
      const data = await fetch(url, {
        method: 'get',
        headers: new Headers(headers),
      });

      return data.json();
    } catch (error: any) {
      Logger.error('Fetcher', error);

      return catchHttpError(error);
    }
  };

  public static post = async (
    url: string,
    body?: BodyInit,
    headers?: HeadersInit
  ) => {
    try {
      const data = await fetch(url, {
        body,
        method: 'post',
        headers: new Headers(headers),
      });

      return data.json();
    } catch (error: any) {
      Logger.error('Fetcher', error);

      return catchHttpError(error);
    }
  };
}
