import { getType } from 'typesafe-actions';

import { getClosestPools, getPools, resetState } from './../../actions';
import { IAction, IPoolsAllReducer } from './../../types';

export const initialState: IPoolsAllReducer = {
  closestPoolsData: [],
  poolsData: null,
};

const reducer = (
  state: IPoolsAllReducer = initialState,
  action: IAction
): IPoolsAllReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getPools.success):
      return {
        ...state,
        closestPoolsData: state.closestPoolsData.map((pool) => {
          if (pool?.rundateSlug === action.payload.poolsData?.rundateSlug) {
            return { ...pool, poolsData: action.payload.poolsData };
          }

          return pool;
        }),
        poolsData: action.payload.poolsData,
      };

    case getType(getClosestPools.success):
      return { ...state, closestPoolsData: action.payload };

    case getType(resetState):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
