import { connect } from 'react-redux';

import _Store from '@Store';

import { isEnglish } from '@Model/locale/selectors';

import Title from './Title.component';
import { ITitleFromState, ITitleOwnProps } from './Title.types';

const mapStateToProps = (state: _Store.IState): ITitleFromState => ({
  isEnglish: isEnglish(state),
});

export default connect<ITitleFromState, void, ITitleOwnProps, _Store.IState>(
  mapStateToProps
)(Title);
