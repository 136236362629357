import { getType } from 'typesafe-actions';

import { IEventFull } from '@Model/event/types';

import { getEventData, resetTickets } from './../../actions';
import { IAction, ITicketsReducer } from './../../types';

const reducer = (
  state: IEventFull | null = null,
  action: IAction
): IEventFull | null => {
  switch (action.type) {
    case getType(getEventData.success):
      return action.payload;

    case getType(resetTickets):
      return null;

    default:
      return state;
  }
};

export default reducer;
