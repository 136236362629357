import { createSelector } from 'reselect';

import * as CONSTS from './../constants/constants';
import getDevice from './getDevice';

const isTablet = createSelector(
  [getDevice],
  (device) => device === CONSTS.TABLET
);

export default isTablet;
