import React, { FC } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import { getImageUrl } from '@Services/Cloudinary';

import * as CONSTS from './Gallery.constants';
import styles from './Gallery.module.scss';
import { IGalleryProps } from './Gallery.types';
import ViewerModal from './components/ViewerModal';

const HORIZONTAL_IMAGES_AMOUNT = 6;
const VERTICAL_IMAGES_AMOUNT = 4;
const MOBILE_IMAGES_AMOUNT = 4;

const Gallery: FC<IGalleryProps> = ({
  anchor,
  customClassImage,
  customContainerClass,
  isDesktop,
  isLoading,
  photos,
  vertical,
}) => {
  const { isMobile } = useMuiBreakpoints();
  const theme = useTheme();
  const miniaturesToShow = !isDesktop
    ? MOBILE_IMAGES_AMOUNT
    : vertical
    ? VERTICAL_IMAGES_AMOUNT
    : HORIZONTAL_IMAGES_AMOUNT;
  const howMuchPhotosDisplay =
    photos.length > miniaturesToShow ? miniaturesToShow - 1 : miniaturesToShow;
  const photosToShow = photos.slice(0, howMuchPhotosDisplay);
  const notDisplayedPhotosAmount =
    isLoading || photos.length === 1 ? 0 : photos.length - howMuchPhotosDisplay;
  const notDisplayedToRender =
    notDisplayedPhotosAmount >= 1 ? '+' + notDisplayedPhotosAmount : '';
  const showVerticalGallery = isDesktop && vertical;
  const galleryAnchor = anchor
    ? anchor + CONSTS.GALLERY_SEPARATOR
    : CONSTS.GALLERY_DEFAULT_ANCHOR;
  const imageSize = isMobile
    ? theme.breakpoints.values.sm
    : theme.breakpoints.values.lg;

  const openModal = (image: number) => {
    window.location.hash = galleryAnchor + image;
  };

  return (
    <>
      <div
        className={cn(
          styles.imagesContainer,
          showVerticalGallery && styles.vertical,
          customContainerClass
        )}
      >
        {photosToShow.map((photo, id) => (
          <img
            key={id}
            className={cn(
              styles.image,
              isLoading && styles.loading,
              customClassImage
            )}
            onClick={() => openModal(id)}
            alt={photo}
            src={getImageUrl(photo, {
              width: imageSize,
              height: imageSize,
            })}
          />
        ))}
        {!!notDisplayedPhotosAmount && (
          <div
            className={styles.image}
            onClick={() => openModal(howMuchPhotosDisplay)}
          >
            {notDisplayedToRender}
          </div>
        )}
      </div>
      {!isLoading && photos.length > 1 && (
        <ViewerModal anchor={galleryAnchor} images={photos} />
      )}
    </>
  );
};

export default Gallery;
