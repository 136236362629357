import React, { FC, useEffect, useState } from 'react';

import CarouselFixed from '@/components/reusable/CarouselFixed';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import YouTube from 'react-youtube';

import Breadcrumb from '@Compo/Breadcrumb';
import Bricks from '@Compo/Bricks';
import classes from '@Compo/Event/Event.classes';
import useStyles from '@Compo/Event/Event.styles';
import Top from '@Compo/PlaceEvents/components/Top';
import BuyBox from '@Compo/eventBoxes/Buy';
import ArtistSlider from '@Compo/reusable/ArtistSlider';
import { IArtistTile } from '@Compo/reusable/ArtistSlider/components/ArtistTile/ArtistTile.types';
import { ICard } from '@Compo/reusable/Card/Card.types';
import CardDeck from '@Compo/reusable/CardDeck';
import DeepLinkModal from '@Compo/reusable/DeepLinkModal';
import Description from '@Compo/reusable/Description';
import { default as SimilarEvent } from '@Compo/reusable/Event';
import ImageFixed from '@Compo/reusable/ImageFixed';
import Tags from '@Compo/reusable/Tags';
import DetailsLoading from '@Compo/reusable/loadings/DetailsLoading';
import TagsLoading from '@Compo/reusable/loadings/TagsLoading';
import TitleLoading from '@Compo/reusable/loadings/TitleLoading';
import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import getNonEmptyObject from '@Misc/helpers/getNonEmptyObject';
import isEmptyObject from '@Misc/helpers/isEmptyObject';
import isHTML from '@Misc/helpers/isHTML';
import { IEvent } from '@Model/events/types';
import { useDictionary } from '@Recoil/lang/hooks/useDictionary';
import routes from '@Routes/routes';
import PlacesApi from '@Services/$places-api';
import mobile_banner from '@Styles/images/event_baner_page_breaker_810.png';
import desktop_banner from '@Styles/images/event_baner_page_breaker_1280x264.png';

import { ICarouselFixedImage } from '../reusable/CarouselFixed/CarouselFixed.types';
import Gallery from './../reusable/Gallery';
import styles from './Event.module.scss';
import { IEventProps } from './Event.types';
import EventArtists from './components/EventArtists';
import Location from './components/Location';
import Tickets from './components/Tickets';

const E_KEY_CODE = 69;
const EVENT_BANNER_LABEL = 'baner wydarzenie';

// This link is only for a static banner to redirect to a subpage
const ABOUT_APP_LINK = 'https://goingapp.pl/o-aplikacji';

const Event: FC<IEventProps> = ({
  event: eventProps,
  placeImage,
  eventBannerClick,
  isDesktop,
  isEmbed,
  isLoading,
  isLoadingEvent,
  isMobile,
  isTablet,
  mounted,
  params,
  placeEvents,
  similarEvents,
  structure,
  userLocation,
}) => {
  const i18n = useDictionary();
  const [activities, setActivities] = useState<ICard[]>([]);
  const event = getNonEmptyObject(eventProps);
  const tags = event.tags || [];
  const isVideo = !!event.videoId;
  const [redirect, setRedirect] = useState(false);
  const eventLink = fillUrlWithValues(
    i18n.routes.eventRoute,
    [':eventSlug', ':rundateSlug'],
    [event.eventSlug, event.rundateSlug]
  );

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const onBannerClick = () => {
    eventBannerClick(EVENT_BANNER_LABEL);
  };

  const onImageClick = () => {
    setRedirect(false);
    window.open(`${config.app.cmsUrl}events/events/update/${event.eventId}`);
  };

  const redirectToBackend = (eventKey: KeyboardEvent) => {
    if (eventKey.altKey && eventKey.keyCode === E_KEY_CODE) {
      setRedirect(true);
    }
  };

  const removeRedirectToBackend = (eventKey: KeyboardEvent) => {
    if (eventKey.altKey || eventKey.keyCode === E_KEY_CODE) {
      setRedirect(false);
    }
  };

  const shouldShowDesc =
    !config.theme.isEmpik || (config.theme.isEmpik && event.eventId < 3142539);
  const empikDefDesc = `${event.title}\r\n\r\n${event.placeFriendly}\r\n\r\n${
    event.friendlyDate
  }, ${event.friendlyHour ? event.friendlyHour : ''}`;

  const relatedArtists: IArtistTile[] | undefined =
    event.forFansOfArtists && event.forFansOfArtists.length > 0
      ? event.forFansOfArtists.map((artist) => ({
          link: fillUrlWithValues(routes.artist, ':slug', artist.slug),
          title: artist.name,
          slug: artist.slug,
        }))
      : undefined;

  useEffect(() => {
    document.addEventListener('keydown', redirectToBackend, false);
    document.addEventListener('keyup', removeRedirectToBackend, false);

    return () => {
      document.removeEventListener('keydown', redirectToBackend, false);
      document.addEventListener('keyup', removeRedirectToBackend, false);
    };
  }, []);

  useEffect(() => {
    mounted();
  }, [params]);

  useEffect(() => {
    if (event.calendarEvent) {
      PlacesApi.getPlaceActivities(event.placeSlug).then((response) =>
        setActivities(response)
      );
    } else {
      setActivities([]);
    }
  }, [event.calendarEvent]);

  if (isEmbed && isEmptyObject(event)) {
    return null;
  }

  const banner: ICarouselFixedImage = {
    alt: i18n.promotionBanner.altText,
    link: ABOUT_APP_LINK,
    wideImage: isMobile ? mobile_banner : desktop_banner,
  };

  return (
    <>
      <Breadcrumb
        current={{
          isLoading: isLoadingEvent,
          link: eventLink,
          name: event.title,
        }}
      />
      <div className={cn(styles.event, classes.event)}>
        <div className={cn(styles.header, classes.header)}>
          {isLoading ? <TitleLoading /> : <h1>{event.title}</h1>}
        </div>

        <div className={cn(styles.tagBar, classes.tagBar)}>
          <div className={cn(styles.tags, classes.tags)}>
            {isLoading ? <TagsLoading /> : <Tags tags={tags} />}
          </div>
        </div>

        <div className={cn(styles.twoColumns, classes.twoColumns)}>
          {(isMobile || isTablet) && (
            <BuyBox withFloatedBar={true} favoriteId={event.eventId} />
          )}

          {isDesktop && (
            <div className={cn(styles.fixedBox, classes.fixedBox)}>
              <BuyBox favoriteId={event.eventId} />
            </div>
          )}

          <div className={cn(styles.eventDetails, classes.eventDetails)}>
            {isDesktop && (
              <ImageFixed
                src={
                  event.externalImageId ||
                  (event.externalImages?.[0] ?? event.imagesUrl)
                }
                title={event.title}
                isLoading={isLoading}
                onClick={redirect ? onImageClick : undefined}
                transformation={{
                  height: 700,
                  width: 810,
                }}
              />
            )}

            {isLoading ? (
              <DetailsLoading />
            ) : (
              <>
                {event.description && (
                  <Description
                    text={event.description}
                    isVideo={isVideo}
                    html={
                      isHTML(event.description) ? event.description : undefined
                    }
                  />
                )}

                {!shouldShowDesc && !event.description && (
                  <Description text={empikDefDesc} isVideo={isVideo} />
                )}

                {event.artists &&
                  event.artists.length > 0 &&
                  config.theme.isGoing && (
                    <EventArtists
                      artists={event.artists}
                      sectionTitle={i18n.event.participatingArtists}
                    />
                  )}

                {shouldShowDesc && event.additionalDescription && (
                  <>
                    <div className={styles.sectionTitle}>
                      {i18n.event.additionalDescriptionTitle}
                    </div>
                    <Description
                      text={event.additionalDescription}
                      html={
                        isHTML(event.additionalDescription)
                          ? event.additionalDescription
                          : undefined
                      }
                    />
                  </>
                )}

                {event.videoId && (
                  <div className={styles.video}>
                    <div className={styles.videoCover}>
                      <YouTube
                        videoId={event.videoId}
                        containerClassName={styles.player}
                      />
                    </div>
                  </div>
                )}
                {config.features.eventBricks && (
                  <Bricks
                    structure={structure}
                    inner={true}
                    userLocation={userLocation}
                  />
                )}
              </>
            )}
          </div>
        </div>

        {!isLoading && !event.calendarEvent && !event.redirectToUrl && (
          <div className={isDesktop ? styles.bannerContainer : undefined}>
            <Tickets key={event.rundateId} />
          </div>
        )}
        <div onClick={onBannerClick}>
          <CarouselFixed
            images={[banner]}
            isLoading={false}
            GAclass={classes.gaClass}
          />
        </div>

        {!!event.externalImages && event.externalImages.length > 1 && (
          <>
            <div className={styles.sectionTitle}>{i18n.event.gallery}</div>
            <Gallery
              photos={event.externalImages}
              isLoading={isLoading}
              vertical={true}
            />
          </>
        )}

        {!isLoading && (
          <div className={cn(styles.oneColumn, classes.oneColumn)}>
            <Location
              address={event.placeAddress}
              description={event.placeDescription}
              name={event.placeName}
              placeImageUrl={
                !!placeImage?.length ? placeImage[0] : event.placeImageUrl
              }
              showTags={isDesktop}
              slug={event.placeSlug}
              tags={event.placeCategory}
            />
          </div>
        )}

        {relatedArtists &&
          relatedArtists.length > 0 &&
          config.theme.isGoing && (
            <>
              <div className={styles.sectionTitle}>{i18n.event.forFansOf}</div>
              <div className={muiStyles.artistSliderContainer}>
                <ArtistSlider artistSection={relatedArtists} />
              </div>
            </>
          )}

        {placeEvents && placeEvents.length > 0 && (
          <CardDeck
            cards={placeEvents}
            fixedWidthCards={true}
            sectionTitle={i18n.event.samePlaceEventsTitle}
          />
        )}

        {!!activities.length && (
          <CardDeck
            cards={activities}
            fixedWidthCards={true}
            sectionTitle={i18n.event.samePlaceActivitiesTitle}
          />
        )}

        {similarEvents && similarEvents.length > 0 && (
          <div className={styles.similarEventsSection}>
            <div className={styles.sectionTitle}>
              {i18n.event.similarEventsTitle}
            </div>
            <div className={styles.sectionWrapper}>
              <ul className={styles.list}>
                {similarEvents.map((similarEvent: IEvent, id) => (
                  <SimilarEvent
                    key={similarEvent.id}
                    {...similarEvent}
                    isEmbed={isEmbed}
                    isMobile={isMobile}
                    position={id}
                  />
                ))}
              </ul>
              {!isMobile && config.theme.isEmpik && <Top />}
            </div>
          </div>
        )}
      </div>
      {(isMobile || isTablet) && config.theme.isGoing && (
        <DeepLinkModal url={eventLink} />
      )}
    </>
  );
};

export default Event;
