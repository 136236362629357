import { isToday, isTomorrow } from 'date-fns';
import format from 'date-fns/format';
import { enGB, pl } from 'date-fns/locale';

import EventsListService from '@Misc/classes/EventsListService';
import ucFirst from '@Misc/helpers/ucFirst';
import { ILang } from '@Model/locale/types';
import { IDate } from '@Services/$events-api/types';

const DEFAULT_DATE_TRANSLATE = {
  locale: 'pl',
  today: 'Dzisiaj',
  tomorrow: 'Jutro',
};

/**
 * Function to prepare url with Date from DateObject for specified locale
 *
 * @param {IDate | undefined} date - date Object as year, month, day, hour, minutes
 * @param rundateDescription
 * @param {ILang} translation - translate object for 'date'
 *
 * @param endDate
 * @return {string} friendlyDate
 */
const translatableDate = (
  date: IDate | string | undefined,
  rundateDescription: string | undefined,
  translation: ILang,
  endDate?: IDate | string
) => {
  if (rundateDescription) {
    return rundateDescription;
  }
  let startDate: string = '';
  let newEndDate: string = '';

  if (date && typeof date !== 'string') {
    startDate = EventsListService.getDateString(date);
  } else if (typeof date === 'string') {
    startDate = date;
  }

  if (!startDate) {
    return '';
  }

  if (endDate && typeof endDate !== 'string') {
    newEndDate = EventsListService.getDateString(endDate);
  } else if (typeof endDate === 'string') {
    newEndDate = endDate;
  }

  const _date = new Date(startDate);
  const timezonedDate = new Date(
    new Date(_date.valueOf() + _date.getTimezoneOffset() * 60 * 1000)
  );

  if (isToday(timezonedDate) && !endDate) {
    return translation?.today || DEFAULT_DATE_TRANSLATE.today;
  } else if (isTomorrow(timezonedDate) && !endDate) {
    return translation?.tomorrow || DEFAULT_DATE_TRANSLATE.tomorrow;
  }

  const locale = translation?.locale === 'en' ? enGB : pl;

  if (
    !endDate ||
    endDate.toString().split('T')[0] === date?.toString().split('T')[0]
  ) {
    return ucFirst(format(timezonedDate, 'EEEE, d MMMM yyyy', { locale }));
  }

  const _endDate = new Date(newEndDate);
  const timezonedEndDate = new Date(
    new Date(_endDate.valueOf() + _endDate.getTimezoneOffset() * 60 * 1000)
  );

  const timezonedFullDateLeft = ucFirst(
    ucFirst(format(timezonedDate, 'EEEE, d MMMM yyyy', { locale }))
  );
  const timezonedFullDateRight = ucFirst(
    format(timezonedEndDate, 'EEEE, d MMMM yyyy', { locale })
  );

  if (timezonedFullDateLeft === timezonedFullDateRight) {
    return timezonedFullDateLeft;
  }

  const timezonedDateLeft = timezonedDate.getFullYear();
  const timezonedDateRight = timezonedEndDate.getFullYear();

  const isSameYear = timezonedDateLeft === timezonedDateRight;

  return (
    ucFirst(
      format(timezonedDate, isSameYear ? 'd.MM - ' : 'd.MM.yy - ', { locale })
    ) + ucFirst(format(timezonedEndDate, 'd.MM.yy', { locale }))
  );
};

export default translatableDate;
