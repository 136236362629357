import {
  ITimelineBrickOwnProps,
  ITimelineExtra,
} from '@Compo/Bricks/components/TimelineBrick/TimelineBrick.types';
import { ITimelineMarker } from '@Compo/reusable/Timeline/Timeline.types';
import { ISectionsComponentDataMutable } from '@Model/pages/types/sections';

export const timeline = (
  data: ISectionsComponentDataMutable,
  extra?: ITimelineExtra | null,
  background?: string
): ITimelineBrickOwnProps => {
  const timelineData = data as ITimelineMarker[];

  return {
    background,
    color: extra?.color,
    timeline: timelineData,
  };
};
