import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { ILocations } from '@Model/locations/types';

import { ILocationsResponse } from './types';

class LocationsApi {
  private static getLocationsUri(): string {
    return `${config.api.baseUrl}mainpage/locations/${config.app.salesChannelId}`;
  }

  public getLocations(): Promise<ILocationsResponse> {
    return new Promise((resolve, reject) => {
      const locationsUri = LocationsApi.getLocationsUri();

      axios
        .get(locationsUri)
        .then(getData)
        .then((response: ILocationsResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(new Error(error)));
        });
    });
  }

  public normalizeLocations(locationsResponse: ILocationsResponse): ILocations {
    const normalizedLocations: ILocations = {};

    locationsResponse.map((location) => {
      normalizedLocations[location.slug] = {
        cities: location.cities.map((city) => city.id),
        name: location.name,
      };
    });

    return normalizedLocations;
  }
}

export default new LocationsApi();
