import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISelectionReducer, ISelectionReducerRequestTimes } from './../types';
import get from './get';

const getRequestTimes = createSelector<
  _Store.IState,
  ISelectionReducer,
  ISelectionReducerRequestTimes
>([get], (selection) => selection.requestTimes);

export default getRequestTimes;
