import { push } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import TransactionError from '@Misc/classes/TransactionError';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import getClosestValue from '@Misc/helpers/getClosestValue';
import isEmptyObject from '@Misc/helpers/isEmptyObject';
import { getIframeParams, getParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { createNotification } from '@Model/notifications/actions';
import { getLocation } from '@Model/router/selectors';
import { resetLoading, setLoading } from '@Model/state/actions';
import { sendTransaction } from '@Model/transaction/actions';
import { PaymentMethods } from '@Model/transaction/constants/paymentMethods';
import { ITransactionBody } from '@Model/transaction/types';
import routes from '@Routes/routes';
import { ITicketsMatchParams } from '@Routes/types';
import {
  IReceiveTicketsDataResponse,
  ITicketsDataResponse,
} from '@Services/$tickets-api/types';

import * as CONSTS from './../../state/constants/constants';
import {
  cancelTicketTransfer,
  cancelTransfer,
  changeDateMounted,
  downloadTicketFromTicketData,
  downloadTicketFromTicketDataOnRequest,
  downloadTicketFromTransactionData,
  downloadTicketFromTransactionDataOnRequest,
  getAvailabilities,
  getEventData,
  getTicketsData,
  getTicketsDataFromTransaction,
  getTransferTicketFee,
  receiveTicket,
  receiveTicketMounted,
  receiveTicketSubmit,
  resetTickets,
  returnTicket,
  returnTicketSubmit,
  setShowSender,
  setTicketDate,
  ticketDataFormSubmit,
  transactionDataFormSubmit,
  transferTicket,
  transferTicketFormSubmit,
} from './../actions';
import {
  getReceivedTicketData,
  getTicketTransferFee,
  getTicketsData as getTicketsDataSelector,
} from './../selectors';
import {
  IReturnTicketSuccessPayload,
  ITicketReceiveData,
  ITicketsListData,
} from './../types';

export const submitTicketDataForm: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(ticketDataFormSubmit)),
    mergeMap$((action) => {
      return [getTicketsData.request(action.payload)];
    })
  );
};

export const submitTransactionDataForm: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(transactionDataFormSubmit)),
    mergeMap$((action) => {
      return [getTicketsDataFromTransaction.request(action.payload)];
    })
  );
};

export const fetchTicketDataFromTransactionWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { eventsApi, ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getTicketsDataFromTransaction.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const body = action.payload;
      const translateDate = translate(state)('dates');

      return from$(ticketsApi.getTicketsDataFromTransaction(body)).pipe(
        mergeMap$((data: ITicketsDataResponse) => {
          const ticketData: ITicketsListData = [];

          data.map((ticket) => {
            const event = ticket.rundate
              ? eventsApi.normalizeFullEvent(ticket.rundate, translateDate)
              : ticketsApi.normalizeFullEvent(ticket.event);

            if (event) {
              ticketData.push({
                actions: ticket.actions,
                event,
                name: ticket.ticket.name || null,
                numberOfTickets: ticket.ticket.quantity,
                orderId: body.orderId,
                paymentSum: body.paymentSum,
                slugs: ticket.ticket.slug,
                ticketId: ticket.ticket.ticketId,
                token: ticket.token,
                transfers: ticket.transfers,
              });
            }
          });

          const location = getLocation(state);
          const hasParamsInUrl =
            location.query?.email && location.query?.entryToken;

          let redirectUri;

          if (hasParamsInUrl) {
            redirectUri = fillUrlWithValues(
              routes.transferTicket,
              ':ticketId',
              `${ticketData[0].ticketId}`
            );
          } else {
            redirectUri = isEmptyObject(state.internalRouter.embed)
              ? routes.myTicket
              : routes.pinMyTicket;
          }

          return [
            getTicketsDataFromTransaction.success(ticketData),
            push(redirectUri),
          ];
        }),
        catchError$((error: TransactionError) =>
          of$(getTicketsDataFromTransaction.failure(error))
        )
      );
    }),
    catchError$((error: TransactionError) =>
      of$(getTicketsDataFromTransaction.failure(error))
    )
  );
};

export const fetchTicketDataWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { eventsApi, ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getTicketsData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const body = action.payload;
      const translateDate = translate(state)('dates');
      const lang = translate(state)('ticketsManagement', 'ticketTransfer');

      return from$(ticketsApi.getTicketsData(body, lang)).pipe(
        mergeMap$((data: ITicketsDataResponse) => {
          const ticketData: ITicketsListData = [];
          data.map((ticket) => {
            const event = ticket.rundate
              ? eventsApi.normalizeFullEvent(ticket.rundate, translateDate)
              : ticketsApi.normalizeFullEvent(ticket.event);

            if (event) {
              ticketData.push({
                actions: ticket.actions,
                code: body.code.trim(),
                email: body.email.trim(),
                event,
                name: ticket.ticket.name || null,
                numberOfTickets: ticket.ticket.quantity,
                pool: ticket.pool,
                slugs: ticket.ticket.slug,
                ticketId: ticket.ticket.ticketId,
                token: ticket.token,
                transfers: ticket.transfers,
              });
            }
          });

          const location = getLocation(state);
          const hasParamsInUrl =
            location.query?.email && location.query?.entryToken;
          const isThemeDark = location.query?.isDark === 'true';

          let redirectUri;

          if (hasParamsInUrl) {
            redirectUri = fillUrlWithValues(
              routes.transferTicket,
              ':ticketId',
              `${ticketData[0].ticketId}`
            );
          } else {
            redirectUri = isEmptyObject(state.internalRouter.embed)
              ? routes.myTicket
              : routes.pinMyTicket;
          }

          return [
            getTicketsData.success(ticketData),
            push(`${redirectUri}${isThemeDark ? '?isDark=true' : ''}`),
            getTransferTicketFee.request(),
          ];
        }),
        catchError$((error: TransactionError) =>
          of$(getTicketsData.failure(error))
        )
      );
    }),
    catchError$((error: TransactionError) => of$(getTicketsData.failure(error)))
  );
};

export const submitTransferTicketForm: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(transferTicketFormSubmit)),
    mergeMap$((action) => {
      return [transferTicket.request(action.payload)];
    })
  );
};

export const sendTransferTicketRequest: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(transferTicket.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(ticketsApi.transferTicket(action.payload)).pipe(
        mergeMap$(() => {
          const location = getLocation(state);
          const isThemeDark = location.query?.isDark === 'true';
          const uri = isEmptyObject(state.internalRouter.embed)
            ? routes.transferSuccess
            : routes.pinTransferSuccess;

          return [
            transferTicket.success(),
            push(`${uri}${isThemeDark ? '?isDark=true' : ''}`),
          ];
        }),
        catchError$((error) => of$(transferTicket.failure(error)))
      );
    })
  );
};

export const submitCancelTransferTicketForm: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(cancelTransfer)),
    mergeMap$((action) => {
      return [cancelTicketTransfer.request(action.payload)];
    })
  );
};

export const sendCancelTransferTicketRequest: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(cancelTicketTransfer.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(ticketsApi.cancelTicketTransfer(action.payload)).pipe(
        mergeMap$(() => {
          const location = getLocation(state);
          const isThemeDark = location.query?.isDark === 'true';
          const uri = isEmptyObject(state.internalRouter.embed)
            ? routes.transferSuccess
            : routes.pinTransferSuccess;

          return [
            cancelTicketTransfer.success(),
            push(`${uri}${isThemeDark ? '?isDark=true' : ''}`),
          ];
        }),
        catchError$((error) => of$(cancelTicketTransfer.failure(error)))
      );
    })
  );
};

export const whenReceiveTicketMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(receiveTicketMounted)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const urlParams = getParams(state) as ITicketsMatchParams;

      return [receiveTicket.request(urlParams.slug)];
    })
  );
};

export const fetchReceiveTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { eventsApi, ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(receiveTicket.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const ticketHash = action.payload;
      const translateDate = translate(state)('dates');

      return from$(ticketsApi.getReceiveTicketsData(ticketHash)).pipe(
        mergeMap$((data: IReceiveTicketsDataResponse) => {
          const ticket: ITicketReceiveData = {
            ...data.ticketData,
            pool: data.pools,
            partner: data.rundate.event.partner,
          };

          const redirectUri = isEmptyObject(state.internalRouter.embed)
            ? routes.myTicket
            : routes.pinMyTicket;
          const lang = translate(state)(
            'ticketsManagement',
            'ticketRecipience'
          );
          const temporaryUnavailableMessage = lang.temporaryUnavailable;
          if (!data.pools.isAvailable) {
            return [
              push(redirectUri),
              createNotification(temporaryUnavailableMessage),
            ];
          }

          return [
            getEventData.success(
              eventsApi.normalizeFullEvent(data.rundate, translateDate)
            ),
            receiveTicket.success(ticket),
            getTransferTicketFee.request(),
          ];
        }),
        catchError$((error: Error) => of$(receiveTicket.failure(error)))
      );
    }),
    catchError$((error: Error) => of$(receiveTicket.failure(error)))
  );
};

export const submitReceiveTicketForm: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(receiveTicketSubmit)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const urlParams = getParams(state) as ITicketsMatchParams;
      const receivedTicket = getReceivedTicketData(state);
      const transferFee = getTicketTransferFee(state);
      const paymentDetails = { type: PaymentMethods.PAYU };
      const thankYouRoute = routes.receiveTicketSuccess.replace(
        ':slug',
        urlParams.slug
      );
      const failRoute = routes.receiveTicketFail.replace(
        ':slug',
        urlParams.slug
      );
      const payUReturnLink = `${config.app.baseUrl}${
        !!getIframeParams(state) ? routes.pinReceiveSummary : thankYouRoute
      }`;
      const payUFailLink = `${config.app.baseUrl}${
        !!getIframeParams(state) ? routes.pinReceiveSummary : failRoute
      }`;
      const acceptedTerms: number[] = [];
      Object.keys(action.payload.customTerms).forEach((term) => {
        if (action.payload.customTerms[term]) {
          acceptedTerms.push(parseInt(term, 10));
        }
      });

      if (!receivedTicket || !transferFee) {
        return EMPTY$;
      }

      const body: ITransactionBody = {
        agent: config.app.salesAgent,
        discountCode: null,
        invoice: null,
        language: state.locale.selectedLang,
        linkFail: payUFailLink,
        linkOk: payUReturnLink,
        paymentDetails,
        paymentOperator: config.buy.defaultOperator,
        products: [
          {
            id: transferFee.id,
            quantity: 1,
          },
        ],
        salesChannelId: config.app.salesChannelId,
        tickets: [
          {
            poolId: receivedTicket.currentPoolId,
            ticketsNum: receivedTicket.ticketNum || 0,
          },
        ],
        transferHash: urlParams.slug,
        user: {
          acceptedTerms,
          email: receivedTicket.targetEmail,
          empikCardNumber: null,
          empikPremiumJWT: null,
          facebookId: null,
          firstName: receivedTicket.targetFirstname,
          lastName: receivedTicket.targetLastname,
          newsletter: action.payload.newsletter,
          terms: action.payload.terms,
        },
      };

      return [sendTransaction.request({ body, onDone: () => null })];
    })
  );
};

export const fetchEventWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { eventsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getEventData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const slugs = action.payload.split('/');
      const translateDate = translate(state)('dates');

      return from$(eventsApi.getSingleEvent(slugs[0], slugs[1])).pipe(
        mergeMap$((data) => {
          return [
            getEventData.success(
              eventsApi.normalizeFullEvent(data, translateDate)
            ),
          ];
        }),
        catchError$((error: Error) => of$(getEventData.failure(error)))
      );
    }),
    catchError$((error: Error) => of$(getEventData.failure(error)))
  );
};

export const whenDayIsSetGetAvailabilities: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf([setTicketDate, changeDateMounted])),
    withLatestFrom$(state$),
    mergeMap$(() => {
      // const happening = get(state); //TODO: get data from api

      // if (!happening) {
      // return EMPTY$;
      // }

      // const meta = getMetadataForLanguage(happening.metadata);

      return of$(
        getAvailabilities.success({
          '09:00:00': [
            {
              available: true,
              capacity: 30,
              capacityLeft: 30,
              configurationId: 795,
              duration: 3600,
              price: 1,
              reserved: false,
              spaceId: 238,
              start: 1587114000,
              upsell: false,
            },
          ],
        })
      );

      // return [
      //   getAvailabilities.request({
      //     date: format(getSelectedDay(state) || new Date(), 'yyyy-MM-dd'),
      //     slug: meta.slug,
      //   }),
      // ];
    })
  );
};

export const returnTicketInit: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(returnTicket)),
    mergeMap$((action) => {
      return of$(returnTicketSubmit.request(action.payload));
    })
  );

export const onReturnTicket: _Store.IEpic = (action$, state$, { ticketsApi }) =>
  action$.pipe(
    filter$(isActionOf(returnTicketSubmit.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(ticketsApi.requestRefundTicket(action.payload)).pipe(
        mergeMap$((data) => {
          const redirectUri = isEmptyObject(state.internalRouter.embed)
            ? routes.myTicket
            : routes.pinMyTicket;

          const payload: IReturnTicketSuccessPayload = {
            success: data.status,
            ticketId: action.payload.ticketId,
          };

          return [returnTicketSubmit.success(payload), push(redirectUri)];
        }),
        catchError$((error) => of$(returnTicketSubmit.failure(error)))
      );
    })
  );

export const displaySuccessNotification: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(returnTicketSubmit.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (action.payload.success) {
        const successMessage = translate(state)(
          'ticketsManagement',
          'myTicket'
        ).returnSuccess;

        return of$(createNotification(successMessage));
      }

      return EMPTY$;
    })
  );

export const displayTransferSuccessNotification: _Store.IEpic = (
  action$,
  state$
) =>
  action$.pipe(
    filter$(isActionOf(transferTicket.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const successMessage = translate(state)(
        'ticketsManagement',
        'myTicket'
      ).transferSuccess;

      return of$(createNotification(successMessage));
    })
  );

export const displayCancelTransferSuccessNotification: _Store.IEpic = (
  action$,
  state$
) =>
  action$.pipe(
    filter$(isActionOf(cancelTicketTransfer.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const successMessage = translate(state)(
        'ticketsManagement',
        'myTicket'
      ).transferCancel;

      return of$(createNotification(successMessage));
    })
  );

export const redirectOnReset: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(resetTickets)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const redirectUri = isEmptyObject(state.internalRouter.embed)
        ? routes.myTicket
        : routes.pinMyTicket;

      return [push(redirectUri)];
    })
  );

export const fetchTransferTicketFee: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) =>
  action$.pipe(
    filter$(isActionOf(getTransferTicketFee.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return from$(ticketsApi.getTransferFee()).pipe(
        mergeMap$((data) => {
          const receivedTicket = getReceivedTicketData(state);
          const ticketsData = getTicketsDataSelector(state);

          if (ticketsData && ticketsData.length) {
            const {
              event: { partnerTransferAmount, partnerTransferPercent },
              pool,
              numberOfTickets,
            } = ticketsData[0];

            if (pool) {
              const summaryPrice = pool.price * numberOfTickets + pool.fee;

              const ticketDataTransferFee =
                partnerTransferAmount ||
                (partnerTransferPercent
                  ? summaryPrice * (partnerTransferPercent / 100)
                  : 0);

              const ticketDataValue = getClosestValue(
                data,
                'price',
                ticketDataTransferFee / 2
              );

              if (summaryPrice < getClosestValue(data, 'price', 0).price) {
                return of$(
                  getTransferTicketFee.success(ticketDataValue),
                  setShowSender(false)
                );
              }

              return of$(
                getTransferTicketFee.success(ticketDataValue),
                setShowSender(true)
              );
            }

            return EMPTY$;
          }

          if (!receivedTicket) {
            return EMPTY$;
          }
          const selectedPool = receivedTicket.pool?.pools.find(
            (pool) => pool.id === receivedTicket.currentPoolId
          );

          if (!selectedPool) {
            return EMPTY$;
          }

          const ticketTransferFee =
            receivedTicket.partner.transferAmount ||
            (receivedTicket.partner.transferPercent
              ? (selectedPool.price * receivedTicket.ticketNum +
                  selectedPool.serviceFee) *
                (receivedTicket.partner.transferPercent / 100)
              : 0);

          const value = getClosestValue(data, 'price', ticketTransferFee / 2);

          return of$(getTransferTicketFee.success(value));
        }),
        catchError$((error) => of$(getTransferTicketFee.failure(error)))
      );
    })
  );

export const requestTicketDownloadFromTicketData: _Store.IEpic = (action$) =>
  action$.pipe(
    filter$(isActionOf(downloadTicketFromTicketData)),
    mergeMap$((action) => {
      return of$(
        downloadTicketFromTicketDataOnRequest.request(action.payload),
        setLoading(CONSTS.MYTICKET)
      );
    })
  );

export const downloadTheTicketFromTicketDataWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) =>
  action$.pipe(
    filter$(isActionOf(downloadTicketFromTicketDataOnRequest.request)),
    mergeMap$((action) => {
      const { code, email, ticketId } = action.payload;

      return from$(
        ticketsApi.downloadTicketFromTicketData(ticketId, code, email)
      ).pipe(
        mergeMap$(() => {
          return of$(
            downloadTicketFromTicketDataOnRequest.success(),
            resetLoading(CONSTS.MYTICKET)
          );
        }),
        catchError$((error) =>
          of$(
            downloadTicketFromTicketDataOnRequest.failure(error),
            resetLoading(CONSTS.MYTICKET)
          )
        )
      );
    })
  );

export const requestTicketDownloadFromTransactionData: _Store.IEpic = (
  action$
) =>
  action$.pipe(
    filter$(isActionOf(downloadTicketFromTransactionData)),
    mergeMap$((action) => {
      return of$(
        downloadTicketFromTransactionDataOnRequest.request(action.payload),
        setLoading(CONSTS.MYTICKET)
      );
    })
  );

export const downloadTheTicketFromTransactionDataWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) =>
  action$.pipe(
    filter$(isActionOf(downloadTicketFromTransactionDataOnRequest.request)),
    mergeMap$((action) => {
      const { ticketId, paymentSum, orderId } = action.payload;

      return from$(
        ticketsApi.downloadTicketFromTransactionData(
          ticketId,
          orderId,
          paymentSum
        )
      ).pipe(
        mergeMap$(() => {
          return of$(
            downloadTicketFromTransactionDataOnRequest.success(),
            resetLoading(CONSTS.MYTICKET)
          );
        }),
        catchError$((error) =>
          of$(
            downloadTicketFromTransactionDataOnRequest.failure(error),
            resetLoading(CONSTS.MYTICKET)
          )
        )
      );
    })
  );
