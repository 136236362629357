import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITransactionReducer, PaymentOperatorEnum } from './../types';
import get from './get';
import getOperator from './getPaymentOperator';

const isFreePaymentAvailable = createSelector<
  _Store.IState,
  ITransactionReducer,
  PaymentOperatorEnum,
  boolean
>(
  [get, getOperator],
  (transaction, operator) =>
    transaction.data !== null && operator === PaymentOperatorEnum.free
);

export default isFreePaymentAvailable;
