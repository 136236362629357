import { getSearch } from 'connected-react-router';
import queryString from 'query-string';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  delay as delay$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import nearestWeekend from '@Misc/helpers/date/nearestWeekend';
import { thisMonth } from '@Misc/helpers/date/thisMonth';
import thisWeek from '@Misc/helpers/date/thisWeek';
import today from '@Misc/helpers/date/today';
import tomorrow from '@Misc/helpers/date/tomorrow';
import { getLocationNameFromId } from '@Misc/helpers/getLocationNameFromId';
import isEmptyObject from '@Misc/helpers/isEmptyObject';
import { locationChange } from '@Model/internalRouter/actions';
import { getModule } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { getLocationsRequest } from '@Model/locations/actions';
import { fetchUrlStructure } from '@Model/pages/actions';
import { PagesEnum } from '@Model/pages/constants/pages';
import {
  baseSearch,
  fetchFacets,
  fetchSearchResults,
  getFacets,
  removeDateFilter,
  resetFilters,
  searchMounted,
  searchStructureMounted,
  setDateFilter,
  setSearchFilter,
  setSearchPhrase,
} from '@Model/search/actions';
import {
  getSearchDates,
  getSearchFilters,
  getSearchOffset,
  getSearchPhrase,
} from '@Model/search/selectors';
import * as MODULES from '@Routes/modules';

import { ISearchFilter, ISearchSuccessPayload } from './../types';

export const getFiltersFromQuery: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf([searchMounted, locationChange])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search = queryString.parse(getSearch(state));

      if (!isEmptyObject(search)) {
        return from$(Object.entries(search)).pipe(
          map$((filterArray) => {
            const dateFilterKey = 'date';
            const locationsKey = 'locations';
            const locationsIdsKey = 'locations_ids';
            const isDateFilter = filterArray[0] === dateFilterKey;
            const isLocationsFilter = filterArray[0] === locationsKey;
            const isLocationsIdFilter = filterArray[0] === locationsIdsKey;

            if (isDateFilter) {
              switch (filterArray[1]) {
                case 'today':
                  return setDateFilter({
                    from: today(),
                    to: today(),
                  });
                case 'tomorrow':
                  return setDateFilter({
                    from: tomorrow(),
                    to: tomorrow(),
                  });
                case 'weekend':
                  return setDateFilter({
                    from: nearestWeekend()[0],
                    to: nearestWeekend()[1],
                  });
                case 'week':
                  return setDateFilter({
                    from: thisWeek()[0],
                    to: thisWeek()[1],
                  });
                case 'month':
                  return setDateFilter({
                    from: thisMonth()[0],
                    to: thisMonth()[1],
                  });
                default:
              }
            }

            if (isLocationsFilter) {
              return setSearchFilter({
                key: 'locations_names',
                value: filterArray[1],
              } as ISearchFilter);
            }

            if (isLocationsIdFilter && typeof filterArray[1] === 'string') {
              return setSearchFilter({
                key: 'locations_names',
                value: getLocationNameFromId(filterArray[1]),
              } as ISearchFilter);
            }

            return setSearchFilter({
              key: filterArray[0],
              value: filterArray[1],
            } as ISearchFilter);
          })
        );
      }

      return EMPTY$;
    })
  );

export const getLocationOnSearchMounted: _Store.IEpic = (action$) =>
  action$.pipe(
    filter$(isActionOf(searchMounted)),
    map$(() => getLocationsRequest.request(PagesEnum.mainpage))
  );

export const getSearchStructure: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(searchStructureMounted)),
    map$(() => fetchUrlStructure())
  );

export const baseSearchInit: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(baseSearch)),
    map$((action) => fetchSearchResults.request(action.payload))
  );

export const getFacetsForAPhrase: _Store.IEpic = (action$) =>
  action$.pipe(
    filter$(isActionOf(getFacets)),
    map$(() => fetchFacets.request())
  );

export const fetchBaseSearchResults: _Store.IEpic = (
  action$,
  state$,
  { awsSearchApi }
) =>
  action$.pipe(
    filter$(isActionOf(fetchSearchResults.request)),
    delay$(config.api.cancellationFixDelay),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const dateTranslate = translate(state)('dates');
      const phrase = getSearchPhrase(state);
      const dates = getSearchDates(state);
      const filters = getSearchFilters(state);
      const offset = action.payload.offset;
      const isFuzzySearch = action.payload.isFuzzySearch;

      return from$(
        awsSearchApi.searchAWS({
          dateTranslate,
          phrase,
          filters,
          dates,
          offset,
          isFuzzySearch,
        })
      ).pipe(
        map$((data: ISearchSuccessPayload) =>
          fetchSearchResults.success({ ...data, offset })
        ),
        catchError$((error: Error) => of$(fetchSearchResults.failure(error))),
        takeUntil$(
          action$.pipe(
            filter$(isActionOf(fetchSearchResults.request)),
            tap$(() => awsSearchApi.cancelSearchAWS())
          )
        )
      );
    })
  );

export const fetchFacetsEpic: _Store.IEpic = (
  action$,
  state$,
  { awsSearchApi }
) =>
  action$.pipe(
    filter$(isActionOf(fetchFacets.request)),
    delay$(config.api.cancellationFixDelay),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const dateTranslate = translate(state)('dates');
      const phrase = getSearchPhrase(state);
      const dates = getSearchDates(state);
      const offset = getSearchOffset(state);

      return from$(
        awsSearchApi.searchAWS({ dateTranslate, phrase, dates, offset })
      ).pipe(
        map$((data: ISearchSuccessPayload) =>
          fetchFacets.success({ facets: data.facets })
        ),
        catchError$((error: Error) => of$(fetchSearchResults.failure(error))),
        takeUntil$(
          action$.pipe(
            filter$(isActionOf(fetchSearchResults.request)),
            tap$(() => awsSearchApi.cancelSearchAWS())
          )
        )
      );
    })
  );

export const resetFiltersOnLocationChange: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(locationChange)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const module = getModule(state);

      if (module !== MODULES.SEARCH)
        return [resetFilters(), removeDateFilter(), setSearchPhrase('')];

      return EMPTY$;
    })
  );
