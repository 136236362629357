export const CAPTURE_EVENT_FROM_IFRAME =
  '@@internalRouter/CAPTURE_EVENT_FROM_IFRAME';
export const EMBED_LOADED = '@@internalRouter/EMBED_LOADED';
export const INIT_EMBED = '@@internalRouter/INIT_EMBED';
export const LOCATION_CHANGE = '@@internalRouter/LOCATION_CHANGE';
export const SET_MODULE = '@@internalRouter/SET_MODULE';
export const REDIRECT_PARENT_TO = '@@internalRouter/REDIRECT_PARENT_TO';

export const _GET_RUNDATE_SLUG_REQUEST =
  '@@internalRouter/_GET_RUNDATE_SLUG_REQUEST';
export const _GET_RUNDATE_SLUG_SUCCESS =
  '@@internalRouter/_GET_RUNDATE_SLUG_SUCCESS';
export const _GET_RUNDATE_SLUG_FAILURE =
  '@@internalRouter/_GET_RUNDATE_SLUG_FAILURE';
