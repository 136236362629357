import calculateDayForSearchFilters from '@Misc/helpers/date/calculateDayForSearchFilters';

const thisWeek = () => {
  const date = new Date();
  const weekdayNumber = date.getDay();
  const currentDay = date.getDate();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const firstWeekDay = new Date(
    currentYear,
    currentMonth,
    currentDay - (weekdayNumber - 1)
  );
  const lastWeekDay = new Date(
    currentYear,
    currentMonth,
    currentDay + (7 - weekdayNumber)
  );

  const firstDay = calculateDayForSearchFilters(firstWeekDay);
  const lastDay = calculateDayForSearchFilters(lastWeekDay);

  return [firstDay, lastDay];
};

export default thisWeek;
