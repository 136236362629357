import React, { MouseEvent } from 'react';

import { Grid, Typography } from '@material-ui/core';
import cn from 'classnames';

import SectionLead from '@Compo/Bricks/components/LeadSection';
import {
  ITestimonialsData,
  ITestimonialsProps,
} from '@Compo/Bricks/components/Testimonials/Testimonials.types';
import Link from '@Compo/reusable/DualLink/DualLink.component';
import slugifyString from '@Misc/helpers/slugifyString';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import Analytics from '@Services/$analytics';

import useStyles from './Testimonials.styles';

const TestimonialItem = ({
  item,
  index,
  compositionIdentifier,
  id,
  color,
  onClick,
  sectionTitle,
}: {
  item: ITestimonialsData;
  index: number;
  compositionIdentifier: string;
  onClick: (
    link?: string,
    textDisplayed?: string,
    componentName?: string
  ) => (e: MouseEvent<HTMLElement>) => void;
  id?: string;
  color?: string;
  sectionTitle?: string;
}) => {
  const { isMobile } = useMuiBreakpoints();
  const muiStyles = useStyles(color)();
  const { src, link, author, authorsJob, description } = item;

  const pill = (
    <img
      src={src}
      className={cn(muiStyles.pill, isMobile && muiStyles.pillMobile)}
    />
  );

  return (
    <Grid
      container={true}
      direction={isMobile ? 'column' : 'row'}
      className={muiStyles.root}
      id={`${id}-${index}`}
    >
      <Grid
        container={true}
        item={true}
        md={4}
        justifyContent={'center'}
        alignItems={'center'}
        direction={isMobile ? 'column' : 'row'}
      >
        {link ? (
          <Link
            to={link}
            className={`${compositionIdentifier}-${id}-${slugifyString(
              author
            )}`}
            onClick={onClick(link, author, sectionTitle)}
          >
            {pill}
          </Link>
        ) : (
          pill
        )}
      </Grid>
      <Grid item={true} container={true} md={8}>
        <Typography
          variant={'body1'}
          children={description}
          className={cn(
            muiStyles.description,
            isMobile && muiStyles.centeredText
          )}
        />
        <Grid item={true} className={cn(isMobile && muiStyles.centeredText)}>
          <Typography variant={'body1'} children={author} />
          <Typography variant={'subtitle1'} children={authorsJob} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const Testimonials = ({
  data,
  extra,
  sectionBgColor,
  id,
  compositionIdentifier,
}: ITestimonialsProps) => {
  const handleAddToDataLayerOnClick =
    (link?: string, textDisplayed?: string, componentName?: string) =>
    (e: MouseEvent<HTMLElement>) =>
      link
        ? Analytics.pushForHomepageSelectItem(
            e,
            link,
            textDisplayed || '',
            componentName
          )
        : undefined;

  return (
    <Grid id={id}>
      <SectionLead
        background={sectionBgColor}
        color={extra?.color}
        description={extra?.sectionDescription}
        title={extra?.sectionTitle}
        showDropdown={false}
      />
      {data.map((item, index) => (
        <TestimonialItem
          key={`${id}-${index}`}
          index={index}
          item={item}
          id={id}
          color={extra?.color}
          compositionIdentifier={compositionIdentifier}
          onClick={handleAddToDataLayerOnClick}
          sectionTitle={extra?.sectionTitle!}
        />
      ))}
    </Grid>
  );
};

export default Testimonials;
