import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { eventDeeplinkWeb } from '@Model/analytics/actions';

import DeepLinkModal from './DeepLinkModal.component';
import {
  IDeepLinkModalFromDispatch,
  IDeepLinkModalOwnProps,
} from './DeepLinkModal.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IDeepLinkModalFromDispatch => ({
  eventDeeplinkWeb: (value) => dispatch(eventDeeplinkWeb(value)),
});

export default connect<
  {},
  IDeepLinkModalFromDispatch,
  IDeepLinkModalOwnProps,
  _Store.IState
>(
  null,
  mapDispatchToProps
)(DeepLinkModal);
