import React from 'react';

import goTrough from '@Misc/helpers/goTrough';

import styles from './TitleLoading.module.scss';

const getWords = (): number[] => goTrough(2 + Math.random() * 3);

const getStyle = (baseWidth: number): object => ({
  width: `${baseWidth + Math.random() * 10}%`,
});

const TitleLoading = () => (
  <div className={styles.titleLoading}>
    {getWords().map((key) => (
      <div key={key} className={styles.fakeWord} style={getStyle(15)} />
    ))}
  </div>
);

export default TitleLoading;
