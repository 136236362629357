import React from 'react';

import cn from 'classnames';

import Link from '@Compo/reusable/ExternalLink';
import translateEngLink from '@Misc/helpers/translateEngLink';

import PremiumBadge from './../PremiumBadge';
import styles from './BuyButton.module.scss';
import { IBuyButtonProps } from './BuyButton.types';

const BuyButton = ({
  analyticData,
  buyButton,
  forcedVertical,
  isEnglish,
  isPremiumEvent,
  label,
  lang,
  link: _link,
  notForSale,
  onClick,
}: IBuyButtonProps) => {
  const sendAnalytic = () => (analyticData ? buyButton(analyticData) : null);
  const buttonText = notForSale ? lang.readMore : lang.buyTicket;
  const link = translateEngLink(_link.toString(), isEnglish);

  return (
    <div
      className={cn(
        styles.buyContainer,
        forcedVertical && styles.withPaddingTop
      )}
      onClick={onClick}
    >
      <Link
        className={cn(styles.buyButton, notForSale && styles.notForSale)}
        to={link}
        onClick={sendAnalytic}
      >
        {label || buttonText}
      </Link>

      {isPremiumEvent && <PremiumBadge />}
    </div>
  );
};

export default BuyButton;
