import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import ArtistTile from '@Compo/reusable/ArtistSlider/components/ArtistTile/ArtistTile.component';
import {
  IArtistTileFromDispatch,
  IArtistTileFromState,
} from '@Compo/reusable/ArtistSlider/components/ArtistTile/ArtistTile.types';
import { getImagesByCloudinaryPrefixAndSlug } from '@Model/images/actions';
import { get } from '@Model/images/selectors';
import { getCompositionIdentifier } from '@Model/pages/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

const mapStateToProps = (state: _Store.IState): IArtistTileFromState => ({
  images: get(state),
  isLoading: getLoading(CONST.SECTIONS)(state),
  compositionIdentifier: getCompositionIdentifier(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IArtistTileFromDispatch => ({
  mounted: (payload) => dispatch(getImagesByCloudinaryPrefixAndSlug(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistTile);
