import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '1.125rem',
      textTransform: 'none',
    },
  })
);

export default useStyles;
