import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  generateIdempotencyKey,
  setCalculatedPrices,
  toggleSelectedUpsell,
  upsellMounted,
} from '@Model/happening/actions';
import {
  getAvailabilities,
  getIsSelectedSlotLastInCurrentDay,
  getSelected,
} from '@Model/happening/selectors';
import { IPriceToCalculate } from '@Model/happening/types';
import { translate } from '@Model/locale/selectors';
import { getTheme } from '@Model/theme/selectors';

import Upsell from './Upsell.component';
import { IUpsellFromDispatch, IUpsellFromState } from './Upsell.types';

const mapStateToProps = (state: _Store.IState): IUpsellFromState => ({
  availabilities: getAvailabilities(state),
  isSelectedSlotLastInCurrentDay: getIsSelectedSlotLastInCurrentDay(state),
  lang: translate(state)('happening', 'upsell'),
  selected: getSelected(state),
  selectedTheme: getTheme(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IUpsellFromDispatch => ({
  calculatePrice: (value: IPriceToCalculate) =>
    dispatch(setCalculatedPrices(value)),
  generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
  mounted: () => dispatch(upsellMounted()),
  toggleUpsellState: (value) => dispatch(toggleSelectedUpsell(value)),
});

export default connect<
  IUpsellFromState,
  IUpsellFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Upsell);
