import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useGlobalStyles from '../../global.styles';
import stepNames from '../../steps';
import Paper from '../Paper';
import useStyles from './SubmissionsList.styles';
import { ISubmissionsListProps } from './SubmissionsList.types';

const SubmissionList = ({
  submissions,
  finalizeTransaction,
  handlePrevStep,
  skipToSelectedStep,
  isViewOnly,
}: ISubmissionsListProps) => {
  const classes = useStyles();
  const globals = useGlobalStyles();
  const { entryList: lang } = useDictionary();

  return (
    <Paper
      title={lang.submissionsSummary.title}
      helperText={lang.submissionsSummary.helper}
    >
      <Grid container={true} justifyContent="center">
        {!!submissions.length &&
          submissions.map((item, idx) => (
            <Grid item={true} key={idx} xs={12}>
              <Typography variant="h5" component="h6">
                {`${item.firstName} ${item.lastName}`}
              </Typography>
            </Grid>
          ))}

        <Grid item={true} xs={12} className={classes.buttons}>
          <Grid
            container={true}
            justifyContent="center"
            className={globals.column}
          >
            <Grid item={true}>
              <Button
                className={globals.button}
                variant="text"
                color="primary"
                onClick={handlePrevStep}
              >
                {lang.submissionsSummary.goBack}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                className={globals.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={() =>
                  isViewOnly
                    ? skipToSelectedStep(stepNames.greeting)
                    : finalizeTransaction()
                }
              >
                {lang.submissionsSummary.confirm}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SubmissionList;
