const fillDataWithEmptyElement = <Type>(elements: number): Type[] => {
  const emptyElement = {} as Type;
  const data: Type[] = [];

  for (let i = 0; i < elements; i++) {
    data.push(emptyElement);
  }

  return data;
};

export default fillDataWithEmptyElement;
