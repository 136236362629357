import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import Link from '@Compo/reusable/DualLink';
import translateEngLink from '@Misc/helpers/translateEngLink';

import styles from './Title.module.scss';
import useStyles from './Title.styles';
import { ITitleProps } from './Title.types';

const Title = ({
  customClassName,
  forcedVertical,
  isEnglish,
  isLoading,
  link: _link,
  title,
  onClick,
}: ITitleProps) => {
  if (isLoading || !title) {
    return <div className={styles.fakeTitle} />;
  }
  const link = translateEngLink(_link || '', isEnglish);
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <h2
      className={cn(
        forcedVertical ? styles.forcedVerticalTitle : styles.title,
        muiStyles.fontColor,
        customClassName
      )}
    >
      {link && (
        <Link
          to={link}
          className={cn(muiStyles.fontColor, customClassName)}
          onClick={onClick}
        >
          {title}
        </Link>
      )}
      {!link && title}
    </h2>
  );
};

export default Title;
