import { connect } from 'react-redux';

import _Store from '@Store';

import compositionPage from '@Model/meta/selectors/compositionPage';
import { IMeta } from '@Model/meta/types';

import Helmet from './../../Helmet';

const mapStateToProps = (state: _Store.IState): IMeta => compositionPage(state);

export default connect<IMeta, void, { noIndex?: boolean }, _Store.IState>(
  mapStateToProps
)(Helmet);
