import React, { FC, useEffect, useRef } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import { getImageUrl } from '@Services/Cloudinary';

import styles from './DesktopViewer.module.scss';
import { IDesktopViewerProps } from './DesktopViewer.types';

const DesktopViewer: FC<IDesktopViewerProps> = ({
  changeImage,
  chosenImage,
  images,
}) => {
  const targetElement = useRef(document.createElement('ul'));
  const theme = useTheme();

  const nextImage = () =>
    chosenImage === images.length - 1
      ? changeImage(0)
      : changeImage(chosenImage + 1);
  const previousImage = () => changeImage(chosenImage - 1);

  const bigImage = images[chosenImage];

  useEffect(() => {
    const selectNode = targetElement.current.childNodes[
      chosenImage > 0 ? chosenImage - 1 : chosenImage
    ] as HTMLLIElement;
    targetElement.current.scrollTo({
      behavior: 'smooth',
      left: selectNode.offsetLeft,
    });
  }, [chosenImage]);

  return (
    <>
      {chosenImage > 0 && (
        <i className={cn(styles.arrow, styles.left)} onClick={previousImage} />
      )}
      <img
        alt={bigImage}
        className={styles.bigImage}
        draggable={'false'}
        src={getImageUrl(bigImage, {
          width: theme.breakpoints.values.lg,
          height: theme.breakpoints.values.lg,
        })}
      />
      <i className={styles.arrow} onClick={nextImage} />
      <ul className={styles.miniatures} ref={targetElement}>
        {images.map((image, index) => {
          const isChosenImage = index === chosenImage;

          return (
            <li className={styles.miniatureContainer} key={index}>
              <img
                alt={image}
                className={cn(styles.miniature, isChosenImage && styles.active)}
                draggable={'false'}
                onClick={() => changeImage(index)}
                src={getImageUrl(bigImage, {
                  width: 450,
                  height: 450,
                })}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default DesktopViewer;
