import { Selector } from 'reselect';

import _Store from '@Store';

import { IPoolsAllReducer } from './../types';

const getAll: Selector<_Store.IState, IPoolsAllReducer> = (state) =>
  state.pools.all;

export default getAll;
