import {
  ISlidableCardsOwnProps,
  ISlidableExtraData,
} from '@Compo/Bricks/components/SlidableCards/SlidableCards.types';
import { ISelectedLocation } from '@Model/locations/types';

import {
  IComponentExtraProps,
  ISectionsComponentDataMutable,
} from './../types/sections';

export const slidable_hotspots = (
  data: ISectionsComponentDataMutable,
  extra?: ISlidableExtraData,
  background?: string,
  id?: string,
  userLocation?: ISelectedLocation
): ISlidableCardsOwnProps & ISlidableExtraData => {
  const extraFlags: IComponentExtraProps = {
    disableDate: extra?.disableDate,
    disableDescription: extra?.disableDescription,
    disablePlace: extra?.disablePlace,
    disableTitle: extra?.disableTitle,
  };

  return {
    background,
    data,
    color: extra?.color,
    hotDeals: extra?.hotDeals,
    moreButton: extra?.moreButton,
    moreButtonText: extra?.moreButtonText,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
    verticalImage: extra?.verticalImage,
    extraFlags,
    userLocation,
  };
};
