import axios from 'axios';
import FileDownload from 'js-file-download';

import config from '@Config';
import TransactionError from '@Misc/classes/TransactionError';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import { IEventFull } from '@Model/event/types';
import { ILang } from '@Model/locale/types';
import routes from '@Routes/routes';
import { EventsApi } from '@Services/$events-api/EventsApi';

import getTransferError from './helpers/getTransferError';
import {
  ICancelTicketTransferBody,
  IEventResponse,
  IProductResponse,
  IReceiveTicketsDataResponse,
  IRequestRefundTicketBody,
  IReturnTicketBody,
  IReturnTicketResponse,
  ITicketsDataBody,
  ITicketsDataResponse,
  ITransactionDataBody,
  ITransferTicketBody,
  ITransferTicketResponse,
} from './types';

const CANNOT_MANAGE_ERROR = 'Niestety nie możesz zarządzać tym biletem.';
const CONNECTION_GETTING_ERROR =
  'Nie udało się pobrać danych. Spróbuj ponownie później.';
const CONNECTION_SENDING_ERROR =
  'Nie udało się przesłać danych. Spróbuj ponownie później.';
const NO_TICKET_DATA_ERROR =
  'Nie możemy znaleźć tego biletu. Sprawdź, czy wprowadzone dane są poprawne.';

export class TicketsApi {
  private static transferUri: string = `${config.api.baseUrl}transfer-ticket`;

  private static uri = (code: string, mail: string): string =>
    `${config.api.baseUrl}ticket/${code.trim()}/${mail.trim()}`;
  private static uriFromTransaction = (orderId: string, paymentSum: string) =>
    `${
      config.api.baseUrl
    }ticket/by_order/${orderId.trim()}/${encodeURIComponent(
      paymentSum.trim()
    )}`;
  private static receiveTicketUri = (hash: string): string =>
    `${config.api.baseUrl}transfer-ticket/${hash}/form-data`;
  private static returnTicketUri = (id: string, code: string, email: string) =>
    `${config.api.baseUrl}ticket/refund/${id}/${code}/${email}`;
  private static requestRefundUri = (): string =>
    `${config.api.baseUrl}ticket/request-refund`;
  private static getTransferFeeUri = () => `${config.api.baseUrl}products`;
  private static ticketDownloadUriFromTicketData = (
    ticketId: string,
    code: string,
    email: string
  ) => `${config.api.api3Url}/ticket/${ticketId}/pdf/code/${code}/${email}`;
  private static ticketDownloadUriFromTransactionData = (
    ticketId: string,
    orderId: string,
    paymentSum: string
  ) =>
    `${config.api.api3Url}/ticket/${ticketId}/pdf/transaction/${orderId}/${paymentSum}`;

  public getReceiveTicketsData(
    ticketHash: string
  ): Promise<IReceiveTicketsDataResponse> {
    return new Promise((resolve, reject) => {
      axios
        .get(TicketsApi.receiveTicketUri(ticketHash))
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }

  public getTicketsData(
    body: ITicketsDataBody,
    lang: ILang
  ): Promise<ITicketsDataResponse> {
    return new Promise((resolve, reject) => {
      axios
        .get(TicketsApi.uri(body.code, body.email))
        .then(getData)
        .then((response: ITicketsDataResponse) => {
          const available: ITicketsDataResponse = [];

          if (response.length === 0) {
            reject(
              new TransactionError(
                lang.errorMessage,
                undefined,
                undefined,
                undefined,
                lang.errorTitle,
                lang.errorMessageLinkLabel,
                routes.contact
              )
            );
          }

          response.map((ticket) => {
            if (ticket.isAvailable) {
              available.push(ticket);
            }
          });

          if (available.length > 0) {
            return resolve(available);
          } else {
            reject(new TransactionError(CANNOT_MANAGE_ERROR));
          }
        })
        .catch((error: Error) => {
          reject(new TransactionError(CONNECTION_GETTING_ERROR));
        });
    });
  }

  public getTicketsDataFromTransaction(
    body: ITransactionDataBody
  ): Promise<ITicketsDataResponse> {
    return new Promise((resolve, reject) => {
      axios
        .get(TicketsApi.uriFromTransaction(body.orderId, body.paymentSum))
        .then(getData)
        .then((response: ITicketsDataResponse) => {
          const available: ITicketsDataResponse = [];

          if (response.length === 0) {
            reject(new TransactionError(NO_TICKET_DATA_ERROR));
          }

          response.map((ticket) => {
            if (ticket.isAvailable) {
              available.push(ticket);
            }
          });

          if (available.length > 0) {
            return resolve(available);
          } else {
            reject(new TransactionError(CANNOT_MANAGE_ERROR));
          }
        })
        .catch((error: Error) => {
          reject(new TransactionError(CONNECTION_GETTING_ERROR));
        });
    });
  }

  public downloadTicketFromTicketData(
    ticketId: string,
    code: string,
    email: string
  ) {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(
          TicketsApi.ticketDownloadUriFromTicketData(ticketId, code, email),
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          FileDownload(response.data, 'bilet.pdf');
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public downloadTicketFromTransactionData(
    ticketId: string,
    orderId: string,
    paymentSum: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          TicketsApi.ticketDownloadUriFromTransactionData(
            ticketId,
            orderId,
            paymentSum
          ),
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          FileDownload(response.data, 'bilet.pdf');
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public transferTicket(body: ITransferTicketBody): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          TicketsApi.transferUri,
          JSON.stringify({ ...body }),
          withCacheHeader()
        )
        .then(getData)
        .then((response: ITransferTicketResponse) => {
          if (response.status) {
            resolve();
          } else {
            reject(getTransferError(response));
          }
        })
        .catch((error: Error) => {
          reject(new TransactionError(CONNECTION_SENDING_ERROR));
        });
    });
  }

  public cancelTicketTransfer(body: ICancelTicketTransferBody): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(TicketsApi.transferUri, { data: { ...body } })
        .then(getData)
        .then((response: ITransferTicketResponse) => {
          if (response.status) {
            resolve();
          } else {
            reject(getTransferError(response));
          }
        })
        .catch((error: Error) => {
          reject(new TransactionError(CONNECTION_SENDING_ERROR));
        });
    });
  }

  public returnTicket(body: IReturnTicketBody): Promise<IReturnTicketResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post(TicketsApi.returnTicketUri(body.ticketId, body.code, body.email))
        .then(getData)
        .then((response) => {
          if (response.isSuccess === true) {
            resolve(response);
          } else {
            reject(new TransactionError(CONNECTION_SENDING_ERROR));
          }
        })
        .catch((error: Error) => {
          reject(new TransactionError(CONNECTION_SENDING_ERROR));
        });
    });
  }

  public requestRefundTicket(
    body: IRequestRefundTicketBody
  ): Promise<IReturnTicketResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post(TicketsApi.requestRefundUri(), {
          token: body.token,
          refundType: body.refundType,
        })
        .then(getData)
        .then((response) => {
          if (response.status === true) {
            resolve(response);
          } else {
            reject(new TransactionError(CONNECTION_SENDING_ERROR));
          }
        })
        .catch((error: Error) => {
          reject(new TransactionError(CONNECTION_SENDING_ERROR));
        });
    });
  }

  public getTransferFee(): Promise<IProductResponse[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(TicketsApi.getTransferFeeUri(), { params: { type: 'transfer' } })
        .then(getData)
        .then(getData)
        .then((response: IProductResponse[]) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(catchHttpError(error));
        });
    });
  }

  public normalizeFullEvent(
    eventResponse?: IEventResponse
  ): IEventFull | undefined {
    if (eventResponse) {
      const {
        titlePL,
        teaser,
        tags,
        rundatesCount,
        externalImages,
        formattedPartnerDescription,
        calendarEvent,
        formattedDescription,
        id,
        slug,
        images,
        partner,
        place,
        category,
        currency,
      } = eventResponse;

      return {
        additionalDescription: formattedPartnerDescription
          ? formattedPartnerDescription
          : '',
        artists: [],
        buttonLabel: null,
        calendarEvent,
        courierDelivery: false,
        customTerms: [],
        description: formattedDescription ? formattedDescription : '',
        eventId: id,
        eventSlug: slug,
        externalImages,
        forFansOfArtists: [],
        freeOfCharge: false,
        friendlyDate: '',
        friendlyHour: '',
        gtmId: partner.gtmId,
        hasManyPools: false,
        imagesUrl: EventsApi.getImageUrl(images),
        isAvailable: true,
        notForSale: false,
        partnerId: partner.id,
        partnerName: partner.name,
        passed: false,
        placeAddress: place.address,
        placeCategory: EventsApi.normalizeEventPlaceTags(place.category),
        placeCityName: place.city.name,
        placeCitySlug: place.city.slug ? place.city.slug : '',
        placeDescription: place.description,
        placeFriendly: EventsApi.getFriendlyPlace(eventResponse.place),
        placeId: place.id,
        placeImageUrl: place.thumb,
        placeLat: place.lat,
        placeLon: place.lon,
        placeName: place.name,
        placeSlug: place.slug,
        price: null,
        rundate: '',
        rundateId: 0,
        rundateSlug: '',
        rundatesCount,
        startDate: '',
        tags: EventsApi.prepareTagsFromRundate(category, tags),
        teaser,
        title: titlePL,
        videoId: null,
        currency,
        externalImageId: null,
      };
    }
  }
}

export default new TicketsApi();
