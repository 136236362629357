export const CHECK_EMAIL = 'Empik/auth/CHECK_EMAIL';
export const TOGGLE_LOGIN = 'Empik/auth/TOGGLE_LOGIN';

// Private
export const _CHECK_EMAIL_REQUEST = 'Empik/auth/_CHECK_EMAIL_REQUEST';
export const _CHECK_EMAIL_SUCCESS = 'Empik/auth/_CHECK_EMAIL_SUCCESS';
export const _CHECK_EMAIL_FAILURE = 'Empik/auth/_CHECK_EMAIL_FAILURE';

export const _GENERATE_TOKEN_REQUEST = 'Empik/auth/_GENERATE_TOKEN_REQUEST';
export const _GENERATE_TOKEN_SUCCESS = 'Empik/auth/_GENERATE_TOKEN_SUCCESS';
export const _GENERATE_TOKEN_FAILURE = 'Empik/auth/_GENERATE_TOKEN_FAILURE';
