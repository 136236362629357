import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getPlaceEvents } from '@Model/placeEvents/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONST from './../constants/constants';

export const setLoaderWhenPlaceEventsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(getPlaceEvents.request)),
    map$(() => setLoading(CONST.PLACE_EVENTS))
  );
};

export const setLoaderWhenPlaceEventsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getPlaceEvents.success, getPlaceEvents.failure], action)
    ),
    map$(() => resetLoading(CONST.PLACE_EVENTS))
  );
};
