import { createSelector } from 'reselect';

import _Store from '@Store';

import { IArticleReducer, IArticleReducerRequestTimes } from './../types';
import get from './get';

const getRequestTimes = createSelector<
  _Store.IState,
  IArticleReducer,
  IArticleReducerRequestTimes
>([get], (article) => article.requestTimes);

export default getRequestTimes;
