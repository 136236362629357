import React, { useState } from 'react';

import { Form, Formik } from 'formik';

import FormField from '@Compo/forms/FormField';
import ActionMessage from '@Compo/modals/ActionMessage';

import styles from './GiftCard.module.scss';
import { IGiftCardProps } from './GiftCard.types';

const ACTION_MESSAGE_TEXT = 'Czy na pewno chcesz aktywować kod?';
const PAGE_TITLE_TEXT = 'Aktywuj kartę prezentową';
const FIELD_DESCRIPTION_TEXT =
  'Wpisz numer karty prezentowej, którą chcesz aktywować';
const PLACEHOLDER_TEXT = 'Nr karty prezentowej';
const TIP_TEXT = 'Numer karty znajdziesz na odwrocie, obok kodu paskowego';
const BUTTON_TEXT = 'Aktywuj';

const GiftCard = ({ activateCard }: IGiftCardProps) => {
  const [modal, toggleModal] = useState(false);

  const initialValues = {
    id: '',
  };

  const openActionMessage = () => toggleModal(true);
  const closeActionMessage = () => toggleModal(false);

  return (
    <div className={styles.formWrapper}>
      <h2 className={styles.pageTitle}>{PAGE_TITLE_TEXT}</h2>
      <div className={styles.fieldDescription}>{FIELD_DESCRIPTION_TEXT}</div>

      <Formik initialValues={initialValues} onSubmit={openActionMessage}>
        {(props) => {
          const action = () => {
            activateCard(props.values.id);
            closeActionMessage();
            props.resetForm();
          };

          return (
            <Form>
              <label className={styles.label} htmlFor="code">
                <FormField
                  id="id"
                  name="id"
                  placeholder={PLACEHOLDER_TEXT}
                  type="number"
                />
              </label>
              <p className={styles.tip}>{TIP_TEXT}</p>
              <button
                className={styles.submitButton}
                type="submit"
                disabled={false}
              >
                {BUTTON_TEXT}
              </button>
              <ActionMessage
                action={action}
                close={closeActionMessage}
                show={modal}
                message={ACTION_MESSAGE_TEXT}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default GiftCard;
