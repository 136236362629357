import TicketTransferError from '@Misc/classes/TicketTransferError';

import { ITransferTicketResponse } from '../types';
import errorsDefinition from './errorsDefinition';

const getTransferError = (
  errors: ITransferTicketResponse
): ITransferTicketResponse => {
  const responseErrors = errorsDefinition.filter(
    (error) => error.errorCode === errors.errorCode
  );
  const errorToShow = responseErrors.shift();

  if (errorToShow) {
    if (
      errorToShow.errorCode === 'internal_error' &&
      errorToShow.errorMessage
    ) {
      return new TicketTransferError(
        errorToShow.status,
        errorToShow.errorCode,
        errors.errorMessage
      );
    }

    return new TicketTransferError(
      errorToShow.status,
      errorToShow.errorCode,
      errorToShow.errorMessage
    );
  }

  return new TicketTransferError(false);
};

export default getTransferError;
