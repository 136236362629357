import { createSelector } from 'reselect';

import getSelected from './getSelected';

const isAllSelected = createSelector(
  [getSelected],
  (selected): boolean => !!(selected.day && selected.slot && selected.space > 0)
);

export default isAllSelected;
