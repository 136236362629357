import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { checkUserCardStatus } from '@Model/premium/actions';
import { getUserCardStatus } from '@Model/premium/selectors';

import EmpikCardNumber from './EmpikCardNumber.component';
import {
  IEmpikCardNumberFromDispatch,
  IEmpikCardNumberFromState,
  IEmpikCardNumberOwnProps,
} from './EmpikCardNumber.types';

const mapStateToProps = (state: _Store.IState): IEmpikCardNumberFromState => ({
  errors: translate(state)('errors'),
  lang: translate(state)('buy', 'empikCardNumber'),
  userCardStatus: getUserCardStatus(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IEmpikCardNumberFromDispatch => {
  return {
    checkUserCardStatus: (cardNumber, email) =>
      dispatch(checkUserCardStatus({ cardNumber, email })),
  };
};

export default connect<
  IEmpikCardNumberFromState,
  IEmpikCardNumberFromDispatch,
  IEmpikCardNumberOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(EmpikCardNumber);
