import React, { useEffect, useState } from 'react';

import { Grid, Link, Typography } from '@material-ui/core';
import cn from 'classnames';

import { IArtistCardProps } from '@Compo/reusable/ArtistCard/ArtistCard.types';
import ImageFixed from '@Compo/reusable/ImageFixed';
import config from '@Config';
import getUrlWithTransformation from '@Misc/helpers/cloudinary/getUrlWithTransformation';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import routes from '@Routes/routes';

import useStyles from './ArtistCard.styles';

const ArtistCard = ({
  artist,
  images,
  mounted,
  reversed,
}: IArtistCardProps) => {
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const { isMobile } = useMuiBreakpoints();
  const muiStyles = useStyles();
  const artistLink = fillUrlWithValues(routes.artist, ':slug', artist.slug);
  const trimFactor = 24;
  const trimmedName =
    artist.name.length > trimFactor
      ? `${artist.name.slice(0, trimFactor)}...`
      : artist.name;

  useEffect(() => {
    mounted({
      prefix: config.images.artistPrefix,
      slug: artist.slug,
    });
  }, []);

  useEffect(() => {
    const key = `${config.images.artistPrefix}${artist.slug}`;

    if (!imgUrl && images[key] && images[key].length) {
      setImgUrl(images[key][0]);
    }
  }, [images]);

  return (
    <Grid container={true} item={true} xs={isMobile ? 12 : 6}>
      <Link
        className={cn(muiStyles.root, reversed && muiStyles.reversed)}
        href={artistLink}
      >
        <Grid item={true} container={true} xs={6}>
          <ImageFixed
            src={imgUrl}
            transformation={{ width: 300, height: 260 }}
          />
        </Grid>
        <Grid
          item={true}
          container={true}
          alignItems={'flex-end'}
          xs={6}
          className={muiStyles.title}
        >
          <Typography
            variant={isMobile ? 'body1' : 'h5'}
            children={trimmedName}
            className={muiStyles.titleText}
          />
        </Grid>
      </Link>
    </Grid>
  );
};

export default ArtistCard;
