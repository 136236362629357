import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import { IArtistFull, IArtistNormalized, IPastEvents } from './../types';

export const artistPageMounted = createStandardAction(
  CONSTS.ARTICLE_PAGE_MOUNTED
)();
export const resetState = createStandardAction(CONSTS._RESET_STATE)();

export const getArtist = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IArtistFull, Error>();

export const getClosestPool = createAsyncAction(
  CONSTS._GET_CLOSEST_POOL_REQUEST,
  CONSTS._GET_CLOSEST_POOL_SUCCESS,
  CONSTS._GET_CLOSEST_POOL_FAILURE
)<undefined, IArtistNormalized, Error>();

export const getPastEvents = createAsyncAction(
  CONSTS._GET_PAST_EVENTS_REQUEST,
  CONSTS._GET_PAST_EVENTS_SUCCESS,
  CONSTS._GET_PAST_EVENTS_FAILURE
)<undefined, IPastEvents[], Error>();
