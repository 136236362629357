import { defaultMemoize } from 'reselect';
import { object, string } from 'yup';

import { ILang } from '@Model/locale/types';

const passwordResetValidationToMemoize = (lang: ILang) => {
  const validators = {
    email: string().required(lang.requiredField).email(lang.incorrectEmail),
    languageId: string(),
    password: string().required(lang.requiredField),
    passwordConfirmation: string().required(lang.requiredField),
    token: string(),
  };

  return object().shape(validators);
};

export const makeValidationSchema = (lang: ILang) => {
  const validationSchema = () => passwordResetValidationToMemoize(lang);

  return defaultMemoize(validationSchema);
};
