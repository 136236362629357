import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { mounted } from '@Model/payment/actions';

import Payment from './Payment.component';
import { IPaymentFromDispatch } from './Payment.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPaymentFromDispatch => {
  return {
    mounted: () => dispatch(mounted()),
  };
};

export default connect<void, IPaymentFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(Payment);
