import React from 'react';

import Timeline from '@Compo/reusable/Timeline';
import invertIfGrayScaleColor from '@Misc/helpers/invertIfGrayScaleColor';
import isColorDark from '@Misc/helpers/isColorDark';

import styles from './TimelineBrick.module.scss';
import { ITimelineBrickProps } from './TimelineBrick.types';

const TimelineBrick = ({
  background,
  color,
  id,
  isDarkMode,
  isLoading,
  timeline,
  compositionIdentifier,
}: ITimelineBrickProps) => (
  <div className={styles.timeline} id={id}>
    <Timeline
      isDark={
        isColorDark(background) ||
        isColorDark(
          isDarkMode && background
            ? invertIfGrayScaleColor(background)
            : background
        )
      }
      color={color}
      timeline={timeline}
      isLoading={isLoading}
      compositionIdentifier={compositionIdentifier}
      brickId={id}
    />
  </div>
);

export default TimelineBrick;
