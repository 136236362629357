import React, { FC, MouseEvent } from 'react';

import SectionLead from '@Compo/Bricks/components/LeadSection';
import ArrowSlider from '@Compo/reusable/ArrowSlider';
import Card from '@Compo/reusable/Card';
import { BricksEnum } from '@Model/pages/constants/bricks';
import { useDynamicListings } from '@Model/pages/hooks/useDynamicListings';
import { ICloudsearchCardData } from '@Model/pages/types';
import { ISectionsComponentExtendedHotSpot } from '@Model/pages/types/sections';
import Analytics from '@Services/$analytics';
import sectionsApi from '@Services/$sections-api';

import { ISlidableCardsProps } from './SlidableCards.types';

const MORE_BUTTON_DEFAULT_TEXT = 'Zobacz więcej';

const SlidableCards: FC<ISlidableCardsProps> = ({
  background,
  data,
  color,
  hotDeals,
  id,
  moreButton,
  moreButtonText = MORE_BUTTON_DEFAULT_TEXT,
  sectionDescription,
  sectionTitle,
  verticalImage,
  isLoading,
  compositionIdentifier,
  userLocation,
  extraFlags,
}) => {
  const cardData =
    !!data?.length && 'cloudsearch' in data[0]
      ? undefined
      : (data as ISectionsComponentExtendedHotSpot[]);
  const cloudsearchCardData =
    !!data?.length && 'cloudsearch' in data[0]
      ? (data as ICloudsearchCardData[])
      : undefined;

  const { events } = useDynamicListings({
    cloudsearchQuery:
      cloudsearchCardData && cloudsearchCardData[0].cloudsearchQuery,
    userLocation,
    componentId: id,
  });

  const cards =
    events && !!data?.length && 'cloudsearch' in data[0]
      ? sectionsApi.normalizeCloudsearchResponseToCardData(events, extraFlags)
      : cardData
      ? sectionsApi.normalizeElementsToCardData(cardData, extraFlags)
      : [];

  const handleAddToDataLayerOnClick =
    (link?: string, textDisplayed?: string, componentName?: string) =>
    (e: MouseEvent<HTMLElement>) =>
      link
        ? Analytics.pushForHomepageSelectItem(
            e,
            link,
            textDisplayed || '',
            componentName
          )
        : undefined;

  const cardDeck = cards.map((card, index) => (
    <Card
      sectionBackground={background}
      {...card}
      color={color}
      children={moreButton && <span>{moreButtonText}</span>}
      highlightTitle={false}
      hotDeals={hotDeals}
      isLoading={isLoading}
      isSlider={true}
      maxInRow={4}
      verticalImage={verticalImage}
      key={index.toString()}
      compositionIdentifier={compositionIdentifier}
      type={BricksEnum.slidable_hotspots}
      brickId={id}
      onClick={handleAddToDataLayerOnClick(
        card.link,
        card.title!,
        sectionTitle!
      )}
    />
  ));

  return (
    <>
      <SectionLead
        background={background}
        description={sectionDescription}
        medium={true}
        showDropdown={false}
        title={sectionTitle}
      />
      {!!cardDeck.length && (
        <ArrowSlider
          children={cardDeck}
          length={cardDeck.length}
          id={id}
          verticalImage={verticalImage}
        />
      )}
    </>
  );
};

export default SlidableCards;
