import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      marginTop: '2rem',
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '1rem',
      padding: '2rem',
    },
  })
);

export default useStyles;
