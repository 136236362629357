import React, { FC } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import linkToFileInStorage from '@Misc/helpers/linkToFileInStorage';
import _L from '@Misc/helpers/local';
import * as LOCALS from '@Vars/constants';

import styles from './Term.module.scss';
import useStyles from './Term.styles';
import { ITermProps } from './Term.types';

const Terms: FC<ITermProps> = ({ lang }) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <span>
      {lang.iAccept}{' '}
      <a
        className={cn(styles.termLink, muiStyles.root)}
        href={linkToFileInStorage(_L(LOCALS.STORAGE__TERMS), true)}
        target="_blank"
      >
        {lang.regulations}
      </a>{' '}
      {lang.and}{' '}
      <a
        className={cn(styles.termLink, muiStyles.root)}
        href={linkToFileInStorage(_L(LOCALS.STORAGE__PRIVACY_POLICY), true)}
        target="_blank"
      >
        {lang.privacyPolicy}
      </a>
      *
    </span>
  );
};

export default Terms;
