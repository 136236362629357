import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import Price from './Price.component';
import { IPriceFromState, IPriceOwnProps } from './Price.types';

const mapStateToProps = (state: _Store.IState): IPriceFromState => ({
  lang: translate(state)('event'),
});

export default connect<IPriceFromState, void, IPriceOwnProps, _Store.IState>(
  mapStateToProps
)(Price);
