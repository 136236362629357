import React, { useEffect, useState } from 'react';

import loadDOMStyle from '@Misc/helpers/loadDOMStyle';
import HtmlApi from '@Services/$html-api';

import styles from './CustomCode.module.scss';
import { ICustomCodeProps } from './CustomCode.types';

const CustomCode = ({ cssSrc, htmlSrc, id, isLoading }: ICustomCodeProps) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    if (cssSrc) {
      loadDOMStyle(cssSrc);
    }

    HtmlApi.getHtml(htmlSrc).then((data) => {
      setHtml(data.body.innerHTML);
    });
  }, [htmlSrc]);

  if (!html) {
    return null;
  }

  return (
    <div
      className={styles.customCode}
      id={id}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default CustomCode;
