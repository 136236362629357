import React from 'react';

import EmbedFrame from '@Compo/reusable/EmbedFrame';

import styles from './EmbedBrick.module.scss';
import { IEmbedBrickProps } from './EmbedBrick.types';

const EmbedBrick = ({ embed, id, isLoading }: IEmbedBrickProps) => (
  <div className={styles.embed} id={id}>
    <EmbedFrame embedSrc={embed.embedSrc} title={embed.title} />
  </div>
);

export default EmbedBrick;
