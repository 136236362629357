import { createSelector } from 'reselect';

import { IBasketTicket } from '@Model/basket/types';

import get from './get';

const getBasketTickets = createSelector(
  [get],
  (basketState): IBasketTicket[] => {
    return basketState.map((basketItem) => ({
      amount: basketItem.amount,
      price: basketItem.price / basketItem.amount,
      poolId: basketItem.id,
    }));
  }
);

export default getBasketTickets;
