import { combineReducers } from 'redux';

import availabilitiesReducer from './availabilities';
import dateReducer from './date';
import eventReducer from './event';
import receivingTicketsReducer from './receivingTickets';
import seatsReducer from './seats';
import ticketTransferFeeReducer from './ticketTransferFee';
import ticketsReducer from './tickets';

const reducer = combineReducers({
  availabilities: availabilitiesReducer,
  date: dateReducer,
  eventData: eventReducer,
  receivingTicket: receivingTicketsReducer,
  seats: seatsReducer,
  ticketTransferFee: ticketTransferFeeReducer,
  ticketsData: ticketsReducer,
});

export default reducer;
