import { getType } from 'typesafe-actions';

import { getPlace, setCurrentKey } from './../actions';
import { IAction, IPlaceReducer } from './../types';

const initialState: IPlaceReducer = {
  currentKey: null,
  data: {},
  requestTimes: {},
};

const reducer = (
  state: IPlaceReducer = initialState,
  action: IAction
): IPlaceReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getPlace.success):
      const { place, key } = action.payload;

      return {
        ...state,
        currentKey: key,
        data: {
          ...state.data,
          [key]: place,
        },
        requestTimes: {
          ...state.requestTimes,
          [key]: Date.now(),
        },
      };

    case getType(setCurrentKey):
      return {
        ...state,
        currentKey: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
