import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioButton: {
      border: `0.0625rem solid ${theme.palette.secondary.main}`,
      borderRadius: '0.5rem',
      width: '100%',
    },
  })
);

export default useStyles;
