import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import Link from '@Compo/reusable/DualLink';
import styles from '@Compo/ticketsManagement/MyTicket/MyTicket.module.scss';
import useStyles from '@Compo/ticketsManagement/MyTicket/styles/MyTicket.styles';

import { IOptionBoxProps } from './OptionBox.types';

const OptionBox = ({
  action,
  buttonText,
  link,
  text,
  isDisabled,
  errors,
  icon,
}: IOptionBoxProps) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <Grid
      container={true}
      alignItems={'center'}
      className={cn(
        styles.optionWrapper,
        muiStyles.root,
        isDisabled && muiStyles.disabled
      )}
      spacing={2}
    >
      <Grid item={true}>
        <Grid container={true} spacing={3}>
          <Grid item={true}>{icon}</Grid>
          <Grid item={true}>
            <Typography variant={'body1'}>{text}</Typography>
            {errors.map((error) => (
              <Typography variant={'caption'}>{error}</Typography>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} sm={'auto'}>
        {link && (
          <>
            {!isDisabled ? (
              <Link to={link}>
                <Button
                  variant={'contained'}
                  size={'medium'}
                  color={'primary'}
                  onClick={action}
                  className={cn(muiStyles.button, muiStyles.buttonText)}
                >
                  {buttonText}
                </Button>
              </Link>
            ) : (
              <Button
                variant={'contained'}
                size={'medium'}
                color={'primary'}
                onClick={action}
                className={cn(muiStyles.button, muiStyles.buttonText)}
                disabled={isDisabled}
              >
                {buttonText}
              </Button>
            )}
          </>
        )}
        {action && (
          <Button
            variant={'contained'}
            size={'medium'}
            color={'primary'}
            onClick={action}
            className={cn(muiStyles.button, muiStyles.buttonText)}
            disabled={isDisabled}
          >
            {buttonText}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default OptionBox;
