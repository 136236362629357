import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { changeLocation } from '@Model/locations/actions';
import { getLocations, getSelectedLocation } from '@Model/locations/selectors';

import CitiesDropdown from './CitiesDropdown.component';
import {
  ICitiesDropdownFromDispatch,
  ICitiesDropdownFromState,
  ICitiesDropdownOwnProps,
} from './CitiesDropdown.types';

const mapStateToProps = (state: _Store.IState): ICitiesDropdownFromState => ({
  locations: getLocations(state),
  selectedLocation: getSelectedLocation(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ICitiesDropdownFromDispatch => ({
  changeHomeLocation: (slug: string) => dispatch(changeLocation(slug, null)),
});

export default connect<
  ICitiesDropdownFromState,
  ICitiesDropdownFromDispatch,
  ICitiesDropdownOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(CitiesDropdown);
