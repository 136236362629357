export enum FavoritesTypes {
  Artists = 'artist',
  Places = 'place',
  Rundates = 'rundate',
  Selection = 'selection',
  Tags = 'tag',
}

export interface IFavorite {
  type: FavoritesTypes;
  name: string;
  imagePath: string | null;
  slug: string;
  externalId: number;
  extraInfo?: string;
}

export interface IFavoritePayload {
  externalId: number;
  type: FavoritesTypes;
  email: string;
}
