import { createSelector, defaultMemoize } from 'reselect';
import { lazy, object, string } from 'yup';

import _Store from '@Store';

import {
  IHappeningFormValidators,
  IHappeningFormValues,
} from '@Compo/Happening/components/HappeningBuyForm/HappeningBuyForm.types';
import { IBuyingOnlineCustomTermsValidators } from '@Compo/buying/Online/Online.types';
import * as CONSTS from '@Compo/buying/constants/constants';
import {
  customTermValidator,
  discountCodeValidator,
  invoiceNipValidator,
  invoicePostCodeValidator,
  invoiceRequiredFieldValidator,
  phoneNumberValidator,
  termValidator,
} from '@Compo/buying/constants/validators';
import { IEventTerm } from '@Model/event/types';
import { getHappeningTerms } from '@Model/happening/selectors';
import { ILang } from '@Model/locale/types';
import { getProducts } from '@Model/products/selectors';
import { IProduct } from '@Model/products/types';
import { PaymentMethods } from '@Model/transaction/constants/paymentMethods';

export const getInitialValues = createSelector<
  _Store.IState,
  IEventTerm[],
  IProduct[],
  IHappeningFormValues
>([getHappeningTerms, getProducts], (terms, products) => {
  const initialValues: IHappeningFormValues = {
    customTerms: {},
    discount: CONSTS.DEFAULT_EMPTY_TEXT,
    discountCheckbox: false,
    email: CONSTS.DEFAULT_EMPTY_TEXT,
    firstname: CONSTS.DEFAULT_EMPTY_TEXT,
    invoiceAddress: CONSTS.DEFAULT_EMPTY_TEXT,
    invoiceCheckbox: false,
    invoiceCity: CONSTS.DEFAULT_EMPTY_TEXT,
    invoiceName: CONSTS.DEFAULT_EMPTY_TEXT,
    invoiceNip: CONSTS.DEFAULT_EMPTY_TEXT,
    invoicePost: CONSTS.DEFAULT_EMPTY_TEXT,
    lastname: CONSTS.DEFAULT_EMPTY_TEXT,
    newsletter: false,
    pass: CONSTS.DEFAULT_EMPTY_TEXT,
    passCheckbox: false,
    paymentMethod: PaymentMethods.PAYU,
    phone: CONSTS.DEFAULT_EMPTY_TEXT,
    prepaidCard: CONSTS.DEFAULT_EMPTY_TEXT,
    prepaidCheckbox: false,
    products: {},
    terms: false,
  };

  Object.values(products).forEach((product) => {
    initialValues.products[`${product.id}`] = product.autocomplete ? 1 : 0;
  });

  terms.forEach((term) => {
    initialValues.customTerms[`${term.id}`] = false;
  });

  return initialValues;
});

const makeValidationSchemaToMemoize = (
  customTerms: IEventTerm[],
  errors: ILang
) => {
  const validators: Partial<IHappeningFormValidators> = {
    discount: discountCodeValidator(errors),
    email: string()
      .required(errors.emailAddressIsRequiredValidator)
      .email(errors.providedEmailAddressIsIncorrectValidator),
    firstname: string().required(errors.firstNameIsRequiredValidator),
    invoiceAddress: invoiceRequiredFieldValidator(
      errors.thisFieldIsRequiredValidator
    ),
    invoiceCity: invoiceRequiredFieldValidator(
      errors.thisFieldIsRequiredValidator
    ),
    invoiceName: invoiceRequiredFieldValidator(
      errors.thisFieldIsRequiredValidator
    ),
    invoiceNip: invoiceNipValidator(errors.incorrectData),
    invoicePost: invoicePostCodeValidator(errors),
    lastname: string().required(errors.lastNameIsRequiredValidator),
    phone: phoneNumberValidator(errors.incorrectPhoneNumber).required(
      errors.thisFieldIsRequiredValidator
    ),
    terms: termValidator(errors.checkingThisRegulationsIsRequiredValidator),
    customTerms: lazy((value: { [key: string]: boolean }) =>
      customTermValidator(errors, customTerms, value, null).when(
        'invoiceCheckbox',
        {
          is: true,
          then: customTermValidator(errors, customTerms, value, 'forFVat'),
          otherwise: customTermValidator(
            errors,
            customTerms,
            value,
            'exceptFVat'
          ),
        }
      )
    ),
  };

  return object().shape(validators as IHappeningFormValidators);
};

export const makeValidationSchema = defaultMemoize(
  makeValidationSchemaToMemoize
);
