import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    buttons: {
      marginTop: '2rem',
    },
  })
);

export default useStyles;
