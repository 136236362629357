import React from 'react';

import cn from 'classnames';

import styles from './LoadingContent.module.scss';
import { ILoadingContentProps } from './LoadingContent.types';

const LoadingContent = ({
  forcedVertical,
  className,
}: ILoadingContentProps) => {
  return (
    <div
      className={cn(
        styles.content,
        className,
        forcedVertical ? styles.forcedVertical : styles.normal
      )}
    >
      <div className={styles.fakeTags} />
      <div className={styles.fakeTitle} />
      <div className={styles.fakeValue} />
      <div className={styles.fakeValue} />
      <div className={styles.fakeValue} />
      <div className={styles.fakeButtonContainer}>
        <div className={styles.fakeButton} />
      </div>
    </div>
  );
};

export default LoadingContent;
