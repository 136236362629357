import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import {
  resetAllLoading,
  resetLoading,
  resetRequested,
  resetResourceUrl,
  resetSkipNextRequest,
  setLoading,
  setRequested,
  setResourceUrl,
  setSkipNextRequest,
} from './../actions';
import * as CONSTS from './../constants/constants';
import { IAction, ISubState } from './../types';

export const initialSubState: ISubState = {
  /*
   * When API asked and waits to response.
   */
  [CONSTS._LOADING]: false,

  /*
   * When API asked, (response doesnt matter).
   */
  [CONSTS._REQUESTED]: false,

  /*
   * To save url of current resource.
   */
  [CONSTS._RESOURCE_URL]: 'unknown',

  /*
   * Skip next request when true.
   */
  [CONSTS._SKIP_NEXT_REQUEST]: false,
};

const createSubStateReducer =
  (key: string): Reducer<ISubState, any> =>
  (state = initialSubState, action: IAction): ISubState => {
    if (
      action.payload?.module === key ||
      action.payload?.module === CONSTS._ANY
    ) {
      switch (action.type) {
        case getType(resetLoading):
        case getType(resetAllLoading):
          return { ...state, [CONSTS._LOADING]: false };

        case getType(resetRequested):
          return { ...state, [CONSTS._REQUESTED]: false };

        case getType(resetResourceUrl):
          return { ...state, [CONSTS._RESOURCE_URL]: '' };

        case getType(resetSkipNextRequest):
          return { ...state, [CONSTS._SKIP_NEXT_REQUEST]: false };

        case getType(setLoading):
          return { ...state, [CONSTS._LOADING]: true };

        case getType(setRequested):
          return { ...state, [CONSTS._REQUESTED]: true };

        case getType(setResourceUrl):
          return { ...state, [CONSTS._RESOURCE_URL]: action.payload.url };

        case getType(setSkipNextRequest):
          return { ...state, [CONSTS._SKIP_NEXT_REQUEST]: true };

        default:
          return state;
      }
    }

    return state;
  };

export default createSubStateReducer;
