import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEventTerm } from '@Model/event/types';
import { ITicketsReducer } from '@Model/tickets/types';

import get from './get';

const getReceivedCustomTerms = createSelector<
  _Store.IState,
  ITicketsReducer | null,
  IEventTerm[]
>([get], (ticket) => ticket?.eventData?.customTerms || []);

export default getReceivedCustomTerms;
