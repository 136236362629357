import { createSelector } from 'reselect';

import _Store from '@Store';

import { IClosestPoolsData } from '@Model/pools/types';

import { IPoolsAllReducer } from './../types';
import getAll from './getAll';

const getClosestPoolsData = createSelector<
  _Store.IState,
  IPoolsAllReducer,
  IClosestPoolsData[]
>([getAll], (all) => (all.closestPoolsData || []) as IClosestPoolsData[]);

export default getClosestPoolsData;
