import { defaultMemoize } from 'reselect';
import { number, object, string } from 'yup';

import { ILang } from '@Model/locale/types';

import {
  ITicketDataFormPayload,
  ITransactionDataFormPayload,
} from './TicketDataForm.types';

export const initialTicketValues: ITicketDataFormPayload = {
  code: '',
  email: '',
};

export const initialTransactionValues: ITransactionDataFormPayload = {
  orderId: '',
  paymentSum: '',
};

const makeTicketValidationSchemaToMemoize = (lang: ILang) => {
  const validators = {
    code: string().required(lang.requiredField),

    email: string().required(lang.requiredField).email(lang.incorrectEmail),
  };

  return object().shape(validators);
};

const makeTransactionValidationSchemaToMemoize = (lang: ILang) => {
  const validators = {
    orderId: string().required(lang.requiredField),

    paymentSum: number()
      .required(lang.requiredField)
      .test('twoDigitsAfterDecimal', lang.mustBeANumber, (num) =>
        Number.isInteger(num * 10 ** 2)
      )
      .typeError(lang.mustBeANumber),
  };

  return object().shape(validators);
};

export const makeTicketValidationSchema = (lang: ILang) => {
  const validationSchema = () => makeTicketValidationSchemaToMemoize(lang);

  return defaultMemoize(validationSchema);
};

export const makeTicketFromTransactionValidationSchema = (lang: ILang) => {
  const validationSchema = () => makeTransactionValidationSchemaToMemoize(lang);

  return defaultMemoize(validationSchema);
};
