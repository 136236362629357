import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPool } from './../types';
import getPools from './getPools';

const getPoolsIds = createSelector<_Store.IState, IPool[], number[]>(
  [getPools],
  (pools) => {
    return pools.map((pool) => pool.id);
  }
);

export default getPoolsIds;
