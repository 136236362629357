import { createSelector } from 'reselect';

import config from '@Config';
import { PagesEnum } from '@Model/pages/constants/pages';
import get from '@Model/pages/selectors/get';

/**
 * If used in Recommend context, it returns compositions slug & theme name.
 * If used in Mainpage context, it returns 'mainpage' string & theme name.
 * If used above contexts, it will return theme name.
 */
const getCompositionIdentifier = createSelector([get], (pages): string =>
  pages.compositions[PagesEnum.recommend]?.slug
    ? pages.compositions[PagesEnum.recommend]?.slug + `-${config.theme.name}`
    : pages.compositions[PagesEnum.mainpage]
    ? `mainpage-${config.theme.name}`
    : config.theme.name || ''
);

export default getCompositionIdentifier;
