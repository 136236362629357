import { createSelector } from 'reselect';

import _Store from '@Store';

import { IArticleReducer, IArticleReducerData } from './../types';
import get from './get';

const getData = createSelector<
  _Store.IState,
  IArticleReducer,
  IArticleReducerData
>([get], (article) => article.data);

export default getData;
