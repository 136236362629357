import { createSelector } from 'reselect';

import _Store from '@Store';

import isEmptyObject from '@Misc/helpers/isEmptyObject';

import { IInternalRouterEmbed, IInternalRouterReducer } from './../types';
import get from './get';

const getParams = createSelector<
  _Store.IState,
  IInternalRouterReducer,
  IInternalRouterEmbed | null
>([get], (router) => (isEmptyObject(router.embed) ? null : router.embed));

export default getParams;
