import axios from 'axios';

import config from '@Config';

class BrazeApi {
  private addToNewsletterUrl = `${config.cloudFunctions.url}/addToNewsletter`;

  public addUserToNewsletter = async (emailAddress: string) => {
    const body = {
      email: emailAddress,
    };

    try {
      const response = await axios.post(this.addToNewsletterUrl, body);

      return response;
    } catch (error) {
      console.error(error);
    }
  };
}

export default new BrazeApi();
