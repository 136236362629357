import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import TransactionError from '@Misc/classes/TransactionError';
import { setError } from '@Model/errors/actions';
import Logger from '@Services/Logger';

import { requestDataFromFacebook } from './../actions';

const FACEBOOK_API_ERROR_TEXT =
  'Wystąpił błąd podczas pobierania danych z Facebooka.';

export const getClientDataFromFacebook: _Store.IEpic = (
  action$,
  _,
  { facebookApi }
) => {
  return action$.pipe(
    filter$(isActionOf(requestDataFromFacebook)),
    mergeMap$((action) => {
      const setFieldValue = action.payload;

      return from$(facebookApi.getLoginStatus()).pipe(
        mergeMap$((data) => {
          setFieldValue('email', data.email);
          setFieldValue('firstname', data.short_name);
          setFieldValue('lastname', data.last_name);

          return EMPTY$;
        }),
        catchError$((error: Error) => {
          Logger.error('getClientDataFromFacebook', error);

          return of$(
            setError(
              new TransactionError(FACEBOOK_API_ERROR_TEXT, error.message)
            )
          );
        })
      );
    })
  );
};
