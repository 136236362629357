import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import { IGetArticleSuccessPayload, ISetCurrentKeyPayload } from './../types';

export const articlePageMounted = createStandardAction(
  CONSTS.ARTICLE_PAGE_MOUNTED
)();

export const getArticle = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IGetArticleSuccessPayload, Error>();

export const setCurrentKey = createStandardAction(
  CONSTS.SET_CURRENT_KEY
)<ISetCurrentKeyPayload>();
