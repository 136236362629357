import { connect } from 'react-redux';

import _Store from '@Store';

import { getSizes, isDesktop } from '@Model/app/selectors';

import Gallery from './Gallery.component';
import { IGalleryFromState, IGalleryOwnProps } from './Gallery.types';

const mapStateToProps = (state: _Store.IState): IGalleryFromState => ({
  isDesktop: isDesktop(state),
  size: getSizes(state),
});

export default connect<
  IGalleryFromState,
  void,
  IGalleryOwnProps,
  _Store.IState
>(mapStateToProps)(Gallery);
