import React from 'react';

import SeatsChart from '@Compo/reusable/SeatsChart';
import { IPoolsSelectedSeat } from '@Model/pools/types';

import styles from './Seats.module.scss';
import { ISeatsProps } from './Seats.types';

const Seats = ({
  deselectSeat,
  event,
  onSelectionInvalid,
  onSelectionValid,
  poolsData,
  pricing,
  selectedSeats,
  selectSeat,
  selectedPool,
  specialEvent,
  eventRemoveFromCart,
  eventAddToCart,
}: ISeatsProps) => {
  const handleSelectSeats = (seat: IPoolsSelectedSeat) => {
    selectSeat(seat);
    if (seat.pricing?.price || seat.pricing?.price === 0)
      eventAddToCart({ price: seat.pricing.price, currency: 'PLN' });
  };

  const handleDeselectSeats = (seat: IPoolsSelectedSeat) => {
    deselectSeat(seat);
    if (seat.pricing?.price || seat.pricing?.price === 0)
      eventRemoveFromCart({ price: seat.pricing.price, currency: 'PLN' });
  };

  return (
    <div className={styles.seatsPanel}>
      {event && (
        <SeatsChart
          specialEvent={specialEvent}
          eventId={String(event.rundateId)}
          publicKey={poolsData.seatsIoPublicKey}
          selectedSeats={selectedSeats}
          maxSelectedSeats={poolsData.ticketsNumLimit}
          onSelectionInvalid={onSelectionInvalid}
          onSelectionValid={onSelectionValid}
          onSeatsSelected={handleSelectSeats}
          onSeatsDeselected={handleDeselectSeats}
          pricing={pricing}
          selectedPool={selectedPool}
          pools={poolsData.pools}
        />
      )}
    </div>
  );
};

export default Seats;
