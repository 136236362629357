import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { reserveSlotsOnsite } from '@Model/happening/actions';
import { translate } from '@Model/locale/selectors';

import HappeningBuyFormOnsite from './HappeningBuyFormOnsite.component';
import { getInitialValues } from './HappeningBuyFormOnsite.selector';
import {
  IHappeningBuyFormFromDispatch,
  IHappeningOnsiteBuyFormFromState,
} from './HappeningBuyFormOnsite.types';

const mapStateToProps = (
  state: _Store.IState
): IHappeningOnsiteBuyFormFromState => ({
  initialValues: getInitialValues(state),
  langErrors: translate(state)('errors'),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IHappeningBuyFormFromDispatch => ({
  reserve: (values) => dispatch(reserveSlotsOnsite(values)),
});

export default connect<
  IHappeningOnsiteBuyFormFromState,
  IHappeningBuyFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(HappeningBuyFormOnsite);
