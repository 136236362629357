import { IBannerOwnProps } from '@Compo/Bricks/components/Banner/Banner.types';
import prepareHotSpotUri from '@Misc/helpers/prepareHotSpotsUri';

import {
  ISectionsComponentBanner,
  ISectionsComponentDataMutable,
} from './../types/sections';

export const banner = (
  data: ISectionsComponentDataMutable
): IBannerOwnProps => {
  if (!data) {
    return {} as IBannerOwnProps;
  }

  const bannerObject = data[0] as ISectionsComponentBanner;

  return {
    link: prepareHotSpotUri(bannerObject),
    title: bannerObject.title,
  };
};
