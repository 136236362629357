import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import withCacheHeader from '@Misc/helpers/withCacheHeader';

import { IProductResponse } from './types';

class ProductApi {
  private static salesAgentHeader = {
    'X-Sales-Agent': config.app.salesAgent,
  };

  private static getProductUrl(productSlug: string): string {
    return `${config.api.baseUrl}product/${productSlug}`;
  }

  private cancelToken?: CancelTokenSource;

  public getProduct(productSlug: string): Promise<IProductResponse> {
    return new Promise((resolve, reject) => {
      const url = ProductApi.getProductUrl(productSlug);

      this.cancelToken = axios.CancelToken.source();

      axios
        .get(
          url,
          withCacheHeader({
            cancelToken: this.cancelToken.token,
            headers: ProductApi.salesAgentHeader,
          })
        )
        .then(getData)
        .then(getData)
        .then((response: IProductResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelProduct() {
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.cancelToken = undefined;
    }
  }
}

export default new ProductApi();
