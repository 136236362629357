import moment from 'moment';

const getShortTime = (startTime: string) => {
  const eventStartTime = moment(startTime, 'HH:mm');
  const parts = moment(eventStartTime).format('HH:mm');

  return parts;
};

export default getShortTime;
