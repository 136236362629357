import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPremiumReducer, IPremiumToken } from './../types';
import get from './get';

const getToken = createSelector<
  _Store.IState,
  IPremiumReducer,
  IPremiumToken | null
>([get], (slice) => {
  return slice.token;
});

export default getToken;
