import React, { FC, useState } from 'react';

import { Button, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import SettingBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { Link } from 'react-router-dom';

import Breadcrumb from '@Compo/Breadcrumb';
import ActionMessage from '@Compo/modals/ActionMessage';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { TicketManagementOptions } from '@Model/tickets/constants/options';
import routes from '@Routes/routes';

import TicketDataForm from './../TicketDataForm';
import WithEventDetailBox from './../WithEventDetailBox';
import styles from './MyTicket.module.scss';
import { IDefaultModal, IMyTicketProps } from './MyTicket.types';
import OptionBox from './components/OptionBox';
import useStyles from './styles/MyTicket.styles';

const MyTicket: FC<IMyTicketProps> = ({
  isEmbed,
  lang,
  resetTicket,
  returnTicket,
  ticketData,
  tickets,
  downloadTicketFromTicketData,
  downloadTicketFromTransactionData,
  cancelTransfer,
}) => {
  const defaultModal: IDefaultModal = {
    action: '',
    message: '',
    show: false,
    confirmText: undefined,
    cancelText: undefined,
    radioOptions: undefined,
  };

  const [modal, setModal] = useState(defaultModal);

  const [refundType, setRefundType] = useState('transfer');

  const closeModal = () =>
    setModal({
      ...modal,
      show: false,
    });

  const returnModal = () =>
    setModal({
      ...defaultModal,
      action: 'RETURN_OPTION',
      message: lang.returnMessage,
      show: !modal.show,
    });

  const cancelTransferModal = () =>
    setModal({
      ...defaultModal,
      action: 'CANCEL_TRANSFER_TICKET',
      message: lang.cancelTransferMessage,
      show: !modal.show,
    });

  if (!ticketData) {
    return <TicketDataForm />;
  }

  const downloadTicket = () => {
    if (ticketData.code && ticketData.email) {
      const { ticketId, code, email } = ticketData;

      downloadTicketFromTicketData({
        code,
        email,
        ticketId: ticketId.toString(10),
      });
    } else if (ticketData.orderId && ticketData.paymentSum) {
      const { ticketId, orderId, paymentSum } = ticketData;

      downloadTicketFromTransactionData({
        orderId,
        paymentSum: encodeURIComponent(paymentSum),
        ticketId: ticketId.toString(10),
      });
    }
  };

  const actionSelect = () => {
    switch (modal.action) {
      case 'RETURN_OPTION':
        return setModal({
          ...defaultModal,
          action: 'RETURN_TICKET',
          message: lang.refundOptionsMessage,
          show: true,
          confirmText: lang.returnButton,
          cancelText: lang.cancel,
          radioOptions: [
            { label: lang.refundTransfer, value: 'transfer' },
            { label: lang.refundVoucherGoing, value: 'voucherGoing' },
            { label: lang.refundVoucherEmpik, value: 'voucherEmpik' },
          ],
        });
      case 'RETURN_TICKET':
        return returnTicket({
          ticketId: ticketData.ticketId.toString() || '',
          token: ticketData.token,
          refundType,
        });
      case 'CANCEL_TRANSFER_TICKET':
        const hash = ticketData.transfers.find(
          (transfer) => transfer.hash
        )?.hash;
        if (hash) {
          return cancelTransfer({
            token: ticketData.token,
            hash,
          });
        }

        return;
      default:
        return closeModal;
    }
  };

  const rescheduleLink = fillUrlWithValues(
    isEmbed ? routes.pinChangeDate : routes.changeDate,
    ':ticketId',
    `${ticketData.ticketId}`
  );

  const transferLink = fillUrlWithValues(
    isEmbed ? routes.pinTransferTicket : routes.transferTicket,
    ':ticketId',
    `${ticketData.ticketId}`
  );

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <>
      <Breadcrumb
        current={{
          link: `${routes.myTicket}/${ticketData.ticketId}`,
          name: ticketData.event.title,
        }}
      />
      <h2 className={styles.pageTitle}>{lang.pageTitle}</h2>
      <WithEventDetailBox>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={3}>
              {!ticketData.actions[TicketManagementOptions.CANCEL_TRANSFER]
                .isAvailable && (
                <Grid item={true} xs={12}>
                  <OptionBox
                    buttonText={lang.downloadButton}
                    text={lang.downloadTicket}
                    action={downloadTicket}
                    isDisabled={false}
                    errors={[]}
                    icon={<ConfirmationNumberOutlinedIcon />}
                  />
                </Grid>
              )}
              {!ticketData.actions[TicketManagementOptions.CANCEL_TRANSFER]
                .isAvailable ? (
                Object.keys(ticketData.actions).map((action, index) => {
                  switch (action) {
                    // @TODO: refactor transfer when API ready
                    case TicketManagementOptions.TRANSFER:
                      return (
                        <Grid item={true} xs={12}>
                          <OptionBox
                            buttonText={lang.transferButton}
                            link={transferLink}
                            text={lang.transfer}
                            key={index}
                            isDisabled={!ticketData.actions[action].isAvailable}
                            errors={ticketData.actions[action].errors}
                            icon={<CompareArrowsIcon />}
                          />
                        </Grid>
                      );
                    // @TODO: uncomment when API ready
                    // case TicketManagementOptions.CHANGE_TIME:
                    //   return (
                    //     <Grid item={true} xs={12}>
                    //       <OptionBox
                    //         buttonText={lang.choose}
                    //         link={rescheduleLink}
                    //         text={lang.reschedule}
                    //         key={index}
                    //         isDisabled={!ticketData.actions[action].isAvailable}
                    //         errors={ticketData.actions[action].errors}
                    //         icon={<ScheduleIcon />}
                    //       />
                    //     </Grid>
                    //   );
                    case TicketManagementOptions.RETURN:
                      return (
                        <Grid item={true} xs={12}>
                          <OptionBox
                            buttonText={lang.returnButton}
                            action={returnModal}
                            text={lang.return}
                            key={index}
                            isDisabled={!ticketData.actions[action].isAvailable}
                            errors={ticketData.actions[action].errors}
                            icon={<SettingBackupRestoreIcon />}
                          />
                        </Grid>
                      );

                    default:
                  }
                })
              ) : (
                <Grid item={true} xs={12}>
                  <OptionBox
                    buttonText={lang.cancelTransferButton}
                    action={cancelTransferModal}
                    text={lang.transferInProgress}
                    isDisabled={
                      !ticketData.actions[
                        TicketManagementOptions.CANCEL_TRANSFER
                      ].isAvailable
                    }
                    errors={
                      ticketData.actions[
                        TicketManagementOptions.CANCEL_TRANSFER
                      ].errors
                    }
                    icon={<CompareArrowsIcon />}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {tickets && tickets.length > 1 && (
            <Grid item={true}>
              <Link to={isEmbed ? routes.pinMyTicket : routes.myTicket}>
                <Button
                  variant={'text'}
                  startIcon={<ArrowBackIcon />}
                  color={'secondary'}
                  size={'medium'}
                  className={muiStyles.buttonText}
                >
                  {lang.reset}
                </Button>
              </Link>
            </Grid>
          )}
          <Grid item={true} xs={12}>
            <Button
              variant={'text'}
              startIcon={<ArrowBackIcon />}
              color={'secondary'}
              size={'medium'}
              onClick={resetTicket}
              className={muiStyles.buttonText}
            >
              {lang.resetTicketCode}
            </Button>
          </Grid>
        </Grid>
      </WithEventDetailBox>
      <ActionMessage
        action={actionSelect}
        close={closeModal}
        show={modal.show}
        message={modal.message}
        confirmText={modal.confirmText}
        cancelText={modal.cancelText}
        radioOptions={modal.radioOptions}
        setOption={setRefundType}
        option={refundType}
      />
    </>
  );
};

export default MyTicket;
