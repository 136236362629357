import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    slot: {
      fontSize: '1rem',
      padding: '1rem 0rem',
    },
    tooltipContainer: {
      padding: 0,
      borderRadius: '0.3125rem',
    },
    tooltipArrow: {
      '&::before': {
        backgroundColor: theme.palette.primary.contrastText,
      },
    },
  })
);

export default useStyles;
