import React, { useState } from 'react';

import { Link, Menu, MenuItem, useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { useStyles } from '@Compo/layout/MenuAppBar/components/MobileMenu/MobileMenu.styles';
import config from '@Config';
import menu from '@Services/$menu-api';

const menuSelector = config.theme.isEmpik ? menu.menuEmpik : menu.menuGoing;

const linkRegExp = new RegExp('^https?://');

const MobileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const styles = useStyles(theme);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        edge={'end'}
        color={'inherit'}
        aria-label={config.mui().mobileMenuId}
        aria-controls={config.mui().mobileMenuId}
        aria-haspopup={'true'}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id={config.mui().mobileMenuId}
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuSelector.map((item) => (
          <MenuItem key={item.title}>
            {item.link && linkRegExp.test(item.link) ? (
              <Link
                href={item.link}
                color={'inherit'}
                underline={'none'}
                target={'_blank'}
              >
                {item.title}
              </Link>
            ) : item.link ? (
              <Link
                component={NavLink}
                to={item.link}
                color={'inherit'}
                underline={'none'}
                style={item.isSalesButton ? styles.salesButton : undefined}
              >
                {item.title}
              </Link>
            ) : (
              <Tooltip title={menu.toBeReleasedText}>
                <Link color={'inherit'} underline={'none'}>
                  {item.title}
                </Link>
              </Tooltip>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MobileMenu;
