/**
 * Function that finds links in strings and wraps them with HTML tags
 *
 * @param {string} inputString - Source text.
 * @return {string} outputString - string with HTML tags
 */
const wrapStringLinks = (inputString: string) => {
  let regExp1;
  let regExp2;
  let regExp3;
  let outputString;

  regExp1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  outputString = inputString.replace(
    regExp1,
    '<a href="$1" target="_blank">$1</a>'
  );

  regExp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  outputString = outputString.replace(
    regExp2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  regExp3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  outputString = outputString.replace(regExp3, '<a href="mailto:$1">$1</a>');

  return outputString;
};

export default wrapStringLinks;
