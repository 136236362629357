import { Selector } from 'reselect';

import _Store from '@Store';

import { IPaymentMethodsReducer } from '@Model/pools/types';

const getPaymentMethods: Selector<_Store.IState, IPaymentMethodsReducer> = (
  state
) => state.pools.paymentMethods;

export default getPaymentMethods;
