import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getEvents } from '@Model/events/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenEventsFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getEvents.request)),
    map$(() => setLoading(CONSTS.EVENTS))
  );
};

export const setLoaderWhenEventsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getEvents.success, getEvents.failure], action)
    ),
    map$(() => resetLoading(CONSTS.EVENTS))
  );
};
