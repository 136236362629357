/**
 * Function appending external css file to the DOM
 *
 * @param {string} path - css file source path
 *
 */
const loadDOMStyle = (path: string) => {
  const head = document.getElementsByTagName('HEAD')[0];
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = path;
  head.appendChild(link);
};

export default loadDOMStyle;
