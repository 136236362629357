import React, { FC, useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import { useLocation } from 'react-router';

import BuyButton from '@Compo/Events/components/BuyButton';
import Tags from '@Compo/Events/components/Tags';
import Title from '@Compo/Events/components/TitleResponsive';
import ChangeMonitorTag from '@Compo/reusable/ChangeMonitorTag';
import Heart from '@Compo/reusable/Heart';
import ImageFixed from '@Compo/reusable/ImageFixed';
import Price from '@Compo/reusable/Price';
import config from '@Config';
import isHTML from '@Misc/helpers/isHTML';
import prepareEventLink from '@Misc/helpers/prepareEventLink';
import removeHTMLTags from '@Misc/helpers/removeHTMLTags';
import { IBuyButtonAnalytics } from '@Model/analytics/types';

import styles from './Event.module.scss';
import useStyles from './Event.styles';
import { IEventProps } from './Event.types';

const Event: FC<IEventProps> = ({
  buttonLabel,
  calendarEvent,
  currency,
  changeMonitorName,
  description,
  eventSlug,
  friendlyDate,
  friendlyHour,
  id,
  imageUrl,
  isEmbed,
  isPremiumEvent,
  notForSale,
  partner,
  place,
  placeId,
  placeSlug,
  position,
  price,
  priceDescription,
  redirectToUrl,
  rundateSlug,
  tags,
  title,
  forcedVertical,
  isMobile,
  noDescription,
  eventSelectItem,
  hasPools,
}) => {
  const [urlAnalytics, setUrlAnalytics] = useState('');
  const hasPoolsFromSearch = Number(hasPools) > 0;
  const isPriceTBA = !hasPoolsFromSearch && priceDescription === 'TBA';

  useEffect(() => {
    setUrlAnalytics(
      window.location.origin + '/' + location.pathname.split('/')[1]
    );
  }, []);

  const location = useLocation();
  const link = prepareEventLink(
    eventSlug,
    rundateSlug,
    isEmbed,
    undefined,
    calendarEvent
  );
  const showFriendlyDate =
    friendlyDate && friendlyHour
      ? friendlyDate + `, ${friendlyHour}`
      : friendlyDate;
  const showTagsAbove = config.theme.isGoing && isMobile;
  const showTagsBelow = config.theme.isEmpik || !isMobile;
  const showDescription = description && !noDescription;
  const analyticData: IBuyButtonAnalytics = {
    eventSlug,
    id,
    partner,
    position,
    price,
    rundateSlug,
    tags,
    title,
  };

  const newAnalyticsData = {
    rundate_id: id,
    partner,
    price,
    tags,
    title,
    place,
    placeId,
    url: urlAnalytics,
  };
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const handleOnClick = () => {
    eventSelectItem(newAnalyticsData);
  };

  return (
    <li
      data-test-id={'event'}
      className={cn(
        styles.event,
        forcedVertical ? styles.forcedVertical : styles.normal,
        isPremiumEvent && styles.premiumEvent,
        muiStyles.root,
        changeMonitorName && styles.withChangeMonitorName
      )}
    >
      <div className={styles.imageWrapper} onClick={handleOnClick}>
        <ImageFixed
          src={imageUrl}
          link={link}
          transformation={{
            width: 810,
            height: 700,
          }}
        />
      </div>
      {tags && showTagsAbove && (
        <div className={styles.tagsContainer}>
          <Tags tags={tags} />
        </div>
      )}
      <div className={styles.eventDetails}>
        {tags && showTagsBelow && (
          <div className={styles.detailsTop}>
            <Tags tags={tags} />
            <Heart />
          </div>
        )}
        <Title title={title} link={link} onClick={handleOnClick} />
        {changeMonitorName && (
          <ChangeMonitorTag
            changeMonitorType={{ name: changeMonitorName, id: 0, slug: '' }}
          />
        )}
        <h3 className={cn(styles.value, muiStyles.secondary)}>
          {showFriendlyDate}
        </h3>
        {place && (
          <h3 className={cn(styles.value, muiStyles.secondary)}>
            {place}
            {!isPriceTBA && (
              <>
                {', '}
                <Price
                  price={price}
                  currency={currency}
                  priceDescription={priceDescription}
                />
              </>
            )}
          </h3>
        )}
        {showDescription &&
          (isHTML(description) ? (
            <h3 className={cn(styles.eventDescription, muiStyles.fontColor)}>
              {removeHTMLTags(description)}
            </h3>
          ) : (
            <h3 className={cn(styles.eventDescription, muiStyles.fontColor)}>
              {description}
            </h3>
          ))}
        <div onClick={handleOnClick} className={cn(styles.buyButtonContainer)}>
          <BuyButton
            analyticData={analyticData}
            forcedVertical={forcedVertical}
            isPremiumEvent={isPremiumEvent}
            label={buttonLabel}
            link={link}
            notForSale={notForSale}
          />
        </div>
      </div>
    </li>
  );
};

export default Event;
