import { connect } from 'react-redux';

import _Store from '@Store';

import {
  ICtaButtonsBrickOwnProps,
  ICtaButtonsFromState,
} from '@Compo/Bricks/components/CtaButtonsBrick/CtaButtonsBrick.types';
import { getCompositionIdentifier } from '@Model/pages/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import CtaButtonsBrick from './CtaButtonsBrick.component';

const mapStateToProps = (state: _Store.IState) => {
  return {
    isLoading: getLoading(CONST.SECTIONS)(state),
    compositionIdentifier: getCompositionIdentifier(state),
  };
};

export default connect<
  ICtaButtonsFromState,
  null,
  ICtaButtonsBrickOwnProps,
  _Store.IState
>(mapStateToProps)(CtaButtonsBrick);
