import { defaultMemoize } from 'reselect';
import { object, string } from 'yup';

import { ILang } from '@Model/locale/types';

const personalDataValidation = (lang: ILang) => {
  const validators = {
    submission: string().required(lang.requiredField).nullable(),
  };

  return object().shape(validators);
};

export const makeValidationSchema = (lang: ILang) => {
  const validationSchema = () => personalDataValidation(lang);

  return defaultMemoize(validationSchema);
};
