import { createSelector } from 'reselect';

import config from '@Config';
import { specialEventNames } from '@Model/event/constants/specialEvents';
import { getEvent, specialEvent } from '@Model/event/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';
import { getLocation } from '@Model/router/selectors';

import * as TEXTS from './../constants/texts';
import { IBuyPageMeta, IMetaValue } from './../types';

const buyPageMeta = createSelector(
  [getLocation, getEvent, getIframeParams, specialEvent],
  (location, event, embed, specialEventName): IBuyPageMeta => {
    let pageName: IMetaValue = TEXTS.LOADING;
    let description: IMetaValue = TEXTS.LOADING;
    let ogDescription: IMetaValue = TEXTS.LOADING;

    if (event) {
      const category = event.tags?.[0] || null;
      const categoryName = (category?.isMain && category?.name) || TEXTS.EVENTS;

      let eventDescription = event.description;
      if (eventDescription?.length > 60) {
        eventDescription = `${eventDescription.substr(0, 60)}...`;
      }

      pageName = [
        event.title,
        `${TEXTS.BUY_TICKETS_FOR} ${categoryName}`,
        event.placeAddress,
      ];

      description = [
        `${TEXTS.BUY_TICKETS_FOR_EVENT} ${event.title}`,
        event.friendlyDate,
        event.placeAddress,
      ];

      ogDescription = [
        categoryName,
        event.title,
        eventDescription,
        TEXTS.BUY_TICKET,
      ];
    }

    const isCustomGtm =
      specialEventName === specialEventNames.snowFest2023 && embed !== null;
    const customGtmId = (eventName: specialEventNames | null) => {
      switch (eventName) {
        case specialEventNames.snowFest2023:
          return 'GTM-WCKGZ76';
        default:
          return '';
      }
    };

    return {
      currentUrl: `${config.app.baseUrl}${location.pathname}`,
      description,
      ogDescription,
      pageName,
      customGtmId: isCustomGtm
        ? customGtmId(specialEventName)
        : embed !== null
        ? embed?.gtmKey
        : event?.gtmId,
      isEmbed: embed !== null,
      customGtagId: embed?.gtagKey,
      fbPixelKey: embed?.fbPixelKey,
    };
  }
);

export default buyPageMeta;
