import { useEffect, useState } from 'react';

import useSWR from 'swr';

import config from '@Config';
import { IEvent } from '@Model/events/types';
import { ISelectedLocation } from '@Model/locations/types';
import { getAndFormatDynamicListingParams } from '@Model/pages/helpers/getAndFormatDynamicListingParams';
import { IDynamicListingsCloudsearchQuery } from '@Model/pages/types';
import {
  ISearchQueryJoinMethod,
  ISearchSuccessPayload,
} from '@Model/search/types';
import Analytics from '@Services/$analytics';
import AwsSearchApi from '@Services/$awsSearch-api';

import pl from '../../../lang/pl';

const DEFAULT_SEARCH_SIZE = 30;

export const useDynamicListings = ({
  cloudsearchQuery,
  userLocation,
  componentId,
}: {
  cloudsearchQuery?: IDynamicListingsCloudsearchQuery;
  userLocation?: ISelectedLocation;
  componentId?: string;
}) => {
  const [events, setEvents] = useState<IEvent[]>([]);
  const [isIgnoreDynamicParams, setIsIgnoreDynamicParams] = useState(false);

  const dynamicListing = getAndFormatDynamicListingParams(
    cloudsearchQuery?.params,
    isIgnoreDynamicParams,
    userLocation
  );

  const getCloudsearchData = async () =>
    cloudsearchQuery && !!dynamicListing?.dynamicFilters?.length
      ? await AwsSearchApi.searchAWS({
          dateTranslate: pl.dates,
          dynamicFilters: dynamicListing.dynamicFilters,
          offset: 0,
          searchSize: dynamicListing?.itemsLimit || DEFAULT_SEARCH_SIZE,
          customSorting: cloudsearchQuery?.sorting?.length
            ? cloudsearchQuery?.sorting
            : undefined,
          queryJoinMethod:
            cloudsearchQuery?.type?.toUpperCase() as ISearchQueryJoinMethod,
          dates: dynamicListing.dates,
        })
      : undefined;

  const {
    data: cloudsearchResponse,
    isValidating,
    mutate,
  } = useSWR<ISearchSuccessPayload | undefined>(
    cloudsearchQuery ? componentId : undefined,
    getCloudsearchData,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (
      !isValidating &&
      !cloudsearchResponse?.results.length &&
      !isIgnoreDynamicParams
    )
      /**
       * If the first request does not bring any data,
       * we want to run another one, without current user's
       * dynamic params.
       */
      setIsIgnoreDynamicParams(true);
  }, [cloudsearchResponse?.results.length, isValidating]);

  useEffect(() => {
    if (!isValidating && cloudsearchResponse?.results.length)
      setEvents(cloudsearchResponse.results);
  }, [isValidating]);

  useEffect(() => {
    if (!!events.length && componentId && cloudsearchQuery)
      Analytics.pushForEventsList(
        events,
        0,
        'params: ' +
          cloudsearchQuery.params
            .map((p) => `${p.key}=${p.value}`)
            .join(' ' + cloudsearchQuery.type?.toUpperCase() + ' ') +
          ', sorting: ' +
          `${cloudsearchQuery.sorting
            ?.map((s) => `${s.field}-${s.sort}-${s.Priority}`)
            .join(', ')}`,
        componentId,
        true
      );
  }, [JSON.stringify(events)]);

  useEffect(() => {
    if (!isValidating) (async () => await mutate())();
  }, [userLocation?.slug, isIgnoreDynamicParams]);

  return {
    events,
  };
};
