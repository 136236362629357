import React, { ChangeEvent, useState } from 'react';

import {
  Button,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import cn from 'classnames';

import config from '@Config';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import routes from '@Routes/routes';
import image from '@Styles/images/01_oaplikacji_2 1.png';
import icon_huawei_store from '@Styles/images/button_app_gallery.svg';
import icon_ios_store from '@Styles/images/button_app_store.svg';
import icon_android_store from '@Styles/images/button_google_play.svg';

import classes from '../../Success.classes';
import useStyles from './DownloadSection.styles';
import { IDownloadSectionProps } from './DownloadSection.types';

const DownloadSection = ({ lang }: IDownloadSectionProps) => {
  const ITUNES_LINK = 'https://itunes.apple.com/us/app/going./id704764616';
  const ANDROID_LINK =
    'https://play.google.com/store/apps/details?id=pl.goingapp';
  const HUAWEI_LINK = 'https://appgallery7.huawei.com/#/app/C101184169';

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const { isMobile, isTablet, isDesktop } = useMuiBreakpoints();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorNumber, setErrorNumber] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [isSuccessActive, setIsSuccessActive] = useState(false);

  const aboutAppRedirect = () => {
    window.open(
      config.theme.isGoing ? routes.about : config.app.aboutAppUrl,
      '_blank'
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPhoneNumber(event.target.value);
  };

  const checkValidation = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length === 9) {
      setHelperText('');
    } else {
      setHelperText(lang.helperText);
      setErrorNumber(true);
    }
  };

  const handleSendSMS = () => {
    const link = config.api.smsGateApi;

    const data = {
      message: `
      Pobierz aplikację Going.: https://going.pl/pobierz-going
i bądź na bieżąco z najlepszymi wydarzeniami.
    `,
      receiver: '+48' + phoneNumber,
    };

    if (link) {
      fetch(link, {
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        mode: 'no-cors',
      })
        .then((response) => {
          if (phoneNumber.length === 9) {
            setIsSuccessActive(true);
            setTimeout(() => {
              setIsSuccessActive(false);
            }, 2000);
          }

          return response;
        })
        .catch((error) => {
          setIsAlertActive(true);
          setTimeout(() => {
            setIsAlertActive(false);
          }, 2000);
        });
    }

    setPhoneNumber('');
  };

  const limitNumber = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 9);
  };

  return (
    <Grid
      container={true}
      className={cn(muiStyles.wrapper, classes.downloadContainer)}
    >
      {isAlertActive && (
        <Grid
          item={true}
          className={cn(muiStyles.alert, classes.downloadAlert)}
        >
          <Alert severity={'error'}>
            <AlertTitle>{lang.error}</AlertTitle>
            {lang.somethingWentWrong}
          </Alert>
        </Grid>
      )}

      {isSuccessActive && (
        <Grid
          item={true}
          className={cn(muiStyles.alert, classes.downloadSuccessMessage)}
        >
          <Alert severity={'success'}>{lang.successMessage}</Alert>
        </Grid>
      )}

      <Grid item={true} xs={true} className={classes.donwloadContentContainer}>
        <Grid container={true}>
          <Grid
            item={true}
            className={cn(muiStyles.header, classes.downloadHeader)}
          >
            <Typography variant={isMobile ? 'h5' : 'h3'} color="textPrimary">
              {lang.getMore}
            </Typography>
          </Grid>

          <Grid
            item={true}
            className={cn(muiStyles.header, classes.downloadSubHeader)}
          >
            <Typography variant={isMobile ? 'body1' : 'h5'} color="textPrimary">
              {lang.beUpToDate}
            </Typography>
          </Grid>

          {isDesktop && (
            <Grid item={true} className={classes.downloadInputSection}>
              <Grid container={true}>
                <Grid item={true} xs={12} className={classes.enterNumberText}>
                  <Typography variant="body1" color="textPrimary">
                    {lang.enterNumber}
                  </Typography>
                </Grid>

                <Grid item={true} xs={true} className={classes.inputContainer}>
                  <Grid
                    container={true}
                    className={muiStyles.inputAndButtonWrapper}
                  >
                    <Grid item={true} xs={true} className={classes.input}>
                      <TextField
                        error={errorNumber}
                        helperText={helperText}
                        id="standard-number"
                        type="number"
                        value={phoneNumber}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth={true}
                        onBlur={checkValidation}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography>{lang.prefix}</Typography>
                            </InputAdornment>
                          ),
                        }}
                        onInput={limitNumber}
                      />
                    </Grid>

                    <Grid
                      item={true}
                      className={cn(muiStyles.button, classes.inputButton)}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleSendSMS}
                      >
                        {lang.send}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item={true} xs={12} className={classes.textUnderInput}>
                  <Typography variant="body1" color="textPrimary">
                    {lang.orDownload}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          {isTablet && (
            <Grid item={true} xs={12} className={classes.downloadAppText}>
              <Typography variant="body1" color="textPrimary">
                {lang.downloadApp}
              </Typography>
            </Grid>
          )}

          {!isMobile && (
            <Grid
              item={true}
              xs={true}
              className={cn(muiStyles.buttonsWrapper, classes.buttonsWrapper)}
            >
              <Grid
                container={true}
                justifyContent={'space-around'}
                spacing={3}
              >
                <Grid item={true} xs={4} className={classes.storeButton}>
                  <Link href={ITUNES_LINK} target={'_blank'} rel={'nofollow'}>
                    <img
                      className={muiStyles.images}
                      src={icon_ios_store}
                      alt={lang.altText2}
                    />
                  </Link>
                </Grid>
                <Grid item={true} xs={4} className={classes.storeButton}>
                  <Link href={ANDROID_LINK} target={'_blank'} rel={'nofollow'}>
                    <img
                      className={muiStyles.images}
                      src={icon_android_store}
                      alt={lang.altText2}
                    />
                  </Link>
                </Grid>
                <Grid item={true} xs={4} className={classes.storeButton}>
                  <Link href={HUAWEI_LINK} target={'_blank'} rel={'nofollow'}>
                    <img
                      className={muiStyles.images}
                      src={icon_huawei_store}
                      alt={lang.altText2}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}

          {isMobile && (
            <Grid item={true} xs={12} className={classes.discoverApp2}>
              <Button
                fullWidth={true}
                variant="contained"
                size="large"
                color="primary"
                onClick={aboutAppRedirect}
              >
                {lang.discoverApp2}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {isDesktop && (
        <Grid item={true} xs={6} className={cn(classes.downloadBigImage)}>
          <Grid container={true} justifyContent={'center'}>
            <Grid item={true}>
              <img src={image} alt={lang.altText} className={muiStyles.image} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DownloadSection;
