import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { eventBannerClick } from '@Model/analytics/actions';
import { isMobile } from '@Model/app/selectors';
import { getLocation } from '@Model/router/selectors';
import {
  baseSearch,
  resetSearchOffset,
  setSearchOffset,
} from '@Model/search/actions';
import {
  getSearchLoadingStatus,
  getSearchOffset,
  getSearchPhrase,
  getSearchResults,
  getSearchView,
} from '@Model/search/selectors';

import ResultsList from './ResultsList.component';
import {
  IResultsListFromDispatch,
  IResultsListFromState,
} from './ResultsList.types';

const mapStateToProps = (state: _Store.IState): IResultsListFromState => ({
  events: getSearchResults(state),
  isMobile: isMobile(state),
  location: getLocation(state),
  phrase: getSearchPhrase(state),
  searchLoadingStatus: getSearchLoadingStatus(state),
  searchOffset: getSearchOffset(state),
  tileView: getSearchView(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IResultsListFromDispatch => ({
  eventBannerClick: (label) => dispatch(eventBannerClick(label)),
  resetOffset: () => dispatch(resetSearchOffset()),
  search: (offset) => dispatch(baseSearch({ offset })),
  setOffset: () => dispatch(setSearchOffset()),
});

export default connect<
  IResultsListFromState,
  IResultsListFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ResultsList);
