import React from 'react';

import cn from 'classnames';
import { Field, useFormikContext } from 'formik';

import Checkbox from '@Compo/forms/Checkbox';
import FormField from '@Compo/forms/FormField';
import config from '@Config';
import { UserCardStatusEnum } from '@Model/premium/types';

import { IBuyingOnlineFormValues } from '../../Online/Online.types';
import clientDataStyles from './../ClientData/ClientData.module.scss';
import styles from './EmpikCardNumber.module.scss';
import { IEmpikCardNumberProps } from './EmpikCardNumber.types';

const ONSITE_EMPIK_CARD_DESCRITPION_TEXT =
  'Wpisz 13 cyfrowy kod karty Mój Empik klienta.';

const EmpikCardNumber = ({
  checkUserCardStatus,
  errors: errorsText,
  isDisabled,
  lang,
  number,
  open,
  userCardStatus,
  withoutHeader,
}: IEmpikCardNumberProps) => {
  const { values, errors, setErrors, setFieldValue } =
    useFormikContext<IBuyingOnlineFormValues>();
  const email = values.email;
  const userCardNumber = values.empikPremiumNumber;
  const getCardStatus =
    errors.empikPremiumNumber === errorsText.checkingCardValidator &&
    !errors.email;
  const isPremiumInactive = userCardStatus === UserCardStatusEnum.INACTIVE;
  let activeCardText = '';

  const setUserCardErrorText = (errorMessage: string | undefined) => {
    setErrors({ ...errors, empikPremiumNumber: errorMessage });
  };

  if (getCardStatus) {
    checkUserCardStatus(userCardNumber, email);
  }

  switch (userCardStatus) {
    case UserCardStatusEnum.OK:
      if (values.userCardStatusCheck !== UserCardStatusEnum.OK) {
        setFieldValue('userCardStatusCheck', UserCardStatusEnum.OK);
      }
      setUserCardErrorText(undefined);
      activeCardText = errorsText.cardActiveText;
      break;
    case UserCardStatusEnum.INACTIVE:
      setUserCardErrorText(errorsText.checkingCardInactiveValidator);
      break;
    case UserCardStatusEnum.NOMATCH:
      setUserCardErrorText(errorsText.checkingCardNomatchValidator);
      break;
    case UserCardStatusEnum.ERROR:
      setUserCardErrorText(errorsText.checkingCardErrorValidator);
      break;
    default:
  }

  return (
    <div
      className={cn(
        withoutHeader && styles.wrapper,
        !withoutHeader && clientDataStyles.clientData,
        isDisabled && clientDataStyles.disabled,
        clientDataStyles.premiumCard
      )}
    >
      {!withoutHeader && (
        <h3 className={clientDataStyles.header} data-number={number}>
          {lang.premiumUserData}
        </h3>
      )}

      <div className={cn(!withoutHeader && clientDataStyles.formPanel)}>
        <div className={cn(clientDataStyles.label)}>
          <Field
            component={Checkbox}
            name="userHavePremiumCheckbox"
            id="userHavePremiumCheckbox"
          >
            {lang.premiumUser}
          </Field>
        </div>

        {open && (
          <div className={styles.form}>
            <span className={clientDataStyles.premiumDescription}>
              {config.app.onlineSale && lang.cardDescription}
              {!config.app.onlineSale && ONSITE_EMPIK_CARD_DESCRITPION_TEXT}
            </span>

            <label
              className={clientDataStyles.label}
              htmlFor="empikPremiumNumber"
            >
              <FormField
                id="empikPremiumNumber"
                name="empikPremiumNumber"
                placeholder={lang.empikCardNumber}
                type="text"
                correct={activeCardText}
              />
              {isPremiumInactive && (
                <a
                  href={config.premium.url}
                  className={styles.buyPremiumLink}
                  target="blank"
                >
                  {lang.buyNow}
                </a>
              )}
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmpikCardNumber;
