import React from 'react';

import config from '@Config';

import styles from './FullTriangleBackground.module.scss';

const FullTriangleBackground = () => {
  if (!config.theme.isEmpik) {
    return null;
  }

  return <div className={styles.background} />;
};

export default FullTriangleBackground;
