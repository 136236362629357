import { createSelector } from 'reselect';

import _Store from '@Store';

import config from '@Config';
import { ILocaleReducer } from '@Model/locale/types';

import { ILang } from './../types';
import get from './get';

const { en, pl } = config.i18nConfig.langMaps;

const getLangMap = createSelector<_Store.IState, ILocaleReducer, ILang>(
  [get],
  (locale) => {
    let langMap: {};

    switch (locale.selectedLang) {
      case 'en':
        langMap = en;
        break;

      default:
        langMap = pl;
        break;
    }

    return langMap;
  }
);

export default getLangMap;
