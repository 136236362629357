import { eventProceed } from '@/models/analytics/actions';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import { selectPool, selectTicket } from '@Model/pools/actions';

import Pools from './Pools.component';
import {
  IPoolsFromDispatch,
  IPoolsFromState,
  IPoolsOwnProps,
} from './Pools.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPoolsFromDispatch => {
  return {
    selectPool: (eventSlug, rundateSlug) =>
      dispatch(selectPool({ eventSlug, rundateSlug })),
    selectTicket: (
      poolId,
      amount,
      poolName,
      currency,
      paymentMethods,
      price,
      serviceFee,
      additionalFields,
      forms
    ) =>
      dispatch(
        selectTicket({
          additionalFields,
          amount,
          currency,
          forms,
          poolId,
          poolName,
          paymentMethods,
          price,
          serviceFee,
        })
      ),
    eventProceed: (textDisplayed) => dispatch(eventProceed(textDisplayed)),
  };
};

const mapStateToProps = (state: _Store.IState): IPoolsFromState => {
  return {
    lang: translate(state)('event'),
    translate: translate(state)('buy', 'pools'),
  };
};

export default connect<
  IPoolsFromState,
  IPoolsFromDispatch,
  IPoolsOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Pools);
