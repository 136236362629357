import React, { FC } from 'react';

import { Button } from '@material-ui/core';

import { ILoadMoreButton } from './LoadMoreButton.types';

const LoadMoreButton: FC<ILoadMoreButton> = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      size={'large'}
      variant={'outlined'}
      color={'secondary'}
    >
      {children}
    </Button>
  );
};

export default LoadMoreButton;
