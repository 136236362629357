import React, { FC, ReactNode } from 'react';

import { Link, Typography, useTheme } from '@material-ui/core';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';

import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import useStyles from './MarkdownText.styles';
import { IMarkdownTag, IMarkdownTextProps } from './MarkdownText.types';

const MarkdownText: FC<IMarkdownTextProps> = ({
  color,
  highlightFirstParagraph,
  text,
}) => {
  const theme = useTheme();
  const classes = useStyles(color)(theme);
  const { isDesktop } = useMuiBreakpoints();

  const mappedComponents: { [key: string]: ({}: IMarkdownTag) => ReactNode } = {
    a: ({ node, ...props }) => <Link {...props} />,
    h1: ({ node, ...props }) => <Typography variant={'h1'} {...props} />,
    h2: ({ node, ...props }) => <Typography variant={'h2'} {...props} />,
    h3: ({ node, ...props }) => <Typography variant={'h3'} {...props} />,
    h4: ({ node, ...props }) => <Typography variant={'h4'} {...props} />,
    h5: ({ node, ...props }) => <Typography variant={'h5'} {...props} />,
    h6: ({ node, ...props }) => <Typography variant={'h6'} {...props} />,
    img: ({ node, ...props }) => <img className={classes.image} {...props} />,
    li: ({ node, ...props }) => (
      <li className={classes.item}>
        <Typography variant={'body2'} {...props} />
      </li>
    ),
    ol: ({ node, ...props }) => <ol className={classes.list} {...props} />,
    p: ({ node, ...props }) => <Typography variant={'body2'} {...props} />,
    ul: ({ node, ...props }) => <ul className={classes.list} {...props} />,
  };

  return (
    <ReactMarkdown
      className={cn(
        classes.fontColor,
        highlightFirstParagraph
          ? isDesktop
            ? classes.firstParagraph
            : cn(classes.firstParagraph, classes.firstParagraphMobile)
          : ''
      )}
      components={mappedComponents}
      children={text ? text : ''}
    />
  );
};

export default MarkdownText;
