import { createStyles, makeStyles } from '@material-ui/core/styles';

import sectionComponentFontColor from '@Misc/helpers/theme/sectionComponentFontColor';

const useStyles = (color?: string, isDark?: boolean) =>
  makeStyles((theme) =>
    createStyles({
      fontColor: {
        color: sectionComponentFontColor(theme, color, isDark),
      },
    })
  );

export default useStyles;
