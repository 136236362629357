import { createSelector } from 'reselect';

import * as CONSTS from './../constants/constants';
import getDevice from './getDevice';

const isDesktop = createSelector(
  [getDevice],
  (device) => device === CONSTS.DESKTOP
);

export default isDesktop;
