import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getLocations } from '@Model/locations/actions';
import { getSelectedLocation } from '@Model/locations/selectors';
import { PagesEnum } from '@Model/pages/constants/pages';
import { getInspirations } from '@Model/pages/selectors';

import Inspirations from './Inspirations.component';
import {
  IInspirationsFromDispatch,
  IInspirationsFromState,
} from './Inspirations.types';

const mapStateToProps = (state: _Store.IState): IInspirationsFromState => ({
  structure: getInspirations(state),
  userLocation: getSelectedLocation(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IInspirationsFromDispatch => ({
  mounted: () => dispatch(getLocations(PagesEnum.inspirations)),
});

export default connect<
  IInspirationsFromState,
  IInspirationsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Inspirations);
