import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { set } from '@Model/breadcrumb/actions';

import Breadcrumb from './Breadcrumb.component';
import { IBreadcrumbFromDispatch } from './Breadcrumb.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IBreadcrumbFromDispatch => {
  return {
    set: (payload) => dispatch(set(payload)),
  };
};

export default connect<void, IBreadcrumbFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(Breadcrumb);
