import React from 'react';

import styles from './Loading.module.scss';

const ContentLoading = () => (
  <div className={styles.content}>
    <div className={styles.fakeHeader} />
    <div className={styles.fakeButton} />
  </div>
);

export default ContentLoading;
