import { includes } from 'lodash';

import config from '@Config';
import routes from '@Routes/routes';

/**
 * Function converting internal absolute URLs to routes.
 *
 * @param {string} url - URL to check and convert
 *
 * @return {string} converted route or the original URL
 */
const internalAbsoluteLinksToRoutes = (url: string) => {
  const pathname = url.split('/')[3];
  const cleanedPathname = pathname?.includes('?')
    ? pathname.split('?')[0]
    : pathname;
  const isRoute =
    url.includes(config.app.productionUrl) &&
    !!Object.values(routes).find((route) => route.includes(cleanedPathname));

  if (isRoute && !config.theme.isSbm) {
    const isAbsoluteInternal = includes(url, config.app.productionUrl);

    return isAbsoluteInternal ? url.replace(config.app.productionUrl, '') : url;
  }

  return url;
};

export default internalAbsoluteLinksToRoutes;
