import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IGiftCardActivationPayload } from '@Model/giftCard/types';
import { IGiftCardActivationResponse } from '@Services/$giftCard-api/types';

import * as CONSTS from './../constants/actions';

export const giftCardActivation = createStandardAction(
  CONSTS.GIFT_CARD_ACTIVATION
)<IGiftCardActivationPayload>();

// Private actions
export const activateGiftCard = createAsyncAction(
  CONSTS._GIFT_CARD_ACTIVATION_REQUEST,
  CONSTS._GIFT_CARD_ACTIVATION_SUCCESS,
  CONSTS._GIFT_CARD_ACTIVATION_FAILURE
)<IGiftCardActivationPayload, IGiftCardActivationResponse, Error>();
