import React from 'react';

import { Grid } from '@material-ui/core';

import EntryListStepper from '@Compo/EntryList';

import useStyles from './EntryList.styles';

const EntryList = () => {
  const classes = useStyles();

  return (
    <Grid
      container={true}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item={true}>
        <EntryListStepper />
      </Grid>
    </Grid>
  );
};

export default EntryList;
