import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isMobile, isTablet } from '@Model/app/selectors';
import { landingPageMounted } from '@Model/landingPage/actions';
import { getLandingPage } from '@Model/landingPage/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import LandingPage from './LandingPage.component';
import {
  ILandingPageFromDispatch,
  ILandingPageFromState,
} from './LandingPage.types';

const mapStateToProps = (state: _Store.IState): ILandingPageFromState => {
  return {
    isLoading: getLoading(CONST.LANDING_PAGE)(state),
    isMobile: isMobile(state),
    isTablet: isTablet(state),
    landingPage: getLandingPage(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ILandingPageFromDispatch => {
  return {
    mounted: () => dispatch(landingPageMounted()),
  };
};

export default connect<
  ILandingPageFromState,
  ILandingPageFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage);
