import { JSXElementConstructor } from 'react';

import config from '@Config';

import Online from './../Online';
import Onsite from './../Onsite';

let On_;

/*
 * We use in plain way reference to this var, because
 * during parsing by webpack it is replaced by boolean value and
 * evaluated. Thanks to tree-shaking build module which is not in use
 * is not included.
 */
if (config.app.onlineSale) {
  On_ = Online;
} else {
  On_ = Onsite;
}

export default On_ as JSXElementConstructor<unknown>;
