import isColorGrayScale from '@Misc/helpers/isColorGrayscale';

const padWithZeros = (str: string) => {
  const len = 2;
  const zeros = new Array(len).join('0');

  return (zeros + str).slice(-len);
};

const invertIfGrayScaleColor = (hex: string) => {
  if (hex) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }

    const red = parseInt(hex.slice(0, 2), 16);
    const green = parseInt(hex.slice(2, 4), 16);
    const blue = parseInt(hex.slice(4, 6), 16);

    let r;
    let g;
    let b;

    if (isColorGrayScale(red, green, blue, 5)) {
      r = (255 - red).toString(16);
      g = (255 - green).toString(16);
      b = (255 - blue).toString(16);
    } else {
      r = red.toString(16);
      g = green.toString(16);
      b = blue.toString(16);
    }

    return '#' + padWithZeros(r) + padWithZeros(g) + padWithZeros(b);
  } else {
    return '#ffffff';
  }
};

export default invertIfGrayScaleColor;
