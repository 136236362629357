import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundGradient: {
      background: 'linear-gradient(84.41deg, #FE839F -1.06%, #EFE80D 116.98%)',
      borderRadius: '1rem',
      padding: theme.spacing(2),
      color: '#ffffff',
    },
    container: {
      padding: '1.5rem',
    },
    containerColored: {
      padding: '1.5rem',
      backgroundColor: '#dadeff',
    },
    containerWithGradient: {
      padding: '2.5rem',
    },
    muiButtonMobile: {
      width: '100%',
    },
  })
);

export default useStyles;
