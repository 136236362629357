import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import parse, { domToReact } from 'html-react-parser';
import YouTube from 'react-youtube';

import MarkdownText from '@Compo/reusable/MarkdownText';
import Title from '@Compo/reusable/Title';

import styles from './Description.module.scss';
import useStyles from './Description.styles';
import { IDescriptionProps } from './Description.types';

const Description = ({
  html,
  hideApostrophe,
  text,
  title,
  isFullWidth,
  isVideo,
}: IDescriptionProps) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <div
      className={cn(
        styles.description,
        muiStyles.root,
        muiStyles.fontColor,
        isVideo && styles.noVideo,
        hideApostrophe && styles.hideApostrophe,
        isFullWidth && styles.fullWidth
      )}
    >
      {title && <Title title={title} customClassName={styles.defaultTitle} />}
      {html &&
        parse(html, {
          replace: (domNode) => {
            if (domNode.attribs && domNode.name === 'img') {
              return (
                <img
                  src={domNode.attribs['data-src'] || domNode.attribs.src}
                  className={styles.image}
                />
              );
            }
            if (domNode.name === 'h4' && domNode.children) {
              return (
                <h4 className={styles.title}>{domToReact(domNode.children)}</h4>
              );
            }
            if (
              domNode.attribs &&
              domNode.attribs['data-plyr-provider'] === 'youtube'
            ) {
              return (
                <YouTube
                  videoId={domNode.attribs['data-plyr-embed-id']}
                  containerClassName={styles.player}
                />
              );
            }
          },
        })}
      {!html && (
        <MarkdownText text={text ? text : ''} highlightFirstParagraph={true} />
      )}
    </div>
  );
};

export default Description;
