import { getType } from 'typesafe-actions';

import {
  getArtist,
  getClosestPool,
  getPastEvents,
  resetState,
} from './../actions';
import { IAction, IArtistReducer } from './../types';

const initialState: IArtistReducer = {
  closestEvents: [],
  closestPool: null,
  data: null,
  pastEvents: [],
};

const reducer = (
  state: IArtistReducer = initialState,
  action: IAction
): IArtistReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getArtist.success):
      return {
        ...state,
        data: action.payload,
      };

    case getType(getClosestPool.success):
      return {
        ...state,
        closestEvents: action.payload.closestPools,
        closestPool: action.payload.buyBox,
      };

    case getType(getPastEvents.success):
      return {
        ...state,
        pastEvents: action.payload,
      };

    case getType(resetState):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
