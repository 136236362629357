import { push } from 'connected-react-router';
import { EMPTY as EMPTY$, of as of$ } from 'rxjs';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { selectProduct } from '@Model/products/actions';
import { getSelectedProducts } from '@Model/products/selectors';
import routes from '@Routes/routes';
import PoolsSelector from '@Services/$pools-selector';

import {
  selectPool,
  selectTicket,
  updateSelectedSeats,
  updateSelectedTickets,
} from './../actions';
import { getSelectedTickets, getSelectedTicketsBySeats } from './../selectors';

export const updateSelectedTicketsWhenAmountClicked: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(selectTicket)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedTickets = getSelectedTickets(state);
      const selectedProducts = getSelectedProducts(state);
      const {
        poolId,
        amount,
        poolName,
        price,
        serviceFee,
        additionalFields,
        forms,
        submissions,
        paymentMethods,
        currency,
      } = action.payload;

      const updatedSelected = PoolsSelector.updateSelected(
        selectedTickets,
        poolId,
        amount,
        poolName,
        currency,
        price,
        serviceFee,
        additionalFields,
        forms,
        submissions,
        paymentMethods
      );

      const foundedProduct = selectedProducts.find((product) =>
        product.poolIds?.includes(poolId)
      );

      if (amount === 0 && foundedProduct) {
        return of$(
          updateSelectedTickets(updatedSelected),
          selectProduct({ amount: 0, product: foundedProduct })
        );
      }

      return of$(updateSelectedTickets(updatedSelected));
    })
  );
};

export const updateSelectedTicketsWhenSeatsSelectedUpdate: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(updateSelectedSeats)),
    withLatestFrom$(state$),
    map$(([_, state]) => {
      const updatedSelected = getSelectedTicketsBySeats(state);

      return updateSelectedTickets(updatedSelected);
    })
  );
};

export const redirectWhenSelectPool: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(selectPool)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return of$(
        push(
          fillUrlWithValues(
            state.locale.selectedLang === 'en' ? routes.engBuy : routes.buy,
            [':eventSlug', ':rundateSlug'],
            [action.payload.eventSlug, action.payload.rundateSlug]
          )
        )
      );
    })
  );
};

export const removeProductWhenDeselectTicket: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(updateSelectedTickets)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedProducts = getSelectedProducts(state);

      const foundedProduct = selectedProducts.filter(
        (product) =>
          !action.payload.some((selectedTicket) =>
            product.poolIds?.includes(selectedTicket.poolId)
          )
      )[0];

      if (foundedProduct) {
        return of$(selectProduct({ amount: 0, product: foundedProduct }));
      }

      return EMPTY$;
    })
  );
};
