import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { generateIdempotencyKey, setDay } from '@Model/happening/actions';
import { getLocation } from '@Model/router/selectors';

import Calendar from './Calendar.component';
import { ICalendarFromDispatch, ICalendarFromState } from './Calendar.types';

const mapStateToProps = (state: _Store.IState): ICalendarFromState => {
  return {
    location: getLocation(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ICalendarFromDispatch => {
  return {
    generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
    setDay: (day) => dispatch(setDay(day)),
  };
};

export default connect<
  ICalendarFromState,
  ICalendarFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);
