import React from 'react';

import styles from './../../DatePicker.module.scss';
import { IHeaderProps } from './../../DatePicker.types';
import Days from './../Days';

const months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];

const Header = ({ month, year, fromOrTo, setMonth }: IHeaderProps) => {
  return (
    <div className={styles.header}>
      <div className={styles.controls}>
        <button onClick={() => setMonth(-1, fromOrTo)}>&laquo;</button>
        <span>
          {months[month]} {year}
        </span>
        <button onClick={() => setMonth(1, fromOrTo)}>&raquo;</button>
      </div>
      <Days />
    </div>
  );
};

export default Header;
