import { createSelector } from 'reselect';

import config from '@Config';
import { getParams } from '@Model/internalRouter/selectors';
import { IPlaceMatchParams } from '@Routes/types';
import PlacesApiService from '@Services/$places-api';

import getData from './getData';
import getRequestTimes from './getRequestTimes';

/*
 * Selector returns 3-values logic.
 *
 * string when place exists
 * false when place doesnt exist
 * null when data is missing
 */
const getKeyWhenPlaceExistsAndIsFresh = createSelector(
  [getParams, getData, getRequestTimes],
  (params, data, requestTimes) => {
    if (params) {
      const key = PlacesApiService.makeKeyFromParams(
        params as IPlaceMatchParams
      );

      if (
        key in data &&
        key in requestTimes &&
        requestTimes[key] + config.app.howOldCanBeStaticPage > Date.now()
      ) {
        return key;
      } else {
        return false;
      }
    }

    return null;
  }
);

export default getKeyWhenPlaceExistsAndIsFresh;
