import { eventSelectItem } from '@/models/analytics/actions';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { ISelectItemEvent } from '@Model/analytics/types';

import EventTile from './EventTile.component';
import { IEventTileElementFromDispatch } from './EventTile.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IEventTileElementFromDispatch => {
  return {
    eventSelectItem: (event: ISelectItemEvent) =>
      dispatch(eventSelectItem(event)),
  };
};

export default connect<{}, IEventTileElementFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(EventTile);
