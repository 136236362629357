import React from 'react';

import cn from 'classnames';

import LoadingContent from '@Compo/Events/components/LoadingContent';

import styles from './Loading.module.scss';
import { ILoadingProps } from './Loading.types';

const Loading = ({ forcedVertical }: ILoadingProps) => {
  return (
    <div
      className={cn(
        styles.loading,
        forcedVertical ? styles.forcedVertical : styles.normal
      )}
    >
      <div className={styles.fakeImage} />
      <LoadingContent
        className={styles.fakeDetails}
        forcedVertical={forcedVertical}
      />
    </div>
  );
};

export default Loading;
