import React, { FC, useContext, useState } from 'react';

import cn from 'classnames';

import ButtonDescription from '@Compo/Event/components/Tickets/ButtonDescription';

import { clearContext } from './../../BasketProvider/BasketProvider';
import styles from './BasketItemPool.module.scss';
import useStyles from './BasketItemPool.styles';
import { IBasketItemPoolProps } from './BasketItemPool.types';

const BasketItemPool: FC<IBasketItemPoolProps> = ({
  basketItemPool,
  isDesktop,
  lang,
}) => {
  const state = useContext(clearContext);

  const muiStyles = useStyles();

  const [showInfo, setShowInfo] = useState(false);

  const close = () => {
    state?.removeFromBag(basketItemPool.id);
  };

  return (
    <div className={styles.container}>
      <div className={cn(styles.line, styles.poolNameContainer)}>
        <p className={styles.poolName}>{basketItemPool.poolName}</p>
        {!isDesktop && <span className={styles.close} onClick={close} />}
      </div>
      {!isDesktop && (
        <div className={styles.info}>
          <ButtonDescription
            buttonText={lang.details}
            onClick={() => setShowInfo(!showInfo)}
            buttonHideText={lang.hide}
          />
        </div>
      )}
      {(showInfo || isDesktop) && (
        <div className={styles.infoContainer}>
          <div className={cn(styles.line, styles.row)}>
            <p>{lang.pricePerItem}</p>
            <p className={styles.pricePerItem}>
              {basketItemPool.poolPrice}{' '}
              <span className={styles.currency}>{basketItemPool.currency}</span>
            </p>
          </div>
          <div className={styles.line}>
            <div className={styles.serviceFeeContainer}>
              <p className={muiStyles.typographyWithTooltip}>
                {lang.serviceFee}
              </p>
              <p className={styles.serviceFee}>
                {(basketItemPool.serviceFee || 0).toFixed(2)}{' '}
                {basketItemPool.currency}
              </p>
            </div>
          </div>

          <div
            className={cn(
              styles.line,
              styles.row,
              styles.ticketAmountContainer
            )}
          >
            {!isDesktop && <p>{lang.ticketsNum}</p>}
            <p className={styles.ticketAmount}>
              {basketItemPool.amount}
              {isDesktop && (
                <span className={styles.pieces}> {lang.pieces}</span>
              )}
            </p>
          </div>
        </div>
      )}
      <div className={cn(styles.line, styles.totalPriceContainer, styles.row)}>
        <p className={styles.summaryPrice}>{lang.totalPrice}</p>
        <p className={cn(styles.price, styles.totalPrice)}>
          {basketItemPool.poolPriceWithFee?.toFixed(2)}{' '}
          <span className={styles.currency}>{basketItemPool.currency}</span>
        </p>
      </div>
      <div className={styles.deleteContainer}>
        {isDesktop && <span className={styles.close} onClick={close} />}
      </div>
    </div>
  );
};

export default BasketItemPool;
