import { defaultMemoize } from 'reselect';
import { number, object, string } from 'yup';

import { ILang } from '@Model/locale/types';

const parentData = (lang: ILang) => {
  const validators = {
    firstName: string().required(lang.requiredField),
    lastName: string().required(lang.requiredField),
    phoneNumber: number()
      .required(lang.requiredField)
      .typeError(lang.incorrectPhoneNumber),
  };

  return object().shape(validators);
};

export const makeValidationSchema = (lang: ILang) => {
  const validationSchema = () => parentData(lang);

  return defaultMemoize(validationSchema);
};
