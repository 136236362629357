import React from 'react';

import config from '@Config';
import highlightWordInText from '@Misc/helpers/highlightWordInText';
import premiumInfoIcon from '@Styles/images/premium-info.svg';
import premiumStarIcon from '@Styles/images/premium-star.svg';

import styles from './PremiumBadge.module.scss';

const BADGE_TEXT = 'Premium';
const INFO_TEXT = 'Pokaż informacje';
const PREMIUM_LINK_TEXT = 'Sprawdź ofertę premium';
const TOOLTIP_TEXT =
  'Aby skorzystać z benefitów Premium podaj numer swojej karty Mój Empik podczas finalizacji zakupu';
const WORD_TO_HIGHLIGHT = 'Premium';

const textWithHighlight = highlightWordInText(TOOLTIP_TEXT, WORD_TO_HIGHLIGHT);

const PremiumBadge = () => (
  <div className={styles.premiumBadge}>
    <img src={premiumStarIcon} alt={BADGE_TEXT} className={styles.starIcon} />
    {BADGE_TEXT}

    <div className={styles.tooltip}>
      <img src={premiumInfoIcon} alt={INFO_TEXT} />

      <div className={styles.tooltipText}>
        {textWithHighlight}
        <a href={config.premium.url}>{PREMIUM_LINK_TEXT}</a>
      </div>
    </div>
  </div>
);

export default PremiumBadge;
