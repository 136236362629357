import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$ } from 'rxjs';
import {
  filter as filter$,
  map as map$,
  take as take$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { resetAllLoading } from '@Model/state/actions';

import { appIsReady, setDevice, setSizes, setUserAgent } from './../actions';
import { IActionDevice } from './../types';

const ssrIsEnabled = /true/i.test(
  process.env.REACT_APP_ENABLE_SSR_FOR_ALL_USERS || ''
);

export const setDeviceWhenSizesChangedEpic: _Store.IEpic = (
  action$,
  _,
  { deviceDetector }
) => {
  return action$.pipe(
    filter$(isActionOf(setSizes)),
    map$((action) => {
      return setDevice(
        deviceDetector.getDevice(action.payload) as IActionDevice
      );
    })
  );
};

export const determineMobileUserAgent: _Store.IEpic = (
  action$,
  state$,
  { deviceDetector }
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    map$(() => {
      return setUserAgent(deviceDetector.determineMobileUserAgent());
    })
  );
};

export const resetLoadingsAlwaysOnLocationChange: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    map$(() => resetAllLoading())
  );
};

export const informThatAppIsReady: _Store.IEpic = (action$) => {
  if (!ssrIsEnabled) {
    return EMPTY$;
  }

  return action$.pipe(
    take$(1),
    map$(() => {
      return appIsReady();
    })
  );
};
