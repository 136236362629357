import React, { FC } from 'react';

import SectionLead from '@Compo/Bricks/components/LeadSection';
import CardDeck from '@Compo/reusable/CardDeck';
import { BricksEnum } from '@Model/pages/constants/bricks';
import { useDynamicListings } from '@Model/pages/hooks/useDynamicListings';
import { ICloudsearchCardData } from '@Model/pages/types';
import { ISectionsComponentExtendedHotSpot } from '@Model/pages/types/sections';
import sectionsApi from '@Services/$sections-api';

import { IMultilineCardsProps } from './MultilineCards.types';

const MORE_BUTTON_DEFAULT_TEXT = 'Zobacz więcej';

const MultilineCards: FC<IMultilineCardsProps> = ({
  background,
  data,
  color,
  fixedWidthCards,
  hotDeals,
  id,
  isHorizontal,
  maxInRow,
  moreButton,
  moreButtonText = MORE_BUTTON_DEFAULT_TEXT,
  sectionDescription,
  sectionTitle,
  isLoading,
  compositionIdentifier,
  userLocation,
  extraFlags,
}) => {
  const cardData =
    !!data?.length && 'cloudsearch' in data[0]
      ? undefined
      : (data as ISectionsComponentExtendedHotSpot[]);
  const cloudsearchCardData =
    !!data?.length && 'cloudsearch' in data[0]
      ? (data as ICloudsearchCardData[])
      : undefined;

  const { events } = useDynamicListings({
    cloudsearchQuery:
      cloudsearchCardData && cloudsearchCardData[0].cloudsearchQuery,
    userLocation,
    componentId: id,
  });

  const cards =
    events && !!data?.length && 'cloudsearch' in data[0]
      ? sectionsApi.normalizeCloudsearchResponseToCardData(events, extraFlags)
      : cardData
      ? sectionsApi.normalizeElementsToCardData(cardData, extraFlags)
      : [];

  return (
    <>
      <SectionLead
        background={background}
        color={color}
        description={sectionDescription}
        medium={true}
        showDropdown={false}
        title={sectionTitle}
      />
      <CardDeck
        background={background}
        cards={cards}
        color={color}
        fixedWidthCards={fixedWidthCards}
        hotDeals={hotDeals}
        id={id}
        forceHorizontalOnMobile={isHorizontal}
        maxInRow={maxInRow}
        isLoading={isLoading}
        children={moreButton && <span>{moreButtonText}</span>}
        compositionIdentifier={compositionIdentifier}
        type={BricksEnum.multiline_hotspots}
      />
    </>
  );
};

export default MultilineCards;
