import { createSelector } from 'reselect';

import _Store from '@Store';

import { IInternalRouterParams, IInternalRouterReducer } from './../types';
import get from './get';

const getParams = createSelector<
  _Store.IState,
  IInternalRouterReducer,
  IInternalRouterParams
>([get], (router) => router.internalRouter.params);

export default getParams;
