export const GET_CUSTOM_PAGE = 'Empik/pages/GET_CUSTOM_PAGE';
export const GET_ABOUT_PAGE = 'Empik/pages/GET_ABOUT_PAGE';
export const GET_MAIN_PAGE = 'Empik/pages/GET_MAIN_PAGE';
export const GET_URL_STRUCTURE = 'Empik/pages/GET_URL_STRUCTURE';
export const OPEN_PAGE_AUTH = 'Empik/pages/OPEN_PAGE_AUTH';
export const AUTHORIZE_PAGE = 'Empik/pages/AUTHORIZE_PAGE';
export const RESET_AUTH_ERROR = 'Empik/pages/RESET_AUTH_ERROR';
export const SET_AUTH_ERROR = 'Empik/pages/SET_AUTH_ERROR';

// Private
export const _GET_SECTIONS_REQUEST = 'Empik/pages/_GET_SECTIONS_REQUEST';
export const _GET_SECTIONS_SUCCESS = 'Empik/pages/_GET_SECTIONS_SUCCESS';
export const _GET_SECTIONS_FAILURE = 'Empik/pages/_GET_SECTIONS_FAILURE';
