export const putAfterSubmitAction = (
  url: string,
  callback: () => void,
  errorCallback: (errorMessage: string) => void
) => {
  fetch(url, {
    headers: {
      'content-type': 'application/json',
    },
    method: 'PUT',
  })
    .then((response) => {
      if (
        response &&
        response.status &&
        response.status >= 200 &&
        response.status < 300
      ) {
        callback();
      } else {
        return response;
      }
    })
    .then((response: any) => {
      if (response) {
        return response.json();
      }
    })
    .then((response: any) => {
      if (response && response.errors && response.errors['Error:0']) {
        errorCallback(response.errors['Error:0']);
      } else {
        callback();
      }
    })
    .catch(() => errorCallback(''));
};

export const getValuesFromObjectToUrl = (customObject: {
  [_: string]: string;
}): string => {
  const keys = Object.keys(customObject);
  if (keys && keys.length) {
    return keys.map((key) => `${key}=${customObject[key]}`).join('&');
  }

  return '';
};
