import React from 'react';

import AgreementExist from './components/AgreementExist';
import CheckSubmission from './components/CheckSubmission';
import Greeting from './components/Greeting';
import ParentData from './components/ParentData';
import PersonalData from './components/PersonalData';
import SelectSubmission from './components/SelectSubmission';
import SmsCode from './components/SmsCode';
import Starter from './components/Starter';
import SubmissionsList from './components/SubmissionsList';
import Summary from './components/Summary';
import TicketCode from './components/TicketCode';

enum stepNames {
  starter = 'starter',
  personalData = 'personalData',
  summary = 'summary',
  greeting = 'greeting',
  parentData = 'parentData',
  smsCode = 'smsCode',
  agreementExist = 'agreementExist',
  ticketCode = 'ticketCode',
  submissionsList = 'submissionsList',
  checkSubmission = 'checkSubmission',
  selectSubmission = 'selectSubmission',
}

export const steps = [
  {
    name: stepNames.starter,
    component: () => <Starter />,
  },
  {
    name: stepNames.checkSubmission,
    component: () => <CheckSubmission />,
  },
  {
    name: stepNames.ticketCode,
    component: () => <TicketCode />,
  },
  {
    name: stepNames.selectSubmission,
    component: () => <SelectSubmission />,
  },

  {
    name: stepNames.personalData,
    component: () => <PersonalData />,
  },
  {
    name: stepNames.summary,
    component: () => <Summary />,
  },
  {
    name: stepNames.parentData,
    component: () => <ParentData />,
  },
  {
    name: stepNames.smsCode,
    component: () => <SmsCode />,
  },
  { name: stepNames.greeting, component: () => <Greeting /> },
  {
    name: stepNames.agreementExist,
    component: () => <AgreementExist />,
  },
  {
    name: stepNames.submissionsList,
    component: () => <SubmissionsList />,
  },
];

export default stepNames;
