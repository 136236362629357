import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';
import { EmptyAction } from 'typesafe-actions/dist/type-helpers';

import _Store from '@Store';

import { getEvent, setCurrentKey } from '@Model/event/actions';
import { getKeyWhenEventExistsAndIsFresh } from '@Model/event/selectors';
import { loadImages } from '@Model/images/actions';
import { getModule, getParams } from '@Model/internalRouter/selectors';
import { getSelectedLocale } from '@Model/locale/selectors';
import { getProductsPools } from '@Model/products/actions';
import { getLocation } from '@Model/router/selectors';
import { resetSkipNextRequest } from '@Model/state/actions';
import * as CONSTS from '@Model/state/constants/constants';
import { getSkipNextRequest } from '@Model/state/selectors';
import * as MODULES from '@Routes/modules';
import { IBuyMatchParams } from '@Routes/types';
import { IPoolsResponse } from '@Services/$pools-api/types';
import { IPaymentMethod } from '@Services/$transactions-api/types';

import {
  getClosestPools,
  getPools,
  poolsMounted,
  resetState,
  resetTicketsState,
  setPaymentMethods,
} from './../actions';

/*
 * When we visit buy page we want to load event only when it is necessary.
 * Every time we load pools except it is loaded by server.
 * We always should display the newest data.
 */
export const getPoolsWhenPoolsComponentMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(poolsMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const mergedActions: Array<EmptyAction<any>> = [];
      const modules = getModule(state);
      const shouldSkipNextRequestForPools = getSkipNextRequest(CONSTS.POOLS)(
        state
      );
      const params = getParams(state) as IBuyMatchParams;

      const disableActivityPoolRequest =
        !params.rundateSlug || params.eventSlug === params.rundateSlug;

      if (shouldSkipNextRequestForPools || disableActivityPoolRequest) {
        mergedActions.push(resetSkipNextRequest(CONSTS.POOLS));
      } else {
        if (modules === MODULES.EVENT) {
          mergedActions.push(getClosestPools.request());
        } else if (params.eventSlug && params.rundateSlug) {
          mergedActions.push(getPools.request(params));
        }
      }

      const keyOfExistedEvent = getKeyWhenEventExistsAndIsFresh(state);

      if (!keyOfExistedEvent && modules !== MODULES.EVENT) {
        mergedActions.push(getEvent.request(), loadImages());
      }

      if (keyOfExistedEvent && modules === MODULES.BUY) {
        mergedActions.push(setCurrentKey(keyOfExistedEvent));
      }

      return mergedActions;
    })
  );
};

/*
 * When we left buy page we want to reset data for transaction.
 */
export const resetStateWhenBuyRouteLeft: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getModule(state) === MODULES.BUY &&
        !getSkipNextRequest(CONSTS.POOLS)(state)
    ),
    mergeMap$(() => {
      return of$(resetState(), resetTicketsState());
    })
  );
};

/*
 * This epic loads data from API for pools.
 */
export const fetchPoolsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { poolsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPools.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (action.payload) {
        const language = getSelectedLocale(state);
        const isPreview =
          getLocation(state).query?.preview === 'true' ||
          getLocation(state).query?.preview === '1' ||
          getLocation(state).query?.preview === 'on' ||
          getLocation(state).query?.preview === 'yes';

        return from$(
          poolsApi.getPools({
            eventSlug: action.payload.eventSlug,
            isPreview,
            language,
            rundateSlug: action.payload.rundateSlug,
          })
        ).pipe(
          mergeMap$((data: IPoolsResponse) => {
            const payments = data.pools.map((pool) => pool.payments);
            const concatValues: IPaymentMethod[] = (
              [] as IPaymentMethod[]
            ).concat.apply([], payments);

            const uniquePayments = concatValues.reduce(
              (acc, payment) =>
                acc.concat(
                  acc.find((array) => array.type === payment.type)
                    ? []
                    : [payment]
                ),
              [] as IPaymentMethod[]
            );

            return of$(
              getPools.success({
                poolsData: poolsApi.normalize(data),
              }),
              getProductsPools.request(),
              setPaymentMethods(uniquePayments)
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => poolsApi.cancelPools())
            )
          ),
          catchError$((error: Error) => {
            const module = getModule(state);
            if (module === MODULES.EVENT) {
              return EMPTY$;
            }

            return of$(getPools.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const fetchClosestPoolsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { poolsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getClosestPools.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as IBuyMatchParams;

      return from$(poolsApi.getClosestPools(params.eventSlug)).pipe(
        mergeMap$((data) => {
          const array: any = data.map((pool) =>
            getPools.request({
              eventSlug: pool.eventSlug,
              rundateSlug: pool.rundateSlug,
            })
          );

          return of$(getClosestPools.success(data), ...array);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => poolsApi.cancelPools())
          )
        ),
        catchError$((error: Error) => {
          const module = getModule(state);
          if (module === MODULES.EVENT) {
            return EMPTY$;
          }

          return of$(getClosestPools.failure(error));
        })
      );
    })
  );
};
