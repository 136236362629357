import { getType } from 'typesafe-actions';

import {
  getPremiumUserByToken,
  getUserCardStatus,
  setToken,
} from './../actions';
import { IAction, IPremiumReducer, UserCardStatusEnum } from './../types';

const initialState: IPremiumReducer = {
  token: null,
  user: null,
  userCardData: null,
  userCardStatus: UserCardStatusEnum.NODATA,
};

const reducer = (
  state: IPremiumReducer = initialState,
  action: IAction
): IPremiumReducer => {
  switch (action.type) {
    case getType(getPremiumUserByToken.success):
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };

    case getType(getUserCardStatus.request):
      return {
        ...state,
        userCardData: {
          ...action.payload,
        },
      };

    case getType(getUserCardStatus.success):
      return {
        ...state,
        userCardStatus: action.payload,
      };

    case getType(setToken):
      return {
        ...state,
        token: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
