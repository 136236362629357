import { Selector } from 'reselect';

import _Store from '@Store';

import { IEntryListReducer } from './../types';

const get: Selector<_Store.IState, IEntryListReducer> = (state) =>
  state.entryList;

export default get;
