import { LOCATION_CHANGE, getLocation, push } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import { takeUntil as takeUntil$ } from 'rxjs/internal/operators/takeUntil';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { getParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import routes from '@Routes/routes';
import { ISelectionMatchParams } from '@Routes/types';
import { ISelectionResponse } from '@Services/$selection-api/types';

import {
  getSelection,
  getSelectionSlug,
  selectionPageMounted,
} from './../actions';

export const getSelectionWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(selectionPageMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return [getSelection.request()];
    })
  );
};

export const fetchSelectionWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { selectionApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSelection.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as ISelectionMatchParams;
      const dateTranslate = translate(state)('dates');

      if (params) {
        return from$(selectionApi.getSingleSelection(params.slug)).pipe(
          mergeMap$((data: ISelectionResponse) => {
            const normalizedSelection = selectionApi.normalizeShortSelection(
              data,
              dateTranslate
            );
            const normalizedSelectionKey = selectionApi.makeKeyFromParams(
              normalizedSelection.slug
            );

            return [
              getSelection.success({
                key: normalizedSelectionKey,
                selection: normalizedSelection,
              }),
            ];
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => selectionApi.cancelSelection())
            )
          ),
          catchError$((error: Error) => of$(getSelection.failure(error)))
        );
      }

      return EMPTY$;
    }),
    catchError$((error: Error) => of$(getSelection.failure(error)))
  );
};

export const redirectSelectionWhenLocationChange: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] ===
        routes.selectionLegacy.split('/')[1]
    ),
    mergeMap$(([_, state]) => {
      return of$(
        getSelectionSlug.request(
          Number(getLocation(state).pathname.split('/')[2])
        )
      );
    })
  );
};

export const getSelectionSlugWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { selectionApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSelectionSlug.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(selectionApi.getSingleSelectionById(action.payload)).pipe(
        map$((data) => {
          const dateTranslate = translate(state)('dates');

          const normalizedSelection = selectionApi.normalizeShortSelection(
            data,
            dateTranslate
          );
          const normalizedSelectionKey = selectionApi.makeKeyFromParams(
            normalizedSelection.slug
          );

          return getSelectionSlug.success({
            key: normalizedSelectionKey,
            selection: normalizedSelection,
          });
        }),
        catchError$((error: Error) =>
          of$(getSelectionSlug.failure(error), push(routes.index))
        )
      );
    })
  );
};

export const redirectSelectionWhenSelectionSlugSuccess: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(getSelectionSlug.success)),
    mergeMap$((action) => {
      return of$(
        push(
          fillUrlWithValues(
            routes.selection,
            ':slug',
            action.payload.selection.slug
          )
        )
      );
    })
  );
};
