import {
  IArtistSliderBrickOwnProps,
  IArtistSliderExtraData,
} from '@Compo/Bricks/components/ArtistSliderBrick/ArtistSliderBrick.types';
import {
  ISectionsComponentArtistSlider,
  ISectionsComponentDataMutable,
} from '@Model/pages/types/sections';

export const artistSlider = (
  data: ISectionsComponentDataMutable,
  extra?: IArtistSliderExtraData | null,
  bgColor?: string
): IArtistSliderBrickOwnProps => {
  const artists = data as ISectionsComponentArtistSlider[];

  return {
    artists,
    bgColor,
    color: extra?.color,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
  };
};
