import { selectorFamily } from 'recoil';

import { EventsApi } from '@Services/$events-api/EventsApi';

const getEvent = selectorFamily({
  get: (path: string) => async () => {
    const slugs = path.split('/');

    return await EventsApi.getEvent(slugs[2], slugs[3]);
  },
  key: 'getEvent',
});

export default getEvent;
