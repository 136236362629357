import React, { useEffect } from 'react';

import Bricks from '@Compo/Bricks';
import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import AboutContent from '@Compo/statics/About';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import { IAboutPageProps } from './About.types';

const ABOUT_TEXT = 'O aplikacji';

const About = ({ structure, mounted, userLocation }: IAboutPageProps) => {
  const { isMobile } = useMuiBreakpoints();

  useEffect(() => {
    mounted();
  }, []);

  return (
    <MuiGrid withoutSidePaddings={true}>
      <Helmet pageName={ABOUT_TEXT} />
      <FullTriangleBackground />
      {isMobile ? (
        <AboutContent />
      ) : (
        <Bricks userLocation={userLocation} structure={structure} />
      )}
    </MuiGrid>
  );
};

export default About;
