import React, { useEffect } from 'react';

import cn from 'classnames';

import Link from '@Compo/reusable/DualLink';
import TicketDataForm from '@Compo/ticketsManagement/TicketDataForm';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import icons from '@Misc/helpers/icons';
import routes from '@Routes/routes';

import styles from './MyTickets.module.scss';
import { IMyTicketsProps } from './MyTickets.types';

const MyTickets = ({ lang, tickets, history }: IMyTicketsProps) => {
  useEffect(() => {
    if (tickets?.length === 1) {
      history.push(
        fillUrlWithValues(
          routes.myTicketEdit,
          ':ticketId',
          `${tickets[0].ticketId}`
        )
      );
    }
  }, [tickets]);

  if (!tickets) {
    return <TicketDataForm />;
  }

  return (
    <>
      <h2 className={styles.pageTitle}>{lang.pageTitle}</h2>
      <ul className={styles.ticketListWrapper}>
        {tickets.map((ticket) => (
          <li className={styles.ticketContainer} key={ticket.code}>
            <div className={styles.column}>
              <span className={cn(styles.ticketIcon, icons.ticket)} />
              <div className={styles.eventDetails}>
                <h2>{ticket.event.title}</h2>
                <p className={styles.date}>{ticket.event.friendlyDate}</p>
                <p>
                  {ticket.event.placeName}, {ticket.event.placeCityName}
                </p>
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.display}>
                <p className={styles.count}>{ticket.numberOfTickets}</p>
                <p className={styles.code}>{ticket.code}</p>
              </div>
              <Link
                className={styles.button}
                to={fillUrlWithValues(
                  routes.myTicketEdit,
                  ':ticketId',
                  `${ticket.ticketId}`
                )}
              >
                {lang.choose}
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MyTickets;
