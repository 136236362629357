import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISearchReducer } from '@Model/search/types';

import get from './get';

const getLastFilter = createSelector<
  _Store.IState,
  ISearchReducer,
  string | undefined
>([get], (search) => search.lastFilter);

export default getLastFilter;
