import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      pointerEvents: 'none',
      opacity: 0.6,
    },
    label: {
      alignItems: 'center',
      display: 'flex !important',
    },
    tooltip: {
      marginLeft: theme.spacing(1),
      width: '0.75rem',
    },
  })
);

export default useStyles;
