import { CloudinaryPrefixes } from '@Constants/Cloudinary';
import { IArtist } from '@Types/Artist';
import { IPlace, ITag } from '@Types/EventCommon';
import { FavoritesTypes, IFavorite } from '@Types/Favorites';

export class DataMutations {
  public static artistsToFavorites = (data?: IArtist[]): IFavorite[] => {
    if (!data) return [];

    return data.map((item) => ({
      id: item.id,
      externalId: item.id,
      type: FavoritesTypes.Artists,
      name: item.name,
      imagePath: item.slug,
      slug: item.slug,
    }));
  };

  public static placeToFavorite = (data?: IPlace): IFavorite | null => {
    if (!data) return null;

    return {
      externalId: data.id,
      type: FavoritesTypes.Places,
      name: data.name,
      imagePath: data.thumb ?? '',
      slug: data.slug,
      extraInfo: data.address,
    };
  };

  public static tagsToFavorites = (tags?: ITag[]): IFavorite[] =>
    tags?.map((tag) => ({
      id: Number(tag.id),
      externalId: Number(tag.id),
      type: FavoritesTypes.Tags,
      name: tag.name,
      imagePath: tag.slug,
      slug: tag.slug,
    })) || [];

  public static favoriteCloudinaryPrefix = (type: FavoritesTypes) => {
    switch (type) {
      case FavoritesTypes.Artists:
        return CloudinaryPrefixes.ARTIST;
      case FavoritesTypes.Places:
        return CloudinaryPrefixes.PLACE;
      case FavoritesTypes.Rundates:
        return CloudinaryPrefixes.EVENT;
      case FavoritesTypes.Tags:
        return CloudinaryPrefixes.TAG;
      default:
        return null;
    }
  };
}
