import { createSelector } from 'reselect';

import _Store from '@Store';

import {
  IPlaceDetails,
  IPlaceReducerCurrentKey,
  IPlaceReducerData,
} from './../types';
import getCurrentKey from './getCurrentKey';
import getData from './getData';

const getPlace = createSelector<
  _Store.IState,
  IPlaceReducerCurrentKey,
  IPlaceReducerData,
  IPlaceDetails | null
>([getCurrentKey, getData], (key, data) => {
  if (key && key in data) {
    return data[key] as IPlaceDetails;
  }

  return null;
});

export default getPlace;
