import React from 'react';

import { useTheme } from '@material-ui/core';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import ImageFixed from '@Compo/reusable/ImageFixed';
import getNonEmptyObject from '@Misc/helpers/getNonEmptyObject';
import prepareUrlForRoute from '@Misc/helpers/prepareUrlForRoute';
import translatableDate from '@Misc/helpers/translatableDate';
import { IEventFull } from '@Model/event/types';
import routes from '@Routes/routes';

import styles from './Details.module.scss';
import useStyles from './Details.styles';
import { IDetailsProps } from './Details.types';
import Loading from './components/Loading';

const Details = ({
  dateTranslate,
  event: potentialEvent,
  isBasketPay,
  isProductsPay,
  isLoading,
}: IDetailsProps) => {
  const event = getNonEmptyObject(potentialEvent) as IEventFull;
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  if (isBasketPay || isProductsPay) return null;

  return (
    <div className={cn(styles.eventDetails, muiStyles.root, muiStyles.radius)}>
      <ImageFixed
        src={event.externalImages?.[0] || event.imagesUrl}
        title={event.title}
        transformation={{
          height: 700,
          width: 810,
        }}
      />
      {isLoading && <Loading />}

      {!isLoading && (
        <div className={styles.content}>
          <h1 className={styles.title}>{event.title}</h1>

          <div className={styles.infoBox}>
            <h2 className={styles.info}>
              {translatableDate(
                event.startDate,
                event.rundateDescription,
                dateTranslate
              )}
            </h2>
            {event.friendlyHour && (
              <h2 className={styles.info}>{event.friendlyHour}</h2>
            )}
            {event.placeName && (
              <h3 className={styles.info}>
                {event.placeSlug && (
                  <Link
                    to={prepareUrlForRoute(routes.place, event.placeSlug)}
                    className={muiStyles.fontColor}
                  >
                    {event.placeName}, {event.placeCityName}
                  </Link>
                )}
                {!event.placeSlug &&
                  `${event.placeName}, ${event.placeCityName}`}
              </h3>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
