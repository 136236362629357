import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

import { IShipmentDeliveryResponse } from './types';

class ShipmentApi {
  private static getShipmentDeliveryUrl(): string {
    return `${config.api.baseUrl}shipment-delivery`;
  }

  private cancelTokenShipmentDelivery?: CancelTokenSource;

  public getShipmentDelivery(): Promise<IShipmentDeliveryResponse[]> {
    return new Promise((resolve, reject) => {
      const shipmentUri = ShipmentApi.getShipmentDeliveryUrl();
      this.cancelTokenShipmentDelivery = axios.CancelToken.source();

      axios
        .post(shipmentUri)
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelShipmentDelivery() {
    if (this.cancelTokenShipmentDelivery) {
      this.cancelTokenShipmentDelivery.cancel();
      this.cancelTokenShipmentDelivery = undefined;
    }
  }
}

export default new ShipmentApi();
