import React, { FC } from 'react';

import cn from 'classnames';

import ReusableTags from '@Compo/reusable/Tags';
import Loading from '@Compo/reusable/loadings/TagsLoading';

import styles from './Tags.module.scss';
import { IPlaceTags } from './Tags.types';

const Tags: FC<IPlaceTags> = ({ isLoading, tags, topLine }) => {
  const tagBar = topLine ? cn(styles.tagBar, styles.topLine) : styles.tagBar;

  return (
    <div className={styles.tags}>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className={tagBar}>
          <div className={styles.tags}>
            <ReusableTags tags={tags} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tags;
