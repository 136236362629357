import * as React from 'react';

import { Button, IconButton, Typography } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { Dialog, Grid } from '@mui/material';
import useSWR from 'swr';

import { IPurchaseFollowProps } from '@Compo/PurchaseFollow/PurchaseFollow.types';
import { FavoriteListItem } from '@Compo/PurchaseFollow/components/FavoriteListItem';
import { useToggle } from '@Misc/hooks/useToggle';
import { useDictionary } from '@Recoil/lang/hooks/useDictionary';
import { EventsApi } from '@Services/$events-api/EventsApi';
import { DataMutations } from '@Services/DataMutations';
import { Fetcher } from '@Services/Fetcher';
import { IActivityResponse } from '@Types/EventActivity';
import { IRundateResponse } from '@Types/EventRundate';
import { FavoritesTypes } from '@Types/Favorites';

export const PurchaseFollow = ({ transaction, user }: IPurchaseFollowProps) => {
  const { isVisible, handleHide, handleShow } = useToggle(false);
  const i18n = useDictionary();
  const url = EventsApi.getEventUrl(
    transaction.eventSlug,
    transaction.rundateSlug
  );
  const { data: eventData } = useSWR<IRundateResponse | IActivityResponse>(
    transaction.id && url,
    Fetcher.get
  );

  React.useEffect(() => {
    if (!!user && eventData) handleShow();
  }, [user, eventData]);

  if (!user || !eventData) return null;

  const isCalendarEvent = eventData.calendarEvent;

  const data = {
    [FavoritesTypes.Artists]: DataMutations.artistsToFavorites(
      isCalendarEvent ? [] : eventData.event.artists
    ),
    [FavoritesTypes.Places]: DataMutations.placeToFavorite(eventData.place),
    [FavoritesTypes.Tags]: DataMutations.tagsToFavorites(
      isCalendarEvent ? eventData.tags : eventData?.event.tags
    ),
  };

  return (
    <Dialog
      open={isVisible}
      fullWidth={true}
      onBackdropClick={handleHide}
      onClose={handleHide}
    >
      <Grid container={true} direction={'column'}>
        <Grid item={true}>
          <Grid
            container={true}
            p={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography
              variant={'h5'}
              children={i18n.favorites.stayFresh}
              align={'center'}
            />
            <IconButton title={i18n.favorites.close} onClick={handleHide}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item={true}>
          <Grid container={true} flexDirection={'column'} spacing={2}>
            {!!data[FavoritesTypes.Artists].length && (
              <Grid item={true}>
                <Grid container={true} spacing={2} flexDirection={'column'}>
                  <Grid item={true}>
                    <Grid container={true} px={3}>
                      <Typography
                        variant={'body2'}
                        children={i18n.favorites[FavoritesTypes.Artists]}
                      />
                    </Grid>
                  </Grid>
                  <Grid item={true}>
                    <Grid container={true} flexDirection={'column'}>
                      {data[FavoritesTypes.Artists].map((favorite) => (
                        <FavoriteListItem
                          favorite={favorite}
                          userEmail={user.userEmail}
                          key={favorite.externalId.toString()}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!!data[FavoritesTypes.Places] && (
              <Grid item={true}>
                <Grid container={true} spacing={2} flexDirection={'column'}>
                  <Grid item={true}>
                    <Grid container={true} px={3}>
                      <Typography
                        variant={'body2'}
                        children={i18n.favorites[FavoritesTypes.Places]}
                      />
                    </Grid>
                  </Grid>
                  <Grid item={true}>
                    <Grid container={true} flexDirection={'column'}>
                      {[data[FavoritesTypes.Places]].map((favorite) => (
                        <FavoriteListItem
                          favorite={favorite}
                          userEmail={user.userEmail}
                          key={favorite.externalId.toString()}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!!data[FavoritesTypes.Tags].length && (
              <Grid item={true}>
                <Grid container={true} spacing={2} flexDirection={'column'}>
                  <Grid item={true}>
                    <Grid container={true} px={3}>
                      <Typography
                        variant={'body2'}
                        children={i18n.favorites[FavoritesTypes.Tags]}
                      />
                    </Grid>
                  </Grid>
                  <Grid item={true}>
                    <Grid container={true} flexDirection={'column'}>
                      {data[FavoritesTypes.Tags].map((favorite) => (
                        <FavoriteListItem
                          favorite={favorite}
                          userEmail={user.userEmail}
                          key={favorite.externalId.toString()}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item={true}>
          <Grid container={true} p={2}>
            <Button
              color={'primary'}
              size={'large'}
              onClick={handleHide}
              variant={'contained'}
              children={i18n.favorites.close}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
