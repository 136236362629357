import React from 'react';

import { Box, Container, Grid } from '@material-ui/core';
import cn from 'classnames';

import config from '@Config';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import useStyles from './MuiFooter.styles';
import AppDownload from './components/AppDownload';
import FooterMenu from './components/FooterMenu';
import PhoneMockup from './components/PhoneMockup';

const MuiFooter = () => {
  const classes = useStyles();
  const { isMobile } = useMuiBreakpoints();
  const isEmpik = config.theme.isEmpik;

  return (
    <Box
      component={'footer'}
      className={
        isEmpik
          ? cn(classes.root, classes.empik)
          : cn(classes.root, classes.going)
      }
    >
      <Container maxWidth={'lg'}>
        <Grid
          container={true}
          direction={isMobile ? 'column' : 'row'}
          spacing={6}
        >
          <Grid item={true} sm={8}>
            <FooterMenu />
            {config.theme.isGoing && <AppDownload />}
          </Grid>
          {!isMobile && config.theme.isGoing && <PhoneMockup />}
        </Grid>
      </Container>
    </Box>
  );
};

export default MuiFooter;
