import React, { Component } from 'react';

import cn from 'classnames';

import styles from './ProgressBar.module.scss';
import {
  IProgressBarCSS,
  IProgressBarProps,
  IProgressBarState,
} from './ProgressBar.types';

const DURATION_LOADING = 3000;
const DURATION_FINISHING = 1000;
const DURATION_DELAY = 100;

class ProgressBar extends Component<IProgressBarProps, IProgressBarState> {
  private timestamp: number | null = null;

  constructor(props: IProgressBarProps) {
    super(props);

    this.state = {
      show: false,
      status: 0,
    };

    this.check = this.check.bind(this);
  }

  public componentDidUpdate(prevProps: IProgressBarProps) {
    const { isLoading } = this.props;

    if (prevProps.isLoading === isLoading) {
      return;
    }

    if (isLoading) {
      // Start
      if (this.state.status === 0) {
        this.setState({
          show: true,
          status: 1, // Go to 75%
        });
      } else if (this.state.status === 2) {
        // Finishing
        let timeToFinish = DURATION_FINISHING;

        if (this.timestamp) {
          timeToFinish =
            Math.max(timeToFinish - (Date.now() - this.timestamp), 0) +
            DURATION_DELAY;

          this.timestamp = null;
        }

        setTimeout(this.check, timeToFinish);
      }
    } else {
      if (this.state.status === 1) {
        // Stop
        this.setState(
          {
            show: true,
            status: 2, // go to 100%
          },
          () => {
            setTimeout(() => {
              this.setState({
                show: false,
                status: 0,
              });
            }, DURATION_FINISHING);

            this.timestamp = Date.now();
          }
        );
      }
    }
  }

  public shouldComponentUpdate(
    nextProps: IProgressBarProps,
    nextState: IProgressBarState
  ) {
    return (
      nextState.show !== this.state.show ||
      nextState.status !== this.state.status ||
      nextProps.isLoading !== this.props.isLoading
    );
  }

  public check() {
    if (this.props.isLoading && this.state.status === 0) {
      this.setState({
        show: true,
        status: 1, // Go to 75%
      });
    }
  }

  public render() {
    const css: IProgressBarCSS = {};
    const { show, status } = this.state;

    if (show) {
      css.transitionDuration = `${
        status === 1 ? DURATION_LOADING : DURATION_FINISHING
      }ms`;
    }

    return (
      <div className={cn(styles.container, show && styles.show)}>
        <div
          style={css}
          className={cn(styles.bar, {
            [styles.loading]: status === 1,
            [styles.finishing]: status === 2,
          })}
        />
      </div>
    );
  }
}

export default ProgressBar;
