import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { resetForm, skipToSelectedStep } from '@Model/entryList/actions';
import { translate } from '@Model/locale/selectors';

import Greeting from './Greeting.component';
import { IGreetingFromDispatch } from './Greeting.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IGreetingFromDispatch => ({
  resetForm: () => dispatch(resetForm()),
  skipToSelectedStep: (step) => dispatch(skipToSelectedStep(step)),
});

export default connect<{}, IGreetingFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(Greeting);
