import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import FormioPage from './FormioPage.component';
import { IFormioPageFromState } from './FormioPage.types';

const mapStateToProps = (state: _Store.IState): IFormioPageFromState => ({
  title: translate(state)('formio', 'title'),
});

export default connect<IFormioPageFromState, null, {}, _Store.IState>(
  mapStateToProps,
  null
)(FormioPage);
