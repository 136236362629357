import React, { useEffect } from 'react';

import { Button, Grid } from '@material-ui/core';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useGlobalStyles from '../../global.styles';
import stepNames from '../../steps';
import Paper from '../Paper';
import { IAgreementExistProps } from './AgreementExist.types';

const AgreementExist = ({
  resetForm,
  handlePrevStep,
  submissionsComplete,
  skipToSelectedStep,
}: IAgreementExistProps) => {
  const globals = useGlobalStyles();
  const { entryList: lang } = useDictionary();

  const handleResetForm = () => {
    resetForm();
    skipToSelectedStep(stepNames.starter);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      resetForm();
      skipToSelectedStep(stepNames.starter);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Paper
      title={
        submissionsComplete
          ? lang.agreementExist.submissionsCompleteTitle
          : lang.agreementExist.title
      }
      helperText={
        submissionsComplete
          ? lang.agreementExist.submissionsCompleteHelper
          : lang.agreementExist.subtitle
      }
    >
      <Grid item={true} xs={12}>
        <Grid
          container={true}
          justifyContent="center"
          className={globals.column}
        >
          <Grid item={true}>
            <Button
              className={globals.button}
              type="button"
              variant="text"
              color="primary"
              onClick={handlePrevStep}
            >
              {lang.agreementExist.goBack}
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              className={globals.button}
              variant="contained"
              size="medium"
              color="primary"
              onClick={handleResetForm}
            >
              {lang.agreementExist.homepage}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AgreementExist;
