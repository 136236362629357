import React, { FC } from 'react';

import cn from 'classnames';
import { Map, Overlay } from 'pigeon-maps';

import config from '@Config';
import useCenterImage from '@Misc/hooks/useCenterImage';

import mapProviders from './InteractiveMap.providers';
import useStyles from './InteractiveMap.styles';
import { IInteractiveMap } from './InteractiveMap.types';

const DEFAULT_MAP_ZOOM = 14;

const map: FC<IInteractiveMap> = ({
  height,
  longitude,
  latitude,
  marker,
  markerHeight,
  markerStyle,
  markerWidth,
  zoom = DEFAULT_MAP_ZOOM,
  isDarkTheme,
}) => {
  const { provider, Attribution } = config.theme.isEmpik
    ? mapProviders.stamenGrey
    : mapProviders.stamenBlackAndWhite;

  const muiStyles = useStyles();

  return (
    <Map
      center={[latitude, longitude]}
      zoom={zoom}
      height={height}
      provider={provider}
      attribution={<Attribution />}
      metaWheelZoom={true}
      boxClassname={cn(isDarkTheme && muiStyles.invert)}
    >
      <Overlay
        anchor={[latitude, longitude]}
        offset={useCenterImage(markerWidth, markerHeight)}
      >
        <img
          src={marker}
          width={markerWidth}
          height={markerHeight}
          className={cn(markerStyle, isDarkTheme && muiStyles.invert)}
          alt={''}
        />
      </Overlay>
    </Map>
  );
};

export default map;
