import { createSelector } from 'reselect';

import _Store from '@Store';

import { IInsuranceProduct, IProductsReducer } from './../types';
import getAll from './getAll';

const getInsuranceProducts = createSelector<
  _Store.IState,
  IProductsReducer,
  IInsuranceProduct[]
>([getAll], (products) => products.insurance);

export default getInsuranceProducts;
