import React, { ChangeEvent } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import useStyles from './ActionMessage.styles';
import { IActionMessageProps } from './ActionMessage.types';

const ActionMessage = ({
  action,
  close,
  lang,
  message,
  show,
  confirmText,
  cancelText,
  radioOptions,
  option,
  setOption,
}: IActionMessageProps) => {
  const muiStyles = useStyles();

  const handleChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    if (setOption) {
      setOption(value);
    }
  };

  return (
    <Dialog maxWidth={'sm'} open={show} onClose={close}>
      <DialogContent className={muiStyles.content}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Typography variant={'body1'}>{message}</Typography>
          </Grid>
          {radioOptions && option && (
            <Grid item={true} xs={12}>
              <RadioGroup value={option} onChange={handleChange}>
                {radioOptions.map((radio) => (
                  <FormControlLabel
                    key={radio.value}
                    value={radio.value}
                    control={<Radio />}
                    label={radio.label}
                  />
                ))}
              </RadioGroup>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={muiStyles.actions}>
        <Button variant={'text'} size={'medium'} onClick={close}>
          {cancelText || lang.no}
        </Button>
        <Button
          variant={'contained'}
          size={'medium'}
          color={'primary'}
          onClick={action}
        >
          {confirmText || lang.yes}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionMessage;
