const theme = {
  /*
   * Default theme
   */
  isEmpik: process.env.THEME !== 'going' && process.env.THEME !== 'sbm',
  isGoing: process.env.THEME === 'going',
  isSbm: process.env.THEME === 'sbm',

  name: process.env.THEME,
};

export default theme;
