import { createStyles, makeStyles } from '@material-ui/core';

const errorModalHeight = 150; // TODO: this will work 95% times, but calculate this properly later

const useStyles = (
  embedDialogPosition: number,
  isEmbed: boolean,
  isDesktop: boolean
) =>
  makeStyles((theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.background.paper,
        ...(isEmbed && {
          position: 'fixed !important' as 'fixed',
          top: `${embedDialogPosition - errorModalHeight}px`,
        }),
      },
      content: {
        padding: '2rem',

        '&:first-child': {
          paddingTop: '2rem',
        },
      },
    })
  );

export default useStyles;
