import React, { Suspense } from 'react';

import MainPageTop from '@Compo/layout/MainPageTop';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import SimpleLoading from '@Compo/reusable/SimpleLoading';
import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

const loading = <SimpleLoading />;

const HappeningRegisterPage = () => {
  const i18n = useDictionary();

  return (
    <MuiGrid>
      <MuiSectionContainer>
        <Suspense fallback={loading}>
          <MainPageTop title={i18n.entryListL.title} />
        </Suspense>
      </MuiSectionContainer>
    </MuiGrid>
  );
};

export default HappeningRegisterPage;
