import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getSelection } from '@Model/selection/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenSelectionFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getSelection.request)),
    map$(() => setLoading(CONSTS.SELECTION))
  );
};

export const setLoaderWhenSelectionFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getSelection.success, getSelection.failure], action)
    ),
    map$(() => resetLoading(CONSTS.SELECTION))
  );
};
