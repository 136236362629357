import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  delay as delay$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import HttpError from '@Misc/classes/HttpError';
import LocalStorage from '@Misc/classes/LocalStorage';
import { clearLocation, getLocations } from '@Model/locations/actions';
import { getSelectedLocation } from '@Model/locations/selectors';
import { getPagesAuth } from '@Model/pages/selectors';
import { getLocation } from '@Model/router/selectors';

import {
  authorizePage,
  fetchUrlStructure,
  getAboutPage,
  getCustomPage,
  getMainPage,
  getSections,
  openPageAuth,
  resetAuthError,
  setAuthError,
} from './../actions';
import { PagesEnum } from './../constants/pages';
import { ICompositionData } from './../types';

const ONE_HOUR_MILLISECONDS = 3600000;
const ABOUT_APP_TEXT = 'o-aplikacji';

export const getMainPageSections: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(getMainPage)),
    map$(() => {
      if (config.app.mainpageCategory !== PagesEnum.mainpage) {
        return getSections.request({
          site: config.app.mainpageCategory,
          slug: config.app.mainpage,
        });
      }

      return getLocations(PagesEnum.mainpage);
    })
  );

export const getAboutPageWhenRequested: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(getAboutPage)),
    map$(() => {
      return getSections.request({
        site: PagesEnum.recommend,
        slug: ABOUT_APP_TEXT,
      });
    })
  );

export const getCustomSections: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(getCustomPage)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const path = getLocation(state).pathname.split('/');

      if (path[3]) {
        return [
          getSections.request({
            groupSlug: path[2],
            site: PagesEnum.recommend,
            slug: path[3],
          }),
        ];
      } else {
        return [
          getSections.request({
            site: PagesEnum.recommend,
            slug: path[2],
          }),
        ];
      }
    })
  );

export const fetchUrlSections: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(fetchUrlStructure)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const url = getLocation(state).pathname.replace('/', '');

      return of$(getSections.request({ site: PagesEnum.link, slug: url }));
    })
  );
};

export const fetchSections: _Store.IEpic = (action$, state$, { sectionsApi }) =>
  action$.pipe(
    filter$(isActionOf(getSections.request)),
    delay$(config.api.cancellationFixDelay),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedLocation = getSelectedLocation(state);
      const slug = action.payload.slug
        ? action.payload.slug
        : selectedLocation.slug;
      const groupSlug = action.payload.groupSlug;
      const authCredentials = getPagesAuth(state);
      const savedCredentials = LocalStorage.getItem(
        `${action.payload.slug}-auth`
      );
      const version = getLocation(state).query?.version;

      return from$(
        sectionsApi.getSections(
          action.payload.site,
          slug,
          groupSlug,
          authCredentials.email || savedCredentials?.email,
          authCredentials.entryToken || savedCredentials?.token,
          version
        )
      ).pipe(
        mergeMap$((data: ICompositionData) => {
          const pages = {
            pages: data,
            site: action.payload.site,
          };

          if (data) {
            if (action.payload.email && action.payload.entryToken) {
              LocalStorage.setItem(
                `${action.payload.slug}-auth`,
                {
                  email: action.payload.email,
                  token: action.payload.entryToken,
                },
                ONE_HOUR_MILLISECONDS
              );
            }

            return of$(getSections.success(pages));
          }

          return of$(clearLocation(), getSections.failure(new Error()));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => sectionsApi.cancelSections())
          )
        ),
        catchError$((error: HttpError) => {
          if (error.status === 401) {
            if (action.payload.email && action.payload.entryToken) {
              return of$(openPageAuth(action.payload), setAuthError());
            }

            return of$(openPageAuth(action.payload));
          }

          return of$(getSections.failure(error));
        })
      );
    }),
    catchError$((error: Error) => of$(getSections.failure(error)))
  );

export const authenticateSections: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(authorizePage)),
    mergeMap$((action) => {
      return [resetAuthError(), getSections.request(action.payload)];
    })
  );
