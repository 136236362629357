import { IAvailableThemes } from '@Model/theme/types';

const getThemeFromPartnerId = (partnerId?: string): IAvailableThemes => {
  const partners: Array<{ id: string; name: IAvailableThemes }> = [
    { id: '2399', name: 'Hangar646' },
    { id: '2459', name: 'Saltos' },
  ];
  if (partnerId) {
    const themeName = partners.find(
      (partner) => partner.id === partnerId
    )?.name;

    if (themeName) {
      return themeName;
    }
  }

  return 'default';
};

export default getThemeFromPartnerId;
