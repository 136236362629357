import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      gap: '1rem',
    },
    value: {
      fontWeight: 500,
      lineHeight: '1.1875rem',
      wordBreak: 'break-all',
    },
    label: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
    buttons: {
      marginTop: '3rem',
    },
  })
);

export default useStyles;
