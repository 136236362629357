import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPool, IPricing } from './../types';
import getPools from './getPools';

const getPricing = createSelector<_Store.IState, IPool[], IPricing[]>(
  [getPools],
  (pools) => {
    const pricing: IPricing[] = [];
    const filteredPools = pools.filter(
      (pool) => pool.seatsIoCategories.length > 0
    );

    filteredPools.forEach((pool) => {
      if (pool.seatsIoCategories.length > 0) {
        const poolCategoryKey = pool.seatsIoCategories[0].categoryKey;
        const poolTicketTypes = pool.seatsIoCategories[0].ticketTypes;

        const categoryInPricing = pricing.find(
          (pricingCategory) => pricingCategory.category === poolCategoryKey
        );
        const categoryInPricingIndex = pricing.findIndex(
          (pricingCategory) => pricingCategory.category === poolCategoryKey
        );

        const numOfPoolsWithCurrentCategory = filteredPools.filter(
          (poolWithCategory) =>
            poolWithCategory.seatsIoCategories[0].categoryKey ===
            poolCategoryKey
        ).length;
        const categoryHasMoreThanOnePoolAssigned =
          numOfPoolsWithCurrentCategory > 1;

        const poolCategories = (): IPricing => {
          if (categoryHasMoreThanOnePoolAssigned) {
            if (categoryInPricing && categoryInPricing.ticketTypes) {
              pricing.splice(categoryInPricingIndex, 1);

              return {
                ...categoryInPricing,
                ticketTypes: [
                  ...categoryInPricing.ticketTypes,
                  ...poolTicketTypes,
                ],
              };
            }

            return {
              category: poolCategoryKey,
              ticketTypes: poolTicketTypes,
            };
          } else {
            return {
              category: poolCategoryKey,
              price: poolTicketTypes[0].price,
            };
          }
        };

        pricing.push(poolCategories());
      }
    });

    return pricing;
  }
);

export default getPricing;
