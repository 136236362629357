import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import EventPage from './EventPage.component';
import { IEventPageFromState } from './EventPage.types';

const mapStateToProps = (state: _Store.IState): IEventPageFromState => ({
  lang: translate(state)('breadcrumbs'),
});

export default connect<IEventPageFromState, void, {}, _Store.IState>(
  mapStateToProps
)(EventPage);
