import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getIframeParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { getTicketsData } from '@Model/tickets/selectors';

import MyTickets from './MyTickets.component';
import { IMyTicketsFromDispatch, IMyTicketsFromState } from './MyTickets.types';

const mapStateToProps = (state: _Store.IState): IMyTicketsFromState => ({
  isEmbed: !!getIframeParams(state),
  lang: translate(state)('ticketsManagement', 'myTickets'),
  tickets: getTicketsData(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IMyTicketsFromDispatch => ({
  editTicket: null,
});

export default withRouter(
  connect<IMyTicketsFromState, IMyTicketsFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(MyTickets)
);
