export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
export const TABLET = 'tablet';

export const IOS = 'ios';
export const ANDROID = 'android';
export const WINDOWS = 'windows';
export const UNDEFINED = '';

export const MOBILE_MAX_HEIGHT = 736;
export const MOBILE_MAX_WIDTH = 600;

export const DESKTOP_MIN_HEIGHT = 1024;
export const DESKTOP_MIN_WIDTH = 960;
