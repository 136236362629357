import axios from 'axios';

import config from '@Config';
import getData from '@Misc/helpers/api/getData';
import withCacheHeader from '@Misc/helpers/withCacheHeader';

import { IPasswordResetResponse } from './types';

class LegacyAuthApi {
  private static resetUrl = `${config.api.baseUrl}password/reset`;

  public passwordReset(requestBody: string): Promise<IPasswordResetResponse> {
    return new Promise<IPasswordResetResponse>((resolve, reject) => {
      axios
        .post(LegacyAuthApi.resetUrl, requestBody, withCacheHeader())
        .then(getData)
        .then((response: IPasswordResetResponse) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new LegacyAuthApi();
