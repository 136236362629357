import { EMPTY as EMPTY$, from as from$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getParams } from '@Model/internalRouter/selectors';
import { IMailMatchParams } from '@Routes/types';

import { mounted } from './../actions';

export const sendMailWhenMounted: _Store.IEpic = (
  action$,
  state$,
  { mailApi }
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as IMailMatchParams;

      return from$(mailApi.sendMail(params.id)).pipe(mergeMap$(() => EMPTY$));
    })
  );
};
