import { createSelector } from 'reselect';

import getApp from './getApp';

const getMobileUserAgent = createSelector(
  [getApp],
  (app) => app.mobileUserAgent
);

export default getMobileUserAgent;
