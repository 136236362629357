import React, { MouseEvent } from 'react';

import { Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import cn from 'classnames';

import useStyles from '@Compo/Bricks/components/TourListBrick/TourList.styles';
import {
  ITourListElementProps,
  ITourListProps,
} from '@Compo/Bricks/components/TourListBrick/TourList.types';
import DualLink from '@Compo/reusable/DualLink';
import invertIfGrayScaleColor from '@Misc/helpers/invertIfGrayScaleColor';
import isColorDark from '@Misc/helpers/isColorDark';
import slugifyString from '@Misc/helpers/slugifyString';
import translatableDate from '@Misc/helpers/translatableDate';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import Analytics from '@Services/$analytics';

const TourListElement = ({
  item,
  index,
  dataLength,
  compositionIdentifier,
  lang,
  isMobile,
  muiStyles,
  brickId,
  onClick,
}: ITourListElementProps) => {
  const startDate = translatableDate(item.startDate, undefined, lang);
  const endDate = translatableDate(item.endDate, undefined, lang);
  const isDate = item.startDate && item.endDate;
  const isDivider = index < dataLength - 1;

  return (
    <Grid spacing={6} container={true} item={true}>
      <Grid
        spacing={6}
        item={true}
        container={true}
        className={muiStyles.item}
        alignItems={'center'}
      >
        <Grid
          item={true}
          container={true}
          className={muiStyles.stretch}
          direction={isMobile ? 'column' : 'row'}
        >
          <Grid item={true} className={muiStyles.stretch}>
            <Typography
              variant={'h4'}
              children={item.title}
              className={cn(muiStyles.fontColor)}
            />
          </Grid>
          <Grid item={true} className={muiStyles.stretch}>
            <Typography
              variant={'body1'}
              children={isDate ? `${startDate} - ${endDate}` : 'TBA'}
              className={cn(muiStyles.fontColor)}
            />
          </Grid>
        </Grid>
        <Grid item={true}>
          <DualLink
            to={item.link ? item.link : ''}
            className={`${compositionIdentifier}-${brickId}-${slugifyString(
              item.title
            )}`}
            onClick={onClick(item.link, item.title)}
          >
            <IconButton disabled={!item.link}>
              <ArrowRight color={'primary'} />
            </IconButton>
          </DualLink>
        </Grid>
      </Grid>
      {isDivider && (
        <Grid item={true} className={muiStyles.stretch}>
          <Divider className={muiStyles.divider} />
        </Grid>
      )}
    </Grid>
  );
};

const TourList = ({
  data,
  color,
  bgColor,
  lang,
  isDark,
  compositionIdentifier,
  id,
}: ITourListProps) => {
  const isBgDark =
    isColorDark(bgColor) ||
    isColorDark(isDark && bgColor ? invertIfGrayScaleColor(bgColor) : bgColor);
  const { isMobile } = useMuiBreakpoints();
  const muiStyles = useStyles(isBgDark, color, isMobile)();

  const handleAddToDataLayerOnClick =
    (link?: string, textDisplayed?: string, componentName?: string) =>
    (e: MouseEvent<HTMLElement>) =>
      link
        ? Analytics.pushForHomepageSelectItem(
            e,
            link,
            textDisplayed || '',
            componentName
          )
        : undefined;

  return (
    <Grid
      spacing={6}
      container={true}
      direction={'column'}
      className={muiStyles.root}
    >
      {data.map((item, index) => (
        <TourListElement
          item={item}
          index={index}
          isMobile={isMobile}
          compositionIdentifier={compositionIdentifier}
          lang={lang}
          muiStyles={muiStyles}
          dataLength={data.length}
          brickId={id}
          onClick={handleAddToDataLayerOnClick}
        />
      ))}
    </Grid>
  );
};

export default TourList;
