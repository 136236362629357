import { spacing } from '@/config/mui';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textAlign: 'right',
    },
    center: {
      alignSelf: 'center',
      textAlign: 'center',
    },
    disabled: {
      opacity: 0.3,
      pointerEvents: 'none',
    },
    icon: {
      fontSize: 48,
    },
    iconContainer: {
      alignItems: 'center',
      display: 'flex',
      height: '2em',
      justifyContent: 'center',
      width: '2em',
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.border.color,
      borderRadius: theme.border.radius,
      borderStyle: theme.border.style,
      borderWidth: theme.border.width,
      margin: 'auto',
      padding: theme.spacing(spacing.medium),
      width: 'auto',
    },
    upsellButton: {
      padding: theme.spacing(3),
    },
  })
);

export default useStyles;
