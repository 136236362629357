import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    basketPools: {
      paddingLeft: `${theme.spacing(1)}px!important`,
      paddingRight: `${theme.spacing(1)}px!important`,
    },
    tooltip: {
      marginLeft: theme.spacing(1),
      width: '0.75rem',
    },

    typographyWithTooltip: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export default useStyles;
