import { PaymentOperatorEnum } from '@Model/transaction/types';

const buy = {
  /*
   * Default operator
   */
  defaultOperator: PaymentOperatorEnum.payU,
  onsiteSlotOperator: PaymentOperatorEnum.virtual,

  courierDeliveryPrice: 0,
  giftWrapperPrice: 10,
  goingCouponLink: 'https://web.goingapp.pl/buy/',
  goingCouponLinkParameter: '?discount=1',
  transferPrice: 5,

  /*
   * How many products user can select in default mode
   */
  maxProductsCount: 20,
  /*
   * How many tickets user can select in default mode
   */
  maxTicketsCount: 4,

  /*
   * Sometimes payment operator wants to receive two links: for fails
   * and success (eCard) and sometimes one link (payU)
   */
  paymentSeparatedLinks: false,

  maxInsuredTicketsLimit: 6,

  payUPosId: process.env.REACT_APP_PAYU_POS_ID || '', // copy fallback value from Dockerfile for local development. We don't want it here not to expose it in browser.
};

export default buy;
