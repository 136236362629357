/**
 * Function marks special keys started with ':' by wrap it on brackets.
 *
 * @param {string} url - source url
 *
 * @example
 * makeUrlForPaymentFromRouteString('http://example.com/:key/adress');
 * //=> http://example.com/{key}/adress
 */
const replaceColonParamsWithBrackets = (url: string) => {
  return url.replace(/[\/=&?]:([a-z]+)[\/&]?/gi, '/{$1}/');
};

export default replaceColonParamsWithBrackets;
