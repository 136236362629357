import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { INormalizedPools } from '@Services/$pools-api/types';
import { IPaymentMethod } from '@Services/$transactions-api/types';

import * as CONSTS from './../constants/actions';
import {
  IGetClosestPoolsPayload,
  IGetPoolsPayload,
  ISelectPoolPayload,
  ISelectSeatPayload,
  ISelectTicketPayload,
  IUpdateSelectedSeatsPayload,
  IUpdateSelectedTicketsPayload,
} from './../types';

// Public actions
export const poolsMounted = createStandardAction(CONSTS.MOUNTED)();
export const selectPool = createStandardAction(
  CONSTS.SELECT_POOL
)<ISelectPoolPayload>();
export const selectTicket = createStandardAction(
  CONSTS.SELECT_TICKET
)<ISelectTicketPayload>();
export const selectSeat = createStandardAction(
  CONSTS.SELECT_SEAT
)<ISelectSeatPayload>();
export const deselectSeat = createStandardAction(
  CONSTS.DESELECT_SEAT
)<ISelectSeatPayload>();
export const resetTicketsState = createStandardAction(
  CONSTS.RESET_TICKETS_STATE
)();
export const setFinalPrice = createStandardAction(
  CONSTS.SET_FINAL_PRICE
)<number>();
export const setPaymentMethods = createStandardAction(
  CONSTS.SET_PAYMENT_METHODS
)<IPaymentMethod[]>();

// Private actions
export const getPools = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined | IGetClosestPoolsPayload, IGetPoolsPayload, Error>();
export const getClosestPools = createAsyncAction(
  CONSTS._GET_CLOSEST_POOLS_REQUEST,
  CONSTS._GET_CLOSEST_POOLS_SUCCESS,
  CONSTS._GET_CLOSEST_POOLS_FAILURE
)<undefined, INormalizedPools[], Error>();

export const resetState = createStandardAction(CONSTS._RESET_STATE)();
export const updateSelectedTickets = createStandardAction(
  CONSTS._UPDATE_SELECTED_TICKETS
)<IUpdateSelectedTicketsPayload>();
export const updateSelectedSeats = createStandardAction(
  CONSTS._UPDATE_SELECTED_SEATS
)<IUpdateSelectedSeatsPayload>();
