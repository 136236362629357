import {
  ITestimonialsData,
  ITestimonialsOwnProps,
} from '@Compo/Bricks/components/Testimonials/Testimonials.types';
import {
  ISectionComponentData,
  ISectionsComponentDataMutable,
} from '@Model/pages/types/sections';

export const testimonials = (
  data: ISectionsComponentDataMutable,
  extra?: ISectionComponentData | null,
  sectionBgColor?: string,
  id?: string
): ITestimonialsOwnProps => {
  const testimonialData = data as ITestimonialsData[];

  return {
    data: testimonialData,
    extra,
    id,
    sectionBgColor,
  };
};
