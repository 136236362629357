import { LOCATION_CHANGE } from 'connected-react-router';

import { IAction, IRouterFirstRenderingReducer } from './../types';

const initialState: IRouterFirstRenderingReducer = false;

const isFirstRenderingReducer = (
  state: IRouterFirstRenderingReducer = initialState,
  action: IAction
): IRouterFirstRenderingReducer => {
  switch (action.type) {
    // LOCATION_CHANGE
    case LOCATION_CHANGE:
      const { isFirstRendering } = action.payload;

      if (isFirstRendering) {
        return true;
      }

      if (!isFirstRendering) {
        return false;
      }

    default:
      return state;
  }
};

export default isFirstRenderingReducer;
