import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEntryListReducer, ISubmissionsSummary } from './../types';
import get from './get';

const getSubmissionsSummary = createSelector<
  _Store.IState,
  IEntryListReducer,
  ISubmissionsSummary[]
>([get], (state) => state.submissionsSummary);

export default getSubmissionsSummary;
