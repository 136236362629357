import { connect } from 'react-redux';

import _Store from '@Store';

import { getActiveStep } from '@Model/entryList/selectors';

import EntryListStepper from './EntryList.component';
import { IEntryListFromState } from './EntryList.types';

const mapStateToProps = (state: _Store.IState): IEntryListFromState => ({
  activeStep: getActiveStep(state),
});

export default connect<IEntryListFromState, {}, {}, _Store.IState>(
  mapStateToProps
)(EntryListStepper);
