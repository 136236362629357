import { getType } from 'typesafe-actions';

import { locationChange } from '@Model/internalRouter/actions';
import { IAction as IInternalRouterAction } from '@Model/internalRouter/types';

import { checkoutStep, firstValidation } from './../actions';
import { IAction, IAnalyticsReducer } from './../types';

const initialState: IAnalyticsReducer = {
  checkoutValidation: false,
  selectTickets: false,
};

const reducer = (
  state: IAnalyticsReducer = initialState,
  action: IAction | IInternalRouterAction
): IAnalyticsReducer => {
  switch (action.type) {
    case getType(firstValidation):
      return {
        ...state,
        checkoutValidation: true,
      };

    case getType(checkoutStep):
      return {
        ...state,
        selectTickets: true,
      };

    case getType(locationChange):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
