import React from 'react';

import cn from 'classnames';

import spinnerIcon from '@Misc/styles/images/spinner_icon.svg';
import spinnerIconDark from '@Misc/styles/images/spinner_icon_dark.svg';

import styles from './SimpleLoading.module.scss';
import { ISimpleLoadingProps } from './SimpleLoading.types';

const SimpleLoading = ({ isDark, relative }: ISimpleLoadingProps) => (
  <img
    className={cn(styles.spinnerIcon, relative && styles.relative)}
    src={isDark ? spinnerIcon : spinnerIconDark}
    alt={'loading'}
  />
);

export default SimpleLoading;
