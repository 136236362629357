import React from 'react';

import styles from './TagsLoading.module.scss';

const TagsLoading = () => (
  <div className={styles.tagsLoading}>
    <div className={styles.fakeTag} />
    <div className={styles.fakeTag} />
    <div className={styles.fakeTag} />
  </div>
);

export default TagsLoading;
