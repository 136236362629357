import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import PriceTypes from '@Compo/Happening/components/PriceTypes/PriceTypes.component';
import {
  IPriceTypesFromDispatch,
  IPriceTypesFromState,
} from '@Compo/Happening/components/PriceTypes/PriceTypes.types';
import {
  eventAddToCartHappening,
  eventRemoveFromCartHappening,
} from '@Model/analytics/actions';
import {
  setOnlyOnePriceType,
  setPricesInState,
} from '@Model/happening/actions';
import {
  getAvailabilities,
  getData,
  getSelected,
} from '@Model/happening/selectors';
import { getTheme } from '@Model/theme/selectors';

const mapStateToProps = (state: _Store.IState): IPriceTypesFromState => ({
  availabilities: getAvailabilities(state),
  calculatePricePerPerson: getData(state)?.calculatePricePerPerson,
  happening: getData(state),
  happeningSpaces: getData(state)?.spaces,
  selectedSlot: getSelected(state).slot,
  selectedSpace: getSelected(state).space,
  selectedTheme: getTheme(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPriceTypesFromDispatch => ({
  setOnlyOnePrice: (value) => dispatch(setOnlyOnePriceType(value)),
  setPricesInState: (value) => dispatch(setPricesInState(value)),
  eventAddToCartHappening: (params) =>
    dispatch(eventAddToCartHappening(params)),
  eventRemoveFromCartHappening: (params) =>
    dispatch(eventRemoveFromCartHappening(params)),
});

export default connect<
  IPriceTypesFromState,
  IPriceTypesFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PriceTypes);
