import React from 'react';

import { Grid, Typography, useTheme } from '@material-ui/core';
import cn from 'classnames';

import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import MuiButton from '@Compo/reusable/MuiButton';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import mockup_one from '@Styles/images/01_oaplikacji_2 1.png';
import mockup_two from '@Styles/images/02_oaplikacji_2 1.png';
import mockup_three from '@Styles/images/03_oaplikacji_2 1.png';
import mockup_four from '@Styles/images/04_oaplikacji_2 1.png';
import mockup_five from '@Styles/images/05_oaplikacji_2 1.png';
import icon_huawei_store from '@Styles/images/button_app_gallery.svg';
import icon_ios_store from '@Styles/images/button_app_store.svg';
import icon_android_store from '@Styles/images/button_google_play.svg';
import mockup_one_desktop from '@Styles/images/going_mockup_1.png';
import mockup_two_desktop from '@Styles/images/going_mockup_2.png';
import mockup_three_desktop from '@Styles/images/going_mockup_3.png';
import mockup_four_desktop from '@Styles/images/going_mockup_4.png';

import styles from './About.module.scss';
import useStyles from './About.styles';
import { IAbout } from './About.types';

const ALT_TEXT = 'Going.';
const DOWNLOAD_TEXT = 'Pobierz aplikację Going.';

const About = ({ device, isDesktop, mobileUserAgent }: IAbout) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const { isMobile } = useMuiBreakpoints();
  const isIos = mobileUserAgent === 'ios';
  const isAndroid = mobileUserAgent === 'android';

  const iosStoreLink = 'https://itunes.apple.com/us/app/going./id704764616';
  const androidStoreLink =
    'https://play.google.com/store/apps/details?id=pl.goingapp';

  const buttonStyleMobile = isMobile ? muiStyles.muiButtonMobile : undefined;

  const handleOnClick = () => {
    if (isIos) {
      window.location.replace(iosStoreLink);
    } else {
      window.location.replace(androidStoreLink);
    }
  };

  if (isMobile) {
    return (
      <div className={styles.container}>
        <MuiSectionContainer className={muiStyles.containerColored}>
          <Grid container={true} spacing={5} justifyContent={'center'}>
            <Grid item={true}>
              <Grid direction={'column'} spacing={4} container={true}>
                <Grid item={true}>
                  <Typography variant={'h2'}>
                    Kulturalny przewodnik i sprzedaż biletów w jednej aplikacji
                  </Typography>
                </Grid>

                <Grid item={true}>
                  <Typography variant={'body1'}>
                    Bądź na bieżąco z najlepszymi wydarzeniami, skomponuj swój
                    kalendarz eventowy, informuj znajomych i trzymaj bilety
                    zawsze pod ręką w aplikacji.
                  </Typography>
                </Grid>

                <Grid item={true}>
                  <MuiButton
                    onClick={handleOnClick}
                    className={buttonStyleMobile}
                  >
                    pobierz
                  </MuiButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true}>
              <img className={styles.mockup} src={mockup_one} alt={ALT_TEXT} />
            </Grid>
          </Grid>
        </MuiSectionContainer>

        <MuiSectionContainer className={muiStyles.container}>
          <Grid container={true} spacing={5} justifyContent={'center'}>
            <Grid item={true}>
              <Grid direction={'column'} spacing={4} container={true}>
                <Grid item={true}>
                  <Typography variant={'h2'}>
                    Skomponuj swój kalendarz eventowy!
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant={'body1'}>
                    Oznaczaj i zapisuj najciekawsze dla Ciebie wydarzenia, by o
                    niczym nie zapomnieć, nie zagubić ważnej daty w codziennym
                    biegu i mieć wszystko w jednym miejscu. W Going. nic ważnego
                    dla Ciebie nie ucieknie!
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <img className={styles.mockup} src={mockup_two} alt={ALT_TEXT} />
            </Grid>
          </Grid>
        </MuiSectionContainer>

        <MuiSectionContainer className={muiStyles.container}>
          <Grid container={true} spacing={5} justifyContent={'center'}>
            <Grid item={true}>
              <Grid direction={'column'} spacing={4} container={true}>
                <Grid item={true}>
                  <Typography variant={'h2'}>Informuj znajomych!</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant={'body1'}>
                    W miasto najlepiej uderzać z przyjaciółmi. W Going.
                    polecenie wydarzenia jest niezwykle proste, można również
                    obdarować kogoś bliskiego biletem i zorganizować wszystkim
                    wspólny wypad na najlepszą imprezę.
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <MuiButton
                    onClick={handleOnClick}
                    className={buttonStyleMobile}
                  >
                    pobierz
                  </MuiButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <img
                className={styles.mockup}
                src={mockup_three}
                alt={ALT_TEXT}
              />
            </Grid>
          </Grid>
        </MuiSectionContainer>

        <MuiSectionContainer className={muiStyles.container}>
          <Grid container={true} justify="center" spacing={5}>
            <Grid item={true}>
              <Grid direction={'column'} spacing={4} container={true}>
                <Grid item={true}>
                  <Typography variant={'h2'}>
                    Trzymaj bilety w jednym miejscu!
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant={'body1'}>
                    Kupowanie biletów i organizowanie sobie planów nigdy nie
                    było tak proste i przejrzyste. Zakupione w kilku klikach
                    bilety masz w jednym miejscu i na pewno nie przegapisz
                    żadnego z wyczekiwanych wydarzeń.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <img className={styles.mockup} src={mockup_four} alt={ALT_TEXT} />
            </Grid>
          </Grid>
        </MuiSectionContainer>

        <MuiSectionContainer className={muiStyles.container}>
          <Grid container={true} justify="center" spacing={5}>
            <Grid item={true}>
              <Grid direction={'column'} spacing={4} container={true}>
                <Grid item={true}>
                  <Typography variant={'h2'}>...i jeszcze więcej!</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant={'body1'}>
                    Miejskie treści z Going. MORE, ekskluzywne wywiady i
                    materiały wideo. Bądź zawsze na bieżąco.
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <MuiButton
                    onClick={handleOnClick}
                    className={buttonStyleMobile}
                  >
                    pobierz
                  </MuiButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true}>
              <img className={styles.mockup} src={mockup_five} alt={ALT_TEXT} />
            </Grid>
          </Grid>
        </MuiSectionContainer>

        <MuiSectionContainer className={muiStyles.containerWithGradient}>
          <Grid
            container={true}
            direction={'column'}
            spacing={5}
            className={muiStyles.backgroundGradient}
          >
            <Grid item={true}>
              <Typography variant={'h2'} align={'center'}>
                Pobierz aplikację Going. i więcej nie przegap ulubionych
                wydarzeń!
              </Typography>
            </Grid>
            <Grid item={true}>
              <MuiButton onClick={handleOnClick} className={buttonStyleMobile}>
                pobierz
              </MuiButton>
            </Grid>
          </Grid>
        </MuiSectionContainer>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={cn(styles.row, styles.rowReverse)}>
        <div className={styles.leftColumn}>
          <img
            className={styles.mockup}
            src={mockup_one_desktop}
            alt={ALT_TEXT}
          />
        </div>
        <div className={styles.rightColumn}>
          <h2 className={styles.headerDesktop}>Bądź na bieżąco!</h2>
          <p>
            Dołącz do społeczności bywalców najciekawszych wydarzeń w mieście:
            kino, teatr, imprezy, koncerty, spotkania, targi. <b>Going.</b> to
            Twój własny codzienny kalendarz kulturalny zawsze pod ręką.
          </p>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.leftColumn}>
          <h2 className={styles.headerDesktop}>
            Skomponuj swój kalendarz eventowy!
          </h2>
          <p>
            Oznaczaj i zapisuj najciekawsze dla Ciebie wydarzenia, by o niczym
            nie zapomnieć, nie zagubić ważnej daty w codziennym biegu i mieć
            wszystko w jednym miejscu. W <b>Going.</b> nic ważnego dla Ciebie
            nie ucieknie!
          </p>
        </div>
        <div className={styles.rightColumn}>
          <img
            className={styles.mockup}
            src={mockup_two_desktop}
            alt={ALT_TEXT}
          />
        </div>
      </div>

      <div className={cn(styles.row, styles.rowReverse)}>
        <div className={styles.leftColumn}>
          <img
            className={styles.mockup}
            src={mockup_three_desktop}
            alt={ALT_TEXT}
          />
        </div>
        <div className={styles.rightColumn}>
          <h2 className={styles.headerDesktop}>Informuj znajomych!</h2>
          <p>
            W miasto najlepiej uderzać z przyjaciółmi. W <b>Going.</b> polecenie
            wydarzenia jest niezwykle proste, można również obdarować kogoś
            bliskiego biletem i zorganizować wszystkim wspólny wypad na
            najlepszą imprezę.
          </p>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.leftColumn}>
          <h2 className={styles.headerDesktop}>
            Trzymaj bilety w jednym miejscu!
          </h2>
          <p>
            Kupowanie biletów i organizowanie sobie planów nigdy nie było tak
            proste i przejrzyste. Zakupione w kilku klikach bilety masz w jednym
            miejscu i na pewno nie przegapisz żadnego z wyczekiwanych wydarzeń.
          </p>
        </div>
        <div className={styles.rightColumn}>
          <img
            className={styles.mockup}
            src={mockup_four_desktop}
            alt={ALT_TEXT}
          />
        </div>
      </div>

      <div className={styles.download}>
        <h2 className={styles.downloadTitle}>{DOWNLOAD_TEXT}</h2>
        <div className={styles.downloadButtons}>
          <a
            href={'https://itunes.apple.com/us/app/going./id704764616'}
            target={'_blank'}
            rel="nofollow noopener noreferrer"
          >
            <img src={icon_ios_store} className={styles.icon} alt={ALT_TEXT} />
          </a>

          <a
            href={'https://play.google.com/store/apps/details?id=pl.goingapp'}
            target={'_blank'}
            rel="nofollow noopener noreferrer"
          >
            <img
              src={icon_android_store}
              className={styles.icon}
              alt={ALT_TEXT}
            />
          </a>

          <a
            href={'https://appgallery7.huawei.com/#/app/C101184169'}
            target={'_blank'}
            rel="nofollow noopener noreferrer"
          >
            <img
              src={icon_huawei_store}
              className={styles.icon}
              alt={ALT_TEXT}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
