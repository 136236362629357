import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import paymentSuccessPageMeta from '@Model/meta/selectors/paymentSuccessPage';
import paymentSummary from '@Model/meta/selectors/paymentSummary';

import SummaryAfterSellSegment from './SummaryAfterSellSegment.component';
import { ISummaryAfterSellSegmentFromState } from './SummaryAfterSellSegment.types';

const mapStateToProps = (
  state: _Store.IState
): ISummaryAfterSellSegmentFromState => ({
  transactionData: paymentSuccessPageMeta(state),
  transactionItems: paymentSummary(state),
  lang: translate(state)('payment', 'paymentSuccess'),
});

export default connect<
  ISummaryAfterSellSegmentFromState,
  {},
  {},
  _Store.IState
>(mapStateToProps)(SummaryAfterSellSegment);
