export enum IAvailableFunctions {
  LOADER_POSITION_TOP = 'LOADER_POSITION_TOP',
  PING = 'PING',
  REDIRECT = 'REDIRECT',
  RESIZE = 'RESIZE',
  SCROLL_TO_TOP = 'SCROLL_TO_TOP',
  REDIRECT_EVENT_IFRAME = 'REDIRECT_EVENT_IFRAME',
  GET_IFRAME_RELATIVE_SCROLL_POSITION = 'GET_IFRAME_RELATIVE_SCROLL_POSITION',
  SEND_IFRAME_RELATIVE_SCROLL_POSITION = 'SEND_IFRAME_RELATIVE_SCROLL_POSITION',
}

export interface IApiAction {
  type: keyof typeof IAvailableFunctions;
  payload?: string | number;
}

export interface IFrameAction {
  action: IApiAction;
  type: 'GOING';
}
