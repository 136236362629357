import {
  IOneBigAndVerticalListData,
  IOneBigAndVerticalListExtraData,
  IOneBigAndVerticalListOwnProps,
} from '@Compo/Bricks/components/OneBigAndVerticalList/OneBigAndVerticalList.types';
import makeFriendlyDate from '@Misc/helpers/makeFriendlyDate';
import prepareHotSpotUri from '@Misc/helpers/prepareHotSpotsUri';

import {
  ISectionComponentData,
  ISectionsComponentDataMutable,
  ISectionsComponentHotSpot,
} from './../types/sections';

export const big_and_vertical_list = (
  data: ISectionsComponentDataMutable,
  extra?: IOneBigAndVerticalListExtraData
): IOneBigAndVerticalListOwnProps & ISectionComponentData => {
  const hotSpotsData = data as ISectionsComponentHotSpot[];
  const articlesListData: IOneBigAndVerticalListData[] = [];
  const firstArticle = hotSpotsData[0];
  const firstArticleDate = firstArticle.dateDesc
    ? firstArticle.dateDesc
    : makeFriendlyDate(firstArticle.date);

  const bigArticleData: IOneBigAndVerticalListData = {
    description: extra?.disableDescription ? null : firstArticle.description,
    friendlyDate: extra?.disableDate ? null : firstArticleDate,
    imageUrl: firstArticle.thumbUrl,
    title: extra?.disableTitle ? null : firstArticle.title,
    url: prepareHotSpotUri(firstArticle),
  };

  hotSpotsData.map((hotSpot, id) => {
    const date = hotSpot.dateDesc
      ? hotSpot.dateDesc
      : makeFriendlyDate(hotSpot.date);

    if (id) {
      articlesListData.push({
        color: extra?.color,
        description: extra?.disableDescription ? null : hotSpot.description,
        friendlyDate: extra?.disableDate ? null : date,
        imageUrl: hotSpot.thumbUrl,
        title: extra?.disableTitle ? null : hotSpot.title,
        url: prepareHotSpotUri(hotSpot),
      });
    }
  });

  return {
    articlesListData,
    bigArticleData,
    color: extra?.color,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
  };
};
