import { getLocation } from 'connected-react-router';
import { connect } from 'react-redux';

import _Store from '@Store';

import routes from '@Routes/routes';

import TransferSummary from './TransferSummary.component';
import { ITransferSummaryFromState } from './TransferSummary.types';

const mapStateToProps = (state: _Store.IState): ITransferSummaryFromState => {
  return {
    isTransferReceived:
      getLocation(state).pathname === routes.receiveTicketSuccess ||
      getLocation(state).pathname === routes.pinReceiveSummary,
    transactionError: getLocation(state).search.indexOf('error') > -1,
  };
};

export default connect<ITransferSummaryFromState, void, {}, _Store.IState>(
  mapStateToProps
)(TransferSummary);
