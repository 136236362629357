import { connect } from 'react-redux';

import _Store from '@Store';

import { getEvent } from '@Model/event/selectors';
import { getHappeningEvent } from '@Model/happening/selectors';
import { translate } from '@Model/locale/selectors';
import { EVENT } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import Details from './Details.component';
import { IDetailsFromState } from './Details.types';

const mapStateToProps = (state: _Store.IState): IDetailsFromState => ({
  dateTranslate: translate(state)('dates'),
  event: getEvent(state) || getHappeningEvent(state),
  isLoading: getLoading(EVENT)(state),
});

export default connect<IDetailsFromState, void, {}, _Store.IState>(
  mapStateToProps
)(Details);
