import qs from 'query-string';

import EventsListService from '@Misc/classes/EventsListService';
import { ISelectedLocation } from '@Model/locations/types';
import { DynamicListingsKeys } from '@Model/pages/constants/dynamicListings';
import { HitFields } from '@Model/search/types';
import Logger from '@Services/Logger';

const { getDateHashFromDateString } = EventsListService;

const emptyArrayString = '[]';

export const getAndFormatDynamicListingParams = (
  componentParams?: Array<{ key: string; value: string }>,
  isIgnoreDynamicParams?: boolean,
  userLocation?: ISelectedLocation
) => {
  if (!componentParams?.length)
    return { dynamicFilters: [], dates: { from: null, to: null } };

  try {
    const query = qs.parse(window.location.search);

    const from = getDateHashFromDateString(
      componentParams.find((param) => param.key === 'from')?.value
    );
    const to = getDateHashFromDateString(
      componentParams.find((param) => param.key === 'to')?.value
    );

    const dates = {
      from: from ? from : null,
      to: to ? to : null,
    };

    const itemsLimit = Number(
      componentParams.find((param) => param.key === 'itemsLimit')?.value
    );

    const dynamicFilters: Array<{ [key: string]: string }> = [];
    const formattedQueryParams: Array<{ key: string; value: string }> = [];
    const paramsReplacedWithDynamicOnes: Array<{ key: string; value: string }> =
      [];

    const formatParamsCallback = (param: { key: string; value: string }) => {
      const artistRegex = new RegExp(/artist[0-9]/gi);
      const placeRegex = new RegExp(/place[0-9]/gi);
      const tagRegex = new RegExp(/tag[0-9]/gi);

      const isArtistParam = artistRegex.test(param.key);
      const isPlaceParam = placeRegex.test(param.key);
      const isTagParam = tagRegex.test(param.key);
      const isUserLocationFlagParam = param.key === 'isUsingUserLocation';
      const isItemsLimitParam = param.key === 'itemsLimit';
      const isDateParam = param.key === 'from' || param.key === 'to';

      const isDynamicParam = isArtistParam || isPlaceParam || isTagParam;

      if (isArtistParam) {
        dynamicFilters.push({
          key: isNaN(Number(param.value))
            ? HitFields.artists_names
            : HitFields.artists_ids,
          value: param.value,
        });
      }

      if (isPlaceParam)
        dynamicFilters.push({
          key: isNaN(Number(param.value))
            ? HitFields.place_name
            : HitFields.place_id,
          value: param.value,
        });

      if (isTagParam)
        dynamicFilters.push({
          key: isNaN(Number(param.value))
            ? HitFields.public_tags_names
            : HitFields.public_tags_ids,
          value: param.value,
        });

      if (
        !isDynamicParam &&
        !isUserLocationFlagParam &&
        !isDateParam &&
        !isItemsLimitParam
      )
        dynamicFilters.push(param);

      if (
        userLocation?.name &&
        !!componentParams.find(
          (par) => isUserLocationFlagParam && par.value === 'true'
        ) &&
        !componentParams.find(
          (par) =>
            par.key === HitFields.locations_ids ||
            par.key === HitFields.locations_names
        )
      )
        dynamicFilters.push({
          key: HitFields.locations_names,
          value: userLocation.name,
        });
    };

    if (isIgnoreDynamicParams) {
      componentParams.map(formatParamsCallback);

      return { dynamicFilters, dates, itemsLimit };
    }

    const lastPurchaseArtists: string[] | undefined = JSON.parse(
      sessionStorage.getItem(DynamicListingsKeys.lastPurchaseArtists) ||
        emptyArrayString
    );
    const lastPurchasePlaces: string[] | undefined = JSON.parse(
      sessionStorage.getItem(DynamicListingsKeys.lastPurchasePlaces) ||
        emptyArrayString
    );
    const lastPurchaseTags: string[] | undefined = JSON.parse(
      sessionStorage.getItem(DynamicListingsKeys.lastPurchaseTags) ||
        emptyArrayString
    );

    const lastPurchaseParams: Array<{ key: string; value: string }> = [];

    if (lastPurchaseArtists)
      lastPurchaseArtists.map((value, index) =>
        lastPurchaseParams.push({ key: `artist${index}`, value })
      );

    if (lastPurchasePlaces)
      lastPurchasePlaces.map((value, index) =>
        lastPurchaseParams.push({ key: `place${index}`, value })
      );

    if (lastPurchaseTags)
      lastPurchaseTags.map((value, index) =>
        lastPurchaseParams.push({ key: `tag${index}`, value })
      );

    Object.entries(query).map((param) => {
      if (typeof param[1] === 'string')
        formattedQueryParams.push({ key: param[0], value: param[1] });
    });

    /**
     * Only load lastPurchaseParams if there are no query params.
     */
    if (!formattedQueryParams.length)
      lastPurchaseParams.map((param) => {
        formattedQueryParams.push(param);
      });

    componentParams.forEach((componentParam) => {
      const updatedParam = formattedQueryParams.find(
        (queryParam) => componentParam.key === queryParam.key
      );

      if (!!updatedParam) paramsReplacedWithDynamicOnes.push(updatedParam);
      else paramsReplacedWithDynamicOnes.push(componentParam);
    });

    paramsReplacedWithDynamicOnes.map(formatParamsCallback);

    return {
      dynamicFilters,
      dates,
      itemsLimit,
    };
  } catch (e: any) {
    Logger.error('getAndFormatDynamicListingParams', e);
  }
};
