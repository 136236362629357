import { createSelector } from 'reselect';

import _Store from '@Store';

import { specialEventNames } from './../constants/specialEvents';
import { getEvent } from './../selectors';
import { IEventFull } from './../types';

// names of special events
const BLOK_PARTY_EVENT_ID = 3108789;
const LAS_FESTIVAL_EVENT_ID = 3132044;
const SBM_EVENT_ID = 3132150;
const EKIPA_EVENT_WYMIANA_ID = 3139751;
const EKIPA_EVENT_OPIEKUN_ID = 3139687;
const ALICE_COOPER_RUNDATE_ID = 2332462;
const SHAGGY_LATO_W_PLENERZE_RUNDATE_ID = 2335012;
const FEST_FESTIVAL_2022_RUNDATE_ID = 2342541;
const ON_AIR_FESTIVAL = 2344182;
const IGO_WROCLAW = 2346355;
const IGO_LODZ = 2346357;
const IGO_GDANSK = 2346358;
const IGO_BYDGOSZCZ = 2346359;
const IGO_POZNAN = 2346360;
const IGO_KRAKOW = 2346361;
const IGO_WARSZAWA = 2346362;
const SNOWFEST_2023 = 2347527;
const DREAM_THEATER = 2351019;
const JIMEK_I_GOSCIE = 2352480;
const JIMEK_HISTORIA_POLSKIEGO_HH = 2352479;
const MATA_SZCZECIN = 2355879;
const MATA_GLIWICE = 2355881;
const RADZIMIR_WARSZAWA_1 = 2356567;
const RADZIMIR_WARSZAWA_2 = 2356568;
const RADZIMIR_WROCLAW = 2356573;
const RADZIMIR_LUSLAWICE = 2356571;
const HEY30 = 2368584;
const HEY30_SECOND_DATE = 2369599;

export const specialEvent = createSelector<
  _Store.IState,
  IEventFull | null,
  specialEventNames | null
>([getEvent], (event) => {
  if (event?.eventId === BLOK_PARTY_EVENT_ID) {
    return specialEventNames.blokParty;
  }
  if (event?.eventId === LAS_FESTIVAL_EVENT_ID) {
    return specialEventNames.lasFestival;
  }
  if (event?.eventId === SBM_EVENT_ID) {
    return specialEventNames.sbmEvent;
  }
  if (
    event?.eventId === EKIPA_EVENT_WYMIANA_ID ||
    event?.eventId === EKIPA_EVENT_OPIEKUN_ID
  ) {
    return specialEventNames.ekipaEvent;
  }
  if (event?.rundateId === ALICE_COOPER_RUNDATE_ID) {
    return specialEventNames.aliceCooperRundate;
  }
  if (event?.rundateId === SHAGGY_LATO_W_PLENERZE_RUNDATE_ID) {
    return specialEventNames.shaggyRundate;
  }
  if (event?.rundateId === FEST_FESTIVAL_2022_RUNDATE_ID) {
    return specialEventNames.festFestival2022Rundate;
  }
  if (event?.rundateId === ON_AIR_FESTIVAL) {
    return specialEventNames.onAirFestival;
  }
  if (event?.rundateId === IGO_WROCLAW) {
    return specialEventNames.igoWroclaw;
  }
  if (event?.rundateId === IGO_LODZ) {
    return specialEventNames.igoLodz;
  }
  if (event?.rundateId === IGO_GDANSK) {
    return specialEventNames.igoGdansk;
  }
  if (event?.rundateId === IGO_BYDGOSZCZ) {
    return specialEventNames.igoBydgoszcz;
  }
  if (event?.rundateId === IGO_POZNAN) {
    return specialEventNames.igoPoznan;
  }
  if (event?.rundateId === IGO_KRAKOW) {
    return specialEventNames.igoKrakow;
  }
  if (event?.rundateId === IGO_WARSZAWA) {
    return specialEventNames.igoWarszawa;
  }
  if (event?.rundateId === SNOWFEST_2023) {
    return specialEventNames.snowFest2023;
  }
  if (event?.rundateId === DREAM_THEATER) {
    return specialEventNames.dreamTheater;
  }
  if (event?.rundateId === JIMEK_I_GOSCIE) {
    return specialEventNames.jimekIGoscie;
  }
  if (event?.rundateId === JIMEK_HISTORIA_POLSKIEGO_HH) {
    return specialEventNames.jimekHistoriaPolskiegoHH;
  }
  if (event?.rundateId === MATA_SZCZECIN) {
    return specialEventNames.mataSzczecin;
  }
  if (event?.rundateId === MATA_GLIWICE) {
    return specialEventNames.mataGliwice;
  }
  if (event?.rundateId === RADZIMIR_WARSZAWA_1) {
    return specialEventNames.radzimirWarszawa1;
  }
  if (event?.rundateId === RADZIMIR_WARSZAWA_2) {
    return specialEventNames.radzimirWarszawa2;
  }
  if (event?.rundateId === RADZIMIR_WROCLAW) {
    return specialEventNames.radzimirWroclaw;
  }
  if (event?.rundateId === RADZIMIR_LUSLAWICE) {
    return specialEventNames.radzimirLuslawice;
  }
  if (event?.rundateId === HEY30) {
    return specialEventNames.hey30;
  }
  if (event?.rundateId === HEY30_SECOND_DATE) {
    return specialEventNames.hey30SecondDate;
  }

  return null;
});

export default specialEvent;
