import React, { useState } from 'react';

import { Icon } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import cn from 'classnames';

import ToolTip from '@Compo/reusable/ToolTip';
import icons from '@Misc/helpers/icons';

import ButtonDescription from './../ButtonDescription';
import styles from './Pools.module.scss';
import { IPoolsProps } from './Pools.types';

const DEFAULT_TICKET_AMOUNT = 2;

const PoolComponent: React.FC<IPoolsProps> = ({
  eventSlug,
  lang,
  onlyOneTicket,
  pool,
  rundateSlug,
  selectPool,
  selectTicket,
  translate,
  eventProceed,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const handleClick = () => {
    setIsDetailsOpen(!isDetailsOpen);
  };
  const choosePool = () => {
    selectPool(eventSlug, rundateSlug);
    selectTicket(
      pool.id,
      DEFAULT_TICKET_AMOUNT,
      pool.title,
      pool.currency,
      pool.payments,
      pool.price,
      pool.serviceFee,
      pool.isDetailedPurchaseRequired,
      pool.forms
    );
    eventProceed(lang.choose);
  };

  const disableButton = !pool.active;

  return (
    <div
      className={cn(
        styles.borderContainer,
        !onlyOneTicket && styles.manyTickets
      )}
      data-test-id={'pool-border-container'}
    >
      <div
        className={cn(
          styles.singleTicketContainer,
          !onlyOneTicket && styles.withoutDetails
        )}
      >
        <span className={cn(styles.ticketIcon, icons.ticket)} />
        <div className={styles.ticketKind}>{pool.title}</div>
        <div className={styles.singleTicketContainerPrice}>
          <div className={styles.priceContainer}>
            <div className={styles.priceTextContainer}>
              <span className={styles.priceText}>od </span>
              <span className={styles.price}>{pool.price}</span>
              <span className={styles.currency}> {pool.currency}</span>
              <ToolTip
                title={lang.serviceFeeTooltip}
                className={styles.tooltip}
              >
                <Icon component={InfoIcon} />
              </ToolTip>
            </div>
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <button
            className={cn(styles.button, disableButton && styles.disabled)}
            onClick={choosePool}
          >
            {lang.choose}
          </button>
        </div>
        {onlyOneTicket && (
          <div className={styles.mobileDisplayNone}>
            <ButtonDescription
              buttonText={translate.details}
              onClick={handleClick}
            />
          </div>
        )}
      </div>
      {onlyOneTicket && (
        <div className={styles.detailsTitle}>
          <ButtonDescription
            buttonText={translate.details}
            onClick={handleClick}
          />
        </div>
      )}
      {isDetailsOpen && (
        <div className={styles.descriptionContainer}>
          {pool.description ? <p>{pool.description}</p> : lang.noDescription}
        </div>
      )}
    </div>
  );
};

export default PoolComponent;
