import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  setExtendedSlot,
  setExtendedSlotModalVisible,
} from '@Model/happening/actions';
import {
  getExtendedSlotModalVisible,
  getExtendedSlots,
} from '@Model/happening/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';

import MultipleSlotModal from './MultipleSlotModal.component';
import {
  IMultipleSlotModalFromDispatch,
  IMultipleSlotModalFromState,
} from './MultipleSlotModal.types';

const mapStateToProps = (
  state: _Store.IState
): IMultipleSlotModalFromState => ({
  extendedSlots: getExtendedSlots(state),
  isEmbed: !!getIframeParams(state),
  modalOpen: getExtendedSlotModalVisible(state),
});
const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IMultipleSlotModalFromDispatch => ({
  setFormExtendedSlot: (slot) => dispatch(setExtendedSlot(slot)),
  setModalOpen: (open) => dispatch(setExtendedSlotModalVisible(open)),
});

export default connect<
  IMultipleSlotModalFromState,
  IMultipleSlotModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(MultipleSlotModal);
