import React, { useContext, useEffect } from 'react';

import { connect, useFormikContext } from 'formik';
import { withRouter } from 'react-router';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import { IBuyingOnlineFormValues } from '@Compo/buying/Online/Online.types';
import * as CONST from '@Compo/buying/constants/constants';
import config from '@Config';
import deepCopy from '@Misc/helpers/deepCopy';
import routes from '@Routes/routes';

import Summary from './Summary.component';
import { ISummaryProps } from './Summary.types';

const SummaryFormik = (props: any) => {
  const { values } = useFormikContext<IBuyingOnlineFormValues>();

  const copiedProps: ISummaryProps = {
    ...props,
    summary: deepCopy(props.summary),
  };

  const shipment = copiedProps.shipments.find(
    (value) => values.pickUpWay === String(value.productId)
  );

  if (copiedProps.summary) {
    if (values.pickUpWay && values.pickUpWay === CONST.PICK_UP_WAY_POST) {
      copiedProps.summary.finalPrice += config.buy.courierDeliveryPrice;
      copiedProps.withCourier = true;

      if (values.giftWrapper) {
        copiedProps.summary.finalPrice += config.buy.giftWrapperPrice;
        copiedProps.giftWrap = true;
      }
    } else if (shipment) {
      copiedProps.summary.finalPrice += shipment.price;
      copiedProps.selectedShipment = shipment;
    }
  }

  const state = useContext(clearContext);
  useEffect(() => {
    if (
      location.pathname === routes.basketBuy &&
      state?.basketItems.length === 0
    ) {
      props.history.push(routes.basket);
    }
  }, [state?.basketItems.length]);

  return <Summary {...copiedProps} />;
};

export default withRouter(connect(SummaryFormik));
