import React from 'react';

import Event from '@Compo/Event';
import Helmet from '@Compo/helmet/components/WithEvent';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import routes from '@Routes/routes';

import { IActivityProps } from './Activity.types';

const ActivityPage = ({ lang }: IActivityProps) => (
  <MuiGrid withoutSidePaddings={true}>
    <Helmet />
    <MuiSectionContainer>
      <MuiBreadcrumbs
        base={[
          {
            link: routes.events,
            name: lang.activities,
          },
        ]}
      />
      <Event />
    </MuiSectionContainer>
  </MuiGrid>
);

export default ActivityPage;
