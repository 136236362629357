import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import { ICloudinaryParams, IGetImagesSuccessPayload } from './../types';

export const loadImages = createStandardAction(CONSTS.LOAD_IMAGES)();
export const getImagesByCloudinaryPrefixAndSlug = createStandardAction(
  CONSTS.GET_IMAGES_BY_CLOUDINARY_PREFIX_AND_SLUG
)<ICloudinaryParams>();

// Private actions
export const getImages = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<string, IGetImagesSuccessPayload, Error>();
