import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEventFull } from './../types';
import getEvent from './getEvent';

const getIsEventForPremiumUsers = createSelector<
  _Store.IState,
  IEventFull | null,
  boolean
>([getEvent], (event) => Boolean(event?.isPremiumEvent));

export default getIsEventForPremiumUsers;
