import { selectors } from 'goingapp-shared';
import { createSelector } from 'reselect';

import { IAvailabilitiesData } from './../types';
import get from './get';
import getSelected from './getSelected';

const getAvailabilitiesSelector = createSelector(
  [get, getSelected],
  (happening, selected): IAvailabilitiesData =>
    selectors.getAvailabilities(happening, selected)
);

export default getAvailabilitiesSelector;
