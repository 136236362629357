import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useGlobalStyles from '../../global.styles';
import Paper from '../Paper';
import useStyles from './Summary.styles';
import { ISummaryProps } from './Summary.types';

const Summary = ({
  handlePrevStep,
  formValues,
  confirmPersonalData,
}: ISummaryProps) => {
  const classes = useStyles();
  const globals = useGlobalStyles();
  const { entryList: lang } = useDictionary();

  const prepareRequest = () => {
    confirmPersonalData();
  };

  return (
    <Paper
      title={lang.dataConfirmation.title}
      helperText={lang.dataConfirmation.subtitle}
    >
      <Grid
        container={true}
        className={classes.container}
        justifyContent="center"
      >
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" className={classes.value}>
                {formValues.email}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography variant="body1" className={classes.label}>
                {lang.dataConfirmation.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" className={classes.value}>
                {formValues.firstName}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography variant="body1" className={classes.label}>
                {lang.dataConfirmation.firstName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" className={classes.value}>
                {formValues.lastName}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography variant="body1" className={classes.label}>
                {lang.dataConfirmation.lastName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" className={classes.value}>
                {formValues.dateOfBirth}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography variant="body1" className={classes.label}>
                {lang.dataConfirmation.birthDay}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Grid
            container={true}
            justifyContent="center"
            className={globals.column}
          >
            <Grid item={true}>
              <Button
                className={globals.button}
                variant="text"
                color="primary"
                onClick={handlePrevStep}
              >
                {lang.dataConfirmation.goBack}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                className={globals.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={prepareRequest}
              >
                {lang.dataConfirmation.confirm}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default Summary;
