import { createSelector } from 'reselect';

import _Store from '@Store';

import { IProductSelect } from './../types';
import getSelectedProducts from './getSelectedProducts';

const isAnyProductSelected = createSelector<
  _Store.IState,
  IProductSelect[],
  boolean
>([getSelectedProducts], (selectedProducts) => selectedProducts.length > 0);

export default isAnyProductSelected;
