import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { IMultilineCtaButton } from '@Model/pages/types/sections';

const useStyles = (
  isEmpik: boolean,
  color?: string,
  isSectionBgDark?: boolean,
  ctaButton?: IMultilineCtaButton,
  secondaryCtaButton?: IMultilineCtaButton
) =>
  makeStyles((theme: Theme) => {
    const contrastColor = {
      color: color
        ? color + '!important'
        : isEmpik
        ? theme.palette.primary.main + '!important'
        : theme.palette.primary.contrastText + '!important',
    };

    const fontColor = {
      color: color
        ? color + '!important'
        : isSectionBgDark
        ? theme.palette.grey[50] + '!important'
        : isEmpik
        ? theme.palette.primary.main + '!important'
        : theme.palette.text.primary + '!important',
    };

    const placeFontColor = {
      color: color
        ? color
        : isSectionBgDark
        ? theme.palette.grey[50] + '!important'
        : isEmpik
        ? theme.palette.grey[900] + '!important'
        : theme.palette.text.primary + '!important',
    };

    return createStyles({
      contrastColor: {
        ...contrastColor,
        '& a': {
          '& :visited': contrastColor,
          ...contrastColor,
        },
      },
      fontColor: {
        ...fontColor,
        '& a': {
          '& :visited': fontColor,
          ...fontColor,
        },
      },
      placeFontColor: {
        ...placeFontColor,
        '& a': {
          '& :visited': placeFontColor,
          ...placeFontColor,
        },
      },
      // tslint:disable-next-line:object-literal-sort-keys
      ctaButton: {
        background: ctaButton?.bgColor,
        color: ctaButton?.color,
        padding: isEmpik ? '0.25rem 0' : '0.25rem',
      },
      horizontalMultiline: {
        marginBottom: '2rem !important', // regular CSS has precedence over MuiStyles so we need to use !important
      },
      secondaryCtaButton: {
        background: secondaryCtaButton?.bgColor,
        color: secondaryCtaButton?.color,
        padding: isEmpik ? '0.25rem 0' : '0.25rem',
      },
      root: {
        backgroundColor: theme.palette.background.paper,
      },
      secondary: {
        color: theme.palette.text.secondary,
      },
    });
  });

export default useStyles;
