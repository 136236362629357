import { createSelector } from 'reselect';

import _Store from '@Store';

import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import isEmptyObject from '@Misc/helpers/isEmptyObject';
import _L from '@Misc/helpers/local';
import * as MODULES from '@Routes/modules';
import routes from '@Routes/routes';
import { IBuyMatchParams } from '@Routes/types';

import { get as getInternalRouter } from './../selectors';
import { IInternalRouterReducer } from './../types';

const prepareBackUrl = createSelector<
  _Store.IState,
  IInternalRouterReducer,
  string | null
>([getInternalRouter], ({ internalRouter: { module, params }, embed }) => {
  if (config.theme.isGoing) {
    return null;
  }

  let backUrl = routes.index;

  if (module === MODULES.BUY && params) {
    const { eventSlug, rundateSlug } = params as IBuyMatchParams;

    backUrl = fillUrlWithValues(
      isEmptyObject(embed) ? routes.event : routes.pinEvent,
      [':eventSlug', ':rundateSlug'],
      [eventSlug, rundateSlug]
    );
  }

  return backUrl;
});

export default prepareBackUrl;
