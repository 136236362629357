import React, { FC } from 'react';

import CarouselFixed from '@Compo/reusable/CarouselFixed';

import styles from './CarouselBrick.module.scss';
import { ICarouselBrickProps } from './CarouselBrick.types';

const CarouselBrick: FC<ICarouselBrickProps> = ({
  id,
  images,
  isLoading,
  compositionIdentifier,
}) => {
  return (
    <div className={styles.carousel} id={id}>
      <CarouselFixed
        images={images}
        isLoading={isLoading}
        compositionIdentifier={compositionIdentifier}
        brickId={id}
      />
    </div>
  );
};

export default CarouselBrick;
