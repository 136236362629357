import React from 'react';

import Search from '@Compo/Search';
import SearchStructure from '@Compo/Search/components/SearchStructure';
import Helmet from '@Compo/helmet/components/CompositionPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import config from '@Config';

const SEARCH_PLACEHOLDER_TEXT = 'Znajdź wydarzenie, artystę lub miejsce';

const SearchPage = () => (
  <MuiGrid>
    <Helmet />
    <FullTriangleBackground />
    <MuiSectionContainer>
      <Search
        placeholder={SEARCH_PLACEHOLDER_TEXT}
        showApostrophe={config.theme.isEmpik}
      >
        <SearchStructure />
      </Search>
    </MuiSectionContainer>
  </MuiGrid>
);

export default SearchPage;
