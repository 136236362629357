import { createSelector } from 'reselect';

import _Store from '@Store';

import {
  ISelectionReducerCurrentKey,
  ISelectionReducerData,
  ISelectionShort,
} from '../types';
import getCurrentKey from './getCurrentKey';
import getData from './getData';

const getSelection = createSelector<
  _Store.IState,
  ISelectionReducerCurrentKey,
  ISelectionReducerData,
  ISelectionShort | null
>([getCurrentKey, getData], (key, data) => {
  if (key && key in data) {
    return data[key] as ISelectionShort;
  }

  return null;
});

export default getSelection;
