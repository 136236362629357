import { BROWSER, SERVER, __BASE__ } from '@Model/__base__/constants/constants';
import { IMode } from '@Model/__base__/types';

const preloadedState = {
  // Add shared state
  ...(typeof window !== 'undefined' && window.__PRELOADED_STATE__),

  // Add base state - it has to be placed after pre-loaded state
  // because it should override some settings if needed
  [__BASE__]: {
    mode: (typeof window === 'undefined' ? SERVER : BROWSER) as IMode,
  },
};

export default preloadedState;
