import { connectRouter } from 'connected-react-router';
import { createMemoryHistory } from 'history';
import { combineReducers } from 'redux';

// Base reducer
import { __BASE__ } from '@Model/__base__/constants/constants';
import baseReducer from '@Model/__base__/reducer';
// App reducer: import
import analyticsReducer from '@Model/analytics/reducer';
import appReducer from '@Model/app/reducer';
import articleReducer from '@Model/article/reducer';
import artistReducer from '@Model/artist/reducer';
import authReducer from '@Model/auth/reducer';
import basketReducer from '@Model/basket/reducer';
import breadcrumbReducer from '@Model/breadcrumb/reducer';
import entryListReducer from '@Model/entryList/reducer';
import errorsReducer from '@Model/errors/reducer';
import eventReducer from '@Model/event/reducer';
import eventsReducer from '@Model/events/reducer';
import happeningReducer from '@Model/happening/reducer';
import imagesReducer from '@Model/images/reducer';
import internalRouterReducer from '@Model/internalRouter/reducer';
import landingPageReducer from '@Model/landingPage/reducer';
import localeReducer from '@Model/locale/reducer';
import locationsReducer from '@Model/locations/reducer';
import modalsReducer from '@Model/modals/reducer';
import notificationsReducer from '@Model/notifications/reducer';
import pagesReducer from '@Model/pages/reducer';
import placeReducer from '@Model/place/reducer';
import placeEventsReducer from '@Model/placeEvents/reducer';
import poolsReducer from '@Model/pools/reducer';
import premiumReducer from '@Model/premium/reducer';
import productsReducer from '@Model/products/reducer';
import isFirstRenderingReducer from '@Model/router/reducer/isFirstRendering';
import searchReeducer from '@Model/search/reducer';
import selectionReducer from '@Model/selection/reducer';
import sendgridReducer from '@Model/sendgrid/reducer';
import shipmentReducer from '@Model/shipment/reducer';
import stateReducer from '@Model/state/reducer';
import themeReducer from '@Model/theme/reducer';
import ticketsReducer from '@Model/tickets/reducer';
import trafficParamsReducer from '@Model/trafficParams/reducer';
import transactionReducer from '@Model/transaction/reducer';

import history from './history';

const reducerList = {
  // __base__
  [__BASE__]: baseReducer,

  // router
  router: connectRouter(history),
  routerIsFirstRendering: isFirstRenderingReducer,

  // App reducer: usage
  analytics: analyticsReducer,
  app: appReducer,
  article: articleReducer,
  artist: artistReducer,
  auth: authReducer,
  basket: basketReducer,
  breadcrumb: breadcrumbReducer,
  errors: errorsReducer,
  event: eventReducer,
  events: eventsReducer,
  happening: happeningReducer,
  images: imagesReducer,
  internalRouter: internalRouterReducer,
  landingPage: landingPageReducer,
  locale: localeReducer,
  locations: locationsReducer,
  modals: modalsReducer,
  notifications: notificationsReducer,
  pages: pagesReducer,
  place: placeReducer,
  placeEvents: placeEventsReducer,
  pools: poolsReducer,
  premium: premiumReducer,
  products: productsReducer,
  search: searchReeducer,
  sendgrid: sendgridReducer,
  selection: selectionReducer,
  shipment: shipmentReducer,
  state: stateReducer,
  theme: themeReducer,
  tickets: ticketsReducer,
  transaction: transactionReducer,
  trafficParams: trafficParamsReducer,
  entryList: entryListReducer,
};

/* @@STORE_COMPONENT@@ */
const rootReducer = combineReducers(reducerList);

let hmrRootReducer = rootReducer;

export const createRootReducerForSSR = (initialUrl: string) => {
  return combineReducers({
    ...reducerList,
    router: connectRouter(
      createMemoryHistory({
        initialEntries: [initialUrl],
      })
    ),
    routerIsFirstRendering: () => true,
  });
};

////////////////////////////////////////////////////////////////////////////////
//                           DEVELOPMENT MODE ONLY                            //
////////////////////////////////////////////////////////////////////////////////
if (process.env.NODE_ENV === 'development') {
  if (typeof localStorage !== 'undefined') {
    const hmr = localStorage.getItem('@@hmr');

    if (hmr) {
      const state = JSON.parse(hmr);

      if (state) {
        localStorage.removeItem('@@hmr');

        hmrRootReducer = (_state: any, action: any) => {
          if (action.type === '@@INIT') {
            return state;
          }

          return rootReducer(_state, action);
        };
      }
    }
  }
}
////////////////////////////////////////////////////////////////////////////////

export default hmrRootReducer as typeof rootReducer;
