import React, { Suspense } from 'react';

import BetaEvent from '@Compo/BetaEvent';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import SimpleLoading from '@Compo/reusable/SimpleLoading';
import routes from '@Routes/routes';

const EventPage = () => {
  return (
    <MuiGrid>
      <MuiSectionContainer>
        <MuiBreadcrumbs
          base={[
            {
              link: routes.events,
              name: 'wydarzenia',
            },
          ]}
        />
        <Suspense fallback={<SimpleLoading />}>
          <BetaEvent />
        </Suspense>
      </MuiSectionContainer>
    </MuiGrid>
  );
};

export default EventPage;
