import React, { useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import styles from './Description.module.scss';
import useStyles from './Description.styles';
import { IDescriptionProps } from './Description.types';

const Description = ({ text, translate }: IDescriptionProps) => {
  const [showDescription, setShowDescription] = useState(false);
  const { isMobile } = useMuiBreakpoints();

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const toggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setShowDescription(!showDescription);
  };

  return (
    <div
      className={cn(
        styles.description,
        muiStyles.description,
        muiStyles.overrideFlex
      )}
    >
      <button
        className={cn(
          muiStyles.primaryText,
          isMobile ? muiStyles.centerSelf : muiStyles.alignSelfLeft
        )}
        onClick={toggle}
      >
        {translate.details}
        <i
          className={cn(styles.arrow, muiStyles.arrow, {
            [styles.down]: !showDescription,
            [styles.up]: showDescription,
          })}
        />
      </button>
      {showDescription && (
        <div
          className={cn(
            styles.descriptionText,
            muiStyles.secondaryText,
            isMobile && muiStyles.centerSelf
          )}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Description;
