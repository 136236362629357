import { ILeadSectionOwnProps } from '@Compo/Bricks/components/LeadSection/LeadSection.types';

import {
  ISearchExtraData,
  ISectionsComponentDataMutable,
  ISectionsComponentLead,
} from './../types/sections';

export const section_lead = (
  data: ISectionsComponentDataMutable,
  extra?: ISearchExtraData | null,
  background?: string
): ILeadSectionOwnProps => {
  if (!data) {
    return {} as ILeadSectionOwnProps;
  }

  const { description, title } = data[0] as ISectionsComponentLead;

  return {
    background,
    color: extra?.color,
    description,
    showDropdown: extra?.showDropdown || false,
    title,
  };
};
