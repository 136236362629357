import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  delay as delay$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import { getParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { IPlaceMatchParams } from '@Routes/types';
import { IPlaceEventsAwsResponse } from '@Services/$places-api/types';

import {
  getPlaceEvents,
  placeEventsMounted,
  requestMoreEvents,
  resetState,
} from './../actions';
import { getPlaceEvents as getPlaceEventsSelector } from './../selectors';
import { IEvent } from './../types';

export const firstPlaceEventsRequest: _Store.IEpic = (
  action$,
  state$,
  { placesApi }
) => {
  return action$.pipe(
    filter$(isActionOf(placeEventsMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as IPlaceMatchParams;

      if (
        params &&
        placesApi.makeKeyFromParams(params) === state.placeEvents.placeKey
      ) {
        return EMPTY$;
      }

      return of$(resetState(), getPlaceEvents.request());
    })
  );
};

export const loadMoreWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(requestMoreEvents)),
    mergeMap$(() => {
      return of$(getPlaceEvents.request());
    })
  );
};

export const fetchEventsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { placesApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPlaceEvents.request)),
    /*
     * @TODO:
     * We use delay to control flow of calling epics.
     * Without this delay we first create request, and after that cancel.
     * Correct approach is: first tap and cancel, and later  make a request.
     */
    delay$(config.api.cancellationFixDelay),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { eventsData, nextPage } = getPlaceEventsSelector(state);

      const params = getParams(state) as IPlaceMatchParams;
      const dateTranslate = translate(state)('dates');

      return from$(placesApi.getManyEvents(params?.slug)).pipe(
        map$((data: IPlaceEventsAwsResponse) => {
          const { items } = data;
          const normalizedData: IEvent[] = items.map((item) =>
            placesApi.normalizeShortEvent(item, dateTranslate)
          );

          return getPlaceEvents.success({
            eventsData: [...eventsData, ...normalizedData],
            nextPage: nextPage + 1,
            placeKey: placesApi.makeKeyFromParams(params),
          });
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => placesApi.cancelEvents())
          )
        ),
        catchError$((error: Error) => of$(getPlaceEvents.failure(error)))
      );
    })
  );
};
