import { CSSProperties } from 'react';

/**
 * Function that set background image to element
 *
 * @param {string} url - image url.
 * @return {CSSProperties} - css style setting passed image as background
 */
export const getBackgroundImageStyle = (
  url: string | null | undefined
): CSSProperties => ({
  backgroundImage: url ? `url(${url})` : 'none',
});
