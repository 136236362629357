import React, { useEffect, useState } from 'react';

import cn from 'classnames';

import classes from './Buttons.classes';
import styles from './Buttons.module.scss';
import useStyles from './Buttons.styles';
import { IButtonProps } from './Buttons.types';

const Buttons = ({
  product,
  howManyProducts,
  selectProducts,
  selectedProducts,
  generateIdempotencyKey,
  selectedTheme,
}: IButtonProps) => {
  const [amount, setAmount] = useState(0);
  const muiStyles = useStyles();

  const selectedProduct = selectedProducts.find(
    (element) => element.id === product?.id
  );

  useEffect(() => {
    if (!selectedProduct) {
      setAmount(0);
    }
  }, [selectedProduct]);

  const subtract = () => {
    if (amount > 0) {
      setAmount(amount - 1);
      generateIdempotencyKey();
    }
  };

  const add = () => {
    if (amount >= howManyProducts) {
      return;
    }
    generateIdempotencyKey();
    setAmount(amount + 1);
  };

  useEffect(() => {
    if (product) {
      selectProducts(product, amount);
    }
  }, [amount]);

  const isDefaultTheme = selectedTheme === 'default';

  return (
    <div className={cn(styles.buttons, classes.buttons)}>
      <button
        className={cn(styles.button, !isDefaultTheme && muiStyles.button)}
        onClick={subtract}
        disabled={amount === 0}
        type={'button'}
      >
        -
      </button>
      <p className={cn(styles.amount, !isDefaultTheme && muiStyles.amount)}>
        {amount}
      </p>
      <button
        className={cn(styles.button, !isDefaultTheme && muiStyles.button)}
        onClick={add}
        disabled={amount === howManyProducts}
        type={'button'}
      >
        +
      </button>
    </div>
  );
};

export default Buttons;
