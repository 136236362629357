import React, { FC, useEffect } from 'react';

import ResizeObserver from 'resize-observer-polyfill';

import { IResizer } from './Resizer.types';

const Resizer: FC<IResizer> = ({ handleSetSizes, isBrowser }) => {
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      handleSetSizes(entry.contentRect.width, entry.contentRect.height);
    }
  });

  useEffect(() => {
    if (isBrowser) {
      resizeObserver.observe(document.body);
    }
  }, []);

  return null;
};

export default Resizer;
