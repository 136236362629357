import React, { FC } from 'react';

import { Form, Formik } from 'formik';

import FormField from '@Compo/forms/FormField';

import styles from './PasswordReset.module.scss';
import { makeValidationSchema } from './PasswordReset.selector';
import {
  IPasswordResetPayload,
  IPasswordResetProps,
} from './PasswordReset.types';

const PasswordReset: FC<IPasswordResetProps> = ({
  isLoading,
  lang,
  queryParams,
  submitForm,
}) => {
  const email = queryParams.email;
  const sanitizedEmail = typeof email !== 'string' ? email?.toString() : email;

  const initialValues: IPasswordResetPayload = {
    email: sanitizedEmail ? sanitizedEmail : '',
    languageId: '',
    password: '',
    passwordConfirmation: '',
    token: '',
  };

  return (
    <div className={styles.formWrapper}>
      <h2 className={styles.pageTitle}>{lang.pageTitle}</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={makeValidationSchema(lang)}
      >
        <Form>
          <label className={styles.label} htmlFor="email">
            <div className={styles.extraField}>{lang.emailAddress}</div>
            <FormField
              id="email"
              name="email"
              placeholder={lang.emailAddressBoundToAccount}
              type="text"
              isDisabled={true}
            />
          </label>
          <label className={styles.label} htmlFor="password">
            <div className={styles.extraField}>{lang.newPass}</div>
            <FormField
              id="password"
              name="password"
              placeholder={lang.newPass}
              type="password"
            />
          </label>
          <label className={styles.label} htmlFor="passwordConfirmation">
            <div className={styles.extraField}>{lang.confirmNewPass}</div>
            <FormField
              id="passwordConfirmation"
              name="passwordConfirmation"
              placeholder={lang.confirmNewPass}
              type="password"
            />
          </label>
          <button
            className={styles.submitButton}
            type="submit"
            disabled={isLoading}
          >
            {lang.submitButton}
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default PasswordReset;
