import axios, { CancelTokenSource } from 'axios';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';

import config from '@Config';
import AWSQueryBuilder from '@Misc/classes/AWSQueryBuilder';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import convertToBool from '@Misc/helpers/convertToBool';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';
import translatableDate from '@Misc/helpers/translatableDate';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import {
  IArtistBuyBox,
  IArtistFull,
  IArtistNormalized,
  IPastEvents,
} from '@Model/artist/types';
import { IEvent } from '@Model/events/types';
import { ILang } from '@Model/locale/types';
import {
  DEFAULT_DATE_TRANSLATE,
  EventsApi,
} from '@Services/$events-api/EventsApi';
import {
  IEventsAwsResponseData,
  IEventsHitResponse,
  IRundateResponseMutable,
} from '@Services/$events-api/types';
import { IPlaceResponse } from '@Services/$places-api/types';

import {
  IArtistResponse,
  IClosestPoolArtistHitResponse,
  IPastEventsHitResponse,
} from './types';

class ArtistApi extends EventsApi {
  private static getClosestPool(artistId: number): string {
    const closestTerms = [
      {
        name: 'has_pools',
        value: config.theme.isGoing ? ['0', '1'] : ['1'],
      },
      {
        name: 'sales_channels_ids',
        value: [config.app.salesChannelId.toString()],
      },
      {
        name: 'artists_ids',
        value: [String(artistId)],
      },
    ];

    const closestSort = [{ priority: 1, field: 'closest', sort: 'desc' }];

    const queryString = [
      AWSQueryBuilder.phrase([]),
      AWSQueryBuilder.rangeDate(
        new Date(new Date().setHours(0, 0, 0, 0)).toString(),
        ''
      ),
      AWSQueryBuilder.term(closestTerms),
    ];

    return AWSQueryBuilder.query('search', queryString.join(''), [
      AWSQueryBuilder.option('format', 'json'),
      AWSQueryBuilder.option('q.parser', 'lucene'),
      AWSQueryBuilder.option('size', 3),
      AWSQueryBuilder.option('sort', AWSQueryBuilder.sort(closestSort)),
      AWSQueryBuilder.option('expr.closest', '(_time%20-%20rundate_rundate)'),
    ]);
  }

  private static getPastEventsUrl(artistId: number): string {
    const terms = [
      {
        name: 'has_pools',
        value: config.theme.isGoing ? ['0', '1'] : ['1'],
      },
      {
        name: 'sales_channels_ids',
        value: [config.app.salesChannelId.toString()],
      },
      {
        name: 'artists_ids',
        value: [String(artistId)],
      },
    ];

    const sort = [{ priority: 1, field: 'rundate_rundate', sort: 'asc' }];

    const queryString = [
      AWSQueryBuilder.phrase([]),
      AWSQueryBuilder.rangeDate(
        '',
        new Date(new Date().setHours(0, 0, 0, 0)).toString()
      ),
      AWSQueryBuilder.term(terms),
    ];

    return AWSQueryBuilder.query('search', queryString.join(''), [
      AWSQueryBuilder.option('format', 'json'),
      AWSQueryBuilder.option('q.parser', 'lucene'),
      AWSQueryBuilder.option('size', 9),
      AWSQueryBuilder.option('sort', AWSQueryBuilder.sort(sort)),
    ]);
  }

  private cancelTokenArticle?: CancelTokenSource;

  public getArtistUrl(slug: string): string {
    return `${config.api.baseUrl}artist/${slug}`;
  }

  public getSingleArtist(slug: string): Promise<IArtistFull> {
    return new Promise((resolve, reject) => {
      const articleUrl = this.getArtistUrl(slug);
      this.cancelTokenArticle = axios.CancelToken.source();

      axios
        .get(
          articleUrl,
          withCacheHeader({
            cancelToken: this.cancelTokenArticle.token,
          })
        )
        .then(getData)
        .then((response) => {
          resolve(this.normalizeFullArticle(response));
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }

  public getClosestPool(
    artistId: number,
    dateTranslate: ILang = DEFAULT_DATE_TRANSLATE
  ): Promise<IArtistNormalized> {
    return new Promise((resolve, reject) => {
      const queryString = ArtistApi.getClosestPool(artistId);
      const urlClosestPools = `${config.api.awsUrl}${queryString}`;
      this.cancelTokenArticle = axios.CancelToken.source();

      axios
        .get(
          urlClosestPools,
          withCacheHeader({
            cancelToken: this.cancelTokenArticle.token,
          })
        )
        .then(getData)
        .then((response: IEventsAwsResponseData) => {
          return resolve({
            buyBox: this.normalizeClosestPool(response.hits.hit[0]),
            closestPools: response.hits.hit.map((item) =>
              this.normalizeShortEvent(item, dateTranslate)
            ),
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSimilarArtists(
    artistId: number,
    dateTranslate: ILang = DEFAULT_DATE_TRANSLATE
  ): Promise<IArtistNormalized> {
    return new Promise((resolve, reject) => {});
  }

  public getPastEvents(artistId: number): Promise<IPastEvents[]> {
    return new Promise((resolve, reject) => {
      const queryString = ArtistApi.getPastEventsUrl(artistId);
      const urlPastEvents = `${config.api.awsUrl}${queryString}`;
      this.cancelTokenArticle = axios.CancelToken.source();

      axios
        .get(
          urlPastEvents,
          withCacheHeader({
            cancelToken: this.cancelTokenArticle.token,
          })
        )
        .then(getData)
        .then((response: IEventsAwsResponseData) => {
          const data = response.hits.hit
            .map(this.normalizePastEvents)
            .reduce((acc, d) => {
              const found = acc.find((a) => a.caption === d.caption);
              const value = d.events;
              if (!found) {
                acc.push({ caption: d.caption, events: value });
              } else {
                found.events.push(...value);
              }

              return acc;
            }, [] as IPastEvents[]);

          return resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizePastEvents(response: IPastEventsHitResponse): IPastEvents {
    const { rundate_rundate, title_pl, event_slug, rundate_slug, place_name } =
      response.fields;

    return {
      caption: format(new Date(rundate_rundate), 'LLLL y', { locale: pl }),
      events: [
        {
          eventName: title_pl,
          eventSlug: event_slug,
          placeName: place_name,
          rundate: {
            day: format(new Date(rundate_rundate), 'd'),
            dayName: format(new Date(rundate_rundate), 'eee', {
              locale: pl,
            }).replace('.', ''),
          },
          rundateSlug: rundate_slug,
        },
      ],
    };
  }

  public normalizeFullArticle(response: IArtistResponse): IArtistFull {
    return {
      description: response.bio,
      id: response.id,
      imagesUrl: { alt: '', imgBig: '', imgSmall: '' },
      tags: response.tags.length
        ? [{ ...response.tags[0], isMain: true }, ...response.tags.slice(1)]
        : [],
      title: response.name,
      videoId: '',
    };
  }

  public normalizeClosestPool(
    response: IClosestPoolArtistHitResponse
  ): IArtistBuyBox {
    const {
      currency,
      event_slug,
      rundate_slug,
      buttonLabel,
      rundate_rundate,
      place_name,
      place_slug,
      city_name,
      price,
      has_pools,
      redirect_to_url,
      horizontal_image,
      title_pl,
      rundate_description,
      price_description_pl,
    } = response.fields;

    return {
      buttonLabel,
      currency,
      eventSlug: event_slug,
      friendlyHour: EventsApi.getFriendlyHour(
        rundate_rundate,
        rundate_description
      ),
      imagesUrl: horizontal_image,
      isAvailable: convertToBool(has_pools),
      placeCityName: city_name,
      placeName: place_name,
      placeSlug: place_slug,
      price: getPriceFormatted(Number(price)),
      priceDescription: EventsApi.getPriceDescription(price_description_pl),
      redirectToUrl: redirect_to_url || null,
      rundateDescription: rundate_description,
      rundateSlug: rundate_slug,
      startDate: rundate_rundate,
      title: title_pl,
    };
  }

  public normalizeShortEvent(
    eventResponse: IRundateResponseMutable,
    dateTranslate: ILang = DEFAULT_DATE_TRANSLATE
  ): IEvent {
    const {
      id,
      fields: {
        buttonLabel,
        city_name,
        city_slug,
        change_monitor_name,
        currency,
        description_pl,
        empik_premium,
        end_date,
        event_category_id,
        event_category_name,
        event_slug,
        geolocation,
        horizontal_image,
        partner_id,
        partner_name,
        place_slug,
        place_id,
        place_name,
        place_thumb,
        price,
        price_description_pl,
        public_tags_ids,
        public_tags_names,
        rundate_not_for_sale,
        rundate_rundate,
        rundate_rundate_description,
        rundate_slug,
        redirect_to_url,
        square_image,
        title_pl,
      },
    } = eventResponse as IEventsHitResponse;

    const location = geolocation ? geolocation.split(',') : [0, 0];
    const place = {
      address: city_name, // TODO: change this to address from api instead city_name
      city: { name: city_name, slug: city_slug },
      id: place_id,
      lat: Number(location[0]),
      lon: Number(location[1]),
      name: place_name,
      slug: place_slug,
    } as IPlaceResponse;

    const eventTags = public_tags_ids
      ? public_tags_ids.map((eventId, index) => {
          return {
            id: Number(eventId),
            name: public_tags_names[index],
            slug: public_tags_names[index],
          };
        })
      : [];

    return {
      buttonLabel,
      changeMonitorName: change_monitor_name,
      currency,
      dateDescription: rundate_rundate_description,
      description: description_pl,
      endDate: end_date,
      eventSlug: event_slug,
      friendlyDate: translatableDate(
        rundate_rundate,
        rundate_rundate_description,
        dateTranslate
      ),
      friendlyHour: ArtistApi.getFriendlyHour(
        rundate_rundate,
        rundate_rundate_description
      ),
      id: parseInt(id, 10),
      imageUrl: ArtistApi.getImageUrl([
        {
          large: square_image || horizontal_image,
          medium: square_image || horizontal_image,
        },
      ]),
      isPremiumEvent: empik_premium,
      notForSale: convertToBool(rundate_not_for_sale),
      partner: `${partner_name}|${partner_id}`,
      place: ArtistApi.getFriendlyPlace(place),
      placeImageUrl: place_thumb || '',
      placeSlug: place_slug || '',
      price: getPriceFormatted(price),
      priceDescription: ArtistApi.getPriceDescription(price_description_pl),
      redirectToUrl: redirect_to_url || null,
      rundateSlug: rundate_slug,
      startDate: rundate_rundate,
      tags: ArtistApi.prepareTagsFromRundate(
        { id: event_category_id, name: event_category_name, slug: event_slug },
        eventTags
      ),
      title: title_pl,
    };
  }
}

export default new ArtistApi();
