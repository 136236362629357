import React, { useEffect } from 'react';

import config from '@Config';
import apostropheBlue from '@Styles/images/empik-apostrophe-blue.svg';

import Pools from './Pools';
import Ticket from './Ticket';
import styles from './Tickets.module.scss';
import { ITicketsProps } from './Tickets.types';

const TICKETS_ID = 'terminy-i-bilety';

const Tickets: React.FC<ITicketsProps> = ({
  lang,
  mounted,
  tickets,
  poolsTable,
  params,
}) => {
  useEffect(() => {
    mounted();
  }, []);

  const filteredTickets = tickets.filter(
    (ticket) =>
      ticket.eventSlug === params.eventSlug &&
      ticket.rundateSlug !== params.rundateSlug
  );

  if (filteredTickets.length > 0 || poolsTable.length) {
    const isSamePlace = filteredTickets.every(
      (ticket, index, array) => ticket.placeId === array[0].placeId
    );

    return (
      <>
        <div className={styles.container}>
          {!!poolsTable.length && (
            <>
              {config.theme.isGoing && (
                <div className={styles.title}>{lang.tickets}</div>
              )}
              <div className={styles.ticketsMainContainer}>
                {config.theme.isEmpik && (
                  <div className={styles.title}>{lang.tickets}</div>
                )}
                {poolsTable.map((pool, id) => (
                  <Pools
                    key={id}
                    pool={pool}
                    eventSlug={params.eventSlug}
                    rundateSlug={params.rundateSlug}
                    onlyOneTicket={true}
                  />
                ))}
              </div>
            </>
          )}

          {filteredTickets.length > 0 && (
            <>
              {config.theme.isGoing && (
                <div className={styles.title}>{lang.otherDates}</div>
              )}
              <div className={styles.ticketsMainContainer} id={TICKETS_ID}>
                {config.theme.isEmpik && (
                  <div className={styles.title}>{lang.otherDates}</div>
                )}
                {filteredTickets.map((ticket, id) => (
                  <Ticket key={id} ticket={ticket} samePlace={isSamePlace} />
                ))}
              </div>
            </>
          )}
        </div>
        {config.theme.isEmpik && (
          <div className={styles.logoContainer}>
            <div className={styles.opacityContainer}>
              <div className={styles.rectangleSquareContainer} />
            </div>
            <img src={apostropheBlue} className={styles.icon} />
          </div>
        )}
      </>
    );
  }

  return null;
};

export default Tickets;
