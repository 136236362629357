import { getType } from 'typesafe-actions';

import { getTransactionInfo, unmount } from './../../actions';
import { IAction, ITransactionInfoReducer } from './../../types';

const reducer = (
  state: ITransactionInfoReducer = null,
  action: IAction
): ITransactionInfoReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getTransactionInfo.success):
      return {
        ...state,
        ...action.payload,
      };

    // RESET_STORE
    case getType(unmount):
      return null;

    default:
      return state;
  }
};

export default reducer;
