// Create history
import { History, createBrowserHistory } from 'history';

/**
 * We use history in a few places, so good approach is using singleton.
 * If we use createBrowserHistory as default value of history, when we
 * import this file in SSR, we will get error. To avoid that situation
 * we set history as empty object.
 *
 * Important note: This file in functionally used only in browser.
 */
const history =
  typeof window !== 'undefined' ? createBrowserHistory() : ({} as History);

export default history;
