import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isMobile } from '@Model/app/selectors';
import { switchView } from '@Model/search/actions';
import { getSearchDates, getSearchView } from '@Model/search/selectors';

import StatusBar from './StatusBar.component';
import { IStatusBarFromDispatch, IStatusBarFromState } from './StatusBar.types';

const mapStateToProps = (state: _Store.IState): IStatusBarFromState => ({
  dates: getSearchDates(state),
  isMobile: isMobile(state),
  tileView: getSearchView(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IStatusBarFromDispatch => ({
  switchView: () => dispatch(switchView()),
});

export default connect<
  IStatusBarFromState,
  IStatusBarFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(StatusBar);
