import React from 'react';

import { Box, Grid, Link, Toolbar } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import routes from '@Routes/routes';
import empikFotoImage from '@Styles/images/empik-foto.svg';
import empikGoImage from '@Styles/images/empik-go.svg';
import empikPremiumImage from '@Styles/images/empik-premium.svg';
import empikImage from '@Styles/images/empik.svg';

import useStyles from './EmpikBar.styles';

const EmpikBar = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid container={true} direction={'row'} wrap={'nowrap'}>
          <Link
            className={classes.link}
            href={'https://www.empik.com'}
            target={'_blank'}
          >
            <img className={classes.image} src={empikImage} alt="empik" />
          </Link>
          <Link
            className={classes.link}
            href={'https://www.empik.com/premium'}
            target={'_blank'}
          >
            <img
              className={classes.image}
              src={empikPremiumImage}
              alt="empik-premium"
            />
          </Link>
          <Link
            className={classes.link}
            href={'https://www.empikfoto.pl'}
            target={'_blank'}
          >
            <img
              className={classes.image}
              src={empikFotoImage}
              alt="empik-foto"
            />
          </Link>
          <Link
            className={classes.link}
            href={'https://www.empik.com/abonamenty'}
            target={'_blank'}
          >
            <img className={classes.image} src={empikGoImage} alt="empik-go" />
          </Link>
        </Grid>

        <Box>
          <Link
            className={classes.textLink}
            href={'https://pomoc.empikbilety.pl/'}
            target={'_blank'}
            underline={'none'}
          >
            Pomoc
          </Link>
          <Link
            className={classes.textLink}
            component={NavLink}
            to={routes.contact}
            underline={'none'}
          >
            Kontakt
          </Link>
        </Box>
      </Toolbar>
    </Box>
  );
};

export default EmpikBar;
