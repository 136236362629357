import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getAvailabilities } from '@Model/happening/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenAvailabilitiesFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getAvailabilities.request])),
    map$(() => setLoading(CONSTS.AVAILABILITIES))
  );
};

export const setLoaderWhenAvailabilitiesFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getAvailabilities.success, getAvailabilities.failure], action)
    ),
    map$(() => resetLoading(CONSTS.AVAILABILITIES))
  );
};
