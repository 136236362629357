import React from 'react';

import { Button, Grid, TextField } from '@material-ui/core';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useGlobalStyles from '../../global.styles';
import Paper from '../Paper';
import useStyles from './ParentFormLayout.styles';
import { IParentFormLayoutProps } from './ParentFormLayout.types';

const ParentFormLayout = ({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  handleSubmit,
  handlePrevStep,
}: IParentFormLayoutProps) => {
  const classes = useStyles();
  const globals = useGlobalStyles();
  const { entryList: lang } = useDictionary();

  return (
    <Paper title={lang.parentForm.title} helperText={lang.parentForm.subtitle}>
      <form onSubmit={handleSubmit}>
        <Grid container={true} justifyContent="center" className={classes.root}>
          <Grid item={true} xs={12}>
            <TextField
              name="firstName"
              helperText={
                !!(touched.firstName && errors.firstName) && errors.firstName
              }
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={lang.parentForm.firstName}
              variant="outlined"
              fullWidth={true}
              error={!!(touched.firstName && errors.firstName)}
              autoComplete="off"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              name="lastName"
              helperText={
                !!(touched.lastName && errors.lastName) && errors.lastName
              }
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              label={lang.parentForm.lastName}
              variant="outlined"
              fullWidth={true}
              error={!!(touched.lastName && errors.lastName)}
              autoComplete="off"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              name="phoneNumber"
              helperText={
                !!(touched.phoneNumber && errors.phoneNumber) &&
                errors.phoneNumber
              }
              value={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              label={lang.parentForm.phoneNumber}
              variant="outlined"
              fullWidth={true}
              error={!!(touched.phoneNumber && errors.phoneNumber)}
              inputProps={{ inputMode: 'numeric' }}
              autoComplete="off"
            />
          </Grid>
          <Grid item={true} xs={12} className={classes.buttons}>
            <Grid
              container={true}
              justifyContent="center"
              className={globals.column}
            >
              <Grid item={true}>
                <Button
                  className={globals.button}
                  type="button"
                  variant="text"
                  color="primary"
                  onClick={handlePrevStep}
                >
                  {lang.parentForm.goBack}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  className={globals.button}
                  variant="contained"
                  size="medium"
                  color="primary"
                  type="submit"
                >
                  {lang.parentForm.goNext}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ParentFormLayout;
