import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultBg: {
      backgroundColor: theme.palette.background.default,
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default useStyles;
