import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { Divider, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import BasketButton from '@Compo/Basket/components/BasketButton';
import ImageFixed from '@Compo/reusable/ImageFixed';
import config from '@Config';
import getFormattedBasketPools from '@Misc/helpers/getBasketFormattedPools';
import getCurrencyFromCode from '@Misc/helpers/getCurrencyFromCode';
import getNonEmptyObject from '@Misc/helpers/getNonEmptyObject';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';
import isEmptyObject from '@Misc/helpers/isEmptyObject';
import prepareUrlForRoute from '@Misc/helpers/prepareUrlForRoute';
import translatableDate from '@Misc/helpers/translatableDate';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import productIconEmpik from '@Misc/styles/images/product-empik.svg';
import productIcon from '@Misc/styles/images/product.svg';
import { IBasket } from '@Model/basket/types';
import { IPoolsSummary } from '@Model/pools/types';
import { IProductsSummary } from '@Model/products/types';
import * as MODULES from '@Routes/modules';
import routes from '@Routes/routes';

import Triangle from './../Triangle';
import classes from './Summary.classes';
import styles from './Summary.module.scss';
import useStyles from './Summary.styles';
import { ISummaryProps } from './Summary.types';
import Loading from './components/Loading';
import Pools from './components/Pools';

const CURRENCY_TEXT = 'zł';
const AFTER_MIDNIGHT_TEXT = ' (po północy)';

const Summary: FC<ISummaryProps> = ({
  dateTranslate,
  event: potentialEvent,
  giftWrap,
  isBasketPay,
  isDesktop,
  isEmbed,
  isLoading,
  isProductsPay,
  isTransferTicket,
  lang,
  langBasket,
  module,
  products,
  selectedShipment,
  selectedDay,
  selectedDayFromSlot,
  selections,
  selectedProducts,
  selectedTheme,
  selectedTickets,
  slots,
  summary: _summary,
  summaryProducts: _summaryProducts,
  transferTicketFee,
  receivedTickets,
  withCourier,
  insurancePrice,
  isInsurance,
  language,
  selectedExtendedSlot,
  defaultSelections,
  setFinalPriceAction,
  setInsuranceSummaryData,
}) => {
  const [items, setItems] = useState<IBasket[]>([]);
  const { isMobile } = useMuiBreakpoints();
  const state = useContext(clearContext);
  const basketItems = isEmbed && !state?.basketItems ? [] : state?.basketItems;
  const isProductsModule = module === MODULES.PRODUCTS_BUY;
  const isHappeningsModule =
    module === MODULES.HAPPENING || module === MODULES.EMBED_HAPPENING;
  const summary = _summary || ({ amount: 0 } as IPoolsSummary);
  const summaryProducts =
    _summaryProducts || ({ amount: 0 } as IProductsSummary);
  const event = getNonEmptyObject(potentialEvent);
  const withoutBottomSpace =
    summary.amount < 1 && summaryProducts.amount < 1 && !slots?.activityName;
  const selectedDayIso = selectedDay?.toISOString();
  const selectedDayFromSlotIso = selectedDayFromSlot?.toISOString();
  const selectedDayWithoutHours = selectedDayIso?.split('T')[0];
  const selectedDayFromSlotWithoutHours = selectedDayFromSlotIso?.split('T')[0];
  const isChosenSlotAfterMidnight =
    selectedDayWithoutHours &&
    selectedDayFromSlotWithoutHours &&
    selectedDayWithoutHours !== selectedDayFromSlotWithoutHours;
  const isExtendedUpsell =
    selectedExtendedSlot &&
    (selectedExtendedSlot.upsellPercentageValue ||
      selectedExtendedSlot.upsellValue);

  const withoutEventDetails = !isBasketPay && !isProductsPay;
  const isImageFixedRendered =
    ((!isMobile && isHappeningsModule) || isDesktop) && withoutEventDetails;
  const receivedPrice = () =>
    receivedTickets && receivedTickets.pool
      ? receivedTickets.pool.pools
          .map((pool) =>
            pool.id === receivedTickets.currentPoolId
              ? (pool.price + pool.serviceFee) * receivedTickets.ticketNum
              : 0
          )
          .reduce((a, b) => a + b, 0) + (transferTicketFee || 0)
      : null;

  const receivedSeatPool = receivedTickets?.pool?.pools.find(
    (pool) => pool.id === receivedTickets?.currentPoolId
  );

  const receivedSeat = receivedTickets?.pool?.seatsio
    ? receivedTickets?.pool?.seatsio.find(
        (seat) => seat?.ticketTypes[0].poolId === receivedTickets?.currentPoolId
      )
    : null;

  const receivedSeatsPrice =
    receivedTickets && receivedSeatPool && receivedSeat
      ? (receivedSeat.ticketTypes[0].price + receivedSeatPool.serviceFee) *
          receivedTickets.ticketNum +
        (transferTicketFee || 0)
      : 0;

  const totalHappeningProductPrice = products.reduce(
    (acc, product) => acc + product.price,
    0
  );

  const selectionsFilteredByPlayersNum = selections?.filter(
    (selection) => selection.numberOfPlayers && selection.numberOfPlayers > 0
  );

  const finalPrice =
    (summary.finalPrice || 0) +
    (slots?.price || 0) +
    totalHappeningProductPrice +
    (summaryProducts.finalPrice || 0) +
    (isInsurance ? insurancePrice || 0 : 0);

  const totalPriceWithExtendedSlot = useMemo(
    () =>
      defaultSelections?.reduce((prev, crr) => {
        return (
          prev +
          (crr.priceType?.value || 0) *
            (crr.numberOfPlayers || 1) *
            (selectedExtendedSlot?.priceMultiplier || 0)
        );
      }, 0),
    [finalPrice, selectedExtendedSlot?.priceMultiplier]
  );

  const discountValue = useMemo(
    () => ((totalPriceWithExtendedSlot || 0) - finalPrice).toFixed(2),
    [finalPrice]
  );

  useEffect(() => {
    setItems(getFormattedBasketPools(basketItems) || []);
  }, [state?.basketItems]);

  const totalPrice = () =>
    basketItems?.length
      ? basketItems.map((item) => item.price).reduce((a, b) => a + b, 0) +
        finalPrice
      : null;

  const theme = useTheme();
  const muiStyles = useStyles(theme);
  const isDefaultTheme = selectedTheme === 'default';

  const isAnyNonFreeItemsInBasket =
    basketItems &&
    basketItems.length > 0 &&
    getPriceFormatted(totalPrice()) !== undefined;

  const isTicketWithInsurance =
    isInsurance &&
    insurancePrice &&
    !basketItems?.length &&
    getPriceFormatted(insurancePrice) !== null;

  useEffect(() => {
    if (isTicketWithInsurance) {
      setInsuranceSummaryData({
        isSelected: true,
        value: Number(getPriceFormatted(insurancePrice)),
      });
    }

    isAnyNonFreeItemsInBasket
      ? setFinalPriceAction(Number(getPriceFormatted(totalPrice())))
      : setFinalPriceAction(Number(getPriceFormatted(finalPrice)));
  }, [finalPrice]);
  return (
    <div className={cn(styles.summaryBox, classes.summaryBox)}>
      <div className={cn(styles.wrapper, classes.wrapper)}>
        {isImageFixedRendered && (
          <div
            className={cn(
              styles.imageWrapper,
              classes.imageWrapper,
              muiStyles.imageWrapper
            )}
          >
            <ImageFixed
              src={
                event.externalImageId ||
                event.externalImages?.[0] ||
                event.imagesUrl
              }
              title={event.title}
              transformation={{
                height: 700,
                width: 810,
              }}
            />
          </div>
        )}

        <div className={cn(styles.sticky, classes.sticky)}>
          {isLoading && <Loading />}

          {!isProductsModule && !isLoading && withoutEventDetails && (
            <div
              className={cn(
                styles.content,
                withoutBottomSpace && styles.withoutBottomSpace,
                basketItems?.length && !isDesktop && styles.withBasket,
                classes.content,
                muiStyles.root
              )}
            >
              <Typography
                className={cn(
                  config.theme.isGoing && isDefaultTheme && muiStyles.title,
                  isDefaultTheme && styles.title
                )}
                variant={isDefaultTheme ? 'h3' : 'h4'}
              >
                {event.title}
              </Typography>

              <Divider />

              <div
                className={cn(
                  styles.infoBox,
                  withoutBottomSpace && styles.withoutBottomSpace,
                  slots && styles.slotsInfoBox,
                  classes.infoBox,
                  muiStyles.content,
                  !isDefaultTheme && muiStyles.withoutSidePaddings
                )}
              >
                {!slots && (
                  <>
                    <h2
                      className={cn(
                        styles.info,
                        classes.info,
                        muiStyles.fontColor
                      )}
                    >
                      {translatableDate(
                        event.startDate,
                        event.rundateDescription,
                        dateTranslate
                      )}
                      {config.theme.isGoing && event.friendlyHour && (
                        <span>, {event.friendlyHour}</span>
                      )}
                    </h2>
                    {config.theme.isEmpik && event.friendlyHour && (
                      <h2
                        className={cn(
                          styles.info,
                          classes.info,
                          muiStyles.fontColor
                        )}
                      >
                        {event.friendlyHour}
                      </h2>
                    )}
                    <h2 className={cn(styles.info, classes.info)}>
                      {event.placeSlug && !isEmbed && (
                        <Link
                          to={prepareUrlForRoute(routes.place, event.placeSlug)}
                          className={muiStyles.fontColor}
                        >
                          {event.placeFriendly}
                        </Link>
                      )}
                      {(!event.placeSlug || isEmbed) && event.placeFriendly}
                    </h2>
                  </>
                )}
                {slots && (
                  <Grid
                    container={true}
                    direction={'column'}
                    spacing={1}
                    className={muiStyles.infoBox}
                  >
                    <Grid item={true}>
                      <Typography variant={'body1'} color={'primary'}>
                        {translatableDate(
                          event.startDate,
                          event.rundateDescription,
                          dateTranslate
                        )}
                        {isChosenSlotAfterMidnight && AFTER_MIDNIGHT_TEXT}
                      </Typography>
                    </Grid>
                    <Grid
                      container={true}
                      item={true}
                      direction={'row'}
                      spacing={1}
                    >
                      <Grid item={true}>
                        <Typography variant={'body1'}>{lang.time}</Typography>
                      </Grid>
                      <Grid item={true}>
                        <Typography variant={'body1'} color={'primary'}>
                          {event.friendlyHour}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          )}

          {!withoutBottomSpace && (
            <>
              {!isDefaultTheme && <Divider />}
              <div
                className={cn(
                  styles.content,
                  withoutBottomSpace && styles.withoutBottomSpace,
                  classes.content,
                  styles.basket,
                  (isBasketPay || !isDefaultTheme) &&
                    styles.basketWithoutMargin,
                  styles.withoutTopPadding,
                  isProductsModule && styles.products,
                  isDesktop ? muiStyles.root : muiStyles.transparent
                )}
              >
                <section
                  className={cn(
                    styles.contentWrapper,
                    slots && styles.slotsWrapper,
                    muiStyles.root,
                    !isDefaultTheme && muiStyles.withoutSidePaddings,
                    isDefaultTheme && muiStyles.rootDefault
                  )}
                >
                  {slots && slots.activityName && (
                    <Grid container={true} spacing={6} direction={'column'}>
                      <Grid
                        container={true}
                        item={true}
                        spacing={4}
                        direction={'column'}
                      >
                        <Grid item={true}>
                          <Typography variant={isDefaultTheme ? 'h6' : 'h4'}>
                            {lang.yourReservation}
                          </Typography>
                        </Grid>

                        {isExtendedUpsell && (
                          <Grid item={true}>
                            <Grid container={true} spacing={1}>
                              <Grid item={true} xs={12}>
                                <Grid
                                  container={true}
                                  justifyContent={'space-between'}
                                >
                                  <Grid item={true}>
                                    <Typography variant={'body1'}>
                                      {lang.extendedSlotPrice}
                                    </Typography>
                                  </Grid>
                                  <Grid item={true}>
                                    <Typography
                                      variant={'body1'}
                                      className={muiStyles.lineThrough}
                                    >
                                      {totalPriceWithExtendedSlot?.toFixed(2)}{' '}
                                      {CURRENCY_TEXT}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item={true} xs={12}>
                                <Grid
                                  container={true}
                                  justifyContent={'space-between'}
                                >
                                  <Grid item={true}>
                                    <Typography
                                      variant={'subtitle2'}
                                      color={'primary'}
                                    >
                                      {lang.extendedSlotDiscount}
                                    </Typography>
                                  </Grid>
                                  <Grid item={true}>
                                    <Typography
                                      variant={'subtitle2'}
                                      color={'primary'}
                                    >
                                      {discountValue} {CURRENCY_TEXT}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          item={true}
                          container={true}
                          spacing={1}
                          direction={'column'}
                        >
                          {slots &&
                            selectionsFilteredByPlayersNum &&
                            selectionsFilteredByPlayersNum?.map((selection) => (
                              <Pools
                                key={`tt-${selection.priceType?.type}`}
                                pool={{
                                  amount: selection.numberOfPlayers || 0,
                                  id: 0,
                                  poolName:
                                    selection.priceType?.type !== 'default'
                                      ? language === 'en'
                                        ? selection.priceType?.titleEN || ''
                                        : selection.priceType?.title || ''
                                      : lang.defaultPriceType,
                                  poolPrice:
                                    (selection.value || 0) /
                                    (selection.numberOfPlayers || 1),
                                  serviceFee: selection.fee || 0,
                                  totalPoolPrice: selection.value || 0,
                                  currency: config.app.defaultCurrency,
                                }}
                                withCourier={withCourier}
                                giftWrap={giftWrap}
                                cancelTicket={false}
                                numberOfPlayers={selection.numberOfPlayers}
                                priceType={selection.priceType?.type}
                              />
                            ))}
                        </Grid>

                        <Grid
                          item={true}
                          container={true}
                          direction={'row'}
                          justifyContent={'space-between'}
                        >
                          <Typography variant={'body2'}>
                            {lang.space}
                          </Typography>
                          <Typography variant={'body1'}>
                            {slots.activityName}
                          </Typography>
                        </Grid>
                      </Grid>
                      {!isEmptyObject(selectedProducts) && (
                        <Grid
                          container={true}
                          item={true}
                          spacing={4}
                          direction={'column'}
                        >
                          <Grid item={true}>
                            <Typography variant={'h6'}>
                              {lang.additionalProducts}
                            </Typography>
                          </Grid>
                          {selectedProducts.map((product, index) => (
                            <Grid
                              container={true}
                              item={true}
                              key={`slots-${product.id}`}
                              xs={12}
                            >
                              <Grid item={true} xs={12}>
                                <Pools
                                  pool={{
                                    amount: product.amount,
                                    id: product.id,
                                    poolName: product.name,
                                    poolPrice: product.price,
                                    serviceFee: 0,
                                    totalPoolPrice:
                                      product.amount * (product.price || 0),
                                    currency: config.app.defaultCurrency,
                                  }}
                                  product={product}
                                  withCourier={withCourier}
                                  giftWrap={giftWrap}
                                  cancelTicket={true}
                                />
                              </Grid>
                              {index !== selectedProducts.length - 1 && (
                                <Grid item={true}>
                                  <Divider />
                                </Grid>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {selectedTickets.map((ticket) => (
                    <Pools
                      key={`pool-${ticket.poolId}`}
                      pool={{
                        amount: ticket.amount,
                        id: ticket.poolId,
                        poolName: ticket.poolName,
                        poolPrice: ticket.price,
                        serviceFee: ticket.amount * (ticket.serviceFee || 0),
                        totalPoolPrice: ticket.amount * (ticket.price || 0),
                        currency: ticket.currency,
                      }}
                      withCourier={withCourier}
                      giftWrap={giftWrap}
                      cancelTicket={true}
                    />
                  ))}
                  {!slots &&
                    selectedProducts.map((product) => (
                      <Pools
                        key={`pool-${product.id}`}
                        pool={{
                          amount: product.amount,
                          id: product.id,
                          poolName: product.name,
                          poolPrice: product.price,
                          serviceFee: 0,
                          totalPoolPrice: product.amount * (product.price || 0),
                          currency: config.app.defaultCurrency,
                        }}
                        product={product}
                        withCourier={withCourier}
                        giftWrap={giftWrap}
                        cancelTicket={true}
                      />
                    ))}
                  {isTransferTicket && !receivedTickets?.pool?.seatsio
                    ? receivedTickets?.pool?.pools.map(
                        (currentReceivedPool) => {
                          return currentReceivedPool.id ===
                            receivedTickets?.currentPoolId ? (
                            <Pools
                              key={`pool-${currentReceivedPool.id}`}
                              pool={{
                                amount: receivedTickets?.ticketNum,
                                id: currentReceivedPool.id,
                                poolName:
                                  currentReceivedPool.poolDescription?.title ||
                                  lang.noData,
                                poolPrice: currentReceivedPool.price,
                                serviceFee:
                                  receivedTickets?.ticketNum *
                                  currentReceivedPool.serviceFee,
                                totalPoolPrice:
                                  receivedTickets?.ticketNum *
                                  currentReceivedPool.price,
                                currency: config.app.defaultCurrency,
                              }}
                              cancelTicket={true}
                              giftWrap={giftWrap}
                              isTransferTicket={isTransferTicket}
                              withCourier={withCourier}
                              isInsurance={isInsurance}
                            />
                          ) : null;
                        }
                      )
                    : receivedTickets?.pool?.seatsio?.map(
                        (currentReceivedSeat) => {
                          const summedServiceFee = receivedSeatPool
                            ? receivedTickets?.ticketNum *
                              receivedSeatPool?.serviceFee
                            : undefined;
                          const summedPoolPrice =
                            receivedTickets?.ticketNum *
                            currentReceivedSeat.ticketTypes[0].price;

                          return currentReceivedSeat.ticketTypes[0].poolId ===
                            receivedTickets?.currentPoolId ? (
                            <Pools
                              key={`pool-${currentReceivedSeat.ticketTypes[0].poolId}`}
                              pool={{
                                amount: receivedTickets?.ticketNum,
                                id: currentReceivedSeat.ticketTypes[0].poolId,
                                poolName:
                                  currentReceivedSeat.ticketTypes[0].label ||
                                  lang.noData,
                                poolPrice:
                                  currentReceivedSeat.ticketTypes[0].price,
                                serviceFee: summedServiceFee,
                                totalPoolPrice: summedPoolPrice,
                                currency: config.app.defaultCurrency,
                              }}
                              cancelTicket={true}
                              giftWrap={giftWrap}
                              isTransferTicket={isTransferTicket}
                              withCourier={withCourier}
                              isInsurance={isInsurance}
                            />
                          ) : null;
                        }
                      )}
                  {selectedShipment && (
                    <>
                      <Grid
                        container={true}
                        direction={'row'}
                        justifyContent={'space-between'}
                        className={muiStyles.insuranceShipment}
                      >
                        <Grid item={true} xs={6}>
                          <Typography variant={'body1'}>
                            {lang.shipment}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <Typography variant={'body1'} align={'right'}>
                            {selectedShipment.price} {CURRENCY_TEXT}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <Typography variant={'caption'} color={'secondary'}>
                            {selectedShipment.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {isInsurance && insurancePrice && !basketItems?.length && (
                    <>
                      <Grid
                        container={true}
                        direction={'row'}
                        justifyContent={'space-between'}
                        className={muiStyles.insuranceShipment}
                      >
                        <Grid item={true} xs={6}>
                          <Typography variant={'body1'}>
                            {lang.insurance}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <Typography variant={'body1'} align={'right'}>
                            {getPriceFormatted(insurancePrice)} {CURRENCY_TEXT}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </section>

                {!basketItems?.length && (
                  <>
                    {isDesktop && <Divider />}
                    <p
                      className={cn(
                        styles.totalPrice,
                        !isDefaultTheme && muiStyles.withoutSidePaddings
                      )}
                    >
                      <span
                        className={cn(
                          styles.totalPriceTitle,
                          muiStyles.fontColor
                        )}
                      >
                        {lang.payment}
                      </span>
                      <span className={styles.totalPriceText}>
                        {!isTransferTicket
                          ? getPriceFormatted(finalPrice)
                          : !receivedTickets?.pool?.seatsio
                          ? getPriceFormatted(receivedPrice())
                          : receivedSeatsPrice}
                        <span>
                          {' '}
                          {getCurrencyFromCode(selectedTickets[0]?.currency) ||
                            CURRENCY_TEXT}
                        </span>
                      </span>
                    </p>
                  </>
                )}
              </div>
            </>
          )}

          {/* BASKET STARTS HERE */}

          {basketItems && basketItems.length > 0 && (
            <div
              className={cn(
                styles.content,
                withoutBottomSpace && styles.withoutBottomSpace,
                classes.content,
                styles.basket,
                isBasketPay && styles.basketWithoutMargin,
                isDesktop && muiStyles.root
              )}
            >
              {config.app.showUnfinishedFeatures &&
                (config.theme.isGoing ||
                  (!config.theme.isGoing && isDesktop)) && (
                  <div className={styles.yourBasketWrapper}>
                    <BasketButton />
                  </div>
                )}
              {items.map((pool) => {
                const {
                  cityName,
                  hour,
                  id,
                  place,
                  rundate,
                  title,
                  pools,
                  isProduct,
                } = pool;

                return (
                  <section
                    key={id}
                    className={cn(styles.contentWrapper, muiStyles.root)}
                  >
                    <div
                      className={cn(
                        styles.basketTitle,
                        isProduct && styles.titleProduct
                      )}
                    >
                      <div className={styles.titleWrapper}>
                        {isDesktop && (
                          <>
                            {!isProduct ? (
                              <span
                                className={cn(
                                  styles.ticketIcon,
                                  classes.ticketIcon
                                )}
                              />
                            ) : (
                              <img
                                src={
                                  config.theme.isEmpik
                                    ? productIconEmpik
                                    : productIcon
                                }
                                className={styles.productIcon}
                                alt={''}
                              />
                            )}
                          </>
                        )}
                        {title}
                      </div>
                    </div>
                    {rundate && (
                      <p className={styles.basketRundate}>
                        {hour ? `${rundate}, ${hour}` : rundate}
                      </p>
                    )}
                    {place && cityName && (
                      <p className={styles.basketPlace}>
                        {`${place}, ${cityName}`}
                      </p>
                    )}
                    <div className={styles.poolsWrapper}>
                      <Pools pool={pool} />
                      {pools.map((element, index) => (
                        <Pools key={`pool-${index}`} pool={element} />
                      ))}
                    </div>
                  </section>
                );
              })}

              {isInsurance && insurancePrice && (
                <>
                  <Grid
                    container={true}
                    direction={'row'}
                    justifyContent={'space-between'}
                    className={cn(
                      muiStyles.insuranceShipment,
                      styles.contentWrapper,
                      muiStyles.root
                    )}
                  >
                    <Grid item={true} xs={6}>
                      <Typography variant={'body1'}>
                        {selectedTickets.length
                          ? lang.insuranceWithCurrentEventTickets
                          : lang.insurance}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={'body1'} align={'right'}>
                        {getPriceFormatted(insurancePrice)} {CURRENCY_TEXT}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}

              <p className={styles.totalPrice}>
                <span className={styles.totalPriceTitle}>{lang.payment}</span>
                <span className={styles.totalPriceText}>
                  {getPriceFormatted(totalPrice())}{' '}
                  <span>
                    {getCurrencyFromCode(items[0]?.currency) || CURRENCY_TEXT}
                  </span>
                </span>
              </p>
            </div>
          )}

          {config.theme.isEmpik &&
            isDesktop &&
            !isEmptyObject(selectedProducts) && (
              <span
                className={cn(styles.triangleWrapper, classes.triangleWrapper)}
              >
                <Triangle light={true} />{' '}
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

export default Summary;
