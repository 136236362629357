import React, { useEffect } from 'react';

import { useTheme } from '@material-ui/core';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import useStyles from '@Compo/errors/styles/errors.styles';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import config from '@Config';

import styles from './../styles/errors.module.scss';
import { IErrorBasicProps } from './ErrorBasic.types';

const CHECK_INTERNET_TEXT =
  'Podczas ładowania strony wystąpił błąd. Upewnij się, że masz dostęp do internetu i';
const HEADER_TEXT = 'Upss... Coś poszło nie tak';
const REFRESH_TEXT = 'odśwież stronę';
const SEND_CODE_TEXT =
  'Gdyby błąd nadal występował wyślij nam informację z tym kodem';

const ErrorBasic = ({
  location,
  informAboutError,
  isEmbed,
}: IErrorBasicProps) => {
  useEffect(() => {
    informAboutError();
  }, []);

  try {
    window.airbrake.notify({
      error: new Error('UPS error'),
      params: { isEmbed },
    });
  } catch (noWindow) {}

  const link = `${location.pathname}${location.search}${location.hash}`;

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <>
      <FullTriangleBackground />
      <MuiGrid>
        <MuiSectionContainer>
          <div
            className={cn(
              styles.error,
              muiStyles.root,
              config.theme.isEmpik && styles.withApostrophe
            )}
          >
            <div className={styles.content}>
              <h2>{HEADER_TEXT}</h2>
            </div>
            <div className={styles.tryAgain}>
              {CHECK_INTERNET_TEXT}{' '}
              <Link to={link} replace={true}>
                {REFRESH_TEXT}.
              </Link>
              {typeof btoa !== 'undefined' && (
                <>
                  <br />
                  {SEND_CODE_TEXT}: <strong>{btoa(link)}</strong>.
                </>
              )}
            </div>
          </div>
        </MuiSectionContainer>
      </MuiGrid>
    </>
  );
};

export default ErrorBasic;
