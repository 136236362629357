import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  getTicketsData,
  receiveTicket,
  transferTicket,
} from '@Model/tickets/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenTicketsSendRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getTicketsData.request,
        receiveTicket.request,
        transferTicket.request,
      ])
    ),
    map$(() => setLoading(CONSTS.MYTICKET))
  );
};

export const setLoaderWhenTicketsSendFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getTicketsData.success,
        getTicketsData.failure,
        receiveTicket.success,
        receiveTicket.failure,
        transferTicket.success,
        transferTicket.failure,
      ])
    ),
    map$(() => resetLoading(CONSTS.MYTICKET))
  );
};
