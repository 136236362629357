import { createSelector } from 'reselect';

import config from '@Config';
import _L from '@Misc/helpers/local';
import { getModule } from '@Model/internalRouter/selectors';
import homePageSchemae from '@Model/meta/helpers/homePageSchema';
import { IMeta } from '@Model/meta/types';
import { PagesEnum } from '@Model/pages/constants/pages';
import { get } from '@Model/pages/selectors';
import { getLocation } from '@Model/router/selectors';
import * as MODULES from '@Routes/modules';
import * as LOCALS from '@Vars/constants';

const DEFAULT_DESCRIPTION_TEXT = config.theme.isSbm
  ? 'SBM FFestival powraca w 2022 roku! Przed nami szósta odsłona największego święta fanów SBM Label.'
  : _L(LOCALS.HELMET__PAGE_DESCRIPTION);
const DEFAULT_PAGE_NAME_TEXT = config.theme.isSbm
  ? 'SBM FFestival vol. 6 Bilety na Festiwal 2022 Warszawa | sbmffestival.pl'
  : _L(LOCALS.HELMET__PAGE_TITLE);
const DEFAULT_KEYWORDS_TEXT = config.theme.isSbm
  ? 'sbm ffestival, bilety na festiwal, festiwal 2022, sbm label, bilety na koncerty, festiwal warszawa'
  : _L(LOCALS.HELMET__PAGE_KEYWORDS);

const baseUrl = config.theme.isSbm
  ? 'https://sbmffestival.pl'
  : config.app.baseUrl;

const compositionPageMeta = createSelector(
  [get, getModule, getLocation],
  (page, module, location): IMeta => {
    let compositionType;
    switch (module) {
      case MODULES.ACTIVITIES:
        compositionType = PagesEnum.activities;
        break;
      case MODULES.RECOMMEND:
        compositionType = PagesEnum.recommend;
        break;
      case MODULES.INSPIRATIONS:
        compositionType = PagesEnum.inspirations;
        break;
      default:
        compositionType = PagesEnum.mainpage;
        break;
    }

    return {
      currentUrl: `${baseUrl}${location.pathname}`,
      customGtmId:
        page.compositions[compositionType]?.metadata?.partnerGtm || undefined,
      description:
        page.compositions[compositionType]?.metadata?.description ||
        DEFAULT_DESCRIPTION_TEXT,
      keywords:
        page.compositions[compositionType]?.metadata?.keywords?.toString() ||
        DEFAULT_KEYWORDS_TEXT,
      noIndex: page.compositions[compositionType]?.metadata?.noIndex || false,
      ogDescription:
        page.compositions[compositionType]?.metadata?.description ||
        DEFAULT_DESCRIPTION_TEXT,
      ogImage:
        page.compositions[compositionType]?.metadata?.ogImage ||
        `${baseUrl}/static_legacy/${config.theme.name}/og_image.jpg`,
      pageName:
        (page.compositions[compositionType]?.metadata?.title &&
          page.compositions[compositionType]?.metadata?.title) ||
        DEFAULT_PAGE_NAME_TEXT,
      schema:
        config.theme.isGoing && module === MODULES.HOME
          ? homePageSchemae
          : null,
    };
  }
);

export default compositionPageMeta;
