import React from 'react';

import {
  getHighlightIcon,
  translateHighlightName,
} from '@Compo/Search/Search.helpers';

import styles from './Highlight.module.scss';
import { IHighlightProps } from './Highlight.types';

const FOUND_TEXT = 'Znaleziono:';

const Highlight = ({
  count,
  category,
  thumb,
  title,
  isMobile,
  setFilter,
}: IHighlightProps) => {
  return (
    <li
      className={styles.highlightWrapper}
      onClick={() => setFilter({ key: category, value: title })}
    >
      <div className={styles.leftColumn}>
        <div className={styles.imageWrapper}>
          <img src={getHighlightIcon(thumb)} alt={title} />
        </div>
        <div className={styles.data}>
          <h4>{title}</h4>
          <p>{translateHighlightName(category)}</p>
        </div>
      </div>
      {!isMobile && (
        <div className={styles.rightColumn}>
          <p>{FOUND_TEXT}</p>
          <div className={styles.counter}>
            <p>{count}</p>
          </div>
        </div>
      )}
    </li>
  );
};

export default Highlight;
