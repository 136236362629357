import {
  LOCATION_CHANGE,
  getLocation,
  go,
  goBack,
  push,
} from 'connected-react-router';
import { EMPTY, of as of$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  skip as skip$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { isMobile } from '@Model/app/selectors';

import {
  _closeAll,
  _closeLatest,
  _open,
  _setOne,
  closeAll,
  closeLatest,
  open,
} from './../actions';
import * as MODALS from './../constants/constants';
import { getModals } from './../selectors';

// OPEN AND CLOSE
export const openOrChangeRouteWhenTryOpen: _Store.IEpic = (
  action$,
  state$,
  { modalDictionary }
) => {
  return action$.pipe(
    filter$(isActionOf(open)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { pathname, search } = getLocation(state);

      return isMobile(state)
        ? of$(
            push(
              `${pathname}${search}#${modalDictionary.toHash(
                action.payload.type
              )}`,
              action.payload.context
            )
          )
        : of$(_open(action.payload.type, action.payload.context));
    })
  );
};

export const closeLatestOrChangeRouteWhenTryCloseLatest: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(closeLatest)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) =>
      isMobile(state) ? of$(goBack()) : of$(_closeLatest())
    )
  );
};

export const closeAllOrChangeRouteWhenTryCloseAll: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(closeAll)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) =>
      isMobile(state) ? of$(go(-getModals(state).length)) : of$(_closeAll())
    )
  );
};

// LOCATION
export const openModalWhenLocationChange: _Store.IEpic = (
  action$,
  state$,
  { modalDictionary }
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    skip$(1),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const location = getLocation(state);

      const modal = modalDictionary.fromHash(
        location.hash || ''
      ) as MODALS.IModalKey;

      if (modal) {
        return of$(_setOne(modal, undefined));
      } else if (getModals(state).length > 0) {
        return of$(_closeAll());
      }

      return EMPTY;
    })
  );
};
