import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import ArtistCard from '@Compo/reusable/ArtistCard';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import { IGetArtistSuccessPayload } from '@Model/artist/types';

import { IEventArtistsProps } from './EventArtists.types';

const EventArtists = ({ artists, sectionTitle }: IEventArtistsProps) => {
  const { isMobile } = useMuiBreakpoints();
  const artistsCoupledIntoPairs: IGetArtistSuccessPayload[][] = artists.reduce(
    (result: IGetArtistSuccessPayload[][], value, index, array) => {
      if (index % 2 === 0) {
        result.push(array.slice(index, index + 2));
      }

      return result;
    },
    []
  );

  const cards = isMobile
    ? artists.map((artist, index) =>
        index % 2 === 0 ? (
          <ArtistCard key={`${artist.slug}-${index}`} artist={artist} />
        ) : (
          <ArtistCard
            key={`${artist.slug}-${index}`}
            artist={artist}
            reversed={true}
          />
        )
      )
    : artistsCoupledIntoPairs.map((artistPair, index) =>
        index % 2 === 0
          ? artistPair.map((artist) => (
              <ArtistCard key={`${artist.slug}-${index}`} artist={artist} />
            ))
          : artistPair.map((artist) => (
              <ArtistCard
                key={`${artist.slug}-${index}`}
                artist={artist}
                reversed={true}
              />
            ))
      );

  return (
    <>
      <Typography
        variant={'h4'}
        children={sectionTitle}
        style={{
          fontSize: isMobile ? '1.5rem' : '3rem',
          margin: isMobile ? '3rem 0 1rem' : '5rem 0 2rem',
        }}
      />
      <Grid container={true} spacing={4}>
        {cards}
      </Grid>
    </>
  );
};

export default EventArtists;
