import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISearchDates, ISearchReducer } from '@Model/search/types';

import get from './get';

const getSearchFilters = createSelector<
  _Store.IState,
  ISearchReducer,
  ISearchDates
>([get], (search) => search.datesFilter);

export default getSearchFilters;
