import { connect } from 'react-redux';

import _Store from '@Store';

import { getData, isECardPaymentAvailable } from '@Model/transaction/selectors';

import RedirectForECard from './RedirectForECard.component';
import {
  IRedirectForECardData,
  IRedirectForECardProps,
} from './RedirectForECard.types';

const mapStateToProps = (state: _Store.IState): IRedirectForECardProps => ({
  data: getData(state) as IRedirectForECardData,
  isPaymentAvailable: isECardPaymentAvailable(state),
});

export default connect<IRedirectForECardProps, null, {}, _Store.IState>(
  mapStateToProps
)(RedirectForECard);
