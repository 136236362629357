import { MusicEvent, WithContext } from 'schema-dts';

import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { IEventFull } from '@Model/event/types';
import routes from '@Routes/routes';

const AVAILABLE_EVENT_LABEL = 'http://schema.org/InStock';
const CURRENCY = 'PLN';
const UNAVAILABLE_EVENT_LABEL = 'http://schema.org/LimitedAvailability';
const URI_EVENT_SLUG = ':eventSlug';
const URI_RUNDATE_SLUG = ':rundateSlug';

const createSchemaFromEvent = (event: IEventFull) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'MusicEvent',
    description: event.description,
    endDate: event.endDate,
    image: event.imagesUrl,
    location: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressLocality: event.placeCityName,
        streetAddress: event.placeAddress,
      },
      name: event.placeName,
    },
    name: event.title,
    offers: {
      '@type': 'Offer',
      availability: event.isAvailable
        ? AVAILABLE_EVENT_LABEL
        : UNAVAILABLE_EVENT_LABEL,
      price: event.price ? event.price : event.priceDescription,
      priceCurrency: CURRENCY,
      url: `${config.app.baseUrl}${fillUrlWithValues(
        routes.buy,
        [URI_EVENT_SLUG, URI_RUNDATE_SLUG],
        [event.eventSlug, event.rundateSlug]
      )}`,
    },
    startDate: event.startDate,
  };
};

export default createSchemaFromEvent;
