import { EMPTY as EMPTY$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { appIsReady } from '@Model/app/actions';
import { getLocation } from '@Model/router/selectors';

import { prepareUtmParamsForState, recordUtmParamsInState } from '../actions';

export const getTrafficParams: _Store.IEpic = (action$) =>
  action$.pipe(
    filter$(isActionOf(appIsReady)),
    mergeMap$(() => [prepareUtmParamsForState()])
  );

export const saveTrafficParamsInState: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(prepareUtmParamsForState)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const query = getLocation(state).query;

      if (
        !query ||
        (!query.utm_source &&
          !query.utm_campaign &&
          !query.utm_medium &&
          !query.traffic_source)
      )
        return EMPTY$;

      return [
        recordUtmParamsInState({
          utm_campaign: query.utm_campaign,
          utm_medium: query.utm_medium,
          utm_source: query.utm_source,
          traffic_source: query.traffic_source,
        }),
      ];
    })
  );
