import { EMPTY, of as of$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { deselectSeat, selectSeat, updateSelectedSeats } from './../actions';
import { getSelectedSeats } from './../selectors';

export const updateSelectedSeatsWhenSeatSelected: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(selectSeat)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedSeats = getSelectedSeats(state);
      const { payload: addedSeats } = action;

      return of$(updateSelectedSeats([...selectedSeats, addedSeats]));
    })
  );
};

export const updateSelectedSeatsWhenSeatDeselected: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(deselectSeat)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedSeats = [...getSelectedSeats(state)];

      const indexOfObjectToDelete = selectedSeats.findIndex(
        (seat) => seat.id === action.payload.id
      );

      if (indexOfObjectToDelete >= 0) {
        selectedSeats.splice(indexOfObjectToDelete, 1);

        return of$(updateSelectedSeats(selectedSeats));
      }

      return EMPTY;
    })
  );
};
