import React, { FC } from 'react';

import { useTheme } from '@material-ui/core';
import cn from 'classnames';

import styles from './StaticContentWrapper.module.scss';
import useStyles from './StaticContentWrapper.styles';

const StaticContentWrapper: FC = ({ children }) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return <div className={cn(styles.content, muiStyles.root)}>{children}</div>;
};

export default StaticContentWrapper;
