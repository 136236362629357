import makeNumber from './makeNumber';

const nearestWeekend = (): number[] => {
  const date = new Date();

  const whichDay = date.getDay();

  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  let add = 0;
  let daysToSunday = 2;

  if (whichDay === 0) {
    add = 5;
  } else if (whichDay === 6) {
    add = 0;
    daysToSunday = 1;
  } else {
    add = 5 - whichDay;
  }

  const nextFriday = new Date(year, month, day + add);
  const nextSunday = new Date(year, month, day + add + daysToSunday);

  // Friday's calculations
  day = nextFriday.getDate();
  month = nextFriday.getMonth();
  year = nextFriday.getFullYear();

  const friday = makeNumber(year, month, day);

  // Sunday's calculations
  day = nextSunday.getDate();
  month = nextSunday.getMonth();
  year = nextSunday.getFullYear();

  const sunday = makeNumber(year, month, day);

  // Return array
  return [friday, sunday];
};

export default nearestWeekend;
