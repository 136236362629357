import { connect } from 'react-redux';

import _Store from '@Store';

import MuiGrid from '@Compo/layout/MuiGrid/MuiGrid.component';
import { IMuiGridFromState } from '@Compo/layout/MuiGrid/MuiGrid.types';
import { getApp } from '@Model/app/selectors';

export default connect(
  (state: _Store.IState): IMuiGridFromState => ({
    isWebview: getApp(state).isWebview,
  })
)(MuiGrid);
