import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { poolsMounted } from '@Model/pools/actions';
import { getClosestPoolsData, getPools } from '@Model/pools/selectors';
import { IBuyMatchParams } from '@Routes/types';

import Tickets from './Tickets.component';
import { ITicketsFromDispatch, ITicketsFromState } from './Tickets.types';

const mapStateToProps = (state: _Store.IState): ITicketsFromState => {
  return {
    lang: translate(state)('event'),
    params: getParams(state) as IBuyMatchParams,
    poolsTable: getPools(state),
    tickets: getClosestPoolsData(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketsFromDispatch => {
  return {
    mounted: () => dispatch(poolsMounted()),
  };
};

export default connect<
  ITicketsFromState,
  ITicketsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Tickets);
