import { Theme } from '@material-ui/core/styles';

const fontColor = (theme: Theme, color?: string, isBgDark?: boolean) => {
  if (color) {
    return color;
  }

  if (isBgDark) {
    return theme.palette.grey[50];
  }

  return theme.palette.grey[900];
};

export default fontColor;
