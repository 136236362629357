import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPool, IPoolsSelectedTicketsReducer } from './../types';
import getPools from './getPools';
import getSelectedTickets from './getSelectedTickets';

const isAnyPoolSelectedHasDetailed = createSelector<
  _Store.IState,
  IPool[],
  IPoolsSelectedTicketsReducer,
  boolean
>(
  [getPools, getSelectedTickets],
  (pools: IPool[], selectedTickets: IPoolsSelectedTicketsReducer) => {
    let detailedPurchase = false;

    selectedTickets.map((ticket) => {
      if (
        pools.find((pool) => pool.id === ticket.poolId)
          ?.isDetailedPurchaseRequired
      ) {
        detailedPurchase = true;
      }
    });

    return detailedPurchase;
  }
);

export default isAnyPoolSelectedHasDetailed;
