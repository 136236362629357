import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { eventAutoFillData } from '@Model/analytics/actions';
import { getBasketData } from '@Model/basket/selector';
import {
  getCurrentKey,
  getCustomTerms,
  getEvent,
  specialEvent,
} from '@Model/event/selectors';
import {
  generateIdempotencyKey,
  setCalculatedPrices,
  setDiscountState,
  setPrepaidState,
} from '@Model/happening/actions';
import { getSelected } from '@Model/happening/selectors';
import { translate } from '@Model/locale/selectors';
import { createNotification } from '@Model/notifications/actions';
import { isPoolHasDependency } from '@Model/pools/selectors';
import getPoolsData from '@Model/pools/selectors/getPoolsData';
import { getLocation } from '@Model/router/selectors';
import { checkEmailValidity } from '@Model/sendgrid/actions';
import { getCheckEmail } from '@Model/sendgrid/selectors';
import { requestDataFromFacebook } from '@Model/social/actions';
import { getTheme } from '@Model/theme/selectors';
import { getPreviousForm } from '@Model/transaction/selectors';

import ClientData from './ClientData.component';
import {
  IClientDataFromDispatch,
  IClientDataFromState,
  IClientDataOwnProps,
} from './ClientData.types';

const mapStateToProps = (state: _Store.IState): IClientDataFromState => ({
  autoFill: !!getLocation(state).query?.data,
  currentBasketData: getBasketData(state),
  currentKey: getCurrentKey(state),
  customTerms: getCustomTerms(state),
  emailValidation: getCheckEmail(state),
  hasDependency: isPoolHasDependency(state),
  isWebview: getLocation(state).query?.isWebview === 'true',
  lang: translate(state)('buy', 'clientData'),
  lastData: getPreviousForm()?.values,
  selected: getSelected(state),
  selectedTheme: getTheme(state),
  specialEvent: specialEvent(state),
  selectedSpace: getSelected(state).space,
  // TODO: Temporary feature 05.05.2022
  poolsData: getPoolsData(state),
  eventData: getEvent(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IClientDataFromDispatch => ({
  calculatePrice: (value) => dispatch(setCalculatedPrices(value)),
  checkEmail: (value) => dispatch(checkEmailValidity(value)),
  generateIdempotencyKey: () => dispatch(generateIdempotencyKey()),
  requestDataFromFacebook: (setFieldValue) =>
    dispatch(requestDataFromFacebook(setFieldValue)),
  setDiscountState: (discountCode) => dispatch(setDiscountState(discountCode)),
  setPrepaidState: (prepaidCard) => dispatch(setPrepaidState(prepaidCard)),
  eventAutoFillData: (textDisplayed) =>
    dispatch(eventAutoFillData(textDisplayed)),
  notify: (message) => dispatch(createNotification(message)),
});

export default connect<
  IClientDataFromState,
  IClientDataFromDispatch,
  IClientDataOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ClientData);
