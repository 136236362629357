import React, { FC, useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import PageWrapper from '@Compo/layout/PageWrapper';
import { ICard } from '@Compo/reusable/Card/Card.types';
import CardDeck from '@Compo/reusable/CardDeck';
import Event from '@Compo/reusable/Event';
import LoadMoreButton from '@Compo/reusable/LoadMoreButton';
import Loading from '@Compo/reusable/loadings/EventLoading';
import config from '@Config';
import goTrough from '@Misc/helpers/goTrough';
import scrollToTop from '@Misc/helpers/scrollToTop';
import { useLayoutEffect } from '@Misc/hooks/useIsomorphicLayoutEffect';
import routes from '@Routes/routes';
import PlacesApi from '@Services/$places-api';

import styles from './PlaceEvents.module.scss';
import useStyles from './PlaceEvents.styles';
import { IPlaceEventsProps } from './PlaceEvents.types';
import Top from './components/Top';

const LOADERS_COUNT = 3;

const HEADER_TEXT = 'aktualne wydarzenia';
const NO_EVENTS_TEXT = 'Brak wyników...';
const PLACE_EVENTS_ID = 'aktualne-wydarzenia';
const SHOW_MORE_TEXT = 'pokaż więcej';
const ACTIVITIES_TEXT = 'aktywności w tym miejscu';

const PlaceEvents: FC<IPlaceEventsProps> = ({
  events,
  isDesktop,
  isLoading,
  isMobile,
  placeEventsMounted,
  history,
  location,
}) => {
  const [activities, setActivities] = useState<ICard[]>([]);

  useLayoutEffect(() => {
    placeEventsMounted();
  }, []);

  const loadMoreRedirect = () => {
    history.push(
      `${routes.events}?place_name=${events[0].place.split(',')[0]}`
    );
    scrollToTop(location, history);
  };

  useEffect(() => {
    const slug = location.pathname.split('/')[2];

    PlacesApi.getPlaceActivities(slug).then((response) =>
      setActivities(response)
    );
  }, [location.pathname]);

  const eventsList = events.map((event) => <Event key={event.id} {...event} />);

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  if (isLoading) {
    return null;
  }

  return (
    <div
      className={cn(styles.placeEvents, muiStyles.defaultBg)}
      id={PLACE_EVENTS_ID}
    >
      <PageWrapper>
        {!!activities.length && (
          <CardDeck
            cards={activities}
            fixedWidthCards={true}
            sectionTitle={ACTIVITIES_TEXT}
          />
        )}

        <h2 className={styles.sectionTitle}>{HEADER_TEXT}</h2>

        {events.length > 0 ? (
          <div className={styles.eventsListWrapper}>
            <ul className={styles.eventsList}>{eventsList}</ul>

            {isLoading &&
              goTrough(LOADERS_COUNT).map((key) => (
                <Loading key={key} forcedVertical={isMobile} />
              ))}

            {isDesktop && config.theme.isEmpik && <Top />}
          </div>
        ) : (
          <p className={styles.noResults}>{NO_EVENTS_TEXT}</p>
        )}

        {!isLoading && events.length === 4 && (
          <LoadMoreButton onClick={loadMoreRedirect}>
            {SHOW_MORE_TEXT}
          </LoadMoreButton>
        )}
      </PageWrapper>
    </div>
  );
};

export default PlaceEvents;
