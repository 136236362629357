import React from 'react';

import SelectView from '@Compo/reusable/SelectView';
import dateToHumanText from '@Misc/helpers/date/dateToHumanText';

import styles from './StatusBar.module.scss';
import { IStatusBarProps } from './StatusBar.types';

const StatusBar = ({
  dates,
  switchView,
  tileView,
  isMobile,
}: IStatusBarProps) => (
  <div className={styles.statusBarWrapper}>
    {!isMobile && <SelectView tileView={tileView} switchView={switchView} />}
    <div className={styles.dates}>{dateToHumanText(dates.from, dates.to)}</div>
  </div>
);

export default StatusBar;
