import { defaultMemoize } from 'reselect';
import { number, object, string } from 'yup';

import { ITransferTicketPayload } from './TransferTicket.types';

const FIRST_NAME_IS_REQUIRED_TEXT = 'Imię jest wymagane';
const INCORRECT_EMAIL_TEXT = 'Podany adres e-mail jest niepoprawny';
const LAST_NAME_IS_REQUIRED_TEXT = 'Nazwisko jest wymagane';
const REQUIRED_FIELD_TEXT = 'Ile biletów chcesz przekazać';
const REQUIRED_EMAIL_TEXT = 'Adres e-mail jest wymagany';
const TICKETS_NUMBER_TEXT = 'Musisz wybrać co najmniej jeden bilet';

export const initialValues: ITransferTicketPayload = {
  email: '',
  firstName: '',
  lastName: '',
  quantity: 1,
  token: '',
};

const makeValidationSchemaToMemoize = () => {
  const validators = {
    email: string().required(REQUIRED_EMAIL_TEXT).email(INCORRECT_EMAIL_TEXT),

    firstName: string().required(FIRST_NAME_IS_REQUIRED_TEXT),

    lastName: string().required(LAST_NAME_IS_REQUIRED_TEXT),

    quantity: number()
      .required(REQUIRED_FIELD_TEXT)
      .min(1, TICKETS_NUMBER_TEXT),
  };

  return object().shape(validators);
};

export const makeValidationSchema = defaultMemoize(
  makeValidationSchemaToMemoize
);
