import React from 'react';

import PasswordReset from '@Compo/PasswordReset';
import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import { IBreadcrumb } from '@Model/breadcrumb/types';
import routes from '@Routes/routes';

const PASSWORD_RESET_TEXT = 'Resetuj hasło';

const breadcrumbs: IBreadcrumb[] = [
  {
    link: routes.passwordReset,
    name: PASSWORD_RESET_TEXT,
  },
];

const PasswordResetPage = () => (
  <MuiGrid>
    <Helmet pageName={PASSWORD_RESET_TEXT} />
    <FullTriangleBackground />
    <MuiSectionContainer>
      <MuiBreadcrumbs base={breadcrumbs} />
      <PasswordReset />
    </MuiSectionContainer>
  </MuiGrid>
);

export default PasswordResetPage;
