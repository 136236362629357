import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { loadImages } from '@Model/images/actions';
import { getParams } from '@Model/internalRouter/selectors';
import { IArtistMatchParams } from '@Routes/types';

import {
  artistPageMounted,
  getArtist,
  getClosestPool,
  getPastEvents,
} from './../actions';
import { getArtistId } from './../selectors';

export const getArtistWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(artistPageMounted)),
    map$(() => getArtist.request())
  );
};

export const fetchArtistWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { artistApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getArtist.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as IArtistMatchParams;

      if (params) {
        return from$(artistApi.getSingleArtist(params.slug)).pipe(
          mergeMap$((data) => {
            return [
              getArtist.success(data),
              getClosestPool.request(),
              getPastEvents.request(),
              loadImages(),
            ];
          }),
          catchError$((error: Error) => of$(getArtist.failure(error)))
        );
      }

      return EMPTY$;
    }),
    catchError$((error: Error) => of$(getArtist.failure(error)))
  );
};

export const fetchClosestPoolWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { artistApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getClosestPool.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const artistId = getArtistId(state);

      if (artistId) {
        return from$(artistApi.getClosestPool(artistId)).pipe(
          mergeMap$((data) => {
            return [getClosestPool.success(data)];
          }),
          catchError$((error: Error) => of$(getClosestPool.failure(error)))
        );
      }

      return EMPTY$;
    }),
    catchError$((error: Error) => of$(getClosestPool.failure(error)))
  );
};

export const fetchPastEventsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { artistApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPastEvents.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const artistId = getArtistId(state);

      if (artistId) {
        return from$(artistApi.getPastEvents(artistId)).pipe(
          mergeMap$((data) => {
            return [getPastEvents.success(data)];
          }),
          catchError$((error: Error) => of$(getPastEvents.failure(error)))
        );
      }

      return EMPTY$;
    }),
    catchError$((error: Error) => of$(getPastEvents.failure(error)))
  );
};
