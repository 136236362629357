import React, { useEffect } from 'react';

import Helmet from '@Compo/helmet/components/BuyPage';
import loadDOMStyle from '@Misc/helpers/loadDOMStyle';
import scrollToTop from '@Misc/helpers/scrollToTop';
import { useLayoutEffect } from '@Misc/hooks/useIsomorphicLayoutEffect';
import * as MODULES from '@Routes/modules';

import { IPageProps } from './../InternalRouter.types';
import styles from './Embed.module.scss';

const Embed = (props: IPageProps) => {
  const {
    Child,
    externalStyle,
    iframeProps,
    history,
    location,
    locationChange,
    match,
    mounted,
    setBrowserLocale,
    module,
  } = props;

  useLayoutEffect(() => {
    if (setBrowserLocale) {
      setBrowserLocale();
    }
    if (externalStyle) {
      loadDOMStyle(externalStyle);
    }
  });

  useEffect(() => {
    const locationPayload = {
      module: module ?? MODULES.EMPTY,
      params: Object.keys(match.params).length > 0 ? match.params : null,
    };

    locationChange(locationPayload);
  });

  useLayoutEffect(() => {
    scrollToTop(location, history);
  });

  useEffect(() => {
    let triggeringProtectionTimer: NodeJS.Timeout;
    if (mounted) {
      triggeringProtectionTimer = setTimeout(() => mounted(), 100);
    }

    return () => {
      clearTimeout(triggeringProtectionTimer);
    };
  }, []);

  if (iframeProps?.loaded && Child) {
    return (
      <div className={styles.embedWrap}>
        <Helmet />
        <Child />
      </div>
    );
  }

  return null;
};

export default Embed;
