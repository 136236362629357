import React, { FC, Suspense } from 'react';

import Helmet from '@Compo/helmet/components/ContactPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MainPageTop from '@Compo/layout/MainPageTop';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import StaticContentWrapper from '@Compo/layout/StaticContentWrapper';
import { IFormioPageProps } from '@Compo/pages/Formio/FormioPage.types';

const Formio = React.lazy(() => import('@Compo/Formio'));

const FORMIO_FALLBACK_TEXT = 'Wczytywanie...';

const FormioPage: FC<IFormioPageProps> = ({ title }) => {
  return (
    <MuiGrid>
      <Helmet pageName={title} />
      <FullTriangleBackground />
      <MuiSectionContainer>
        <Suspense fallback={<p>{FORMIO_FALLBACK_TEXT}</p>}>
          <MainPageTop title={title} />
          <StaticContentWrapper>
            <Formio />
          </StaticContentWrapper>
        </Suspense>
      </MuiSectionContainer>
    </MuiGrid>
  );
};

export default FormioPage;
