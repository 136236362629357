import React, { FC, useEffect, useRef } from 'react';

import cn from 'classnames';

import SimpleLoading from '@Compo/reusable/SimpleLoading';
import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import routes from '@Routes/routes';

import {
  getValuesFromObjectToUrl,
  putAfterSubmitAction,
} from './Formio.helpers';
import './Formio.module.scss';
import { IFormioProps, IFormioRedirectProps } from './Formio.types';

const FormIoActions = {
  AUTO_REDIRECT_FORM_PATH: 'autoRedirectFormPath',
  PUT_AFTER_SUBMIT: 'putAfterSubmit',
  REDIRECT_AFTER_SUBMIT_FORM_PATH: 'redirectAfterSubmitFormPath',
};

const Formio: FC<IFormioProps> = ({
  formSlug,
  redirect,
  isEmbed,
  langButton,
  locale,
  params,
  changeLocale,
  setError,
  isDarkTheme,
  isWebview,
}) => {
  const formRef = useRef(null);
  const formLink = config.formIo.location + formSlug;
  const doneSlug = isWebview
    ? formSlug +
      config.formIo.doneSlug +
      `?isWebview=true${isDarkTheme ? '&isDark=true' : ''}`
    : formSlug + config.formIo.doneSlug;
  const showLangButton =
    formSlug && config.formIo.translatedForms.includes(formSlug);

  const createFormPath = (ownFormPath?: string): string | null => {
    if (!ownFormPath) {
      return null;
    }

    return fillUrlWithValues(
      isEmbed ? routes.pinForm : routes.form,
      ':slug',
      ownFormPath
    );
  };

  const appendForm = () => {
    setTimeout(() => {
      try {
        window.Formio.createForm(formRef.current, formLink, {
          i18n: {
            pl: config.formIo.langPL.default,
            en: config.formIo.langEN.goingSupport, // tslint:disable-line:object-literal-sort-keys
          },
          language: locale,
          readOnly: false,
        })
          .then((form: IFormioRedirectProps) => {
            /**
             * Last check form actions
             */
            const autoRedirectFormPath = form.components.find(
              (component) =>
                component.path === FormIoActions.AUTO_REDIRECT_FORM_PATH
            )?.component?.defaultValue;

            const redirectAfterSubmitFormPath = form.components.find(
              (component) =>
                component.path === FormIoActions.REDIRECT_AFTER_SUBMIT_FORM_PATH
            )?.component?.defaultValue;

            const putAfterSubmit = form.components.find(
              (component) => component.path === FormIoActions.PUT_AFTER_SUBMIT
            )?.component?.customDefaultValue;

            if (autoRedirectFormPath) {
              setTimeout(() => {
                redirect(autoRedirectFormPath);
              }, config.formIo.autoRedirectTime);
            }

            if (params) {
              form.submission = {
                data: params,
              };
            }

            form.on('submitDone', (data: any) => {
              const redirectUrl: string =
                createFormPath(redirectAfterSubmitFormPath) ||
                createFormPath(doneSlug) ||
                '';

              if (data.data[FormIoActions.PUT_AFTER_SUBMIT] && data._id) {
                const formUrl = `${
                  data.data[FormIoActions.PUT_AFTER_SUBMIT]
                }?formIoSubmissionId=${data._id}&${getValuesFromObjectToUrl(
                  data.data
                )}`;

                putAfterSubmitAction(
                  formUrl,
                  () => redirect(redirectUrl),
                  setError
                );
              } else {
                redirect(redirectUrl);
              }
            });
          })
          .catch((): null => null);
      } catch (noSSR) {}
    }, 1000);
  };

  const translateForm = () => {
    if (locale === 'pl') {
      changeLocale({ selectedLang: 'en' });
    } else {
      changeLocale({ selectedLang: 'pl' });
    }
  };

  const isDonePage = formSlug?.includes('-done');
  const isBackButtonDisabled = isDonePage && isWebview;

  useEffect(() => {
    if (formSlug) {
      appendForm();
    }
  }, [formSlug, locale, formRef]);

  return (
    <div
      className={cn(
        isDarkTheme && 'dark',
        isBackButtonDisabled && 'backButtonDisabled'
      )}
    >
      {showLangButton && (
        <button className={'langButton'} onClick={translateForm}>
          {langButton}
        </button>
      )}
      <div className={config.formIo.elementClass} ref={formRef}>
        <SimpleLoading />
      </div>
    </div>
  );
};

export default Formio;
