/**
 * Function converting number to bool
 *
 * @param {number | string} number - number to convert
 *
 */
const convertToBool = (number: number | string): boolean => {
  if (typeof number === 'string') {
    return parseInt(number, 10) !== 0;
  }

  return number !== 0;
};

export default convertToBool;
