import * as React from 'react';

import { Button, Typography } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { ISuccessDialogProps } from '@Compo/eventBoxes/Buy/components/Following/components/SuccessDialog/SuccessDialog.types';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import backgroundDesktop from '@Misc/styles/images/following_dialog_02_desktop.png';
import backgroundMobile from '@Misc/styles/images/following_dialog_02_mobile.png';
import { useDictionary } from '@Recoil/lang/hooks/useDictionary';
import routes from '@Routes/routes';

import classes from './SuccessDialog.module.scss';

export const SuccessDialog = ({ handleHide }: ISuccessDialogProps) => {
  const { isMobile } = useMuiBreakpoints();
  const i18n = useDictionary();

  return (
    <div className={cn(classes.dialog, isMobile && classes.mobile)}>
      <div className={classes.imageContainer}>
        <img
          src={isMobile ? backgroundMobile : backgroundDesktop}
          alt={'Going.'}
        />
      </div>
      <div className={classes.dialogBody}>
        <div className={classes.content}>
          <div className={classes.header}>
            <Typography variant={'body1'} children={i18n.following.success} />
            {!isMobile && (
              <IconButton onClick={handleHide}>
                <Close />
              </IconButton>
            )}
          </div>
          <Typography
            variant={'body2'}
            children={i18n.following.successDownload}
          />
        </div>
        <div className={classes.footer}>
          <Button
            onClick={handleHide}
            size={'large'}
            children={i18n.following.successButtonNope}
          />
          <Link to={routes.about}>
            <Button
              fullWidth={isMobile}
              className={classes.submitButton}
              variant={'contained'}
              color={'primary'}
              size={'large'}
              children={i18n.following.successButtonYes}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
