import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  getTransactionInfo,
  sendTransaction,
} from '@Model/transaction/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONSTS from './../constants/constants';

export const setLoaderWhenTransactionSendRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([sendTransaction.request, getTransactionInfo.request])),
    map$(() => setLoading(CONSTS.TRANSACTION))
  );
};

export const setLoaderWhenTransactionSendFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        sendTransaction.success,
        sendTransaction.failure,
        getTransactionInfo.success,
        getTransactionInfo.failure,
      ])
    ),
    map$(() => resetLoading(CONSTS.TRANSACTION))
  );
};
