import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isMobile } from '@Model/app/selectors';
import { getEvent } from '@Model/event/selectors';
import { happeningMounted } from '@Model/happening/actions';
import {
  getData,
  getExtendedSlots,
  getIsSelectedSlotLastInCurrentDay,
  getSelected,
  getSelectedSlotHasUpsell,
  isAllSelected,
} from '@Model/happening/selectors';
import { getIframeParams, getParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { mountProducts } from '@Model/products/actions';
import { getAreAnyProductsAvailable } from '@Model/products/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { getTheme } from '@Model/theme/selectors';

import Happening from './Happening.component';
import { IHappeningFromDispatch, IHappeningFromState } from './Happening.types';

const mapStateToProps = (state: _Store.IState): IHappeningFromState => ({
  areAnyProductsAvailable: getAreAnyProductsAvailable(state),
  calculatePricePerPerson: getData(state)?.calculatePricePerPerson,
  canReserve: isAllSelected(state),
  dateTranslation: translate(state)('dates'),
  lang: translate(state)('buy', 'happening'),
  happeningEndDate: getData(state)?.endDate,
  happeningSpaces: getData(state)?.spaces,
  happeningStartDate: getData(state)?.startDate,
  hasExtendedSlot: !!getExtendedSlots(state).length,
  isEmbed: !!getIframeParams(state),
  isActivityEventLoading: getLoading(CONST.EVENT)(state),
  isHappeningLoading: getLoading(CONST.HAPPENING)(state),
  isLoadingPrice: getLoading(CONST.HAPPENING_PRICE)(state),
  isMobile: isMobile(state),
  isSelectedSlotLastInCurrentDay: getIsSelectedSlotLastInCurrentDay(state),
  params: getParams(state),
  selectedSpace: getSelected(state).space,
  selectedTheme: getTheme(state),
  slotHasUpsell: getSelectedSlotHasUpsell(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IHappeningFromDispatch => ({
  mountProducts: () => dispatch(mountProducts()),
  mounted: () => dispatch(happeningMounted()),
});

export default connect<
  IHappeningFromState,
  IHappeningFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Happening);
