import { createSelector } from 'reselect';

import _Store from '@Store';

import { ILocations, ILocationsReducer } from './../types';
import get from './get';

const getLocations = createSelector<
  _Store.IState,
  ILocationsReducer,
  ILocations
>([get], (slice) => slice.locations);

export default getLocations;
