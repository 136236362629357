import React, { FC } from 'react';

import { Grid, useTheme } from '@material-ui/core';
import cn from 'classnames';

import classes from './Success.classes';
import useStyles from './Success.styles';
import { ISuccessProps } from './Success.types';
import DownloadSection from './components/DownloadSection';
import SellSuccessHeader from './components/SellSuccessHeader';
import SellSuccessInfo from './components/SellSuccessInfo';
import SummaryAfterSellSegment from './components/SummaryAfterSellSegment';

const Success: FC<ISuccessProps> = ({ transferSending, transferReceived }) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  return (
    <Grid
      container={true}
      className={cn(muiStyles.wrapper, classes.successContainer)}
    >
      <Grid
        item={true}
        xs={12}
        className={cn(muiStyles.containerMargin, classes.sellSuccessHeader)}
      >
        <SellSuccessHeader />
      </Grid>
      <Grid
        item={true}
        xs={12}
        className={cn(muiStyles.containerMargin, classes.sellSuccessInfo)}
      >
        <SellSuccessInfo />
      </Grid>
      <Grid
        item={true}
        xs={12}
        className={cn(
          muiStyles.containerMargin,
          classes.summaryAfterSellSegment
        )}
      >
        <SummaryAfterSellSegment />
      </Grid>
      <Grid
        item={true}
        className={cn(
          muiStyles.marginForLastComponent,
          classes.downloadSection
        )}
      >
        <DownloadSection />
      </Grid>
    </Grid>
  );
};

export default Success;
