import LocaleService from '@Misc/classes/LocaleService';

import { ILocaleDetectorTypes } from './types';

class LocaleDetector extends LocaleService {
  public init(options: ILocaleDetectorTypes): Promise<string> {
    const supportedLocales: ReadonlyArray<string> = Object.freeze(
      options.supportedLocales.map(LocaleDetector.normalizeLocale)
    );

    return new Promise((resolve, reject) => {
      try {
        const getUserLocale = () => {
          return (
            window.navigator.language ||
            window.browserLanguage ||
            window.userLanguage
          );
        };

        // TODO: Jest na sztywno po polsku bo coś dziwnego się dzieje przy ładowaniu apki na angielskich systemach (nie wszystkich)
        resolve(
          LocaleDetector.resolveUserLocale(supportedLocales, 'pl') || 'pl'
        );
      } catch (error: any) {
        reject(new Error(error.message));
      }
    });
  }
}

export default new LocaleDetector();
