import React, { useContext, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import cn from 'classnames';

import { PayUContext } from '@Compo/functional/PayUContextProvider/PayUContextProvider.component';

import styles from './CardPaymentForm.module.scss';

const CardPaymentForm = () => {
  const { cardNumberForm, cardDateForm, cardCVVForm, isCardPaymentMethod } =
    useContext(PayUContext);
  const payuContainerIdentifiers = {
    number: 'payu-card-number',
    date: 'payu-card-date',
    cvv: 'payu-card-cvv',
  };

  useEffect(() => {
    if (isCardPaymentMethod) {
      cardNumberForm?.render('#' + payuContainerIdentifiers.number);
      cardDateForm?.render('#' + payuContainerIdentifiers.date);
      cardCVVForm?.render('#' + payuContainerIdentifiers.cvv);
    }
  }, [isCardPaymentMethod]);

  return (
    <Grid
      container={true}
      item={true}
      className={cn(styles.payUCardFormContainer, 'payu-card-form-container')}
    >
      <Grid item={true} className={styles.payUCardFormInnerContainer}>
        <div
          className={cn(styles.payuCardForm, 'payu-card-form')}
          id={payuContainerIdentifiers.number}
        />
      </Grid>
      <Grid item={true} className={styles.payUCardFormInnerContainer}>
        <div
          className={cn(styles.payuCardForm, 'payu-card-form')}
          id={payuContainerIdentifiers.date}
        />
      </Grid>
      <Grid item={true} className={styles.payUCardFormInnerContainer}>
        <div
          className={cn(styles.payuCardForm, 'payu-card-form')}
          id={payuContainerIdentifiers.cvv}
        />
      </Grid>
    </Grid>
  );
};

export default CardPaymentForm;
