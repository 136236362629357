import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISearchReducer } from '@Model/search/types';

import get from './get';

const getSearchOffset = createSelector<_Store.IState, ISearchReducer, number>(
  [get],
  (search) => search.searchPaginationOffset
);

export default getSearchOffset;
