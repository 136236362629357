import { getType } from 'typesafe-actions';

import { getLandingPage, setCurrentKey } from './../actions';
import { IAction, ILandingPageReducer } from './../types';

const initialState: ILandingPageReducer = {
  currentKey: null,
  data: {},
  requestTimes: {},
};

const reducer = (
  state: ILandingPageReducer = initialState,
  action: IAction
): ILandingPageReducer => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getLandingPage.success):
      const { landingPage, key } = action.payload;

      return {
        ...state,
        currentKey: key,
        data: {
          ...state.data,
          [key]: landingPage,
        },
        requestTimes: {
          ...state.requestTimes,
          [key]: Date.now(),
        },
      };

    case getType(setCurrentKey):
      return {
        ...state,
        currentKey: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
