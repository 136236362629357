import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import { IGetSelectionSuccessPayload, ISetCurrentKeyPayload } from './../types';

export const selectionPageMounted = createStandardAction(
  CONSTS.SELECTION_PAGE_MOUNTED
)();

export const getSelection = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IGetSelectionSuccessPayload, Error>();

export const setCurrentKey = createStandardAction(
  CONSTS.SET_CURRENT_KEY
)<ISetCurrentKeyPayload>();

export const getSelectionSlug = createAsyncAction(
  CONSTS._GET_SELECTION_SLUG_REQUEST,
  CONSTS._GET_SELECTION_SLUG_SUCCESS,
  CONSTS._GET_SELECTION_SLUG_FAILURE
)<number, IGetSelectionSuccessPayload, Error>();
