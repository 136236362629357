import React, { FC } from 'react';

import cn from 'classnames';

import config from '@Config';

import { IAppClassNameFromState } from './AppClassName.types';

const App: FC<IAppClassNameFromState> = ({
  isDesktop,
  isMobile,
  isTablet,
  mobileUserAgent,
  children,
  client,
}) => (
  <div
    className={cn({
      desktop: isDesktop,
      empik: config.theme.isEmpik,
      going: config.theme.isGoing,
      mobile: isMobile,
      [mobileUserAgent]: mobileUserAgent,
      sbm: config.theme.isSbm,
      tablet: isTablet,
      [client]: client,
    })}
    children={children}
  />
);

export default App;
