import React from 'react';

import { Button, Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useGlobalStyles from '../../global.styles';
import Paper from '../Paper';
import { makeValidationSchema } from './CheckSubmission.selector';
import { ICheckSubmissionProps } from './CheckSubmission.types';

const CheckSubmission = ({
  handlePrevStep,
  checkSubmissionsExists,
}: ICheckSubmissionProps) => {
  const globals = useGlobalStyles();
  const { entryList } = useDictionary();

  const handleFormSubmit = (values: { email: string }) => {
    checkSubmissionsExists(values.email);
  };

  return (
    <Paper
      title={entryList.checkSubmission.title}
      helperText={entryList.checkSubmission.helper}
    >
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleFormSubmit}
        validationSchema={makeValidationSchema(entryList.validation)}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <TextField
                  name="email"
                  helperText={!!(touched.email && errors.email) && errors.email}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={entryList.checkSubmission.placeholder}
                  variant="outlined"
                  fullWidth={true}
                  error={!!(touched.email && errors.email)}
                  inputProps={{ inputMode: 'email' }}
                  autoCapitalize="false"
                  autoCorrect="false"
                  autoComplete="off"
                />
              </Grid>

              <Grid item={true} xs={12} className={globals.gapTop}>
                <Grid
                  container={true}
                  justifyContent="center"
                  className={globals.column}
                >
                  <Grid item={true}>
                    <Button
                      className={globals.button}
                      type="button"
                      variant="text"
                      color="primary"
                      onClick={handlePrevStep}
                    >
                      {entryList.checkSubmission.goBack}
                    </Button>
                  </Grid>
                  <Grid item={true}>
                    <Button
                      className={globals.button}
                      variant="contained"
                      size="medium"
                      color="primary"
                      type={'submit'}
                    >
                      {entryList.checkSubmission.goNext}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

export default CheckSubmission;
