import { eventBannerClick } from '@/models/analytics/actions';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isDesktop, isMobile, isTablet } from '@Model/app/selectors';
import { eventPageMounted } from '@Model/event/actions';
import {
  getEvent,
  getPlaceEvents,
  getSimilarEvents,
} from '@Model/event/selectors';
import { getEventImages, getPlaceImages } from '@Model/images/selectors';
import { getIframeParams, getParams } from '@Model/internalRouter/selectors';
import { getSelectedLocation } from '@Model/locations/selectors';
import { getUrlStructure } from '@Model/pages/selectors';
import { EVENT, PREMIUM } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import Event from './Event.component';
import { IEventFromDispatch, IEventFromState } from './Event.types';

const mapStateToProps = (state: _Store.IState): IEventFromState => ({
  event: getEvent(state),
  placeImage: getPlaceImages(state),
  isDesktop: isDesktop(state),
  isEmbed: !!getIframeParams(state),
  isLoading: getLoading(EVENT)(state) || getLoading(PREMIUM)(state),
  isLoadingEvent: getLoading(EVENT)(state),
  isMobile: isMobile(state),
  isTablet: isTablet(state),
  params: getParams(state),
  placeEvents: getPlaceEvents(state),
  similarEvents: getSimilarEvents(state),
  structure: getUrlStructure(state),
  userLocation: getSelectedLocation(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IEventFromDispatch => {
  return {
    eventBannerClick: (label) => dispatch(eventBannerClick(label)),
    mounted: () => dispatch(eventPageMounted()),
  };
};

export default connect<IEventFromState, IEventFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Event);
