import React, { useEffect, useState } from 'react';

import config from '@Config';
import linkToFileInStorage from '@Misc/helpers/linkToFileInStorage';
import _L from '@Misc/helpers/local';
import * as LOCALS from '@Vars/constants';

import styles from './CookieBar.module.scss';
import { ICookieBarProps } from './CookieBar.types';

const COOKIE_TEXT = 'Ta strona używa plików cookie.';
const READ_MORE_TEXT = 'Dowiedz się więcej';
const ACCEPT_TEXT = 'Ok';

const STORAGE_KEY = 'cookie_accepted';
const ACCEPTED_VALUE = 'true';

const CookieBar = ({ isBrowser, isEmbed }: ICookieBarProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    try {
      if (
        isBrowser &&
        !isEmbed &&
        localStorage.getItem(STORAGE_KEY) !== ACCEPTED_VALUE
      ) {
        setShow(true);
      }
    } catch (noSSR) {}
  }, []);

  const acceptCookie = () => {
    try {
      localStorage.setItem(STORAGE_KEY, ACCEPTED_VALUE);
    } catch (noSSR) {}

    setShow(false);
  };

  return !show ? null : (
    <div className={styles.cookieBar}>
      <div className={styles.info}>
        {COOKIE_TEXT}{' '}
        <a
          href={linkToFileInStorage(
            _L(LOCALS.STORAGE__PRIVACY_POLICY),
            true,
            config.storage.baseUrl
          )}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          {READ_MORE_TEXT}
        </a>
      </div>
      <div className={styles.accept}>
        <button onClick={acceptCookie}>{ACCEPT_TEXT}</button>
      </div>
    </div>
  );
};

export default CookieBar;
