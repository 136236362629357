import React, { useState } from 'react';

import cn from 'classnames';

import styles from './ButtonDescription.module.scss';
import { IButtonDescriptionProps } from './ButtonDescription.types';

const ButtonDescription = (props: IButtonDescriptionProps) => {
  const [showDescription, setShowDescription] = useState(false);

  const handleClick = () => {
    setShowDescription(!showDescription);
    props.onClick();
  };

  return (
    <div className={styles.description}>
      <button onClick={handleClick}>
        {props.buttonHideText && showDescription
          ? props.buttonHideText
          : props.buttonText}
        <i
          className={cn(styles.arrow, {
            [styles.down]: !showDescription,
            [styles.up]: showDescription,
          })}
        />
      </button>
    </div>
  );
};

export default ButtonDescription;
