import { createSelector } from 'reselect';

import * as CONSTS from './../constants/constants';
import getDevice from './getDevice';

const isMobile = createSelector(
  [getDevice],
  (device) => device === CONSTS.MOBILE
);

export default isMobile;
