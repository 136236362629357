import axios, { CancelTokenSource } from 'axios';

import { ICard } from '@Compo/reusable/Card/Card.types';
import config from '@Config';
import EventsListService from '@Misc/classes/EventsListService';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import makeFriendlyDate from '@Misc/helpers/makeFriendlyDate';
import prepareHotSpotUri from '@Misc/helpers/prepareHotSpotsUri';
import { IEvent } from '@Model/events/types';
import { DynamicListingsKeys } from '@Model/pages/constants/dynamicListings';
import { PagesEnum } from '@Model/pages/constants/pages';
import {
  IComponentExtraProps,
  ISectionsComponentExtendedHotSpot,
} from '@Model/pages/types/sections';
import routes from '@Routes/routes';

import { ICompositionResponse } from './types';

class SectionsApi {
  private static getSectionsUrl(
    page: PagesEnum,
    slug: string,
    groupSlug?: string,
    email?: string | null,
    entryToken?: string | null,
    version?: string
  ): string {
    const authParams =
      email && entryToken ? `?email=${email}&entryToken=${entryToken}` : '';
    const isAuthParams = authParams.length > 0;
    const versionParam = version
      ? `${isAuthParams ? '&' : '?'}version=${version}`
      : '';
    const formattedSlug = slug && slug !== 'undefined' ? slug : '';

    if (groupSlug) {
      return `${config.api.baseUrlV2}page/${config.app.serviceName}/${PagesEnum[page]}/${groupSlug}/${formattedSlug}${authParams}${versionParam}`;
    }

    return `${config.api.baseUrlV2}page/${config.app.serviceName}/${PagesEnum[page]}/${formattedSlug}${authParams}${versionParam}`;
  }

  private cancelTokenSections?: CancelTokenSource;

  public getSections(
    page: PagesEnum,
    slug: string,
    groupSlug?: string,
    email?: string | null,
    entryToken?: string | null,
    version?: string
  ): Promise<ICompositionResponse> {
    return new Promise((resolve, reject) => {
      const sectionUri = SectionsApi.getSectionsUrl(
        page,
        slug,
        groupSlug,
        email,
        entryToken,
        version
      );

      this.cancelTokenSections = axios.CancelToken.source();

      axios
        .get(sectionUri)
        .then(getData)
        .then((response: ICompositionResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelSections() {
    if (this.cancelTokenSections) {
      this.cancelTokenSections.cancel();
      this.cancelTokenSections = undefined;
    }
  }

  public normalizeCloudsearchResponseToCardData(
    events: IEvent[],
    extra?: IComponentExtraProps
  ): ICard[] {
    const cards: ICard[] = [];

    events.map((event) =>
      cards.push({
        date: event.dateDescription
          ? event.dateDescription
          : EventsListService.getFriendlyDate(event.startDate),
        description: extra?.disableDescription ? null : event.description,
        link: fillUrlWithValues(
          routes.event,
          [':eventSlug', ':rundateSlug'],
          [event.eventSlug, event.rundateSlug]
        ),
        place: extra?.disablePlace ? null : event.place,
        placeSlug: event.placeSlug || null,
        thumb: event.imageUrl || null,
        title: extra?.disableTitle ? null : event.title,
      })
    );

    return cards;
  }

  public normalizeElementsToCardData(
    hotSpotsData: ISectionsComponentExtendedHotSpot[],
    extra?: IComponentExtraProps
  ): ICard[] {
    const cards: ICard[] = [];

    hotSpotsData.map((card) =>
      cards.push({
        badge: card.badge,
        ctaButton: card.ctaButton,
        date: card.dateDesc ? card.dateDesc : makeFriendlyDate(card.date),
        description: extra?.disableDescription ? null : card.description,
        link: prepareHotSpotUri(card),
        place: extra?.disablePlace ? null : card.place,
        placeSlug: card.placeSlug || null,
        secondaryCtaButton: card.secondaryCtaButton,
        subtitle: card.subtitle || null,
        thumb: card.thumbUrl || null,
        title: extra?.disableTitle ? null : card.title,
      })
    );

    return cards;
  }

  public getQueryParamsForDynamicListings() {
    const lastPurchaseTags = sessionStorage.getItem(
      DynamicListingsKeys.lastPurchaseTags
    );
    const lastPurchasePlaces = sessionStorage.getItem(
      DynamicListingsKeys.lastPurchasePlaces
    );
    const lastPurchaseArtists = sessionStorage.getItem(
      DynamicListingsKeys.lastPurchaseArtists
    );

    const queryParams: { [key: string]: string } = {};

    const parsedTags: string[] | undefined = lastPurchaseTags
      ? JSON.parse(lastPurchaseTags)
      : undefined;
    parsedTags?.map((tag, index) => {
      queryParams[`tag${index}`] = tag;
    });

    const parsedPlaces: string[] | undefined = lastPurchasePlaces
      ? JSON.parse(lastPurchasePlaces)
      : undefined;
    parsedPlaces?.map((place, index) => {
      queryParams[`place${index}`] = place;
    });

    const parsedArtists: string[] | undefined = lastPurchaseArtists
      ? JSON.parse(lastPurchaseArtists)
      : undefined;
    parsedArtists?.map((artist, index) => {
      queryParams[`artist${index}`] = artist;
    });

    return queryParams;
  }
}

export default new SectionsApi();
