import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  slider: {
    '& > div:nth-child(4)': {
      background: theme.palette.secondary.dark,
    },
  },
  sliderWrapper: {
    height: 40,
  },
  value: {
    alignItems: 'center',
    boxShadow: 'none',
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    outline: 'none',
    textAlign: 'center',
    width: '6rem',

    '&:active': {
      boxShadow: 'none',
    },
  },
  wrapper: {
    borderColor: theme.palette.secondary.dark,
    borderStyle: 'solid',
    borderWidth: 3,
  },
}));

export default useStyles;
