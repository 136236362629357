import { ICustomCodeOwnProps } from '@Compo/Bricks/components/CustomCode/CustomCode.types';
import {
  ISectionsComponentCustomCode,
  ISectionsComponentDataMutable,
} from '@Model/pages/types/sections';

export const custom_code = (
  data: ISectionsComponentDataMutable
): ICustomCodeOwnProps => {
  const customCodeData = data as ISectionsComponentCustomCode[];
  const customCodeElement = customCodeData[0];

  return {
    cssSrc: customCodeElement.cssSrc,
    htmlSrc: customCodeElement.htmlSrc,
  };
};
