import { connect } from 'react-redux';

import _Store from '@Store';

import { isBrowser } from '@Model/__base__/selectors';
import { isMobile } from '@Model/app/selectors';
import { getLocation } from '@Model/router/selectors';

import Section from './Section.component';
import { ISectionFromState, ISectionOwnProps } from './Section.types';

const mapStateToProps = (state: _Store.IState): ISectionFromState => {
  return {
    isBrowser: isBrowser(state),
    isDarkMode: getLocation(state).query?.isDark
      ? getLocation(state).query?.isDark === 'true'
      : false,
    isMobile: isMobile(state),
  };
};

export default connect<
  ISectionFromState,
  null,
  ISectionOwnProps,
  _Store.IState
>(mapStateToProps)(Section);
