import React, { useContext, useEffect } from 'react';

import {
  Grid,
  Icon,
  Link,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Alert } from '@material-ui/lab';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Field } from 'formik';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import { classes } from '@Compo/buying/components/Insurance/Insurance.classes';
import useStyles from '@Compo/buying/components/Insurance/Insurance.styles';
import { IInsuranceProps } from '@Compo/buying/components/Insurance/Insurance.types';
import ToggleField from '@Compo/buying/components/ToggledField';
import Checkbox from '@Compo/forms/Checkbox';
import ToolTip from '@Compo/reusable/ToolTip';
import config from '@Config';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import useUpdateEffect from '@Misc/hooks/useUpdateEffect';

import styles from './Insurance.module.scss';

const OWU_LINK =
  'https://web.goingapp.pl/storage/app/media/ProTicket/1.%20OWU_Going.ProTicket.pdf';
const PID_LINK =
  'https://web.goingapp.pl/storage/app/media/ProTicket/2-dokument-zawierajacy-informacje-o-produkcie-ubezpieczeniowympid.pdf';
const RODO_LINK =
  'https://web.goingapp.pl/storage/app/media/ProTicket/3-informacje-dotyczace-przetwarznia-danych-osobowychrodo.pdf';
const CONSUMER_LINK =
  'https://web.goingapp.pl/storage/app/media/ProTicket/4-obowiazki-informacyjne-wynikajace-z-ustawy-o-prawach-konumenta.pdf';

const Insurance = ({
  step,
  lang,
  selectedTickets,
  setFieldValue,
  insuranceTotalPrice,
  insuranceProducts,
  touched,
  values,
  isDisabled,
  eventAddToCart,
  eventRemoveFromCart,
  isBasket,
  reloadInsurance,
}: IInsuranceProps) => {
  const theme = useTheme();
  const { isMobile } = useMuiBreakpoints();
  const muiStyles = useStyles(isMobile)(theme);
  const basketTickets =
    useContext(clearContext)?.basketItems.map((basketItem) => ({
      amount: basketItem.amount,
      price: basketItem.price / basketItem.amount,
    })) || [];
  const isInsuranceProperlyLoaded = insuranceProducts.length === 9 || isBasket;
  const currentBasket = [...selectedTickets /*...basketTickets*/];

  const totalSelectedTicketsNumber = currentBasket
    .map((pool) => pool.amount)
    .reduce((previous, current) => previous + current, 0);
  const insuranceOptionalFieldNames = ['isAccidentInsurance'];
  const insuranceRequiredFieldNames = [
    'isInsuranceAdultCheckbox',
    'insuranceBirthDate',
    'insuranceIsLivingInPoland',
  ];
  const isValidTicketsNumber =
    totalSelectedTicketsNumber <= config.buy.maxInsuredTicketsLimit;
  const isValidTicketPrice =
    !!currentBasket.length &&
    currentBasket.every(
      (pool) => !!pool.price && pool.price >= 5 && pool.price <= 2500
    );
  const isShowError =
    (values.isInsurance &&
      (!values.isInsuranceAdultCheckbox ||
        !values.insuranceBirthDate ||
        !values.insuranceIsLivingInPoland) &&
      insuranceRequiredFieldNames.find(
        (fieldName) => touched.hasOwnProperty(fieldName) && !values[fieldName]
      )) ||
    !isValidTicketsNumber ||
    !isValidTicketPrice;

  const handleReloadInsurance = () => {
    reloadInsurance();
  };

  const toolTip = (
    <>
      <p>{lang.tooltipPart1}</p>
      <ul className={muiStyles.toolTipList}>
        <li className={muiStyles.toolTipListElement}>{lang.tooltipLi1}</li>
        <li className={muiStyles.toolTipListElement}>{lang.tooltipLi2}</li>
        <li className={muiStyles.toolTipListElement}>{lang.tooltipLi3}</li>
        <li className={muiStyles.toolTipListElement}>{lang.tooltipLi4}</li>
        <li className={muiStyles.toolTipListElement}>{lang.tooltipLi5}</li>
        <li className={muiStyles.toolTipListElement}>{lang.tooltipLi6}</li>
      </ul>
      <p>{lang.tooltipPart2}</p>
    </>
  );

  useEffect(() => {
    if (!isValidTicketsNumber || !isValidTicketPrice || isBasket) {
      setFieldValue('isInsurance', false);
    }
  }, [isValidTicketsNumber, isValidTicketPrice, isBasket]);

  useUpdateEffect(() => {
    if (values.isInsurance) {
      eventAddToCart({
        price: insuranceTotalPrice,
        currency: 'PLN',
        isInsuranceAddToCartEvent: true,
        isInsuranceSelected: values.isInsurance,
      });
    } else {
      eventRemoveFromCart({
        price: insuranceTotalPrice,
        currency: 'PLN',
        isInsuranceAddToCartEvent: true,
      });
    }
  }, [values.isInsurance]);

  return (
    <Grid
      item={true}
      container={true}
      direction={'column'}
      className={cn(
        muiStyles.container,
        isDisabled && muiStyles.disabled,
        styles.insuranceBox,
        classes.insuranceBox
      )}
    >
      <Typography
        variant={'h3'}
        children={`${step}. ${lang.insuranceHeader}`}
        className={muiStyles.heading}
      />
      <Paper elevation={0} className={muiStyles.box}>
        {isInsuranceProperlyLoaded ? (
          <Grid container={true} direction={'column'} spacing={3}>
            <Grid container={true} item={true} spacing={3}>
              {(!!currentBasket.length && !!isShowError) ||
                (isBasket && (
                  <Grid item={true} container={true} spacing={3}>
                    <Grid item={true}>
                      <Alert severity={'error'}>
                        {isBasket
                          ? config.theme.isEmpik
                            ? lang.insuranceNotAvailableforBasketEmpik
                            : lang.insuranceNotAvailableforBasket
                          : !isValidTicketsNumber
                          ? lang.tooManyTickets
                          : !isValidTicketPrice
                          ? lang.invalidTicketPrice
                          : lang.invalidInsuranceFormData}
                      </Alert>
                    </Grid>
                  </Grid>
                ))}
              <Grid item={true} className={muiStyles.stretch}>
                <Field
                  component={Checkbox}
                  name={'isInsurance'}
                  id={'isInsurance'}
                  children={lang.choose}
                  disabled={
                    !isValidTicketsNumber || !isValidTicketPrice || isBasket
                  }
                />
              </Grid>
              {isValidTicketsNumber && isValidTicketPrice && (
                <Grid item={true}>
                  <Typography
                    variant={'body1'}
                    children={`+${getPriceFormatted(insuranceTotalPrice)} zł`}
                  />
                </Grid>
              )}
              <Grid item={true} className={'insurance-tooltip'}>
                <ToolTip title={toolTip}>
                  <Icon component={InfoIcon} />
                </ToolTip>
              </Grid>
            </Grid>
            {values.isInsurance && (
              <Grid
                container={true}
                item={true}
                className={muiStyles.indentation}
                direction={'column'}
              >
                <Grid item={true}>
                  <Field
                    component={Checkbox}
                    name={insuranceOptionalFieldNames[0]}
                    id={insuranceOptionalFieldNames[0]}
                    children={lang.accidentInsurance + '*'}
                  />
                </Grid>
                <Grid item={true} className={muiStyles.checkboxGap}>
                  <ToggleField
                    checkboxId={insuranceRequiredFieldNames[0]}
                    inputId={insuranceRequiredFieldNames[1]}
                    placeholder={lang.birthdayPlaceholder}
                    open={values.isInsurance && values.isInsuranceAdultCheckbox}
                    text={lang.iAmAdult + '*'}
                    helperText={lang.insuranceBirthDateHelperText}
                  />
                </Grid>
                <Grid item={true} className={muiStyles.checkboxGap}>
                  <Field
                    component={Checkbox}
                    name={insuranceRequiredFieldNames[2]}
                    id={insuranceRequiredFieldNames[2]}
                    children={lang.iLiveInPoland + '*'}
                  />
                </Grid>
                <Grid
                  item={true}
                  className={cn(muiStyles.fitContent, muiStyles.checkboxGap)}
                >
                  <Link
                    className={muiStyles.link}
                    href={OWU_LINK}
                    target={'_blank'}
                  >
                    <Typography
                      variant={'subtitle1'}
                      children={lang.moreInsuranceInfo}
                      className={muiStyles.infoLink}
                    />
                  </Link>
                </Grid>
                <Grid item={true} className={muiStyles.fitContent}>
                  <Link
                    className={muiStyles.link}
                    href={PID_LINK}
                    target={'_blank'}
                  >
                    <Typography
                      variant={'subtitle1'}
                      children={lang.insuranceProductInfo}
                      className={muiStyles.infoLink}
                    />
                  </Link>
                </Grid>
                <Grid item={true} className={muiStyles.fitContent}>
                  <Link
                    className={muiStyles.link}
                    href={CONSUMER_LINK}
                    target={'_blank'}
                  >
                    <Typography
                      variant={'subtitle1'}
                      children={lang.consumerRights}
                      className={muiStyles.infoLink}
                    />
                  </Link>
                </Grid>
                <Grid item={true} className={muiStyles.fitContent}>
                  <Link
                    className={muiStyles.link}
                    href={RODO_LINK}
                    target={'_blank'}
                  >
                    <Typography
                      variant={'subtitle1'}
                      children={lang.rodo}
                      className={muiStyles.infoLink}
                    />
                  </Link>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container={true} item={true}>
            <Alert
              severity={'error'}
              action={
                <Button
                  onClick={handleReloadInsurance}
                  title={lang.tryAgain}
                  children={lang.tryAgain}
                  variant={'text'}
                  className={muiStyles.tryAgainButton}
                />
              }
            >
              {lang.invalidInsuranceData}
            </Alert>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default Insurance;
