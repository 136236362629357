import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isMobile, isTablet } from '@Model/app/selectors';
import { getLocation } from '@Model/router/selectors';
import { selectionPageMounted } from '@Model/selection/actions';
import { getSelection } from '@Model/selection/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import Selection from './Selection.component';
import { ISelectionFromDispatch, ISelectionFromState } from './Selection.types';

const mapStateToProps = (state: _Store.IState): ISelectionFromState => {
  return {
    isMobile: isMobile(state),
    isSelectionLoading: getLoading(CONST.SELECTION)(state),
    isTablet: isTablet(state),
    isWebview: getLocation(state).query?.isWebview === 'true',
    selection: getSelection(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISelectionFromDispatch => {
  return {
    mounted: () => dispatch(selectionPageMounted()),
  };
};

export default connect<
  ISelectionFromState,
  ISelectionFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Selection);
