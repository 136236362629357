import React from 'react';

import { IRedirectForPayUProps } from './RedirectForPayU.types';

const RedirectForPayU = ({
  data,
  isBrowser,
  isPaymentAvailable,
  isCardPayment,
}: IRedirectForPayUProps): null => {
  if (isPaymentAvailable && isBrowser && !isCardPayment) {
    window.location.replace(data.formUrl);
  }

  return null;
};

export default RedirectForPayU;
