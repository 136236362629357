import React, { JSXElementConstructor, useContext, useEffect } from 'react';

import { Formik } from 'formik';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import { PayUContext } from '@Compo/functional/PayUContextProvider/PayUContextProvider.component';
import getBasketTerms from '@Misc/helpers/getBasketTerms';

import { makeValidationSchema } from './../Online/Online.selectors';
import { IBuyingOnlineFormValues } from './../Online/Online.types';
import FormLayout from './../components/FormLayout';
import { IBasketProps } from './Basket.types';

const Basket = ({
  checkout,
  errors,
  buyAndPay,
  initialValues,
  isDataHydratedTransaction,
  mounted,
  updateBasketState,
  cardPaymentForm,
}: IBasketProps) => {
  const basketContext = useContext(clearContext);
  const { payuInstance, handleTokenizeCard } = useContext(PayUContext);

  const basketCustomTerms = basketContext?.basketItems
    ? getBasketTerms(basketContext?.basketItems)
    : [];

  const validationSchema = makeValidationSchema(
    basketCustomTerms,
    errors,
    checkout,
    !isDataHydratedTransaction
  );

  const catchSaveData = async (data: IBuyingOnlineFormValues) => {
    if (handleTokenizeCard && payuInstance) {
      const tokenize = await handleTokenizeCard(payuInstance);

      if (tokenize === 'ERROR') {
        return;
      }
    }

    buyAndPay({
      basketItems: basketContext?.basketItems ? basketContext.basketItems : [],
      data,
      onDone: basketContext?.clearBasket
        ? basketContext.clearBasket
        : () => null,
    });
  };

  useEffect(() => {
    mounted();
  }, []);

  useEffect(() => {
    if (basketContext?.basketItems) {
      updateBasketState(basketContext.basketItems);
    }
  }, [basketContext?.basketItems]);

  return (
    <Formik
      children={(props) => (
        <FormLayout
          {...props}
          isBasketPay={true}
          cardPaymentForm={cardPaymentForm}
          payuInstance={payuInstance}
        />
      )}
      initialValues={initialValues(basketCustomTerms)}
      onSubmit={catchSaveData}
      validationSchema={validationSchema}
      validateOnMount={false}
    />
  );
};

export default Basket as JSXElementConstructor<unknown>;
