import React, { FC, RefObject, createRef, useEffect, useState } from 'react';

import cn from 'classnames';

import BeautyScroll from '@Compo/reusable/BeautyScroll';
import Dropdown from '@Compo/reusable/Dropdown';
import { closeLatest } from '@Model/modals/actions';

import styles from './CitiesDropdown.module.scss';
import { ICitiesDropdownProps } from './CitiesDropdown.types';

const DROPDOWN_CONTENT_PADDING = 32;

const CitiesDropdown: FC<ICitiesDropdownProps> = ({
  changeHomeLocation,
  isSearchDropdown,
  locations,
  selectedLocation,
}) => {
  const dropdownRef: RefObject<HTMLDivElement> = createRef();

  const [dropdownValue, setDropdownValue] = useState(selectedLocation.name);
  const [dropdownWidth, setDropdownWidth] = useState<number>();

  const selectLocation = (slug: string) => {
    changeHomeLocation(slug);
    setDropdownValue(locations[slug].name);
    closeLatest();
  };

  useEffect(() => {
    if (selectedLocation.slug) {
      selectLocation(selectedLocation.slug);
    }
  });

  useEffect(() => {
    if (dropdownRef.current && isSearchDropdown) {
      const widthDropdown =
        dropdownRef.current.clientWidth + DROPDOWN_CONTENT_PADDING;
      setDropdownWidth(widthDropdown);
    }
  });

  if (!Object.entries(locations).length) {
    return null;
  }

  return (
    <Dropdown
      chevron={true}
      isCityDropdown={true}
      className={isSearchDropdown ? styles.searchSelect : styles.select}
      button={
        <div
          className={
            isSearchDropdown
              ? styles.searchDropdownWrapper
              : styles.dropdownWrapper
          }
          style={{ width: dropdownWidth }}
        >
          <input
            className={cn(
              styles.dropdown,
              isSearchDropdown && styles.searchDropdown
            )}
            type="button"
            value={dropdownValue}
          />
        </div>
      }
      content={
        <div
          ref={dropdownRef}
          className={
            isSearchDropdown
              ? styles.searchOptionsWrapper
              : styles.optionsWrapper
          }
        >
          <div className={styles.options}>
            <BeautyScroll>
              {Object.entries(locations).map(([slug, locationData], id) => {
                if (!dropdownValue && !id) {
                  setDropdownValue(locationData.name);
                }

                return (
                  <input
                    key={slug}
                    onClick={() => {
                      selectLocation(slug);
                    }}
                    type="button"
                    value={locationData.name}
                  />
                );
              })}
            </BeautyScroll>
          </div>
        </div>
      }
    />
  );
};

export default CitiesDropdown;
