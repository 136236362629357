import React from 'react';

import cn from 'classnames';
import pl from 'date-fns/locale/pl';
import DatePicker, { registerLocale } from 'react-datepicker';

import styles from './Calendar.module.scss';
import { ICalendarProps } from './Calendar.types';

registerLocale('pl', pl);

const Calendar = ({
  isDateSelectionAvailable,
  selectedDate,
  setDay,
}: ICalendarProps) => {
  return (
    <>
      <div
        className={cn(
          styles.datePicker,
          !isDateSelectionAvailable && styles.disabled
        )}
      >
        <DatePicker
          fixedHeight={true}
          minDate={new Date()}
          inline={true}
          locale="pl"
          onChange={setDay}
          selected={selectedDate}
          formatWeekDay={(nameOfDay: string) =>
            nameOfDay.toString().substr(0, 1)
          }
          useWeekdaysShort={true}
        />
      </div>
    </>
  );
};

export default Calendar;
