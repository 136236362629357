import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import Insurance from '@Compo/buying/components/Insurance/Insurance.component';
import {
  IInsuranceFromDispatch,
  IInsuranceFromState,
  IInsuranceOwnProps,
} from '@Compo/buying/components/Insurance/Insurance.types';
import { eventAddToCart, eventRemoveFromCart } from '@Model/analytics/actions';
import { translate } from '@Model/locale/selectors';
import { getInsurancePrice, getSelectedTickets } from '@Model/pools/selectors';
import { reloadInsurance } from '@Model/products/actions';
import { getInsuranceProducts } from '@Model/products/selectors';

const mapStateToProps = (state: _Store.IState): IInsuranceFromState => ({
  lang: translate(state)('buy', 'insurance'),
  selectedTickets: getSelectedTickets(state),
  insuranceTotalPrice: getInsurancePrice(state),
  insuranceProducts: getInsuranceProducts(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IInsuranceFromDispatch => ({
  reloadInsurance: () => dispatch(reloadInsurance()),
  eventAddToCart: (params) => dispatch(eventAddToCart(params)),
  eventRemoveFromCart: (params) => dispatch(eventRemoveFromCart(params)),
});

export default connect<
  IInsuranceFromState,
  IInsuranceFromDispatch,
  IInsuranceOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Insurance);
