export const BUY_TICKETS_FOR_EVENT = 'Kup bilety na wydarzenie:';
export const BUY_TICKETS_FOR = 'Kup bilety na:';
export const BUY_TICKET = 'KUP BILET';
export const EVENT = 'Wydarzenie';
export const EVENTS = 'Wydarzenia';
export const PLACE = 'Koncerty 2022';
export const SELECTION = 'Selekcja';
export const LANDING_PAGE = 'Wydarzenia';
export const LOADING = 'Ładowanie...';
export const TICKETS_AND_EVENT_INFO = 'Bilety i informacje o wydarzeniu:';
export const TICKETS_FOR = 'Bilety na';
export const TICKETS_FOR_CONCERTS = 'bilety na koncerty';
