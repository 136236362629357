import { createSelector } from 'reselect';

import _Store from '@Store';

import { IInternalRouterModule, IInternalRouterReducer } from './../types';
import get from './get';

const getModule = createSelector<
  _Store.IState,
  IInternalRouterReducer,
  IInternalRouterModule
>([get], (router) => router.internalRouter.module);

export default getModule;
