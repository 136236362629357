import {
  IWideArticleExtraData,
  IWideArticleOwnProps,
} from '@Compo/Bricks/components/WideArticle/WideArticle.types';
import makeFriendlyDate from '@Misc/helpers/makeFriendlyDate';
import prepareHotSpotUri from '@Misc/helpers/prepareHotSpotsUri';

import {
  ISectionComponentData,
  ISectionsComponentDataMutable,
  ISectionsComponentHotSpot,
} from './../types/sections';

export const big_hotspot = (
  data: ISectionsComponentDataMutable,
  extra?: IWideArticleExtraData,
  bgColor?: string
): IWideArticleOwnProps & ISectionComponentData => {
  const hotSpots = data as ISectionsComponentHotSpot[];
  const hotSpot = hotSpots[0];

  return {
    articleData: {
      date: hotSpot.dateDesc
        ? hotSpot.dateDesc
        : makeFriendlyDate(hotSpot.date),
      description: extra?.disableDescription ? null : hotSpot.description,
      imageUrl: hotSpot.thumbUrl,
      tags: hotSpot.tags ?? [],
      title: extra?.disableTitle ? null : hotSpot.title,
      url: prepareHotSpotUri(hotSpot),
    },
    bgColor,
    color: extra?.color,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
  };
};
