import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IBasketFromDispatch } from '@Compo/Basket/Basket.types';
import { updateBasketStateFromContext } from '@Model/basket/actions';

import Basket from './Basket.component';

const mapDispatchToProps = (dispatch: Dispatch): IBasketFromDispatch => {
  return {
    updateBasketState: (basketState) =>
      dispatch(updateBasketStateFromContext(basketState)),
  };
};

export default connect(() => {}, mapDispatchToProps)(Basket);
