import React from 'react';

import { Grid, Link, Typography } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { includes } from 'lodash';
import { NavLink } from 'react-router-dom';

import config from '@Config';
import menu from '@Services/$menu-api';

const menuSelector = config.theme.isEmpik
  ? menu.menuFooterEmpik
  : menu.menuFooterGoing;

const linkRegExp = new RegExp('^https?://');
const mailExp = 'mailto:';

const FooterMenu = () => {
  return (
    <Grid
      container={true}
      direction={'row'}
      spacing={6}
      alignContent={'stretch'}
    >
      {menuSelector.map((section) => (
        <Grid item={true} sm={'auto'} key={section.title}>
          <Grid container={true} direction={'column'} spacing={1}>
            <Grid item={true}>
              <Typography variant={'h6'}>{section.title}</Typography>
            </Grid>
            {section.links.map((link) => {
              const isMail = link.link ? includes(link.link, mailExp) : false;
              const isExternal = link.link ? linkRegExp.test(link.link) : false;

              return (
                <Grid item={true} key={link.title}>
                  {isExternal || isMail ? (
                    <Link
                      href={link.link}
                      color={'inherit'}
                      underline={'none'}
                      target={isExternal ? '_blank' : '_self'}
                      rel={link.nofollow ? 'nofollow' : 'follow'}
                    >
                      {link.title}
                    </Link>
                  ) : link.link ? (
                    <Link
                      component={NavLink}
                      to={link.link}
                      color={'inherit'}
                      underline={'none'}
                      rel={link.nofollow ? 'nofollow' : 'follow'}
                    >
                      {link.title}
                    </Link>
                  ) : (
                    <Tooltip title={menu.toBeReleasedText}>
                      <Link
                        color={'inherit'}
                        underline={'none'}
                        rel={link.nofollow ? 'nofollow' : 'follow'}
                      >
                        {link.title}
                      </Link>
                    </Tooltip>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
export default FooterMenu;
