import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isBrowser } from '@Model/__base__/selectors';
import { isMobile } from '@Model/app/selectors';
import {
  removeDateFilter,
  setDateFilter,
  setLastFilter,
} from '@Model/search/actions';
import { getSearchDates } from '@Model/search/selectors';

import DateFilter from './DateFilter.component';
import {
  IDateFilterFromDispatch,
  IDateFilterFromState,
  IDateFilterOwnProps,
} from './DateFilter.types';

const mapStateToProps = (state: _Store.IState): IDateFilterFromState => ({
  isBrowser: isBrowser(state),
  isMobile: isMobile(state),
  searchDates: getSearchDates(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IDateFilterFromDispatch => ({
  removeDateFilter: () => dispatch(removeDateFilter()),
  setDates: (dates) => dispatch(setDateFilter(dates)),
  setLastFilter: (filter) => dispatch(setLastFilter(filter)),
});

export default connect<
  IDateFilterFromState,
  IDateFilterFromDispatch,
  IDateFilterOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(DateFilter);
