import React from 'react';

import ProductsForm from '@Compo/buying/Products';
import Helmet from '@Compo/helmet/components/BuyPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';

const ProductsBuy = () => (
  <MuiGrid>
    <Helmet />
    <FullTriangleBackground />
    <MuiSectionContainer>
      <ProductsForm />
    </MuiSectionContainer>
  </MuiGrid>
);

export default ProductsBuy;
