import React from 'react';

import Basket from '@Compo/Basket';
import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';

const BASKET_TEXT = 'Koszyk';

const BasketPage = () => (
  <MuiGrid>
    <Helmet pageName={BASKET_TEXT} />
    <FullTriangleBackground />
    <MuiSectionContainer>
      <Basket />
    </MuiSectionContainer>
  </MuiGrid>
);

export default BasketPage;
