import React, { useContext, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import { Field, FormikValues, useField, useFormikContext } from 'formik';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import BasketItem from '@Compo/Basket/components/BasketItem';
import useStyles from '@Compo/buying/components/Agreements/Agreements.styles';
import Checkbox from '@Compo/forms/Checkbox';
import config from '@Config';
import getBasketFormattedPools from '@Misc/helpers/getBasketFormattedPools';
import { specialEventNames } from '@Model/event/constants/specialEvents';
import { IEventTerm } from '@Model/event/types';

import { IBuyingOnlineFormValues } from '../../Online/Online.types';
import AdditionalField from './../AdditionalField';
import CustomTerm from './../CustomTerm';
import Term from './../Term';
import classes from './Agreements.classes';
import styles from './Agreements.module.scss';
import { IAgreementsProps } from './Agreements.types';

const TERM_FIELD_NAME = 'terms';
const TERM_NEWSLETTER_ID = 185;

const Agreements = ({
  customTerms,
  isBasketPay,
  isDetailedPurchase,
  isDisabled,
  isEmbed,
  lang,
  number,
  selectedTickets,
  slotsSale,
  specialEventCopy,
  setFieldValue,
  values,
  selectedTheme,
}: IAgreementsProps) => {
  const [field, meta, { setValue }] = useField(TERM_FIELD_NAME);
  const { initialValues, errors, touched } =
    useFormikContext<IBuyingOnlineFormValues>();
  const isBlockPartyEvent = specialEventCopy === specialEventNames.blokParty;
  const state = useContext(clearContext);

  const basketItems = state?.basketItems;

  const getFilteredCustomTerms = (terms: IEventTerm[]): IEventTerm[] =>
    terms.filter((term) =>
      values.invoiceCheckbox
        ? !term.scope || term.scope === 'forFVat'
        : !term.scope || term.scope === 'exceptFVat'
    );

  const filteredCustomTerms = getFilteredCustomTerms(customTerms);

  const formattedItems = getBasketFormattedPools(basketItems);
  useEffect(() => {
    if (isBlockPartyEvent) {
      setTimeout(() => setValue(true), 500);
    }
  }, [customTerms]);

  useEffect(() => {
    setFieldValue('customTerms', initialValues.customTerms);
  }, [values.invoiceCheckbox]);

  let step = 0;

  const theme = useTheme();
  const muiStyles = useStyles(theme);
  const isDefaultTheme = selectedTheme === 'default';

  return (
    <div
      className={cn(
        classes.agreements,
        styles.agreements,
        isDisabled && styles.disabled,
        slotsSale && styles.withoutTopMargin
      )}
    >
      {!slotsSale && (
        <Typography
          variant={'h3'}
          className={cn(muiStyles.header, classes.header)}
          data-number={number}
        >
          {isDetailedPurchase
            ? lang.acceptAgreementsDetailed
            : lang.acceptAgreements}
        </Typography>
      )}

      <fieldset className={cn(styles.formPanel, classes.formPanel)}>
        {!isEmbed &&
          formattedItems?.map((item, i) => {
            return !item.additionalFields &&
              !item.pools.some((pool) => pool.additionalFields) ? null : (
              <div
                key={`basketItem_${i}`}
                className={cn(
                  styles.agreement,
                  classes.agreement,
                  muiStyles.root,
                  !isDefaultTheme && muiStyles.withoutPadding
                )}
              >
                <BasketItem basketItem={item} withoutPrice={true} />
                {[...Array(item.amount)].map((v, index) => {
                  if (item.additionalFields) {
                    return (
                      <AdditionalField
                        key={`additional_${step}`}
                        ticket={item}
                        index={index}
                        step={step++}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    );
                  }
                })}
                {item.pools.map((pool) => {
                  return [...Array(pool.amount)].map((v, index) => {
                    if (pool.additionalFields) {
                      return (
                        <AdditionalField
                          key={`additional_${step}`}
                          ticket={pool}
                          index={index}
                          step={step++}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      );
                    }
                  });
                })}

                <div className={styles.agreementsContainer}>
                  <h3 className={styles.title}>
                    {lang.agreementsAndRegulations}
                  </h3>
                  <p className={styles.description}>{lang.selectConsents}</p>
                </div>
                {getFilteredCustomTerms(item.customTerms).map((term) => (
                  <div
                    className={cn(
                      styles.checkbox,
                      classes.checkbox,
                      !term.selectable && styles.withoutCheckbox,
                      !term.selectable && classes.withoutCheckbox
                    )}
                    data-test-id={'agreements-checkbox'}
                    key={term.id}
                  >
                    {term.selectable ? (
                      <Field
                        component={Checkbox}
                        data-test-id={'agreements-checkbox'}
                        name={`customTerms.${term.id}`}
                        id={String(term.id)}
                      >
                        <CustomTerm term={term} />
                      </Field>
                    ) : (
                      <CustomTerm term={term} />
                    )}
                  </div>
                ))}
              </div>
            );
          })}

        {!isEmbed &&
          formattedItems?.map((item) => {
            if (
              !item.isProduct &&
              !item.additionalFields &&
              !item.pools.some((pool) => pool.additionalFields)
            ) {
              return (
                <div
                  className={cn(
                    styles.agreement,
                    classes.agreement,
                    muiStyles.root,
                    !isDefaultTheme && muiStyles.withoutPadding
                  )}
                  key={`agreement-${item.id}`}
                >
                  <BasketItem basketItem={item} withoutPrice={true} />
                  {getFilteredCustomTerms(item.customTerms).map((term) => (
                    <div
                      className={cn(
                        styles.checkbox,
                        classes.checkbox,
                        !term.selectable && styles.withoutCheckbox,
                        !term.selectable && classes.withoutCheckbox
                      )}
                      data-test-id={'agreements-checkbox'}
                      key={term.id}
                    >
                      {term.selectable ? (
                        <Field
                          component={Checkbox}
                          name={`customTerms.${term.id}`}
                          id={String(term.id)}
                        >
                          <CustomTerm term={term} />
                        </Field>
                      ) : (
                        <CustomTerm term={term} />
                      )}
                    </div>
                  ))}
                </div>
              );
            }
          })}

        {!isEmbed &&
          formattedItems?.map((item) =>
            item.pools.map((pool) => {
              if (!pool.additionalFields) {
                return (
                  <div
                    className={cn(
                      styles.agreement,
                      classes.agreement,
                      muiStyles.root,
                      !isDefaultTheme && muiStyles.withoutPadding
                    )}
                    key={`agreement-${pool.id}`}
                  >
                    <BasketItem basketItem={item} withoutPrice={true} />
                    {getFilteredCustomTerms(item.customTerms).map((term) => (
                      <div
                        className={cn(
                          styles.checkbox,
                          classes.checkbox,
                          !term.selectable && styles.withoutCheckbox,
                          !term.selectable && classes.withoutCheckbox
                        )}
                        data-test-id={'agreements-checkbox'}
                        key={term.id}
                      >
                        {term.selectable ? (
                          <Field
                            component={Checkbox}
                            name={`customTerms.${term.id}`}
                            id={String(term.id)}
                          >
                            <CustomTerm term={term} />
                          </Field>
                        ) : (
                          <CustomTerm term={term} />
                        )}
                      </div>
                    ))}
                  </div>
                );
              }
            })
          )}

        <div
          className={cn(
            styles.agreement,
            classes.agreement,
            muiStyles.root,
            !isDefaultTheme && muiStyles.withoutPadding
          )}
        >
          {selectedTickets.map((ticket) => {
            return [...Array(ticket.amount)].map((value, index) => {
              if (ticket.additionalFields) {
                return (
                  <AdditionalField
                    key={`additional_${index}`}
                    ticket={{ id: ticket.poolId, poolName: ticket.poolName }}
                    index={index}
                    step={step++}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                );
              }
            });
          })}
          {config.theme.isGoing && isDefaultTheme && (
            <div className={cn(styles.checkbox, classes.checkbox)}>
              <Field component={Checkbox} name="newsletter" id="newsletter">
                <div>
                  <div>{lang.iWantNewsletter}</div>
                  <p
                    className={cn(
                      styles.newsletterText,
                      classes.newsletterText,
                      muiStyles.secondary
                    )}
                  >
                    {lang.newsletterAgreement}
                  </p>
                </div>
              </Field>
            </div>
          )}

          {!isBlockPartyEvent && (
            <div
              className={cn(styles.checkbox, classes.checkbox)}
              data-test-id={'agreements-checkbox'}
            >
              <Field
                component={Checkbox}
                data-test-id={'agreements-checkbox'}
                name={TERM_FIELD_NAME}
                id={TERM_FIELD_NAME}
                isRequiredTerm={true}
                errorMessage={touched.terms && errors.terms}
                error={!!(touched.terms && errors.terms)}
              >
                <Term />
              </Field>
            </div>
          )}

          {!isBasketPay &&
            filteredCustomTerms.map((term) => {
              if (!isDefaultTheme && term.id === TERM_NEWSLETTER_ID) {
                return null;
              }

              return (
                <div
                  className={cn(
                    styles.checkbox,
                    classes.checkbox,
                    !term.selectable && styles.withoutCheckbox,
                    !term.selectable && classes.withoutCheckbox
                  )}
                  data-test-id={'agreements-checkbox'}
                  key={term.id}
                >
                  {term.selectable ? (
                    <Field
                      component={Checkbox}
                      name={`customTerms.${term.id}`}
                      id={String(term.id)}
                      isRequiredTerm={term.required}
                      errorMessage={
                        touched.customTerms?.[term.id] &&
                        errors.customTerms?.[term.id]
                      }
                      error={
                        !!(
                          touched.customTerms?.[term.id] &&
                          errors.customTerms?.[term.id]
                        )
                      }
                    >
                      <CustomTerm term={term} />
                    </Field>
                  ) : (
                    <CustomTerm term={term} />
                  )}
                </div>
              );
            })}
        </div>

        <p className={cn(styles.requiredText, classes.requiredText)}>
          {lang.requiredFields}
        </p>
      </fieldset>
    </div>
  );
};

export default Agreements;
