import axios from 'axios';

import config from '@Config';
import TransactionError from '@Misc/classes/TransactionError';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import {
  ITransactionBody,
  ITransactionECardPayment,
  ITransactionWithRedirectPayment,
} from '@Model/transaction/types';

import getError from './helpers/getError';
import * as TEXTS from './helpers/texts';
import {
  IPaymentMethodsResponse,
  ITransactionECardPaymentData,
  ITransactionPayUPaymentData,
  ITransactionResponse,
} from './types';

class TransactionsApi {
  private static transactionUrl: string = `${config.api.baseUrl}transaction`;
  private static paymentMethodsUrl: string = `${config.api.baseUrl}transaction-payment-methods`;

  public sendTransaction(
    body: ITransactionBody
  ): Promise<ITransactionResponse> {
    return new Promise((resolve, reject) => {
      const requestBodyStringified = JSON.stringify({ ...body });

      axios
        .post(
          TransactionsApi.transactionUrl,
          requestBodyStringified,
          withCacheHeader()
        )
        .then(getData)
        .then((response: ITransactionResponse) => {
          if (response.status === 0) {
            resolve(response);
          } else {
            reject(getError(response.errors));
          }
        })
        .catch((error) => {
          window.airbrake.notify(error);
          let message = TEXTS.DEFAULT_ERROR_TEXT;
          let errorId = TEXTS.DEFAULT_ERROR_ID;

          switch (error.message) {
            case 'Network Error':
              message = TEXTS.CHECK_INTERNET_TEXT;
              errorId = TEXTS.CHECK_INTERNET_ERROR_ID;
              break;

            default:
              break;
          }

          reject(new TransactionError(message, errorId));
        });
    });
  }

  public normalizeECardPayment(
    payment: ITransactionECardPaymentData
  ): ITransactionECardPayment {
    const {
      AMOUNT,
      AUTODEPOSIT,
      CHARSET,
      COUNTRY,
      CURRENCY,
      HASH,
      HASHALGORITHM,
      LANGUAGE,
      LINKFAIL,
      LINKOK,
      MERCHANTID,
      NAME,
      ORDERDESCRIPTION,
      ORDERNUMBER,
      PAYMENTTYPE,
      SURNAME,
      TRANSPARENTPAGES,
    } = payment;

    return {
      amount: AMOUNT,
      autoDeposit: AUTODEPOSIT,
      charset: CHARSET,
      country: COUNTRY,
      currency: CURRENCY,
      hash: HASH,
      hashAlgorithm: HASHALGORITHM,
      language: LANGUAGE,
      linkFail: LINKFAIL,
      linkOk: LINKOK,
      merchantId: MERCHANTID,
      name: NAME,
      orderDescription: ORDERDESCRIPTION,
      orderNumber: ORDERNUMBER,
      paymentType: PAYMENTTYPE,
      surname: SURNAME,
      transparentPages: TRANSPARENTPAGES,
    };
  }

  public normalizePayUPayment(
    payment: ITransactionPayUPaymentData
  ): ITransactionWithRedirectPayment {
    const { formUrl, ORDERNUMBER } = payment;

    return {
      formUrl,
      orderNumber: ORDERNUMBER,
    };
  }

  public getPaymentMethods(): Promise<IPaymentMethodsResponse> {
    return new Promise<IPaymentMethodsResponse>((resolve, reject) => {
      axios
        .post(TransactionsApi.paymentMethodsUrl)
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }
}

export default new TransactionsApi();
