import { createSelector } from 'reselect';

import _Store from '@Store';

import { IErrorsReducer } from './../types';
import get from './get';

const getPath = createSelector<_Store.IState, IErrorsReducer, string[] | null>(
  [get],
  (error) => error.path
);

export default getPath;
