import { createSelector } from 'reselect';

import _Store from '@Store';

import config from '@Config';

import { ICompositionData, IPagesReducer } from './../types';
import get from './get';

const getHomeSections = createSelector<
  _Store.IState,
  IPagesReducer,
  ICompositionData | null
>([get], (pages) =>
  pages.compositions &&
  pages.compositions[config.app.mainpageCategory]?.sections?.length
    ? pages.compositions[config.app.mainpageCategory] || null
    : null
);

export default getHomeSections;
