import React from 'react';

import { Formik } from 'formik';

import { makeValidationSchema } from '@Compo/Happening/components/HappeningBuyForm/HappeningBuyForm.selector';
import { IHappeningBuyFormProps } from '@Compo/Happening/components/HappeningBuyForm/HappeningBuyForm.types';
import HappeningFormLayout from '@Compo/Happening/components/HappeningFormLayout';

const HappeningBuyForm = ({
  customTerms,
  initialValues,
  reserve,
  langErrors,
  step,
}: IHappeningBuyFormProps) => {
  const validationSchema = makeValidationSchema(customTerms, langErrors);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={reserve}
      component={(props) => <HappeningFormLayout {...props} step={step} />}
    />
  );
};

export default HappeningBuyForm;
