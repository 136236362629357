import { IBasket } from '@Model/basket/types';
import { IEventTerm } from '@Model/event/types';

const getBasketTerms = (items: IBasket[]) => {
  const basketCustomTerms: IEventTerm[] = [];

  items.forEach((item) =>
    item.customTerms.forEach((term) => basketCustomTerms.push(term))
  );

  return basketCustomTerms;
};

export default getBasketTerms;
