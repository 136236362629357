import { Theme, makeStyles } from '@material-ui/core';

const useStyles = (color?: string) =>
  makeStyles((theme: Theme) => {
    return {
      firstParagraph: {
        '& > p': {
          '&:first-of-type': {
            '&:not(:last-of-type)': {
              fontSize: '1.5rem',
              fontWeight: theme.typography.fontWeightBold,
            },
          },
        },
      },
      firstParagraphMobile: {
        '& > p': {
          '&:first-of-type': {
            '&:not(:last-of-type)': {
              fontSize: '1.25rem',
            },
          },
        },
      },
      fontColor: {
        color: color && color,
      },
      image: {
        width: '100%',
      },
      item: {
        '& > *': {
          display: 'inline',
        },
        listStylePosition: 'inside',
      },
      list: {
        paddingInlineStart: 0,
      },
    };
  });

export default useStyles;
