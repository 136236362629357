import axios, { CancelTokenSource } from 'axios';

import { ICard } from '@Compo/reusable/Card/Card.types';
import config from '@Config';
import AWSQueryBuilder from '@Misc/classes/AWSQueryBuilder';
import EventsListService from '@Misc/classes/EventsListService';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import convertToBool from '@Misc/helpers/convertToBool';
import formatDate from '@Misc/helpers/date/formatDate';
import splitNumberToDayMonthYear from '@Misc/helpers/date/splitNumberToDayMonthYear';
import today from '@Misc/helpers/date/today';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';
import translatableDate from '@Misc/helpers/translatableDate';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import { IEvent } from '@Model/events/types';
import { ILang } from '@Model/locale/types';
import { IPlaceDetails, IPlaceTag } from '@Model/place/types';
import routes from '@Routes/routes';
import { DEFAULT_DATE_TRANSLATE } from '@Services/$events-api/EventsApi';
import {
  IEventsAwsResponseData,
  IEventsHitResponse,
  IRundateResponseMutable,
} from '@Services/$events-api/types';

import {
  IPlace,
  IPlaceEventsAwsResponse,
  IPlaceResponse,
  IPlaceRouterParams,
  IPlacesResponse,
} from './types';

const PLACE_EVENTS_LIST_SIZE = 4;
const SORTING_RECOMMEND = [
  { priority: 1, field: '_score', sort: 'desc' },
  { priority: 2, field: 'rundate_not_for_sale', sort: 'asc' },
  { priority: 3, field: 'partner_premium_level', sort: 'desc' },
  { priority: 4, field: 'place_premium_level', sort: 'desc' },
  { priority: 5, field: 'rundate_rundate', sort: 'asc' },
];

const SEARCH_ALL_PHRASE = AWSQueryBuilder.phrase([]);

class PlacesApi extends EventsListService {
  protected static getPlaceEventsQueryString(placeSlug: string): string {
    let queryString = '';
    const todayDate = formatDate(splitNumberToDayMonthYear(today()), true);
    const filterOldEventsQuery = AWSQueryBuilder.datesQuery(todayDate, '');

    const termsArray = [] as string[];

    const placeEventsTerms = [
      {
        name: 'place_slug',
        value: [`${placeSlug}`],
      },
      {
        name: 'has_pools',
        value: config.theme.isGoing ? ['0', '1'] : ['1'],
      },
      {
        name: 'sales_channels_ids',
        value: [config.app.salesChannelId.toString()],
      },
    ];

    termsArray.push(SEARCH_ALL_PHRASE);
    termsArray.push(AWSQueryBuilder.term(placeEventsTerms));

    if (termsArray.length > 0) {
      queryString = termsArray.join('');
    }

    return AWSQueryBuilder.query('search', queryString + filterOldEventsQuery, [
      AWSQueryBuilder.option('format', 'json'),
      AWSQueryBuilder.option('q.parser', 'lucene'),
      AWSQueryBuilder.option('size', PLACE_EVENTS_LIST_SIZE),
      AWSQueryBuilder.option('sort', AWSQueryBuilder.sort(SORTING_RECOMMEND)),
      AWSQueryBuilder.option(
        'q.options',
        `%7Bfields: ['title_pl^5','title_en^5','description_pl','description_en','artists_names','place_name','partner_name']%7D`
      ),
    ]);
  }

  private static getPlaceUrl(slug: string): string {
    return `${config.api.baseUrl}place/${slug}`;
  }

  private static getEventsUrl(query: string): string {
    return `${config.api.awsUrl}${query}`;
  }

  private static getPlaceByIdUrl(id: number): string {
    return `${config.api.baseUrl}place/${id}`;
  }

  private cancelTokenPlace?: CancelTokenSource;

  public makeKeyFromParams(params: IPlaceRouterParams) {
    return `${params.slug}`;
  }

  public normalizePlacesData(placesResponse: IPlaceResponse): IPlace {
    const { address, description, id, name, slug, thumb } = placesResponse;

    return {
      address,
      description,
      id,
      name,
      slug,
      tags: [],
      thumb,
    };
  }

  public normalizePlaceData(placeResponse: IPlaceResponse): IPlaceDetails {
    const {
      additionalInfo,
      address,
      category,
      city: { name: cityName },
      description,
      id,
      lat,
      lon,
      name,
      slug,
      shortName,
      thumb,
    } = placeResponse;

    const tags: IPlaceTag[] = [];
    if (category) {
      tags.push({
        ...category,
        isMain: true,
      });
    }

    return {
      address,
      city: cityName,
      description,
      id,
      image: thumb,
      informations: additionalInfo,
      latitude: lat,
      longitude: lon,
      name,
      shortName,
      slug,
      tags,
    };
  }

  public getPlace(slug: string): Promise<IPlaceResponse> {
    return new Promise((resolve, reject) => {
      const placeUrl = PlacesApi.getPlaceUrl(slug);

      this.cancelTokenPlace = axios.CancelToken.source();

      axios
        .get(
          placeUrl,
          withCacheHeader({ cancelToken: this.cancelTokenPlace.token })
        )
        .then(getData)
        .then((response: IPlaceResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }

  public getPlaceById(id: number): Promise<IPlaceResponse> {
    return new Promise((resolve, reject) => {
      const placeUrl = PlacesApi.getPlaceByIdUrl(id);

      this.cancelTokenPlace = axios.CancelToken.source();

      axios
        .get(
          placeUrl,
          withCacheHeader({ cancelToken: this.cancelTokenPlace.token })
        )
        .then(getData)
        .then((response: IPlaceResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }

  public cancelPlace() {
    if (this.cancelTokenPlace) {
      this.cancelTokenPlace.cancel();
      this.cancelTokenPlace = undefined;
    }
  }

  public getManyEvents(placeSlug: string): Promise<IPlaceEventsAwsResponse> {
    return new Promise((resolve, reject) => {
      const queryString = PlacesApi.getPlaceEventsQueryString(placeSlug);
      this.cancelTokenEvents = axios.CancelToken.source();

      axios
        .get(
          PlacesApi.getEventsUrl(queryString),
          withCacheHeader({ cancelToken: this.cancelTokenEvents?.token })
        )
        .then(getData)
        .then((response: IEventsAwsResponseData) => {
          return resolve({
            items: response.hits.hit,
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getPlaceActivities(placeSlug: string): Promise<ICard[]> {
    return new Promise<ICard[]>((resolve, reject) => {
      const terms = [
        {
          name: 'place_slug',
          value: [`${placeSlug}`],
        },
        {
          name: 'calendar_event',
          value: ['1'],
        },
        {
          name: 'has_pools',
          value: config.theme.isGoing ? ['0', '1'] : ['1'],
        },
        {
          name: 'sales_channels_ids',
          value: [config.app.salesChannelId.toString()],
        },
      ];

      const queryString = AWSQueryBuilder.query(
        'search',
        AWSQueryBuilder.term(terms),
        [
          AWSQueryBuilder.return(['_all_fields']),
          AWSQueryBuilder.option('format', 'json'),
          AWSQueryBuilder.option('q.parser', 'lucene'),
          AWSQueryBuilder.option('size', 4),
        ],
        SEARCH_ALL_PHRASE
      );

      axios
        .get(PlacesApi.getEventsUrl(queryString))
        .then(getData)
        .then((response: IEventsAwsResponseData) => {
          const cards: ICard[] = response.hits.hit.map((event): ICard => {
            return {
              badge: null,
              date: null,
              description: event.fields.description_pl,
              link: fillUrlWithValues(
                routes.activity,
                [':eventSlug'],
                [event.fields.event_slug]
              ),
              place: event.fields.address,
              placeSlug: null,
              subtitle: null,
              thumb: event.fields.square_image,
              title: event.fields.title_pl,
            };
          });

          resolve(cards);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeShortEvent(
    eventResponse: IRundateResponseMutable,
    dateTranslate: ILang = DEFAULT_DATE_TRANSLATE
  ): IEvent {
    const {
      id,
      fields: {
        buttonLabel,
        change_monitor_name,
        city_name,
        city_slug,
        currency,
        description_pl,
        empik_premium,
        end_date,
        event_category_id,
        event_category_name,
        event_slug,
        geolocation,
        horizontal_image,
        partner_id,
        partner_name,
        place_slug,
        place_id,
        place_name,
        place_thumb,
        price,
        price_description_pl,
        public_tags_ids,
        public_tags_names,
        rundate_not_for_sale,
        rundate_rundate,
        rundate_rundate_description,
        rundate_slug,
        redirect_to_url,
        square_image,
        title_pl,
        rundate_enddate,
      },
    } = eventResponse as IEventsHitResponse;

    const location = geolocation ? geolocation.split(',') : [0, 0];
    const place = {
      address: city_name, // TODO: change this to address from api instead city_name
      city: { name: city_name, slug: city_slug },
      id: place_id,
      lat: Number(location[0]),
      lon: Number(location[1]),
      name: place_name,
      slug: place_slug,
    } as IPlaceResponse;

    const eventTags = public_tags_ids
      ? public_tags_ids.map((eventId, index) => {
          return {
            id: Number(eventId),
            name: public_tags_names[index],
            slug: public_tags_names[index],
          };
        })
      : [];

    return {
      buttonLabel,
      changeMonitorName: change_monitor_name,
      currency,
      dateDescription: rundate_rundate_description,
      description: description_pl,
      endDate: end_date,
      eventSlug: event_slug,
      friendlyDate: translatableDate(
        rundate_rundate,
        rundate_rundate_description,
        dateTranslate,
        rundate_enddate
      ),
      friendlyHour: PlacesApi.getFriendlyHour(
        rundate_rundate,
        rundate_rundate_description
      ),
      id: parseInt(id, 10),
      imageUrl: PlacesApi.getImageUrl([
        {
          large: square_image || horizontal_image,
          medium: square_image || horizontal_image,
        },
      ]),
      isPremiumEvent: empik_premium,
      notForSale: convertToBool(rundate_not_for_sale),
      partner: `${partner_name}|${partner_id}`,
      place: PlacesApi.getFriendlyPlace(place),
      placeImageUrl: place_thumb || '',
      placeSlug: place_slug || '',
      price: getPriceFormatted(price),
      priceDescription: PlacesApi.getPriceDescription(price_description_pl),
      redirectToUrl: redirect_to_url || null,
      rundateSlug: rundate_slug,
      startDate: rundate_rundate,
      tags: PlacesApi.prepareTagsFromRundate(
        { id: event_category_id, name: event_category_name, slug: event_slug },
        eventTags
      ),
      title: title_pl,
    };
  }
}

export default new PlacesApi();
