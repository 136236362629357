const isHTML = (str: string) => {
  try {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  } catch (e) {
    return false;
  }
};

export default isHTML;
