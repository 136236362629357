import { createSelector } from 'reselect';

import _Store from '@Store';

import { IProductSelect, IProductsSummary } from './../types';
import getSelectedProducts from './getSelectedProducts';

const getProductsSummary = createSelector<
  _Store.IState,
  IProductSelect[],
  IProductsSummary
>([getSelectedProducts], (selectedProducts: IProductSelect[]) => {
  return selectedProducts.reduce(
    (previous, current) => {
      return {
        amount: previous.amount + current.amount,
        finalPrice: previous.finalPrice + current.price * current.amount,
      };
    },
    { amount: 0, finalPrice: 0 }
  );
});

export default getProductsSummary;
