import React from 'react';

import { Step, Stepper } from '@material-ui/core';

import useStyles from './EntryList.styles';
import { IEntryListProps } from './EntryList.types';
import { steps } from './steps';

const EntryListStepper = ({ activeStep }: IEntryListProps) => {
  const classes = useStyles();

  return (
    <Stepper className={classes.root}>
      {steps.map((step) => {
        return (
          activeStep === step.name && (
            <Step key={step.name}>{step.component()}</Step>
          )
        );
      })}
    </Stepper>
  );
};
export default EntryListStepper;
