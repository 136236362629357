import { getType } from 'typesafe-actions';

import { clearLocation, getLocationsRequest, setLocation } from './../actions';
import { IAction as IActionSections, ILocationsReducer } from './../types';

export const initialState: ILocationsReducer = {
  locations: {},
  selectedLocation: {
    cities: [],
    name: '',
    slug: '',
  },
};

const reducer = (
  state: ILocationsReducer = initialState,
  action: IActionSections
): ILocationsReducer => {
  switch (action.type) {
    case getType(getLocationsRequest.success):
      return {
        ...state,
        locations: action.payload,
      };

    case getType(setLocation):
      return {
        ...state,
        selectedLocation: action.payload,
      };

    case getType(clearLocation):
      return {
        ...state,
        selectedLocation: initialState.selectedLocation,
      };

    default:
      return state;
  }
};

export default reducer;
