import { createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';

export const createNotification = createStandardAction(
  CONSTS.CREATE_NOTIFICATION
)<string>();

export const closeNotification = createStandardAction(
  CONSTS.CLOSE_NOTIFICATION
)<number>();
