import { RouterState } from 'connected-react-router';
import { createSelector } from 'reselect';

import _Store from '@Store';

import { ILocation } from './../types';
import get from './get';

const getLocation = createSelector<_Store.IState, RouterState, ILocation>(
  [get],
  (router) => router.location
);

export default getLocation;
