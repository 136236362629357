import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';
import * as CONSTS from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { getRedirectUrl } from '@Model/transaction/selectors';

import Fail from './Fail.component';
import { IFailFromState } from './Fail.types';

const mapStateToProps = (state: _Store.IState): IFailFromState => ({
  isLoading: getLoading(CONSTS.TRANSACTION)(state),
  lang: translate(state)('payment', 'paymentFail'),
  redirectUrl: getRedirectUrl(state),
});

export default connect<IFailFromState, void, {}, _Store.IState>(
  mapStateToProps
)(Fail);
