import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isDesktop, isMobile } from '@Model/app/selectors';
import { placeEventsMounted } from '@Model/placeEvents/actions';
import {
  getPlaceEventsData,
  isMorePlaceEventsAvailable,
} from '@Model/placeEvents/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import PlaceEvents from './PlaceEvents.component';
import {
  IPlaceEventsFromDispatch,
  IPlaceEventsFromState,
} from './PlaceEvents.types';

const mapStateToProps = (state: _Store.IState): IPlaceEventsFromState => {
  return {
    events: getPlaceEventsData(state),
    isDesktop: isDesktop(state),
    isLoading: getLoading(CONST.PLACE_EVENTS)(state),
    isMobile: isMobile(state),
    isMoreEventsAvailable: isMorePlaceEventsAvailable(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPlaceEventsFromDispatch => {
  return {
    placeEventsMounted: () => dispatch(placeEventsMounted()),
  };
};

export default withRouter(
  connect<IPlaceEventsFromState, IPlaceEventsFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(PlaceEvents)
);
