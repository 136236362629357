import React from 'react';

import { Redirect, Route, Switch } from 'react-router';

import EventEmbed from '@Compo/Event';
import Formio from '@Compo/Formio';
// Embed (pins) component
import BuyForm from '@Compo/buying/Main';
// Import components
import Error404 from '@Compo/errors/Error404';
import ErrorBasic from '@Compo/errors/ErrorBasic';
import {
  Embed,
  HiddenRoute,
  Switch as InternalSwitch,
  Page,
} from '@Compo/functional/InternalRouter';
// Page Components
import Activity from '@Compo/pages/Actitvity';
import Artist from '@Compo/pages/ArtistPage';
import BasketPage from '@Compo/pages/Basket';
import BasketBuy from '@Compo/pages/BasketBuy';
import Buy from '@Compo/pages/Buy';
import ChangeDatePage from '@Compo/pages/ChangeDate';
import Discount from '@Compo/pages/Discount';
import Event from '@Compo/pages/EventPage';
import FormioPage from '@Compo/pages/Formio';
import GiftCardPage from '@Compo/pages/GiftCard';
import HappeningPage from '@Compo/pages/HappeningPage';
import Home from '@Compo/pages/HomePage';
import MyTicketPage from '@Compo/pages/MyTicket';
import MyTicketsPage from '@Compo/pages/MyTickets';
import Payment from '@Compo/pages/Payment';
import PaymentFail from '@Compo/pages/PaymentFail';
import PaymentSuccess from '@Compo/pages/PaymentSuccess';
import Place from '@Compo/pages/PlacePage';
import ProductsBuy from '@Compo/pages/ProductsBuy';
import ReceiveTicket from '@Compo/pages/ReceiveTicket';
import Recommend from '@Compo/pages/Recommend';
import Search from '@Compo/pages/Search';
import Selection from '@Compo/pages/Selection';
import SendMailSuccessPage from '@Compo/pages/SendMailSuccess';
import StaticArticle from '@Compo/pages/StaticArticle';
import TransferSuccessPage from '@Compo/pages/TransferSuccess';
import TransferTicketPage from '@Compo/pages/TransferTicket';
import ChangeDate from '@Compo/ticketsManagement/ChangeDate';
import MyTicket from '@Compo/ticketsManagement/MyTicket';
import MyTickets from '@Compo/ticketsManagement/MyTickets';
import TicketRecipience from '@Compo/ticketsManagement/TicketRecipience';
import TransferSuccess from '@Compo/ticketsManagement/TransferSummary';
import TransferSummary from '@Compo/ticketsManagement/TransferSummary/TransferSummary.container';
import TransferTicket from '@Compo/ticketsManagement/TransferTicket';
import config from '@Config';

// Import module's constants
import * as MODULES from './../modules';
import routes from './../routes';

const empikOnsite = !config.app.onlineSale;

const Routes = () => (
  <>
    <Switch>
      {!empikOnsite && (
        <Route
          exact={true}
          path={routes.mainPage}
          component={Page(Home, MODULES.HOME)}
        />
      )}

      {!empikOnsite && (
        <Route
          exact={true}
          path={routes.index}
          component={Page(Home, MODULES.HOME)}
        />
      )}

      {empikOnsite && (
        <Route exact={true} path={routes.index}>
          <Redirect to={routes.events} />
        </Route>
      )}

      {empikOnsite && config.features.onsiteGiftCard && (
        <Route
          exact={true}
          path={routes.giftCardActivation}
          component={Page(GiftCardPage, MODULES.GIFT_CARD)}
        />
      )}

      <Route
        exact={true}
        path={routes.recommend}
        component={Page(Recommend, MODULES.RECOMMEND)}
      />

      <Route
        exact={true}
        path={routes.events}
        component={Page(Search, MODULES.SEARCH)}
      />

      {config.app.showUnfinishedFeatures && (
        <Route
          exact={true}
          path={routes.staticArticle}
          component={Page(StaticArticle, MODULES.STATIC_ARTICLE)}
        />
      )}

      {config.features.basket && (
        <Route
          exact={true}
          path={routes.basket}
          component={Page(BasketPage, MODULES.BASKET)}
        />
      )}

      {config.features.basket && (
        <Route
          exact={true}
          path={routes.basketBuy}
          component={Page(BasketBuy, MODULES.BASKET_BUY)}
        />
      )}

      <Route
        exact={true}
        path={routes.selection}
        component={Page(Selection, MODULES.SELECTION)}
      />

      <Route
        exact={true}
        path={routes.place}
        component={Page(Place, MODULES.PLACE)}
      />

      {config.app.showUnfinishedFeatures && (
        <Route
          exact={true}
          path={routes.artist}
          component={Page(Artist, MODULES.ARTIST)}
        />
      )}

      {config.features.happeningSales && (
        <Route
          exact={true}
          path={routes.happening}
          component={Page(HappeningPage, MODULES.HAPPENING)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.myTicket}
          component={Page(MyTicketsPage, MODULES.MY_TICKETS)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.myTicketEdit}
          component={Page(MyTicketPage, MODULES.MY_TICKET)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.transferTicket}
          component={Page(TransferTicketPage, MODULES.TRANSFER_TICKET)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.transferSuccess}
          component={Page(TransferSuccessPage, MODULES.TRANSFER_SUCCESS)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.receiveTicketSuccess}
          component={Page(PaymentSuccess, MODULES.RECEIVE_SUCCESS)}
        />
      )}

      <Route
        exact={true}
        path={routes.receiveTicketFail}
        component={Page(PaymentFail, MODULES.RECEIVE_FAIL)}
      />

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.changeDate}
          component={Page(ChangeDatePage, MODULES.CHANGE_DATE)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.receiveTicket}
          component={Page(ReceiveTicket, MODULES.RECEIVE_TICKET)}
        />
      )}

      <Route
        exact={true}
        path={routes.event}
        component={Page(Event, MODULES.EVENT)}
      />

      <Route
        exact={true}
        path={routes.activity}
        component={Page(Activity, MODULES.ACTIVITY)}
      />

      <Route
        exact={true}
        path={routes.buy}
        component={Page(Buy, MODULES.BUY)}
      />

      <Route
        exact={true}
        path={routes.buyProducts}
        component={Page(ProductsBuy, MODULES.PRODUCTS_BUY)}
      />

      <Route
        exact={true}
        path={routes.discount}
        component={Page(Discount, MODULES.DISCOUNT)}
      />

      <Route
        exact={true}
        path={routes.payment}
        render={Page(Payment, MODULES.PAYMENT)}
      />

      <Route
        exact={true}
        path={routes.paymentFail}
        component={Page(PaymentFail, MODULES.SUMMARY_FAIL)}
      />

      <Route
        exact={true}
        path={routes.paymentSuccess}
        component={Page(PaymentSuccess, MODULES.SUMMARY_SUCCESS)}
      />

      <Route
        exact={true}
        path={routes.contact}
        component={Page(FormioPage, MODULES.FORMIO)}
      />

      <Route
        exact={true}
        path={routes.form}
        component={Page(FormioPage, MODULES.FORMIO)}
      />

      <Route
        exact={true}
        path={routes.sendMailSuccess}
        component={Page(SendMailSuccessPage, MODULES.SEND_MAIL_SUCCESS)}
      />

      {/* EMBEDS */}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinMyTicket}
          component={Embed(MyTickets)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinMyTicketEdit}
          component={Embed(MyTicket)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinTransferTicket}
          component={Embed(TransferTicket)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinTransferSuccess}
          component={Embed(TransferSuccess)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinReceiveSummary}
          component={Embed(TransferSummary)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinReceiveSummary}
          component={Embed(TransferSuccess)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinChangeDate}
          component={Embed(ChangeDate)}
        />
      )}

      {config.features.ticketManagement && (
        <Route
          exact={true}
          path={routes.pinReceiveTicket}
          component={Embed(TicketRecipience)}
        />
      )}

      <Route exact={true} path={routes.pinForm} component={Embed(Formio)} />

      <Route exact={true} path={routes.pinBuy} component={Embed(BuyForm)} />

      <Route
        exact={true}
        path={routes.pinEvent}
        component={Embed(EventEmbed)}
      />

      <Route path={routes.pinEventId} component={Embed(EventEmbed)} />

      <Route path={routes.pinBuyId} component={Embed(BuyForm)} />

      <Route component={Page(Error404, MODULES.ERROR_404)} />

      <HiddenRoute component={Page(ErrorBasic, MODULES.ERROR_BASIC)} />
    </Switch>
    <InternalSwitch />
  </>
);

export default Routes;
