import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISearchHighlight, ISearchReducer } from '@Model/search/types';

import get from './get';

const getSearchHighlights = createSelector<
  _Store.IState,
  ISearchReducer,
  ISearchHighlight[]
>([get], (search) => search.searchHighlights);

export default getSearchHighlights;
