import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getPlace } from '@Model/place/actions';

import { resetLoading, setLoading } from './../actions';
import * as CONST from './../constants/constants';

export const setLoaderWhenPlaceFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getPlace.request)),
    map$(() => setLoading(CONST.PLACE))
  );
};

export const setLoaderWhenPlaceFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getPlace.success, getPlace.failure], action)
    ),
    map$(() => resetLoading(CONST.PLACE))
  );
};
