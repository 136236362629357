import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITransactionReducer, PaymentOperatorEnum } from './../types';
import get from './get';

const getPaymentOperator = createSelector<
  _Store.IState,
  ITransactionReducer,
  PaymentOperatorEnum
>([get], (transaction) => transaction.paymentOperator);

export default getPaymentOperator;
