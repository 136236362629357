import React, { FC } from 'react';

import { Box } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import MenuAppBar from '@Compo/layout/MenuAppBar';
import MuiFooter from '@Compo/layout/MuiFooter';
import { IMuiGridProps } from '@Compo/layout/MuiGrid/MuiGrid.types';
import config from '@Config';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import useStyles from './MuiGrid.styles';

const MuiGrid: FC<IMuiGridProps> = ({
  children,
  withoutSidePaddings,
  isWebview,
}) => {
  const classes = useStyles();
  const { isDesktop } = useMuiBreakpoints();

  const isSbm = config.theme.isSbm;

  return (
    <>
      <CssBaseline />
      {!isSbm && !isWebview && <MenuAppBar />}
      <Box
        className={
          isSbm
            ? classes.sbm
            : isDesktop && withoutSidePaddings
            ? classes.withoutSidePaddingsDesktop
            : isDesktop && !withoutSidePaddings
            ? classes.desktop
            : withoutSidePaddings
            ? classes.withoutSidePaddingsRoot
            : classes.root
        }
        component={'main'}
      >
        {children}
      </Box>
      {!isSbm && !isWebview && <MuiFooter />}
    </>
  );
};

export default MuiGrid;
