import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getIframeParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { successSummaryMounted, unmount } from '@Model/transaction/actions';
import getTransactionDetails from '@Model/transaction/selectors/getTransactionDetails';
import getTransactionInfo from '@Model/transaction/selectors/getTransactionInfo';

import PaymentSuccess from './PaymentSuccess.component';
import {
  IPaymentSuccessFromDispatch,
  IPaymentSuccessFromState,
} from './PaymentSuccess.types';

function mapStateToProps(state: _Store.IState): IPaymentSuccessFromState {
  return {
    isEmbed: !!getIframeParams(state),
    lang: translate(state)('payment', 'paymentSuccess'),
    transactionInfo: getTransactionInfo(state),
    user: getTransactionDetails(state).user,
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPaymentSuccessFromDispatch => {
  return {
    mounted: () => {
      dispatch(successSummaryMounted());
    },
    unmount: () => {
      dispatch(unmount());
    },
  };
};

export default connect<
  IPaymentSuccessFromState,
  IPaymentSuccessFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSuccess);
