import { connect } from 'react-redux';

import _Store from '@Store';

import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import DescriptionBrick from './DescriptionBrick.component';
import {
  IDescriptionBrickFromState,
  IDescriptionBrickOwnProps,
} from './DescriptionBrick.types';

const mapStateToProps = (state: _Store.IState): IDescriptionBrickFromState => ({
  isLoading: getLoading(CONST.SECTIONS)(state),
});

export default connect<
  IDescriptionBrickFromState,
  void,
  IDescriptionBrickOwnProps,
  _Store.IState
>(mapStateToProps)(DescriptionBrick);
