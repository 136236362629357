import { getType } from 'typesafe-actions';

import { changeTheme, resetTheme } from './../actions';
import { IAction, IThemeReducer } from './../types';

const initialState: IThemeReducer = 'default';

const reducer = (state: IThemeReducer = initialState, action: IAction) => {
  switch (action.type) {
    case getType(changeTheme):
      return action.payload;
    case getType(resetTheme):
      return initialState;
    default:
      return state;
  }
};

export default reducer;
