import {
  IMultilineCardsOwnProps,
  IMultilineExtraData,
} from '@Compo/Bricks/components/MultilineCards/MultilineCards.types';
import { ISelectedLocation } from '@Model/locations/types';

import {
  IComponentExtraProps,
  ISectionsComponentDataMutable,
} from './../types/sections';

export const multiline_hotspots = (
  data: ISectionsComponentDataMutable,
  extra?: IMultilineExtraData,
  background?: string,
  id?: string,
  userLocation?: ISelectedLocation
): IMultilineCardsOwnProps & IMultilineExtraData => {
  const extraFlags: IComponentExtraProps = {
    disableDate: extra?.disableDate,
    disableDescription: extra?.disableDescription,
    disablePlace: extra?.disablePlace,
    disableTitle: extra?.disableTitle,
  };

  return {
    background,
    data,
    color: extra?.color,
    fixedWidthCards: extra?.fixedWidthCards,
    hotDeals: extra?.hotDeals,
    isHorizontal: extra?.isHorizontal,
    maxInRow: extra?.maxInRow,
    moreButton: extra?.moreButton,
    moreButtonText: extra?.moreButtonText,
    sectionDescription: extra?.sectionDescription,
    sectionTitle: extra?.sectionTitle,
    extraFlags,
    userLocation,
  };
};
