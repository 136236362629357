import React from 'react';

import Helmet from '@Compo/helmet/components/StaticPage';
import FullTriangleBackground from '@Compo/layout/FullTriangleBackground';
import MainPageTop from '@Compo/layout/MainPageTop';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import ChangeDate from '@Compo/ticketsManagement/ChangeDate';
import { IBreadcrumb } from '@Model/breadcrumb/types';
import routes from '@Routes/routes';

import style from './ChangeDate.module.scss';

const MY_TICKET_TEXT = 'Zarządzaj biletem';
const CHANGE_DATE_TEXT = 'Zmień date';

const breadcrumbs: IBreadcrumb[] = [
  {
    link: routes.myTicket,
    name: MY_TICKET_TEXT,
  },
  {
    link: routes.changeDate,
    name: CHANGE_DATE_TEXT,
  },
];

const ChangeDatePage = () => (
  <MuiGrid>
    <Helmet pageName={CHANGE_DATE_TEXT} />
    <FullTriangleBackground />
    <MuiSectionContainer>
      <MuiBreadcrumbs base={breadcrumbs} />
      <MainPageTop title={CHANGE_DATE_TEXT} />
      <div className={style.content}>
        <ChangeDate />
      </div>
    </MuiSectionContainer>
  </MuiGrid>
);

export default ChangeDatePage;
