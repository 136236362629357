import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { ButtonBase, Grid, Icon } from '@mui/material';

import { FavoriteAvatar } from '@Compo/PurchaseFollow/components/FavoriteAvatar';
import { IFavoriteListItemProps } from '@Compo/PurchaseFollow/components/FavoriteListItem/FavoriteListItem.types';
import SimpleLoading from '@Compo/reusable/SimpleLoading';
import Analytics from '@Services/$analytics';
import { Favorites } from '@Services/Favorites';
import Logger from '@Services/Logger';

export const FavoriteListItem = ({
  favorite,
  userEmail,
}: IFavoriteListItemProps) => {
  const [isSelected, setSelected] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const status = await Favorites.add({
        email: userEmail,
        externalId: favorite.externalId,
        type: favorite.type,
      });
      Analytics.pushAddToFavorites({
        destination_url: window.location.href,
        item_name: favorite.name,
        item_id: favorite.externalId,
        email: userEmail,
      });
      // @TODO: add notification message
      Logger.info('favorites', status);
    } catch (e) {
      // @TODO: add notification message
    }
    setSelected(true);
    setLoading(false);
  };

  return (
    <Grid item={true}>
      <ButtonBase
        onClick={handleClick}
        sx={{ display: 'flex', width: '100%' }}
        disabled={isSelected}
      >
        <Grid
          container={true}
          flexDirection={'row'}
          alignItems={'center'}
          flexWrap={'nowrap'}
          justifyContent={'flex-start'}
          spacing={2}
          p={1}
          pl={2}
        >
          <Grid item={true}>
            <FavoriteAvatar slug={favorite.slug} type={favorite.type} />
          </Grid>
          <Grid item={true} flex={1}>
            <Typography
              align={'left'}
              variant={'body2'}
              children={favorite.name}
            />
          </Grid>
          <Grid item={true}>
            {isLoading ? (
              <SimpleLoading relative={true} />
            ) : (
              <Icon color={'error'}>
                {isSelected ? <Favorite /> : <FavoriteBorder />}
              </Icon>
            )}
          </Grid>
        </Grid>
      </ButtonBase>
    </Grid>
  );
};
