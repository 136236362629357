import { connect } from 'react-redux';

import _Store from '@Store';

import { get } from '@Model/internalRouter/selectors';
import { IInternalRouterPayload } from '@Model/internalRouter/types';

import Switch from './Switch.component';

function mapStateToProps(state: _Store.IState): IInternalRouterPayload {
  return get(state).internalRouter;
}

export default connect<IInternalRouterPayload, void, {}, _Store.IState>(
  mapStateToProps
)(Switch);
