import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isBrowser } from '@Model/__base__/selectors';
import { eventBuyButtonClicked, eventProceed } from '@Model/analytics/actions';
import { isDesktop, isMobile, isTablet } from '@Model/app/selectors';
import {
  getArtistClosestEvents,
  getArtistClosestPool,
} from '@Model/artist/selectors';
import { getEvent } from '@Model/event/selectors';
import { getArtistImages, getPlaceImages } from '@Model/images/selectors';
import { getParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { getClosestPoolsData } from '@Model/pools/selectors';
import getPoolsData from '@Model/pools/selectors/getPoolsData';
import { getLocation } from '@Model/router/selectors';
import { EVENT } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { IBuyMatchParams } from '@Routes/types';

import Buy from './Buy.component';
import { IBuyFromDispatch, IBuyFromState, IBuyOwnProps } from './Buy.types';

const mapStateToProps = (state: _Store.IState): IBuyFromState => ({
  artistClosestPool: getArtistClosestPool(state),
  artistEvents: getArtistClosestEvents(state),
  dateTranslate: translate(state)('dates'),
  event: getEvent(state),
  // TODO: remove image selectors when Cloudinary images support is done.
  image: getArtistImages(state)?.[0]
    ? getArtistImages(state)?.[0]
    : getPlaceImages(state)?.[0]
    ? getPlaceImages(state)?.[0]
    : '',
  isBrowser: isBrowser(state),
  isDesktop: isDesktop(state),
  isLoading: getLoading(EVENT)(state),
  isMobile: isMobile(state),
  isPreview:
    getLocation(state).query?.preview === 'true' ||
    getLocation(state).query?.preview === '1' ||
    getLocation(state).query?.preview === 'on' ||
    getLocation(state).query?.preview === 'yes',
  isTablet: isTablet(state),
  lang: {
    ...translate(state)('event'),
    ...translate(state)('routes'),
    ...translate(state)('artist'),
  },
  params: getParams(state) as IBuyMatchParams,
  tickets: getClosestPoolsData(state),
  isPool: !!getPoolsData(state)?.pools?.length,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IBuyFromDispatch => {
  return {
    buyButtonAnalytic: () => dispatch(eventBuyButtonClicked()),
    eventProceed: (textDisplayed) => dispatch(eventProceed(textDisplayed)),
  };
};

export default connect<
  IBuyFromState,
  IBuyFromDispatch,
  IBuyOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Buy);
