import { connect } from 'react-redux';

import _Store from '@Store';

import { isAnyLoading } from '@Model/state/selectors';

import ProgressBar from './ProgressBar.component';
import { IProgressBarFromState } from './ProgressBar.types';

function mapStateToProps(state: _Store.IState): IProgressBarFromState {
  return {
    isLoading: isAnyLoading(state),
  };
}

export default connect<IProgressBarFromState, void, {}, _Store.IState>(
  mapStateToProps
)(ProgressBar);
