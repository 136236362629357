import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import Description from './Description.component';
import { IDescriptionFromState } from './Description.types';

const mapStateToProps = (state: _Store.IState): IDescriptionFromState => ({
  translate: translate(state)('buy', 'pools'),
});

export default connect<IDescriptionFromState, void, {}, _Store.IState>(
  mapStateToProps
)(Description);
