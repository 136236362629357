import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import SingleSegment from './SingleSegment.component';
import { ISingleSegmentFromState } from './SingleSegment.types';

const mapStateToProps = (state: _Store.IState): ISingleSegmentFromState => {
  return {
    lang: translate(state)('payment', 'paymentSuccess'),
  };
};

export default connect<ISingleSegmentFromState, {}, {}, _Store.IState>(
  mapStateToProps,
  {}
)(SingleSegment);
