export const REQUEST_TO_CHECK_SUBMISSIONS_EXISTS =
  'entryList/REQUEST_TO_CHECK_SUBMISSIONS_EXISTS';

export const _CHECK_SUBMISSIONS_EXISTS_REQUEST =
  'entryList/_CHECK_SUBMISSIONS_EXISTS_REQUEST';
export const _CHECK_SUBMISSIONS_EXISTS_SUCCESS =
  'entryList/_CHECK_SUBMISSIONS_EXISTS_SUCCESS';
export const _CHECK_SUBMISSIONS_EXISTS_FAILURE =
  'entryList/_CHECK_SUBMISSIONS_EXISTS_FAILURE';

export const SELECT_SUBMISSION = 'entryList/SELECT_SUBMISSION';

export const CONFIRM_PERSONAL_DATA = 'entryList/CONFIRM_PERSONAL_DATA';

export const CLEAR_FORMS_DATA = 'entryList/CLEAR_FORMS_DATA';

export const SET_IS_VIEW_ONLY = 'entryList/SET_IS_VIEW_ONLY';
export const SET_FORM_VALUES = 'entryList/SET_FORM_VALUES';
export const SET_PARENT_DATA = 'entryList/SET_PARENT_DATA';

export const PREPARE_SUBMISSIONS_TO_ATTACH =
  'entryList/PREPARE_SUBMISSIONS_TO_ATTACH';

export const CHECK_AGREEMENT_EXIST = 'entryList/CHECK_AGREEMENT_EXIST';

export const COMPLETE_ATTACHING = 'entryList/COMPLETE_ATTACHING';

export const CLEAR_FORMS_ERRORS = 'entryList/CLEAR_FORMS_ERRORS';

export const SET_FORM_ERRORS = 'entryist/SET_FORM_ERRORS';

export const APPEND_TO_SUBMISSIONS_SUMMARY =
  'entryList/APPEND_TO_SUBMISSIONS_SUMMARY';

export const FINALIZE_TRANSACTION = 'entryList/FINALIZE_TRANSACTION';

export const TICKET_SUBMISSIONS_COMPLETE =
  'entryList/TICKET_SUBMISSIONS_COMPLETE';

export const REQUEST_TO_ATTACH_SUBMISSION =
  'entryList/REQUEST_TO_ATTACH_SUBMISSION';

export const REQUEST_TO_REGISTER_NEW_SUBMISSION =
  'entryList/REQUEST_TO_REGISTER_NEW_SUBMISSION';

export const GENERATE_AND_SEND_SMS_CODE =
  'entryList/GENERATE_AND_SEND_SMS_CODE';

export const ATTACH_SUBMISSION_REQUEST = 'entryList/ATTACH_SUBMISSION_REQUEST';
export const ATTACH_SUBMISSION_SUCCESS = 'entryList/ATTACH_SUBMISSION_SUCCESS';
export const ATTACH_SUBMISSION_FAILURE = 'entryList/ATTACH_SUBMISSION_FAILURE';

export const CHECK_AGREEMENT_EXIST_REQUEST =
  'entryList/CHECK_AGREEMENT_EXIST_REQUEST';
export const CHECK_AGREEMENT_EXIST_SUCCESS =
  'entryList/CHECK_AGREEMENT_EXIST_SUCCESS';
export const CHECK_AGREEMENT_EXIST_FAILURE =
  'entryList/CHECK_AGREEMENT_EXIST_FAILURE';

export const HANDLE_NEXT_STEP = 'entryList/HANDLE_NEXT_STEP';
export const HANDLE_PREV_STEP = 'entryList/HANDLE_PREV_STEP';
export const SKIP_TO_SELECTED_STEP = 'entryList/SKIP_TO_SELECTED_STEP';
export const RESET_FORM = 'entryList/RESET_FORM';

export const GET_DATA_FROM_TICKET = 'entryList/GET_DATA_FROM_TICKET';
export const GET_DATA_FROM_TICKET_REQUEST =
  'entryList/GET_DATA_FROM_TICKET_REQUEST';
export const GET_DATA_FROM_TICKET_SUCCESS =
  'entryList/GET_DATA_FROM_TICKET_SUCCESS';
export const GET_DATA_FROM_TICKET_FAILURE =
  'entryList/GET_DATA_FROM_TICKET_FAILURE';

export const SEND_SMS_CODE_REQUEST = 'entryList/SEND_SMS_CODE_REQUEST';
export const SEND_SMS_CODE_SUCCESS = 'entryList/SEND_SMS_CODE_SUCCESS';
export const SEND_SMS_CODE_FAILURE = 'entryList/SEND_SMS_CODE_FAILURE';

export const REGISTER_NEW_SUBMISSION_REQUEST =
  'entrylist/REGISTER_NEW_SUBMISSION_REQUEST';
export const REGISTER_NEW_SUBMISSION_SUCCESS =
  'entrylist/REGISTER_NEW_SUBMISSION_SUCCESS';
export const REGISTER_NEW_SUBMISSION_FAILURE =
  'entrylist/REGISTER_NEW_SUBMISSION_FAILURE';
