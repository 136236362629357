import axios from 'axios';

import EventsListService from '@Misc/classes/EventsListService';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IHtmlResponse } from '@Services/$html-api/types';

class HtmlApi extends EventsListService {
  public getHtml(url: string): Promise<IHtmlResponse> {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          responseType: 'document',
        })
        .then(getData)
        .then((response: IHtmlResponse) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}

export default new HtmlApi();
