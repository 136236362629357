import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEntryListReducer } from './../types';
import get from './get';

const getSubmissionsComplete = createSelector<
  _Store.IState,
  IEntryListReducer,
  boolean
>([get], (state) => state.submissionsComplete);

export default getSubmissionsComplete;
