import { getType } from 'typesafe-actions';

import utcOffsetFixer from '@Misc/helpers/dateTime/utcOffsetFixer';

import {
  calculatePrice,
  setDay,
  setDayFromSlot,
  setDiscountState,
  setExtendedSlot,
  setExtendedSlotModalVisible,
  setNumberOfPlayers,
  setOnlyOnePriceType,
  setPrepaidState,
  setPriceType,
  setPricesInState,
  setSelectedUpsell,
  setSlot,
  setSpace,
  setTotalPriceInState,
  setUpsellState,
} from './../../actions';
import { IAction, IHappeningSelected } from './../../types';

const initialState: IHappeningSelected = {
  day: null,
  dayFromSlot:
    null /* we need a separate value for this to prevent choosing after-midnight slot from sending new requests for availabilities */,
  discount: null,
  prepaid: null,
  extendSlotModalVisible: false,
  priceType: null,
  prices: [],
  selections: [],
  selectionsPerPriceType: [],
  slot: null,
  space: -1,
  totalPrice: 0,
  upsell: null,
  selectedExtendedSlot: null,
};

const reducer = (
  state: IHappeningSelected = initialState,
  action: IAction
): IHappeningSelected => {
  switch (action.type) {
    case getType(setDay):
      const payloadDate = new Date(action.payload);
      const fixedDate = utcOffsetFixer(payloadDate, 'subtract');

      if (fixedDate) {
        return {
          ...state,
          day: fixedDate,
          dayFromSlot: initialState.dayFromSlot,
          slot: initialState.slot,
          space: initialState.space,
        };
      } else {
        return {
          ...state,
        };
      }

    case getType(setDayFromSlot):
      const payloadDateFromSlot = new Date(action.payload);
      const fixedDateFromSlot = utcOffsetFixer(payloadDateFromSlot, 'subtract');

      if (fixedDateFromSlot) {
        return {
          ...state,
          dayFromSlot: fixedDateFromSlot,
        };
      } else {
        return {
          ...state,
        };
      }

    case getType(setNumberOfPlayers):
      const _indexToModify = state.selections.findIndex(
        (selection) =>
          selection.priceType?.type === action.payload.priceType?.type
      );
      const _modifiedState = (_state: IHappeningSelected) => {
        if (_state.selections.length > 0 && _state.selections[_indexToModify]) {
          _state.selections[_indexToModify].numberOfPlayers =
            action.payload.numberOfPlayers;

          return _state;
        }

        return {
          ..._state,
          selections: [..._state.selections, action.payload],
        };
      };

      if (action.payload.priceType?.type === '') {
        return {
          ...state,
        };
      }
      const newState = _modifiedState(state);

      return {
        ...newState,
      };

    case getType(setSlot):
      return {
        ...state,
        slot: action.payload,
        space: initialState.space,
      };

    case getType(setSpace):
      return {
        ...state,
        space: action.payload,
      };

    case getType(setPriceType):
      const _priceTypeIndexToModify = state.selections.findIndex(
        (selection) => selection.priceType?.type === action.payload.type
      );
      const _priceTypeModifiedState = (_state: IHappeningSelected) => {
        if (
          _state.selections.length > 0 &&
          _state.selections[_priceTypeIndexToModify]
        ) {
          _state.selections[_priceTypeIndexToModify].priceType = action.payload;

          return _state;
        }

        return {
          ..._state,
          selections: [..._state.selections, action.payload],
        };
      };

      if (action.payload.type === '') {
        return {
          ...state,
        };
      }
      const priceTypeNewState = _priceTypeModifiedState(state);

      return {
        ...(priceTypeNewState as IHappeningSelected),
      };

    case getType(setOnlyOnePriceType):
      return {
        ...state,
        selections: [action.payload],
      };

    case getType(calculatePrice.success):
      return {
        ...state,
        selectionsPerPriceType: !!action.payload.length ? action.payload : [],
      };

    case getType(setSelectedUpsell):
      return {
        ...state,
        upsell: action.payload,
      };

    case getType(setUpsellState):
      if (state.upsell) {
        return {
          ...state,
          upsell: {
            ...state.upsell,
            isSelected: action.payload,
          },
        };
      }

      return { ...state };

    case getType(setTotalPriceInState):
      return {
        ...state,
        totalPrice: action.payload,
      };

    case getType(setDiscountState):
      return {
        ...state,
        discount: action.payload,
      };

    case getType(setPrepaidState):
      return {
        ...state,
        prepaid: action.payload,
      };

    case getType(setPricesInState):
      return {
        ...state,
        prices: action.payload,
      };

    case getType(setExtendedSlot):
      return {
        ...state,
        selectedExtendedSlot: action.payload,
      };

    case getType(setExtendedSlotModalVisible):
      return {
        ...state,
        extendSlotModalVisible: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
