import app from './app';

export enum imagePriorityEnum {
  api,
  cloudinary,
}

const images = {
  artistPrefix: 'artist/',
  cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME || 'going-test',
  cloudinaryApiKey:
    process.env.REACT_APP_CLOUDINARY_API_KEY || '174992729864757',
  cloudinaryApiSecret:
    process.env.REACT_APP_CLOUDINARY_API_SECRET ||
    'Zv1EPrJpd22qioak8dBSO9jsQvo',
  eventPrefix: 'event/',
  imagePriority: app.showUnfinishedFeatures
    ? imagePriorityEnum.cloudinary
    : imagePriorityEnum.api,
  placePrefix: 'place/',
};

export default images;
