import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = (customAspectRatio?: [number, number]) =>
  makeStyles(() =>
    createStyles({
      customAspectRatio: {
        ...(customAspectRatio && {
          paddingBottom: `calc(${customAspectRatio[0]} / ${customAspectRatio[0]} * 100%)`,
        }),
      },
      pill: {
        borderRadius: '50%',
      },
    })
  );

export default useStyles;
