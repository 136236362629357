import { createSelector } from 'reselect';

import * as CONSTS from './../constants/constants';
import getBase from './getBase';

const isBrowser = createSelector(
  [getBase],
  (__base__) => __base__.mode === CONSTS.BROWSER
);

export default isBrowser;
