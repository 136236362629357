import { Selector } from 'reselect';

import _Store from '@Store';

import { IPoolsSelectedSeatsReducer } from './../types';

const getSelectedSeats: Selector<_Store.IState, IPoolsSelectedSeatsReducer> = (
  state
) => state.pools.selectedSeats;

export default getSelectedSeats;
