import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import AWSQueryBuilder from '@Misc/classes/AWSQueryBuilder';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import convertToBool from '@Misc/helpers/convertToBool';
import formatDate from '@Misc/helpers/date/formatDate';
import splitNumberToDayMonthYear from '@Misc/helpers/date/splitNumberToDayMonthYear';
import today from '@Misc/helpers/date/today';
import translatableDate from '@Misc/helpers/translatableDate';
import withCacheHeader from '@Misc/helpers/withCacheHeader';
import { IEvent } from '@Model/events/types';
import { ILang } from '@Model/locale/types';
import {
  ISearchDates,
  ISearchFilter,
  ISearchHighlight,
  ISearchQueryJoinMethod,
  ISearchSorting,
  ISearchSuccessPayload,
} from '@Model/search/types';
import { EventsApi } from '@Services/$events-api/EventsApi';
import { IBuckets, IEventPlaceResponse } from '@Services/$events-api/types';

import { ISearchHit, ISearchResponse } from './types';

class AWSSearchApi extends AWSQueryBuilder {
  protected static getBaseQuery(
    phrase?: string,
    filters?: ISearchFilter[],
    dynamicFilters?: Array<{ [key: string]: string }>,
    dates?: ISearchDates,
    offset?: number,
    isFuzzySearch?: boolean,
    customSorting?: ISearchSorting,
    queryJoinMethod?: ISearchQueryJoinMethod,
    searchSize?: number
  ) {
    const allFilters: Array<{ [key: string]: string } | ISearchFilter> = [];

    if (!!filters?.length) allFilters.push(...filters);
    if (!!dynamicFilters?.length) allFilters.push(...dynamicFilters);

    const staticQueryTerms = AWSSearchApi.term(config.aws.searchStaticTerms);
    const filterArray: Array<{ name: string; value: string[] }> = [];

    const formatFilters = () =>
      allFilters?.map((filterFromState) => {
        const existingFilterIndex = filterArray.findIndex(
          (filter) => filter.name === filterFromState.key
        );

        if (existingFilterIndex > -1) {
          filterArray[existingFilterIndex].value = [
            ...filterArray[existingFilterIndex].value,
            filterFromState.value,
          ];

          return;
        }

        filterArray.push({
          name: filterFromState.key,
          value: [filterFromState.value],
        });
      });

    formatFilters();

    const filtersQuery = !!allFilters?.length
      ? AWSSearchApi.term(filterArray, queryJoinMethod)
      : '';
    const datesQuery = AWSSearchApi.datesQuery(
      dates?.from
        ? formatDate(splitNumberToDayMonthYear(dates.from), true)
        : formatDate(splitNumberToDayMonthYear(today()), true),
      dates?.to ? formatDate(splitNumberToDayMonthYear(dates.to), true) : null
    );

    const isEmptyPhrase = !phrase?.length;

    const options = [
      AWSSearchApi.return(['_all_fields']),
      AWSSearchApi.option('format', 'json'),
      AWSSearchApi.option('q.parser', 'lucene'),
      AWSSearchApi.option(
        'q.options',
        AWSSearchApi.searchFieldsOption(config.aws.searchFields)
      ),
      AWSSearchApi.option('start', offset || 0),
      AWSSearchApi.option(
        'size',
        searchSize ?? config.aws.searchPaginationPageSize
      ),
      AWSSearchApi.option(
        'sort',
        AWSQueryBuilder.sort(
          customSorting
            ? customSorting.map((item) => ({
                priority: item.Priority, // TODO remove when backend gets fixed :exploding_head:
                sort: item.sort,
                field: item.field,
              }))
            : isEmptyPhrase
            ? config.aws.emptyPhraseSorting
            : config.aws.sorting
        )
      ),
      AWSSearchApi.option(
        'expr.city',
        `(city_id%3D%3D${config.aws.sortPriorityCityId})*_score`
      ),
      // AWSSearchApi.return(config.aws.searchSuggestListFields),
      AWSSearchApi.highlights(config.aws.searchHighlights),
      AWSSearchApi.facets(config.aws.facets),
    ];

    return AWSSearchApi.query(
      'search',
      this.bond([staticQueryTerms, filtersQuery, datesQuery], 'AND'),
      options,
      AWSSearchApi.basePhrase(phrase || '', isFuzzySearch)
    );
  }

  private static filterHighlights(hits: ISearchHit[]) {
    const highlights: ISearchHighlight[] = [];
    const countedHighlights: ISearchHighlight[] = [];

    hits.forEach((hit) => {
      Object.entries(hit.highlights).forEach(([key, value]) => {
        const foundPhrase = value.includes(config.aws.searchHighlightsTags.pre);

        if (foundPhrase) {
          const isArray = Array.isArray(hit.fields[key]);

          if (isArray) {
            const values = hit.fields[key] as string[];

            values.forEach((element) => {
              highlights.push({
                count: 1,
                key,
                value: element,
              });
            });
          } else {
            highlights.push({
              count: 1,
              key,
              value: hit.fields[key] as string,
            });
          }
        }
      });
    });

    highlights.forEach((highlight) => {
      const duplicate = countedHighlights.some(
        (item) => item.key === highlight.key && item.value === highlight.value
      );

      if (duplicate) {
        const index = countedHighlights.findIndex(
          (item) => item.key === highlight.key && item.value === highlight.value
        );
        countedHighlights[index].count = countedHighlights[index].count + 1;
      } else {
        countedHighlights.push(highlight);
      }
    });

    return countedHighlights.sort((a, b) => (a.count < b.count ? 1 : -1));
  }

  private static normalizeEvents(
    events: ISearchHit[],
    dateTranslate: ILang
  ): IEvent[] {
    const normalizeSingleEvent = (event: ISearchHit): IEvent => {
      const {
        id,
        fields: {
          address,
          app_image,
          artists_ids,
          artists_names,
          button_label,
          calendar_event,
          change_monitor_name,
          cloudinary_images,
          city_id,
          city_name,
          currency,
          description_pl,
          description_en,
          event_category_id,
          event_category_name,
          event_slug,
          geolocation,
          has_pools,
          horizontal_image,
          location_ids,
          locations_names,
          partner_id,
          partner_name,
          partner_premium_level,
          place_id,
          place_name,
          place_premium_level,
          place_slug,
          price,
          private_tags_ids,
          private_tags_names,
          rundate_id,
          rundate_not_for_sale,
          rundate_enddate,
          rundate_rundate,
          rundate_slug,
          sales_channels_ids,
          square_image,
          title_en,
          title_pl,
          // possibly undefined
          city_slug,
          empik_premium,
          end_date,
          place_thumb,
          price_description_pl,
          public_tags_ids,
          public_tags_names,
          redirect_to_url,
          rundate_description,
        },
      } = event;

      const eventTags = public_tags_ids
        ? public_tags_ids.map((eventId, index) => {
            return {
              id: Number(eventId),
              name: public_tags_names ? public_tags_names[index] : '',
              slug: public_tags_names ? public_tags_names[index] : '',
            };
          })
        : [];

      const location = geolocation ? geolocation.split(',') : [0, 0];

      const place = {
        address: city_name,
        city: { name: city_name, slug: city_slug },
        id: place_id,
        lat: Number(location[0]),
        lon: Number(location[1]),
        name: place_name,
        slug: place_slug,
      } as unknown as IEventPlaceResponse;

      return {
        buttonLabel: button_label,
        calendarEvent: calendar_event
          ? convertToBool(calendar_event)
          : undefined,
        changeMonitorName: change_monitor_name,
        currency,
        dateDescription: rundate_description,
        description: description_pl,
        endDate: end_date,
        eventSlug: event_slug,
        friendlyDate: translatableDate(
          rundate_rundate,
          rundate_description,
          dateTranslate,
          rundate_enddate
        ),
        friendlyHour: '',
        hasPools: has_pools,
        id: parseInt(id, 10),
        imageUrl: !!cloudinary_images?.length
          ? cloudinary_images[0]
          : square_image || horizontal_image,
        isPremiumEvent: empik_premium,
        notForSale: convertToBool(rundate_not_for_sale),
        partner: `${partner_name}|${partner_id}`,
        place: EventsApi.getFriendlyPlace(place),
        placeId: place.id,
        placeImageUrl: place_thumb || '',
        placeSlug: place_slug,
        price: price?.split('.')?.[0],
        priceDescription:
          price_description_pl &&
          EventsApi.getPriceDescription(price_description_pl),
        redirectToUrl: redirect_to_url || null,
        rundateSlug: rundate_slug,
        startDate: rundate_rundate,
        tags: EventsApi.prepareTagsFromRundate(
          {
            id: parseInt(event_category_id, 10),
            name: event_category_name,
            slug: event_slug,
          },
          eventTags
        ),
        title: title_pl,
      };
    };

    return events.map((event) => {
      return normalizeSingleEvent(event);
    });
  }

  protected cancelTokenEvents?: CancelTokenSource;

  public searchAWS({
    dateTranslate,
    phrase,
    filters,
    dynamicFilters,
    dates,
    offset,
    isFuzzySearch,
    customSorting,
    queryJoinMethod,
    searchSize,
  }: {
    dateTranslate: ILang;
    phrase?: string;
    filters?: ISearchFilter[];
    dynamicFilters?: Array<{ [key: string]: string }>;
    dates?: ISearchDates;
    offset?: number;
    isFuzzySearch?: boolean;
    customSorting?: ISearchSorting;
    queryJoinMethod?: ISearchQueryJoinMethod;
    searchSize?: number;
  }): Promise<ISearchSuccessPayload> {
    const queryString = AWSSearchApi.getBaseQuery(
      phrase,
      filters,
      dynamicFilters,
      dates,
      offset,
      isFuzzySearch,
      customSorting,
      queryJoinMethod,
      searchSize
    );
    const searchUrl = `${config.api.awsUrl}${queryString}`;

    return new Promise((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();

      axios
        .get(
          searchUrl,
          withCacheHeader({ cancelToken: this.cancelTokenEvents.token })
        )
        .then(getData)
        .then((response: ISearchResponse) => {
          return resolve({
            highlights: AWSSearchApi.filterHighlights(response.hits.hit),
            offset: offset || 0,
            results: AWSSearchApi.normalizeEvents(
              response.hits.hit,
              dateTranslate
            ),
            facets: response.facets,
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public combineFacets = (
    facetIds: IBuckets,
    facetNames: IBuckets,
    facetSlugs?: IBuckets
  ) =>
    facetIds.buckets.map((facet, index) => ({
      count: facet.count,
      id: facet.value,
      name: facetNames?.buckets[index]?.value,
      slug: facetSlugs?.buckets[index]?.value,
      verified: facet.count === facetNames.buckets[index]?.count,
    }));

  public cancelSearchAWS() {
    if (this.cancelTokenEvents) {
      this.cancelTokenEvents.cancel();
      this.cancelTokenEvents = undefined;
    }
  }
}

export default new AWSSearchApi();
