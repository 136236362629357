export const getLocationNameFromId = (id: string) => {
  switch (id) {
    case '1':
      return 'Warszawa';
    case '2':
      return 'Kraków';
    case '8':
      return 'Poznań';
    case '9':
      return 'Śląsk';
    case '10':
      return 'Łódź';
    case '12':
      return 'Wrocław';
    case '13':
      return 'Trójmiasto';
    case '27':
      return 'Białystok';
    case '33':
      return 'Lublin';
    case '40':
      return 'Bydgoszcz';
    case '41':
      return 'Toruń';
    default:
      return;
  }
};
