import { connect } from 'react-redux';

import _Store from '@Store';

import { isBrowser } from '@Model/__base__/selectors';
import { isDesktop, isMobile, isTablet } from '@Model/app/selectors';

import EmbedFrame from './EmbedFrame.component';
import { IEmbedFrameFromState, IEmbedFrameOwnProps } from './EmbedFrame.types';

const mapStateToProps = (state: _Store.IState): IEmbedFrameFromState => ({
  isBrowser: isBrowser(state),
  isDesktop: isDesktop(state),
  isMobile: isMobile(state),
  isTablet: isTablet(state),
});

export default connect<
  IEmbedFrameFromState,
  void,
  IEmbedFrameOwnProps,
  _Store.IState
>(mapStateToProps)(EmbedFrame);
