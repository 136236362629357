import React, { useContext, useEffect } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { IBasketProps } from '@Compo/Basket/Basket.types';
import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import BasketButton from '@Compo/Basket/components/BasketButton';
import classesButton from '@Compo/buying/components/FormLayout/FormLayout.classes';
import getBasketFormattedPools from '@Misc/helpers/getBasketFormattedPools';
import getPriceFormatted from '@Misc/helpers/getPriceFormatted';
import routes from '@Routes/routes';

import styles from './Basket.module.scss';
import BasketEmpty from './components/BasketEmpty';
import BasketItem from './components/BasketItem';

const CONTINUE_TEXT = 'Kontynuuj zakupy';
const FINAL_PRICE = 'do zapłaty:';
const LOCAL_CURRENCY = 'zł';
const PAY_TEXT = 'Przejdź do płatności';

const Basket = ({ updateBasketState }: IBasketProps) => {
  const state = useContext(clearContext);

  const basketItems = state?.basketItems;

  const finalPrice = state?.basketItems.length
    ? state?.basketItems
        .map((item) => item.price)
        .reduce((a, b) => {
          return a + b;
        })
    : null;

  const formattedItems = getBasketFormattedPools(basketItems);

  useEffect(() => {
    if (basketItems) {
      updateBasketState(basketItems);
    }
  }, [basketItems]);

  return (
    <div className={styles.basketWrapper}>
      <div className={styles.yourBasketWrapper}>
        <BasketButton
          iconClassName={styles.icon}
          itemsLengthClassName={styles.itemsLength}
        />
      </div>
      {!!state?.basketItems.length && (
        <>
          <div className={styles.content}>
            {formattedItems?.map((basketItem) => (
              <BasketItem
                basketItem={basketItem}
                key={`basketItem-${basketItem.rundateId || basketItem.id}`}
              />
            ))}
            <div className={styles.finalPrice}>
              <span className={styles.totalPriceTitle}>{FINAL_PRICE} </span>
              <span className={styles.totalPriceText}>
                {getPriceFormatted(finalPrice)}
                <span className={styles.currency}>
                  {' '}
                  {formattedItems?.[0]?.currency || LOCAL_CURRENCY}
                </span>
              </span>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonsWrapper}>
              <Link to={routes.events} className={styles.buttonWrapper}>
                <button
                  type="button"
                  className={cn(
                    styles.buyButton,
                    styles.basketButton,
                    classesButton.buyButton
                  )}
                >
                  {CONTINUE_TEXT}
                </button>
              </Link>
              <Link to={routes.basketBuy} className={styles.buttonWrapper}>
                <button
                  type="button"
                  className={cn(styles.buyButton, classesButton.buyButton)}
                >
                  {PAY_TEXT}
                </button>
              </Link>
            </div>
          </div>
        </>
      )}
      {!state?.basketItems.length && <BasketEmpty />}
    </div>
  );
};

export default Basket;
