import { from as from$, of as of$ } from 'rxjs';
import { catchError as catchError$ } from 'rxjs/internal/operators/catchError';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';
import { createNotification } from '@Model/notifications/actions';
import {
  passwordReset,
  passwordResetFormSubmit,
} from '@Model/passwordReset/actions';
import { IPasswordResetMatchParams } from '@Routes/types';
import LegacyAuthApi from '@Services/$legacy-auth-api/index';

export const submitResetPassword: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(passwordResetFormSubmit)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const payload = action.payload;
      const paramsFromState = getParams(state) as IPasswordResetMatchParams;
      payload.languageId = paramsFromState.languageParam;
      payload.token = paramsFromState.token;

      return [passwordReset.request(payload)];
    })
  );
};

export const sendResetPasswordRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(passwordReset.request)),
    mergeMap$((action) => {
      const body = JSON.stringify(action.payload);

      return from$(LegacyAuthApi.passwordReset(body)).pipe(
        mergeMap$((data) => {
          return [passwordReset.success(data)];
        }),
        catchError$((error) => of$(passwordReset.failure(error)))
      );
    })
  );
};

export const passwordResetSuccess: _Store.IEpic = (action$, state$) =>
  action$.pipe(
    filter$(isActionOf(passwordReset.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const lang = translate(state)('passwordReset', 'passwordResetForm');

      const error = action.payload.errors[0];
      const success = lang.successMessage;

      return action.payload.isSuccess
        ? of$(createNotification(success))
        : of$(createNotification(error));
    })
  );

export const passwordResetFailure: _Store.IEpic = (action$) =>
  action$.pipe(
    filter$(isActionOf(passwordReset.failure)),
    mergeMap$((action) => {
      const message = action.payload.message;

      return of$(createNotification(message));
    })
  );
