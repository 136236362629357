import React from 'react';

import LandingPage from '@Compo/LandingPage';
import Helmet from '@Compo/helmet/components/CompositionPage';

const LandingPageWrapper = () => (
  <>
    <Helmet />
    <LandingPage />
  </>
);

export default LandingPageWrapper;
