import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amount: {
      aspectRatio: '1',
      borderColor: theme.border.color,
      borderRadius: theme.border.radius,
      borderStyle: theme.border.style,
      borderWidth: theme.border.width,
      width: '3.5rem!important',
    },
    button: {
      backgroundColor: 'transparent!important',
    },
  })
);

export default useStyles;
