import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPool, IPoolsSelectedTicketsReducer } from './../types';
import getPools from './getPools';
import getSelectedTickets from './getSelectedTickets';

const isPoolHasDependency = createSelector<
  _Store.IState,
  IPool[],
  IPoolsSelectedTicketsReducer,
  boolean
>(
  [getPools, getSelectedTickets],
  (pools: IPool[], selectedTickets: IPoolsSelectedTicketsReducer) => {
    return selectedTickets
      .map((selectedTicket) => {
        const currentPool =
          pools.find((pool) => pool.id === selectedTicket.poolId) ||
          ({} as IPool);

        return currentPool.hasDependency;
      })
      .includes(true);
  }
);

export default isPoolHasDependency;
