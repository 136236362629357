import { Theme, createStyles, makeStyles } from '@material-ui/core';

import sectionComponentFontColor from '@Misc/helpers/theme/sectionComponentFontColor';

const useStyles = (color?: string, isBgDark?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      fontColor: {
        color: sectionComponentFontColor(theme, color, isBgDark),
      },
    })
  );

export default useStyles;
