import { createSelector } from 'reselect';

import config from '@Config';
import { getData } from '@Model/artist/selectors';

import * as TEXTS from './../constants/texts';
import { IMeta } from './../types';

const artistPageMeta = createSelector([getData], (artist): IMeta => {
  const artistName = artist?.title || '';
  const description = artist?.description || TEXTS.LOADING;
  const ogDescription = description;
  const pageName = `${artistName} ${TEXTS.TICKETS_FOR_CONCERTS}` || '';

  return {
    currentUrl: `${config.app.baseUrl}${location.pathname}`,
    description,
    ogDescription,
    pageName,
  };
});

export default artistPageMeta;
