import { createSelector } from 'reselect';

import _Store from '@Store';

import { IInternalRouterReducer } from './../types';
import get from './get';

const getExternalStyle = createSelector<
  _Store.IState,
  IInternalRouterReducer,
  string | undefined
>([get], (embed) => embed.embed.externalStyle);

export default getExternalStyle;
