import { createSelector } from 'reselect';

import config from '@Config';
import getBasketTickets from '@Model/basket/selector/getBasketTickets';
import getSelectedTickets from '@Model/pools/selectors/getSelectedTickets';
import { getInsuranceProducts } from '@Model/products/selectors';

const getNumberOfInsuredTickets = (isInsurance: boolean) =>
  createSelector(
    [getSelectedTickets, getBasketTickets, getInsuranceProducts],
    (selectedTickets, basketTickets) => {
      /**
       * Checking for any conditions that result in turning off of the insurance.
       */
      if (
        !isInsurance ||
        (!selectedTickets.length && !basketTickets.length) ||
        selectedTickets.length > config.buy.maxInsuredTicketsLimit ||
        basketTickets.length > config.buy.maxInsuredTicketsLimit ||
        [...selectedTickets, ...basketTickets].length >
          config.buy.maxInsuredTicketsLimit
      )
        return 0;

      /**
       * Only allowing tickets that are within insurance price range
       */
      return selectedTickets.filter(
        (pool) => pool.price && pool.price >= 5 && pool.price <= 2500
      ).length;
    }
  );

export default getNumberOfInsuredTickets;
