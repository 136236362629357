import React from 'react';

import styles from './Loading.module.scss';

const Loading = () => (
  <div className={styles.buy}>
    <div className={styles.fakeDate}>
      <span className={styles.fakeIcon} />
      <span className={styles.fakeDateItem} />
    </div>
    <div className={styles.fakeTime}>
      <span className={styles.fakeIcon} />
      <span className={styles.fakeTimeItem} />
    </div>
    <div className={styles.fakePlace}>
      <span className={styles.fakeIcon} />
      <span className={styles.fakePlaceItem} />
    </div>

    <div className={styles.tickets}>
      <div className={styles.fakePrice} />
    </div>

    <div className={styles.fakeButton} />
  </div>
);

export default Loading;
