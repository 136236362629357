import React, { FC } from 'react';

import { IPagesSections, IPagesSectionsComponent } from '@Model/pages/types';

import bricksComponents from './Bricks.connections';
import { IBricksProps } from './Bricks.types';
import Loading from './components/Loading';
import Section from './components/Section';

const Bricks: FC<IBricksProps> = ({ structure, inner, userLocation }) => (
  <>
    {structure?.sections.map((section: IPagesSections, sectionKey) => (
      <Section
        background={section.background}
        backgroundImage={section.backgroundImage}
        contain={section.contain}
        inner={inner}
        inRow={section.inRow}
        key={sectionKey}
        mobileBackgroundImage={section.mobileBackgroundImage}
        useGradient={section.useGradient}
        id={section.id}
      >
        {section.components.map(
          (component: IPagesSectionsComponent, componentKey) => {
            if (!bricksComponents.hasOwnProperty(component.type)) {
              return null;
            }
            const Brick = bricksComponents[component.type].component;
            const id = component.type + '-' + component.id;
            const brickProps = bricksComponents[component.type].props(
              component.data,
              component.extra,
              section.background,
              id,
              userLocation
            );

            return <Brick {...brickProps} key={componentKey} id={id} />;
          }
        )}
      </Section>
    ))}
    {!structure && !inner && <Loading />}
  </>
);

export default Bricks;
