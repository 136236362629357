import hashToDate from './hashToDate';

const TRANSLATE_FROM = 'od';
const TRANSLATE_TO = 'do';

const prepareForOneDay = (
  day: {
    day: number;
    month: string;
    year: number;
  },
  currentYear: number
): Array<string | number> => {
  const arr = [];

  arr.push(day.day, day.month);

  if (day.year !== currentYear) {
    arr.push(day.year);
  }

  return arr;
};

const dateToHumanText = (
  from: number | null,
  to: number | null
): string | null => {
  const currentYear = new Date().getFullYear();

  let fromArr: Array<string | number> = [];
  const toArr: Array<string | number> = [];

  if (from) {
    const dateFrom = hashToDate(from);

    if (to) {
      if (from === to) {
        fromArr = prepareForOneDay(dateFrom, currentYear);
      } else {
        const dateTo = hashToDate(to);

        // Always add days.
        fromArr.push(dateFrom.day);
        toArr.push(dateTo.day);

        if (dateFrom.year !== dateTo.year) {
          fromArr.push(dateFrom.month, dateFrom.year);
          toArr.push(dateTo.month, dateTo.year);
        } else {
          if (dateFrom.month !== dateTo.month) {
            fromArr.push(dateFrom.month);
          }

          toArr.push(dateTo.month);

          // For current year displaying it is unnecessary.
          if (dateFrom.year !== currentYear) {
            toArr.push(dateTo.year);
          }
        }
      }
    } else {
      fromArr = prepareForOneDay(dateFrom, currentYear);
    }
  }

  if (fromArr.length > 0) {
    if (toArr.length > 0) {
      return `${TRANSLATE_FROM} ${fromArr.join(
        ' '
      )} ${TRANSLATE_TO} ${toArr.join(' ')}`;
    }

    return fromArr.join(' ');
  }

  return null;
};

export default dateToHumanText;
