import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

import { IPriceBody, IPriceResponse } from './types';

class PriceTypeApi {
  private static getCheckPriceUrl() {
    return `${config.api.baseUrl}price`;
  }

  private cancelTokenPriceCheck?: CancelTokenSource;

  public getPrice(body: IPriceBody): Promise<IPriceResponse> {
    return new Promise<IPriceResponse>((resolve, reject) => {
      this.cancelTokenPriceCheck = axios.CancelToken.source();

      return axios
        .post(PriceTypeApi.getCheckPriceUrl(), body, {
          cancelToken: this.cancelTokenPriceCheck.token,
        })
        .then(getData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelCheckPrice() {
    if (this.cancelTokenPriceCheck) {
      this.cancelTokenPriceCheck.cancel();
      this.cancelTokenPriceCheck = undefined;
    }
  }
}

export default new PriceTypeApi();
