import { getType } from 'typesafe-actions';

import { setInvalidSelection, setValidSelection } from '@Model/tickets/actions';
import { IAction, ISeatsData } from '@Model/tickets/types';

const reducer = (
  state: ISeatsData = {
    isSelectionValid: true,
  },
  action: IAction
): ISeatsData => {
  switch (action.type) {
    case getType(setInvalidSelection):
      return {
        isSelectionValid: false,
      };

    case getType(setValidSelection):
      return {
        isSelectionValid: true,
      };

    default:
      return state;
  }
};

export default reducer;
