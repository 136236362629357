const classes = {
  agreement: 'agreements-agreement',
  agreements: 'agreements',
  checkbox: 'agreements-checkbox',
  formPanel: 'agreements-form-panel',
  header: 'agreements-header',
  newsletterText: 'agreements-newsletter-text',
  requiredText: 'agreements-required-text',
  withoutCheckbox: 'agreements-without-checkbox',
};

export default classes;
