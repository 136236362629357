import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blackFontColor: {
      color: '#111',
    },
  })
);

export default useStyles;
