import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { validateForm } from '@Model/analytics/actions';
import { translate } from '@Model/locale/selectors';
import { isDataHydratedTransaction } from '@Model/pools/selectors';
import { buyAndPayOnline } from '@Model/transaction/actions';

import { getInitialValues } from './../Online/Online.selectors';
import Products from './Products.component';
import {
  IBuyingOnsiteFromDispatch,
  IBuyingOnsiteFromState,
} from './Products.types';

const mapStateToProps = (state: _Store.IState): IBuyingOnsiteFromState => {
  return {
    errors: translate(state)('errors'),
    initialValues: (basketCustomTerms) =>
      getInitialValues(state, basketCustomTerms),
    isDataHydratedTransaction: isDataHydratedTransaction(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IBuyingOnsiteFromDispatch => {
  return {
    buyAndPay: (values) => dispatch(buyAndPayOnline(values)),
    checkout: () => dispatch(validateForm()),
  };
};

export default connect<void, IBuyingOnsiteFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Products);
