import React from 'react';

import { IRedirectForCardPaymentProps } from './RedirectForCardPayment.types';

const RedirectForCardPayment = ({
  data,
  isBrowser,
  isCardPayment,
}: IRedirectForCardPaymentProps): null => {
  if (!!data.formUrl && isBrowser && isCardPayment) {
    window.location.replace(data.formUrl);
  }

  return null;
};

export default RedirectForCardPayment;
