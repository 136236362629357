import { ChangeEventHandler, useEffect, useState } from 'react';

import { regExp } from '@Constants/RegExp';
import { useToggle } from '@Misc/hooks/useToggle';
import { getPreviousForm } from '@Model/transaction/selectors';
import Analytics from '@Services/$analytics';
import { Favorites } from '@Services/Favorites';
import Logger from '@Services/Logger';
import { FavoritesTypes } from '@Types/Favorites';

export const useFollowing = ({
  ids,
  type,
  itemName,
}: {
  ids: number[];
  type: FavoritesTypes;
  itemName: string;
}) => {
  const { isVisible, handleHide, handleShow } = useToggle(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(true);

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event
  ) => {
    setError(false);
    setEmail(event.target.value);
  };

  const onSubmit = async () => {
    if (!email || !regExp.email.test(email)) {
      setError(true);

      return;
    }

    setIsLoading(true);
    try {
      await Favorites.add({
        email,
        externalId: ids[0],
        type,
      });
      Analytics.pushAddToFavorites({
        destination_url: window.location.href,
        item_name: itemName,
        item_id: ids[0],
        email,
      });
    } catch (e: any) {
      Logger.error('useFollowing.onSubmit', e);
    } finally {
      setIsLoading(false);
      setIsSuccess(true);
    }
  };

  useEffect(() => setIsSuccess(false), [ids]);

  useEffect(() => {
    setError(false);
    const buyForm = getPreviousForm();

    if (buyForm?.values?.email) {
      setEmail(buyForm.values.email);
    }
  }, []);

  return {
    email,
    error,
    onChange,
    onSubmit,
    handleHide,
    handleShow,
    isVisible,
    isSuccess,
    isLoading,
  };
};
