import React from 'react';

import Selection from '@Compo/Selection';
import Helmet from '@Compo/helmet/components/SelectionPage';
import MuiBreadcrumbs from '@Compo/layout/MuiBreadcrumbs';
import MuiGrid from '@Compo/layout/MuiGrid';
import MuiSectionContainer from '@Compo/layout/MuiSectionContainer';
import { IBreadcrumb } from '@Model/breadcrumb/types';

const breadcrumbs: IBreadcrumb[] = [];

const SelectionPage = () => (
  <MuiGrid>
    <Helmet />
    <MuiSectionContainer>
      <MuiBreadcrumbs base={breadcrumbs} />
      <Selection />
    </MuiSectionContainer>
  </MuiGrid>
);

export default SelectionPage;
