import { connect } from 'react-redux';

import _Store from '@Store';

import { isDesktop } from '@Model/app/selectors';
import { getIframeParams } from '@Model/internalRouter/selectors';
import { translate } from '@Model/locale/selectors';

import ActionMessage from './ActionMessage.component';
import { IActionMessageFromState } from './ActionMessage.types';

const mapStateToProps = (state: _Store.IState): IActionMessageFromState => ({
  client: getIframeParams(state)?.client || '',
  isDesktop: isDesktop(state),
  lang: translate(state)('modals', 'ActionMessage'),
});

export default connect<IActionMessageFromState, void, {}, _Store.IState>(
  mapStateToProps
)(ActionMessage);
