import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { ILocaleReducer } from '@Model/locale/types';

import * as CONSTS from './../constants/actions';

export const changeLocale = createStandardAction(
  CONSTS.CHANGE_LOCALE
)<ILocaleReducer>();

export const setUserLocale = createStandardAction(CONSTS.SET_USER_LOCALE)<
  void | string
>();

export const getUserLocale = createAsyncAction(
  CONSTS._GET_USER_LOCALE_REQUEST,
  CONSTS._GET_USER_LOCALE_SUCCESS,
  CONSTS._GET_USER_LOCALE_FAILURE
)<void, string, Error>();
