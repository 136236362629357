import React from 'react';

import Tag from '@Compo/Events/components/Tag';

import styles from './Tags.module.scss';
import { ITagsProps } from './Tags.types';

const Tags = ({ tags }: ITagsProps) => {
  if (!tags) {
    return null;
  }

  return (
    <ul className={styles.list}>
      {tags.map((tag) => (
        <li key={tag.id}>
          <Tag
            id={tag.id}
            name={tag.name}
            slug={tag.slug}
            isMain={tag.isMain}
          />
        </li>
      ))}
    </ul>
  );
};

export default Tags;
