import React, { FC } from 'react';

import Gallery from '@Compo/reusable/Gallery';

import styles from './PlaceGallery.module.scss';
import { IPlaceGalleryTypes } from './PlaceGallery.types';

const PlaceGallery: FC<IPlaceGalleryTypes> = ({ images, isLoading }) => {
  if (!isLoading && !images) {
    return null;
  }

  return (
    <Gallery
      photos={images || []}
      isLoading={isLoading}
      vertical={true}
      customContainerClass={styles.galleryWrapper}
    />
  );
};

export default PlaceGallery;
