import React, { useEffect, useState } from 'react';

import { DatePicker } from '@material-ui/pickers/';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import cn from 'classnames';
import moment from 'moment';

import config from '@Config';

import styles from './Calendar.module.scss';
import { ICalendarProps } from './Calendar.types';

const Calendar = ({
  happeningStartDate,
  isDateSelectionUnAvailable,
  location,
  setDay,
  generateIdempotencyKey,
}: ICalendarProps) => {
  const [date, setDate] = useState(happeningStartDate);
  const changeDate = (newDate: MaterialUiPickersDate) => {
    if (newDate) {
      setDate(moment(newDate).toDate());
      setDay(moment(newDate).toDate());
      generateIdempotencyKey();
    }
  };
  const isDarkTheme =
    config.theme.isGoing &&
    location.query?.isDark &&
    location.query?.isDark === 'true';

  useEffect(() => {
    if (date) {
      changeDate(moment(date));
    }
  }, [happeningStartDate]);

  return (
    <div
      className={cn(
        styles.datePicker,
        isDateSelectionUnAvailable && styles.disabled,
        isDarkTheme && 'dark'
      )}
    >
      <DatePicker
        autoOk={false}
        cancelLabel={false}
        value={date}
        onChange={changeDate}
        disablePast={true}
        disableToolbar={true}
        variant={'static'}
        open={true}
      />
    </div>
  );
};

export default Calendar;
