import { JSXElementConstructor } from 'react';

import empikRoutes from './empik';
import goingRoutes from './going';
import sbmRoutes from './sbm';

let Router;

/*
 * We use in plain way reference to this var, because
 * during parsing by webpack it is replaced by boolean value and
 * evaluated. Thanks to tree-shaking build doesnt have included
 * module which is not in use.
 */
switch (process.env.THEME) {
  case 'going':
    Router = goingRoutes;
    break;
  case 'sbm':
    Router = sbmRoutes;
    break;
  default:
    Router = empikRoutes;
}

export default Router as JSXElementConstructor<unknown>;
