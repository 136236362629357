import React, { useContext } from 'react';

import { Box, IconButton } from '@material-ui/core';
import {
  CardGiftcardOutlined,
  ConfirmationNumberOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from '@material-ui/icons';
import { Badge } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import MobileMenu from '@Compo/layout/MenuAppBar/components/MobileMenu';
import config from '@Config';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import routes from '@Routes/routes';

import useStyles from './IconBar.styles';

const COLOR = 'inherit';

const IconBar = () => {
  const classes = useStyles();
  const basketState = useContext(clearContext);
  const itemsLength = basketState?.basketItems.length;
  const { isDesktop } = useMuiBreakpoints();

  return (
    <Box className={classes.root}>
      <IconButton component={NavLink} to={routes.events} color={COLOR}>
        <SearchOutlined />
      </IconButton>
      {config.app.onlineSale && (
        <>
          <IconButton component={NavLink} to={routes.myTicket} color={COLOR}>
            <ConfirmationNumberOutlined />
          </IconButton>

          <IconButton component={NavLink} to={routes.basket} color={COLOR}>
            <Badge badgeContent={itemsLength} color={'primary'}>
              <ShoppingCartOutlined />
            </Badge>
          </IconButton>
          {!isDesktop && <MobileMenu />}
        </>
      )}
      {!config.app.onlineSale && (
        <IconButton
          component={NavLink}
          to={routes.giftCardActivation}
          color={COLOR}
        >
          <CardGiftcardOutlined />
        </IconButton>
      )}
    </Box>
  );
};

export default IconBar;
