const colors = {
  actions: {
    background: 'rgba(0, 0, 0, 0.08);',
    disabled: 'rgba(0,0,0,0.12);',
    disabledBackground:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12))',
    disabledText: 'rgba(0, 0, 0, 0.26);',
    main: '#E0E0E0',
  },
  other: {
    activeRating: '#FFB400',
    backdrop: 'rgba(0, 0, 0, 0.5);',
    divider: '#E0E0E0',
    snackbar: '#323232',
    stroke: '#2C2D53',
    white: '#ffffff',
    gray: 'rgba(0,0,0,0.2)',
  },
  primary: {
    background: 'rgba(0, 167, 216, 0.08);',
    border: '#2D2E4E',
    contrastText: '#FFFFFF',
    dark: '#3D8CB9',
    light: '#A5DBFF',
    main: '#5EA6D5',
  },
  secondary: {
    background: '#2C2D5310',
    border: '#2C2D53',
    contrastText: '#FFFFFF',
    dark: '#28293D',
    light: '#373737',
    main: '#2C2D53',
  },
  text: {
    disabled: 'rgba(0, 0, 0, 0.38);',
    hint: 'rgba(0, 0, 0, 0.38);',
    primary: '#2C2D53',
    secondary: 'rgba(0, 0, 0, 0.54);',
  },
};

export default colors;
