import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { handlePrevStep } from '@Model/entryList/actions';

import ParentFormLayout from './ParentFormLayout.component';
import { IParentFormLayoutFromDispatch } from './ParentFormLayout.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IParentFormLayoutFromDispatch => ({
  handlePrevStep: () => dispatch(handlePrevStep()),
});

export default connect<{}, IParentFormLayoutFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(ParentFormLayout);
