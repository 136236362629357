import { createSelector } from 'reselect';

import _Store from '@Store';

import config from '@Config';

import { IProduct, IProductsReducer } from './../types';
import getAll from './getAll';

const getProducts = createSelector<_Store.IState, IProductsReducer, IProduct[]>(
  [getAll],
  (products) =>
    products.products.filter(
      (product) => !(config.theme.isEmpik && product.type === 'collectorTicket')
    )
);

export default getProducts;
