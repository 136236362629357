export const CHANGE_LOCATION = 'Empik/locations/CHANGE_LOCATION';
export const CLEAR_LOCATION = 'Empik/locations/CLEAR_LOCATION';
export const DEFAULT_LOCATION = 'Empik/locations/DEFAULT_LOCATION';
export const GET_LOCATIONS = 'Empik/locations/GET_LOCATIONS';
export const SET_LOCATION = 'Empik/locations/SET_LOCATION';

// Private
export const _GET_LOCATIONS_FAILURE = 'Empik/locations/_GET_LOCATIONS_FAILURE';
export const _GET_LOCATIONS_REQUEST = 'Empik/locations/_GET_LOCATIONS_REQUEST';
export const _GET_LOCATIONS_SUCCESS = 'Empik/locations/_GET_LOCATIONS_SUCCESS';
