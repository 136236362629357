import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getParams } from '@Model/internalRouter/selectors';
import { ILandingPageMatchParams } from '@Routes/types';
import { ILandingPageResponse } from '@Services/$landing-api/types';

import { getLandingPage, landingPageMounted } from './../actions';

export const getLandingPageWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(landingPageMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return [getLandingPage.request()];
    })
  );
};

export const fetchLandingPageWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { landingPageApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getLandingPage.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const params = getParams(state) as ILandingPageMatchParams;

      if (params) {
        return from$(landingPageApi.getLandingPage(params.landingSlug)).pipe(
          mergeMap$((data: ILandingPageResponse) => {
            const normalizedLandingKey = landingPageApi.makeKeyFromParams(data);

            return [
              getLandingPage.success({
                key: normalizedLandingKey,
                landingPage: data,
              }),
            ];
          }),
          catchError$((error: Error) => of$(getLandingPage.failure(error)))
        );
      }

      return EMPTY$;
    }),
    catchError$((error: Error) => of$(getLandingPage.failure(error)))
  );
};
