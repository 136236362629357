import { getType } from 'typesafe-actions';

import { IBuyingOnlineFormValues } from '@Compo/buying/Online/Online.types';
import { IAction } from '@Model/transaction/types';

import { buyAndPayOnline } from './../../actions';

const reducer = (
  state: IBuyingOnlineFormValues | null = null,
  action: IAction
): IBuyingOnlineFormValues | null => {
  switch (action.type) {
    // _POST_SUCCESS
    case getType(buyAndPayOnline):
      return action.payload.data;

    default:
      return state;
  }
};

export default reducer;
