import React, { FC } from 'react';

import FailContent from '@Compo/summaries/Fail';
import SuccessContent from '@Compo/summaries/Success';

import { ITransferSummaryProps } from './TransferSummary.types';

const TransferSummary: FC<ITransferSummaryProps> = ({
  isTransferReceived,
  transactionError,
}) => {
  if (transactionError) {
    return <FailContent />;
  }

  return (
    <SuccessContent
      transferSending={!isTransferReceived}
      transferReceived={isTransferReceived}
    />
  );
};

export default TransferSummary;
