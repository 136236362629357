import React, { FC, MouseEvent } from 'react';

import Card from '@Compo/reusable/Card';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import Analytics from '@Services/$analytics';

import styles from './CardDeck.module.scss';
import { ICardDeckProps } from './CardDeck.types';

const CardDeck: FC<ICardDeckProps> = ({
  background,
  cards,
  color,
  fixedWidthCards,
  forceHorizontalOnMobile,
  hotDeals,
  id,
  isLoading,
  maxInRow,
  sectionTitle,
  children,
  compositionIdentifier,
  type,
}) => {
  const { isMobile } = useMuiBreakpoints();

  const handleAddToDataLayerOnClick =
    (link?: string, textDisplayed?: string, componentName?: string) =>
    (e: MouseEvent<HTMLElement>) =>
      link
        ? Analytics.pushForHomepageSelectItem(
            e,
            link,
            textDisplayed || '',
            componentName
          )
        : undefined;

  const cardDeck = cards.map((card, index) => (
    <Card
      ctaButton={card.ctaButton}
      secondaryCtaButton={card.secondaryCtaButton}
      sectionBackground={background}
      badge={card.badge}
      children={children}
      color={color}
      date={card.date}
      description={card.description}
      fixedWidthCards={fixedWidthCards}
      isHorizontalMultiline={forceHorizontalOnMobile}
      isSlider={forceHorizontalOnMobile}
      highlightTitle={true}
      hotDeals={hotDeals}
      isLoading={isLoading}
      key={index.toString()}
      link={card.link}
      maxInRow={maxInRow}
      place={card.place}
      placeSlug={card.placeSlug}
      subtitle={card.subtitle}
      thumb={card.thumb}
      title={card.title}
      compositionIdentifier={compositionIdentifier}
      type={type}
      brickId={id}
      onClick={handleAddToDataLayerOnClick(
        card.link,
        card.title!,
        sectionTitle
      )}
    />
  ));

  const cardDeckClass = !isMobile
    ? styles.cardDeck
    : forceHorizontalOnMobile
    ? styles.slidableDeck
    : styles.cardDeck;

  return (
    <>
      {sectionTitle && (
        <div className={styles.sectionTitle}>{sectionTitle}</div>
      )}
      <ul className={cardDeckClass} id={id} data-test-id={'card-deck'}>
        {cardDeck}
      </ul>
    </>
  );
};

export default CardDeck;
