import React from 'react';

import styles from './Loading.module.scss';

const Loading = () => {
  return (
    <div className={styles.content}>
      <h1 className={styles.fakeTitle} />

      <div className={styles.infoBox}>
        <p className={styles.fakeInfo} />
        <p className={styles.fakeInfo} />
      </div>
    </div>
  );
};

export default Loading;
