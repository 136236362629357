import { getPlaceImages } from '@/models/images/selectors';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isDesktop, isTablet } from '@Model/app/selectors';
import { placePageMounted } from '@Model/place/actions';
import { getPlace } from '@Model/place/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import Place from './Place.component';
import { IPlaceFromDispatch, IPlaceFromState } from './Place.types';

const mapStateToProps = (state: _Store.IState): IPlaceFromState => {
  return {
    images: getPlaceImages(state),
    isDesktop: isDesktop(state),
    isLoading: getLoading(CONST.PLACE)(state),
    isTablet: isTablet(state),
    place: getPlace(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPlaceFromDispatch => {
  return {
    mounted: () => dispatch(placePageMounted()),
  };
};

export default connect<IPlaceFromState, IPlaceFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Place);
