import { createSelector } from 'reselect';

import _Store from '@Store';

import { ILocaleReducer } from '@Model/locale/types';

import { ILang } from './../types';
import get from './get';

const getExternalLangMap = createSelector<
  _Store.IState,
  ILocaleReducer,
  ILang | undefined
>([get], (locale) => locale.externalLangMap);

export default getExternalLangMap;
