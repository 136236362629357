import React from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import ImageFixed from '@Compo/reusable/ImageFixed';
import Tags from '@Compo/reusable/Tags';
import prepareUrlForRoute from '@Misc/helpers/prepareUrlForRoute';
import { useDictionary } from '@Recoil/lang/hooks/useDictionary';
import routes from '@Routes/routes';

import styles from './Location.module.scss';
import { ILocationProps } from './Location.types';

const Location = ({
  address,
  description,
  name,
  placeImageUrl,
  showTags,
  slug,
  tags,
}: ILocationProps) => {
  const i18n = useDictionary();

  return (
    <div className={styles.location}>
      <div className={cn(styles.row, styles.placeName)}>{i18n.event.place}</div>
      <div className={styles.row}>
        <div className={styles.left}>
          <ImageFixed
            src={placeImageUrl}
            title={name}
            transformation={{ width: 810, height: 700 }}
          />
        </div>
        <div className={styles.right}>
          {tags && showTags && (
            <div className={styles.tags}>
              <Tags tags={tags} />
            </div>
          )}
          <h2>
            {slug && (
              <Link to={prepareUrlForRoute(routes.place, slug)}>{name}</Link>
            )}
            {!slug && name}
          </h2>
          <h3>{address}</h3>
          {description && (
            <div className={styles.description}>
              <p>{description}</p>
            </div>
          )}
          {slug && (
            <div className={styles.placeLinks}>
              <Link to={prepareUrlForRoute(routes.place, slug)}>
                {i18n.event.placeLink}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Location;
