import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { eventSearchClick } from '@Model/analytics/actions';
import { isMobile } from '@Model/app/selectors';
import { getLocations } from '@Model/locations/selectors';
import {
  baseSearch,
  getFacets,
  removeSearchFilter,
  searchMounted,
  setLastFilter,
  setSearchFilter,
  setSearchPhrase,
} from '@Model/search/actions';
import {
  getSearchCategories,
  getSearchDates,
  getSearchFilters,
  getSearchHighlights,
  getSearchPhrase,
  getSearchResults,
  getSearchTags,
} from '@Model/search/selectors';

import Search from './Search.component';
import {
  ISearchFromDispatch,
  ISearchFromState,
  ISearchOwnProps,
} from './Search.types';

const mapStateToProps = (state: _Store.IState): ISearchFromState => ({
  isMobile: isMobile(state),
  locations: getLocations(state),
  searchCategories: getSearchCategories(state),
  searchDates: getSearchDates(state),
  searchTags: getSearchTags(state),
  searchFilters: getSearchFilters(state),
  searchHighlights: getSearchHighlights(state),
  searchPhrase: getSearchPhrase(state),
  searchResults: getSearchResults(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISearchFromDispatch => ({
  removeFilter: (filter) => dispatch(removeSearchFilter(filter)),
  search: (offset, isFuzzySearch) =>
    dispatch(baseSearch({ offset, isFuzzySearch })),
  getCategories: () => dispatch(getFacets()),
  eventSearchClick: () => dispatch(eventSearchClick()),
  searchMounted: () => dispatch(searchMounted()),
  setSearchPhrase: (phrase) => dispatch(setSearchPhrase(phrase)),
  setFilter: (filter) => dispatch(setSearchFilter(filter)),
  setLastFilter: (filter) => dispatch(setLastFilter(filter)),
});

export default withRouter(
  connect<
    ISearchFromState,
    ISearchFromDispatch,
    ISearchOwnProps,
    _Store.IState
  >(
    mapStateToProps,
    mapDispatchToProps
  )(Search)
);
