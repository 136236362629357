import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '3.75rem',
    },
    formPanel: {
      border: 'none',
      borderRadius: theme.border.radius,
      marginBottom: '2rem',
      padding: '2.375rem 1.875rem',

      [theme.breakpoints.down('md')]: {
        padding: '1.75rem 1.25rem',
      },
    },
    formContainer: {
      '& .formio-component-submit': {
        display: 'none',
      },
    },
    header: {
      fontSize: ' 1.75rem',
      // @ts-ignore TODO
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: '2rem',
      textTransform: 'lowercase',

      '&:before': {
        content: "attr(data-number) '. '",
      },
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default useStyles;
