import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISearchFilter, ISearchReducer } from '@Model/search/types';

import get from './get';

const getSearchFilters = createSelector<
  _Store.IState,
  ISearchReducer,
  ISearchFilter[]
>([get], (search) => search.filters);

export default getSearchFilters;
