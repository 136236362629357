import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import stepNames from '../../steps';
import useStyles from './Paper.styles';
import { IPaperProps } from './Paper.types';
import Counter from './components/Counter';

const Paper = ({
  title,
  helperText,
  children,
  submissions,
  ticketData,
  activeStep,
}: IPaperProps) => {
  const classes = useStyles();
  const showCounter = !!(
    ticketData &&
    submissions.length &&
    (activeStep === stepNames.personalData ||
      activeStep === stepNames.selectSubmission)
  );

  return (
    <Grid container={true} justifyContent="center" className={classes.root}>
      <Grid item={true} xs={12}>
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Grid
              container={true}
              spacing={2}
              justifyContent="center"
              alignItems="center"
              className={classes.header}
            >
              <Grid item={true}>
                <Typography variant="h5" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
              {showCounter && (
                <Grid item={true}>
                  <Counter
                    submissionsAdded={submissions.length}
                    submissionsLeft={ticketData.entriesQuantity - 1}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {helperText && (
            <Grid item={true} xs={12}>
              <Typography variant="body1" className={classes.subtitle}>
                {helperText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item={true} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Paper;
