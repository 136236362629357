import config from '@Config';

const getCurrencyFromCode = (currencyCode: string): string => {
  switch (currencyCode) {
    case 'PLN':
      return 'zł';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'USD':
      return '$';
    default:
      return config.app.defaultCurrency;
  }
};

export default getCurrencyFromCode;
