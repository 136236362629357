import { getType } from 'typesafe-actions';

import { EmbedActions } from '@Model/internalRouter/constants/embedActions';
import { IInternalRouterEvent } from '@Model/internalRouter/types';
import { changeLocale } from '@Model/locale/actions';
import { IAction, ILang, ILocaleReducer } from '@Model/locale/types';

export const initialState: ILocaleReducer = {
  externalLangMap: undefined,
  selectedLang: 'pl',
};

const reducer = (
  state: ILocaleReducer = initialState,
  action: IAction
): ILocaleReducer => {
  switch (action.type) {
    case getType(changeLocale):
      return {
        ...state,
        selectedLang: action.payload.selectedLang,
      };

    case EmbedActions.LOAD_EXTERNAL_LANG_MAP:
      const internalAction = action as IInternalRouterEvent;

      return {
        ...state,
        externalLangMap: internalAction.payload as ILang,
      };

    default:
      return state;
  }
};

export default reducer;
