import React, { FC } from 'react';

import { Container, Grid } from '@material-ui/core';

import { IMuiSectionContainerProps } from './MuiSectionContainer.types';

const MuiSectionContainer: FC<IMuiSectionContainerProps> = ({
  children,
  className,
}) => (
  <Container component={'section'} maxWidth={'lg'} className={className}>
    <Grid container={true} direction={'column'} justifyContent={'flex-start'}>
      {children}
    </Grid>
  </Container>
);

export default MuiSectionContainer;
