import { combineReducers } from 'redux';

import agentReducer from './agent';
import deviceReducer from './device';
import isWebviewReducer from './isWebview';
import sizesReducer from './sizes';

const reducer = combineReducers({
  device: deviceReducer,
  mobileUserAgent: agentReducer,
  sizes: sizesReducer,
  isWebview: isWebviewReducer,
});

export default reducer;
