import { createStandardAction } from 'typesafe-actions';

import * as CONSTS from './../constants/actions';
import {
  IBuyButtonAnalytics,
  IEventAddPaymentInfo,
  IEventAddToCart,
  IEventDeeplinkWeb,
  IHappeningAddToCart,
  ISelectItemEvent,
} from './../types';

export const eventAddPaymentInfo = createStandardAction(
  CONSTS.INFORM_ABOUT_ADD_PAYMENT_INFO
)<IEventAddPaymentInfo>();

export const informAboutBuyingProcess = createStandardAction(
  CONSTS.INFORM_ABOUT_BUYING_PROCESS
)();

export const eventAddToCart = createStandardAction(
  CONSTS.INFORM_ABOUT_ADD_TO_CART
)<IEventAddToCart>();

export const eventAutoFillData = createStandardAction(
  CONSTS.INFORM_ABOUT_AUTO_FILL_DATA
)<string>();

export const eventRemoveFromCart = createStandardAction(
  CONSTS.INFORM_ABOUT_REMOVE_FROM_CART
)<IEventAddToCart>();

export const eventAddToCartHappening = createStandardAction(
  CONSTS.INFORM_ABOUT_ADD_TO_CART_HAPPENING
)<IHappeningAddToCart>();

export const eventDeeplinkWeb = createStandardAction(
  CONSTS.INFORM_ABOUT_DEEPLINK_WEB
)<IEventDeeplinkWeb>();

export const eventRemoveFromCartHappening = createStandardAction(
  CONSTS.INFORM_ABOUT_REMOVE_FROM_CART_HAPPENING
)<IHappeningAddToCart>();

export const eventProceed = createStandardAction(
  CONSTS.INFORM_ABOUT_PROCEED
)<string>();

export const eventListBuyButtonClicked = createStandardAction(
  CONSTS.INFORM_ABOUT_EVENT_LIST_BUY_BUTTON
)<IBuyButtonAnalytics>();

export const eventBannerClick = createStandardAction(
  CONSTS.INFORM_ABOUT_BANNER_CLICK
)<string>();

export const eventBuyButtonClicked = createStandardAction(
  CONSTS.INFORM_ABOUT_EVENT_BUY_BUTTON
)();

export const eventSearchClick = createStandardAction(
  CONSTS.INFORM_ABOUT_SEARCH_CLICK
)();

export const eventSelectItem = createStandardAction(
  CONSTS.INFORM_ABOUT_SELECT_ITEM
)<ISelectItemEvent>();

export const validateForm = createStandardAction(
  CONSTS.INFORM_ABOUT_VALIDATE
)();

export const firstValidation = createStandardAction(CONSTS.FIRST_VALIDATION)();

export const checkoutStep = createStandardAction(
  CONSTS.INFORM_ABOUT_CHECKOUT
)<number>();

export const setTradedoublerId = createStandardAction(
  CONSTS.SET_TRADEDOUBLER_ID
)<string>();

export const addNewsletterSubscriptionToDataLayer = createStandardAction(
  CONSTS.ADD_NEWSLETTER_SUB_TO_DATA_LAYER
)<string>();
