import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import styles from '@Compo/Happening/Happening.module.scss';
import { HappeningFormLayoutProps } from '@Compo/Happening/components/HappeningFormLayout/HappeningFormLayout.types';
import HappeningSummary from '@Compo/Happening/components/HappeningSummary/HappeningSummary.container';
import Agreements from '@Compo/buying/components/Agreements/Agreements.container';
import AuthId from '@Compo/buying/components/AuthId';
import ClientData from '@Compo/buying/components/ClientData/ClientData.container';
import classes from '@Compo/buying/components/FormLayout/FormLayout.classes';
import ToggleField from '@Compo/buying/components/ToggledField';
import config from '@Config';
import autocompleteForm from '@Misc/helpers/autocompleteForm';
import isEmptyObject from '@Misc/helpers/isEmptyObject';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';
import useUpdateEffect from '@Misc/hooks/useUpdateEffect';

import useStyles from './HappeningFormLayout.styles';

const HappeningFormLayout = ({
  autoFill,
  canReserve,
  customTerms,
  errors,
  handleSubmit,
  lang,
  langDiscount,
  langFormLayout,
  setFieldValue,
  values,
  step,
  setDiscountState,
  calculatePrice,
  upsellFromState,
  selectedTheme,
  langHappening,
  langAgreements,
  isTransactionLoading,
  isError,
}: HappeningFormLayoutProps) => {
  const autocomplete = () =>
    autocompleteForm(autoFill, customTerms, setFieldValue);
  const calculateWithDiscount = (discountCode: string) => {
    setDiscountState(discountCode);
    calculatePrice({
      discountCode,
      upsell: upsellFromState,
    });
  };

  const { isMobile } = useMuiBreakpoints();
  let formikSteps = step;

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  useUpdateEffect(() => {
    if (!values.discountCheckbox && values.discount) {
      setFieldValue('discount', '');
      calculateWithDiscount('');
    }
  }, [values.discountCheckbox]);

  const isDefaultTheme = selectedTheme === 'default';

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12} className={muiStyles.marginTop}>
          {config.app.onlineSale && (
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12}>
                <Typography variant={'h4'}>
                  {formikSteps++}. {langDiscount.provideData}
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                <div
                  className={cn(
                    styles.box,
                    muiStyles.root,
                    !isDefaultTheme && muiStyles.withoutPadding
                  )}
                >
                  <ClientData
                    autocompleteForm={autocomplete}
                    isDisabled={!canReserve}
                    number={0}
                    slotsSale={true}
                    setFieldValue={setFieldValue}
                    showInvoiceForm={values.invoiceCheckbox}
                    showPassForm={values.passCheckbox}
                  />
                </div>
              </Grid>
            </Grid>
          )}
          {!config.app.onlineSale && (
            <>
              <Typography variant={'h4'}>
                {formikSteps++}. {langHappening.enterDiscountCode}
              </Typography>
              <div
                className={cn(
                  styles.box,
                  muiStyles.root,
                  !isDefaultTheme && muiStyles.withoutPadding
                )}
              >
                <ToggleField
                  checkboxId={'discountCheckbox'}
                  inputId={'discount'}
                  isDisabled={!canReserve}
                  open={values.discountCheckbox}
                  placeholder={lang.discountCode}
                  text={
                    config.theme.isGoing
                      ? langDiscount.iHaveDiscountGoing
                      : langDiscount.iHaveDiscount
                  }
                />
                {values.discountCheckbox && (
                  <Button
                    variant={'contained'}
                    size={'large'}
                    color={'primary'}
                    disableElevation={true}
                    fullWidth={true}
                    children={langHappening.checkDiscount}
                    onClick={() => calculateWithDiscount(values.discount)}
                  />
                )}
              </div>
              <AuthId isDisabled={!canReserve} number={formikSteps++} />
            </>
          )}
        </Grid>

        <Grid item={true} xs={12}>
          {config.app.onlineSale && (
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12}>
                <Typography variant={'h4'}>
                  {formikSteps++}. {langAgreements.acceptAgreements}
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                <Agreements
                  slotsSale={true}
                  isDisabled={!canReserve}
                  number={0}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item={true} xs={12}>
          {isMobile && (
            <div className={cn(styles.mobileSummary, classes.mobileSummary)}>
              <h3 className={cn(styles.header, classes.header)}>
                {langFormLayout.summary}
              </h3>
              <HappeningSummary />
            </div>
          )}
          <div className={styles.buyWrapper}>
            <Button
              variant={'contained'}
              size={'large'}
              color={'primary'}
              type={'submit'}
              disableElevation={true}
              disabled={!canReserve || isTransactionLoading || isError}
              fullWidth={true}
            >
              {langHappening.book}
            </Button>
            {!isEmptyObject(errors) && (
              <div className={styles.errorContainer}>{lang.formInvalid}</div>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default HappeningFormLayout;
