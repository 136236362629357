import React, { useEffect } from 'react';

import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';

import Breadcrumb from '@Compo/Breadcrumb';
import Loading from '@Compo/Bricks/components/Loading';
import DeepLinkModal from '@Compo/reusable/DeepLinkModal';
import Description from '@Compo/reusable/Description';
import Event from '@Compo/reusable/Event';
import ImageFixed from '@Compo/reusable/ImageFixed';
import Title from '@Compo/reusable/Title';
import DetailsLoading from '@Compo/reusable/loadings/DetailsLoading';
import config from '@Config';
import getUrlWithTransformation from '@Misc/helpers/cloudinary/getUrlWithTransformation';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { IElement } from '@Model/selection/types';
import routes from '@Routes/routes';

import styles from './Selection.module.scss';
import useStyles from './Selection.styles';
import { ISelection } from './Selection.types';

const SELECTION_LINK_KEY = ':slug';
const EVENTS_SECTION_TEXT = 'Wydarzenia';

const Selection = ({
  selection,
  isWebview,
  isMobile,
  isSelectionLoading,
  isTablet,
  mounted,
}: ISelection) => {
  const theme = useTheme();
  const muiStyles = useStyles(theme);

  useEffect(() => {
    mounted();
  }, []);

  if (!selection) {
    return <Loading />;
  }

  return (
    <>
      {selection && (
        <Breadcrumb
          current={{
            isLoading: isSelectionLoading,
            link: fillUrlWithValues(
              routes.selection,
              [SELECTION_LINK_KEY],
              [selection.slug]
            ),
            name: selection.title,
          }}
        />
      )}

      <div className={styles.selectionWrapper}>
        <div className={styles.imageWrapper}>
          <ImageFixed
            src={isMobile ? selection.mobileImage : selection.thumbUrl}
            fallbackImageUrl={selection.thumbUrl}
            wide={!isMobile}
            transformation={{
              height: isMobile ? 810 : 660,
              width: isMobile ? 810 : 1260,
            }}
          />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.selectionHeader}>
            <Title
              title={selection?.title}
              isLoading={isSelectionLoading}
              customClassName={cn(
                styles.titleWrapper,
                muiStyles.blackFontColor
              )}
            />
          </div>
          <div className={styles.selectionBody}>
            {isSelectionLoading && <DetailsLoading isFullWidth={true} />}
            {!isSelectionLoading && (
              <Description
                text={selection?.description}
                hideApostrophe={config.theme.isGoing && true}
              />
            )}
          </div>
        </div>

        {selection?.elements && (
          <h2 className={styles.sectionHeader}>{EVENTS_SECTION_TEXT}</h2>
        )}

        {selection?.elements && (
          <ul className={styles.elementsWrapper}>
            {selection.elements.map((element: IElement, key: number) => {
              return (
                <Event
                  key={key}
                  id={key}
                  {...element}
                  forcedVertical={isTablet}
                  isMobile={isMobile}
                />
              );
            })}
          </ul>
        )}
      </div>
      {selection &&
        (isMobile || isTablet) &&
        config.theme.isGoing &&
        !isWebview && (
          <DeepLinkModal
            url={fillUrlWithValues(routes.selection, ':slug', selection.slug)}
          />
        )}
    </>
  );
};

export default Selection;
