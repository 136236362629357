import { getType } from 'typesafe-actions';

import { getPlaceEvents, resetState } from './../actions';
import { IAction, IEvent, IReducerEvents } from './../types';

const initialState: IReducerEvents = {
  eventsData: [] as IEvent[],
  eventsTileView: false,
  nextPage: 1,
  placeKey: null,
  totalCount: 0,
};

const reducer = (
  state: IReducerEvents = initialState,
  action: IAction
): IReducerEvents => {
  switch (action.type) {
    // _GET_SUCCESS
    case getType(getPlaceEvents.success):
      return {
        ...state,
        eventsData: action.payload.eventsData,
        nextPage: action.payload.nextPage,
        placeKey: action.payload.placeKey ?? null,
        totalCount: action.payload.totalCount ?? 0,
      };

    // _RESET_STATE
    case getType(resetState):
      return { ...initialState };

    default:
      return state;
  }
};

export default reducer;
