import { createSelector } from 'reselect';

import config from '@Config';
import { getParams } from '@Model/internalRouter/selectors';
import { IEventMatchParams } from '@Routes/types';
import EventApiService from '@Services/$events-api';

import getData from './getData';
import getRequestTimes from './getRequestTimes';

/*
 * Selector returns 3-values logic.
 *
 * string when event exists
 * false when event doesnt exist
 * null when data is missing
 */
const getKeyWhenEventExistsAndIsFresh = createSelector(
  [getParams, getData, getRequestTimes],
  (params, data, requestTimes) => {
    if (params) {
      const key = EventApiService.makeKeyFromParams(
        params as IEventMatchParams
      );

      if (
        key in data &&
        key in requestTimes &&
        requestTimes[key] + config.app.howOldCanBeStaticPage > Date.now()
      ) {
        return key;
      } else {
        return false;
      }
    }

    return null;
  }
);

export default getKeyWhenEventExistsAndIsFresh;
