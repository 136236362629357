import React from 'react';

import { Grid, Link, useTheme } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { useStyles } from '@Compo/layout/MenuAppBar/components/DesktopMenu/DesktopMenu.styles';
import config from '@Config';
import menu from '@Services/$menu-api/index';

const menuSelector =
  config.theme.isEmpik && config.app.onlineSale
    ? menu.menuEmpik
    : config.theme.isEmpik && !config.app.onlineSale
    ? menu.menuEmpikOnsite
    : menu.menuGoing;

const linkRegExp = new RegExp('^https?://');

const DesktopMenu = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Grid container={true} spacing={4} justifyContent={'center'}>
      {menuSelector.map((item) => (
        <Grid item={true} key={item.title}>
          {item.link && linkRegExp.test(item.link) ? (
            <Link
              href={item.link}
              color={'inherit'}
              underline={'none'}
              target={'_self'}
              variant={'body1'}
              rel={item.nofollow ? 'nofollow' : 'follow'}
            >
              {item.title}
            </Link>
          ) : item.link ? (
            <Link
              component={NavLink}
              to={item.link}
              color={'inherit'}
              underline={'none'}
              variant={'body1'}
              rel={item.nofollow ? 'nofollow' : 'follow'}
              style={item.isSalesButton ? styles.salesButton : undefined}
            >
              {item.title}
            </Link>
          ) : (
            <Tooltip title={menu.toBeReleasedText}>
              <Link
                color={'inherit'}
                underline={'none'}
                variant={'body1'}
                rel={item.nofollow ? 'nofollow' : 'follow'}
              >
                {item.title}
              </Link>
            </Tooltip>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default DesktopMenu;
