import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPlaceReducer, IPlaceReducerCurrentKey } from './../types';
import get from './get';

const getCurrentKey = createSelector<
  _Store.IState,
  IPlaceReducer,
  IPlaceReducerCurrentKey
>([get], (place) => place.currentKey);

export default getCurrentKey;
