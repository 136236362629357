import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import { getIframeParams } from '@Model/internalRouter/selectors';
import {
  changeLocale,
  getUserLocale,
  setUserLocale,
} from '@Model/locale/actions';
import { ILocaleReducer } from '@Model/locale/types';

export const setBrowserLocale: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setUserLocale)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { supportedLocales, fallbackLocale } = config.i18nConfig;
      const embed = getIframeParams(state);
      const locale = action.payload || embed?.language;

      if (locale) {
        if (locale in supportedLocales) {
          return of$(changeLocale({ selectedLang: locale }));
        } else {
          return of$(changeLocale({ selectedLang: fallbackLocale }));
        }
      }

      return of$(getUserLocale.request());
    })
  );
};

export const getBrowserLocaleSetting: _Store.IEpic = (
  action$,
  state$,
  { localeDetector }
) => {
  const { supportedLocales, fallbackLocale } = config.i18nConfig;

  return action$.pipe(
    filter$(isActionOf(getUserLocale.request)),
    mergeMap$(() => {
      return from$(
        localeDetector.init({
          fallbackLocale,
          supportedLocales: Object.keys(supportedLocales),
        })
      ).pipe(
        mergeMap$((data: string) => of$(getUserLocale.success(data))),
        catchError$((error: Error) => of$(getUserLocale.failure(error)))
      );
    })
  );
};

export const bindBrowserLocale: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getUserLocale.success)),
    mergeMap$((action) => {
      const browserLocale = action.payload;
      const localeSetting: ILocaleReducer = {
        selectedLang: browserLocale,
      };

      return of$(changeLocale(localeSetting));
    })
  );
};
