const getProperlyDateForDelta = (
  delta: number,
  month: number,
  year: number
): {
  month: number;
  year: number;
} => {
  let newMonth = month + delta;
  let newYear = year;

  if (newMonth < 0) {
    newYear -= 1;
    newMonth = 11;
  }

  if (newMonth > 11) {
    newYear += 1;
    newMonth = 0;
  }

  return {
    month: newMonth,
    year: newYear,
  };
};

export default getProperlyDateForDelta;
