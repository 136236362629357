import React from 'react';

import { IRedirectForFreePaymentProps } from './RedirectForFreePayment.types';

const RedirectForFreePayment = ({
  data,
  isBrowser,
  isPaymentAvailable,
  isCardPayment,
}: IRedirectForFreePaymentProps): null => {
  if (isPaymentAvailable && isBrowser && !isCardPayment) {
    window.location.replace(data.formUrl);
  }

  return null;
};

export default RedirectForFreePayment;
