const api = {
  api3Url: process.env.REACT_APP_API_3_URL,
  awsUrl:
    process.env.REACT_APP_AMAZON_SEARCH_API_URL ||
    'https://6ol5b7sjf7.execute-api.eu-west-1.amazonaws.com/prod/rundates/',
  baseUrl:
    typeof window === 'undefined'
      ? process.env.REACT_APP_API_URL_SSR || process.env.REACT_APP_API_URL
      : process.env.REACT_APP_API_URL,
  baseUrlV2: process.env.REACT_APP_API_V2_URL,
  cancellationFixDelay: 40,
  firebaseFunctionsUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS,
  formioUrl: process.env.REACT_APP_FORMIO_URL,
  keyCloakUrl:
    'https://keycloak.develop.k8s-jail.goingdev.goingapp.eu/auth/realms/going/',
  zagrywkiApi: process.env.REACT_APP_API_ZAGRYWKI_URL,
  zagrywkiApiV2: process.env.REACT_APP_API_ZAGRYWKI_V2_URL,
  zagrywkiProductsApi: process.env.REACT_APP_ZAGRYWKI_PRODUCTS_API,
  smsGateApi: process.env.REACT_APP_SMS_GATE,
};

export default api;
