import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { eventListBuyButtonClicked } from '@Model/analytics/actions';
import { isEnglish, translate } from '@Model/locale/selectors';

import BuyButton from './BuyButton.component';
import { IBuyButtonFromDispatch, IBuyButtonFromState } from './BuyButton.types';

const mapStateToProps = (state: _Store.IState): IBuyButtonFromState => ({
  isEnglish: isEnglish(state),
  lang: translate(state)('event'),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IBuyButtonFromDispatch => {
  return {
    buyButton: (data) => dispatch(eventListBuyButtonClicked(data)),
  };
};

export default connect<
  IBuyButtonFromState,
  IBuyButtonFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(BuyButton);
