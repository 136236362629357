import { connect } from 'react-redux';

import _Store from '@Store';

import calculateAngleForTriangleBox from '@Misc/helpers/calculateAngleForTriangleBox';
import { getSizes, isMobile } from '@Model/app/selectors';

import Triangle from './Triangle.component';
import { ITriangleFromState } from './Triangle.types';

const DEFAULT_ANGLE_DEG = 25;

const mapStateToProps = (state: _Store.IState): ITriangleFromState => {
  const isMobileValue = isMobile(state);

  return {
    angle: isMobileValue
      ? calculateAngleForTriangleBox(getSizes(state))
      : DEFAULT_ANGLE_DEG,
    isMobile: isMobileValue,
  };
};

export default connect<ITriangleFromState, void, {}, _Store.IState>(
  mapStateToProps
)(Triangle);
