import { CSSProperties } from 'react';

import { getBackgroundImageStyle } from '@Misc/helpers/css';
import isColorDark from '@Misc/helpers/isColorDark';
import { ILandingPage } from '@Model/landingPage/types';

const useLandingPageStyles = (
  isMobile: boolean,
  landingPage: ILandingPage | null
) => {
  const colors = landingPage?.colors;
  const body = landingPage?.colors.body;
  const primary = landingPage?.colors.primary;
  const secondary = landingPage?.colors.secondary;
  const transparent = 'transparent';
  const isDark = body ? isColorDark(body) : false;
  const swapPrimaryColor = landingPage?.colors.swapButtonColors
    ? secondary
    : primary;
  const swapSecondaryColor = landingPage?.colors.swapButtonColors
    ? primary
    : secondary;

  const isButtonOutline = landingPage?.header.buttonOutline;
  const isBodyTransparent = colors?.transparentBody;
  const isHeaderFixed = landingPage?.header.fixed;
  const isTitleCenter = landingPage?.titleCenter;
  const isTitleUppercase = landingPage?.uppercaseTitle;

  const headerImage = isMobile
    ? landingPage?.header.imageMobileUrl
    : landingPage?.header.imageUrl;

  const bodyScheme = {
    backgroundColor: isHeaderFixed && isBodyTransparent ? transparent : body,
  };

  const borderColorScheme = { borderColor: primary };

  const buttonScheme: CSSProperties = {
    backgroundColor: isButtonOutline ? transparent : swapPrimaryColor,
    borderColor: isButtonOutline ? swapPrimaryColor : swapPrimaryColor,
    color: isButtonOutline ? swapPrimaryColor : body,
  };

  const buttonHoverScheme: CSSProperties = {
    backgroundColor: isButtonOutline ? swapPrimaryColor : swapSecondaryColor,
    borderColor: isButtonOutline ? swapPrimaryColor : swapSecondaryColor,
    color: body,
  };

  const headerScheme: CSSProperties = {
    ...getBackgroundImageStyle(headerImage),
    backgroundColor: body,
  };

  const onButtonOut = (event: any) => {
    const button = event.target.style;
    button.background = buttonScheme.backgroundColor;
    button.borderColor = buttonScheme.borderColor;
    button.color = buttonScheme.color;
  };

  const onButtonOver = (event: any) => {
    const button = event.target.style;
    button.background = buttonHoverScheme.backgroundColor;
    button.borderColor = buttonHoverScheme.borderColor;
    button.color = buttonHoverScheme.color;
  };

  const cardTitleScheme: CSSProperties = {
    color: primary,
  };

  const sectionTitleScheme: CSSProperties = {
    color: primary,
    textAlign: isTitleCenter ? 'center' : 'left',
    textTransform: isTitleUppercase ? 'uppercase' : 'unset',
  };

  const timelineScheme: CSSProperties = {
    background: swapSecondaryColor,
  };

  const backgroundGradient: CSSProperties = {
    background: `linear-gradient(to right, ${primary}, ${secondary})`,
    color: primary,
  };

  return {
    backgroundGradient,
    bodyScheme,
    borderColorScheme,
    buttonScheme,
    cardTitleScheme,
    headerScheme,
    isDark,
    isHeaderFixed,
    onButtonOut,
    onButtonOver,
    sectionTitleScheme,
    timelineScheme,
  };
};

export default useLandingPageStyles;
