import { createSelector } from 'reselect';

import getData from './getData';
import getSelected from './getSelected';

const getSelectedSpaceProducts = createSelector(
  [getData, getSelected],
  (happening, selected) => {
    const space = happening?.spaces.find(
      (_space) => _space.id === selected.space
    );

    return space?.products;
  }
);

export default getSelectedSpaceProducts;
