import { connect } from 'react-redux';

import _Store from '@Store';

import TourList from '@Compo/Bricks/components/TourListBrick/TourList.component';
import { ITourListFromState } from '@Compo/Bricks/components/TourListBrick/TourList.types';
import { translate } from '@Model/locale/selectors';
import { getCompositionIdentifier } from '@Model/pages/selectors';
import { getLocation } from '@Model/router/selectors';

const mapStateToProps = (state: _Store.IState): ITourListFromState => ({
  isDark: getLocation(state).query?.isDark === 'true',
  lang: translate(state)('dates'),
  compositionIdentifier: getCompositionIdentifier(state),
});

export default connect(mapStateToProps)(TourList);
