import { selectors } from 'goingapp-shared';
import { createSelector } from 'reselect';

import getAvailabilitiesSelector from './getAvailabilitiesSelector';
import getData from './getData';
import getSelectedSlot from './getSelectedSlot';

const calculateCapacity = createSelector(
  [getAvailabilitiesSelector, getData, getSelectedSlot],
  (availabilities, data, selectedSlot) =>
    selectors.calculateCapacity(availabilities, data, selectedSlot)
);

export default calculateCapacity;
