import React, {
  CSSProperties,
  PureComponent,
  RefObject,
  createRef,
} from 'react';

import translateEngLink from '@/misc/helpers/translateEngLink';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import Link from '@Compo/reusable/ExternalLink';

import LoadingContent from './../LoadingContent';
import styles from './TitleResponsive.module.scss';
import muiStyles from './TitleResponsive.styles';
import { ITitleProps, ITitleState } from './TitleResponsive.types';

const fontSizes = [0.875, 1, 1.125, 1.25, 1.375, 1.5, 1.625, 1.75, 1.875];

class TitleResponsive extends PureComponent<ITitleProps, ITitleState> {
  public state = {
    fontSize: 0,
    lineHeight: 0,
    loading: true,
    ready: false,
  };

  protected wrapper: RefObject<HTMLDivElement> = createRef();

  protected sizeIndex = fontSizes.length - 1;
  protected forcedResize = false;

  public componentDidMount() {
    this.tryResize();
  }

  public componentDidUpdate(prevProps: ITitleProps) {
    if (prevProps.pageWidth !== this.props.pageWidth) {
      this.forcedResize = true;
      this.sizeIndex = fontSizes.length;
    }

    this.tryResize();
  }

  public render() {
    const { isEnglish, title, link: _link } = this.props;
    const link = translateEngLink(_link?.toString() || '', isEnglish);

    if (!this.props.shouldResize) {
      return (
        <div className={styles.mobileWrapper}>
          <h2 className={cn(styles.title, this.props.classes.fontColor)}>
            {link && (
              <Link
                className={this.props.classes.fontColor}
                to={link}
                onClick={this.props.onClick}
              >
                {title}
              </Link>
            )}
            {!link && title}
          </h2>
        </div>
      );
    }

    const style: CSSProperties = {};

    if (this.state.ready) {
      style.fontSize = `${this.state.fontSize}rem`;
      style.lineHeight = `${this.state.lineHeight}rem`;
    }

    return (
      <>
        {this.state.loading && (
          <div className={styles.loader}>
            <LoadingContent />
          </div>
        )}
        <div className={styles.desktopWrapper}>
          <div className={styles.scroller} ref={this.wrapper} style={style}>
            <h2 className={cn(styles.title, this.props.classes.fontColor)}>
              {link && (
                <Link
                  className={this.props.classes.fontColor}
                  to={link}
                  onClick={this.props.onClick}
                >
                  {title}
                </Link>
              )}
              {!link && title}
            </h2>
          </div>
        </div>
      </>
    );
  }

  protected resize() {
    if (this.wrapper?.current) {
      const element = this.wrapper.current;

      if (element.offsetHeight < element.scrollHeight || this.forcedResize) {
        --this.sizeIndex;
        this.forcedResize = false;

        if (this.sizeIndex < 0) {
          this.hideLoader();

          return;
        }

        this.setState({
          fontSize: fontSizes[this.sizeIndex],
          lineHeight: fontSizes[this.sizeIndex] + 0.125,
          loading: true,
          ready: true,
        });
      } else {
        this.hideLoader();
      }
    }
  }

  protected hideLoader() {
    if (this.state.loading) {
      this.setState({
        loading: false,
      });
    }
  }

  protected tryResize() {
    if (!this.props.shouldResize) {
      return;
    }

    setTimeout(() => {
      this.resize();
    });
  }
}

export default withStyles(muiStyles, { withTheme: true })(TitleResponsive);
