import { Selector } from 'reselect';

import _Store from '@Store';

import { ILandingPageReducer } from './../types';

const get: Selector<_Store.IState, ILandingPageReducer> = (state) =>
  state.landingPage;

export default get;
