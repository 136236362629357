import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      borderRadius: '0.75rem',
      paddingBottom: '0.75rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      paddingTop: '0.75rem',
      fontWeight: 500,
      fontSize: '0.875rem',
      minWidth: '6.25rem',
    },
    buttonText: {
      textTransform: 'unset',
    },
    disabled: {
      opacity: 0.4,
    },
  })
);

export default useStyles;
