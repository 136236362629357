import { connect } from 'react-redux';

import _Store from '@Store';

import { getLocation } from '@Model/router/selectors';

import map from './InteractiveMap.component';
import { IInteractiveMapFromState } from './InteractiveMap.types';

const mapStateToProps = (state: _Store.IState): IInteractiveMapFromState => ({
  isDarkTheme: Boolean(getLocation(state).query?.isDark),
});

export default connect<IInteractiveMapFromState, null, {}, _Store.IState>(
  mapStateToProps
)(map);
