import { MouseEvent } from 'react';

import axios from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import prepareEventLink from '@Misc/helpers/prepareEventLink';
import {
  IBuyButtonAnalytics,
  IEventAddToCart,
  IHappeningAddToCart,
  ISearchFilters,
  ISelectItemEvent,
} from '@Model/analytics/types';
import { IBasket } from '@Model/basket/types';
import { IErrorsDefinition } from '@Model/errors/types';
import { IEventFull } from '@Model/event/types';
import { IEvent } from '@Model/events/types';
import { IPoolSelectedTicket } from '@Model/pools/types';
import { IInsuranceSummaryData } from '@Model/products/types';
import { IGetTransactionInfoSuccessPayload } from '@Model/transaction/types';

import versionJson from '../../../package.json';
import {
  IBuyingProcessPayload,
  IEcommerceObject,
  ITransactionCompleteLayer,
  ITransactionDetailsResponse,
  ITransactionInfoResponse,
  ITransactionItem,
  ITransactionProduct,
} from './types';

const DEFAULT_EVENT_LIST = 'Events';
const DEFAULT_CURRENCY = 'PLN';
const CALENDARY_TEXT = 'Kalendarium';

class Analytics {
  public static getTransactionInfoUrl(transactionId: string) {
    return `${config.api.baseUrl}transaction-info/${transactionId}`;
  }

  public static getTransactionDetailsUrl(transactionId: string) {
    return `${config.api.baseUrl}transaction-details/${transactionId}`;
  }

  public ecommerceObject: IEcommerceObject = {
    item_id: '',
    item_name: '',
    currency: DEFAULT_CURRENCY,
    index: 0,
    discount: 0,
    coupon: '',
    item_brand: '',
    item_category: '',
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: '',
    item_variant: 0,
    price: 0,
    quantity: 0,
  };

  private pageViewLayer = {
    event: 'PageView',
  };

  private transactionCompleteLayer = {
    event: 'transactionComplete',
  };

  private transactionCompleteEmbedLayer = {
    event: 'transactionCompleteEmbed',
  };

  private errorLayer = {
    event: '',
    eventAction: 'Page View',
    eventCategory: '',
    eventNonInteractive: '',
    eventURL: '',
    eventValue: '',
  };

  public constructor() {
    if (typeof window !== 'undefined' && !window.dataLayer) {
      window.dataLayer = [] as unknown as {
        push: (layer: object) => void;
      };
    }
  }

  public getTransactionInfo(
    transactionId: string
  ): Promise<ITransactionInfoResponse> {
    return new Promise((resolve, reject) => {
      const transactionInfoUrl = Analytics.getTransactionInfoUrl(transactionId);

      axios
        .get(transactionInfoUrl)
        .then(getData)
        .then((response: ITransactionInfoResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getTransactionDetails(
    transactionId: string
  ): Promise<ITransactionDetailsResponse> {
    return new Promise((resolve, reject) => {
      const transactionDetailsUrl =
        Analytics.getTransactionDetailsUrl(transactionId);

      axios
        .get(transactionDetailsUrl)
        .then(getData)
        .then((response: ITransactionDetailsResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public pushPageViewOld() {
    this.addToDataLayer(this.pageViewLayer);
  }

  public pushPageView(
    isEmbed: boolean,
    previousUrl?: string,
    embedEventPartnerId?: number,
    embedHostname?: string
  ) {
    const layer = {
      event: 'page_view',
      page: {
        info: {
          name: document.title,
          url: window.location.href,
          path: window.location.pathname,
          referrer: previousUrl,
          environment: process.env.NODE_ENV,
          hostname: isEmbed ? embedHostname : window.location.hostname,
        },
        attributes: {
          is_widget_embed: isEmbed ? 'true' : 'false',
          widget_id: undefined,
          widget_version: versionJson.version || '',
          host_id: embedEventPartnerId,
          host_name: isEmbed ? embedHostname : window.location.hostname,
        },
      },
      user: {
        profile: {
          id: undefined,
          email: undefined,
          type: undefined,
          crm_status: undefined,
          is_app: undefined,
        },
        attributes: {
          lt_transactions: undefined,
          ltv: undefined,
          account_age: undefined,
          account_created_date: undefined,
        },
      },
    };
    this.addToDataLayer(layer);
  }

  public pushBannerClick(label: string) {
    const layer = {
      event: 'click',
      eventCategory: 'promo-banner',
      eventLabel: label,
    };

    this.addToDataLayer(layer);
  }

  /**
   * This is dedicated for buying process. We mix 'PageView' with data
   * about event.
   */
  public pushForBuyingProcessOld(layer: IBuyingProcessPayload) {
    this.addToDataLayer(this.pageViewLayer, layer);
  }

  public pushForTransactionCompletion(
    layer: ITransactionCompleteLayer,
    isEmbed?: boolean
  ) {
    this.addToDataLayer(
      isEmbed
        ? this.transactionCompleteEmbedLayer
        : this.transactionCompleteLayer,
      layer
    );
  }

  public pushForErrorModal(url: string, error: IErrorsDefinition) {
    const layer = {
      event: 'GAEventErrorModal',
      eventAction: `${error.id} | ${error.code}`,
      eventCategory: 'Kod błędu i komunikat',
      eventLabel: `${error.message}`,
      eventURL: url,
    };

    this.addToDataLayer(this.errorLayer, layer);
  }

  public pushForErrorWhenSomethingWrong(url: string) {
    const layer = {
      event: 'GAEventErrorUPS',
      eventCategory: 'Error UPS',
      eventLabel: url,
    };

    this.addToDataLayer(this.errorLayer, layer);
  }

  public pushForError404(url: string) {
    const layer = {
      event: 'GAEventError404',
      eventCategory: 'Error 404',
      eventLabel: url,
    };

    this.addToDataLayer(this.errorLayer, layer);
  }

  public pushForEventsList(
    events: IEvent[],
    offset: number,
    item_list_id: string,
    item_list_name: string,
    isPaginationDisabled?: boolean
  ) {
    this.addToDataLayer({
      event: 'view_item_list',
      event_eventInfo_detail: 'product list viewed',
      event_category_event_type: 'interaction',
      event_category_primary_category: 'ecommerce',
      item_list_id,
      item_list_name,
      ecommerce: {
        items: events.map((event, position) => ({
          item_brand: event.partner,
          item_category: !!event.tags.length ? event.tags[0].name : '',
          item_id: event.eventSlug,
          item_list_name: CALENDARY_TEXT,
          item_name: event.title,
          position,
          index: isPaginationDisabled
            ? position + 1
            : position + 1 + (offset - 5),
          price: event.price,
          item_variant: event.id,
        })),
      },
    });
  }

  public pushForEventListBuyPressed(
    event: IBuyButtonAnalytics,
    searchPhrase: string
  ) {
    const eventPageUrl = prepareEventLink(event.eventSlug, event.rundateSlug);
    const layer = {
      ecommerce: {
        click: {
          actionField: {
            list: searchPhrase || DEFAULT_EVENT_LIST,
          },
          products: [
            {
              brand: event.partner,
              category: !!event.tags?.length ? event.tags[0]?.name : '',
              id: event.eventSlug,
              name: event.title,
              position: event.position,
              price: event.price,
              variant: event.id,
            },
          ],
        },
      },
      event: 'productClick',
    };

    this.addToDataLayer(layer);
  }

  public pushForEventPage(
    event: IEventFull,
    isEmbed?: boolean,
    addToCart?: boolean
  ) {
    const miliSecondToHours = 60 * 60 * 1000;
    const timeShift = 2 * miliSecondToHours;
    const howLongToEvent =
      Number(new Date(event.startDate)) - Number(new Date()) - timeShift;
    const products = [
      {
        brand: `${event.partnerName}|${event.partnerId}`,
        category: !!event.tags.length ? event.tags[0]?.name : '',
        dimension1: `${event.placeFriendly}|${event.placeId}`,
        dimension2: `${event.placeCityName}`,
        dimension4: `${(howLongToEvent / miliSecondToHours).toFixed(2)} h`,
        dimension5: `${event.friendlyDate}|${event.friendlyHour}`,
        dimension8: isEmbed ? 'embed' : 'no-embed',
        id: event.eventId,
        name: event.title,
        price: event.price,
        variant: event.rundateId,
      },
    ];
    if (addToCart) {
      const layer = {
        ecommerce: {
          add: {
            products,
          },
          currencyCode: DEFAULT_CURRENCY,
        },
        event: 'addToCart',
      };
      this.addToDataLayer(layer);
    } else {
      const layer = {
        ecommerce: {
          currencyCode: DEFAULT_CURRENCY,
          detail: {
            actionField: {
              list: `Events`,
            },
            products,
          },
        },
        event: 'viewProduct',
      };
      this.addToDataLayer(layer);
    }
  }

  public pushForBuyPage(
    event: IEventFull,
    isEmbed: boolean,
    step: number,
    tickets: number,
    pools: string
  ) {
    const miliSecondToHours = 60 * 60 * 1000;
    const timeShift = 2 * miliSecondToHours;
    const howLongToEvent =
      Number(new Date(event.startDate)) - Number(new Date()) - timeShift;
    const layer = {
      ecommerce: {
        checkout: {
          actionField: {
            step,
          },
          products: [
            {
              brand: `${event.partnerName}|${event.partnerId}`,
              category: !!event.tags.length ? event.tags[0]?.name : '',
              dimension1: `${event.placeFriendly}|${event.placeId}`,
              dimension10: pools,
              dimension2: `${event.placeCityName}`,
              dimension4: `${(howLongToEvent / miliSecondToHours).toFixed(
                2
              )} h`,
              dimension5: `${event.friendlyDate}|${event.friendlyHour}`,
              dimension8: isEmbed ? 'embed' : 'no-embed',
              id: event.eventId,
              name: event.title,
              price: event.price,
              quantity: tickets,
              variant: event.rundateId,
            },
          ],
        },
        currencyCode: DEFAULT_CURRENCY,
      },
      event: 'checkout',
    };

    this.addToDataLayer(layer);
  }

  public pushForViewEvent(event: IEventFull, isEmbed: boolean) {
    const layer = {
      event: 'view_item',
      eventInfo: {
        detail: 'product detail viewed',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed ? 'true' : 'false',
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: event.partnerId,
        host_name: window.location.hostname,
        tags: event.tags.map((tag) => tag.name).join(','),
      },
      ecommerce: {
        items: [
          {
            item_id: String(event.eventId),
            item_name: event.title,
            currency: DEFAULT_CURRENCY,
            index: 0,
            item_brand: `${event.partnerName}|${event.partnerId}`,
            item_category: `${event.placeFriendly}|${event.placeId}`,
            item_category2: !!event.tags.length ? event.tags[0].name : '',
            item_category3: !!event.artists?.length
              ? event.artists[0].name
              : '',
            item_category4: '',
            item_category5: '',
            item_variant: event.rundateId,
            price: Number(event.price),
            quantity: event.rundatesCount,
          },
        ],
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForAddToCartEvent({
    isEmbed,
    payload,
    isInsurance,
    numOfInsuredTickets,
    event,
    isActivity,
  }: {
    isEmbed: boolean;
    payload: IEventAddToCart | IHappeningAddToCart;
    isInsurance?: boolean;
    numOfInsuredTickets?: number;
    event?: IEventFull;
    isActivity?: boolean;
  }) {
    const layer = {
      event: 'add_to_cart',
      eventInfo: {
        detail: 'add to cart',
        text_displayed: '',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed ? 'true' : 'false',
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: event?.partnerId,
        host_name: window.location.hostname,
        tags: event?.tags.map((tag) => tag.name).join(','),
      },
      ecommerce: {
        items: [
          {
            item_id: String(isInsurance ? undefined : event?.eventId),
            item_name: event?.title,
            currency: DEFAULT_CURRENCY,
            index: 0,
            discount: 0,
            coupon: '',
            item_brand: isInsurance
              ? undefined
              : `${event?.partnerName}|${event?.partnerId}`,
            item_category: isInsurance
              ? 'insurance'
              : `${event?.placeFriendly}|${event?.placeId}`,
            item_category2: isInsurance
              ? ''
              : !!event?.tags.length
              ? event?.tags[0].name
              : '',
            item_category3: !!event?.artists?.length
              ? event.artists[0].name
              : '',
            item_category4: '',
            item_category5: isInsurance ? event?.eventId : '',
            item_variant: isInsurance ? undefined : event?.rundateId,
            price: isInsurance
              ? Number(payload?.price)
              : Number(isActivity ? payload.price : event?.price),
            quantity: isInsurance ? numOfInsuredTickets : 1,
          },
        ],
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForRemoveFromCartEvent({
    isEmbed,
    payload,
    isInsurance,
    numOfInsuredTickets,
    event,
    isActivity,
  }: {
    isEmbed: boolean;
    payload: IEventAddToCart | IHappeningAddToCart;
    isInsurance?: boolean;
    numOfInsuredTickets?: number;
    event?: IEventFull;
    isActivity?: boolean;
  }) {
    const layer = {
      event: 'remove_from_cart',
      eventInfo: {
        detail: 'remove from cart',
        text_displayed: '',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed ? 'true' : 'false',
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: event?.partnerId,
        host_name: window.location.hostname,
        tags: event?.tags.map((tag) => tag.name).join(','),
      },
      ecommerce: {
        items: [
          {
            item_id: String(isInsurance ? undefined : event?.eventId),
            item_name: event?.title,
            currency: DEFAULT_CURRENCY,
            index: 0,
            discount: 0,
            coupon: '',
            item_brand: isInsurance
              ? undefined
              : `${event?.partnerName}|${event?.partnerId}`,
            item_category: isInsurance
              ? 'insurance'
              : `${event?.placeFriendly}|${event?.placeId}`,
            item_category2: isInsurance
              ? ''
              : !!event?.tags.length
              ? event?.tags[0].name
              : '',
            item_category3: !!event?.artists?.length
              ? event.artists[0].name
              : '',
            item_category4: '',
            item_category5: isInsurance ? event?.eventId : '',
            item_variant: isInsurance ? undefined : event?.rundateId,
            price: isInsurance
              ? Number(payload?.price)
              : Number(isActivity ? payload.price : event?.price),
            quantity: isInsurance ? numOfInsuredTickets : 1,
          },
        ],
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForAddPaymentInfo(
    paymentMethod: string,
    finalPrice: number,
    buttonLabel: string,
    isEmbed: boolean,
    event?: IEventFull,
    basketData?: IBasket[],
    selectedTickets?: IPoolSelectedTicket[],
    insuranceSummary?: IInsuranceSummaryData
  ) {
    const itemsArray: IEcommerceObject[] = [];

    if (insuranceSummary?.isSelected && event) {
      const insuranceProduct = {
        ...this.ecommerceObject,
        item_id: undefined,
        item_name: event.title,
        item_brand: undefined,
        item_category: 'insurance',
        item_variant: undefined,
        price: insuranceSummary.value ? insuranceSummary.value : 0,
        quantity: 1,
      };

      itemsArray.push(insuranceProduct);
    }

    if (event && !!selectedTickets?.length) {
      const selectedPools = selectedTickets.map((pool) => ({
        ...this.ecommerceObject,
        item_id: String(event.eventId),
        item_name: event.title,
        item_brand: `${event.partnerName}|${event.partnerId}`,
        item_category: `${event.placeFriendly}|${event.placeId}`,
        item_category2: !!event.tags.length ? event.tags[0].name : '',
        item_category3: !!event.artists.length ? event.artists[0].name : '',
        item_variant: event.rundateId,
        item_ticket_pool_id: pool.poolId,
        price: pool.price || 0,
        quantity: pool.amount,
      }));

      itemsArray.push(...selectedPools);
    }

    if (basketData && basketData.length > 0) {
      const basketPools = basketData
        .map((basketEvent) =>
          basketEvent.pools.map((pool) => ({
            ...this.ecommerceObject,
            item_id: String(basketEvent.eventId),
            item_name: basketEvent.title ? basketEvent.title : '',
            item_brand: `${basketEvent.partnerName}|${basketEvent.partnerId}`,
            item_category: `${basketEvent.placeFriendly}|${basketEvent.placeId}`,
            item_category2: !!basketEvent?.tags?.length
              ? basketEvent.tags[0].name
              : '',
            item_category3: !!basketEvent.artists?.length
              ? basketEvent.artists[0].name
              : '',
            item_variant: basketEvent.rundateId || 0,
            price: pool.poolPrice || 0,
            quantity: pool.amount,
          }))
        )
        .flat();

      itemsArray.push(...basketPools);
    }

    const layer = {
      event: 'add_payment_info',
      eventInfo: {
        detail: 'proceed to payment operator',
        text_displayed: buttonLabel,
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed,
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: event?.partnerId,
        host_name: window.location.hostname,
        tags: event?.tags.map((tag) => tag.name).join(','),
      },
      ecommerce: {
        currency: DEFAULT_CURRENCY,
        value: finalPrice,
        payment_type: paymentMethod ? paymentMethod : '',
        items: itemsArray.map((item, index) => ({ ...item, index })),
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForPurchase(
    isEmbed: boolean,
    transactionInfo: IGetTransactionInfoSuccessPayload,
    transactionItems?: ITransactionItem[],
    transactionInsurance?: ITransactionProduct[]
  ) {
    const itemsArray: IEcommerceObject[] = [];

    if (transactionItems && transactionItems.length > 0) {
      const productsArray = transactionItems.map((product, index) => ({
        ...this.ecommerceObject,
        item_name: product.productName,
        price: product.price,
        quantity: product.quantity,
        item_variant: Number(transactionInfo.products.variant),
        item_brand: transactionInfo.products.brand,
        item_ticket_pool_id: Number(transactionInfo.products.dimension10),
        item_category: transactionInfo.products.dimension1,
        item_category2: transactionInfo.products.dimension7,
        item_category3: product.artists?.join(',') || '',
        item_id: product.eventId?.toString() || '',
        currency: product.currency,
        index,
      }));

      itemsArray.push(...productsArray);
    }

    if (transactionInsurance && transactionInsurance.length > 0) {
      const insuranceArray = transactionInsurance.map((insurance) => ({
        ...this.ecommerceObject,
        item_name: insurance.productName,
        price: insurance.price,
        quantity: insurance.quantity,
      }));

      itemsArray.push(...insuranceArray);
    }

    const layer = {
      event: 'purchase',
      eventInfo: {
        detail: 'purchase',
        text_displayed: '',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'ecommerce',
      },
      attributes: {
        is_widget_embed: isEmbed ? 'true' : 'false',
        widget_id: undefined,
        widget_version: versionJson.version || '',
        host_id: undefined,
        host_name: window.location.hostname,
        tags: [
          transactionInfo.products.category,
          transactionItems &&
            transactionItems?.map((transactionItem) =>
              transactionItem.tags.join(',')
            ),
        ].join(','), // TODO: fix when API updated
      },
      ecommerce: {
        transaction_id: `${transactionInfo.id}`,
        value: transactionInfo.revenue,
        tax: transactionInfo.tax ? transactionInfo.tax : 0,
        shipping: transactionInfo.shipping,
        currency: DEFAULT_CURRENCY,
        coupon: transactionInfo.coupon,
        items: itemsArray,
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForSearch(
    phrase: string,
    results: number,
    tileView: boolean,
    searchFiltersFormatted: ISearchFilters,
    firstDateFormatted: string,
    secondDateFormatted: string
  ) {
    const date =
      firstDateFormatted === secondDateFormatted
        ? firstDateFormatted
        : `${firstDateFormatted} - ${secondDateFormatted}`;

    const layer = {
      event: 'search',
      eventInfo: {
        detail: 'search',
        text_displayed: 'bar',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'search',
      },
      attributes: {
        search_destination_url: window.location.href,
        search_phrase: phrase,
        search_results: results,
        search_view: tileView ? 'no titles' : 'titles',
        search_date: date,
        search_category: searchFiltersFormatted.event_category_name || '',
        search_tag: searchFiltersFormatted.public_tags_names || '',
        search_city: searchFiltersFormatted.locations_names || '',
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForSelectItemEvent(
    phrase: string,
    results: number,
    tileView: boolean,
    searchFiltersFormatted: ISearchFilters,
    firstDateFormatted: string,
    secondDateFormatted: string,
    event: ISelectItemEvent
  ) {
    const date =
      firstDateFormatted === secondDateFormatted
        ? firstDateFormatted
        : `${firstDateFormatted} - ${secondDateFormatted}`;

    const layer = {
      event: 'select_item',
      eventInfo: {
        detail: 'search item select',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'search',
      },
      attributes: {
        search_destination_url: event.url ? event.url : '',
        search_phrase: phrase,
        search_results: results,
        search_view: tileView ? 'no titles' : 'titles',
        search_date: date,
        search_category: searchFiltersFormatted.event_category_name || '',
        search_tag: searchFiltersFormatted.public_tags_names || '',
        search_city: searchFiltersFormatted.locations_names || '',
      },
      ecommerce: {
        items: [
          {
            item_id: undefined,
            item_name: event ? event.title : '',
            currency: DEFAULT_CURRENCY,
            index: 0,
            item_brand: event ? event.partner : '',
            item_category: event ? `${event.place}|${event.placeId}` : '',
            item_category2: !!event.tags?.length ? event?.tags[0].name : '',
            item_category3: '',
            item_category4: '',
            item_category5: '',
            item_list_name: 'search_results',
            item_variant: event ? Number(event.rundate_id) : undefined,
            price: event ? Number(event.price) : 0,
            quantity: 1,
          },
        ],
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForHomepageSelectItem(
    clickEvent: MouseEvent<HTMLElement>,
    destinationUrl: string,
    textDisplayed: string,
    componentName?: string
  ) {
    clickEvent.stopPropagation();

    this.addToDataLayer({
      event: 'interaction',
      eventInfo: {
        detail: 'select_item',
        text_displayed: textDisplayed,
        component_name: componentName || '',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'navigation',
      },
      attributes: {
        destination_url: destinationUrl,
      },
    });
  }

  public pushForSearchView(
    phrase: string,
    results: number,
    tileView: boolean,
    searchFiltersFormatted: ISearchFilters,
    firstDateFormatted: string,
    secondDateFormatted: string
  ) {
    const date =
      firstDateFormatted === secondDateFormatted
        ? firstDateFormatted
        : `${firstDateFormatted} - ${secondDateFormatted}`;

    const layer = {
      event: 'search',
      eventInfo: {
        detail: 'search_view',
        text_displayed: tileView ? 'no titles' : 'titles',
      },
      category: {
        event_type: 'interaction',
        primary_category: 'search',
      },
      attributes: {
        search_destination_url: window.location.href,
        search_phrase: phrase,
        search_results: results,
        search_view: tileView ? 'no titles' : 'titles',
        search_date: date,
        search_category: searchFiltersFormatted.event_category_name || '',
        search_tag: searchFiltersFormatted.public_tags_names || '',
        search_city: searchFiltersFormatted.locations_names || '',
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForSearchFilter(
    phrase: string,
    results: number,
    tileView: boolean,
    searchFiltersFormatted: ISearchFilters,
    firstDateFormatted: string,
    secondDateFormatted: string,
    lastFilterName?: string
  ) {
    const date =
      firstDateFormatted === secondDateFormatted
        ? firstDateFormatted
        : `${firstDateFormatted} - ${secondDateFormatted}`;

    const layer = {
      event: 'search',
      eventInfo: {
        detail: 'search_filter',
        text_displayed: lastFilterName,
      },
      category: {
        event_type: 'interaction',
        primary_category: 'search',
      },
      attributes: {
        search_destination_url: window.location.href,
        search_phrase: phrase,
        search_results: results,
        search_view: tileView ? 'no titles' : 'titles',
        search_date: date,
        search_category: searchFiltersFormatted.event_category_name || '',
        search_tag: searchFiltersFormatted.public_tags_names || '',
        search_city: searchFiltersFormatted.locations_names || '',
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForProceed(textDisplayed: string) {
    const layer = {
      event: 'proceed',
      eventInfo: {
        detail: 'proceed to checkout',
        text_displayed: textDisplayed,
      },
      category: {
        event_type: 'interaction',
        primary_category: 'transaction',
      },
      attributes: {
        destination_url: window.location.href,
      },
      transaction: {
        type: 'buy',
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForAutoFillData(textDisplayed: string) {
    const layer = {
      event: 'interaction',
      eventInfo: {
        detail: 'checkout',
        text_displayed: textDisplayed,
      },
      category: {
        event_type: 'interaction',
        primary_category: 'checkout',
      },
    };

    this.addToDataLayer(layer);
  }

  public pushForDeeplinkWeb(
    textDisplayed: string,
    destinationType: string,
    destinationUrl: string
  ) {
    const layer = {
      event: 'interaction',
      eventInfo: {
        detail: 'choose service version',
        text_displayed: textDisplayed,
      },
      category: {
        event_type: 'interaction',
        primary_category: 'product_page',
      },
      attributes: {
        destination_url: destinationUrl,
        destination_type: destinationType,
      },
    };

    this.addToDataLayer(layer);
  }

  /**
   * Sends information about newsletter subscription to data layer.
   *
   * @param {string} email - SHA256 hashed mail
   * @param buttonText - button label from translations
   */
  public pushNewsletterSubscription(email: string, buttonText: string) {
    this.addToDataLayer({
      event: 'newsletter_subscription',
      eventInfo: {
        detail: 'checkout checkbox',
        text_displayed: buttonText,
      },
      category: {
        event_type: 'newsletter_subscription',
        primary_category: 'checkout',
      },
      user: {
        profile: {
          email,
        },
      },
    });
  }

  public pushAddToFavorites = ({
    destination_url,
    item_id,
    item_name,
    email,
  }: {
    destination_url: string;
    item_id: number;
    item_name: string;
    email: string;
  }) => {
    this.addToDataLayer({
      event: 'wishlist_add',
      eventInfo: {
        detail: 'add event to wishlist',
      },
      category: {
        event_type: 'wishlist_add',
        primary_category: 'wishlist',
      },
      attributes: {
        destination_url,
        item_id,
        item_name,
      },
      user: {
        profile: {
          email,
        },
      },
    });
  };

  protected isDataLayerEnabled() {
    return typeof window !== 'undefined' && window.dataLayer;
  }

  protected addToDataLayer(commonLayer: object, layer?: object) {
    if (this.isDataLayerEnabled()) {
      window.dataLayer.push({
        ecommerce: null,
      });
      window.dataLayer.push({
        ...commonLayer,
        ...layer,
      });
    }
  }
}

export default new Analytics();
