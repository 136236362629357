import { useEffect, useState } from 'react';

export const useIsWebview = () => {
  const [isWebview, setIsWebview] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const isWebviewInQuery = query.get('isWebview') === 'true';

    if (!!window.ReactNativeWebView || isWebviewInQuery) {
      setIsWebview(true);
    }
  }, []);

  return {
    isWebview,
  };
};
