import { IEventTerm } from '@/models/event/types';
import { getType } from 'typesafe-actions';

import { saveTerms } from './../../actions';
import { IAction } from './../../types';

const reducer = (state: IEventTerm[] | null = null, action: IAction) => {
  switch (action.type) {
    case getType(saveTerms.success):
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
