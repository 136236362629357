import React, { useCallback, useContext, useEffect } from 'react';

import { Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import cn from 'classnames';
import { Field, useFormikContext } from 'formik';
import debounce from 'lodash.debounce';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import { IBuyingOnlineFormValues } from '@Compo/buying/Online/Online.types';
import Checkbox from '@Compo/forms/Checkbox';
import FormField from '@Compo/forms/FormField';
import config from '@Config';
import icons from '@Misc/helpers/icons';
import useUpdateEffect from '@Misc/hooks/useUpdateEffect';
import { specialEventNames } from '@Model/event/constants/specialEvents';

import EmpikCardNumber from './../EmpikCardNumber';
import Invoice from './../Invoice';
import ToggleField from './../ToggledField';
import classes from './ClientData.classes';
import styles from './ClientData.module.scss';
import useStyles from './ClientData.styles';
import { IClientDataProps } from './ClientData.types';

const SPECIAL_EVENTS_TEXT = 'Potwierdzenie zakupu wyślemy na wskazany adres.';
const AUTOCOMPLETE_ID = 'autocomplete';
const CALCULATE_DISCOUNT_TEXT = 'Sprawdź zniżkę';
const CALCULATE_PREPAID_TEXT = 'Zrealizuj kod';

// TODO: Temporary feature 05.05.2022
const RUN_DATE_ID = 2324719;

const ClientData = ({
  autocompleteForm,
  autoFill,
  calculatePrice,
  customTerms,
  currentBasketData,
  currentKey,
  checkEmail,
  emailValidation,
  hasDependency,
  handleTokenizeCard,
  isBasketItems,
  isBasketPay,
  isDisabled,
  lang,
  lastData,
  number,
  requestDataFromFacebook,
  setFieldValue,
  showInvoiceForm,
  showPremiumCardInput,
  showPassForm,
  slotsSale,
  specialEventCopy,
  selected,
  setDiscountState,
  setPrepaidState,
  generateIdempotencyKey,
  isWebview,
  selectedTheme,
  specialEvent,
  selectedSpace,
  // TODO: Temporary feature 05.05.2022
  poolsData,
  eventAutoFillData,
  payuInstance,
  notify,
  isProductsPay,
  eventData,
}: IClientDataProps) => {
  const SALTOS_ID = 2459;
  const GIFT_CARDS_RUN_DATES = [2353795, 2357465];
  const isGiftCard = GIFT_CARDS_RUN_DATES.includes(poolsData?.id || 0);
  // TODO: Temporary feature 05.05.2022
  const theme = useTheme();
  const muiStyles = useStyles(theme);
  const BagState = useContext(clearContext);
  const basketItems = BagState?.basketItems || [];
  const { values } = useFormikContext<IBuyingOnlineFormValues>();
  const firstNameAdditional = Object.values(
    values.firstnameAdditional || {}
  ).length;

  const isEmpikPremiumEnabled =
    config.app.showUnfinishedFeatures ||
    RUN_DATE_ID === poolsData.id ||
    specialEvent === specialEventNames.aliceCooperRundate ||
    specialEvent === specialEventNames.shaggyRundate ||
    specialEvent === specialEventNames.festFestival2022Rundate ||
    specialEvent === specialEventNames.onAirFestival ||
    specialEvent === specialEventNames.igoWroclaw ||
    specialEvent === specialEventNames.igoLodz ||
    specialEvent === specialEventNames.igoGdansk ||
    specialEvent === specialEventNames.igoBydgoszcz ||
    specialEvent === specialEventNames.igoPoznan ||
    specialEvent === specialEventNames.igoKrakow ||
    specialEvent === specialEventNames.igoWarszawa ||
    specialEvent === specialEventNames.dreamTheater ||
    specialEvent === specialEventNames.jimekIGoscie ||
    specialEvent === specialEventNames.mataSzczecin ||
    specialEvent === specialEventNames.mataGliwice ||
    specialEvent === specialEventNames.radzimirWarszawa1 ||
    specialEvent === specialEventNames.radzimirWarszawa2 ||
    specialEvent === specialEventNames.radzimirWroclaw ||
    specialEvent === specialEventNames.radzimirLuslawice ||
    specialEvent === specialEventNames.hey30 ||
    specialEvent === specialEventNames.hey30SecondDate;

  const showAutocompleteButton =
    lastData && lastData.email && lastData.firstname && lastData.lastname;
  const upsellFromState = selected.upsell?.isSelected;
  const isDefaultTheme = selectedTheme === 'default';
  const isEventWithoutInvoice =
    currentKey === 'bilet-wsparcia-dla-ukrainy/luty-2022';
  const isDefaultCurrency = ![...currentBasketData, ...basketItems].some(
    (item) => item.currency !== config.app.defaultCurrency
  );
  const showInvoice = !isEventWithoutInvoice;
  const emailCheck = useCallback(debounce(checkEmail, 1000), []);

  const autocompleteClicked = () => {
    if (autocompleteForm) {
      autocompleteForm();
      eventAutoFillData(lang.fillFormWithLastData);
    }

    const autocompleteElement = document.getElementById(AUTOCOMPLETE_ID);
    if (autocompleteElement) {
      autocompleteElement.style.height = '0';
      autocompleteElement.style.margin = '0';
      autocompleteElement.innerHTML = '';
      autocompleteElement.style.padding = '0';
    }
  };

  const prepaidText = !slotsSale ? lang.iHavePrepaidGoing : lang.iHavePrepaid;
  const prepaidTextUnavailable = config.theme.isGoing
    ? !slotsSale
      ? lang.iHavePrepaidGoing + ' ' + lang.discountUnavailableForBasketSales
      : lang.iHavePrepaid + lang.discountUnavailableForBasketSales
    : !slotsSale
    ? lang.iHavePrepaidGoing + ' ' + lang.discountUnavailableForBasketSalesEmpik
    : lang.iHavePrepaid + lang.discountUnavailableForBasketSalesEmpik;

  const facebookIconClicked = () => requestDataFromFacebook(setFieldValue);

  const calculateWithDiscount = async (
    code: string,
    type: 'discount' | 'prepaid'
  ) => {
    if (handleTokenizeCard && payuInstance) {
      const tokenize = await handleTokenizeCard(payuInstance);

      if (tokenize === 'ERROR') {
        return;
      }
    }

    type === 'discount' ? setDiscountState(code) : setPrepaidState(code);
    generateIdempotencyKey();

    type === 'discount'
      ? calculatePrice({
          discountCode: code,
          upsell: upsellFromState,
          emailAddress: values.email,
          paymentMethod: values.paymentMethod.type,
        })
      : calculatePrice({
          prepaidCard: code,
          upsell: upsellFromState,
          emailAddress: values.email,
          paymentMethod: values.paymentMethod.type,
        });
  };

  useEffect(() => {
    if (autoFill) {
      setTimeout(() => autocompleteClicked(), 100);
    }
  }, [customTerms]);

  useUpdateEffect(() => {
    if (
      (!values.discountCheckbox && values.discount) ||
      isBasketItems ||
      isBasketPay
    ) {
      setFieldValue('discount', '');
      calculateWithDiscount('', 'discount');
    }
  }, [values.discountCheckbox, isBasketItems]);

  useUpdateEffect(() => {
    if (
      (!values.prepaidCheckbox && values.prepaidCard) ||
      isBasketItems ||
      isBasketPay
    ) {
      setFieldValue('prepaidCard', '');
      calculateWithDiscount('', 'prepaid');
    }
  }, [values.prepaidCheckbox, isBasketItems]);

  useEffect(() => {
    setFieldValue('hasDependency', hasDependency);
  }, [hasDependency, firstNameAdditional]);
  // TODO: find a better way to fix the bug from WEAPP-3798

  useEffect(() => {
    if (values.email) {
      emailCheck(values.email);
    }

    return emailCheck.cancel;
  }, [values.email]);

  return (
    <div
      className={cn(
        !slotsSale && styles.clientData,
        classes.clientData,
        isDisabled && styles.disabled,
        isBasketPay && styles.withoutMargin
      )}
    >
      {!slotsSale && (
        <Typography
          variant={'h3'}
          className={cn(muiStyles.header, classes.header)}
          data-number={number}
        >
          {lang.provideData}
        </Typography>
      )}
      {showAutocompleteButton && (
        <fieldset
          className={cn(
            styles.formPanel,
            classes.formPanel,
            styles.autoCompleteContainer,
            muiStyles.root,
            !isDefaultTheme && muiStyles.withoutPadding
          )}
          id={AUTOCOMPLETE_ID}
        >
          <label className={cn(styles.autoCompleteLabel, classes.label)}>
            <div className={cn(styles.labelHeader, classes.labelHeader)}>
              <span>{lang.getLastData}</span>
              <span className={styles.email}>{lastData.email}</span>
            </div>

            <Button
              onClick={autocompleteClicked}
              size={'large'}
              variant={'outlined'}
              color={'primary'}
            >
              {lang.fillFormWithLastData}
            </Button>
          </label>
        </fieldset>
      )}

      <fieldset
        className={cn(
          styles.formPanel,
          classes.formPanel,
          muiStyles.root,
          !isDefaultTheme && muiStyles.withoutPadding
        )}
      >
        <label className={cn(styles.label, classes.label)} htmlFor="email">
          {!slotsSale && (
            <div className={cn(styles.labelHeader, classes.labelHeader)}>
              {specialEventCopy ? SPECIAL_EVENTS_TEXT : lang.willSendTickets}{' '}
              <strong>{lang.validEmail}</strong>
            </div>
          )}
          {!isWebview && (
            <Button
              size={'medium'}
              variant={'outlined'}
              color={'primary'}
              onClick={facebookIconClicked}
              fullWidth={true}
            >
              {lang.getDataFrom}
              <span
                className={cn(
                  styles.socialIcon,
                  classes.socialIcon,
                  icons.facebookFrame
                )}
              />
            </Button>
          )}
          <FormField
            data-test-id={'client-data-email'}
            id="email"
            name="email"
            placeholder={lang.eMail}
            type="email"
          />
        </label>
        <div className={styles.mailValidationAlertsContainer}>
          {emailValidation &&
            (!emailValidation.isValid ||
              !!emailValidation.suggestions?.length) && (
              <Alert severity={'warning'}>
                {!emailValidation.isValid &&
                  (emailValidation?.message || lang.validEmail + '.')}
                {!!emailValidation.suggestions?.length && (
                  <>
                    {!emailValidation.isValid && <br />}
                    {lang.didYouMean +
                      emailValidation.suggestions.join(', ') +
                      '?'}
                  </>
                )}
              </Alert>
            )}
        </div>
        <div
          className={cn(
            styles.names,
            slotsSale && styles.slotsNames,
            styles.label,
            classes.names,
            classes.label
          )}
        >
          {specialEventCopy !== specialEventNames.blokParty && !slotsSale && (
            <span className={cn(styles.description, classes.description)}>
              {lang.namedTicket} <strong>{lang.realData}</strong>
              {lang.id}
            </span>
          )}
          <label
            className={cn(styles.namesLabel, classes.namesLabel)}
            htmlFor="name"
          >
            <FormField
              data-test-id={'client-data-firstname'}
              id="firstname"
              name="firstname"
              placeholder={lang.firstName}
              type="text"
            />
          </label>
          <label
            className={cn(styles.namesLabel, classes.namesLabel)}
            htmlFor="surname"
          >
            <FormField
              data-test-id={'client-data-lastname'}
              id="lastname"
              name="lastname"
              placeholder={lang.lastName}
              type="text"
            />
          </label>
        </div>
        {slotsSale && (
          <label className={cn(styles.label, classes.label)} htmlFor="phone">
            <FormField
              id="phone"
              name="phone"
              placeholder={lang.phone}
              type="phone"
            />
          </label>
        )}

        {!isGiftCard && (
          <>
            {slotsSale ? (
              <div
                className={cn(
                  styles.discountCheckBox,
                  selectedSpace < 0 && styles.disabled
                )}
              >
                <ToggleField
                  checkboxId={'discountCheckbox'}
                  inputId={'discount'}
                  isDisabled={isDisabled || values.prepaidCheckbox}
                  open={values.discountCheckbox}
                  placeholder={lang.discountCode}
                  text={
                    config.theme.isGoing
                      ? lang.iHaveDiscountGoing
                      : lang.iHaveDiscount
                  }
                  tooltipText={
                    eventData?.partnerId === SALTOS_ID
                      ? lang.discountCodeTooltipSaltos
                      : lang.discountCodeTooltip
                  }
                />
              </div>
            ) : (
              <>
                <ToggleField
                  checkboxId={'discountCheckbox'}
                  inputId={'discount'}
                  isDisabled={
                    isDisabled ||
                    isBasketItems ||
                    isBasketPay ||
                    values.prepaidCheckbox
                  }
                  open={values.discountCheckbox}
                  placeholder={lang.discountCode}
                  text={
                    isBasketItems || isBasketPay
                      ? config.theme.isGoing
                        ? lang.iHaveDiscountGoing +
                          lang.discountUnavailableForBasketSales
                        : lang.iHaveDiscount +
                          lang.discountUnavailableForBasketSalesEmpik
                      : config.theme.isGoing
                      ? lang.iHaveDiscountGoing
                      : lang.iHaveDiscount
                  }
                  tooltipText={
                    eventData?.partnerId === SALTOS_ID
                      ? lang.discountCodeTooltipSaltos
                      : lang.discountCodeTooltip
                  }
                />
              </>
            )}

            {values.discountCheckbox && (
              <Button
                variant={'contained'}
                size={'large'}
                color={'primary'}
                disableElevation={true}
                fullWidth={true}
                children={CALCULATE_DISCOUNT_TEXT}
                onClick={
                  values.discount.length
                    ? () => calculateWithDiscount(values.discount, 'discount')
                    : () => notify(lang.discountCodeInputEmpty)
                }
              />
            )}
            {(isDefaultCurrency || slotsSale) && (
              <ToggleField
                checkboxId={'prepaidCheckbox'}
                inputId={'prepaidCard'}
                isDisabled={
                  isDisabled ||
                  isBasketItems ||
                  isBasketPay ||
                  values.discountCheckbox
                }
                open={values.prepaidCheckbox}
                placeholder={
                  !slotsSale ? lang.prepaidCardGoing : lang.prepaidCard
                }
                text={
                  isBasketItems || isBasketPay
                    ? prepaidTextUnavailable
                    : prepaidText
                }
                tooltipText={!slotsSale ? lang.prepaidTooltip : undefined}
              />
            )}

            {values.prepaidCheckbox && (
              <Button
                variant={'contained'}
                size={'large'}
                color={'primary'}
                disableElevation={true}
                fullWidth={true}
                children={CALCULATE_PREPAID_TEXT}
                onClick={
                  values.prepaidCard.length
                    ? () => calculateWithDiscount(values.prepaidCard, 'prepaid')
                    : () => notify(lang.prepaidCardInputEmpty)
                }
              />
            )}

            {/*TODO: Temporary feature 05.05.2022 */}
            {!slotsSale && isEmpikPremiumEnabled && !isProductsPay && (
              <EmpikCardNumber
                open={!!showPremiumCardInput}
                withoutHeader={true}
                isDisabled={isDisabled}
              />
            )}

            {slotsSale && (
              <div className={styles.wrapper}>
                <div className={styles.checkbox}>
                  <Field
                    component={Checkbox}
                    name="passCheckbox"
                    id="passCheckbox"
                  >
                    {lang.iHavePass}
                  </Field>
                </div>

                {showPassForm && (
                  <div className={cn(styles.toggledFields)}>
                    <label className={styles.label} htmlFor="pass">
                      <FormField
                        id="pass"
                        name="pass"
                        placeholder={lang.pass}
                        type="text"
                      />
                    </label>
                  </div>
                )}
              </div>
            )}
            {showInvoice && (
              <Invoice
                isDisabled={isDisabled}
                open={showInvoiceForm}
                isDefaultCurrency={isDefaultCurrency}
              />
            )}
          </>
        )}
      </fieldset>
    </div>
  );
};

export default ClientData;
