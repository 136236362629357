import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { IAvailableThemes } from '@Model/theme/types';

const useStyles = (isDesktop: boolean, selectedTheme: IAvailableThemes) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      checkbox: {
        alignSelf: isDesktop ? 'center' : undefined,
        height: 'fit-content',
      },
      disabled: {
        opacity: '0.3',
        pointerEvents: 'none',
      },
      image: {
        backgroundColor: theme.palette.background.default,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: theme.border.radius,
        minHeight: '10rem',
        width: '10rem',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      imageDesktop: {
        borderRadius: `${theme.border.radius} 0 0 ${theme.border.radius}`,
      },
      linkBox: {
        '&:hover': {
          cursor: 'pointer',
        },
        borderRadius: '0 0 0 1rem',
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      },
      linkBoxDesktop: {
        flexDirection: 'row',
      },
      space: {
        alignContent: 'center',
        flex: 5,
        padding: theme.spacing(4),
      },
      spaceContainer: {
        background: theme.palette.background.paper,
        borderColor: theme.border.color,
        borderRadius: theme.border.radius,
        borderStyle: theme.border.style,
        borderWidth: theme.border.width,
        display: 'flex',
        flexDirection: 'row',
        margin: '0.5rem 0',
        width: '100%',
      },
      spaceContainerDesktop: {
        borderRadius: theme.border.radius,
      },
      spaceDescriptionBox: {
        justifyContent: 'center',
      },
    });
  });

export default useStyles;
