import { createSelector } from 'reselect';

import { getSelectedLocale } from '@Model/locale/selectors';

import getData from './getData';
import getSelected from './getSelected';

export const DEFAULT_LANGUAGE = 'pl';

const getSelectedSpaceDescription = createSelector(
  [getData, getSelected, getSelectedLocale],
  (happening, selected, locale) => {
    const space = happening?.spaces.find(
      (_space) => _space.id === selected.space
    );

    const localizedDescription =
      space?.description.find((desc) => desc.language === locale) ||
      space?.description.find((desc) => desc.language === DEFAULT_LANGUAGE);

    return localizedDescription;
  }
);

export default getSelectedSpaceDescription;
