import { ActionType, StateType } from 'typesafe-actions';

import { IBuyingOnlineFormValues } from '@Compo/buying/Online/Online.types';
import { IBuyingOnsiteValues } from '@Compo/buying/Onsite/Onsite.types';
import { IBasket } from '@Model/basket/types';
import { ITrafficParamsReducer } from '@Model/trafficParams/types';
import { ITransactionDetailsResponse } from '@Services/$analytics/types';
import {
  IPaymentMethod,
  IPaymentMethodForTransactionAndDiscount,
  ITransactionOnSiteSalePaymentData,
} from '@Services/$transactions-api/types';

import * as actions from './../actions';
import reducer from './../reducer';

export enum PaymentOperatorEnum {
  eCard = 1,
  // The below value is dedicated for on-site sale.
  virtual = 2,
  payU = 3,
  free = 4,
}

export interface IBuyAndPayOnlinePayload {
  basketItems: IBasket[];
  data: IBuyingOnlineFormValues;
  onDone: () => void;
}
export interface IBuyAndPayOnsitePayload {
  basketItems: IBasket[];
  data: IBuyingOnsiteValues;
  onDone: () => void;
}

export interface ITransactionOnlineUser {
  acceptedTerms: number[];
  email: string;
  empikCardNumber?: string | null;
  empikPremiumJWT?: string | null;
  facebookId?: string | null;
  firstName: string;
  lastName: string;
  phone?: string;
  terms: boolean;
  newsletter: boolean;
}

interface ITransactionOnsiteUser {
  authId: string;
}

export type ITransactionUser = ITransactionOnlineUser | ITransactionOnsiteUser;

export interface ITransactionTicket {
  detailedUsers?: IDetailedUsers[];
  poolId: number;
  // TODO: change this when seats.io will be implemented
  seats?: any;
  ticketsNum: number;
  submissions?: ITransactionForms[];
}

interface IShipmentDetails {
  name: string;
  address: string;
  zip: string;
  city: string;
}

interface IDetailedUsers {
  firstname: string;
  lastname: string;
  email: string;
}

interface IInvoiceDetails {
  address: string;
  city: string;
  name: string;
  nip: string;
  post: string;
}

export interface ISlotReservationData extends ITransactionBody {
  idempotencyKey?: string;
  reservations: ISlotSingleReservation[];
}

export interface ISlotSingleReservation {
  dateTime: string;
  duration: number;
  extendedDuration?: number;
  happeningId: number;
  numberOfPeople: number;
  passCode?: string;
  priceReduction?: IPriceReduction;
  spaceId: number;
  paymentOperator: number;
  products?: ITransactionProduct[];
  priceType: string;
  title: string;
}

export interface IPriceReduction {
  dateTime?: string;
  discount?: IPriceCheckBodyDiscount;
  numberOfPeople?: number;
  price?: number;
  priceType?: string;
  products?: ITransactionProducts[];
  rulePriceId?: number;
  spaceId?: number;
  upsell?: boolean;
}

export interface IPriceCheckBodyDiscount {
  code: string | null;
}

export interface ITransactionProduct {
  id: number;
  quantity: number;
}

export interface ITransactionInsurance {
  birthDate: string;
  isLivingInPoland: boolean;
  isAccidentInsurance: boolean;
}

export type IPaymentDetails = IPaymentMethodForTransactionAndDiscount;

export interface ITransactionBody {
  agent?: string;
  discount?: string;
  discountCode?: string | null;
  language?: string;
  linkCancel?: string;
  linkFail: string;
  linkOk: string;
  invoice?: IInvoiceDetails | null;
  paymentDetails?: IPaymentDetails;
  paymentOperator?: PaymentOperatorEnum;
  salesChannelId: number;
  shipmentDetails?: IShipmentDetails;
  tickets?: ITransactionTicket[];
  transferHash?: string;
  user: ITransactionUser;
  prepaidCard?: string;
  products?: ITransactionProducts[];
  priceReduction?: IDiscountReduction;
  holdToken?: string;
  insurance?: ITransactionInsurance;
  utmTags?: ITrafficParamsReducer;
}

export interface ITransactionForms {
  formId: string;
  submissionId: string;
}

export interface IDiscountReduction {
  code: string;
}

export interface ITransactionProducts {
  id: number;
  quantity: number;
}

export interface ITransaction {
  body: ITransactionBody;
  onDone: () => void;
}

export interface ITransactionECardPayment {
  amount: number;
  autoDeposit: number;
  charset: string;
  country: number;
  currency: number;
  hash: string;
  hashAlgorithm: string;
  language: string;
  linkFail: string;
  linkOk: string;
  merchantId: number;
  name: string;
  orderDescription: string;
  orderNumber: number;
  paymentType: string;
  surname: string;
  transparentPages: number;
}

export interface ITransactionWithRedirectPayment {
  orderNumber: number;
  formUrl: string;
}

export type ITransactionOnSiteSalePayment = ITransactionOnSiteSalePaymentData;

export type ITransactionData =
  | ITransactionECardPayment
  | ITransactionWithRedirectPayment
  | ITransactionOnSiteSalePayment;

export type IGetDetailsRequest = string;

export interface IGetTransactionInfoSuccessPayload {
  affiliation: string;
  coupon: string;
  currency: string;
  eventSlug: string;
  id: string;
  products: ITransactionInfoProduct;
  revenue: number;
  rundateSlug: string;
  shipping: number;
  tax: number;
}

export interface ITransactionInfoProduct {
  id: string;
  name: string;
  brand: string;
  variant: string;
  category: string;
  dimension1: string;
  dimension2: string;
  dimension3: string;
  dimension4: string;
  dimension5: string;
  dimension6: string;
  dimension7: string;
  dimension8: string;
  dimension9: string;
  dimension10: string;
}

export interface IPaymentMethods {
  paymentMethods: IPaymentMethod[];
}

export type IGetTransactionDetailsSuccessPayload = ITransactionDetailsResponse;

export type IPaymentOperatorReducer = PaymentOperatorEnum;
export type IDataReducer = ITransactionData | null;
export type ITransactionInfoReducer = IGetTransactionInfoSuccessPayload | null;
export type ITransactionDetailsReducer =
  IGetTransactionDetailsSuccessPayload | null;
export type IPaymentMethodsReducer = IPaymentMethods;
export type ICardCredentialsReducer = ICardCredentials | null;

export interface ITransactionReducer {
  cardCredentials?: ICardCredentialsReducer;
  data: IDataReducer;
  paymentMethods?: IPaymentMethodsReducer;
  paymentOperator: IPaymentOperatorReducer;
  transactionDetails?: ITransactionDetailsReducer;
  transactionInfo?: ITransactionInfoReducer;
  userData?: IBuyingOnlineFormValues | null;
}

export interface ICardCredentials {
  token: string;
  mask: string;
}

export type ICurrency = 'PLN' | 'EUR' | 'GBP' | 'USD';

export type IState = StateType<typeof reducer>;
export type IAction = ActionType<typeof actions>;
