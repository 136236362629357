import React, { useEffect, useState } from 'react';

import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import cn from 'classnames';
import moment from 'moment';

import SimpleLoading from '@Compo/reusable/SimpleLoading';
import config from '@Config';
import empikInfo from '@Misc/styles/images/empik-info.svg';
import exclamationIcon from '@Misc/styles/images/exclamation.svg';
import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import styles from './TimeSlots.module.scss';
import useStyles from './TimeSlots.styles';
import { IBookingTimeSlot, ITimeSlotsProps } from './TimeSlots.types';
import Occupancy from './components/Occupancy';

const REMEMBERS_TEXT =
  'Pamiętaj żeby pojawić się w Hangarze przynajmniej 20 min. przed czasem!';

const TimeSlots = ({
  isLoadingAvailabilities,
  selectedDay,
  setDayFromSlot,
  setSlot,
  happeningSpaces,
  timeSlots,
  generateIdempotencyKey,
  selectedSlotFromState,
  selectedTheme,
  selections,
  calculatePricePerPerson,
  isMobile,
}: ITimeSlotsProps) => {
  const i18n = useDictionary();

  const [_, changeSelected] = useState('');
  const setSelected = (slot: IBookingTimeSlot) => {
    changeSelected(slot.start);
    setSlot(slot.start);
    const formattedSlotDate = moment(slot.data).toDate();
    setDayFromSlot(formattedSlotDate);
    generateIdempotencyKey();
  };
  let showedSlots: number = timeSlots?.length || 0;
  const infoIcon = config.theme.isEmpik ? empikInfo : exclamationIcon;

  const isDisabled =
    !selections.some((selection) => selection.numberOfPlayers) &&
    calculatePricePerPerson;

  useEffect(() => changeSelected(''), []);
  useEffect(() => {
    if (timeSlots.length === 1) {
      setSelected(timeSlots[0]);
    }
    if (selectedDay) {
      setDayFromSlot(selectedDay);
    }
  }, [selectedDay]);

  const theme = useTheme();
  const muiStyles = useStyles(theme);
  const isHangar = selectedTheme === 'Hangar646';

  if (isLoadingAvailabilities) {
    return <SimpleLoading />;
  }

  return (
    <div className={styles.wrapper}>
      {isHangar && (
        <div className={cn(styles.info, styles.noSlots, styles.remember)}>
          <InfoIcon fontSize={'large'} className={styles.infoIcon} />
          <Typography variant={'subtitle2'}>{REMEMBERS_TEXT}</Typography>
        </div>
      )}
      <ul className={styles.timeSlots}>
        {happeningSpaces &&
          timeSlots.map((slot) => {
            const isTimeOnSlotScoped = happeningSpaces.find(
              (space) => space.id === slot.spaces[0].spaceId
            )?.isScopeView;

            const slotStartInSeconds = moment.duration(slot.start).asSeconds();
            const slotDuration = happeningSpaces.find(
              (space) => space.id === slot.spaces[0].spaceId
            )?.timeSlot;
            const slotEndInSeconds = slotDuration
              ? slotStartInSeconds + slotDuration
              : 0;
            const slotEndInHoursAndMinutes = moment
              .utc()
              .startOf('day')
              .add(slotEndInSeconds, 'seconds')
              .format('HH:mm');

            if (slot.isDisabled) {
              showedSlots--;

              return null;
            }

            const isSelectedSlot = selectedSlotFromState === slot.start;
            const onClick = () => setSelected(slot);

            return (
              <li key={slot.start}>
                <Button
                  disabled={slot.isDisabled || isDisabled}
                  type={'button'}
                  onClick={onClick}
                  size={'large'}
                  variant={isSelectedSlot ? 'contained' : 'outlined'}
                  color={config.theme.isEmpik ? 'secondary' : 'primary'}
                  disableElevation={true}
                  fullWidth={true}
                  className={muiStyles.slot}
                >
                  <Grid container={true}>
                    <Grid item={true} xs={12}>
                      {isTimeOnSlotScoped
                        ? `${slot.start} - ${slotEndInHoursAndMinutes}`
                        : slot.start}
                    </Grid>
                    {!!(
                      slot?.spaces[0]?.capacity && slot.spaces.length < 2
                    ) && (
                      <Grid item={true} xs={12}>
                        <Occupancy slot={slot} />
                      </Grid>
                    )}
                  </Grid>
                </Button>
              </li>
            );
          })}
      </ul>
      {showedSlots ? null : (
        <div className={cn(styles.info, styles.noSlots)}>
          <img className={styles.infoIcon} src={infoIcon} alt="" />
          {i18n.buy.happening.noMoreSlots}
        </div>
      )}
    </div>
  );
};

export default TimeSlots;
