import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPagesAuth, IPagesReducer } from './../types';
import get from './get';

const getPagesAuth = createSelector<_Store.IState, IPagesReducer, IPagesAuth>(
  [get],
  (pages) => pages.auth
);

export default getPagesAuth;
