import { connect } from 'react-redux';

import _Store from '@Store';

import HorizontalList from '@Compo/Bricks/components/HorizontalList/HorizontalList.component';
import { IHorizontalListFromState } from '@Compo/Bricks/components/HorizontalList/HorizontalList.types';
import { getCompositionIdentifier } from '@Model/pages/selectors';

export default connect(
  (state: _Store.IState): IHorizontalListFromState => ({
    compositionIdentifier: getCompositionIdentifier(state),
  })
)(HorizontalList);
