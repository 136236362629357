export const CHANGE_DATE_MOUTED = 'Empik/tickets/CHANGE_DATE_MOUTED';
export const GET_TICKET_DATA = 'Empik/tickets/GET_TICKET_DATA';
export const GET_TRANSACTION_DATA = 'Empik/tickets/GET_TRANSACTION_DATA';
export const RECEIVE_TICKET_MOUNTED = 'Empik/tickets/RECEIVE_TICKET_MOUNTED';
export const RECEIVE_TICKET_SUBMIT = 'Empik/tickets/RECEIVE_TICKET_SUBMIT';
export const RESET = 'Empik/tickets/RESET';
export const SET_TICKET_DATE = 'Empik/tickets/SET_TICKET_DATE';
export const SET_SLOT = 'Empik/tickets/SET_SLOT';
export const TRANSFER_TICKET = 'Empik/tickets/TRANSFER_TICKET';
export const RETURN_TICKET = 'Empik/tickets/RETURN_TICKET';
export const CANCEL_TRANSFER_TICKET = 'Empik/tickets/CANCEL_TRANSFER_TICKET';
export const SET_SHOW_SENDER = 'Empik/tickets/SET_SHOW_SENDER';

// Private
export const _GET_TICKETS_REQUEST = 'Empik/tickets/_GET_TICKETS_REQUEST';
export const _GET_TICKETS_SUCCESS = 'Empik/tickets/_GET_TICKETS_SUCCESS';
export const _GET_TICKETS_FAILURE = 'Empik/tickets/_GET_TICKETS_FAILURE';

export const _GET_EVENT_REQUEST = 'Empik/tickets/_GET_EVENT_REQUEST';
export const _GET_EVENT_SUCCESS = 'Empik/tickets/_GET_EVENT_SUCCESS';
export const _GET_EVENT_FAILURE = 'Empik/tickets/_GET_EVENT_FAILURE';

export const _GET_AVAILABILITIES_REQUEST =
  'Empik/tickets/_GET_AVAILABILITIES_REQUEST';
export const _GET_AVAILABILITIES_SUCCESS =
  'Empik/tickets/_GET_AVAILABILITIES_SUCCESS';
export const _GET_AVAILABILITIES_FAILURE =
  'Empik/tickets/_GET_AVAILABILITIES_FAILURE';

export const _TRANSFER_REQUEST = 'Empik/tickets/_TRANSFER_REQUEST';
export const _TRANSFER_SUCCESS = 'Empik/tickets/_TRANSFER_SUCCESS';
export const _TRANSFER_FAILURE = 'Empik/tickets/_TRANSFER_FAILURE';

export const _CANCEL_TRANSFER_REQUEST =
  'Empik/tickets/_CANCEL_TRANSFER_REQUEST';
export const _CANCEL_TRANSFER_SUCCESS =
  'Empik/tickets/_CANCEL_TRANSFER_SUCCESS';
export const _CANCEL_TRANSFER_FAILURE =
  'Empik/tickets/_CANCEL_TRANSFER_FAILURE';

export const _RETURN_REQUEST = 'Empik/tickets/_RETURN_REQUEST';
export const _RETURN_SUCCESS = 'Empik/tickets/_RETURN_SUCCESS';
export const _RETURN_FAILURE = 'Empik/tickets/_RETURN_FAILURE';

export const _GET_RECEIVE_REQUEST = 'Empik/tickets/_GET_RECEIVE_REQUEST';
export const _GET_RECEIVE_SUCCESS = 'Empik/tickets/_GET_RECEIVE_SUCCESS';
export const _GET_RECEIVE_FAILURE = 'Empik/tickets/_GET_RECEIVE_FAILURE';

export const _GET_RECEIVE_PAYMENT_REQUEST =
  'Empik/tickets/_GET_RECEIVE_PAYMENT_REQUEST';
export const _GET_RECEIVE_PAYMENT_SUCCESS =
  'Empik/tickets/_GET_RECEIVE_PAYMENT_SUCCESS';
export const _GET_RECEIVE_PAYMENT_FAILURE =
  'Empik/tickets/_GET_RECEIVE_PAYMENT_FAILURE';

export const _GET_TRANSFER_TICKET_FEE_REQUEST =
  'Empik/tickets/_GET_TRANSFER_TICKET_FEE_REQUEST';
export const _GET_TRANSFER_TICKET_FEE_SUCCESS =
  'Empik/tickets/_GET_TRANSFER_TICKET_FEE_SUCCESS';
export const _GET_TRANSFER_TICKET_FEE_FAILURE =
  'Empik/tickets/_GET_TRANSFER_TICKET_FEE_FAILURE';

export const _GET_TRANSACTION_DATA_REQUEST =
  'Empik/tickets/_GET_TRANSACTION_DATA_REQUEST';
export const _GET_TRANSACTION_DATA_SUCCESS =
  'Empik/tickets/_GET_TRANSACTION_DATA_SUCCESS';
export const _GET_TRANSACTION_DATA_FAILURE =
  'Empik/tickets/_GET_TRANSACTION_DATA_TICKET_FAILURE';

export const _SET_INVALID_SELECTION = 'Empik/tickets/_SET_INVALID_SELECTION';
export const _SET_VALID_SELECTION = 'Empik/tickets/_SET_VALID_SELECTION';

export const DOWNLOAD_TICKET_FROM_TICKET_DATA =
  'Empik/tickets/DOWNLOAD_TICKET_FROM_TICKET_DATA';

export const _DOWNLOAD_TICKET_FROM_TICKET_DATA_REQUEST =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TICKET_DATA_REQUEST';
export const _DOWNLOAD_TICKET_FROM_TICKET_DATA_SUCCESS =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TICKET_DATA_SUCCESS';
export const _DOWNLOAD_TICKET_FROM_TICKET_DATA_FAILURE =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TICKET_DATA_FAILURE';

export const DOWNLOAD_TICKET_FROM_TRANSACTION_DATA =
  'Empik/tickets/DOWNLOAD_TICKET_FROM_TRANSACTION_DATA';

export const _DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_REQUEST =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_REQUEST';
export const _DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_SUCCESS =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_SUCCESS';
export const _DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_FAILURE =
  'Empik/tickets/_DOWNLOAD_TICKET_FROM_TRANSACTION_DATA_FAILURE';
