import React, { Component } from 'react';

import throttle from 'lodash.throttle';
import ReactHelmet from 'react-helmet';

import config from '@Config';
import assembleTradeDoublerScript from '@Misc/helpers/analytics/assembleTradeDoublerScript';
import join from '@Misc/helpers/join';
import _L from '@Misc/helpers/local';
import * as MODULES from '@Routes/modules';
import * as LOCALS from '@Vars/constants';

import { IHelmetProps, IHelmetState } from './Helmet.types';

const AUTHOR_TEXT = _L(LOCALS.HELMET__AUTHOR);
const PAGE_NAME_TEXT = _L(LOCALS.HELMET__PAGE_NAME);

const DESCRIPTION_SEPARATOR = ', ';
const HELMET_DELAY = 500;
const TITLE_SEPARATOR = ' | ';

class Helmet extends Component<IHelmetProps, IHelmetState> {
  private readonly setStateThrottled: any;

  constructor(props: IHelmetProps) {
    super(props);

    this.state = { ...props };

    this.setStateThrottled = throttle(this.setHelmet.bind(this), HELMET_DELAY);
  }

  public shouldComponentUpdate(
    nextProps: IHelmetProps,
    nextState: IHelmetProps
  ) {
    this.setStateThrottled();

    if (this.props.isGoingHelp && window.groove) {
      window.groove.widget.destroy();
    }

    return this.state !== nextState;
  }

  public componentWillUnmount() {
    this.setStateThrottled.cancel();
  }

  public render() {
    const {
      currentUrl,
      description,
      keywords,
      ogDescription,
      ogImage,
      pageName,
      noIndex,
      schema,
      module,
      orderNumber,
      orderValue,
      rundateId,
      transactionItems,
      userEmail,
      voucher,
      customGtmId,
      isEmbed,
      customGtagId,
      fbPixelKey,
      isGoingHelp,
    } = this.props;

    const title = config.theme.isSbm
      ? join(DESCRIPTION_SEPARATOR, pageName)
      : join(
          TITLE_SEPARATOR,
          join(DESCRIPTION_SEPARATOR, pageName),
          PAGE_NAME_TEXT
        );
    if (isEmbed)
      return (
        <ReactHelmet>
          {customGtmId?.length && customGtmId.length > 0 && (
            <script
              src="/static_legacy/analytics/gtm.js"
              data-gtm-key={customGtmId}
            />
          )}
          {customGtagId?.length && customGtagId.length > 0 && (
            <script
              async={true}
              src={`https://www.googletagmanager.com/gtag/js?id=${customGtagId}`}
            />
          )}
          {customGtagId?.length && customGtagId.length > 0 && (
            <script
              src="/static_legacy/analytics/gtag.js"
              data-gtag-key={customGtagId}
            />
          )}
          {fbPixelKey?.length && fbPixelKey.length > 0 && (
            <script
              src="/static_legacy/analytics/fbPixel.js"
              data-fb-pixel-key={fbPixelKey}
            />
          )}
          {fbPixelKey?.length && fbPixelKey.length > 0 && (
            <noscript>
              {`
              <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src=https://www.facebook.com/tr?id=${fbPixelKey}&ev=PageView&noscript=1
                />
                `}
            </noscript>
          )}
        </ReactHelmet>
      );

    return (
      <ReactHelmet>
        <meta name="author" content={AUTHOR_TEXT} />
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={AUTHOR_TEXT} />
        <meta property="og:type" content="website" />

        {(!config.app.siteIndexation || noIndex) && (
          <meta name="robots" content="noindex" />
        )}

        {currentUrl && config.app.siteIndexation && (
          <link rel="canonical" href={currentUrl} />
        )}

        {currentUrl && <meta property="og:url" content={currentUrl} />}

        {description && (
          <meta
            name="description"
            content={join(DESCRIPTION_SEPARATOR, description)}
          />
        )}

        {keywords && <meta name="keywords" content={keywords} />}

        {ogDescription && (
          <meta
            property="og:description"
            content={join(DESCRIPTION_SEPARATOR, ogDescription)}
          />
        )}

        {ogImage && <meta property="og:image" content={ogImage} />}

        {isGoingHelp && <script src="/static_legacy/grooveWidget/groove.js" />}

        {customGtmId?.length && customGtmId.length > 0 && (
          <script
            src="/static_legacy/analytics/gtm.js"
            data-gtm-key={customGtmId}
          />
        )}

        {schema &&
          schema.map((item, index) => (
            <script
              type={'application/ld+json'}
              key={index.toString()}
              children={JSON.stringify(item)}
            />
          ))}

        {module &&
          module === MODULES.SUMMARY_SUCCESS &&
          rundateId &&
          orderNumber &&
          orderValue &&
          orderValue > 0 &&
          transactionItems?.length &&
          transactionItems?.length > 0 &&
          userEmail && (
            <script>
              {assembleTradeDoublerScript({
                orderNumber: orderNumber || 0,
                orderValue: orderValue || 0,
                rundateId: rundateId || '',
                transactionItems: transactionItems || [],
                userEmail: userEmail || '',
                voucher,
              })}
            </script>
          )}
      </ReactHelmet>
    );
  }

  protected setHelmet() {
    this.setState({
      ...this.props,
    });
  }
}

export default Helmet;
