const slugifyString = (str?: string | null) =>
  str
    ? str
        .toLowerCase()
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
        .split(' ')
        .join('-')
    : '';

export default slugifyString;
