import { connect } from 'react-redux';

import _Store from '@Store';

import { isMobile } from '@Model/app/selectors';

import CarouselFixed from './CarouselFixed.component';
import {
  ICarouselFixedFromState,
  ICarouselFixedOwnProps,
} from './CarouselFixed.types';

const mapStateToProps = (state: _Store.IState): ICarouselFixedFromState => ({
  isMobile: isMobile(state),
});

export default connect<
  ICarouselFixedFromState,
  void,
  ICarouselFixedOwnProps,
  _Store.IState
>(mapStateToProps)(CarouselFixed);
