import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      justifyContent: 'center',
      padding: '2rem',
      paddingTop: 0,
    },
    content: {
      padding: '2rem',

      '&:first-child': {
        paddingTop: '2rem',
      },
    },
  })
);

export default useStyles;
