import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolsAllReducer } from './../types';
import getAll from './getAll';

const shouldShowSeatsIo = createSelector<
  _Store.IState,
  IPoolsAllReducer,
  boolean
>([getAll], (all) => {
  if (all.poolsData?.seatsIoPublicKey) {
    return true;
  }

  return false;
});

export default shouldShowSeatsIo;
