import Banner from '@Compo/Bricks/components/Banner';
import DescriptionBrick from '@Compo/Bricks/components/DescriptionBrick';
import TextBlock from '@Compo/Bricks/components/TextBlock';
import TimelineBrick from '@Compo/Bricks/components/TimelineBrick';
import TourList from '@Compo/Bricks/components/TourListBrick';
import Search from '@Compo/Search';
import { BricksEnum } from '@Model/pages/constants/bricks';
import * as MUTATIONS from '@Model/pages/mutations';

import { IBricksConnection } from './Bricks.types';
import ArtistSliderBrick from './components/ArtistSliderBrick';
import CarouselBrick from './components/CarouselBrick';
import CtaButtonsBrick from './components/CtaButtonsBrick';
import CustomCode from './components/CustomCode';
import EmbedBrick from './components/EmbedBrick';
import FaqBrick from './components/FaqBrick';
import FormioBrick from './components/FormioBrick';
import GalleryBrick from './components/GalleryBrick';
import HorizontalList from './components/HorizontalList';
import HotDeals from './components/HotDeals';
import SectionLead from './components/LeadSection';
import MultilineCards from './components/MultilineCards';
import OneBigAndVerticalList from './components/OneBigAndVerticalList';
import ReadMoreBrick from './components/ReadMoreBrick';
import SlidableCards from './components/SlidableCards';
import Testimonials from './components/Testimonials';
import WideArticle from './components/WideArticle';

const bricksComponents: IBricksConnection = {
  [BricksEnum.artist_slider]: {
    component: ArtistSliderBrick,
    props: MUTATIONS.artistSlider,
  },
  [BricksEnum.banner]: {
    component: Banner,
    props: MUTATIONS.banner,
  },
  [BricksEnum.big_and_vertical_list]: {
    component: OneBigAndVerticalList,
    props: MUTATIONS.big_and_vertical_list,
  },
  [BricksEnum.big_hotspot]: {
    component: WideArticle,
    props: MUTATIONS.big_hotspot,
  },
  [BricksEnum.carousel]: {
    component: CarouselBrick,
    props: MUTATIONS.carousel,
  },
  [BricksEnum.custom_code]: {
    component: CustomCode,
    props: MUTATIONS.custom_code,
  },
  [BricksEnum.description]: {
    component: DescriptionBrick,
    props: MUTATIONS.description,
  },
  [BricksEnum.embed]: {
    component: EmbedBrick,
    props: MUTATIONS.embed,
  },
  [BricksEnum.formio]: {
    component: FormioBrick,
    props: MUTATIONS.formio,
  },
  [BricksEnum.faq]: {
    component: FaqBrick,
    props: MUTATIONS.faq,
  },
  [BricksEnum.gallery]: {
    component: GalleryBrick,
    props: MUTATIONS.gallery,
  },
  [BricksEnum.horizontal_list]: {
    component: HorizontalList,
    props: MUTATIONS.horizontal_list,
  },
  [BricksEnum.hotdeals]: {
    component: HotDeals,
    props: MUTATIONS.hotdeals,
  },
  [BricksEnum.multiline_hotspots]: {
    component: MultilineCards,
    props: MUTATIONS.multiline_hotspots,
  },
  [BricksEnum.read_more]: {
    component: ReadMoreBrick,
    props: MUTATIONS.read_more,
  },
  [BricksEnum.search_bar]: {
    component: Search,
    props: MUTATIONS.search_bar,
  },
  [BricksEnum.section_lead]: {
    component: SectionLead,
    props: MUTATIONS.section_lead,
  },
  [BricksEnum.slidable_hotspots]: {
    component: SlidableCards,
    props: MUTATIONS.slidable_hotspots,
  },
  [BricksEnum.timeline]: {
    component: TimelineBrick,
    props: MUTATIONS.timeline,
  },
  [BricksEnum.cta_button]: {
    component: CtaButtonsBrick,
    props: MUTATIONS.cta_button,
  },
  [BricksEnum.text_block]: {
    component: TextBlock,
    props: MUTATIONS.text_block,
  },
  [BricksEnum.text_block_with_image]: {
    component: TextBlock,
    props: MUTATIONS.text_block,
  },
  [BricksEnum.testimonials]: {
    component: Testimonials,
    props: MUTATIONS.testimonials,
  },
  [BricksEnum.tour_list]: {
    component: TourList,
    props: MUTATIONS.tour_list,
  },
};

export default bricksComponents;
