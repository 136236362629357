import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getShipmentDelivery, shipmentDeliveryMounted } from './../actions';

export const getShipmentDeliveryWhenMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(shipmentDeliveryMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return of$(getShipmentDelivery.request());
    })
  );
};

export const fetchShipmentDeliveryWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { shipmentApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getShipmentDelivery.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return from$(shipmentApi.getShipmentDelivery()).pipe(
        mergeMap$((data) => {
          return of$(getShipmentDelivery.success(data));
        }),
        catchError$((error: Error) => of$(getShipmentDelivery.failure(error)))
      );
    })
  );
};
