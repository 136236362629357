import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getModule, getParams } from '@Model/internalRouter/selectors';
import { initialState as locationsInitialState } from '@Model/locations/reducer';
import { getSections } from '@Model/pages/actions';
import { PagesEnum } from '@Model/pages/constants/pages';
import * as MODULES from '@Routes/modules';
import { ISiteMatchParams } from '@Routes/types';

import {
  changeLocation,
  clearLocation,
  getLocations,
  getLocationsRequest,
  setDefaultLocation,
  setLocation,
} from './../actions';
import {
  getLocations as getAvailableLocations,
  getSelectedLocation,
} from './../selectors';
import { ISelectedLocation } from './../types';

const LOCATION_LOCAL_STORAGE_KEY = 'homeLocation';

export const getLocationsForSite: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getLocations)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const locationsExist = Object.keys(getAvailableLocations(state)).length;

      if (locationsExist) {
        const slug = getParams(state) as ISiteMatchParams;
        const selectedLocation = getSelectedLocation(state).slug;

        if (slug && slug.slug !== selectedLocation) {
          return of$(changeLocation(slug.slug, action.payload));
        }

        return of$(getSections.request({ site: action.payload }));
      }

      return of$(getLocationsRequest.request(action.payload));
    })
  );
};

export const fetchLocations: _Store.IEpic = (
  action$,
  state$,
  { locationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getLocationsRequest.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const slug = getParams(state) as ISiteMatchParams;
      const module = getModule(state);
      const selectedLocation = getSelectedLocation(state).slug;

      return from$(locationsApi.getLocations()).pipe(
        mergeMap$((data) => {
          if (slug && slug.slug !== selectedLocation) {
            return of$(
              getLocationsRequest.success(
                locationsApi.normalizeLocations(data)
              ),
              changeLocation(slug.slug, action.payload)
            );
          }

          if (module === MODULES.SEARCH) {
            return of$(
              getLocationsRequest.success(locationsApi.normalizeLocations(data))
            );
          }

          return of$(
            getLocationsRequest.success(locationsApi.normalizeLocations(data)),
            setDefaultLocation(data, action.payload)
          );
        }),
        catchError$((error: Error) => of$(getLocationsRequest.failure(error)))
      );
    })
  );
};

export const setLocationOnFirstLoad: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setDefaultLocation)),
    mergeMap$(({ payload, meta }) => {
      let storedLocation = null;
      try {
        storedLocation = JSON.parse(
          localStorage.getItem(LOCATION_LOCAL_STORAGE_KEY) as string
        );
      } catch (noSSR) {}

      const defaultLocation = payload.find((location) => location.isDefault);

      const selectedLocation = {
        cities:
          defaultLocation?.cities.map((city) => city.id) ||
          locationsInitialState.selectedLocation.cities,
        name:
          defaultLocation?.name || locationsInitialState.selectedLocation.name,
        slug:
          defaultLocation?.slug || locationsInitialState.selectedLocation.slug,
      };

      if (storedLocation) {
        return [
          setLocation(storedLocation),
          getSections.request({ site: meta }),
        ];
      }

      return [
        setLocation(selectedLocation),
        getSections.request({ site: meta }),
      ];
    })
  );
};

export const changeLocationsAndReload: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(changeLocation)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const locationSlug = action.payload;
      const selectedLocation = getSelectedLocation(state);
      const locations = getAvailableLocations(state);
      const newLocation: ISelectedLocation = {
        cities: locations[locationSlug]?.cities,
        name: locations[locationSlug]?.name,
        slug: locationSlug,
      };
      const module = getModule(state);
      const defaultPage =
        module === MODULES.INSPIRATIONS
          ? PagesEnum.inspirations
          : PagesEnum.mainpage;

      if (locationSlug === selectedLocation.slug) {
        return EMPTY$;
      }

      try {
        localStorage.setItem(
          LOCATION_LOCAL_STORAGE_KEY,
          JSON.stringify(newLocation)
        );
      } catch (noSSR) {}

      return [
        setLocation(newLocation),
        getSections.request({ site: action.meta || defaultPage }),
      ];
    })
  );
};

export const clearLocationInStorage: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(clearLocation)),
    mergeMap$(() => {
      try {
        localStorage.removeItem(LOCATION_LOCAL_STORAGE_KEY);
      } catch (noSSR) {}

      return EMPTY$;
    })
  );
};
