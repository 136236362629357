import { createSelector } from 'reselect';

import config from '@Config';
import { getEvent } from '@Model/event/selectors';
import { translate } from '@Model/locale/selectors';
import {
  getPools,
  getSeatsGroupedByPoolId,
  getSelectedTickets,
} from '@Model/pools/selectors';
import { IPool } from '@Model/pools/types';
import { getSelectedProducts } from '@Model/products/selectors';

import { IBasket } from './../types';

const getBasketData = createSelector(
  [
    getEvent,
    getSelectedTickets,
    getPools,
    getSeatsGroupedByPoolId,
    getSelectedProducts,
    translate,
  ],
  (event, poolsSelected, pools, seats, products, trans) => {
    if (event) {
      const getEventProps = (): IBasket[] => {
        return poolsSelected.map((poolSelected) => {
          const currentPool =
            pools.find((poolItem) => poolItem.id === poolSelected.poolId) ||
            ({} as IPool);

          return {
            additionalFields: currentPool.isDetailedPurchaseRequired,
            amount: poolSelected.amount,
            cityName: event.placeCityName,
            courierRequired: currentPool.courierRequired,
            currency: poolSelected.currency,
            customTerms: event.customTerms,
            eventId: event.eventId,
            hasDependency: currentPool.hasDependency,
            hour: event.friendlyHour,
            id: poolSelected.poolId,
            paymentMethods: currentPool.payments,
            place: event.placeName,
            poolName: currentPool.title,
            poolPrice: currentPool.price,
            pools: [],
            price: currentPool.price
              ? currentPool.price * poolSelected.amount +
                (currentPool.serviceFee || 0) * poolSelected.amount
              : 0,
            rundate: event.friendlyDate,
            rundateId: event.rundateId,
            seats: seats[poolSelected.poolId],
            serviceFee: currentPool.serviceFee
              ? currentPool.serviceFee * poolSelected.amount
              : 0,
            submissions: poolSelected.submissions,
            title: event.title,
            totalPoolPrice: currentPool.price
              ? currentPool.price * poolSelected.amount
              : 0,
          };
        });
      };

      const getProductProps = (): IBasket[] => {
        return products.map((product) => {
          return {
            amount: product.amount,
            customTerms: [],
            currency: config.app.defaultCurrency,
            eventId: -1,
            id: product.id,
            isProduct: true,
            poolName: product.name,
            poolPrice: product.price,
            pools: [],
            price: product.price * product.amount,
            title: trans('basket').products,
            totalPoolPrice: product.price * product.amount,
          };
        });
      };

      return [...getEventProps(), ...getProductProps()];
    }

    return [];
  }
);

export default getBasketData;
