import routes from '@Routes/routes';
import { IFooterSection, IMenuLink } from '@Services/$menu-api/types';

const menuGoing: IMenuLink[] = [
  {
    link: routes.events,
    title: 'Kalendarium',
  },
  {
    link: 'https://goingapp.pl/more/',
    title: 'Magazyn MORE',
  },
  {
    link: routes.about,
    title: 'Aplikacja Going.',
  },
  {
    link: 'https://goingapp.pl/polecamy/going-help',
    title: 'Pomoc',
  },
  {
    link: '/polecamy/wspolpraca',
    title: 'Sprzedawaj z Going.',
    isSalesButton: true,
  },
];

const menuEmpik: IMenuLink[] = [
  {
    link: routes.events,
    title: 'Wydarzenia',
  },
  {
    title: 'Artyści',
  },
  {
    title: 'Miejsca',
  },
];

const menuEmpikOnsite: IMenuLink[] = [
  {
    link: routes.events,
    title: 'Wydarzenia',
  },
  {
    link: 'https://web.goingapp.pl/backend/backend/auth/signin',
    title: 'Raporty i zwroty',
  },
];

export const menuFooterGoing: IFooterSection[] = [
  {
    links: [
      {
        link: 'https://goingapp.pl/polecamy/going-help',
        title: 'Pomoc',
      },
      {
        link: 'https://web.goingapp.pl/storage/app/media/Regulamin%20Aplikacji%20Going./regulamin_aplikacji_going.pdf',
        nofollow: true,
        title: 'Regulamin',
      },
      {
        link: 'https://web.goingapp.pl/themes/going/assets/docs/privacy_policy.pdf',
        nofollow: true,
        title: 'Polityka prywatności',
      },
    ],
    title: 'Pomoc',
  },
  {
    links: [
      {
        link: 'https://goingapp.pl/form/kontakt-organizatorzy',
        nofollow: true,
        title: 'Współpraca',
      },
      {
        link: 'https://goingapp.pl/zglos-wydarzenie',
        nofollow: true,
        title: 'Zgłoś wydarzenie',
      },
      {
        link: 'https://places.goingapp.pl/',
        nofollow: true,
        title: 'System rezerwacji Going. Places',
      },
    ],
    title: 'Dla organizatorów',
  },
];

export const menuFooterEmpik = [
  {
    links: [
      {
        link: routes.contact,
        title: 'Kontakt',
      },
      {
        link: 'https://pomoc.empikbilety.pl/',
        title: 'FAQ',
      },
      {
        link: 'https://web.goingapp.pl/storage/app/media/empikbilety/regulamin-portalu-biletowego-empik.pdf',
        nofollow: true,
        title: 'Regulamin',
      },
      {
        link: 'https://web.goingapp.pl/storage/app/media/empikbilety/polityka-prywatnosci-portalu-biletowego-empik-bilety112019.pdf',
        nofollow: true,
        title: 'Polityka prywatności',
      },
    ],
    title: 'Pomoc',
  },
  {
    links: [
      {
        link: 'https://empikbilety.pl/form/kontakt-organizatorzy',
        nofollow: true,
        title: 'Współpraca',
      },
      {
        link: 'https://places.goingapp.pl/',
        nofollow: true,
        title: 'System rezerwacji Going. Places',
      },
    ],
    title: 'Dla organizatorów',
  },
];

const toBeReleasedText = 'Dostępne wkrótce';
// TODO: Usunąć (zamienić na połączenie z langmapą) przy ostatecznej wersji menu z recoilem

menuFooterEmpik[0].links.push({
  link: 'https://web.goingapp.pl/storage/app/media/Informacje%20o%20Agencie%20ubezpieczeniowym/Informacja%20o%20Agencie%20ubezpieczeniowym_.pdf',
  nofollow: true,
  title: 'Informacja o agencie oraz pełnomocnictwo',
});

menuFooterGoing[0].links.push({
  link: 'https://web.goingapp.pl/storage/app/media/Informacje%20o%20Agencie%20ubezpieczeniowym/Informacja%20o%20Agencie%20ubezpieczeniowym_.pdf',
  nofollow: true,
  title: 'Informacja o agencie oraz pełnomocnictwo',
});

export default {
  menuEmpik,
  menuEmpikOnsite,
  menuFooterEmpik,
  menuFooterGoing,
  menuGoing,
  toBeReleasedText,
};
