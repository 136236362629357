import React, { FC, useEffect, useState } from 'react';

import Cookies from 'universal-cookie';

import config from '@Config';
import browserIcon from '@Misc/styles/images/browser.svg';
import goingIcon from '@Misc/styles/images/going-square.svg';

import styles from './DeepLinkModal.module.scss';
import { IDeepLinkModalProps } from './DeepLinkModal.types';

const OPEN_TEXT = 'Wybierz';
const CONTINUE_TEXT = 'Kontynuuj';
const TITLE_TEXT = 'Przeglądaj dalej z';
const WITH_APP_TEXT = 'Aplikacją Going.';
const WITH_BROWSER_TEXT = 'Przeglądarką';

const EXPIRE_TIME = 60 * 60 * 1000;

const DeepLinkModal: FC<IDeepLinkModalProps> = ({ url, eventDeeplinkWeb }) => {
  const [visible, setVisible] = useState(true);
  const [analyticsUrl, setAnalyticsUrl] = useState('');
  const cookies = new Cookies();

  useEffect(() => {
    setAnalyticsUrl(window.location.href);
    if (cookies.get('continueWithBrowser')) {
      setVisible(false);
    }
  }, []);

  const redirectToApp = () => {
    eventDeeplinkWeb({
      textDisplayed: OPEN_TEXT,
      destinationType: 'app',
      destinationUrl: analyticsUrl,
    });
    try {
      window.open(
        `${config.app.deepLinkApp}?link=${config.app.baseUrl}${url}&apn=${config.deepLink.androidPackageName}&ibi=${config.deepLink.iosPackageName}&isi=${config.deepLink.iosStoreId}`,
        '_self'
      );
    } catch (error) {}
  };

  const continueWithBrowser = () => {
    eventDeeplinkWeb({
      textDisplayed: CONTINUE_TEXT,
      destinationType: 'web',
      destinationUrl: analyticsUrl,
    });
    setVisible(false);
    const date = new Date();
    date.setTime(date.getTime() + EXPIRE_TIME);
    cookies.set('continueWithBrowser', true, { path: '/', expires: date });
  };

  if (!config.features.deepLinkBar || !visible) {
    return null;
  }

  return (
    <div className={styles.modalBackground}>
      <div className={styles.deepLinkBar}>
        <div className={styles.title}>{TITLE_TEXT}</div>
        <div className={styles.optionsWrapper}>
          <div className={styles.option}>
            <img src={goingIcon} className={styles.icon} />
            <div className={styles.description}>{WITH_APP_TEXT}</div>
            <button className={styles.open} onClick={redirectToApp}>
              {OPEN_TEXT}
            </button>
          </div>
          <div className={styles.option}>
            <img src={browserIcon} className={styles.icon} />
            <div className={styles.description}>{WITH_BROWSER_TEXT}</div>
            <button className={styles.open} onClick={continueWithBrowser}>
              {CONTINUE_TEXT}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeepLinkModal;
