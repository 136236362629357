import { connect } from 'react-redux';

import _Store from '@Store';

import { isDesktop, isTablet } from '@Model/app/selectors';
import { getCompositionIdentifier } from '@Model/pages/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import OneBigAndVerticalList from './OneBigAndVerticalList.component';
import {
  IOneBigAndVerticalListFromState,
  IOneBigAndVerticalListOwnProps,
} from './OneBigAndVerticalList.types';

const mapStateToProps = (
  state: _Store.IState
): IOneBigAndVerticalListFromState => {
  return {
    isDesktop: isDesktop(state),
    isLoading: getLoading(CONST.SECTIONS)(state),
    isTablet: isTablet(state),
    compositionIdentifier: getCompositionIdentifier(state),
  };
};

export default connect<
  IOneBigAndVerticalListFromState,
  null,
  IOneBigAndVerticalListOwnProps,
  _Store.IState
>(mapStateToProps)(OneBigAndVerticalList);
