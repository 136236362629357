import React, { useEffect } from 'react';

import Bricks from '@Compo/Bricks';

import { ISearchStructureProps } from './SearchStructure.types';

const SearchStructure = ({
  structure,
  searchStructureMounted,
  userLocation,
}: ISearchStructureProps) => {
  useEffect(() => {
    searchStructureMounted();
  }, []);

  return structure ? (
    <Bricks structure={structure} userLocation={userLocation} inner={true} />
  ) : null;
};

export default SearchStructure;
