import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import SellSuccessInfo from './SellSuccessInfo.component';
import { ISellSuccessInfoFromState } from './SellSuccessInfo.types';

const mapStateToProps = (state: _Store.IState): ISellSuccessInfoFromState => {
  return {
    lang: translate(state)('payment', 'paymentSuccess'),
  };
};

export default connect<ISellSuccessInfoFromState, {}, {}, _Store.IState>(
  mapStateToProps,
  {}
)(SellSuccessInfo);
