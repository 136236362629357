import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IPlaceResponse } from '@Services/$places-api/types';

import * as CONSTS from './../constants/actions';
import {
  IPlaceGetSuccessPayload,
  IPlaceSetCurrentKeyPayload,
} from './../types';

export const placePageMounted = createStandardAction(
  CONSTS.PLACE_PAGE_MOUNTED
)();

export const getPlace = createAsyncAction(
  CONSTS._GET_REQUEST,
  CONSTS._GET_SUCCESS,
  CONSTS._GET_FAILURE
)<undefined, IPlaceGetSuccessPayload, Error>();

export const getPlaceSlug = createAsyncAction(
  CONSTS._GET_PLACE_SLUG_REQUEST,
  CONSTS._GET_PLACE_SLUG_SUCCESS,
  CONSTS._GET_PLACE_SLUG_FAILURE
)<number, IPlaceResponse, Error>();

export const setCurrentKey = createStandardAction(
  CONSTS.SET_CURRENT_KEY
)<IPlaceSetCurrentKeyPayload>();
