import React from 'react';

import image from '@Misc/styles/images/top_rectangle.png';

import styles from './Top.module.scss';

const IMAGE_ALT = 'najlepsze wydarzenia';

const Top = () => (
  <div className={styles.wrapper}>
    <div className={styles.imageContainer}>
      <img src={image} className={styles.rectangle} alt={IMAGE_ALT} />
    </div>
  </div>
);

export default Top;
