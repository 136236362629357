import React, { FC } from 'react';

import moment from 'moment';

import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import scrollToTop from '@Misc/helpers/scrollToTop';
import routes from '@Routes/routes';

import styles from './PastEvents.module.scss';
import { IPastEventsProps } from './PastEvents.types';

const SEE_TEXT = 'Zobacz';
const SHOW_MORE_TEXT = 'pokaż więcej';

const PastEvents: FC<IPastEventsProps> = ({
  artistId,
  pastEvents,
  history,
  location,
}) => {
  const loadMoreRedirect = () => {
    const lastYearDate = moment(
      new Date(new Date().getFullYear() - 1, 0, 1)
    ).format('DD.MM.YYYY');
    const todayDate = moment(new Date()).format('DD.MM.YYYY');

    history.push(
      `${routes.events}?filters=artists:${artistId};dates:${lastYearDate},${todayDate}`
    );
    scrollToTop(location, history);
  };

  return (
    <div>
      {pastEvents.map((event, i) => {
        return (
          <div key={`pastEvent-${i}`}>
            <p className={styles.caption}>{event.caption}</p>
            {event.events.map((evt, index) => (
              <div
                key={`pastEvent-events-${index}`}
                className={styles.eventContainer}
              >
                <div className={styles.contentContainer}>
                  <div className={styles.rundateContainer}>
                    <div className={styles.day}>{evt.rundate.day}</div>
                    <div className={styles.dayName}>{evt.rundate.dayName}</div>
                  </div>
                  <div className={styles.infoContainer}>
                    <div className={styles.eventName}>{evt.eventName}</div>
                    <div className={styles.placeName}>{evt.placeName}</div>
                  </div>
                </div>
                <a
                  href={fillUrlWithValues(
                    routes.event,
                    [':eventSlug', ':rundateSlug?'],
                    [evt.eventSlug, evt.rundateSlug]
                  )}
                  className={styles.seeButton}
                >
                  {SEE_TEXT}
                </a>
              </div>
            ))}
          </div>
        );
      })}
      <div className={styles.center}>
        <button className={styles.more} onClick={loadMoreRedirect}>
          {SHOW_MORE_TEXT}
        </button>
      </div>
    </div>
  );
};

export default PastEvents;
