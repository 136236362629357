import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getSelectedLocation } from '@Model/locations/selectors';
import { getMainPage } from '@Model/pages/actions';
import { getHomeSections } from '@Model/pages/selectors';

import HomePage from './HomePage.component';
import { IHomePageFromDispatch, IHomePageFromState } from './HomePage.types';

const mapStateToProps = (state: _Store.IState): IHomePageFromState => ({
  structure: getHomeSections(state),
  userLocation: getSelectedLocation(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IHomePageFromDispatch => ({
  mounted: () => dispatch(getMainPage()),
});

export default connect<
  IHomePageFromState,
  IHomePageFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
