import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { isMobile } from '@Model/app/selectors';
import { translate } from '@Model/locale/selectors';
import { selectPool } from '@Model/pools/actions';

import Ticket from './Ticket.component';
import { ITicketFromDispatch, ITicketFromState } from './Ticket.types';

const mapStateToProps = (state: _Store.IState): ITicketFromState => {
  return {
    dateTranslate: translate(state)('dates'),
    isMobile: isMobile(state),
    lang: translate(state)('event'),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketFromDispatch => {
  return {
    selectPool: (eventSlug, rundateSlug) =>
      dispatch(selectPool({ eventSlug, rundateSlug })),
  };
};

export default connect<
  ITicketFromState,
  ITicketFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Ticket);
