import { createSelector } from 'reselect';

import _Store from '@Store';

import { ILocationsReducer, ISelectedLocation } from '../types';
import get from './get';

const getSelectedLocation = createSelector<
  _Store.IState,
  ILocationsReducer,
  ISelectedLocation
>([get], (slice) => slice.selectedLocation);

export default getSelectedLocation;
