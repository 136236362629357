import {
  ITextBlockExtra,
  ITextBlockOwnProps,
} from '@Compo/Bricks/components/TextBlock/TextBlock.types';
import {
  ISectionsComponentDataMutable,
  ISectionsTextBlock,
} from '@Model/pages/types/sections';

export const text_block = (
  data: ISectionsComponentDataMutable,
  extra?: ITextBlockExtra | null,
  bgColor?: string
): ITextBlockOwnProps => {
  const textBlockData = data as ISectionsTextBlock[];

  return {
    bgColor: bgColor || '',
    color: extra?.color,
    data: textBlockData,
  };
};
