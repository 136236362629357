import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignSelfLeft: {
      alignSelf: 'flex-start',
      textAlign: 'left',
    },
    arrow: {
      borderColor: `${theme.palette.primary.main}!important`,
    },
    centerSelf: {
      alignSelf: 'center',
      textAlign: 'center',
    },
    description: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    overrideFlex: {
      display: 'flex',
      flexDirection: 'column',
    },
    primaryText: {
      borderColor: `${theme.palette.primary.main}!important`,
      color: theme.palette.text.primary,
    },
    secondaryText: {
      color: theme.palette.text.secondary,
    },
  })
);

export default useStyles;
