import React, { FC, useContext } from 'react';

import { Button } from '@material-ui/core';
import { ShoppingCartOutlined } from '@material-ui/icons';
import { Badge } from '@mui/material';

import { clearContext } from '@Compo/Basket/BasketProvider/BasketProvider';
import useStyles from '@Compo/Basket/components/BasketButton/BasketButton.styles';
import Link from '@Compo/reusable/DualLink';
import routes from '@Routes/routes';

import { IBasketButtonProps } from './BasketButton.types';

const BasketButton: FC<IBasketButtonProps> = ({ isError }) => {
  const state = useContext(clearContext);

  const itemsLength = state?.basketItems.length;

  const YOUR_BASKET_TEXT = 'Twój koszyk';

  const classes = useStyles();

  return (
    <Button
      component={Link}
      to={routes.basket}
      className={classes.root}
      variant={'text'}
      size={'large'}
      startIcon={
        <Badge color={'primary'} badgeContent={isError ? 'X' : itemsLength}>
          <ShoppingCartOutlined />
        </Badge>
      }
    >
      {YOUR_BASKET_TEXT}
    </Button>
  );
};

export default BasketButton;
