import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';

import { useTheme } from '@material-ui/core';

import { IPayUContextProps } from '@Compo/functional/PayUContextProvider/PayUContextProvider.types';
import config from '@Config';
import loadDOMScript from '@Misc/helpers/loadDOMScript';

const initialState: {
  payuInstance: payu.PayU | undefined;
  secureForms: payu.SecureForms | undefined;
  cardNumberForm: payu.SecureForm | undefined;
  cardDateForm: payu.SecureForm | undefined;
  cardCVVForm: payu.SecureForm | undefined;
  handleTokenizeCard:
    | ((payu: payu.PayU) => Promise<'SUCCESS' | 'ERROR'>)
    | undefined;
  setIsCardPaymentMethod: Dispatch<SetStateAction<boolean>> | undefined;
  isCardPaymentMethod: boolean;
} = {
  payuInstance: undefined,
  secureForms: undefined,
  cardNumberForm: undefined,
  cardDateForm: undefined,
  cardCVVForm: undefined,
  handleTokenizeCard: undefined,
  setIsCardPaymentMethod: undefined,
  isCardPaymentMethod: false,
};

export const PayUContext = createContext(initialState);

const PayUContextProvider: FC<IPayUContextProps> = ({
  children,
  setError,
  setCardCredentials,
  lang,
}) => {
  let payuInstance;
  let secureForms;
  let cardNumberForm;
  let cardDateForm;
  let cardCVVForm;
  let handleTokenizeCard;
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [isCardPaymentMethod, setIsCardPaymentMethod] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!isScriptLoaded) {
      loadDOMScript(config.app.payUSdkUrl).then(() => {
        setScriptLoaded(true);
      });
    }
  }, []);

  if (isCardPaymentMethod && isScriptLoaded) {
    payuInstance = PayU(config.buy.payUPosId, {
      dev: config.app.showUnfinishedFeatures === 'true',
    });
    const cardFormOpts: payu.SecureFormOptions = {
      style: {
        basic: {
          fontFamily: 'Helvetica',
          fontWeight: 'bold',
          fontColor: theme.palette.text.primary,
        },
        invalid: {
          fontColor: theme.palette.error.main,
        },
        placeholder: {
          fontColor: theme.palette.text.secondary,
        },
      },
    };
    secureForms = payuInstance.secureForms();
    cardNumberForm = secureForms.add('number', cardFormOpts);
    cardDateForm = secureForms.add('date', cardFormOpts);
    cardCVVForm = secureForms.add('cvv', cardFormOpts);

    handleTokenizeCard = async (payu: payu.PayU) => {
      try {
        const tokenResponse = await payu.tokenize('SINGLE');

        if (tokenResponse.status === 'ERROR') {
          setError({
            message: tokenResponse.error.messages[0].message,
            name: tokenResponse.error.messages[0].code,
          });

          return tokenResponse.status;
        }

        setCardCredentials(tokenResponse.body);

        return tokenResponse.status;
      } catch (error: any) {
        setError({ message: lang.cardDataError, name: 'card.data.error' });

        return 'ERROR';
      }
    };
  }

  return (
    <PayUContext.Provider
      children={children}
      value={{
        isCardPaymentMethod,
        payuInstance,
        secureForms,
        cardNumberForm,
        cardDateForm,
        cardCVVForm,
        handleTokenizeCard,
        setIsCardPaymentMethod,
      }}
    />
  );
};

export default PayUContextProvider;
