import React, { useEffect } from 'react';

import { Button, Grid } from '@material-ui/core';

import { useDictionary } from '@Recoil/lang/hooks/useDictionary';

import useGlobalStyles from '../../global.styles';
import stepNames from '../../steps';
import Paper from '../Paper';
import { IGreetingProps } from './Greeting.types';

const Greeting = ({ resetForm, skipToSelectedStep }: IGreetingProps) => {
  const globals = useGlobalStyles();
  const { entryList: lang } = useDictionary();

  const handleResetForm = () => {
    resetForm();
    skipToSelectedStep(stepNames.starter);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      resetForm();
      skipToSelectedStep(stepNames.starter);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Paper title={lang.greeting.title} helperText={lang.greeting.subtitle}>
      <Grid container={true} justifyContent="center">
        <Grid item={true}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleResetForm}
            className={globals.button}
          >
            {lang.greeting.button}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Greeting;
