import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { getSelectedPriceType } from '@Misc/helpers/happening/getSelectedPriceType';
import useMuiBreakpoints from '@Misc/hooks/useMuiBreakpoints';

import PriceTypePicker from './../../../PriceTypePicker';
import useStyles from './PriceType.styles';
import { IPriceTypeProps } from './PriceType.types';

const PriceType = ({
  isCalculatedPerPerson,
  lang,
  priceType,
  selections,
  selectedSpace,
  selectedSlotPriceTypes,
  selectedLang,
}: IPriceTypeProps) => {
  const DEFAULT_BACKEND_NORMAL_TICKET_NAME = 'default';

  const { isDesktop } = useMuiBreakpoints();

  const muiStyles = useStyles();

  const isSelected = !!selections.find(
    (selection) =>
      selection.priceType?.type === priceType.type &&
      selection.numberOfPlayers &&
      selection.numberOfPlayers > 0
  );

  const priceTypeTitle =
    priceType.type !== DEFAULT_BACKEND_NORMAL_TICKET_NAME
      ? selectedLang === 'en'
        ? priceType.titleEN || priceType.title
        : priceType.title
      : lang.defaultTicket;

  const priceTypeDescription =
    selectedLang === 'en'
      ? priceType.descriptionEN || priceType.description
      : priceType.description;

  return (
    <Grid
      container={true}
      item={true}
      direction={isDesktop && !isCalculatedPerPerson ? 'row' : 'column'}
      spacing={2}
      key={`form-${priceType}`}
    >
      <Grid
        item={true}
        xs={12}
        md={isCalculatedPerPerson ? 12 : 8}
        className={muiStyles.root}
      >
        <Typography variant={'h6'}>{priceTypeTitle}</Typography>
      </Grid>
      {priceType.description && (
        <Grid item={true} xs={12}>
          <Typography variant={'body1'}>{priceTypeDescription}</Typography>
        </Grid>
      )}
      <Grid
        item={true}
        xs={12}
        md={isCalculatedPerPerson ? 12 : 4}
        className={muiStyles.root}
      >
        <PriceTypePicker
          selectedSpace={selectedSpace}
          priceType={getSelectedPriceType(
            priceType.type,
            selectedSlotPriceTypes
          )}
          counter={priceType.counter}
          isSelected={isSelected}
        />
      </Grid>
    </Grid>
  );
};

export default PriceType;
