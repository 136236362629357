import { connect } from 'react-redux';

import _Store from '@Store';

import { translate } from '@Model/locale/selectors';

import Term from './Term.component';
import { ITermFromState } from './Term.types';

const mapStateToProps = (state: _Store.IState): ITermFromState => ({
  lang: translate(state)('buy', 'term'),
});

export default connect<ITermFromState, void, {}, _Store.IState>(
  mapStateToProps
)(Term);
