import { connect } from 'react-redux';

import _Store from '@Store';

import { getDevice, isDesktop, mobileUserAgent } from '@Model/app/selectors';

import About from './About.component';
import { IAboutFromState } from './About.types';

const mapStateToProps = (state: _Store.IState): IAboutFromState => {
  return {
    device: getDevice(state),
    isDesktop: isDesktop(state),
    mobileUserAgent: mobileUserAgent(state),
  };
};

export default connect<IAboutFromState, void, {}, _Store.IState>(
  mapStateToProps
)(About);
