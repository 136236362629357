// Shared services
import { services as sharedServices } from 'goingapp-shared';

import config from '@Config';
import Analytics from '@Services/$analytics';
import ArticleApi from '@Services/$article-api';
import ArtistApi from '@Services/$artist-api';
import AuthApi from '@Services/$auth-api';
import AWSSearchApi from '@Services/$awsSearch-api';
import BrazeApi from '@Services/$braze-api';
import DeviceDetector from '@Services/$device-detector';
import EventsApi from '@Services/$events-api';
import AwsApi from '@Services/$events-api/AwsApi';
import FacebookApi from '@Services/$facebook-api';
import FormIoApi from '@Services/$formio-api';
import GiftCardApi from '@Services/$giftCard-api';
import HtmlApi from '@Services/$html-api';
import IFrameProvider from '@Services/$iframe-provider';
import LandingPageApi from '@Services/$landing-api';
import LocaleDetector from '@Services/$locale-detector';
import LocationsApi from '@Services/$locations-api';
import MailApi from '@Services/$mail-api';
import ModalDictionary from '@Services/$modal-dictionary';
import PlacesApi from '@Services/$places-api';
import PoolsApi from '@Services/$pools-api';
import PremiumApi from '@Services/$premium-api';
import PriceTypeApi from '@Services/$price-type-api';
import ProductApi from '@Services/$product-api';
import RedirectionApi from '@Services/$redirection-api';
import SectionsApi from '@Services/$sections-api';
import SelectionApi from '@Services/$selection-api';
import SendgridApi from '@Services/$sendgrid-api';
import ShipmentApi from '@Services/$shipment-api';
import TicketsApi from '@Services/$tickets-api';
import TransactionsApi from '@Services/$transactions-api';

const availabilitiesApi = new sharedServices.AvailabilitiesApi(
  config.api.zagrywkiApiV2 || ''
);
const happeningApi = new sharedServices.HappeningsApi(
  config.api.zagrywkiApi || ''
);
const priceApi = new sharedServices.PriceApi(config.api.zagrywkiApiV2 || '');
const productsApi = new sharedServices.ProductsApi(
  config.api.zagrywkiApi || ''
);
const termsApi = new sharedServices.TermsApi(config.api.baseUrl || '');
const cloudinaryApi = new sharedServices.CloudinaryApi(config.images.cloudName);

export interface IServices {
  analytics: typeof Analytics;
  articleApi: typeof ArticleApi;
  artistApi: typeof ArtistApi;
  authApi: typeof AuthApi;
  availabilitiesApi: typeof availabilitiesApi;
  awsApi: typeof AwsApi;
  awsSearchApi: typeof AWSSearchApi;
  brazeApi: typeof BrazeApi;
  cloudinaryApi: typeof cloudinaryApi;
  deviceDetector: typeof DeviceDetector;
  eventsApi: typeof EventsApi;
  facebookApi: typeof FacebookApi;
  giftCardApi: typeof GiftCardApi;
  happeningApi: typeof happeningApi;
  htmlApi: typeof HtmlApi;
  iframeProvider: typeof IFrameProvider;
  landingPageApi: typeof LandingPageApi;
  localeDetector: typeof LocaleDetector;
  locationsApi: typeof LocationsApi;
  mailApi: typeof MailApi;
  modalDictionary: typeof ModalDictionary;
  placesApi: typeof PlacesApi;
  poolsApi: typeof PoolsApi;
  premiumApi: typeof PremiumApi;
  priceApi: typeof priceApi;
  priceTypeApi: typeof PriceTypeApi;
  productApi: typeof ProductApi;
  productsApi: typeof productsApi;
  redirectionApi: typeof RedirectionApi;
  sectionsApi: typeof SectionsApi;
  selectionApi: typeof SelectionApi;
  sendgridApi: typeof SendgridApi;
  shipmentApi: typeof ShipmentApi;
  termsApi: typeof termsApi;
  ticketsApi: typeof TicketsApi;
  transactionsApi: typeof TransactionsApi;
  formioApi: typeof FormIoApi;
}

const services: IServices = {
  analytics: Analytics,
  articleApi: ArticleApi,
  artistApi: ArtistApi,
  authApi: AuthApi,
  availabilitiesApi,
  awsApi: AwsApi,
  awsSearchApi: AWSSearchApi,
  brazeApi: BrazeApi,
  cloudinaryApi,
  deviceDetector: DeviceDetector,
  eventsApi: EventsApi,
  facebookApi: FacebookApi,
  giftCardApi: GiftCardApi,
  happeningApi,
  htmlApi: HtmlApi,
  iframeProvider: IFrameProvider,
  landingPageApi: LandingPageApi,
  localeDetector: LocaleDetector,
  locationsApi: LocationsApi,
  mailApi: MailApi,
  modalDictionary: ModalDictionary,
  placesApi: PlacesApi,
  poolsApi: PoolsApi,
  premiumApi: PremiumApi,
  priceApi,
  priceTypeApi: PriceTypeApi,
  productApi: ProductApi,
  productsApi,
  redirectionApi: RedirectionApi,
  sectionsApi: SectionsApi,
  selectionApi: SelectionApi,
  sendgridApi: SendgridApi,
  shipmentApi: ShipmentApi,
  termsApi,
  ticketsApi: TicketsApi,
  transactionsApi: TransactionsApi,
  formioApi: FormIoApi,
};

export default services;
