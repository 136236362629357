import moment from 'moment';

import config from '@Config';
import { ISearchQueryJoinMethod } from '@Model/search/types';
import { IQueryFacets, IQueryTerm, ISort } from '@Services/$events-api/types';

interface ISearchField {
  name: string;
  boost: number;
}

interface IHighlight {
  name: string;
  maxPhrases: number;
}

const SPECIAL_CHARACTERS = /[`!@#$%^&_|+\-=?;:'",<>{}\[\]\\\/]/gi;

class AWSQueryBuilder {
  public static query(
    selector: string,
    query: string,
    options: string[],
    phrase?: string
  ): string {
    return `${selector}?q=${
      phrase ? phrase + ' AND ' : ''
    }${query}&${options.join('&')}`;
  }

  public static filterQuery(term: IQueryTerm): string {
    return `${term.name}:${term.value}`;
  }

  public static andArray(querys: string[]): string {
    return querys.length > 0 ? `${querys.join(' AND ')}` : '';
  }

  public static phrase(phrase: string[]): string {
    return phrase.length > 0
      ? `${phrase.join('~0.7 ').replace(SPECIAL_CHARACTERS, '')}~0.7`
      : '*:*';
  }

  /**
   * Returns formatted CloudSearch phrase with wildcard.
   *
   * @param {string} phrase - phrase to search for
   *
   * @return {string} - CloudSearch phrase
   */
  public static basePhrase(phrase: string, isFuzzySearch?: boolean): string {
    const fuzzyLevel =
      isFuzzySearch && phrase.length > config.aws.fuzzySearchLetters
        ? `~${config.aws.fuzzySearchLevel}`
        : '*';

    return phrase.length > 0 ? phrase + fuzzyLevel : `*:${fuzzyLevel}`;
  }

  public static suggestPhrase(phrases: string[]): string {
    return phrases.length > 0
      ? `title_pl:${phrases
          .map((phrase) => {
            return `(${phrase}~0.7 OR ${phrase}*)`;
          })
          .join(' AND ')
          .replace(SPECIAL_CHARACTERS, '')}`
      : '-*:*';
  }

  public static or(querys: string[]): string {
    return querys.length > 0 ? `${querys.join(' OR ')}` : '';
  }

  public static and(query: string): string {
    return query ? ` AND ${query}` : '';
  }

  public static bond(querys: string[], bond: 'AND' | 'OR' | 'NOT'): string {
    return querys.length > 0
      ? `${querys.filter((query) => !!query.length).join(` ${bond} `)}`
      : '';
  }

  public static term(
    terms: IQueryTerm[],
    queryJoinMethod?: ISearchQueryJoinMethod
  ): string {
    return this.makeTermString(terms, queryJoinMethod || 'AND');
  }

  public static exclude(exclusions: IQueryTerm[]): string {
    return this.makeTermString(exclusions, 'NOT');
  }

  public static option(option: string, value: string | number) {
    return `${option}=${value}`;
  }

  /**
   * Returns search query fields q.option with additional boosting.
   *
   * @param {ISearchField[]} fields - array of ISearchFields objects.
   *
   * @return {string} return should be wrapped in .option method with 'q.option' parameter.
   */
  public static searchFieldsOption(fields: ISearchField[]) {
    const searchFields = fields
      .map((field) => `"${field.name}^${field.boost}"`)
      .toString();

    return `{"fields":[${searchFields}]}`;
  }

  /**
   * Return option query builder
   *
   * @param {string[]} returnValues - list of fields to return. Use _all_fields to return everything.
   *
   * @return {string} return query option
   */
  public static return(returnValues: string[]) {
    return returnValues ? `return=${returnValues.join(',')}` : '';
  }

  /**
   * Takes dates in yyyy-mm-dd format and returns valid query string for the range of dates.
   * If end date is empty string, query will return infinite.
   *
   * @param {string} start - start date
   * @param {string} end - end date or empty string
   *
   * @return {string} Lucene query string
   */
  public static datesQuery(start: string, end: string | null) {
    const s = moment
      .utc(moment(new Date(start)).startOf('day'))
      .local()
      .utc(true)
      .format();
    const e = end
      ? moment
          .utc(moment(new Date(end)).endOf('day'))
          .local()
          .utc(true)
          .format()
      : '';

    return `(${this.or([
      'calendar_event:1',
      `((rundate_enddate:[${s} TO *}) AND (rundate_rundate:{* TO ${s}]))`,
      `((rundate_rundate:[${s} TO ${
        e ? e + ']' : '*}'
      }) AND NOT (rundate_enddate:[* TO *]))`,
      `(rundate_rundate:[${s} TO ${e ? e + ']' : '*}'})`,
    ])})`;
  }

  public static rangeDate(start: string, end: string) {
    if (start && end) {
      const s = moment.utc(moment(start, 'DD.MM.YYYY').startOf('day')).format();
      const e = moment.utc(moment(end, 'DD.MM.YYYY').endOf('day')).format();

      return this.and(
        `(${this.or([
          `((rundate_enddate:[${s} TO *})${this.and(
            `(rundate_rundate:{* TO ${s}])`
          )})`,
          `((rundate_rundate:[${s} TO ${e}]) AND NOT (rundate_enddate:[* TO *]))`,
          `(rundate_rundate:[${s} TO ${e}])`,
        ])})`
      );
    } else if (!end) {
      const s = moment.utc(moment(new Date(start)).startOf('day')).format();

      return this.and(
        `(${this.or([
          `((rundate_enddate:[${s} TO *})${this.and(
            `(rundate_rundate:{* TO ${s}])`
          )})`,
          `((rundate_rundate:[${s} TO *}) AND NOT (rundate_enddate:[* TO *]))`,
          `(rundate_rundate:[${s} TO *})`,
        ])})`
      );
    } else if (!start) {
      const e = moment.utc(moment(new Date(end)).startOf('day').format());

      return this.and(
        `(${this.or([
          `((rundate_enddate:{* TO ${e}])${this.and(
            `(rundate_rundate:{* TO ${e}])`
          )})`,
          `((rundate_rundate:{* TO ${e}]) AND NOT (rundate_enddate:[* TO *]))`,
          `(rundate_rundate:{* TO ${e}])`,
        ])})`
      );
    }

    return '';
  }

  public static sort(sorting: ISort[]) {
    return sorting
      .sort((a, b) => a.priority - b.priority)
      .map((sort) => `${sort.field} ${sort.sort}`)
      .join(', ');
  }

  public static facets(facets: IQueryFacets[]) {
    return facets
      ? facets
          .map((facet) => {
            return `facet.${facet.name}={${
              facet.size ? `size:${facet.size}` : `size:100`
            }${facet.sort ? `, sort:'${facet.sort}'` : ``}}`;
          })
          .join('&')
      : '';
  }

  /**
   * Returns search results highlights option query string from array of IHighlight objects.
   *
   * @param {IHighlight[]} highlights - array of highlight objects
   *
   * @return {string} - lucene query string
   */
  public static highlights(highlights: IHighlight[]) {
    return highlights
      .map((highlight) =>
        this.option(
          `highlight.${highlight.name}`,
          `{"max_phrases":${highlight.maxPhrases},"format":"text","pre_tag":"${config.aws.searchHighlightsTags.pre}","post_tag":"${config.aws.searchHighlightsTags.post}"}`
        )
      )
      .join('&');
  }

  private static makeTermString(
    terms: IQueryTerm[],
    bond: 'AND' | 'OR' | 'NOT'
  ) {
    if (terms) {
      let str = '';
      if (bond !== 'AND') {
        str += ` ${bond} (`;
        str += this.bond(
          terms.map((term: IQueryTerm) => {
            return !!term.value?.length && term.value.length > 1
              ? `${this.or(
                  term.value.map((value: string) => {
                    return `${term.name}:${encodeURIComponent(value)}`;
                  })
                )}`
              : term.value?.length
              ? `${term.name}:${encodeURIComponent(term.value[0])}`
              : '';
          }),
          bond
        );
        str += ')';

        return str;
      }

      str += this.bond(
        terms.map((term: IQueryTerm) => {
          return !!term.value?.length && term.value.length > 1
            ? `(` +
                `${this.or(
                  term.value.map((value: string) => {
                    return `${term.name}:${encodeURIComponent(value)}`;
                  })
                )}` +
                ')'
            : term.value?.length
            ? `(` + `${term.name}:${encodeURIComponent(term.value[0])}` + `)`
            : '';
        }),
        bond
      );

      return str;
    }

    return '';
  }
}

export default AWSQueryBuilder;
