import { createSelector } from 'reselect';

import { ITicketsData, ITicketsListData } from '@Model/tickets/types';

import getTicketsData from './getTicketsData';

const getTicketById = createSelector(
  [getTicketsData],
  (ticketList: ITicketsListData) =>
    (ticketId: string): ITicketsData | null => {
      if (ticketList) {
        const foundTicket = ticketList.find(
          (ticket) => ticket.ticketId.toString() === ticketId
        );

        return foundTicket ? foundTicket : null;
      }

      return null;
    }
);

export default getTicketById;
