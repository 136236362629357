/**
 * Function that check if provided color is white
 *
 * @param {string} color - color to check
 *
 * @return {boolean} true if given color is white
 */
const WHITE_DEFINITIONS = ['#fff', '#ffffff', 'white'];

const isWhiteColor = (color: string) => {
  return new Set(WHITE_DEFINITIONS).has(color);
};

export default isWhiteColor;
