import { connect } from 'react-redux';

import _Store from '@Store';

import { getCompositionIdentifier } from '@Model/pages/selectors';
import { getLocation } from '@Model/router/selectors';
import * as CONST from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import ReadMoreBrick from './ReadMoreBrick.component';
import {
  IReadMoreBrickFromState,
  IReadMoreBrickOwnProps,
} from './ReadMoreBrick.types';

const mapStateToProps = (state: _Store.IState) => {
  return {
    isDark: getLocation(state).query?.isDark === 'true',
    isLoading: getLoading(CONST.SECTIONS)(state),
    compositionIdentifier: getCompositionIdentifier(state),
  };
};

export default connect<
  IReadMoreBrickFromState,
  null,
  IReadMoreBrickOwnProps,
  _Store.IState
>(mapStateToProps)(ReadMoreBrick);
