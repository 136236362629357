import axios from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  IGiftCardActivationBody,
  IGiftCardActivationResponse,
} from '@Services/$giftCard-api/types';

class GiftCardApi {
  private static giftCardUri: string = `${config.api.zagrywkiProductsApi}prepaidcard`;

  public activateGiftCard(
    body: IGiftCardActivationBody
  ): Promise<IGiftCardActivationResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post(GiftCardApi.giftCardUri, body, {
          headers: {
            'auth-token': config.app.zagrywkiProductsAuthToken,
          },
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }
}

export default new GiftCardApi();
