import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    artistSliderContainer: {
      paddingRight: '1.5rem',
    },
  })
);

export default useStyles;
