import React, { useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import { Field } from 'formik';

import goTrough from '@Misc/helpers/goTrough';
import groupBy from '@Misc/helpers/groupBy';
import { specialEventNames } from '@Model/event/constants/specialEvents';

import FormField from './../forms/FormField';
import styles from './Pools.module.scss';
import useStyles from './Pools.styles';
import { IPoolsProps } from './Pools.types';
import Loading from './components/Loading';
import Pool from './components/Pool';
import Seats from './components/Seats';

const LOADERS_COUNT = 5;

const BLOK_PARTY_TEXT = 'Wybierz rodzaj i liczbę pakietów';

const Pools = ({
  arePoolsLoading,
  isDesktop,
  isTransactionLoading,
  lang,
  number,
  pools,
  poolsMounted,
  selectedPools,
  shouldShowSeatsIo,
  specialEventCopy,
  selectedLang,
  eventLang,
}: IPoolsProps) => {
  useEffect(() => {
    poolsMounted();
  }, []);

  const showImage = isDesktop;

  const shouldShowPoolsOrSeats = !arePoolsLoading;
  const noResults = !arePoolsLoading && pools.length === 0;

  const theme = useTheme();
  const muiStyles = useStyles(theme);

  const activePools = pools.filter((pool) => pool.active);

  const langKey = selectedLang === 'en' ? 'poolGroupEN' : 'poolGroupPL';
  const groupedPools = groupBy(pools, (pool) => pool[langKey]);
  const groupedPoolsKeys = [
    ...Array.from(groupedPools.keys()).filter((pool) => pool),
    ...Array.from(groupedPools.keys()).filter((pool) => !pool),
  ];

  return (
    <div className={cn(styles.pools, isTransactionLoading && styles.disabled)}>
      <h3 className={styles.header} data-number={number}>
        {specialEventCopy === specialEventNames.blokParty
          ? BLOK_PARTY_TEXT
          : lang.title}
      </h3>

      {noResults && <p className={styles.noResults}>{lang.noPoolsAvailable}</p>}

      {shouldShowPoolsOrSeats && (
        <>
          {shouldShowSeatsIo && (
            <>
              <Seats />
              {activePools.map((pool) => (
                <span data-poolId={pool.id} />
              ))}
            </>
          )}
          <ul>
            {groupedPoolsKeys.map((key) => {
              const groupPools = groupedPools.get(key);
              if (groupPools) {
                return (
                  <Grid
                    container={true}
                    spacing={3}
                    className={muiStyles.group}
                    key={key}
                  >
                    {!shouldShowSeatsIo && key && (
                      <Grid item={true} xs={12}>
                        <Typography variant={'h5'}>{key}</Typography>
                      </Grid>
                    )}
                    <Grid item={true} xs={12}>
                      {groupPools.map((pool) => {
                        const selectedPoolDependency = selectedPools.find(
                          (selectedPool) => selectedPool.poolId === pool.id
                        );

                        // @TODO: only for SBM
                        if (specialEventCopy === specialEventNames.sbmEvent) {
                          pool.ticketsLimit = 1;
                        }

                        return (
                          <li key={pool.id}>
                            {!shouldShowSeatsIo && (
                              <Pool
                                pool={pool}
                                showImage={showImage}
                                lang={eventLang}
                              />
                            )}
                            <fieldset
                              className={cn(
                                styles.formPanel,
                                styles.autoCompleteContainer,
                                selectedPoolDependency && pool.hasDependency
                                  ? styles.hasDependency
                                  : styles.noDependency,
                                muiStyles.root
                              )}
                            >
                              {selectedPoolDependency && pool.hasDependency && (
                                <label
                                  className={styles.label}
                                  htmlFor="dependencyCode"
                                >
                                  <div className={styles.labelHeader}>
                                    {shouldShowSeatsIo && <h4>{pool.title}</h4>}
                                    <p>{lang.dependency}</p>
                                  </div>
                                  <Field
                                    value={pool.hasDependency}
                                    name="hasDependency"
                                    id="hasDependency"
                                    hidden={true}
                                  />
                                  <FormField
                                    id="dependencyCode"
                                    name="dependencyCode"
                                    placeholder={lang.dependencyPlaceHolder}
                                    type="text"
                                  />
                                </label>
                              )}
                            </fieldset>
                          </li>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              }
            })}
          </ul>
        </>
      )}

      {arePoolsLoading && (
        <div className={styles.loadingWrapper}>
          {goTrough(LOADERS_COUNT).map((key) => (
            <Loading key={key} showImage={showImage} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Pools;
